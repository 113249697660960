import React from 'react';
import { useSelector } from 'react-redux';
import { useSettings } from '../user-info/components/settings/useSettings';
import classNames from './mobileViewSetting.module.scss';
import { Lang } from '../../../../../T';
import { selectLang } from '../../../../../store/user/selectors';

const MobileViewSettings = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { openCreateEditStickersModal, boardPage, archived, toggleArchivedData } = useSettings();

  return (
    <div className={classNames.settings}>
      <ul>
        {boardPage && !archived ? (
          <li>
            <button type="button" onClick={toggleArchivedData}>
              {T.archive}
            </button>
          </li>
        ) : null}
        {boardPage && (
          <li>
            <button type="button" onClick={openCreateEditStickersModal}>
              {T.stickers}
            </button>
          </li>
        )}
        <li>
          <button type="button">{T.edit}</button>
        </li>
        <li>
          <button type="button">{T.delete}</button>
        </li>
      </ul>
    </div>
  );
};
export default MobileViewSettings;
