import React from 'react';

const VerticalDotsSvg = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.9956 25C14.5819 25 14.2292 24.8527 13.9375 24.5581C13.6458 24.2635 13.5 23.9093 13.5 23.4956C13.5 23.0819 13.6473 22.7292 13.9419 22.4375C14.2365 22.1458 14.5907 22 15.0044 22C15.4181 22 15.7708 22.1473 16.0625 22.4419C16.3542 22.7365 16.5 23.0907 16.5 23.5044C16.5 23.9181 16.3527 24.2708 16.0581 24.5625C15.7635 24.8542 15.4093 25 14.9956 25ZM14.9956 16.5C14.5819 16.5 14.2292 16.3527 13.9375 16.0581C13.6458 15.7635 13.5 15.4093 13.5 14.9956C13.5 14.5819 13.6473 14.2292 13.9419 13.9375C14.2365 13.6458 14.5907 13.5 15.0044 13.5C15.4181 13.5 15.7708 13.6473 16.0625 13.9419C16.3542 14.2365 16.5 14.5907 16.5 15.0044C16.5 15.4181 16.3527 15.7708 16.0581 16.0625C15.7635 16.3542 15.4093 16.5 14.9956 16.5ZM14.9956 8C14.5819 8 14.2292 7.85269 13.9375 7.55806C13.6458 7.26346 13.5 6.90929 13.5 6.49556C13.5 6.08185 13.6473 5.72917 13.9419 5.4375C14.2365 5.14583 14.5907 5 15.0044 5C15.4181 5 15.7708 5.14731 16.0625 5.44194C16.3542 5.73654 16.5 6.09071 16.5 6.50444C16.5 6.91815 16.3527 7.27083 16.0581 7.5625C15.7635 7.85417 15.4093 8 14.9956 8Z"
        fill="#98A2B2"
      />
    </svg>
  );
};

export default VerticalDotsSvg;
