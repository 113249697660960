// eslint-disable-next-line import/no-cycle
import { pagesComponents } from './constants';

export const getFilteredPagesByPermissions = (role) => {
  return pagesComponents[role];
};

export const isIOS = () => {
  const browserInfo = navigator.userAgent.toLowerCase();

  if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
    return true;
  }
  return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    navigator.platform
  );
};
