import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectDraggableBoard } from '../../../../../../store/draggable-board/selector';
import { archiveListItem, copyListItem, removeListItem } from '../../../../../../store/draggable-board/actions';
import { selectLang } from '../../../../../../store/user/selectors';
import { Lang } from '../../../../../../T';

export const useOptions = (item, listId) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const { archived } = useSelector(selectDraggableBoard);
  const { accessToLoad: loading } = useSelector((store) => store?.draggableBoard?.archiveBoardListItem || {});
  const dispatch = useDispatch();
  const { id: boardId } = useParams();
  const handleCopy = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(copyListItem({ id: item.id, boardId, listId }));
    },
    [item, boardId]
  );

  const handleRemove = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(removeListItem({ id: item.id, boardId, listId }));
    },
    [item, boardId, listId]
  );

  const handleArchive = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(archiveListItem({ id: item.id, boardId, listId, archived, T }));
    },
    [item, boardId, listId]
  );

  return { archived, handleCopy, handleRemove, handleArchive, loading };
};
