import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  createBoard,
  deleteBoardById,
  editBoard,
  getBoards,
  getByIdBoard,
  getSelectableBoards,
  getStatisticById,
} from './actions';
import { errorLogger } from '../../helpers/errorLogger';
import { closeModal } from '../modals/actions';
import { modalNames } from '../modals/constants';
import Axios from '../../services/axios';
import { axiosData } from './requestGenerators';
import { handleCreateBoardData, handleCreateBoardDataWithoutDomains } from './helpers';
import { notifyMessage } from '../message';
import { selectSavedFilter } from '../filter/selectors';
import { selectDraggableBoard } from '../draggable-board/selector';
import { droppingErrors } from '../draggable-board/constants';

function* createBoardRequest(values) {
  return yield call(Axios.request, {
    ...axiosData.createBoard,
    data: values.type === 'basic' ? handleCreateBoardDataWithoutDomains(values) : handleCreateBoardData(values),
  });
}

function* watchCreateBoard() {
  // OK
  yield takeEvery(createBoard, function* workCreateBoard({ payload: { values, T } }) {
    try {
      yield call(createBoardRequest, values);
      yield put(createBoard.success());
      yield put(getBoards({ T }));
      yield put(closeModal({ name: modalNames.createEditBoard }));
    } catch (e) {
      yield put(createBoard.error());
      const { message: errorMessage } = e.response.data.error;
      yield call(
        notifyMessage,
        { body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage, timer: 3000 },
        T
      );
      errorLogger(e);
    }
  });
}

function* editBoardRequest(values) {
  const url = axiosData.editBoard.url.replace(':id', values.id);

  return yield call(Axios.request, {
    ...axiosData.editBoard,
    data: handleCreateBoardData(values),
    url,
  });
}

function* watchEditBoard() {
  // OK
  yield takeEvery(editBoard, function* workEditBoard({ payload: { values, T } }) {
    try {
      yield call(editBoardRequest, values);
      yield put(editBoard.success());
      yield put(getBoards({ T }));
      yield put(closeModal({ name: modalNames.createEditBoard }));
    } catch (e) {
      yield put(createBoard.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

function* getBoardRequest() {
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getSelectableBoards,
  });

  return result;
}

function* watchGetBoards() {
  // OK
  yield takeEvery(getBoards, function* workGetBoards({ payload: { T } }) {
    try {
      const data = yield call(getBoardRequest);
      yield put(getBoards.success({ data }));
    } catch (e) {
      yield put(getBoards.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

function* getByIdBoardRequest(id) {
  const url = axiosData.getByIdBoard.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getByIdBoard,
    url,
  });

  return result;
}

function* watchGetByIdBoard() {
  // OK
  yield takeEvery(getByIdBoard, function* workgetByIdBoard({ payload: { id, T } }) {
    try {
      const data = yield call(getByIdBoardRequest, id);
      yield put(getByIdBoard.success({ data }));
    } catch (e) {
      yield put(getByIdBoard.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

function* deleteBoardByIdRequest(id) {
  const url = axiosData.deleteBoardById.url.replace(':id', id);
  yield call(Axios.request, {
    ...axiosData.deleteBoardById,
    url,
  });
}

function* watchDeleteByBoardId() {
  // OK
  yield takeEvery(deleteBoardById, function* workDeleteBoardById({ payload: { id, T } }) {
    try {
      yield call(deleteBoardByIdRequest, id);
      yield put(deleteBoardById.success());
    } catch (e) {
      yield put(deleteBoardById.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

function* getSelectableBoardsRequest() {
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getSelectableBoards,
  });

  return result;
}

function* watchGetSelectableBoards() {
  yield takeEvery(getSelectableBoards, function* workGetSelectableBoards() {
    try {
      const result = yield call(getSelectableBoardsRequest);
      yield put(
        getSelectableBoards.success({
          data: result.map(({ id, name }) => ({ value: id, label: name })),
        })
      );
    } catch (e) {
      yield put(getSelectableBoards.error());
      errorLogger(e);
    }
  });
}

function* getStatisticRequest(id) {
  const url = axiosData.getStatisticById.url.replace(':id', id);
  const { filteredData } = yield select(selectSavedFilter);
  const { archived } = yield select(selectDraggableBoard);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getStatisticById,
    url,
    data: { ...filteredData.body, archived },
  });

  return result;
}

function* watchGetStatistics() {
  // OK
  yield takeEvery(getStatisticById, function* workGetStatistics({ payload: { id, T } }) {
    try {
      const data = yield call(getStatisticRequest, id);
      yield put(getStatisticById.success({ data }));
    } catch (e) {
      yield put(getStatisticById.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

export default function* brands() {
  yield all([
    watchCreateBoard(),
    watchGetBoards(),
    watchGetSelectableBoards(),
    watchGetByIdBoard(),
    watchEditBoard(),
    watchDeleteByBoardId(),
    watchGetStatistics(),
  ]);
}
