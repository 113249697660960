export const axiosData = {
  getAllListsNames: {
    url: '/api/admin/lists/state',
    method: 'get',
  },
  getAllBoards: {
    url: '/api/admin/boards',
    method: 'get',
  },
  moveAllItems: {
    url: '/api/move/list/:id/items',
    method: 'put',
  },
  getProductsCount: {
    url: '/api/product/warehouse/summary',
    method: 'get',
  },
};
