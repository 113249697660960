import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../../../../../../store/modals/actions';
import { modalNames } from '../../../../../../store/modals/constants';
import { defaultProductPicture } from '../../../../../../constants/constants';
import { reviewRequest } from '../../../../../../store/warehouse/action';
import { Lang } from '../../../../../../T';
import { selectLang } from '../../../../../../store/user/selectors';

const useRow = (id, updatedAt, product, type, value) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const dispatch = useDispatch();
  const { photos } = product;
  const { url } = photos[0];

  const img =
    url && url !== '/productPictures/null' ? `${process.env.REACT_APP_BASE_ENDPOINT}${url}` : defaultProductPicture;

  const commentRef = useRef();

  const onSubmit = useCallback(() => {
    dispatch(
      openModal({
        name: modalNames.productStatusModal,
        title: type === 'COUNT' ? `${T.of_product} ${T.import}` : T.approve_action,
        data: {
          isReject: false,
          updatedAt,
          product,
          type,
          value,
          onOk: (details) => {
            dispatch(
              reviewRequest({
                inputId: id,
                status: 'APPROVED',
                updatedAt,
                details: type === 'COUNT' ? details : undefined,
                T,
              })
            );
            dispatch(closeModal({ name: modalNames.productStatusModal }));
          },
        },
      })
    );
  }, [product.name, product.count, img, type, value]);

  const onReject = useCallback(() => {
    dispatch(
      openModal({
        name: modalNames.productStatusModal,
        title: T.approve_action,
        data: {
          isReject: true,
          updatedAt,
          product,
          ref: commentRef,
          onOk: () => {
            dispatch(
              reviewRequest({
                inputId: id,
                status: 'REJECTED',
                updatedAt,
                description: commentRef.current.value,
                T,
              })
            );
            dispatch(closeModal({ name: modalNames.productStatusModal }));
          },
        },
      })
    );
  }, []);

  return { onSubmit, onReject };
};

export default useRow;
