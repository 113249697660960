import React from 'react';

const DevilEmoji = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Component_50_1"
      data-name="Component 50 – 1"
      width="30.018"
      height="30"
      viewBox="0 0 30.018 30"
    >
      <rect
        id="Rectangle_11120"
        data-name="Rectangle 11120"
        width="30"
        height="30"
        transform="translate(0.018)"
        fill="none"
      />
      <g id="Group_7044" data-name="Group 7044" transform="translate(4894.473 11166.578)">
        <g id="Group_6985" data-name="Group 6985" transform="translate(-4894.473 -11166.578)">
          <g id="Group_6948" data-name="Group 6948" transform="translate(0.063 0)">
            <path
              id="Path_6822"
              data-name="Path 6822"
              d="M556.742,961.4a.765.765,0,0,0-.426-.623,13.984,13.984,0,0,1-4.824-4.341.775.775,0,0,0-1.4.353c-.638,5.387.568,7.548,1.116,8.227a.768.768,0,0,0,.6.287.778.778,0,0,0,.461-.152l4.164-3.062A.767.767,0,0,0,556.742,961.4Z"
              transform="translate(-549.907 -956.109)"
              fill="#ff4141"
            />
            <path
              id="Path_6823"
              data-name="Path 6823"
              d="M680.862,961.4a.765.765,0,0,1,.425-.623,13.986,13.986,0,0,0,4.824-4.341.775.775,0,0,1,1.4.353c.638,5.387-.568,7.548-1.116,8.227a.768.768,0,0,1-.6.287.778.778,0,0,1-.461-.152l-4.164-3.062A.767.767,0,0,1,680.862,961.4Z"
              transform="translate(-657.822 -956.109)"
              fill="#ff4141"
            />
          </g>
          <g id="Group_6952" data-name="Group 6952" transform="translate(0 0.053)">
            <g id="Group_6949" data-name="Group 6949" transform="translate(0 0)">
              <ellipse
                id="Ellipse_1449"
                data-name="Ellipse 1449"
                cx="15"
                cy="14.973"
                rx="15"
                ry="14.973"
                fill="#ff4343"
              />
            </g>
            <g id="Group_6950" data-name="Group 6950" transform="translate(2.363 2.193)">
              <path
                id="Path_6824"
                data-name="Path 6824"
                d="M583.448,968.862a14.992,14.992,0,0,1-20.486,20.828,14.991,14.991,0,1,0,20.486-20.828Z"
                transform="translate(-562.962 -968.862)"
                fill="#ff4242"
              />
            </g>
            <g id="Group_6951" data-name="Group 6951" transform="translate(1.003 1.106)">
              <path
                id="Path_6825"
                data-name="Path 6825"
                d="M556.282,978.135a13.928,13.928,0,0,1,24.962-8.474,13.919,13.919,0,1,0-23.111,15.4A13.819,13.819,0,0,1,556.282,978.135Z"
                transform="translate(-555.246 -962.688)"
                fill="#ff5c5c"
              />
            </g>
          </g>
          <path
            id="Path_6826"
            data-name="Path 6826"
            d="M595.9,1058.358a.5.5,0,0,1-.806-.539,7.536,7.536,0,0,1,13.921-.138.5.5,0,0,1-.8.545C606.015,1056.1,601.349,1053.078,595.9,1058.358Z"
            transform="translate(-587.054 -1036.013)"
            fill="#2c2c2c"
          />
          <g id="Group_6956" data-name="Group 6956" transform="translate(8.361 11.307)">
            <g id="Group_6955" data-name="Group 6955" transform="translate(0 0)">
              <g id="Group_6953" data-name="Group 6953">
                <ellipse
                  id="Ellipse_1450"
                  data-name="Ellipse 1450"
                  cx="1.107"
                  cy="1.86"
                  rx="1.107"
                  ry="1.86"
                  fill="#2c2c2c"
                />
              </g>
              <g id="Group_6954" data-name="Group 6954" transform="translate(11.065 0)">
                <path
                  id="Path_6827"
                  data-name="Path 6827"
                  d="M662.122,1022.148c0,1.027-.5,1.859-1.107,1.859s-1.107-.832-1.107-1.859.5-1.859,1.107-1.859S662.122,1021.12,662.122,1022.148Z"
                  transform="translate(-659.909 -1020.288)"
                  fill="#2c2c2c"
                />
              </g>
            </g>
          </g>
          <g id="Group_6957" data-name="Group 6957" transform="translate(8.219 7.001)">
            <path
              id="Path_6828"
              data-name="Path 6828"
              d="M600.669,999.194l-1.007,1-3.36-3.35,1.007-1Z"
              transform="translate(-596.302 -995.844)"
              fill="#2c2c2c"
            />
            <path
              id="Path_6829"
              data-name="Path 6829"
              d="M649.494,1000.2l-1.007-1,3.36-3.35,1.007,1Z"
              transform="translate(-639.293 -995.844)"
              fill="#2c2c2c"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DevilEmoji;
