import React from 'react';

const ChevronSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.905" height="12.325" viewBox="0 0 20.905 12.325">
      <path
        id="expand_more_FILL0_wght400_GRAD0_opsz48"
        d="M22.452,28.925,12,18.473,13.873,16.6l8.58,8.623,8.58-8.58L32.9,18.516Z"
        transform="translate(-12 -16.6)"
        fill="#364364"
      />
    </svg>
  );
};

export default ChevronSvg;
