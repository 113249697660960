import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { createSticker, getSticker, editSticker, removeSticker } from './actions';

const getInitialState = {
  loading: false,
  error: false,
  data: [],
};

const get = handleActions(
  {
    [getSticker]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),

    [getSticker.success]: (state, { payload: { data } = {} } = {}) => ({
      ...state,
      loading: false,
      error: false,
      data,
    }),
    [getSticker.error]: (state) => ({ ...state, loading: false, error: true }),
    [getSticker.reset]: (state) => ({ ...state, loading: false, error: false, data: [] }),
  },
  getInitialState
);

const createInitialState = {
  loading: false,
  error: false,
  success: false,
};

const create = handleActions(
  {
    [createSticker]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),

    [createSticker.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [createSticker.error]: (state) => ({ ...state, loading: false, error: true }),
    [createSticker.reset]: (state) => ({ ...state, loading: false, error: false, success: false }),
  },
  createInitialState
);

const editInitialState = {};

const edit = handleActions(
  {
    [editSticker]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: true,
        error: false,
        success: false,
      },
    }),

    [editSticker.success]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: false,
        error: false,
        success: true,
      },
    }),
    [editSticker.error]: (state, { payload: { id } }) => ({
      ...state,
      [id]: { loading: false, error: true },
    }),
    [editSticker.reset]: (state, { payload: { id } }) => ({
      ...state,
      [id]: { loading: false, error: false, success: false },
    }),
  },
  editInitialState
);
// removeSticker
// remove

const removeInitialState = {};

const remove = handleActions(
  {
    [removeSticker]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: true,
        error: false,
        success: false,
      },
    }),

    [removeSticker.success]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: false,
        error: false,
        success: true,
      },
    }),
    [removeSticker.error]: (state, { payload: { id } }) => ({
      ...state,
      [id]: { loading: false, error: true },
    }),
    [removeSticker.reset]: (state, { payload: { id } }) => ({
      ...state,
      [id]: { loading: false, error: false, success: false },
    }),
  },
  removeInitialState
);

export default combineReducers({ get, create, edit, remove });
