import { analAxios } from '../../services/axios';

export const getProductStatsRequest = async (id, filter = {}) => {
  const { period, startDate, endDate, urls } = filter;

  return analAxios
    .post(`/api/product/list/buying/${id}`, {
      intervalType: period !== 'custom' ? period : undefined,
      startDate,
      endDate,
      urls,
    })
    .then((res) => res.data)
    .catch((e) => console.log(e));
};
