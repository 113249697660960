export const handleCreateBoardData = (values) => {
  return {
    name: values.name,
    template: values.id ? undefined : values.type,
    domainIds: values.selectedDomains.map(({ value }) => value),
  };
};

export const handleCreateBoardDataWithoutDomains = (values) => {
  return {
    name: values.name,
    template: values.id ? undefined : values.type,
  };
};
