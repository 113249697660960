import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { selectWorkerResend, selectWorkers } from '../../store/workers/selectors';
import { getWorkers } from '../../store/workers/actions';
import { openModal } from '../../store/modals/actions';
import { modalNames } from '../../store/modals/constants';

export const useWorkers = () => {
  const { data, loading } = useSelector(selectWorkers);
  const { loading: resendLoading, success: resendSuccess } = useSelector(selectWorkerResend);

  const dispatch = useDispatch();

  const openInviteModal = useCallback(() => {
    dispatch(openModal({ name: modalNames.createEditWorker }));
  }, []);

  useEffect(() => {
    dispatch(getWorkers({ active: false }));
    return () => {
      dispatch(getWorkers.reset());
    };
  }, []);

  return { data, loading, openInviteModal, resendLoading, resendSuccess };
};
