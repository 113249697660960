import React from 'react';

const ImportSvg = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.43115 17.5771C4.09782 17.5771 3.80615 17.4521 3.55615 17.2021C3.30615 16.9521 3.18115 16.6605 3.18115 16.3271V2.57715H4.43115V16.3271H18.1812V17.5771H4.43115Z"
        fill="#379FFF"
      />
      <path
        d="M13.698 7.95236L12.8057 7.06006L8.93903 10.9267L8.93903 7.56995L7.66431 7.56995L7.66431 13.0938L13.1881 13.0938L13.1881 11.819L9.83134 11.819L13.698 7.95236Z"
        fill="#379FFF"
      />
    </svg>
  );
};

export default ImportSvg;
