import React from 'react';
import { object } from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from './card.module.scss';
import { formatNumberWithThousandsSeparator } from '../../../../helpers/formatNubmer';
import useCards from './useCards';
import { selectLang } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';

const Cards = ({ data }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const { productCardsData, orderCardsData, bidCardsData, moneyCardsData, currency, role } = useCards(data);

  return (
    <div className={classNames.cardsContent}>
      {role !== 'marketingExpert' && (
        <>
          <div className={classNames.title}>{T.Money}</div>
          <div className={classNames.row}>
            {moneyCardsData.map(({ name, amount, cash, card }) => {
              return (
                <div className={classNames.card} key={name}>
                  <div className={classNames.top}>
                    <div className={classNames.name}>{name}</div>
                  </div>
                  <div className={classNames.amount}>
                    {formatNumberWithThousandsSeparator(amount, ' ')} {currency}
                  </div>
                  <div className={classNames.bottom}>
                    {cash !== null && (
                      <div className={classNames.bottomItem}>
                        {T.Cash} | {formatNumberWithThousandsSeparator(cash, ' ')} {currency}
                      </div>
                    )}
                    {card !== null && (
                      <div className={classNames.bottomItem}>
                        {T.Card} | {formatNumberWithThousandsSeparator(card, ' ')} {currency}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      <div className={classNames.title}>{T.Bids_count}</div>
      <div className={classNames.row}>
        {bidCardsData.map(({ name, amount }) => {
          return (
            <div className={classNames.card} key={name}>
              <div className={classNames.top}>
                <div className={classNames.name}>{name}</div>
              </div>
              <div className={classNames.amount}>{formatNumberWithThousandsSeparator(amount, ' ')}</div>
            </div>
          );
        })}
      </div>
      <div className={classNames.title}>{T.Orders_count}</div>
      <div className={classNames.row}>
        {orderCardsData.map(({ name, amount, bottomItems }) => {
          return (
            <div className={classNames.card} key={name}>
              <div className={classNames.top}>
                <div className={classNames.name}>{name}</div>
              </div>
              <div className={classNames.amount}>{formatNumberWithThousandsSeparator(amount, ' ')}</div>
              <div className={classNames.bottom}>
                {bottomItems.map(({ id, text, value }) => (
                  <div className={classNames.bottomItem} key={id}>
                    {text} | {value}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <div className={classNames.title}>{T.Product_count}</div>
      <div className={classNames.row}>
        {productCardsData.map(({ name, amount }) => {
          return (
            <div className={classNames.card} key={name}>
              <div className={classNames.top}>
                <div className={classNames.name}>{name}</div>
              </div>
              <div className={classNames.amount}>{formatNumberWithThousandsSeparator(amount, ' ')}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
Cards.propTypes = {
  data: object,
};
export default Cards;
