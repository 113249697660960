import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import Pagination from '../../components/pagination/Pagination';
import Search from '../../components/search/Search';
import classNames from './states.module.scss';
import { useStates } from './useStates';
import StatesRow from './moreInfo/workerRow/statesRow';
import { openModal } from '../../store/modals/actions';
import { modalNames } from '../../store/modals/constants';
import RadioGroupState from './RadioGroupState';
import { limitOptions } from '../products/constants';
import { Lang } from '../../T';
import FilterClearSvg from '../../svgs/FilterClearSvg';
import { selectLang } from '../../store/user/selectors';
import { formatNumberWithThousandsSeparator } from '../../helpers/formatNubmer';

const States = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const {
    page,
    setPage,
    data,
    boardData,
    pagesCount,
    loading,
    setBoardId,
    setStatus,
    regions,
    setRegion,
    startDate,
    endDate,
    setDateRange,
    resetFilters,
    handleSearch,
    changeLimit,
    limit,
    region,
    status,
    boardId,
    setSearchWord,
  } = useStates();
  const dispatch = useDispatch();
  const filterStatuses = [
    { value: '', label: T.all },
    { value: 'rejected', label: T.rejected },
    { value: 'done', label: T.approved },
    { value: 'pending', label: T.waiting },
  ];
  const handleOpenModal = useCallback(() => {
    dispatch(
      openModal({
        name: modalNames.stateProducts,
        data: { id: 3, boardId: boardId?.value ? boardId.value : boardId },
      })
    );
  }, [boardId]);

  const MyContainer = useCallback(({ className, children }) => {
    const [radioValues, setRadioValues] = useState('');
    useEffect(() => {
      if (radioValues) {
        localStorage.setItem('radioValue', radioValues);
      }
    }, [radioValues]);
    return (
      <div className={classNames.filterDatePickerContent}>
        <CalendarContainer className={className}>
          <div>
            <RadioGroupState name="date" setRadioValues={setRadioValues} values={radioValues} />
          </div>
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  }, []);
  return (
    <div className={classNames.ProductsSection}>
      <div className="container maxw-100">
        <div className={classNames.statesHeader}>
          <h2 className="title">{T.states}</h2>
          <button type="button" onClick={handleOpenModal}>
            {T.get_amount}
          </button>
        </div>
      </div>
      <div className={classNames.staticHeader}>
        <div className="container maxw-100">
          <div className="row align-items-center justify-content-between">
            <div className={classNames.inputs}>
              <div className={`${classNames.searchFilter} ${classNames.ProductsSearch}`}>
                <Search onChange={handleSearch} placeholder={T.search} setSearch={setSearchWord} />
              </div>
            </div>
            <div className="col-12">
              <div className={`${classNames.states_filterRow} row`}>
                <div className="col-1">
                  <div className={classNames.productStatuses}>
                    <p className={classNames.label}>{T.filter_by_date}</p>
                    <DatePicker
                      calendarContainer={MyContainer}
                      selectsRange
                      autoComplete="off"
                      placeholderText={T.select_date}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update, event) => {
                        event.stopPropagation();
                        setPage(1);
                        setDateRange(update);
                      }}
                      isClearable
                    />
                  </div>
                </div>
                <div className="col-1">
                  <div className={classNames.productStatuses}>
                    <p className={classNames.label}>{T.filter_by_status}</p>
                    <Select
                      options={filterStatuses}
                      onChange={(e) => {
                        setPage(1);
                        setStatus(e);
                      }}
                      value={status}
                      placeholder={T.select_status}
                      disable
                    />
                  </div>
                </div>
                <div className="col-1">
                  <div className={classNames.productStatuses}>
                    <p className={classNames.label}>{T.filter_by_board}</p>
                    <Select
                      options={boardData}
                      onChange={(e) => {
                        setPage(1);
                        setBoardId(e);
                      }}
                      placeholder={T.select_board}
                      value={boardId?.label ? boardId : { value: boardData[0]?.value, label: boardData[0]?.label }}
                      disable
                    />
                  </div>
                </div>
                <div className="col-1">
                  <div className={classNames.productStatuses}>
                    <p className={classNames.label}>{T.filter_by_state}</p>
                    <Select
                      options={regions}
                      onChange={(e) => {
                        setPage(1);
                        setRegion(e);
                      }}
                      placeholder={T.select_state}
                      value={region}
                      disable
                    />
                  </div>
                </div>

                <div className="col-1">
                  <div className={classNames.productStatuses}>
                    <button onClick={resetFilters} type="button" className={classNames.searchClear}>
                      <span>
                        <FilterClearSvg />
                      </span>
                      {T.reset_filter}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container ml-0 maxw-100">
        <div className={classNames.ProductsTable}>
          <div className={classNames.productsTableHeader}>
            <div className={classNames.producstTableRoW}>
              <div className={`${classNames.productCol} ${classNames.productNameImage}`}>
                <p className={classNames.nameImageTitle}>{T.product_name}</p>
              </div>
              <div className={`${classNames.productCol} ${classNames.productType}`}>
                <p>{T.address}</p>
              </div>
              <div className={`${classNames.productCol} ${classNames.productDate}`}>
                <p>{T.date}</p>
              </div>
              <div className={`${classNames.productCol} ${classNames.productCode}`}>
                <p>{T.count}</p>
              </div>
              <div className={`${classNames.productCol} ${classNames.productWarehouse}`}>
                <p>{T.total_amount}</p>
              </div>
              <div className={`${classNames.productCol} ${classNames.productSize}`}>
                <p>{T.status}</p>
              </div>
              <div className={`${classNames.productCol} ${classNames.productPrice}`}>
                <p>{T.approve_status}</p>
              </div>
            </div>
          </div>
          <div className={`${classNames.productsTableBody}`}>
            {data?.map(
              ({ address, barcode, city, orderItems, orderStatus, state, totalPrice, createdAt, listItemId }) => {
                return (
                  <StatesRow
                    address={address}
                    barcode={barcode}
                    city={city}
                    orderItems={{ ...orderItems }}
                    orderStatus={orderStatus}
                    state={state}
                    totalPrice={formatNumberWithThousandsSeparator(totalPrice, ' ')}
                    createdAt={createdAt}
                    loading={loading}
                    listItemId={listItemId}
                  />
                );
              }
            )}
          </div>
        </div>
      </div>
      {pagesCount > 1 ? (
        <div className={classNames.allPagination}>
          <Pagination onChange={(e) => setPage(e)} totalPages={pagesCount} defaultPage={page} />
          <div className={classNames.ProductsSeries}>
            <div className={classNames.ProductsSeriesItem}>
              <p>{T.row}</p>
              <div className={classNames.ProductsSeriesItemSelect}>
                <Select
                  placement="top"
                  menuPlacement="top"
                  options={limitOptions}
                  onChange={changeLimit}
                  placeholder=""
                  value={{ value: limit, label: limit }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default States;
