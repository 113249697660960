import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import {
  addDescription,
  createProductRequest,
  editProductInput,
  getDescription,
  getExportRequests,
  getInputRequests,
  getRequestCounts,
  getRequests,
  getStorage,
  getWarehouseCount,
  reviewRequest,
} from './action';

// GET DATA

const getInitValue = {
  loading: false,
  error: false,
  data: [],
  total: 0,
  needToCheck: 0,
  totalCount: 0,
  totalMarketingCount: 0,
};

const getWarehouse = handleActions(
  {
    [getStorage]: (state) => ({ ...state, loading: true, error: false }),
    [getStorage.success]: (
      _,
      { payload: { data = [], total = 0, needToCheck = {}, totalCount = 0, totalMarketingCount = 0 } }
    ) => {
      return {
        loading: false,
        error: false,
        data,
        total,
        needToCheck,
        totalCount,
        totalMarketingCount,
      };
    },
    [getStorage.error]: (state) => ({ ...state, loading: false, error: true }),
  },
  getInitValue
);

// GET REQUEST COUNT

const getCountsInitValues = {
  product: 0,
  defect: 0,
  export: 0,
  loading: false,
  error: false,
};

const getCounts = handleActions(
  {
    [getRequestCounts]: (state) => ({ ...state, loading: true, error: false }),
    [getRequestCounts.success]: (_, { payload }) => {
      return {
        loading: false,
        error: false,
        product: payload.product,
        defect: payload.defect,
        export: payload.export,
      };
    },
    [getRequestCounts.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  getCountsInitValues
);

// GET WAREHOUSE COUNT

const getWarehouseCountsInitValues = {
  totalNeedToCheckCount: 0,
  totalMarketingCount: 0,
  totalCount: 0,
  totalDefectCount: 0,
  loading: false,
  error: false,
};

const getWarehouseCounts = handleActions(
  {
    [getWarehouseCount]: (state) => ({ ...state, loading: true, error: false }),
    [getWarehouseCount.success]: (_, { payload }) => {
      return {
        loading: false,
        error: false,
        totalMarketingCount: payload.totalMarketingCount || 0,
        totalDefectCount: payload.totalDefectCount || 0,
        totalCount: payload.totalCount || 0,
        totalNeedToCheckCount: payload.totalNeedToCheckCount || 0,
      };
    },
    [getWarehouseCount.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  getWarehouseCountsInitValues
);

// GET PENDING REQUEST

const getRequestsInitValues = {
  loading: false,
  error: false,
  data: [],
  total: 0,
};

const getPendingRequest = handleActions(
  {
    [getRequests]: (state) => ({ ...state, loading: true, error: false }),
    [getRequests.success]: (_, { payload: { data, total } }) => ({
      loading: false,
      error: false,
      data,
      total,
    }),
    [getRequests.error]: (state) => ({ ...state, loading: false, error: true }),
  },
  getRequestsInitValues
);

// REVIEW REQUESTS

const reviewRequestInitValue = { loading: false, error: false, success: false };

const reviewPendingRequest = handleActions(
  {
    [reviewRequest]: () => ({ success: false, loading: true, error: false }),
    [reviewRequest.success]: () => ({ loading: false, error: false, success: true }),
    [reviewRequest.error]: () => ({ loading: false, error: true, success: false }),
    [reviewRequest.reset]: () => ({ success: false, loading: false, error: false }),
  },
  reviewRequestInitValue
);

// GET INPUT REQUESTS

const getInputRequestsInitValues = {
  loading: false,
  error: false,
  data: [],
  product: {},
  total: 0,
};

const getInput = handleActions(
  {
    [getInputRequests]: (state) => ({ ...state, loading: true, error: false }),
    [getInputRequests.success]: (_, { payload: { data, total, product } }) => ({
      loading: false,
      error: false,
      data,
      total,
      product,
    }),
    [getInputRequests.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  getInputRequestsInitValues
);

// GET EXPORT REQUESTS

const getExportRequestsInitValues = {
  loading: false,
  error: false,
  data: [],
  product: {},
  total: 0,
};

const getExport = handleActions(
  {
    [getExportRequests]: (state) => ({ ...state, loading: true, error: false }),
    [getExportRequests.success]: (_, { payload: { data, total, product } }) => ({
      loading: false,
      error: false,
      data,
      total,
      product,
    }),
    [getExportRequests.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  getExportRequestsInitValues
);

// CREATE PRODUCT REQUEST

const createRequestInitValue = { loading: false, error: false, success: false };

const createRequest = handleActions(
  {
    [createProductRequest]: () => ({ success: false, loading: true, error: false }),
    [createProductRequest.success]: () => ({ loading: false, error: false, success: true }),
    [createProductRequest.error]: () => ({ loading: false, error: true, success: false }),
    [createProductRequest.reset]: () => ({ success: false, loading: false, error: false }),
  },
  createRequestInitValue
);
// EDIT PRODUCT INPUT

const editInputInitValue = { loading: false, error: false, success: false };

const editInput = handleActions(
  {
    [editProductInput]: () => ({ success: false, loading: true, error: false }),
    [editProductInput.success]: () => ({ loading: false, error: false, success: true }),
    [editProductInput.error]: () => ({ loading: false, error: true, success: false }),
    [editProductInput.reset]: () => ({ success: false, loading: false, error: false }),
  },
  editInputInitValue
);

// GET DESCRIPTION

const getDescriptionInitValue = {
  loading: false,
  error: false,
  data: [],
  total: 0,
  needToCheck: 0,
  totalCount: 0,
  totalMarketingCount: 0,
};

const getDescriptions = handleActions(
  {
    [getDescription]: (state) => ({ ...state, loading: true, error: false }),
    [getDescription.success]: (_, { payload: { data = [], total = 0 } }) => {
      return { loading: false, error: false, data, total };
    },
    [getStorage.error]: (state) => ({ ...state, loading: false, error: true }),
  },
  getDescriptionInitValue
);
// ADD DESCRIPTION

const addDescriptionInitValue = { loading: false, error: false, success: false };

const addDescriptionReducer = handleActions(
  {
    [addDescription]: () => ({ success: false, loading: true, error: false }),
    [addDescription.success]: () => ({ loading: false, error: false, success: true }),
    [addDescription.error]: () => ({ loading: false, error: true, success: false }),
    [addDescription.reset]: () => ({ success: false, loading: false, error: false }),
  },
  addDescriptionInitValue
);

export default combineReducers({
  getWarehouse,
  getCounts,
  getPendingRequest,
  getInput,
  getExport,
  reviewPendingRequest,
  createRequest,
  editInput,
  getDescriptions,
  addDescription: addDescriptionReducer,
  getWarehouseCounts,
});
