import React, { memo, useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { Draggable } from 'react-beautiful-dnd';
import { areEqual } from 'react-window';
import Item from '../item/Item';
import { ColumnContext } from '../../context';

const Row = ({ data: items, index, openEdit, showMenu, setShowMenu, listId, listType }) => {
  const { column, handleGetMoreData } = useContext(ColumnContext);
  const isGetRef = useRef(null);
  const item = useMemo(() => items[index], [index, items]);
  const key = useMemo(() => Symbol(index), [index]);
  useEffect(() => {
    if (!item && !isGetRef.current) {
      isGetRef.current = true;
      handleGetMoreData({
        listId: column.id,
        offset: column?.items?.length,
        key,
        limit: Math.abs(column?.items?.length - index + 1),
      });
    }
  }, [item, column]);

  if (!column?.items?.length) return null;

  return (
    <Draggable
      draggableId={`${column.id}unknown_${index}`}
      index={index}
      key={`${index}key`}
      isDragDisabled={listType === 'state3'}
    >
      {(provided) => (
        <Item
          setShowMenu={setShowMenu}
          provided={provided}
          item={item}
          column={column}
          openEdit={openEdit}
          showMenu={showMenu}
          listId={listId}
        />
      )}
    </Draggable>
  );
};

Row.propTypes = {
  data: PropTypes.array,
  index: PropTypes.number,
  column: PropTypes.object,
  openEdit: PropTypes.func,
  showMenu: PropTypes.bool,
  setShowMenu: PropTypes.func,
  listId: PropTypes.string,
  listType: PropTypes.string,
};

export default memo(Row, areEqual);
