import React from 'react';

const CloseSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path d="M2.37793 1.68945L18.9999 18.3115" stroke="#364364" strokeWidth="3" strokeLinecap="round" />
      <path d="M18.9999 1.68945L2.37793 18.3115" stroke="#364364" strokeWidth="3" strokeLinecap="round" />
    </svg>
  );
};

export default CloseSvg;
