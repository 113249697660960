import React from 'react';
import classNames from './simpleTable.module.scss';
import FilterArrows from '../../../../../../../../svgs/FilterArrows';
import CustomMenu from '../../../../../custom-menu/CustomMenu';

const SimpleTable = () => {
  const items = [
    {
      key: 1,
      text: 'Edit',
      action: () => console.log('edit'),
    },
    {
      key: 2,
      text: 'Delete',
      action: () => console.log('delete'),
    },
  ];

  return (
    <div className={classNames.tableWrapper}>
      <table>
        <colgroup>
          <col style={{ width: 48 }} />
          <col style={{ width: 140 }} />
          <col style={{ width: 140 }} />
          <col style={{ width: 220 }} />
          <col style={{ width: 220 }} />
          <col style={{ width: 120 }} />
          <col style={{ width: 140 }} />
          <col style={{ width: 140 }} />
          <col style={{ width: 140 }} />
          <col style={{ width: 140 }} />
          <col style={{ width: 115 }} />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">
              <p>N</p>
            </th>
            <th scope="col">
              <p>Date and time</p>
            </th>
            <th scope="col">
              <p>Turn on / off</p>
            </th>
            <th scope="col">
              <p>Type</p>
            </th>
            <th scope="col">
              <p>Name</p>
            </th>
            <th scope="col">
              <p>Status</p>
            </th>
            <th scope="col">
              <p>
                BM
                <span>
                  <FilterArrows />
                </span>
              </p>
            </th>
            <th scope="col">
              <p>
                Add account
                <span>
                  <FilterArrows />
                </span>
              </p>
            </th>
            <th scope="col">
              <p>
                Fun page
                <span>
                  <FilterArrows />
                </span>
              </p>
            </th>
            <th scope="col">
              <p>
                Running ads
                <span>
                  <FilterArrows />
                </span>
              </p>
            </th>
            <th scope="col">
              <p>Action</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>09.07.2023, 12:30</p>
            </td>
            <td>
              <p>
                <input type="checkbox" />
              </p>
            </td>
            <td>
              <p>Personal account</p>
            </td>
            <td>
              <p>Hayk Petrosyan</p>
            </td>
            <td>
              <p>Used</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>18</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>34</p>
            </td>
            <td>
              <CustomMenu items={items} />
            </td>
          </tr>
          <tr>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>09.07.2023, 12:30</p>
            </td>
            <td>
              <p>
                <input type="checkbox" />
              </p>
            </td>
            <td>
              <p>Personal account</p>
            </td>
            <td>
              <p>Hayk Petrosyan</p>
            </td>
            <td>
              <p>Used</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>18</p>
            </td>
            <td>
              <CustomMenu placemenet="left" className="text_table" items={items}>
                <p>3</p>
              </CustomMenu>
            </td>
            <td>
              <p>34</p>
            </td>
            <td>
              <CustomMenu items={items} />
            </td>
          </tr>
          <tr>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>09.07.2023, 12:30</p>
            </td>
            <td>
              <p>
                <input type="checkbox" />
              </p>
            </td>
            <td>
              <p>Personal account</p>
            </td>
            <td>
              <p>Hayk Petrosyan</p>
            </td>
            <td>
              <p>Used</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>18</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>34</p>
            </td>
            <td>
              <CustomMenu items={items} />
            </td>
          </tr>
          <tr>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>09.07.2023, 12:30</p>
            </td>
            <td>
              <p>
                <input type="checkbox" />
              </p>
            </td>
            <td>
              <p>Personal account</p>
            </td>
            <td>
              <p>Hayk Petrosyan</p>
            </td>
            <td>
              <p>Used</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>18</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>34</p>
            </td>
            <td>
              <CustomMenu items={items} />
            </td>
          </tr>
          <tr>
            <td>
              <p>1</p>
            </td>
            <td>
              <p>09.07.2023, 12:30</p>
            </td>
            <td>
              <p>
                <input type="checkbox" />
              </p>
            </td>
            <td>
              <p>Personal account</p>
            </td>
            <td>
              <p>Hayk Petrosyan</p>
            </td>
            <td>
              <p>Used</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>18</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>34</p>
            </td>
            <td>
              <CustomMenu items={items} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SimpleTable;
