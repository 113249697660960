import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { createFilter, saveFilteredData } from './actions.js';

const createInitialState = {
  loading: false,
  error: false,
  success: false,
};

const filter = handleActions(
  {
    [createFilter]: () => ({
      loading: true,
      error: false,
      success: false,
    }),
    [createFilter.success]: () => ({
      success: true,
      loading: false,
      error: false,
    }),
    [createFilter.error]: () => ({
      loading: false,
      error: true,
      success: false,
    }),
    [createFilter.reset]: () => ({
      loading: false,
      error: false,
      success: false,
    }),
  },
  createInitialState
);

const saveInitialState = {
  loading: false,
  error: false,
  success: false,
  filteredData: {},
};

const save = handleActions(
  {
    [saveFilteredData]: () => ({
      loading: true,
      error: false,
      success: false,
    }),
    [saveFilteredData.success]: (state, { payload }) => ({
      ...state,
      success: true,
      loading: false,
      error: false,
      filteredData: payload,
    }),
    [saveFilteredData.error]: () => ({
      loading: false,
      error: true,
      success: false,
    }),
    [saveFilteredData.reset]: () => ({
      loading: false,
      error: false,
      success: false,
      filteredData: {},
    }),
  },
  saveInitialState
);

export default combineReducers({ filter, save });
