import { func, number } from 'prop-types';
import React from 'react';
import { useMemo } from 'react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Pagination from '../../../../../../components/pagination/Pagination';
import { selectWarehouseRequests } from '../../../../../../store/warehouse/selector';
import { limitOptions } from '../../../../constants';
import { Lang } from '../../../../../../T';
import Row from '../row/Row';
import classNames from './table.module.scss';
import NoDataSvg from '../../../../../../svgs/NoDataSvg';
import { selectLang } from '../../../../../../store/user/selectors';

const Table = ({ page, setPage, limit, setLimit }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { total, data } = useSelector(selectWarehouseRequests);
  const pagesCount = useMemo(() => Math.ceil(total / limit), [total, limit]);

  const changePage = (page) => setPage(page);

  const changeLimit = (newLimit) => {
    setLimit(newLimit.value);
    setPage(1);
  };

  return (
    <div className="container ml-0 maxw-100">
      <div className={classNames.table}>
        <div className={classNames.table}>
          <div className={classNames.tableWrapper}>
            <table>
              <colgroup>
                <col style={{ width: 120 }} />
                <col style={{ width: 300 }} />
                <col style={{ width: 160 }} />
                <col style={{ width: 94 }} />
                <col style={{ width: 180 }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 240 }} />
                <col style={{ width: 309 }} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    {T.import_BR} <br /> {T.BR_number}
                  </th>
                  <th>{T.product_name}</th>
                  <th>{T.date_hour}</th>
                  <th>{T.code}</th>
                  <th>{T.added}</th>
                  <th>{T.defect}</th>
                  <th>{T.comment}</th>
                  <th>{T.just_status}</th>
                </tr>
              </thead>
              <tbody>
                {total > 0 &&
                  data?.map((item) => {
                    return (
                      <Fragment key={item.id}>
                        <Row item={item} />
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
            {total === 0 && (
              <p className="no-data-tag">
                <NoDataSvg />
                {T.no_data_found}
              </p>
            )}
          </div>
        </div>
      </div>

      {total > 0 && (
        <div className={classNames.allPagination}>
          <Pagination onChange={changePage} totalPages={pagesCount} defaultPage={page} mode="products" />
          <div className={classNames.ProductsSeries}>
            <div className={classNames.ProductsSeriesItem}>
              <p>{T.row}</p>
              <div className={classNames.ProductsSeriesItemSelect}>
                <Select
                  placement="top"
                  menuPlacement="top"
                  options={limitOptions}
                  onChange={changeLimit}
                  placeholder=""
                  value={{ value: limit, label: limit }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
Table.propTypes = {
  page: number,
  setPage: func,
  limit: number,
  setLimit: func,
};
export default Table;
