import { handleActions } from 'redux-actions';
import { openModal, closeModal } from './actions';
import { modalNames } from './constants';

const initialState = {};

Object.values(modalNames).forEach((name) => {
  initialState[name] = {
    open: false,
  };
});

export default handleActions(
  {
    [openModal]: (state, { payload: { name, data, title } }) => ({
      ...state,
      [name]: { opened: true, data, title },
    }),
    [closeModal]: (state, { payload: { name } }) => ({
      ...state,
      [name]: { opened: false },
    }),
  },
  initialState
);
