import { useEffect, useMemo, useState } from 'react';
import usePagination from '../../hooks/usePagination';
import { getProductBuyingRequest } from './helpers';
import useConstants from './constants';

const useProductBuying = () => {
  const { initialFilter } = useConstants();
  const [filter, setFilter] = useState(initialFilter);
  const [data, setData] = useState([]);
  const [ordering, setOrdering] = useState({ field: '', mode: '' });
  const [total, setTotal] = useState(0);

  const { page, limit, changePage, changeLimit, pagesCount } = usePagination(total);

  const getProductBuying = async (filter, page, limit) => {
    const { data, total } = await getProductBuyingRequest(filter, page, limit);
    setOrdering({ field: '', mode: '' });
    setData(data);
    setTotal(total);
  };

  const orderData = ({ value, mod }) => {
    setOrdering({ field: value, mode: mod });
  };

  const orderedData = useMemo(() => {
    const { field, mode } = ordering;
    if (field) {
      const result = JSON.parse(JSON.stringify(data));
      result.sort((a, b) => (mode === 'imgInc' ? a[field] - b[field] : b[field] - a[field]));
      return result;
    }
    return data;
  }, [ordering, data]);

  console.log({ ordering, orderedData });

  useEffect(() => {
    getProductBuying(filter, page, limit);
  }, [filter, page, limit]);

  return { filter, setFilter, total, orderedData, page, limit, changePage, changeLimit, pagesCount, orderData };
};

export default useProductBuying;
