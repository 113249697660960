import React from 'react';

const ThreePointSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="3.897" height="16.886" viewBox="0 0 3.897 16.886">
      <g id="icon" transform="translate(-0.5 -0.5)">
        <path
          id="Path_6323"
          data-name="Path 6323"
          d="M4.4,2.448A1.948,1.948,0,1,1,2.448.5,1.948,1.948,0,0,1,4.4,2.448Z"
          fill="#fff"
        />
        <path
          id="Path_6324"
          data-name="Path 6324"
          d="M4.4,7.448A1.948,1.948,0,1,1,2.448,5.5,1.948,1.948,0,0,1,4.4,7.448Z"
          transform="translate(0 1.495)"
          fill="#fff"
        />
        <path
          id="Path_6325"
          data-name="Path 6325"
          d="M2.448,14.4A1.948,1.948,0,1,0,.5,12.448,1.948,1.948,0,0,0,2.448,14.4Z"
          transform="translate(0 2.989)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default ThreePointSvg;
