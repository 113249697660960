export const axiosData = {
  createDomain: {
    url: '/api/admin/domains',
    method: 'post',
  },
  getDomain: {
    url: '/api/admin/domains',
    method: 'get',
  },
  removeDomain: {
    url: '/api/admin/domains/:id',
    method: 'delete',
  },
};
