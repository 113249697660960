import React from 'react';

const CartSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34.255" height="34.255" viewBox="0 0 34.255 34.255">
      <path
        id="briefcase_suitcase_luggage"
        data-name="briefcase suitcase luggage"
        d="M18.085,16.237a5.542,5.542,0,0,1,11.085,0Zm-3.695,0a9.237,9.237,0,0,1,18.475,0H36.56a3.7,3.7,0,0,1,3.695,3.695V36.56a3.7,3.7,0,0,1-3.695,3.695H10.695A3.7,3.7,0,0,1,7,36.56V19.932a3.7,3.7,0,0,1,3.695-3.695ZM10.695,36.56V19.932H36.56V36.56Z"
        transform="translate(-6.5 -6.5)"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CartSvg;
