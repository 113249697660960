import React from 'react';

const ProfileSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.697" height="33.255" viewBox="0 0 28.697 33.255">
      <g id="Vector" transform="translate(-0.219 1)">
        <path
          id="Path_6549"
          data-name="Path 6549"
          d="M12.8,0A8.8,8.8,0,1,1,4,8.8,8.812,8.812,0,0,1,12.8,0Zm0,14.67A5.868,5.868,0,1,0,6.934,8.8,5.875,5.875,0,0,0,12.8,14.67Z"
          transform="translate(1.766 -1)"
        />
        <path
          id="Path_6550"
          data-name="Path 6550"
          d="M24.836,26.67H4.3a4.2,4.2,0,0,1-3.354-1.62A3.559,3.559,0,0,1,.359,21.9C2.025,16.072,7.867,12,14.567,12s12.543,4.072,14.208,9.9a3.559,3.559,0,0,1-.585,3.146A4.2,4.2,0,0,1,24.836,26.67ZM14.567,14.934c-5.4,0-10.08,3.2-11.387,7.775a.609.609,0,0,0,.107.574,1.291,1.291,0,0,0,1.012.453H24.836a1.291,1.291,0,0,0,1.012-.453.609.609,0,0,0,.106-.573C24.647,18.131,19.964,14.934,14.567,14.934Z"
          transform="translate(0 5.585)"
        />
      </g>
    </svg>
  );
};

export default ProfileSvg;
