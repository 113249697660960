/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import Image from '../../../components/image/Image';
import classNames from './customerMore.module.scss';
import { Lang } from '../../../T';
import { selectLang } from '../../../store/user/selectors';

const CustomerMore = ({ orders }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const mounths = {
    '01': T.jan,
    '02': T.feb,
    '03': T.mar,
    '04': T.apr,
    '05': T.may,
    '06': T.june,
    '07': T.july,
    '08': T.aug,
    '09': T.sept,
    10: T.oct,
    11: T.nov,
    12: T.dec,
  };
  const determineMonth = (date) => {
    const month = date.split('-')[1];
    return mounths[month];
  };
  let renderedOrderStatus;

  const renderIfStatusDone = {
    sale: T.sold,
    return: T.returned,
    change: T.changed,
  };
  const renderIfStatusNull = {
    sale: T.current,
    return: T.for_return,
    change: T.for_change,
  };

  const renderIfStatusThen = {
    sale: T.sale_then,
    return: T.return_then,
    change: T.change_then,
  };

  const determinItemStatuses = {
    done: (orderType) => (renderedOrderStatus = renderIfStatusDone[orderType]),
    null: (orderType) => (renderedOrderStatus = renderIfStatusNull[orderType]),
    rejected: () => (renderedOrderStatus = T.rejected_in_place),
    callRejected: () => (renderedOrderStatus = T.rejected_by_call),
    then: (orderType) => (renderedOrderStatus = renderIfStatusThen[orderType]),
    default: () => (renderedOrderStatus = T.current),
  };
  const determineStatus = (orderStatus = 'default', orderType) => {
    determinItemStatuses[orderStatus](orderType);
    return renderedOrderStatus;
  };

  return (
    <>
      {orders?.map((item) => {
        return (
          <div>
            <div className={classNames.moreWrapper}>
              <div className={classNames.orderHeader}>
                <div className={classNames.orderRow} key={item.id}>
                  <div className={`${classNames.orderCol} ${classNames.orderColMain}`}>
                    <p>
                      {T.order} {item?.barcode} / {determineMonth(item.createdAt)}
                      {new Date(item.createdAt).getDate()}, {new Date(item.createdAt).getFullYear()}
                    </p>
                  </div>
                  <div className={`${classNames.orderCol} ${classNames.orderColStatus}`}>
                    <p>{T.just_status}</p>
                  </div>
                  <div className={`${classNames.orderCol} ${classNames.orderColDomain}`}>
                    <p>{T.domain}</p>
                  </div>
                  <div className={`${classNames.orderCol} ${classNames.orderColQuality}`}>
                    <p>{T.count}</p>
                  </div>
                  <div className={`${classNames.orderCol} ${classNames.orderColSummary}`}>
                    <p>{T.amount}</p>
                  </div>
                  <div className={`${classNames.orderCol} ${classNames.orderColType}`}>
                    <p>{T.type}</p>
                  </div>
                  <div className={`${classNames.orderCol} ${classNames.orderColCode}`}>
                    <p>{T.code}</p>
                  </div>
                </div>
              </div>
              {item?.orderItems?.map((el) => {
                return (
                  <div className={classNames.bodyItem}>
                    <div className={classNames.orderRow} key={el.order}>
                      <div className={`${classNames.orderCol} ${classNames.orderColMain}`}>
                        <p>
                          <Image widht="37" height="37" src={el.orderedProduct.photos[0]?.url} alt="#" />
                          {el.name}
                        </p>
                      </div>
                      <div className={`${classNames.orderCol} ${classNames.orderColStatus}`}>
                        <p>{determineStatus(item.orderStatus, item.orderType)}</p>
                      </div>
                      <div className={`${classNames.orderCol} ${classNames.orderColDomain}`}>
                        <p>{item?.orderDomain?.name}</p>
                      </div>
                      <div className={`${classNames.orderCol} ${classNames.orderColQuality}`}>
                        <p>{el.count}</p>
                      </div>
                      <div className={`${classNames.orderCol} ${classNames.orderColSummary}`}>
                        <p>{el.totalPrice}</p>
                      </div>
                      <div className={`${classNames.orderCol} ${classNames.orderColType}`}>
                        <p>{el.orderedProduct.type}</p>
                      </div>
                      <div className={`${classNames.orderCol} ${classNames.orderColCode}`}>
                        <p>{el.orderedProduct.barcode}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CustomerMore;
