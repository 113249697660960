import { handleActions } from 'redux-actions';
import { searchData } from './actions';

const initialState = {};

export default handleActions(
  {
    [searchData]: (state, { payload: { key } }) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: true,
        error: false,
      },
    }),
    [searchData.success]: (state, { payload: { key, data, name, offset, mode } }) => {
      const productOption =
        name !== 'mainSearch' && offset && state[key].productData ? [...state[key].productData, ...data] : data;
      const productChange =
        mode === 'change' && offset && state[key].productChange ? [...state[key].productChange, ...data] : data;
      const mainSearch = name === 'mainSearch' ? data.result : [];
      return {
        ...state,
        [key]: {
          ...state[key],
          loading: false,
          data: mainSearch,
          productData: productOption,
          productChange: productChange || [],
          end: data.end,
        },
      };
    },
    [searchData.error]: (state, { payload: { key } }) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: false,
        error: true,
      },
    }),
    [searchData.reset]: (state, { payload: { key } }) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: false,
        error: false,
      },
    }),
  },
  initialState
);
