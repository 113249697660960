import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Image from '../../../components/image/Image';
import { useSettings } from '../header/components/user-info/components/settings/useSettings';
import classNames from './profile.module.scss';
import { Lang } from '../../../T';
import { selectLang } from '../../../store/user/selectors';

const Profile = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const IS_YEREVAN = process.env.REACT_APP_LANG === 'true';

  const { handleLogout, openEditUserProfileSettingsModal, profilePicture, firstName, lastName, salary, percent } =
    useSettings();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);
  return (
    <div className={classNames.profileContainer}>
      <div className={classNames.profile}>
        <div className={classNames.profileImage}>
          {profilePicture ? <Image src={profilePicture} alt="" /> : <img src="/images/avatar.png" alt="" />}
        </div>
        <div>
          <div className={classNames.profileName}>{`${firstName} ${lastName}`}</div>
          <div className="d-flex align-items-center">
            <div className={classNames.profileBalance}>
              {salary || 0} {IS_YEREVAN ? T.money_type_am : T.money_type}
            </div>
            <p className={classNames.plusPrice}>+ {percent || 0} %</p>
          </div>
        </div>
      </div>

      <div className={`${classNames.profile} ${classNames.settingButton}`} onClick={openEditUserProfileSettingsModal}>
        {T.account_settings}
      </div>
      <div className={`${classNames.profile} ${classNames.logoutButton}`} onClick={handleLogout}>
        {T.log_out}
      </div>
    </div>
  );
};
export default Profile;
