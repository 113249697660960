import React from 'react';
import Portal from '../portal/Portal';
import ModalContainer from './components/modal-container/ModalContainer';

const Modal = (props) => {
  return (
    <Portal>
      <ModalContainer {...props} />
    </Portal>
  );
};

export default Modal;
