import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDomainCreate, selectDomainGet, selectDomainRemove } from '../../store/domain/selectors';
import { createDomain, getDomain, removeDomain } from '../../store/domain/actions';
import { openModal } from '../../store/modals/actions';
import { modalNames } from '../../store/modals/constants';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';

export const useDomain = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const dispatch = useDispatch();
  const createStatus = useSelector(selectDomainCreate);
  const removeStatus = useSelector(selectDomainRemove);
  const { data } = useSelector(selectDomainGet);

  const disableDelete = useMemo(
    () => removeStatus.loading || createStatus.loading,
    [removeStatus.loading, createStatus.loading]
  );

  const handleSubmit = useCallback((values, { setFieldValue }) => {
    setFieldValue('name', '');
    dispatch(createDomain({ values, T }));
  }, []);

  const handleDeleteDomain = useCallback((id) => {
    dispatch(
      openModal({
        name: modalNames.confirmation,
        data: {
          title: T.delete,
          description: T.are_you_sure_delete_domain,
          onOk: () => {
            dispatch(removeDomain({ id }));
          },
        },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(getDomain({ boardId: null, all: true }));
  }, []);
  return {
    handleSubmit,
    createStatus,
    removeStatus,
    data,
    handleDeleteDomain,
    disableDelete,
  };
};
