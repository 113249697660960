import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getAllBoards, getAllListsNames, getProductsCount, moveAllItems } from './actions';

const getAllListsNamesInitialState = {
  error: false,
  loading: false,
  data: [],
};
const getAllBoardsInitialState = {
  error: false,
  loading: false,
  data: [],
};

const moveAllItemsInitialState = {
  error: false,
  loading: false,
  success: false,
};

const getProductCountsInitialState = {
  error: false,
  loading: false,
  success: false,
  data: {},
};
const getAll = handleActions(
  {
    [getAllListsNames]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),

    [getAllListsNames.success]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      data: payload,
    }),
    [getAllListsNames.error]: (state) => ({ ...state, loading: false, error: true }),
  },
  getAllListsNamesInitialState
);
const getAllBoardsNames = handleActions(
  {
    [getAllBoards]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),

    [getAllBoards.success]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      data: payload,
    }),
    [getAllBoards.error]: (state) => ({ ...state, loading: false, error: true }),
  },
  getAllBoardsInitialState
);

const move = handleActions(
  {
    [moveAllItems]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),

    [moveAllItems.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [moveAllItems.error]: (state) => ({ ...state, loading: false, error: true, success: false }),
  },
  moveAllItemsInitialState
);

const getCounts = handleActions(
  {
    [getProductsCount]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [getProductsCount.success]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
      data: payload,
    }),
    [getProductsCount.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
  },
  getProductCountsInitialState
);

export default combineReducers({ getAll, getAllBoardsNames, move, getCounts });
