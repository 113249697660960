export const axiosData = {
  getCustomers: {
    method: 'post',
    url: '/api/buyers',
  },
  getCustomerByPhone: {
    method: 'get',
    url: '/api/buyers/byPhone?phone=',
  },
  getCustomersCount: {
    method: 'post',
    url: '/api/buyers/count',
  },

  downloadCustomers: {
    method: 'get',
    url: '/api/buyers/download/:path',
  },
  deleteCustomer: {
    method: 'delete',
    url: '/api/buyers/?phone=:phone',
  },

  getAddress: {
    method: 'get',
    url: '/api/buyers/addresses?phone=:phone',
  },
  getOrders: {
    method: 'get',
    url: '/api/buyers/orders?phone=:phone&limit=:limit&offset=:offset',
  },

  addRate: {
    method: 'put',
    url: '/api/buyers/rates?phone=:phone&rate=:rate',
  },
  getRates: {
    method: 'get',
    url: '/api/buyers/rates?phone=:phone',
  },
  deleteRates: {
    method: 'delete',
    url: '/api/buyers/rates?phone=:phone',
  },

  printBuyers: {
    method: 'post',
    url: '/api/buyers/print',
  },
};
