import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ loading, children, type = 'button', disabled, ...preps }) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} {...preps} disabled={loading || disabled}>
      {loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : null}
      {children}
    </button>
  );
};

Button.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};
export default Button;
