import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import Axios from '../../services/axios';
import { errorLogger } from '../../helpers/errorLogger';
import {
  editProfile,
  initialFetching,
  initialFetchingSuccess,
  login,
  logout,
  sendConfirmedPasswords,
  sendResetPasswordToMail,
  setInitialFetching,
  getUserByPhone,
  getUserOrdersByPhone,
  getUserRatesByPhone,
  getUserAddressesByPhone,
  saveUserData,
} from './actions';
import { axiosData } from './requestGenerators';
import { localStorageNames } from '../../localStorageNames';
import { handleEditProfileData } from './helpers';
import { closeModal } from '../modals/actions';
import { modalNames } from '../modals/constants';
import {
  firebaseMessagingRegistrationWatcher,
  firebaseMessagingUnregisterWatcher,
} from '../../services/firebase/firebase';
import { notifyMessage } from '../message';
import { selectLang, selectUserByPhone } from './selectors';
import { Lang } from '../../T';

function* initialFetchingRequest() {
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.initialFetching });
  return result;
}

function* watchInitialFetching() {
  yield takeEvery(initialFetching, function* workInitialFetching() {
    try {
      const token = localStorage.getItem(localStorageNames.authentication);
      if (!token) {
        throw new Error();
      }

      const { permissionGroupId: type, ...rest } = yield call(initialFetchingRequest);
      // request
      yield put(setInitialFetching({ isAuthenticated: true, isAdmin: type === 1, ...rest }));
      yield put(initialFetchingSuccess());
    } catch (e) {
      errorLogger(e);
      yield put(setInitialFetching({}));
    }
  });
}

function* userLoginRequest(data) {
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.login, data });
  return result;
}

function* watchUserLogin() {
  yield takeEvery(login, function* workUserLogin({ payload: { values } }) {
    const lang = yield select(selectLang);
    const T = Lang(lang);
    try {
      const { token, refreshToken, permissionGroupId: type, ...rest } = yield call(userLoginRequest, values);

      localStorage.setItem(localStorageNames.authentication, token);
      localStorage.setItem(localStorageNames.refreshToken, refreshToken);
      yield put(login.success({ isAdmin: type === 1, ...rest }));
    } catch (e) {
      localStorage.removeItem(localStorageNames.authentication);
      errorLogger(e);
      yield call(notifyMessage, { body: e?.response?.data?.error?.message, timer: 1000 }, T);
      yield put(login.error(e));
    }
  });
}

function* userEditProfileRequest(values) {
  yield call(Axios.request, { ...axiosData.editProfile, data: handleEditProfileData(values) });
}

function* watchUserEditProfile() {
  yield takeEvery(editProfile, function* workUserEditProfile({ payload: { values, T } }) {
    try {
      yield call(userEditProfileRequest, values);

      yield put(initialFetching());
      yield put(editProfile.success());
      yield put(closeModal({ name: modalNames.editUserProfileSettings }));
    } catch (e) {
      // localStorage.removeItem(localStorageNames.authentication);
      errorLogger(e);
      yield call(notifyMessage, { body: e.response.data.error || T.error_something_went_wrong }, T);
      yield put(editProfile.error());
    }
  });
}

function* logOutRequest() {
  const refreshToken = localStorage.getItem('refreshToken');
  const token = localStorage.getItem('authentication');
  yield call(Axios.request, { ...axiosData.logOut, data: { refreshToken, token } });
}

function* watchUserLogout() {
  yield takeEvery(logout, function* workLogout() {
    try {
      yield call(logOutRequest);
      yield put(logout.success());
    } catch (e) {
      errorLogger(e);
    } finally {
      localStorage.removeItem(localStorageNames.authentication);
    }
  });
}

/// //////////////////////////////////////////////////////////////////////////

function* userResetWithEmail(data) {
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.resetWithEmail, data });
  return result;
}

function* watchUserResetWithEmail() {
  // OK
  yield takeEvery(sendResetPasswordToMail, function* workUserResetWithEmail({ payload: { values, T } }) {
    try {
      yield call(userResetWithEmail, values);

      yield put(sendResetPasswordToMail.success());
      yield call(notifyMessage, { body: `${T.sent_to_email} ${values.email}`, timer: 3000 }, T);
    } catch (e) {
      errorLogger(e);
      yield put(sendResetPasswordToMail.error());
      yield call(notifyMessage, { body: `${T.check_email_works} - ${values.email}`, timer: 3000 }, T);
    }
  });
}

function* userRecoverPassword(values, token) {
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.recoverPassword,
    data: { ...values, token },
  });
  return result;
}

function* watchUserRecoverPassword() {
  // OK
  yield takeEvery(sendConfirmedPasswords, function* workUserRecoverPassword({ payload: { values, token, T } }) {
    try {
      yield call(userRecoverPassword, values, token);

      yield put(sendConfirmedPasswords.success());
      yield call(notifyMessage, { body: T.password_changed, timer: 3000 }, T);
    } catch (e) {
      errorLogger(e);
      yield put(sendConfirmedPasswords.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong, timer: 3000 }, T);
    }
  });
}

function* userByPhoneRequest(mainPhone) {
  const url = axiosData.userByPhone.url.replace('=', `=${mainPhone}`);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.userByPhone,
    url,
  });
  return result;
}

function* watchUserByPhone() {
  // OK
  yield takeEvery(getUserByPhone, function* workUsersByPhone({ payload: { mainPhone, T } }) {
    try {
      const result = yield call(userByPhoneRequest, mainPhone);
      yield put(getUserByPhone.success({ data: result }));
    } catch (e) {
      errorLogger(e);
      yield put(getUserByPhone.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong, timer: 3000 }, T);
    }
  });
}

function* userOrdersByPhoneRequest(mainPhone, offset) {
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.userOrdersByPhone,
    params: { phone: mainPhone, limit: '5', offset },
  });
  return result;
}

function* watchUserOrdersByPhone() {
  // OK
  yield takeEvery(getUserOrdersByPhone, function* workUserOrdersByPhone({ payload: { mainPhone, offset, T } }) {
    try {
      const response = yield call(userOrdersByPhoneRequest, mainPhone, offset);
      yield put(getUserOrdersByPhone.success({ data: response }));

      const { data: userData } = yield select(selectUserByPhone);
      yield put(saveUserData.success(userData));
    } catch (e) {
      errorLogger(e);
      yield put(getUserOrdersByPhone.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong, timer: 3000 }, T);
    }
  });
}

function* userRatesByPhoneRequest(mainPhone) {
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.userRatesByPhone,
    params: { phone: mainPhone },
  });
  return result;
}

function* watchUserRatesByPhone() {
  // OK
  yield takeEvery(getUserRatesByPhone, function* workUserRatesByPhone({ payload: { mainPhone, T } }) {
    try {
      const response = yield call(userRatesByPhoneRequest, mainPhone);
      yield put(getUserRatesByPhone.success({ data: response }));
    } catch (e) {
      errorLogger(e);
      yield put(getUserRatesByPhone.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong, timer: 3000 }, T);
    }
  });
}

function* userAddressesByPhoneRequest(mainPhone) {
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.userAddressesByPhone,
    params: { phone: mainPhone },
  });
  return result;
}

function* watchUserAddressesByPhone() {
  // OK
  yield takeEvery(getUserAddressesByPhone, function* workUserAddressesByPhone({ payload: { mainPhone, T } }) {
    try {
      const response = yield call(userAddressesByPhoneRequest, mainPhone);
      yield put(getUserAddressesByPhone.success({ data: response }));
    } catch (e) {
      errorLogger(e);
      yield put(getUserAddressesByPhone.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong, timer: 3000 }, T);
    }
  });
}
export default function* user() {
  yield all([
    watchInitialFetching(),
    watchUserLogin(),
    watchUserLogout(),
    watchUserEditProfile(),
    watchUserResetWithEmail(),
    watchUserRecoverPassword(),
    firebaseMessagingRegistrationWatcher([login.success, initialFetchingSuccess], Axios),
    firebaseMessagingUnregisterWatcher(logout.success),
    watchUserByPhone(),
    watchUserOrdersByPhone(),
    watchUserRatesByPhone(),
    watchUserAddressesByPhone(),
  ]);
}
