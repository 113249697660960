import React from 'react';

const SmallLogoSvg = () => {
  return (
    <svg width="48" height="24" viewBox="0 0 522.59 238.687">
      <path
        id="dd"
        d="M73.676,92.418v37.194a117.774,117.774,0,0,0,14.387-12.491,167.323,167.323,0,0,1,18.329-15.608A74.467,74.467,0,0,1,127.57,91.2a74.467,74.467,0,0,1,22.4-3.972,68.321,68.321,0,0,1,34.751,11.269,83.4,83.4,0,0,1,31.227,40.044,153.094,153.094,0,0,1,42.5-39.468,79.054,79.054,0,0,1,38.723-11.806,67.7,67.7,0,0,1,53.706,26.163,96.9,96.9,0,0,1,21.993,64.538V325.907h-69.5V196.4a50.121,50.121,0,0,0-10.584-32.448,31.773,31.773,0,0,0-25.517-13.166,39.835,39.835,0,0,0-30.949,14.893,52.3,52.3,0,0,0-13.027,35.566V325.907H154.319V199.661A54.758,54.758,0,0,0,143.6,165.316a31.773,31.773,0,0,0-25.925-13.98,39.438,39.438,0,0,0-31,15.34,54.947,54.947,0,0,0-13.027,36.787V325.907H3.637V92.418ZM439.344,282.607a42.722,42.722,0,1,0,0-.04Z"
        transform="translate(-3.637 -87.225)"
        fill="#007bff"
      />
    </svg>
  );
};

export default SmallLogoSvg;
