import React from 'react';
import PropTypes from 'prop-types';
import Navbar from './components/navbar/Navbar';
import { useLayout } from './useLayout';
import Header from './components/header/Header';
import BottomMenu from './components/bottom-menu/BottomMenu';
import MobileMenu from './components/main-menu/mobileMenu';
import Profile from './components/profile/Profile';
import useWindowSize from '../hooks/useWindowSize';

const Layout = ({ children, path }) => {
  const { size } = useWindowSize();

  const { isAuth, brownLayout, location, menuState, setMenuState, isSidebarClose, handleToggleSidebar } =
    useLayout(size);

  return (
    <div className={`page main-page ${isAuth && path !== '/board/:id/:filterId' ? '' : 'inBoardMain'}`}>
      <div className="d-flex flex-grow-1">
        {isAuth && path !== '/board/:id/:filterId' && (
          <div className={`layoutSidebar ${isSidebarClose ? 'layoutSidebarClosed' : ''}`} style={{ width: '260px' }}>
            <Navbar handleToggleSidebar={handleToggleSidebar} isSidebarClose={isSidebarClose} />
          </div>
        )}
        <div
          style={{
            width: !isAuth ? '100%' : '',
            padding: isAuth ? '0 25px' : '0',
            marginLeft: isAuth && path !== '/board/:id/:filterId' ? '260px' : '0',
          }}
          className={`layout-right layoutRightbar 
          ${isAuth && path !== '/board/:id/:filterId' ? 'notInBoardPage' : 'inBoardPage'}
          ${!brownLayout ? 'bg-white' : ''} 
          ${isSidebarClose ? 'layoutRightbarClosed' : ''} 
          ${isAuth ? '' : 'notAuthenticated'}`}
        >
          {path !== '/store' && isAuth && <Header path={path} />}
          {children}
          {isAuth && size[0] < 768 && menuState === 'menu' && <MobileMenu />}
          {isAuth && size[0] < 768 && menuState === 'profile' && <Profile />}
          {isAuth && size[0] < 768 && !location.pathname.includes('/board') && (
            <BottomMenu menuState={menuState} setMenuState={setMenuState} />
          )}
        </div>
      </div>
      {location.pathname === '/signinmoulk' && (
        <footer className={isAuth ? 'd-none' : 'footer'}>
          <div className="footer_container">
            <div className="container">
              <div className="row align-items-center justify-content-between py-3">
                <div className="col-12 col-md-6 text-md-left text-center">
                  <p className="m-0">© moulk. CRM, All rights reserved</p>
                </div>
                <div className="col-12 col-md-6 text-md-right text-center">
                  <p className="m-0">
                    Designed and developed by{' '}
                    <a href="https://aimit.company/" rel="norefferer noopener noreferrer" target="_blank">
                      AimIT software company
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
      {location.pathname === '/privacy' && (
        <footer className={isAuth ? 'd-none' : 'footer'}>
          <div className="footer_container">
            <div className="container">
              <div className="row align-items-center justify-content-between py-3">
                <div className="col-12 col-md-6 text-md-left text-center">
                  <p className="m-0">© moulk. CRM, All rights reserved</p>
                </div>
                <div className="col-12 col-md-6 text-md-right text-center">
                  <p className="m-0">
                    Designed and developed by{' '}
                    <a href="https://aimit.company/" rel="norefferer noopener noreferrer" target="_blank">
                      AimIT software company
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
      {location.pathname === '/resetPassword' && (
        <footer className={isAuth ? 'd-none' : 'footer'}>
          <div className="footer_container">
            <div className="container">
              <div className="row align-items-center justify-content-between py-3">
                <div className="col-12 col-md-6 text-md-left text-center">
                  <p className="m-0">© moulk. CRM, All rights reserved</p>
                </div>
                <div className="col-12 col-md-6 text-md-right text-center">
                  <p className="m-0">
                    Designed and developed by{' '}
                    <a href="https://aimit.company/" rel="norefferer noopener noreferrer" target="_blank">
                      AimIT software company
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
      {location.pathname === '/recover-password' && (
        <footer className={isAuth ? 'd-none' : 'footer'}>
          <div className="footer_container">
            <div className="container">
              <div className="row align-items-center justify-content-between py-3">
                <div className="col-12 col-md-6 text-md-left text-center">
                  <p className="m-0">© moulk. CRM, All rights reserved</p>
                </div>
                <div className="col-12 col-md-6 text-md-right text-center">
                  <p className="m-0">
                    Designed and developed by{' '}
                    <a href="https://aimit.company/" rel="norefferer noopener noreferrer" target="_blank">
                      AimIT software company
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
};
export default Layout;
