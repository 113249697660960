import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from './moreInfo.module.scss';
import { Lang } from '../../../T';
import { selectLang } from '../../../store/user/selectors';

const MoreInfo = ({ loading, orderItems }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  return (
    <>
      <div className={classNames.salaryTable}>
        <div className={classNames.salaryTableHeader}>
          <div className={classNames.salaryTableRow}>
            <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_months}`}>
              <p>{T.product_name}</p>
            </div>
            <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_totalSalary}`}>
              <p>{T.count}</p>
            </div>
            <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_percent}`}>
              <p>{T.total_amount}</p>
            </div>
          </div>
        </div>
        {loading ? (
          <div className={classNames.salaryTableBody}>
            <span>Loading...</span>
          </div>
        ) : !orderItems.length ? (
          <div className={classNames.salaryTableBody}>
            <span>{T.no_data_found}</span>
          </div>
        ) : (
          orderItems?.map(({ name, count, totalPrice }, id) => {
            return (
              <div key={id} className={classNames.salaryTableBody}>
                <div className={classNames.salaryTableRow}>
                  <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_months}`}>
                    <p>{name}</p>
                  </div>
                  <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_totalSalary}`}>
                    <p>{count}</p>
                  </div>
                  <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_percent}`}>
                    <p>{totalPrice}</p>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};
MoreInfo.propTypes = {
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  loading: PropTypes.bool,
  orderItems: PropTypes.array,
};

export default MoreInfo;
