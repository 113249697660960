import * as Yup from 'yup';

export const validationSchema = (T) =>
  Yup.object().shape({
    name: Yup.string().trim().required(T.required_field),
    type: Yup.string().required(T.required_field).typeError(T.required_field),
    selectedDomains: Yup.array()
      .of(
        Yup.object({
          value: Yup.number(),
        })
      )
      .when('type', {
        is: '1',
        then: Yup.array().min(1).required('domain is required on type 1'),
      }),
  });
