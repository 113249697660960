import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectLang, selectUserRole } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';

const useCards = (data) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const role = useSelector(selectUserRole);

  const IS_YEREVAN = useMemo(() => process.env.REACT_APP_LANG === 'true', []);
  const currency = useMemo(() => (IS_YEREVAN ? T.amd_am : T.amd), [IS_YEREVAN]);

  const moneyCardsData = useMemo(() => {
    return [
      {
        name: T.Gross_Profit,
        amount: data?.grossTotal,
        cash: data?.grossCash,
        card: data?.grossNoCash,
      },
      // {
      //   name: 'Delivery price',
      //   amount: data?.deliveryPrice,
      //   cash: data?.deliveryPrice,
      //   card: null,
      // },
      // {
      //   name: 'Net',
      //   amount: data?.netTotal,
      //   cash: data?.netCash,
      //   card: data?.netNoCash,
      // },
    ];
  }, [data, T]);
  const bidCardsData = useMemo(() => {
    return [
      { name: T.Total_count, amount: data?.totalCount },
      { name: T.New_order, amount: data?.newOrderCount },
      { name: T.Accepted, amount: data?.acceptedCount },
      { name: T.Unaccepted, amount: data?.unacceptedCount },
    ];
  }, [data, T]);
  const orderCardsData = useMemo(() => {
    return [
      {
        name: T.Sold_count,
        amount: data?.currentSoldCount,

        bottomItems: [
          {
            id: crypto.randomUUID(),
            text: T.Real_sold,
            value: data?.currentSoldCount,
          },
          {
            id: crypto.randomUUID(),
            text: T.All_sold,
            value: data?.overallSoldCount,
          },
        ],
      },
      {
        name: T.Change_Count,
        amount: data?.doneChangeCount,

        bottomItems: [
          {
            id: crypto.randomUUID(),
            text: T.Pending,
            value: data?.pendingChangeCount,
          },
          {
            id: crypto.randomUUID(),
            text: T.Done,
            value: data?.doneChangeCount,
          },
        ],
      },
      {
        name: T.Return_Count,
        amount: data?.doneReturnCount,
        bottomItems: [
          {
            id: crypto.randomUUID(),
            text: T.Pending,
            value: data?.pendingReturnCount,
          },
          {
            id: crypto.randomUUID(),
            text: T.Done,
            value: data?.doneReturnCount,
          },
        ],
      },
      {
        name: T.Reject_Count,
        amount: data?.rejectedTotal,
        bottomItems: [
          {
            id: crypto.randomUUID(),
            text: T.By_call,
            value: data?.callRejectedCount,
          },
          {
            id: crypto.randomUUID(),
            text: T.In_place,
            value: data?.rejectedCount,
          },
          {
            id: crypto.randomUUID(),
            text: `${T.In_place} ${currency}`,
            value: data?.rejectedInPlaceCount,
          },
        ],
      },
    ];
  }, [data, T, currency]);

  const productCardsData = useMemo(() => {
    return [
      { name: T.Real_Count, amount: data?.currentSoldCount },
      { name: T.Marketing_Count, amount: data?.doneChangeCount },
    ];
  }, [data, T]);

  return {
    productCardsData,
    orderCardsData,
    moneyCardsData,
    currency,
    role,
    bidCardsData,
  };
};

export default useCards;
