import { analAxios } from '../../services/axios';

export const getProductBuyingRequest = async (filter, page, limit) => {
  const { period, startDate, endDate, productIds, urls } = filter;
  const startDates = startDate?.toLocaleDateString('ru-RU').split('.');
  const endDates = endDate?.toLocaleDateString('ru-RU').split('.');

  const data = {
    intervalType: period.value !== 'custom' ? period.value : undefined,
    startDate: startDate ? `${startDates[2]}-${startDates[1]}-${startDates[0]}` : undefined,
    endDate: endDate ? `${endDates[2]}-${endDates[1]}-${endDates[0]}` : undefined,
    productIds: productIds.map(({ value }) => value),
    urls: urls.map(({ value }) => value),
    limit,
    offset: (page - 1) * limit,
  };

  if (limit <= 50) {
    const { count, result } = await analAxios
      .post('/api/product/list/buying', data)
      .then((res) => res.data)
      .catch((e) => {
        console.log('Error in single request:', e);
        return { count: 0, result: [] };
      });
    return { data: result || [], total: Number(count) || 0 };
  }

  const numberOfPages = Math.ceil(limit / 50);
  const pagePromises = Array.from({ length: numberOfPages }, (_, i) => i + 1).map((_, index) => {
    const currentPage = page + index;

    return analAxios
      .post('/api/product/list/buying', {
        ...data,
        limit: 50,
        offset: (currentPage - 1) * 50,
      })
      .then((res) => res.data)
      .catch((e) => {
        console.log('Error in page request:', e);
        return null;
      });
  });

  const responses = await Promise.all(pagePromises);

  const result = responses.flatMap((res) => (res ? res.result : []));
  const total = responses[0]?.count || 0;

  return { data: result, total: Number(total) };
};
