import React from 'react';

const LovelyEmoji = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Component_46_1"
      data-name="Component 46 – 1"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <rect id="Rectangle_11116" data-name="Rectangle 11116" width="30" height="30" fill="none" />
      <g id="Group_7029" data-name="Group 7029" transform="translate(5928.426 11166.009)">
        <g id="Group_6937" data-name="Group 6937" transform="translate(-5928.426 -11166.009)">
          <g id="Group_6934" data-name="Group 6934" transform="translate(0 0)">
            <g id="Group_6931" data-name="Group 6931">
              <circle id="Ellipse_1446" data-name="Ellipse 1446" cx="15" cy="15" r="15" fill="#ffd053" />
            </g>
            <g id="Group_6932" data-name="Group 6932" transform="translate(2.323 2.163)">
              <path
                id="Path_6812"
                data-name="Path 6812"
                d="M1040.325,306.451a15.022,15.022,0,0,1-20.516,20.891,15.021,15.021,0,1,0,20.516-20.891Z"
                transform="translate(-1019.81 -306.451)"
                fill="#ffc448"
              />
            </g>
            <g id="Group_6933" data-name="Group 6933" transform="translate(0.986 1.09)">
              <path
                id="Path_6813"
                data-name="Path 6813"
                d="M1013.134,315.489a13.836,13.836,0,0,1,13.96-13.707,14.027,14.027,0,0,1,11.066,5.351,14,14,0,0,0-12.105-6.875,13.835,13.835,0,0,0-13.96,13.707,13.488,13.488,0,0,0,2.894,8.356A13.453,13.453,0,0,1,1013.134,315.489Z"
                transform="translate(-1012.095 -300.258)"
                fill="#ffd77a"
              />
            </g>
          </g>
          <g id="Group_6935" data-name="Group 6935" transform="translate(8.903 18.136)">
            <path
              id="Path_6814"
              data-name="Path 6814"
              d="M1055.409,396.643a6.316,6.316,0,0,0,12.55,0Z"
              transform="translate(-1055.409 -396.643)"
              fill="#2c2c2c"
            />
            <path
              id="Path_6815"
              data-name="Path 6815"
              d="M1066.612,414.633a6.31,6.31,0,0,0,8.521.136l-.058-.136A10.417,10.417,0,0,0,1066.612,414.633Z"
              transform="translate(-1064.845 -410.757)"
              fill="#ff6669"
            />
          </g>
          <g id="Group_6936" data-name="Group 6936" transform="translate(3.602 8.013)">
            <path
              id="Path_6816"
              data-name="Path 6816"
              d="M1036.324,341.845c0-.007,0-.015,0-.022a2.515,2.515,0,0,0-2.469-2.612,2.172,2.172,0,0,0-2.094,1.639h0a2.172,2.172,0,0,0-2.094-1.639,2.515,2.515,0,0,0-2.469,2.612c0,.007,0,.015,0,.022-.008.3,0,3.315,4.561,5.775h0C1036.324,345.16,1036.332,342.142,1036.324,341.845Z"
              transform="translate(-1027.199 -339.212)"
              fill="#ff2223"
            />
            <path
              id="Path_6817"
              data-name="Path 6817"
              d="M1112.513,341.845c0-.007,0-.015,0-.022a2.515,2.515,0,0,0-2.469-2.612,2.172,2.172,0,0,0-2.094,1.639h0a2.172,2.172,0,0,0-2.094-1.639,2.515,2.515,0,0,0-2.469,2.612c0,.007,0,.015,0,.022-.008.3,0,3.315,4.561,5.775h0C1112.513,345.16,1112.521,342.142,1112.513,341.845Z"
              transform="translate(-1089.718 -339.212)"
              fill="#ff2223"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LovelyEmoji;
