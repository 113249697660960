import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { selectIsAuthenticated, selectNavbarState } from '../store/user/selectors';
import useNavbarOpen from '../hooks/useNavbarOpen';

export const useLayout = (size) => {
  const { handleToggleSidebar } = useNavbarOpen();
  const isAuth = useSelector(selectIsAuthenticated);
  const [menuState, setMenuState] = useState('');
  const { location } = useHistory();
  const open = useSelector(selectNavbarState);

  const brownLayout = useMemo(() => {
    return location.pathname === '/home' || location.pathname.includes('/board/');
  }, [location]);

  useEffect(() => {
    if (!brownLayout) {
      document.body.classList.add('bg-white');
    } else {
      document.body.classList.remove('bg-white');
    }
  }, [brownLayout]);

  useEffect(() => {
    if (size[0] < 768 && size[0] !== 0 && !open) {
      handleToggleSidebar();
    }
  }, [size, open]);

  return {
    isAuth,
    brownLayout,
    location,
    menuState,
    setMenuState,
    isSidebarClose: !open,
    handleToggleSidebar,
  };
};
