import React from 'react';
import DevilEmoji from '../svgs/DevilEmoji';
import GhostEmoji from '../svgs/emoji/GhostEmoji';
import UnavilableEmoji from '../svgs/emoji/UnavilableEmoji';
import GreenEmoji from '../svgs/GreenEmoji';
import LovelyEmoji from '../svgs/LovelyEmoji';
import SadEmoji from '../svgs/SadEmoji';
import SmilyEmoji from '../svgs/SmilyEmoji';

export function handleEmoji(type) {
  switch (type) {
    case 'devil':
      return <DevilEmoji />;
    case 'smily':
      return <SmilyEmoji />;
    case 'green':
      return <GreenEmoji />;
    case 'sad':
      return <SadEmoji />;
    case 'lovely':
      return <LovelyEmoji />;
    case 'fake':
      return <GhostEmoji />;
    case 'notAvailable':
      return <UnavilableEmoji />;
    default:
  }
}
