import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLang, selectUserRole } from '../../../../store/user/selectors';
import { openModal } from '../../../../store/modals/actions';
import { modalNames } from '../../../../store/modals/constants';
import { createPresetRequest, deletePresetRequest, getPresetListRequest } from '../../helpers';
import { Lang } from '../../../../T';

const useFilters = ({ filter, handleReset, preset, setPreset, presetList, setPresetList }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const dispatch = useDispatch();
  const role = useSelector(selectUserRole);
  const [changed, setChanged] = useState(false);

  const [filterView, setFilterView] = useState(false);

  const toggleFilterView = () => {
    setFilterView((prev) => !prev);
  };
  const handleCreatePreset = async () => {
    if (preset) {
      const { status } = await createPresetRequest(preset, filter);
      if (status === 200) {
        setChanged(true);
      }
    } else {
      dispatch(
        openModal({
          name: modalNames.createPreset,
          data: { data: filter, setPresetList, presetList, handleReset },
        })
      );
    }
  };

  const choosePreset = (name) => () => {
    setPreset(name);
  };

  const deletePreset = (name) => async () => {
    dispatch(
      openModal({
        name: modalNames.confirmation,
        data: {
          title: T.delete,
          description: '',
          onOk: async () => {
            const { status } = await deletePresetRequest(name);
            if (status === 200) {
              getPresetListRequest(setPresetList);
              if (preset === name) {
                setPreset(null);
                handleReset();
              }
            }
          },
        },
      })
    );
  };

  useEffect(() => {
    if (changed) {
      const timeout = setTimeout(() => {
        setChanged(false);
      }, 1500);
      return () => clearTimeout(timeout);
    }
  }, [changed]);

  return {
    role,
    filterView,
    toggleFilterView,
    handleCreatePreset,
    choosePreset,
    deletePreset,
    changed,
  };
};

export default useFilters;
