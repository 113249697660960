import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { openModal } from '../store/modals/actions';
import { modalNames } from '../store/modals/constants';

export const useOpenConfirmation = () => {
  const dispatch = useDispatch();

  const handleOpen = useCallback((data) => {
    dispatch(openModal({ name: modalNames.confirmation, data }));
  }, []);

  return handleOpen;
};
