import React from 'react';
import CrmTable from './crm-table/CrmTable';
import CampaignTable from './campaign-table/CampaignTable';

export const useTable = () => {
  const tabs = [
    {
      key: 0,
      name: 'Product CRM',
      children: <CrmTable />,
    },
    {
      key: 1,
      name: 'Campaign',
      children: <CampaignTable />,
    },
    {
      key: 2,
      name: 'Group',
      children: <div>crm table</div>,
    },
    {
      key: 3,
      name: 'ADS',
      children: <div>crm table</div>,
    },
  ];
  return {
    tabs,
  };
};
