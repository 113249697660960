import React from 'react';
import ReactSelect from 'react-select';
import { array, func, number } from 'prop-types';
import { useSelector } from 'react-redux';
import Pagination from '../../../../components/pagination/Pagination';
import { limitOptions } from '../../../special-product-page/constants';
import NoDataSvg from '../../../../svgs/NoDataSvg';
import classNames from './content.module.scss';
import { selectLang } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';
import { createHistoryMessage } from '../../helpers';
import { dateOption } from '../../../../constants/constants';

const Content = ({ page, limit, changePage, changeLimit, pagesCount, data, total }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  return (
    <>
      <div className={classNames.table}>
        <div className={classNames.tableWrapper}>
          {data?.map((item) => {
            const { createdAt } = item;
            const userName =
              (item?.type === 101 || item?.type === 113) && !item?.user
                ? T.CustomerFromWeb
                : `${item?.user?.firstName} ${item?.user?.lastName}`;
            return (
              <div className={classNames.row} key={item?.id}>
                <div className={classNames.userPart}>
                  <img
                    className={classNames.avatar}
                    src={
                      item?.user?.profilePicture && item?.user?.profilePicture !== '/profilePictures/null'
                        ? `${process.env.REACT_APP_BASE_ENDPOINT}${item?.user?.profilePicture}`
                        : '/images/avatar.png'
                    }
                    alt="avatar"
                  />
                  <div className={classNames.userName}>{userName}</div>
                  <div className={classNames.date}>{new Date(createdAt).toLocaleString('ru', dateOption)}</div>
                </div>
                <div className={classNames.message}>{createHistoryMessage(item, lang)}</div>
              </div>
            );
          })}
          {total === 0 && (
            <p className="no-data-tag">
              <NoDataSvg />
              {T.no_data_found}
            </p>
          )}
        </div>
      </div>
      {total > 0 && (
        <div className={classNames.allPagination}>
          <Pagination onChange={changePage} totalPages={pagesCount} defaultPage={page} mode="products" />

          <div className={classNames.ProductsSeries}>
            <div className={classNames.ProductsSeriesItem}>
              <p>{T.row}</p>
              <div className={classNames.ProductsSeriesItemSelect}>
                <ReactSelect
                  placement="top"
                  menuPlacement="top"
                  options={limitOptions}
                  onChange={changeLimit}
                  placeholder=""
                  value={{ value: limit, label: limit }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Content;
Content.propTypes = {
  data: array,
  total: number,
  page: number,
  limit: number,
  changePage: func,
  changeLimit: func,
  pagesCount: number,
};
