import React from 'react';

const TextRotateSvg = () => {
  return (
    <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.0413 30.4391L13.9999 32.2656C13.7985 32.1831 13.6007 32.1366 13.4067 32.126C13.2126 32.1155 13.0266 32.1418 12.8486 32.2049C12.6706 32.2681 12.505 32.3689 12.3518 32.5075C12.1975 32.6422 12.0621 32.8118 11.9454 33.0164C11.7386 33.3793 11.649 33.7429 11.6769 34.1072C11.7061 34.469 11.8486 34.8164 12.1042 35.1491C12.3612 35.4794 12.7284 35.7806 13.2058 36.0525C13.7027 36.3356 14.1594 36.5023 14.5761 36.5525C14.9917 36.599 15.3584 36.5417 15.6761 36.3807C15.9927 36.2158 16.2524 35.9557 16.4551 35.6001C16.5689 35.4004 16.6468 35.2044 16.6887 35.0121C16.732 34.8174 16.7403 34.6301 16.7137 34.4504C16.686 34.2669 16.6244 34.0947 16.529 33.9339C16.4325 33.7692 16.3031 33.6197 16.1409 33.4853L17.1933 31.6651C17.5065 31.8855 17.778 32.1676 18.0079 32.5115C18.2392 32.853 18.4084 33.2382 18.5156 33.667C18.6204 34.0945 18.6369 34.5524 18.565 35.0406C18.4946 35.5263 18.3129 36.0262 18.0199 36.54C17.6339 37.217 17.1396 37.7388 16.537 38.1052C15.9333 38.4679 15.2571 38.6408 14.5084 38.624C13.7596 38.6071 12.9748 38.3649 12.154 37.8973C11.3307 37.4282 10.7224 36.8736 10.3291 36.2333C9.93583 35.5931 9.74334 34.9204 9.75164 34.2154C9.75994 33.5103 9.95362 32.8254 10.3327 32.1605C10.5909 31.7076 10.8931 31.3248 11.2391 31.0122C11.5827 30.6981 11.9584 30.4637 12.3662 30.3089C12.7715 30.1526 13.2004 30.0824 13.6528 30.0982C14.1053 30.114 14.5681 30.2276 15.0413 30.4391Z"
        fill="black"
      />
      <path d="M14.5913 25.9459L21.546 31.0247L20.3327 32.6848L13.378 27.606L14.5913 25.9459Z" fill="black" />
      <path
        d="M22.2052 30.1701L17.2311 26.0374L18.5474 24.4544L23.5214 28.5871L22.2052 30.1701ZM17.3109 24.7627C17.1261 24.985 16.8938 25.115 16.6142 25.1527C16.3325 25.1887 16.0869 25.1196 15.8775 24.9456C15.6702 24.7735 15.5596 24.5465 15.5456 24.2648C15.5294 23.9814 15.6138 23.7285 15.7986 23.5062C15.9852 23.2817 16.2185 23.1526 16.4985 23.1188C16.7781 23.0811 17.0215 23.1483 17.2288 23.3205C17.4382 23.4945 17.551 23.7232 17.5671 24.0067C17.5829 24.2862 17.4975 24.5382 17.3109 24.7627Z"
        fill="black"
      />
      <path
        d="M26.3886 25.7434C25.9054 26.2236 25.392 26.5362 24.8483 26.6813C24.3066 26.8243 23.7674 26.8108 23.2306 26.6408C22.6918 26.4687 22.1902 26.1492 21.7256 25.682C21.2591 25.2129 20.9424 24.7096 20.7755 24.172C20.6085 23.6305 20.5983 23.0894 20.7446 22.5488C20.8929 22.0062 21.2067 21.4968 21.6859 21.0206C22.1095 20.5997 22.5544 20.3097 23.0208 20.1506C23.4892 19.9894 23.9512 19.9611 24.4069 20.0655C24.8627 20.1659 25.2823 20.3991 25.6658 20.7649L24.3027 22.1194C24.0416 21.9125 23.7792 21.8077 23.5155 21.8049C23.2539 21.8002 23.0186 21.9015 22.8098 22.109C22.6408 22.277 22.5401 22.4719 22.5077 22.6937C22.4734 22.9136 22.5123 23.1516 22.6246 23.4076C22.7368 23.6596 22.9274 23.9208 23.1962 24.1911C23.465 24.4614 23.7272 24.6554 23.9826 24.7731C24.238 24.8868 24.4768 24.9282 24.6989 24.8971C24.9191 24.8641 25.1137 24.7636 25.2827 24.5956C25.4179 24.4613 25.5086 24.3139 25.5547 24.1535C25.6027 23.9911 25.6052 23.8207 25.5621 23.6422C25.5171 23.4617 25.4234 23.2821 25.2812 23.1033L26.6443 21.7488C27.006 22.1403 27.2397 22.5641 27.3454 23.0202C27.4531 23.4744 27.4279 23.9361 27.2699 24.4053C27.1119 24.8706 26.8181 25.3166 26.3886 25.7434Z"
        fill="black"
      />
      <path
        d="M29.3418 20.7257L27.8526 18.7842L28.0786 18.6098L28.1027 15.6752L29.9433 14.2545L29.8321 18.5182L29.4035 18.849L29.3418 20.7257ZM28.9105 23.1952L23.6561 16.3928L25.2807 15.1389L30.5352 21.9412L28.9105 23.1952ZM32.0801 20.7487L29.1076 19.6605L29.2872 17.6823L33.9573 19.2998L32.0801 20.7487Z"
        fill="black"
      />
      <path
        d="M36.3365 13.7805L38.0093 17.0623L36.177 17.9956L32.2662 10.323L34.0385 9.42035L35.5547 12.3949L35.6184 12.3625C35.5675 11.9417 35.6385 11.5563 35.8315 11.2064C36.0269 10.8552 36.3394 10.5702 36.7691 10.3513C37.1737 10.1452 37.5718 10.0557 37.9632 10.0829C38.3533 10.1075 38.7136 10.2402 39.044 10.4809C39.3768 10.7204 39.6528 11.058 39.8718 11.4939L41.7413 15.1615L39.909 16.0948L38.2228 12.7867C38.0624 12.4658 37.8551 12.2567 37.6011 12.1596C37.3471 12.0625 37.0727 12.089 36.7779 12.2391C36.5856 12.3371 36.4374 12.466 36.3333 12.626C36.2304 12.7822 36.1772 12.9588 36.1737 13.1556C36.1727 13.3512 36.227 13.5595 36.3365 13.7805Z"
        fill="black"
      />
      <path
        d="M45.384 13.9843C44.7501 14.2179 44.1569 14.2946 43.6044 14.2144C43.0536 14.1306 42.5687 13.9015 42.1497 13.5271C41.7324 13.1491 41.4049 12.638 41.1673 11.9936C40.9364 11.3676 40.8582 10.775 40.9328 10.2157C41.0064 9.6538 41.22 9.15976 41.5738 8.7336C41.9265 8.30481 42.408 7.97795 43.0183 7.75303C43.4497 7.59404 43.8689 7.51568 44.2762 7.51797C44.6834 7.52025 45.0657 7.60491 45.4231 7.77194C45.7805 7.93897 46.1028 8.18914 46.3901 8.52244C46.6764 8.85311 46.9113 9.26699 47.0946 9.76407L47.2722 10.2454L42.0049 12.1868L41.5916 11.0663L45.0637 9.78663C44.9854 9.58246 44.8693 9.4176 44.7154 9.29207C44.5616 9.16653 44.3853 9.08959 44.1866 9.06125C43.9895 9.0293 43.7845 9.05259 43.5714 9.13112C43.3557 9.21062 43.1804 9.33053 43.0453 9.49086C42.9092 9.64856 42.8218 9.82868 42.783 10.0312C42.7432 10.2311 42.759 10.4359 42.8303 10.6456L43.2509 11.7858C43.3421 12.033 43.4708 12.2321 43.637 12.3829C43.8022 12.5312 43.9932 12.6236 44.2099 12.6603C44.4266 12.6969 44.6586 12.6697 44.9058 12.5785C45.0768 12.5155 45.2232 12.4346 45.3452 12.3359C45.4671 12.2372 45.5611 12.1234 45.6271 11.9945C45.6931 11.8656 45.7263 11.7249 45.7268 11.5723L47.5173 10.9707C47.5901 11.395 47.5559 11.8004 47.4147 12.1871C47.2752 12.5702 47.0342 12.9174 46.6919 13.2288C46.3487 13.5376 45.9127 13.7894 45.384 13.9843Z"
        fill="black"
      />
      <path
        d="M48.8467 12.7615L47.402 6.4663L49.3488 6.01987L49.6122 7.16743L49.6778 7.1524C49.6972 6.71077 49.8135 6.3591 50.0267 6.09739C50.2392 5.83295 50.5177 5.66125 50.8619 5.58231C50.9548 5.561 51.052 5.5459 51.1536 5.53701C51.2544 5.52538 51.35 5.52215 51.4404 5.52732L51.8401 7.26915C51.737 7.25829 51.6053 7.26116 51.4452 7.27775C51.2878 7.29371 51.149 7.31547 51.0287 7.34304C50.791 7.39755 50.5887 7.50002 50.4219 7.65043C50.2572 7.79749 50.1419 7.97782 50.0758 8.19141C50.0119 8.40164 50.0088 8.63244 50.0665 8.88381L50.8509 12.3019L48.8467 12.7615Z"
        fill="black"
      />
      <path
        d="M56.3055 11.503C55.6345 11.5825 55.0398 11.5189 54.5213 11.3121C54.0052 11.1023 53.5871 10.7665 53.267 10.3048C52.9494 9.83994 52.7501 9.2665 52.6692 8.58447C52.5907 7.92192 52.6528 7.32739 52.8558 6.80086C53.0583 6.27156 53.3813 5.8409 53.8247 5.5089C54.2677 5.17412 54.8121 4.96846 55.458 4.89194C55.9145 4.83784 56.3405 4.85935 56.736 4.95646C57.1314 5.05358 57.4834 5.225 57.792 5.47072C58.1006 5.71645 58.3558 6.03485 58.5574 6.42593C58.7587 6.81422 58.8906 7.27144 58.953 7.79757L59.0134 8.30701L53.4387 8.96754L53.2981 7.78164L56.9728 7.34623C56.9442 7.12943 56.8698 6.94206 56.7494 6.78412C56.6291 6.62618 56.4756 6.51028 56.289 6.43641C56.1048 6.35943 55.9 6.33429 55.6745 6.36101C55.4463 6.38806 55.2478 6.4638 55.079 6.58825C54.91 6.7099 54.7829 6.86469 54.6979 7.0526C54.6126 7.23773 54.5802 7.44059 54.6007 7.66117L54.7438 8.86795C54.7749 9.12963 54.8536 9.35319 54.98 9.53863C55.1061 9.7213 55.2703 9.8557 55.4725 9.94183C55.6746 10.028 55.9066 10.0555 56.1682 10.0245C56.3492 10.0031 56.5105 9.95858 56.6521 9.89099C56.7937 9.82339 56.9116 9.73462 57.0058 9.62465C57.1 9.51468 57.1652 9.38558 57.2013 9.23733L59.0826 9.06946C59.0545 9.49905 58.9267 9.88541 58.6992 10.2285C58.4742 10.5685 58.1589 10.8501 57.7535 11.0731C57.3477 11.2934 56.865 11.4367 56.3055 11.503Z"
        fill="black"
      />
      <path
        d="M73.115 6.52183L71.0289 6.25951C71.0281 6.04185 70.9954 5.84137 70.9309 5.65808C70.8663 5.4748 70.7709 5.31304 70.6444 5.17282C70.518 5.03261 70.3614 4.91827 70.1748 4.82982C69.9912 4.73893 69.7827 4.6788 69.549 4.64942C69.1346 4.5973 68.7644 4.65388 68.4385 4.81915C68.1153 4.98476 67.8489 5.24934 67.6393 5.61288C67.4324 5.97678 67.2947 6.4313 67.2261 6.97645C67.1547 7.54385 67.1755 8.0296 67.2885 8.43372C67.4047 8.8354 67.5979 9.15213 67.8682 9.3839C68.1417 9.61324 68.4815 9.75344 68.8876 9.8045C69.1156 9.83318 69.3265 9.83004 69.5202 9.79506C69.7167 9.76044 69.8929 9.69642 70.0487 9.603C70.2077 9.50715 70.3433 9.38434 70.4553 9.23456C70.5706 9.08235 70.6592 8.90561 70.7213 8.70433L72.8057 8.97917C72.722 9.35289 72.5652 9.71178 72.3354 10.0558C72.1084 10.4002 71.8173 10.7041 71.4621 10.9674C71.1072 11.2279 70.6905 11.4185 70.2119 11.5391C69.7362 11.6601 69.2048 11.6837 68.618 11.6099C67.8447 11.5127 67.1735 11.2559 66.6043 10.8397C66.0382 10.421 65.6197 9.86259 65.3487 9.16455C65.0778 8.46651 65.0012 7.64883 65.1192 6.71151C65.2375 5.7714 65.5172 4.9971 65.9582 4.3886C66.3993 3.78009 66.9471 3.34465 67.6018 3.08227C68.2564 2.81989 68.9633 2.73644 69.7227 2.83192C70.24 2.89698 70.7093 3.02945 71.1306 3.22935C71.5522 3.42646 71.9125 3.68368 72.2116 4.001C72.5111 4.31555 72.7401 4.68481 72.8987 5.10877C73.0572 5.53274 73.1293 6.00376 73.115 6.52183Z"
        fill="black"
      />
      <path d="M77.1203 4.41965L75.0527 12.7729L73.0581 12.2796L75.1257 3.92633L77.1203 4.41965Z" fill="black" />
      <path
        d="M76.1245 12.9867L78.0629 6.78646L80.0369 7.40311L78.0986 13.6034L76.1245 12.9867ZM79.2778 6.37286C79.0006 6.28627 78.791 6.12037 78.6491 5.87517C78.508 5.62727 78.4782 5.37281 78.5598 5.11177C78.6406 4.85343 78.8092 4.66386 79.0655 4.54308C79.3227 4.41961 79.5899 4.40117 79.8671 4.48776C80.147 4.57519 80.3562 4.74243 80.4946 4.98949C80.6365 5.2347 80.6671 5.48647 80.5864 5.74481C80.5048 6.00585 80.3353 6.1981 80.0781 6.32158C79.8245 6.4432 79.5577 6.4603 79.2778 6.37286Z"
        fill="black"
      />
      <path
        d="M81.7605 15.2324C81.1338 14.9737 80.6493 14.6227 80.3071 14.1795C79.9674 13.7374 79.7725 13.2373 79.7223 12.6792C79.6732 12.1185 79.7739 11.5352 80.0243 10.9291C80.2758 10.3205 80.6163 9.83621 81.0458 9.4762C81.479 9.11468 81.972 8.89709 82.5247 8.82343C83.0801 8.75083 83.6685 8.84285 84.2901 9.0995C84.8395 9.32632 85.2769 9.62315 85.6024 9.98998C85.9304 10.3579 86.1341 10.771 86.2134 11.2294C86.2963 11.6863 86.2435 12.1611 86.055 12.6539L84.287 11.9239C84.3766 11.6046 84.372 11.3235 84.2732 11.0804C84.1769 10.8384 83.9934 10.6615 83.7226 10.5497C83.5034 10.4592 83.2856 10.4417 83.0694 10.4974C82.8543 10.5504 82.6507 10.6777 82.4588 10.8792C82.2705 11.0792 82.1038 11.3546 81.9589 11.7053C81.814 12.056 81.7366 12.3712 81.7267 12.6509C81.7204 12.9291 81.7743 13.1641 81.8882 13.3561C82.0032 13.5454 82.1703 13.6854 82.3895 13.7759C82.5649 13.8483 82.7351 13.8748 82.9001 13.8554C83.0677 13.8371 83.2252 13.7738 83.3726 13.6656C83.521 13.5548 83.65 13.3997 83.7595 13.2004L85.5275 13.9304C85.307 14.4129 85.0076 14.7905 84.6293 15.063C84.2536 15.3365 83.8199 15.491 83.3282 15.5265C82.8402 15.5605 82.3176 15.4624 81.7605 15.2324Z"
        fill="black"
      />
      <path
        d="M87.583 15.9973L88.8163 13.8786L89.064 14.0216L91.794 12.9269L93.8117 14.0913L89.8163 15.6112L89.3465 15.3401L87.583 15.9973ZM85.1293 16.5378L89.4364 9.0805L91.2174 10.1083L86.9102 17.5656L85.1293 16.5378ZM88.6037 18.543L88.4814 15.3749L90.3839 14.7882L90.6614 19.7305L88.6037 18.543Z"
        fill="black"
      />
      <path
        d="M96.6749 19.7744L94.2924 22.5837L92.7237 21.2544L98.2938 14.6865L99.8112 15.9724L97.6517 18.5187L97.7062 18.5649C98.0749 18.3556 98.458 18.2723 98.8554 18.3151C99.2549 18.3598 99.6386 18.538 100.006 18.8497C100.353 19.1433 100.589 19.4758 100.715 19.8472C100.843 20.2165 100.86 20.5999 100.765 20.9975C100.673 21.3968 100.468 21.7816 100.15 22.1518L97.4876 25.2914L95.9189 23.962L98.3204 21.1303C98.5547 20.8585 98.6675 20.5867 98.6591 20.3149C98.6506 20.0432 98.5202 19.8004 98.2678 19.5865C98.1032 19.447 97.9269 19.3601 97.7391 19.3258C97.5552 19.2913 97.3718 19.3104 97.1888 19.3831C97.0079 19.4577 96.8366 19.5881 96.6749 19.7744Z"
        fill="black"
      />
      <path
        d="M99.9541 28.1962C99.5013 27.6963 99.2091 27.1757 99.0775 26.6344C98.9498 26.0932 98.9813 25.559 99.1719 25.0316C99.3665 24.5045 99.718 24.0109 100.226 23.5508C100.72 23.1039 101.24 22.8108 101.786 22.6715C102.334 22.5304 102.871 22.5442 103.398 22.7129C103.927 22.8798 104.409 23.2038 104.845 23.685C105.153 24.0252 105.382 24.3842 105.531 24.762C105.681 25.1399 105.745 25.5253 105.724 25.9185C105.702 26.3116 105.591 26.7032 105.389 27.0933C105.189 27.4815 104.893 27.8531 104.501 28.208L104.122 28.5517L100.36 24.3983L101.243 23.5984L103.723 26.3361C103.883 26.1876 103.993 26.0187 104.052 25.8295C104.111 25.6403 104.116 25.4484 104.068 25.2539C104.024 25.0596 103.926 24.8784 103.774 24.7104C103.62 24.5404 103.444 24.4227 103.245 24.3573C103.048 24.2901 102.849 24.2762 102.646 24.3157C102.446 24.3533 102.263 24.4442 102.095 24.5884L101.196 25.4025C101 25.579 100.864 25.7723 100.786 25.9823C100.711 26.1905 100.696 26.4018 100.743 26.616C100.79 26.8303 100.901 27.0349 101.078 27.2299C101.2 27.3647 101.33 27.4702 101.467 27.5463C101.603 27.6224 101.744 27.667 101.888 27.6801C102.032 27.6932 102.175 27.6716 102.316 27.6152L103.541 29.0487C103.175 29.2742 102.787 29.3936 102.376 29.4069C101.969 29.4203 101.558 29.3266 101.141 29.1257C100.727 28.9229 100.332 28.6131 99.9541 28.1962Z"
        fill="black"
      />
      <path
        d="M102.386 30.9372L107.619 27.192L108.778 28.8097L107.824 29.4924L107.863 29.5469C108.276 29.3952 108.644 29.367 108.967 29.4624C109.292 29.5562 109.557 29.7461 109.761 30.0322C109.817 30.1094 109.868 30.1929 109.915 30.2827C109.965 30.371 110.004 30.4576 110.034 30.5426L108.587 31.5789C108.557 31.4799 108.504 31.36 108.427 31.2192C108.352 31.0807 108.278 30.9615 108.207 30.8616C108.065 30.6641 107.893 30.5176 107.691 30.422C107.492 30.3271 107.282 30.2904 107.061 30.3117C106.843 30.3337 106.629 30.4195 106.42 30.569L103.579 32.6025L102.386 30.9372Z"
        fill="black"
      />
      <path
        d="M106.435 37.3322C106.104 36.7445 105.934 36.172 105.925 35.6148C105.921 35.0586 106.069 34.5444 106.372 34.0721C106.678 33.6009 107.13 33.1971 107.728 32.8606C108.308 32.5338 108.88 32.3628 109.444 32.3476C110.009 32.331 110.53 32.4633 111.007 32.7443C111.486 33.024 111.885 33.4467 112.203 34.0125C112.429 34.4124 112.573 34.8133 112.635 35.2149C112.698 35.6166 112.675 36.0069 112.567 36.3856C112.459 36.7644 112.264 37.1217 111.981 37.4577C111.7 37.7923 111.329 38.0894 110.868 38.3489L110.422 38.6002L107.671 33.7168L108.71 33.1319L110.523 36.3509C110.712 36.2413 110.856 36.1008 110.956 35.9292C111.055 35.7577 111.103 35.5717 111.099 35.3714C111.099 35.1721 111.044 34.9737 110.932 34.7762C110.82 34.5762 110.674 34.4224 110.494 34.3147C110.317 34.2056 110.125 34.148 109.92 34.1418C109.716 34.1343 109.517 34.1824 109.321 34.286L108.264 34.8812C108.035 35.0103 107.859 35.1687 107.737 35.3565C107.617 35.5428 107.556 35.7457 107.554 35.9651C107.552 36.1845 107.616 36.4088 107.745 36.638C107.835 36.7965 107.938 36.928 108.054 37.0326C108.171 37.1371 108.298 37.2117 108.436 37.2563C108.573 37.3009 108.717 37.3113 108.868 37.2876L109.746 38.9568C109.339 39.0959 108.934 39.1265 108.53 39.0486C108.13 38.9718 107.75 38.7894 107.388 38.5015C107.029 38.2121 106.711 37.8224 106.435 37.3322Z"
        fill="black"
      />
      <path
        d="M118.297 56.1547L117.925 54.0853C118.133 54.0196 118.314 53.9286 118.47 53.8124C118.626 53.6962 118.752 53.5569 118.848 53.3944C118.944 53.2319 119.007 53.0484 119.035 52.8439C119.068 52.6416 119.063 52.4246 119.021 52.1928C118.947 51.7817 118.783 51.4452 118.528 51.1833C118.274 50.9242 117.942 50.7487 117.532 50.657C117.123 50.568 116.648 50.572 116.108 50.669C115.545 50.7699 115.087 50.9345 114.735 51.1628C114.386 51.3934 114.142 51.6723 114.001 51.9994C113.863 52.3288 113.831 52.6949 113.903 53.0977C113.944 53.324 114.01 53.5243 114.101 53.6988C114.192 53.8761 114.306 54.0251 114.442 54.1461C114.581 54.2693 114.738 54.362 114.915 54.4244C115.094 54.489 115.289 54.521 115.5 54.5202L115.859 56.5919C115.477 56.6233 115.088 56.5806 114.691 56.4638C114.295 56.3498 113.918 56.1624 113.561 55.9018C113.206 55.6407 112.9 55.2997 112.642 54.8789C112.385 54.4608 112.204 53.9606 112.099 53.3784C111.962 52.6114 112.007 51.8941 112.235 51.2268C112.465 50.5616 112.874 49.9957 113.459 49.529C114.045 49.0623 114.803 48.7456 115.732 48.5788C116.665 48.4116 117.488 48.4478 118.2 48.6875C118.912 48.9271 119.491 49.3203 119.937 49.867C120.382 50.4136 120.673 51.0636 120.808 51.8169C120.9 52.3301 120.913 52.8176 120.848 53.2792C120.786 53.7404 120.647 54.1611 120.434 54.5412C120.223 54.9208 119.939 55.2494 119.581 55.5271C119.224 55.8048 118.796 56.014 118.297 56.1547Z"
        fill="black"
      />
      <path d="M121.516 59.3152L112.922 59.8618L112.791 57.8097L121.386 57.2632L121.516 59.3152Z" fill="black" />
      <path
        d="M112.953 60.9465L119.412 60.9375L119.415 62.9938L112.956 63.0028L112.953 60.9465ZM120.166 61.9667C120.166 61.6779 120.261 61.4297 120.451 61.222C120.644 61.0143 120.877 60.9103 121.149 60.9099C121.418 60.9095 121.648 61.0129 121.839 61.2201C122.032 61.4273 122.129 61.6752 122.13 61.9639C122.13 62.2555 122.034 62.5037 121.841 62.7086C121.65 62.9163 121.421 63.0204 121.151 63.0208C120.88 63.0211 120.647 62.9177 120.453 62.7106C120.262 62.5062 120.166 62.2582 120.166 61.9667Z"
        fill="black"
      />
      <path
        d="M112.581 67.018C112.645 66.3398 112.84 65.7712 113.165 65.312C113.49 64.8557 113.913 64.5213 114.435 64.3089C114.959 64.0967 115.549 64.0217 116.205 64.0839C116.863 64.1464 117.429 64.331 117.901 64.6376C118.376 64.9473 118.73 65.3568 118.963 65.8661C119.196 66.3782 119.28 66.9705 119.217 67.6431C119.16 68.2375 119.004 68.745 118.747 69.1655C118.49 69.5889 118.153 69.9061 117.736 70.1172C117.321 70.3313 116.849 70.4203 116.321 70.3842L116.502 68.4712C116.835 68.4633 117.104 68.3762 117.308 68.2097C117.513 68.046 117.629 67.8177 117.656 67.5247C117.679 67.2874 117.632 67.0732 117.515 66.8819C117.401 66.6908 117.218 66.5328 116.968 66.4077C116.721 66.2856 116.407 66.2066 116.028 66.1706C115.648 66.1346 115.323 66.153 115.051 66.2258C114.782 66.3017 114.572 66.4226 114.421 66.5885C114.273 66.7546 114.188 66.9563 114.165 67.1935C114.147 67.3833 114.172 67.5546 114.239 67.7074C114.306 67.863 114.413 67.9956 114.56 68.1053C114.71 68.2153 114.897 68.2936 115.121 68.3401L114.939 70.2532C114.411 70.1834 113.96 70.0069 113.587 69.7237C113.214 69.4433 112.938 69.0722 112.759 68.6104C112.583 68.1516 112.524 67.6208 112.581 67.018Z"
        fill="black"
      />
      <path
        d="M113.531 72.7794L115.919 73.3326L115.856 73.6114L117.706 75.8966L117.188 78.1677L114.558 74.7992L114.679 74.2703L113.531 72.7794ZM112.291 70.5948L120.688 72.5093L120.23 74.514L111.834 72.5995L112.291 70.5948ZM111.399 74.5058L114.391 73.4542L115.512 75.0986L110.87 76.822L111.399 74.5058Z"
        fill="black"
      />
      <path
        d="M112.599 82.5782L109.211 81.1317L110.019 79.2409L117.939 82.6226L117.157 84.4516L114.087 83.1405L114.059 83.2062C114.367 83.4966 114.56 83.8379 114.636 84.2302C114.711 84.625 114.654 85.0441 114.465 85.4875C114.286 85.9051 114.038 86.2289 113.72 86.459C113.405 86.6902 113.044 86.8193 112.636 86.8463C112.227 86.8759 111.799 86.7937 111.351 86.5995L107.565 84.983L108.373 83.0921L111.788 84.5502C112.117 84.6937 112.41 84.7213 112.667 84.633C112.924 84.5447 113.118 84.3484 113.248 84.0443C113.333 83.8458 113.364 83.6518 113.341 83.4622C113.32 83.2764 113.248 83.1068 113.124 82.9535C112.999 82.8028 112.824 82.6777 112.599 82.5782Z"
        fill="black"
      />
      <path
        d="M105.532 88.2083C105.877 87.6273 106.289 87.1938 106.768 86.9078C107.248 86.6255 107.769 86.4976 108.33 86.524C108.893 86.5542 109.469 86.7444 110.06 87.0946C110.634 87.4347 111.068 87.8453 111.363 88.3263C111.66 88.8088 111.805 89.3268 111.8 89.8806C111.796 90.4357 111.629 90.9929 111.297 91.5522C111.062 91.9475 110.786 92.2726 110.469 92.5276C110.151 92.7825 109.801 92.9579 109.419 93.0536C109.036 93.1493 108.628 93.1584 108.195 93.0809C107.764 93.0048 107.321 92.8317 106.865 92.5616L106.424 92.3L109.288 87.4728L110.315 88.0816L108.427 91.2636C108.617 91.3727 108.811 91.4275 109.009 91.4279C109.208 91.4283 109.393 91.3768 109.565 91.2736C109.738 91.1741 109.883 91.0268 109.999 90.8315C110.116 90.6338 110.176 90.4302 110.18 90.2207C110.186 90.0125 110.141 89.8176 110.043 89.636C109.948 89.4557 109.807 89.3068 109.619 89.189L108.574 88.5694C108.347 88.4351 108.122 88.3618 107.898 88.3495C107.676 88.3386 107.469 88.3873 107.278 88.4955C107.087 88.6037 106.924 88.771 106.79 88.9976C106.697 89.1543 106.634 89.3094 106.602 89.4629C106.569 89.6164 106.568 89.764 106.598 89.9056C106.628 90.0472 106.691 90.1774 106.787 90.2961L105.777 91.8917C105.453 91.6084 105.223 91.2722 105.089 90.8831C104.956 90.4979 104.924 90.0765 104.993 89.6191C105.065 89.1631 105.245 88.6928 105.532 88.2083Z"
        fill="black"
      />
      <path
        d="M103.703 91.4092L108.871 95.3481L107.652 96.9458L106.71 96.2278L106.669 96.2816C106.938 96.6356 107.075 96.9824 107.078 97.3219C107.084 97.6632 106.979 97.9751 106.763 98.2576C106.705 98.3338 106.64 98.408 106.567 98.4802C106.496 98.5541 106.425 98.6181 106.351 98.6722L104.921 97.5824C105.008 97.5244 105.108 97.4375 105.221 97.3217C105.333 97.208 105.426 97.1019 105.502 97.0033C105.65 96.8082 105.741 96.5988 105.773 96.3752C105.806 96.1555 105.779 95.9418 105.693 95.7342C105.607 95.5305 105.461 95.35 105.255 95.1927L102.449 93.054L103.703 91.4092Z"
        fill="black"
      />
      <path
        d="M98.7186 97.1132C99.1832 96.6227 99.6809 96.2909 100.212 96.1179C100.743 95.9488 101.279 95.9391 101.82 96.0889C102.362 96.2427 102.882 96.5557 103.381 97.0278C103.866 97.4865 104.199 97.983 104.38 98.5173C104.563 99.0536 104.59 99.5911 104.462 100.13C104.336 100.671 104.049 101.177 103.602 101.649C103.286 101.983 102.945 102.239 102.579 102.418C102.213 102.596 101.833 102.69 101.438 102.698C101.044 102.707 100.644 102.626 100.239 102.454C99.8353 102.285 99.4412 102.018 99.0565 101.654L98.6839 101.301L102.545 97.2261L103.412 98.047L100.867 100.733C101.028 100.882 101.205 100.978 101.398 101.022C101.592 101.066 101.784 101.057 101.975 100.995C102.166 100.936 102.339 100.824 102.495 100.659C102.653 100.492 102.757 100.307 102.807 100.104C102.859 99.9019 102.858 99.7017 102.803 99.503C102.75 99.3061 102.645 99.1296 102.488 98.9733L101.606 98.1379C101.414 97.9567 101.211 97.8354 100.995 97.7738C100.781 97.7142 100.569 97.716 100.358 97.7793C100.148 97.8425 99.9518 97.9698 99.7706 98.1611C99.6452 98.2933 99.5498 98.4308 99.4843 98.5733C99.4187 98.7159 99.385 98.8595 99.383 99.0043C99.381 99.1491 99.4136 99.29 99.4808 99.4269L98.1426 100.76C97.8892 100.412 97.74 100.033 97.6952 99.6242C97.6504 99.219 97.7124 98.801 97.8811 98.3701C98.0519 97.9412 98.3311 97.5222 98.7186 97.1132Z"
        fill="black"
      />
      <path
        d="M81.7888 114.967L83.7006 114.092C83.816 114.277 83.9493 114.43 84.1007 114.552C84.2521 114.674 84.4185 114.761 84.5999 114.814C84.7812 114.867 84.9746 114.881 85.1798 114.858C85.3837 114.839 85.5928 114.78 85.8069 114.682C86.1867 114.508 86.4716 114.266 86.6617 113.953C86.8492 113.643 86.9364 113.277 86.9231 112.858C86.9073 112.44 86.785 111.981 86.5562 111.481C86.3181 110.962 86.0446 110.56 85.7356 110.275C85.4253 109.995 85.0942 109.828 84.7423 109.773C84.389 109.722 84.0263 109.781 83.6541 109.952C83.4451 110.047 83.2674 110.161 83.1212 110.293C82.9723 110.426 82.8563 110.573 82.773 110.734C82.6883 110.899 82.6377 111.075 82.6213 111.261C82.6035 111.451 82.6212 111.648 82.6745 111.852L80.7575 112.716C80.6318 112.354 80.5761 111.966 80.5902 111.553C80.6018 111.141 80.6893 110.729 80.8527 110.318C81.0172 109.91 81.2711 109.529 81.6144 109.174C81.9552 108.821 82.3945 108.521 82.9324 108.275C83.641 107.951 84.3469 107.816 85.0502 107.87C85.752 107.928 86.4019 108.182 87 108.633C87.598 109.084 88.0937 109.739 88.4871 110.598C88.8817 111.459 89.0517 112.265 88.9972 113.014C88.9427 113.764 88.7063 114.422 88.2879 114.99C87.8695 115.557 87.3124 116 86.6165 116.319C86.1424 116.536 85.6736 116.67 85.2102 116.722C84.7479 116.776 84.306 116.747 83.8846 116.635C83.4643 116.525 83.0751 116.332 82.717 116.055C82.3589 115.778 82.0495 115.415 81.7888 114.967Z"
        fill="black"
      />
      <path d="M79.5469 118.876L76.8562 110.696L78.8096 110.054L81.5003 118.234L79.5469 118.876Z" fill="black" />
      <path
        d="M75.8077 111.002L77.4472 117.249L75.4583 117.771L73.8187 111.524L75.8077 111.002ZM76.6418 118.239C76.9211 118.166 77.1853 118.195 77.4343 118.327C77.6841 118.461 77.8435 118.66 77.9125 118.923C77.9808 119.183 77.9388 119.432 77.7866 119.669C77.635 119.908 77.4196 120.065 77.1403 120.138C76.8583 120.212 76.5937 120.182 76.3467 120.046C76.0976 119.915 75.939 119.719 75.8706 119.458C75.8016 119.195 75.8429 118.944 75.9944 118.704C76.144 118.468 76.3598 118.313 76.6418 118.239Z"
        fill="black"
      />
      <path
        d="M69.8308 112.208C70.5052 112.099 71.1063 112.145 71.6341 112.344C72.1591 112.544 72.5905 112.87 72.9285 113.323C73.2669 113.778 73.4889 114.331 73.5943 114.983C73.7002 115.638 73.664 116.234 73.4858 116.769C73.3052 117.308 72.9973 117.755 72.5621 118.11C72.124 118.466 71.5706 118.698 70.9017 118.806C70.3106 118.901 69.7785 118.878 69.3055 118.735C68.8296 118.592 68.4366 118.345 68.1263 117.994C67.8137 117.646 67.6081 117.211 67.5094 116.688L69.4119 116.381C69.5037 116.702 69.6562 116.941 69.8694 117.097C70.0799 117.254 70.3308 117.309 70.6222 117.262C70.8581 117.224 71.0541 117.124 71.2102 116.962C71.3667 116.803 71.4741 116.586 71.5323 116.312C71.5881 116.041 71.5855 115.717 71.5245 115.339C71.4635 114.962 71.3633 114.65 71.224 114.405C71.0824 114.163 70.912 113.99 70.7128 113.886C70.5141 113.784 70.2968 113.752 70.0609 113.79C69.8722 113.821 69.7122 113.888 69.5809 113.992C69.4468 114.096 69.3451 114.233 69.2759 114.404C69.2071 114.577 69.1784 114.778 69.1898 115.007L67.2873 115.315C67.2215 114.784 67.2788 114.302 67.4593 113.869C67.6371 113.436 67.9275 113.074 68.3305 112.784C68.7312 112.497 69.2313 112.305 69.8308 112.208Z"
        fill="black"
      />
      <path
        d="M64.5047 114.55L64.5725 117.001L64.2867 117.01L62.5429 119.378L60.2145 119.45L62.8098 116.054L63.352 116.038L64.5047 114.55ZM66.3056 112.799L66.5732 121.407L64.5179 121.471L64.2503 112.863L66.3056 112.799ZM62.296 112.924L64.0689 115.553L62.7609 117.053L59.9213 112.997L62.296 112.924Z"
        fill="black"
      />
      <path
        d="M54.7766 116.118L55.3283 112.476L57.3614 112.783L56.0716 121.298L54.1051 121L54.6051 117.699L54.5344 117.689C54.3307 118.06 54.0485 118.332 53.6878 118.505C53.3244 118.677 52.9043 118.726 52.4276 118.654C51.9786 118.586 51.6029 118.427 51.3005 118.177C50.9977 117.93 50.7821 117.613 50.6538 117.225C50.5226 116.836 50.495 116.401 50.5708 115.919L51.1873 111.849L53.2204 112.157L52.6643 115.828C52.6078 116.182 52.6545 116.473 52.8045 116.7C52.9544 116.926 53.1929 117.065 53.52 117.114C53.7334 117.146 53.929 117.128 54.1068 117.058C54.2814 116.991 54.4275 116.879 54.545 116.721C54.6597 116.562 54.7369 116.361 54.7766 116.118Z"
        fill="black"
      />
      <path
        d="M47.5603 110.685C48.2089 110.874 48.7314 111.165 49.1278 111.558C49.5207 111.953 49.7741 112.425 49.8881 112.975C49.9986 113.527 49.9577 114.133 49.7653 114.792C49.5784 115.433 49.2886 115.955 48.8959 116.361C48.5023 116.769 48.0366 117.039 47.4987 117.171C46.9599 117.306 46.3784 117.282 45.754 117.1C45.3127 116.972 44.929 116.785 44.603 116.541C44.2771 116.297 44.0201 116.002 43.8322 115.655C43.6443 115.308 43.534 114.916 43.5014 114.477C43.4681 114.041 43.5256 113.569 43.674 113.06L43.8177 112.567L49.2069 114.139L48.8724 115.285L45.32 114.249C45.2614 114.46 45.2566 114.662 45.3056 114.854C45.3546 115.046 45.4505 115.213 45.5934 115.354C45.7327 115.497 45.9114 115.6 46.1294 115.664C46.3501 115.728 46.5624 115.736 46.7664 115.687C46.9696 115.641 47.1471 115.549 47.2988 115.409C47.4498 115.272 47.559 115.098 47.6264 114.887L47.9668 113.72C48.0406 113.467 48.0556 113.231 48.0117 113.011C47.9671 112.793 47.8686 112.605 47.7162 112.447C47.5639 112.289 47.3612 112.173 47.1082 112.099C46.9333 112.048 46.7674 112.026 46.6106 112.033C46.4539 112.04 46.3106 112.075 46.1809 112.14C46.0511 112.204 45.9406 112.297 45.8495 112.42L44.0522 111.839C44.2461 111.454 44.5149 111.149 44.8585 110.922C45.1987 110.697 45.5989 110.561 46.0594 110.514C46.5191 110.47 47.0194 110.527 47.5603 110.685Z"
        fill="black"
      />
      <path
        d="M44.0304 109.645L41.5107 115.587L39.673 114.808L40.1323 113.725L40.0704 113.699C39.7958 114.045 39.4956 114.262 39.1696 114.349C38.8426 114.438 38.5166 114.414 38.1916 114.276C38.1039 114.239 38.0164 114.195 37.929 114.142C37.8405 114.093 37.7612 114.039 37.6911 113.982L38.3883 112.338C38.4654 112.407 38.5737 112.482 38.7131 112.563C38.8498 112.642 38.975 112.705 39.0885 112.754C39.3128 112.849 39.5366 112.884 39.7598 112.86C39.9794 112.837 40.1784 112.759 40.3571 112.625C40.532 112.492 40.6698 112.307 40.7704 112.07L42.1386 108.844L44.0304 109.645Z"
        fill="black"
      />
      <path
        d="M37.2285 106.327C37.82 106.653 38.2662 107.051 38.5669 107.522C38.8639 107.993 39.0078 108.509 38.9987 109.071C38.9857 109.634 38.8132 110.216 38.4812 110.817C38.1587 111.401 37.7614 111.848 37.2895 112.157C36.8162 112.469 36.3026 112.631 35.7487 112.642C35.1935 112.656 34.6312 112.506 34.0617 112.192C33.6592 111.969 33.3256 111.704 33.0609 111.394C32.7962 111.085 32.6102 110.741 32.5027 110.361C32.3952 109.982 32.3736 109.574 32.4378 109.139C32.5007 108.706 32.6602 108.258 32.9163 107.794L33.1643 107.345L38.0793 110.057L37.502 111.103L34.2622 109.315C34.1589 109.508 34.1101 109.703 34.1158 109.902C34.1215 110.1 34.1786 110.284 34.2872 110.453C34.3919 110.622 34.5437 110.762 34.7425 110.872C34.9438 110.983 35.1492 111.037 35.3589 111.034C35.5672 111.034 35.7607 110.982 35.9394 110.879C36.1167 110.778 36.2613 110.633 36.3733 110.441L36.9607 109.378C37.0881 109.147 37.1545 108.919 37.1599 108.695C37.164 108.473 37.109 108.268 36.9949 108.081C36.8809 107.893 36.7085 107.735 36.4778 107.608C36.3183 107.52 36.1612 107.462 36.0068 107.434C35.8523 107.407 35.7047 107.41 35.564 107.445C35.4233 107.479 35.2951 107.546 35.1793 107.645L33.5527 106.685C33.8261 106.353 34.1552 106.113 34.5402 105.967C34.9213 105.822 35.3417 105.777 35.8012 105.832C36.2594 105.89 36.7352 106.055 37.2285 106.327Z"
        fill="black"
      />
      <path
        d="M17.2525 96.6159L18.6615 98.1715C18.5205 98.3368 18.4148 98.5098 18.3444 98.6905C18.274 98.8713 18.2411 99.0558 18.2456 99.2442C18.2501 99.4326 18.2943 99.621 18.378 99.8094C18.4579 99.9976 18.5767 100.179 18.7345 100.353C19.0144 100.662 19.3315 100.86 19.6859 100.947C20.0384 101.032 20.4122 101.005 20.8075 100.866C21.2009 100.725 21.601 100.471 22.0076 100.102C22.4309 99.7194 22.7312 99.3382 22.9086 98.9587C23.082 98.5791 23.1417 98.2136 23.0878 97.8623C23.03 97.5108 22.8639 97.1837 22.5897 96.8809C22.4356 96.7108 22.2739 96.576 22.1044 96.4765C21.9331 96.3748 21.758 96.3087 21.5792 96.278C21.3965 96.2472 21.2139 96.252 21.0316 96.2925C20.8453 96.3327 20.6632 96.4089 20.4852 96.5209L19.0856 94.9568C19.3922 94.7284 19.7444 94.5587 20.1423 94.4477C20.5383 94.3347 20.9564 94.294 21.3968 94.3258C21.835 94.3594 22.2746 94.4863 22.7154 94.7062C23.1544 94.9241 23.5721 95.2519 23.9685 95.6895C24.4908 96.2661 24.832 96.8971 24.9922 97.5825C25.1484 98.2677 25.102 98.9626 24.853 99.6673C24.604 100.372 24.1299 101.041 23.4307 101.674C22.7294 102.308 22.0138 102.713 21.2839 102.886C20.5539 103.06 19.8558 103.033 19.1894 102.806C18.5229 102.579 17.9333 102.182 17.4204 101.616C17.071 101.23 16.8018 100.825 16.6129 100.399C16.4219 99.9757 16.3164 99.5466 16.2963 99.1118C16.2742 98.6789 16.3411 98.2504 16.4969 97.8263C16.6527 97.4022 16.9046 96.9987 17.2525 96.6159Z"
        fill="black"
      />
      <path d="M12.8247 95.5802L19.847 90.5953L21.0379 92.2716L14.0155 97.2565L12.8247 95.5802Z" fill="black" />
      <path
        d="M19.2491 89.6914L13.7598 93.0949L12.6756 91.3477L18.1649 87.9442L19.2491 89.6914ZM12.5769 92.6161C12.7291 92.8615 12.7789 93.1225 12.7263 93.3992C12.6713 93.6774 12.5282 93.8882 12.2971 94.0315C12.0684 94.1733 11.8183 94.2063 11.5469 94.1304C11.2732 94.0561 11.0602 93.8963 10.9079 93.6509C10.7542 93.4032 10.7056 93.1414 10.7621 92.8656C10.8147 92.5889 10.9554 92.3796 11.1841 92.2378C11.4152 92.0945 11.6677 92.06 11.9414 92.1344C12.2113 92.2078 12.4232 92.3684 12.5769 92.6161Z"
        fill="black"
      />
      <path
        d="M16.3637 84.3249C16.6635 84.9379 16.7946 85.5256 16.757 86.0878C16.7182 86.6476 16.5315 87.1545 16.1972 87.6086C15.8603 88.064 15.3953 88.4365 14.8024 88.7263C14.2069 89.0173 13.6273 89.1552 13.0634 89.1401C12.4958 89.1237 11.9791 88.9589 11.5133 88.6458C11.0463 88.3301 10.6641 87.8683 10.3667 87.2603C10.1039 86.7229 9.9722 86.2075 9.97153 85.7139C9.96964 85.2179 10.0917 84.7706 10.3376 84.3722C10.5798 83.9726 10.9363 83.6499 11.407 83.4042L12.2528 85.1336C11.9723 85.3144 11.7883 85.5294 11.7007 85.7785C11.6118 86.0251 11.6322 86.2809 11.7617 86.5457C11.8666 86.7602 12.0191 86.9185 12.2191 87.0208C12.4167 87.1243 12.655 87.1641 12.9341 87.1403C13.2094 87.1151 13.5186 87.0187 13.8618 86.851C14.2049 86.6833 14.4735 86.4974 14.6675 86.2932C14.8577 86.0877 14.9739 85.8746 15.0161 85.654C15.0557 85.4345 15.0231 85.2176 14.9182 85.0032C14.8343 84.8316 14.7237 84.6981 14.5863 84.6027C14.4478 84.5048 14.2869 84.4474 14.1036 84.4307C13.9179 84.4152 13.7172 84.4461 13.5017 84.5232L12.6559 82.7939C13.144 82.5772 13.6214 82.4924 14.0883 82.5393C14.5539 82.5837 14.9839 82.7565 15.3782 83.0577C15.7687 83.3575 16.0972 83.78 16.3637 84.3249Z"
        fill="black"
      />
      <path
        d="M12.5181 79.942L10.1889 80.7068L10.0987 80.4354L7.33087 79.4406L6.59606 77.23L10.5922 78.7473L10.7633 79.2621L12.5181 79.942ZM14.7112 81.1676L6.53844 83.8821L5.88985 81.9308L14.0626 79.2163L14.7112 81.1676ZM13.4459 77.3608L11.433 79.8106L9.62121 78.9856L12.6965 75.1063L13.4459 77.3608Z"
        fill="black"
      />
      <path
        d="M8.14333 71.1179L11.7842 70.5591L12.0964 72.5915L3.58438 73.898L3.28238 71.9322L6.58247 71.4256L6.57162 71.3549C6.15603 71.2713 5.81228 71.083 5.54039 70.79C5.26807 70.4943 5.0953 70.1082 5.02209 69.6316C4.95314 69.1827 4.99287 68.7767 5.1413 68.4136C5.28695 68.0509 5.52583 67.7505 5.85795 67.5123C6.18965 67.2714 6.59677 67.1153 7.07933 67.0441L11.1483 66.4195L11.4605 68.4519L7.79054 69.0152C7.43544 69.0669 7.17198 69.1981 7.00014 69.4088C6.82831 69.6195 6.7675 69.8884 6.81773 70.2153C6.8505 70.4287 6.92655 70.6099 7.04587 70.7589C7.162 70.9056 7.31298 71.0114 7.49882 71.0765C7.68424 71.1388 7.89907 71.1526 8.14333 71.1179Z"
        fill="black"
      />
      <path
        d="M11.1823 62.608C11.1938 63.2835 11.0707 63.8688 10.8128 64.3639C10.552 64.8562 10.1759 65.2383 9.68428 65.5103C9.18982 65.7795 8.59924 65.9199 7.91253 65.9317C7.24544 65.9431 6.66018 65.8213 6.15676 65.5664C5.65053 65.3115 5.25457 64.9467 4.96888 64.4722C4.68039 63.9977 4.53058 63.4353 4.51945 62.785C4.51158 62.3254 4.57586 61.9037 4.71229 61.5201C4.84871 61.1364 5.0547 60.8035 5.33024 60.5212C5.60578 60.2389 5.94824 60.0172 6.35763 59.856C6.76422 59.6948 7.23238 59.6097 7.76213 59.6006L8.27506 59.5919L8.37111 65.2047L7.17708 65.2251L7.11376 61.5253C6.89518 61.5318 6.70127 61.587 6.53202 61.6908C6.36277 61.7946 6.23201 61.9357 6.13973 62.1139C6.0446 62.2893 5.99898 62.4906 6.00287 62.7176C6.0068 62.9475 6.06218 63.1526 6.169 63.333C6.27303 63.5135 6.41424 63.6554 6.59264 63.7589C6.76825 63.8625 6.96681 63.9151 7.18834 63.917L8.40339 63.8962C8.66687 63.8917 8.89722 63.8359 9.09445 63.7288C9.28888 63.6217 9.43912 63.4719 9.54517 63.2795C9.65122 63.087 9.70199 62.859 9.69748 62.5956C9.69436 62.4134 9.66631 62.2484 9.61331 62.1007C9.56032 61.9531 9.48386 61.8268 9.38394 61.722C9.28401 61.6171 9.16212 61.5393 9.01826 61.4885L9.04061 59.5998C9.46519 59.671 9.83673 59.8371 10.1552 60.098C10.4708 60.3562 10.7192 60.6982 10.9003 61.1241C11.0786 61.55 11.1726 62.0446 11.1823 62.608Z"
        fill="black"
      />
      <path
        d="M11.1125 58.9432L4.68411 58.3165L4.87805 56.3286L6.04989 56.4428L6.05642 56.3759C5.64376 56.2174 5.34713 55.9955 5.16652 55.7103C4.98312 55.4249 4.90857 55.1064 4.94287 54.7548C4.95212 54.66 4.96863 54.563 4.99238 54.4639C5.01334 54.3645 5.04059 54.2728 5.07414 54.1888L6.85282 54.3622C6.80981 54.4566 6.77078 54.5823 6.73574 54.7395C6.70096 54.8938 6.67759 55.0324 6.66561 55.1551C6.64193 55.3979 6.67497 55.6222 6.76473 55.8281C6.85197 56.0309 6.9864 56.1976 7.16803 56.3279C7.34714 56.4552 7.56504 56.5314 7.82173 56.5564L11.3121 56.8967L11.1125 58.9432Z"
        fill="black"
      />
      <path
        d="M12.2875 51.4565C12.1499 52.118 11.9007 52.6617 11.5401 53.0878C11.1772 53.5106 10.7261 53.8004 10.1866 53.9574C9.64493 54.1111 9.03788 54.118 8.36545 53.9782C7.71224 53.8424 7.1682 53.5947 6.73334 53.2351C6.29574 52.875 5.9899 52.432 5.81584 51.9062C5.63904 51.3798 5.61688 50.7983 5.74937 50.1615C5.84303 49.7114 5.99868 49.3143 6.21632 48.9702C6.43396 48.626 6.70829 48.3466 7.03928 48.132C7.37028 47.9173 7.75322 47.7765 8.18808 47.7094C8.62021 47.6418 9.09563 47.6619 9.61436 47.7698L10.1166 47.8742L8.97303 53.3701L7.80383 53.127L8.55766 49.5042C8.34301 49.4625 8.14169 49.4736 7.95371 49.5376C7.76573 49.6015 7.60709 49.7103 7.4778 49.8638C7.34633 50.014 7.25747 50.2002 7.21121 50.4225C7.16438 50.6476 7.17318 50.8599 7.23761 51.0594C7.2993 51.2583 7.40574 51.4279 7.55695 51.5682C7.70542 51.7078 7.88749 51.8029 8.10317 51.8535L9.29296 52.1009C9.55096 52.1545 9.78795 52.1508 10.0039 52.0898C10.2172 52.0282 10.3968 51.9152 10.5426 51.7509C10.6885 51.5865 10.7883 51.3753 10.842 51.1173C10.8791 50.9389 10.8881 50.7719 10.8689 50.6161C10.8498 50.4604 10.8031 50.3204 10.7287 50.1961C10.6543 50.0719 10.5526 49.9691 10.4235 49.8879L10.8616 48.0506C11.2601 48.2136 11.5859 48.4574 11.839 48.782C12.09 49.1034 12.2569 49.4917 12.3396 49.947C12.4197 50.4017 12.4023 50.9049 12.2875 51.4565Z"
        fill="black"
      />
    </svg>
  );
};

export default TextRotateSvg;
