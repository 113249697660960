import { call, all, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import {
  createList,
  drag,
  getDraggableTable,
  removeList,
  editList,
  createListItem,
  getListItemToEdit,
  editListItem,
  copyListItem,
  removeListItem,
  getDraggableTableListItems,
  archiveListItem,
  resetReturnChangeListItemStatus,
  archiveList,
  statusShippingPut,
  changeOrderStatusDone,
  changeOrderStatusBack,
  clearErrorMessage,
  putFilteredValueInStore,
  getMoreInfoOrder,
  revertStatus,
  getBoardName,
  saveListItemId,
  addLabel,
  removeLabel,
} from './actions';
import { errorLogger } from '../../helpers/errorLogger';
import {
  changeLocalTitle,
  getFilteredTableData,
  getGlobalBoardId,
  getListsWithNewOrdering,
  getSameBoardItemsWithNewOrdering,
  getSameOtherItemsWithNewOrdering,
  handleCopyListItem,
  handleCreateList,
  handleCreateListData,
  handleCreateListItem,
  handleCreateListItemData,
  handleEditListItem,
  handleEditListItemData,
  handleEditListItemReturnChangeData,
  handleGetListData,
  handleRemoveList,
  handleRemoveListItem,
  reorderList,
} from './helpers';
import { selectDraggableBoard, selectPutFilteredValue } from './selector';
import { notifyMessage } from '../message';
import Axios from '../../services/axios';
import { axiosData } from './requestGenerators';
import { closeModal } from '../modals/actions';
import { modalNames } from '../modals/constants';
import { LIST_LIMIT } from './constants';
import {
  copyItemMessage,
  createEditOrderMessage,
  createListMessage,
  deleteItemMessage,
  dragListItemToOutListMessage,
  dragListItemToSameListMessage,
  dragListMessage,
  moveItemToArchiveMessage,
  reArchiveItemMessage,
  removeListMessage,
} from '../../services/firebase/actions';
import { createFilter } from '../filter/actions';
import { droppingErrors } from './constants';
import { selectSavedFilter } from '../filter/selectors';
import { addRate } from '../customers/actions';
import { selectLang } from '../user/selectors';
import { Lang } from '../../T';

function* changeOrderStatusToBack({ id }) {
  const url = axiosData.changeOrderStatusToBack.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.changeOrderStatusToBack,
    url,
  });
  return result;
}

function* watchChangeOrderStatusToBack() {
  yield takeEvery(changeOrderStatusBack, function* workChangeOrderBack({ payload: { id } }) {
    try {
      yield call(changeOrderStatusToBack, { id });
      yield put(changeOrderStatusBack.success());
      yield put(closeModal({ name: modalNames.createEditOrder }));
    } catch (e) {
      yield put(changeOrderStatusBack.error());
      errorLogger(e);
    }
  });
}
function* changeOrderStatusToDone({ id, orderStatus }) {
  const url = axiosData.changeOrderStatus.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.changeOrderStatus,
    url,
    data: { orderStatus },
  });
  return result;
}

function* watchChangeOrderStatusToDone() {
  yield takeEvery(changeOrderStatusDone, function* workChangeOrder({ payload: { id, orderStatus } }) {
    try {
      yield call(changeOrderStatusToDone, { id, orderStatus });
      yield put(changeOrderStatusDone.success());
      yield put(closeModal({ name: modalNames.createEditOrder }));
    } catch (e) {
      yield put(changeOrderStatusDone.error());
      errorLogger(e);
    }
  });
}
function* getMoreInfoOrderRequest({ id, isReturn }) {
  const url = axiosData.getOrderInfo.url.replace(':id', id);
  const determineUrlReturnOrChange = isReturn ? url.replace('change', 'return') : url;
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getOrderInfo,
    url: determineUrlReturnOrChange,
  });
  return result;
}
function* watchGetMoreInfoOrder() {
  yield takeEvery(getMoreInfoOrder, function* workGetMoreInfoOrder({ payload }) {
    try {
      const data = yield call(getMoreInfoOrderRequest, {
        id: payload.id,
        isReturn: payload.isReturn,
      });

      yield put(getMoreInfoOrder.success({ data }));
    } catch (e) {
      yield put(getMoreInfoOrder.error());
      errorLogger(e);
    }
  });
}
/// //////////////////////////////////////////////////////////////////////////////
function* getBoardListsRequest({ id, archived }) {
  const url = axiosData.getBoardLists.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getBoardLists,
    url,
    // todo limit test Arman
    data: { archived },
  });
  return result;
}

function* getBoardListsNameRequest({ id }) {
  const url = axiosData.getBoardListsName.url.replace(':id', id);

  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getBoardListsName,
    url,
  });
  return result;
}

function* watchGetDraggableTable() {
  yield takeEvery(getDraggableTable, function* workGetDraggableTable({ payload: { id, archived } }) {
    try {
      const res = yield call(getBoardListsRequest, { id, archived });
      const boardName = yield call(getBoardListsNameRequest, { id });
      const data = handleGetListData(res);

      yield put(getDraggableTable.success({ data, boardName }));
    } catch (e) {
      yield put(getDraggableTable.error());
      errorLogger(e);
    }
  });
}

function* moveListRequest({ id, order }) {
  const url = axiosData.moveList.url.replace(':id', id);
  yield call(Axios.request, {
    ...axiosData.moveList,
    url,
    data: {
      order,
    },
  });
}

function* moveLists({ result, state, T }) {
  const { columns, order, id } = getListsWithNewOrdering({
    destIndex: result.destination.index,
    sourceIndex: result.source.index,
    columns: state.columns,
    columnOrder: state.columnOrder,
  });

  const columnOrder = reorderList(state.columnOrder, result.source.index, result.destination.index);

  yield put(
    drag.success({
      ...state,
      columns,
      columnOrder,
    })
  );

  try {
    if (!result.disabled) yield call(moveListRequest, { id, order });
  } catch (e) {
    errorLogger(e);
    yield call(notifyMessage, { body: T.error_something_went_wrong_refresh, timer: 1000 }, T);
  }
}

function* moveListItemInSameListRequest({ id, order }) {
  const url = axiosData.moveListItemInSameList.url.replace(':id', id);
  yield call(Axios.request, {
    ...axiosData.moveListItemInSameList,
    url,
    data: {
      order,
    },
  });
}

function* moveListItemInSameList({ result, state, T }) {
  const column = state.columns[result.source.droppableId];

  const { columns, order, id } = getSameBoardItemsWithNewOrdering({
    destIndex: result.destination.index,
    sourceIndex: result.source.index,
    columns: column.items,
  });

  const newState = {
    ...state,
    columns: {
      ...state.columns,
      [column.id]: {
        ...column,
        items: columns.sort(({ order: first }, { order: sec }) => first - sec),
      },
    },
  };
  yield put(drag.success(newState));

  try {
    yield call(moveListItemInSameListRequest, { id, order });
  } catch (e) {
    errorLogger(e);
    yield call(notifyMessage, { body: T.error_something_went_wrong_refresh, timer: 1000 }, T);
  }
}

function* moveListItemInOtherListRequest({ id, order, listId }) {
  const url = axiosData.moveListItemInOtherList.url.replace(':id', id);
  yield call(Axios.request, { ...axiosData.moveListItemInOtherList, url, data: { order, listId } });
}

function* moveListItemToOtherList({ result, state, T }) {
  const { id, listId, order, newSourceColumn, newDestinationColumn } = getSameOtherItemsWithNewOrdering({
    result,
    state,
  });

  const newState = {
    ...state,
    columns: {
      ...state.columns,
      [newSourceColumn.id]: newSourceColumn,
      [newDestinationColumn.id]: newDestinationColumn,
    },
  };

  yield put(drag.success(newState));

  try {
    yield call(moveListItemInOtherListRequest, { id, order, listId });
  } catch ({
    response: {
      data: { error },
    },
  }) {
    yield put(drag.error());
    yield call(notifyMessage, { body: droppingErrors(T)[error?.message], timer: 4000 }, T);
  }
}

function* handleDrag(result) {
  const lang = yield select(selectLang);
  const T = Lang(lang);
  const { data: state } = yield select(selectDraggableBoard);
  if (!result.destination) return;

  if (result.type === 'column') {
    yield call(moveLists, { state, result, T });
    return;
  }

  if (result.source.droppableId === result.destination.droppableId) {
    yield call(moveListItemInSameList, { result, state, T });
    return;
  }
  yield call(moveListItemToOtherList, { result, state, T });
}

function* watchDrag() {
  yield takeEvery(drag, function* workDrag({ payload: { result, T } }) {
    try {
      yield call(handleDrag, result, T);
    } catch (e) {
      yield put(drag.error());
      errorLogger(e);
    }
  });
}

function* createListRequest(values) {
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.createList,
    data: handleCreateListData(values),
  });
  return result;
}

function* createListHandler(list) {
  const { data: boardData } = yield select(selectDraggableBoard);

  const data = handleCreateList({ list, data: boardData });
  yield put(getDraggableTable.success({ data }));
  yield put(createList.success());
  yield put(createList.reset());
}

function* watchCreateList() {
  yield takeEvery(createList, function* workCreateList({ payload: { values } }) {
    try {
      const list = yield call(createListRequest, values);

      yield call(createListHandler, list);
    } catch (e) {
      errorLogger(e);
      yield put(createList.error());
    }
  });
}

function* getDraggableTableListItemsRequest({ offset, listId, limit }) {
  const { archived } = yield select(selectDraggableBoard);
  const url = axiosData.getDraggableTableListItems.url.replace(':id', listId);
  const { filteredData } = yield select(selectSavedFilter);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getDraggableTableListItems,
    url,
    params: {
      limit: limit || LIST_LIMIT,
      offset,
      ...(archived ? { archived: true } : {}),
      ...filteredData.body,
    },
  });

  return result;
}

function* watchGetDraggableTableListItems() {
  yield takeLatest(
    getDraggableTableListItems,
    function* workGetDraggableTableListItems({ payload: { listId, offset, limit } }) {
      try {
        const res = yield call(getDraggableTableListItemsRequest, {
          listId,
          offset,
          limit,
        });
        yield put(getDraggableTableListItems.success({ listItems: res, id: listId }));
      } catch (e) {
        errorLogger(e);
        yield put(getDraggableTableListItems.error());
      }
    }
  );
}

function* createListItemRequest(values) {
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.createListItem,
    data: handleCreateListItemData(values),
  });
  return result;
}

function* watchCreateListItem() {
  // OK
  yield takeEvery(createListItem, function* workCreateListItem({ payload }) {
    const { T, values, rateData } = payload;

    const { enteredRate, mainPhone } = rateData;
    try {
      const result = yield call(createListItemRequest, values);
      const { data: boardData } = yield select(selectDraggableBoard);
      yield put(closeModal({ name: modalNames.createEditOrder }));
      const data = handleCreateListItem({
        result,
        data: boardData,
        listId: values.list.value,
      });
      if (enteredRate) yield put(addRate({ T, phone: mainPhone, rate: enteredRate }));

      yield put(getDraggableTable.success({ data }));
      yield put(createListItem.success({ clearMessage: '' }));
      yield put(createListItem.reset());
    } catch (e) {
      errorLogger(e);

      const errorMessage = e?.response?.data?.error?.message;
      yield call(
        notifyMessage,
        { body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage, timer: 3000 },
        T
      );
      yield put(createListItem.error(e.response?.data.error.message));
    }
  });
}

function* watchClearListItem() {
  yield takeEvery(clearErrorMessage, function* workClearListItem() {
    try {
      yield put(createListItem.success({ clearMessage: '' }));
    } catch (e) {
      errorLogger(e);
      yield put(createListItem.error(e.response.data.error.message));
    }
  });
}

function* copyListItemRequest({ id, listId }) {
  const url = axiosData.copyListItem.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.copyListItem,
    data: { listId },
    url,
  });
  return result;
}

function* watchCopyListItem() {
  yield takeEvery(copyListItem, function* workCopyListItem({ payload: { id, listId } }) {
    try {
      const result = yield call(copyListItemRequest, { id, listId });

      const { data: boardData } = yield select(selectDraggableBoard);
      const data = handleCopyListItem({
        data: boardData,
        listId,
        listItem: result,
        id,
      });

      yield put(getDraggableTable.success({ data }));

      yield put(copyListItem.success({ id }));
      yield put(copyListItem.reset({ id }));
    } catch (e) {
      errorLogger(e);
      yield put(copyListItem.error({ id }));
    }
  });
}

function* removeListItemRequest({ id }) {
  const url = axiosData.removeListItem.url.replace(':id', id);
  yield call(Axios.request, {
    ...axiosData.removeListItem,
    url,
  });
}

function* watchRemoveListItem() {
  yield takeEvery(removeListItem, function* workRemoveListItem({ payload: { id, listId } }) {
    try {
      yield call(removeListItemRequest, { id });

      const { data } = yield select(selectDraggableBoard);

      const newData = handleRemoveListItem({ data, listId, id });

      yield put(getDraggableTable.success({ data: newData }));

      yield put(removeListItem.success({ id }));
      yield put(removeListItem.reset({ id }));
    } catch (e) {
      errorLogger(e);
      yield put(removeListItem.error({ id }));
    }
  });
}

function* archiveListItemRequest({ id }, archived) {
  const url = `${axiosData.archiveListItem.url.replace(':id', id)}${archived ? '/undo' : ''}`;
  yield call(Axios.request, {
    ...axiosData.archiveListItem,
    url,
  });
}
function* watchArchiveListItem() {
  yield takeEvery(archiveListItem, function* workArchiveListItem({ payload: { id, listId, archived, T } }) {
    // OK
    try {
      yield call(archiveListItemRequest, { id }, archived);

      const { data } = yield select(selectDraggableBoard);

      const newData = handleRemoveListItem({ data, listId, id });

      yield put(getDraggableTable.success({ data: newData }));

      yield put(archiveListItem.success({ id }));
      yield put(archiveListItem.reset({ id }));
    } catch (e) {
      errorLogger(e);
      yield call(
        notifyMessage,
        {
          body: archived ? T.error_something_went_wrong : T.error_something_went_wrong_ask_deliveryman,
          timer: 3000,
        },
        T
      );
      yield put(archiveListItem.error({ id }));
    }
  });
}

function* archiveListRequest({ listId }, archived) {
  const url = `${axiosData.archiveList.url.replace(':id', listId)}${archived ? '/undo' : ''}`;

  yield call(Axios.request, {
    ...axiosData.archiveList,
    url,
  });
}

function* watchArchiveList() {
  yield takeEvery(archiveList, function* workArchiveList({ payload: { listId, archived } }) {
    try {
      yield call(archiveListRequest, { listId }, archived);

      const { data } = yield select(selectDraggableBoard);

      const newData = handleRemoveList({ data, listId });

      yield put(getDraggableTable.success({ data: newData }));

      yield put(archiveList.success({ listId }));
      yield put(archiveList.reset({ listId }));
    } catch (e) {
      errorLogger(e);
      yield put(archiveList.error({ listId }));
    }
  });
}

function* editListItemRequest(values) {
  const url = axiosData.editListItem.url.replace(':id', values.listItemId);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.editListItem,
    data: handleEditListItemData(values),
    url,
  });
  return result;
}

function* editListItemReturnChangeRequest(values) {
  const url = axiosData.editListItemReturnChange.url.replace(':id', values.listItemId);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.editListItem,
    data: handleEditListItemReturnChangeData(values),
    url,
  });

  return result;
}

function* watchEditListItem() {
  yield takeEvery(editListItem, function* workEditListItem({ payload: { values } }) {
    try {
      const request =
        values.orderType === 'return' || values.orderType === 'change'
          ? editListItemReturnChangeRequest
          : editListItemRequest;

      const result = yield call(request, values);

      const { data: boardData } = yield select(selectDraggableBoard);

      yield put(closeModal({ name: modalNames.createEditOrder }));

      if (window?.fbq) {
        fbq('track', 'InitiateCheckout');
      }
      const data = handleEditListItem({
        result,
        data: boardData,
        listId: values.prevListId,
      });
      yield put(getDraggableTable.success({ data }));

      yield put(editListItem.success());
      yield put(editListItem.reset());
    } catch (e) {
      errorLogger(e);
      yield put(editListItem.error());
    }
  });
}

function* getListItemToEditRequest(id) {
  const url = axiosData.getListItemToEdit.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getListItemToEdit,
    url,
  });

  return result;
}

function* watchGetListItemToEdit() {
  yield takeEvery(getListItemToEdit, function* workGetListItemToEdit({ payload: { id } }) {
    try {
      const data = yield call(getListItemToEditRequest, id);
      yield put(getListItemToEdit.success({ data }));
    } catch (e) {
      errorLogger(e);
      yield put(getListItemToEdit.error());
    }
  });
}

function* statusShippingRequestPut({ payload }) {
  const determinedIsState = payload.isState ? 'stateUrl' : 'url';
  const url = `${axiosData.statusShipping[determinedIsState].replace(':id', payload.listItemId)}${
    payload.isWarehouse ? '/warehouse' : ''
  }`;
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.statusShipping,
    url,
    data:
      payload.values.orderStatus === 'callRejected'
        ? {
            orderStatus: payload.values.orderStatus,
            text: payload.values.text,
          }
        : payload.values,
  });
  return result;
}
function* watchStatusShippingRequest() {
  yield takeEvery(statusShippingPut, function* workStatusShippingRequest({ payload: { T, ...payload } }) {
    try {
      const data = yield call(statusShippingRequestPut, { payload });
      // if (window.fbq) {
      //   window.fbq('track', 'Subscribe', { value: '0.00', currency: 'USD', predicted_ltv: '0.00' });
      // }
      yield put(statusShippingPut.success({ data }));
    } catch (e) {
      errorLogger(e);
      const { message: errorMessage } = e.response.data.error;
      yield call(
        notifyMessage,
        {
          body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage,
          timer: 3000,
        },
        T
      );
      yield put(statusShippingPut.error());
    }
  });
}
function* removeListRequest(id) {
  const url = axiosData.removeList.url.replace(':id', id);
  yield call(Axios.request, {
    ...axiosData.removeList,
    url,
  });
}

function* removeListHandler(id) {
  const { data } = yield select(selectDraggableBoard);

  const newData = handleRemoveList({ data, listId: id });

  yield put(getDraggableTable.success({ data: newData }));
  yield put(removeList.success());
  yield put(removeList.reset());
}

function* watchRemoveList() {
  yield takeEvery(removeList, function* workRemoveList({ payload: { id } }) {
    try {
      yield call(removeListRequest, id);

      yield call(removeListHandler, id);
    } catch (e) {
      errorLogger(e);
      yield put(removeList.error());
    }
  });
}
function* putFilteredValueInStoreRequest(values, dateRange) {
  yield put(putFilteredValueInStore.success({ values, dateRange }));
}

function* watchPutFilteredValueInStore() {
  yield takeEvery(putFilteredValueInStore, function* workPutFilteredValueInStore({ payload: { values, dateRange } }) {
    try {
      yield call(putFilteredValueInStoreRequest, values, dateRange);
    } catch (e) {
      errorLogger(e);
      yield put(putFilteredValueInStore.error());
    }
  });
}
function* editListRequest({ id, title }) {
  const url = axiosData.editList.url.replace(':id', id);
  yield call(Axios.request, {
    ...axiosData.editList,
    url,
    data: { name: title },
  });
}

function* watchEditList() {
  // OK
  yield takeEvery(editList, function* workEditList({ payload: { title, key, id, T } }) {
    try {
      yield call(editListRequest, { title, id });
      yield put(editList.success({ key }));

      const { data } = yield select(selectDraggableBoard);

      const newData = changeLocalTitle({ data, title, id });

      yield put(getDraggableTable.success({ data: newData }));
    } catch (e) {
      errorLogger(e);
      yield put(editList.error({ key }));
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
    } finally {
      yield put(editList.reset({ key }));
    }
  });
}

function* resetReturnChangeListItemStatusRequest({ listItemId }) {
  const url = axiosData.resetReturnChangeListItemStatus.url.replace(':id', listItemId);
  yield call(Axios.request, {
    ...axiosData.resetReturnChangeListItemStatus,
    url,
  });
}

function* watchResetReturnChangeListItemStatus() {
  yield takeEvery(
    resetReturnChangeListItemStatus,
    function* workResetReturnChangeListItemStatus({ payload: { listItemId, T } }) {
      try {
        yield call(resetReturnChangeListItemStatusRequest, { listItemId });
        yield put(resetReturnChangeListItemStatus.success());

        yield put(closeModal({ name: modalNames.createEditOrder }));
      } catch (e) {
        errorLogger(e);
        yield put(resetReturnChangeListItemStatus.error());
        yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
        yield put(resetReturnChangeListItemStatus.reset());
      }
    }
  );
}
function* watchDragListItemToOutListMessage() {
  yield takeEvery(
    dragListItemToOutListMessage,
    function* workDragListItemToOutListMessage({
      payload: { listId, listItemId, oldListId, neighborListItemId, store, T, ...payload },
    }) {
      try {
        const { archived } = yield select(selectDraggableBoard);
        const boardId = getGlobalBoardId();
        if (!boardId && payload.boardId !== boardId) {
          return;
        }
        const { filteredValue, dateRange } = yield select(selectPutFilteredValue);
        if (
          filteredValue?.workerId?.length ||
          filteredValue?.domainId?.length ||
          filteredValue?.state?.length ||
          filteredValue?.createdFrom?.length ||
          filteredValue?.date === 'purchase' ||
          filteredValue?.date === 'delivery'
        ) {
          store.dispatch(
            createFilter({
              body: getFilteredTableData(filteredValue, dateRange),
              id: Number(boardId),
              T,
            })
          );
        } else {
          store.dispatch(getDraggableTable({ id: boardId, archived }));
        }
      } catch (e) {
        errorLogger({ e });
      }
    }
  );
}

function* watchMoveItemToArchiveMessage() {
  yield takeEvery(
    moveItemToArchiveMessage,
    function* workMoveItemToArchiveMessage({ payload: { store, T, ...payload } }) {
      try {
        const { archived } = yield select(selectDraggableBoard);
        const boardId = getGlobalBoardId();
        if (!boardId && payload.boardId !== boardId) {
          return;
        }
        const { filteredValue, dateRange } = yield select(selectPutFilteredValue);
        if (
          filteredValue?.workerId?.length ||
          filteredValue?.domainId?.length ||
          filteredValue?.state?.length ||
          filteredValue?.createdFrom?.length ||
          filteredValue?.date === 'purchase' ||
          filteredValue?.date === 'delivery'
        ) {
          store.dispatch(
            createFilter({
              body: getFilteredTableData(filteredValue, dateRange),
              id: Number(boardId),
              T,
            })
          );
        } else {
          store.dispatch(getDraggableTable({ id: boardId, archived }));
        }
      } catch (e) {
        errorLogger({ e });
      }
    }
  );
}

function* watchDeleteItemMessage() {
  yield takeEvery(deleteItemMessage, function* workDeleteItemMessage({ payload: { store, T, ...payload } }) {
    try {
      const { archived } = yield select(selectDraggableBoard);
      const boardId = getGlobalBoardId();
      if (!boardId && payload.boardId !== boardId) {
        return;
      }
      const { filteredValue, dateRange } = yield select(selectPutFilteredValue);
      if (
        filteredValue?.workerId?.length ||
        filteredValue?.domainId?.length ||
        filteredValue?.state?.length ||
        filteredValue?.createdFrom?.length ||
        filteredValue?.date === 'purchase' ||
        filteredValue?.date === 'delivery'
      ) {
        store.dispatch(
          createFilter({
            body: getFilteredTableData(filteredValue, dateRange),
            id: Number(boardId),
            T,
          })
        );
      } else {
        store.dispatch(getDraggableTable({ id: boardId, archived }));
      }
    } catch (e) {
      errorLogger({ e });
    }
  });
}

function* watchReArchiveItemMessage() {
  yield takeEvery(reArchiveItemMessage, function* workReArchiveItemMessage({ payload: { store, T, ...payload } }) {
    try {
      const { archived } = yield select(selectDraggableBoard);
      const boardId = getGlobalBoardId();
      if (!boardId && payload.boardId !== boardId) {
        return;
      }
      const { filteredValue, dateRange } = yield select(selectPutFilteredValue);
      if (
        filteredValue?.workerId?.length ||
        filteredValue?.domainId?.length ||
        filteredValue?.state?.length ||
        filteredValue?.createdFrom?.length ||
        filteredValue?.date === 'purchase' ||
        filteredValue?.date === 'delivery'
      ) {
        store.dispatch(
          createFilter({
            body: getFilteredTableData(filteredValue, dateRange),
            id: Number(boardId),
            T,
          })
        );
      } else {
        store.dispatch(getDraggableTable({ id: boardId, archived }));
      }
    } catch (e) {
      errorLogger({ e });
    }
  });
}
function* watchCopyItemMessage() {
  yield takeEvery(copyItemMessage, function* workCopyItemItemMessage({ payload: { store, T, ...payload } }) {
    try {
      const { archived } = yield select(selectDraggableBoard);
      const boardId = getGlobalBoardId();
      if (!boardId && payload.boardId !== boardId) {
        return;
      }
      const { filteredValue, dateRange } = yield select(selectPutFilteredValue);
      if (
        filteredValue?.workerId?.length ||
        filteredValue?.domainId?.length ||
        filteredValue?.state?.length ||
        filteredValue?.createdFrom?.length ||
        filteredValue?.date === 'purchase' ||
        filteredValue?.date === 'delivery'
      ) {
        store.dispatch(
          createFilter({
            body: getFilteredTableData(filteredValue, dateRange),
            id: Number(boardId),
            T,
          })
        );
      } else {
        store.dispatch(getDraggableTable({ id: boardId, archived }));
      }
    } catch (e) {
      errorLogger({ e });
    }
  });
}

function* watchDragListItemToSameListMessage() {
  yield takeEvery(
    [dragListItemToSameListMessage, createEditOrderMessage],
    function* workDragListItemToSameListMessage({
      payload: { listId, listItemId, neighborListItemId, store, T, ...payload },
    }) {
      try {
        const { archived } = yield select(selectDraggableBoard);
        const boardId = getGlobalBoardId();
        if (!boardId && payload.boardId !== boardId) {
          return;
        }
        const { filteredValue, dateRange } = yield select(selectPutFilteredValue);
        if (
          filteredValue?.workerId?.length ||
          filteredValue?.domainId?.length ||
          filteredValue?.state?.length ||
          filteredValue?.createdFrom?.length ||
          filteredValue?.date === 'purchase' ||
          filteredValue?.date === 'delivery'
        ) {
          store.dispatch(
            createFilter({
              body: getFilteredTableData(filteredValue, dateRange),
              id: Number(boardId),
              T,
            })
          );
        } else {
          store.dispatch(getDraggableTable({ id: boardId, archived }));
        }
      } catch (e) {
        errorLogger({ e });
      }
    }
  );
}

function* watchCreateListMessage() {
  yield takeEvery(createListMessage, function* workCreateListMessage({ payload: { listId, store, T, ...payload } }) {
    try {
      const { archived } = yield select(selectDraggableBoard);
      const boardId = getGlobalBoardId();
      if (!boardId && payload.boardId !== boardId) {
        return;
      }
      const { filteredValue, dateRange } = yield select(selectPutFilteredValue);
      if (
        filteredValue?.workerId?.length ||
        filteredValue?.domainId?.length ||
        filteredValue?.state?.length ||
        filteredValue?.createdFrom?.length ||
        filteredValue?.date === 'purchase' ||
        filteredValue?.date === 'delivery'
      ) {
        store.dispatch(
          createFilter({
            body: getFilteredTableData(filteredValue, dateRange),
            id: Number(boardId),
            T,
          })
        );
      } else {
        store.dispatch(getDraggableTable({ id: boardId, archived }));
      }
    } catch (e) {
      errorLogger(e);
      yield put(createList.error());
    }
  });
}

function* watchRemoveListMessage() {
  yield takeEvery(removeListMessage, function* workRemoveListMessage({ payload: { listId, store, T, ...payload } }) {
    try {
      const { archived } = yield select(selectDraggableBoard);
      const boardId = getGlobalBoardId();
      if (!boardId && payload.boardId !== boardId) {
        return;
      }
      const { filteredValue, dateRange } = yield select(selectPutFilteredValue);
      if (
        filteredValue?.workerId?.length ||
        filteredValue?.domainId?.length ||
        filteredValue?.state?.length ||
        filteredValue?.createdFrom?.length ||
        filteredValue?.date === 'purchase' ||
        filteredValue?.date === 'delivery'
      ) {
        store.dispatch(
          createFilter({
            body: getFilteredTableData(filteredValue, dateRange),
            id: Number(boardId),
            T,
          })
        );
      } else {
        store.dispatch(getDraggableTable({ id: boardId, archived }));
      }
      yield call(removeListHandler, listId);
    } catch (e) {
      errorLogger(e);
      yield put(createList.error());
    }
  });
}

function* watchDragListMessage() {
  yield takeEvery(dragListMessage, function* workDragListMessage({ payload: { listId, order, store, T, ...payload } }) {
    try {
      const { archived } = yield select(selectDraggableBoard);
      const boardId = getGlobalBoardId();
      if (!boardId && payload.boardId !== boardId) {
        return;
      }

      const { data: state } = yield select(selectDraggableBoard);
      const cols = Object.values(state.columns);

      const destItem = cols.find((item) => {
        return item.order === order;
      });

      const destIndex = state.columnOrder.findIndex((id) => +id === +destItem.id);

      const listIndex = state.columnOrder.findIndex((id) => {
        return +id === +listId;
      });
      const { filteredValue, dateRange } = yield select(selectPutFilteredValue);
      console.log(filteredValue, 'filteredValue');
      if (
        filteredValue?.workerId?.length ||
        filteredValue?.domainId?.length ||
        filteredValue?.state?.length ||
        filteredValue?.createdFrom?.length ||
        filteredValue?.date === 'purchase' ||
        filteredValue?.date === 'delivery'
      ) {
        store.dispatch(
          createFilter({
            body: getFilteredTableData(filteredValue, dateRange),
            id: Number(boardId),
            T,
          })
        );
      } else {
        store.dispatch(getDraggableTable({ id: boardId, archived }));
      }
      yield call(handleDrag, {
        type: 'column',
        destination: { index: destIndex },
        source: { index: listIndex },
        disabled: true,
      });
    } catch (e) {
      errorLogger(e);
      yield put(createList.error());
    }
  });
}
function* revertOrderStatusBack({ listItemId }) {
  const url = axiosData.revertStatusApi.url.replace(':id', listItemId);
  yield call(Axios.request, {
    ...axiosData.revertStatusApi,
    url,
  });
}

function* watchRevertOrderStatusBack() {
  // OK
  yield takeEvery(revertStatus, function* workChangeOrderBack({ payload: { listItemId, T } }) {
    try {
      yield call(revertOrderStatusBack, { listItemId });
      yield put(revertStatus.success());
      yield put(closeModal({ name: modalNames.confirmation }));
    } catch (e) {
      yield put(revertStatus.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

function* getBoardNameRequest({ boardId }) {
  const url = axiosData.getBoardListsName.url.replace(':id', boardId);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getBoardListsName,
    url,
  });
  return result;
}

function* watchGetBoardNameRequest() {
  yield takeEvery(getBoardName, function* workGetBoardName({ payload: { boardId } }) {
    try {
      const { name } = yield call(getBoardNameRequest, { boardId });
      yield put(getBoardName.success({ boardName: name }));
    } catch (e) {
      yield put(getBoardName.error());

      errorLogger(e);
    }
  });
}
function* watchSaveListItemIdRequest() {
  yield takeEvery(saveListItemId, function* workSaveListItemId({ payload: { listItemId } }) {
    try {
      yield put(saveListItemId.success({ listItemId }));
    } catch (e) {
      yield put(saveListItemId.error());
      errorLogger(e);
    }
  });
}

function* addLabelRequest({ value, listItemId }) {
  const url = axiosData.addLabel.url.replace(':listItemId', listItemId).replace(':labelId', value);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.addLabel,
    url,
  });
  return result;
}

function* watchAddLabelRequest() {
  yield takeEvery(addLabel, function* workAddLabel({ payload: { value, listItemId } }) {
    try {
      yield call(addLabelRequest, { value, listItemId });
      yield put(addLabel.success());
    } catch (e) {
      yield put(addLabel.error());

      errorLogger(e);
    }
  });
}

function* removeLabelRequest({ value, listItemId }) {
  const url = axiosData.removeLabel.url.replace(':listItemId', listItemId).replace(':labelId', value);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.removeLabel,
    url,
  });
  return result;
}

function* watchRemoveLabelRequest() {
  yield takeEvery(removeLabel, function* workRemoveLabel({ payload: { value, listItemId } }) {
    try {
      yield call(removeLabelRequest, { value, listItemId });
      yield put(removeLabel.success());
    } catch (e) {
      yield put(removeLabel.error());

      errorLogger(e);
    }
  });
}
export default function* draggableTable() {
  yield all([
    watchGetBoardNameRequest(),
    watchRevertOrderStatusBack(),
    watchGetDraggableTable(),
    watchDrag(),
    watchCreateList(),
    watchRemoveList(),
    watchEditList(),
    watchCreateListItem(),
    watchGetListItemToEdit(),
    watchEditListItem(),
    watchCopyListItem(),
    watchRemoveListItem(),
    watchGetDraggableTableListItems(),
    watchArchiveListItem(),
    watchResetReturnChangeListItemStatus(),
    watchArchiveList(),
    watchDragListItemToOutListMessage(),
    watchDragListItemToSameListMessage(),
    watchCreateListMessage(),
    watchRemoveListMessage(),
    watchDragListMessage(),
    watchStatusShippingRequest(),
    watchChangeOrderStatusToDone(),
    watchChangeOrderStatusToBack(),
    watchClearListItem(),
    watchMoveItemToArchiveMessage(),
    watchDeleteItemMessage(),
    watchReArchiveItemMessage(),
    watchCopyItemMessage(),
    watchPutFilteredValueInStore(),
    watchGetMoreInfoOrder(),
    watchSaveListItemIdRequest(),
    watchAddLabelRequest(),
    watchRemoveLabelRequest(),
  ]);
}
