import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getPosts } from './actions';

const getPostsInitialState = {
  error: false,
  loading: false,
  data: [],
};
const getPostsAction = handleActions(
  {
    [getPosts]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),

    [getPosts.success]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      data: payload,
    }),
    [getPosts.error]: (state) => ({ ...state, loading: false, error: true }),
  },
  getPostsInitialState
);

export default combineReducers({ getPostsAction });
