import { all, takeEvery, put, call } from 'redux-saga/effects';
import Axios from '../../services/axios';
import { errorLogger } from '../../helpers/errorLogger';
import { getAllBoards, getAllListsNames, getProductsCount, moveAllItems } from './actions';
import { axiosData } from './requestGenerators';
import { notifyMessage } from '../message';
import { droppingErrors } from '../draggable-board/constants';

function* getAllListsNamesRequest() {
  const { url } = axiosData.getAllListsNames;
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.getAllListsNames, url });

  return result;
}

function* watchGetAllListsNames() {
  yield takeEvery(getAllListsNames, function* workGetAllListsNames() {
    try {
      const response = yield call(getAllListsNamesRequest);
      yield put(
        getAllListsNames.success(
          response.map((el) => {
            return {
              value: el.id,
              label: `${el.name} / ${el?.listBoard.name}`,
            };
          })
        )
      );
    } catch (e) {
      errorLogger(e);
      yield put(getAllListsNames.error());
    }
  });
}
function* getAllBoardsNamesRequest() {
  const { url } = axiosData.getAllBoards;
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.getAllBoards, url });

  return result;
}

function* watchGetAllBoardNames() {
  yield takeEvery(getAllBoards, function* watchGetAllBoardNames() {
    try {
      const response = yield call(getAllBoardsNamesRequest);
      yield put(
        getAllBoards.success(
          response.map((el) => {
            return {
              value: el.id,
              label: `${el.name}`,
            };
          })
        )
      );
    } catch (e) {
      errorLogger(e);
      yield put(getAllBoards.error());
    }
  });
}

function* moveAllItemsRequest(listId) {
  const url = axiosData.moveAllItems.url.replace(':id', listId);
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.moveAllItems, url });

  return result;
}

function* watchMoveAllItems() {
  // OK
  yield takeEvery(moveAllItems, function* workMoveAllItems({ payload }) {
    const { T, listId } = payload;
    try {
      yield call(moveAllItemsRequest, listId);
      yield put(moveAllItems.success());
    } catch (e) {
      errorLogger(e);
      const { message: errorMessage } = e.response.data.error;
      yield call(
        notifyMessage,
        { body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage, timer: 3000 },
        T
      );
      yield put(moveAllItems.error());
    }
  });
}

// getProductsCounts
function* getProductsCountsRequest() {
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.getProductsCount });

  return result;
}

function* watchGetProductsCounts() {
  yield takeEvery(getProductsCount, function* workGetProductsCounts() {
    try {
      const result = yield call(getProductsCountsRequest);
      yield put(getProductsCount.success(result));
    } catch (e) {
      errorLogger(e);
      yield put(getProductsCount.error());
    }
  });
}
export default function* listsNames() {
  yield all([watchGetAllListsNames(), watchGetAllBoardNames(), watchMoveAllItems(), watchGetProductsCounts()]);
}
