import { createCombinedAction } from '../helpers';

export const getWorkers = createCombinedAction({
  name: 'getWorkers',
  prefix: 'workers@@',
});
export const removeWorker = createCombinedAction({
  name: 'removeWorker',
  prefix: 'workers@@',
});
export const getWorkerEdit = createCombinedAction({
  name: 'getWorkerEdit',
  prefix: 'workers@@',
});
export const workerEdit = createCombinedAction({
  name: 'workerEdit',
  prefix: 'workers@@',
});
export const workerCreate = createCombinedAction({
  name: 'workerCreate',
  prefix: 'workers@@',
});
export const resendEmail = createCombinedAction({
  name: 'resendEmail',
  prefix: 'workers@@',
});
export const workerRegistration = createCombinedAction({
  name: 'workerRegistration',
  prefix: 'workers@@',
});
export const selectWorker = createCombinedAction({ name: 'selectWorker', prefix: 'workers@@' });
export const payWorkerSalary = createCombinedAction({
  name: 'payWorkerSalary',
  prefix: 'workers@@',
});

export const getWorkerSalary = createCombinedAction({
  name: 'getWorkerSalary',
  prefix: 'workers@@',
});
export const checkOrderEditibality = createCombinedAction({
  name: 'checkOrderEditibality',
  prefix: 'workers@@',
});

export const removeEditibality = createCombinedAction({
  name: 'removeOrderEditibality',
  prefix: 'workers@@',
});
