import React, { useCallback, useState } from 'react';

import ReactDatePicker from 'react-datepicker';

import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = ({ onChange, ...rest }) => {
  const [startDate, setStartDate] = useState(null);
  const handleChange = useCallback(
    (date) => {
      setStartDate(date);
      onChange(date);
    },
    [onChange]
  );

  return <ReactDatePicker selected={startDate} {...rest} onChange={handleChange} />;
};

DatePicker.propTypes = {
  defaultDate: PropTypes.any,
  onChange: PropTypes.func,
};

export default DatePicker;
