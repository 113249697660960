import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from './pageHeader.module.scss';
import RightSvg from '../../../../svgs/rightArrow';
import { routes } from '../../../../routes';
import { selectGetProductEdit } from '../../../../store/products/selectors';
import { defaultProductPicture } from '../../../../constants/constants';
import { selectLang } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';

const PageHeader = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const { data } = useSelector(selectGetProductEdit);

  const history = useHistory();
  const { search } = useLocation();

  const goBack = () => history.push(routes.productBuying);

  const periods = {
    today: T.Today,
    yesterday: T.Yesterday,
    currentWeek: T.CurrentWeek,
    currentMonth: T.CurrentMonth,
    currentYear: T.CurrentYear,
    previousWeek: T.PreviousWeek,
    previousMonth: T.PreviousMonth,
    previousYear: T.PreviousYear,
  };

  const period = useMemo(() => {
    const searchParam = new URLSearchParams(search);
    const periodParam = searchParam.get('period');
    const startDate = searchParam.get('startDate')?.split('-');
    const endDate = searchParam.get('endDate')?.split('-');
    if (periodParam === 'custom') {
      return `${startDate[2]}.${startDate[1]}.${startDate[0]} - ${endDate[2]}.${endDate[1]}.${endDate[0]}`;
    }
    return periodParam || 'currentWeek';
  }, [search]);

  const { id, name, type, photos = [{}] } = data;
  const { url } = photos[0];

  return (
    <div className={classNames.pageHeader}>
      <div className={classNames.header}>
        <div className={classNames.backButton} onClick={goBack}>
          <RightSvg />
        </div>
        {T.productBuying}
      </div>
      <div className={classNames.productInfo}>
        <div className={classNames.imageWrapper}>
          <div className={classNames.picturePopUpContainer}>
            <img
              width={34}
              height={34}
              className={classNames.image}
              src={
                url && url !== '/productPictures/null'
                  ? `${process.env.REACT_APP_BASE_ENDPOINT}${url}`
                  : defaultProductPicture
              }
              alt="img"
            />
            <div className={classNames.picturePopUp}>
              <p>
                <img
                  src={
                    url && url !== '/productPictures/null'
                      ? `${process.env.REACT_APP_BASE_ENDPOINT}${url}`
                      : defaultProductPicture
                  }
                  alt="img"
                />
              </p>
            </div>
          </div>
        </div>
        <div className={classNames.details}>
          {id} | {name} | {periods[period] || period} | {type === 'real' ? T.real : T.test}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
