import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from '../../states.module.scss';
import MoreInfo from '../moreInfo';
import { colors } from './constants';
import { openModal } from '../../../../store/modals/actions';
import { modalNames } from '../../../../store/modals/constants';
import { revertStatus } from '../../../../store/draggable-board/actions';
import { toUpperCaseFirstLatter } from '../../../../helpers/barcode';
import SmallCopySvg from '../../../../svgs/SmallCopySvg';
import { Lang } from '../../../../T';
import { selectLang } from '../../../../store/user/selectors';

const StatesRow = ({
  address,
  barcode,
  city,
  orderItems,
  orderStatus,
  state,
  createdAt,
  totalPrice,
  loading,
  listItemId,
}) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const IS_YEREVAN = process.env.REACT_APP_LANG === 'true';

  const statuses = {
    done: T.approved,
    rejected: T.rejected,
    callRejected: T.rejected,
    null: T.waiting,
  };
  const [visible, setVisible] = useState(false);
  const [copied, setCopied] = useState('black');
  const handleVisible = () => {
    setVisible((prev) => !prev);
  };
  const dispatch = useDispatch();
  const handlerDoneModal = useCallback(
    (data) => {
      dispatch(
        openModal({
          name: modalNames.SaleModalsDone,
          data: { data, id: listItemId },
        })
      );
    },
    [listItemId]
  );
  const handlerRejectModal = useCallback(
    (data) => {
      dispatch(
        openModal({
          name: modalNames.SaleModalsReject,
          data: { data, id: listItemId, state },
        })
      );
    },
    [listItemId]
  );
  const openConfirmModal = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        openModal({
          name: modalNames.confirmation,
          data: {
            title: T.reverse_to_the_previous_state,
            description: T.are_you_sure_to_prev_state,
            okText: T.approve,
            onOk: () => {
              dispatch(revertStatus({ listItemId, T }));
            },
          },
        })
      );
    },
    [listItemId]
  );

  const hasMoreProduct = orderItems.length > 1;
  return (
    <div className={classNames.productsTableItem} key={1} onClick={handleVisible}>
      <div className={classNames.producstTableRoW}>
        <div className={`${classNames.productCol} ${classNames.productNameImage}`}>
          <div className={classNames.productNameCodeWrapper}>
            <p>
              <button
                className={`${classNames.copyItem} ${copied !== 'black' ? classNames.copeid : ''}`}
                style={{
                  color: copied,
                }}
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(toUpperCaseFirstLatter(barcode));
                  setCopied('#397ae8');
                  setTimeout(() => setCopied('black'), 3000);
                }}
              >
                <SmallCopySvg />
                {toUpperCaseFirstLatter(barcode)} &nbsp;
              </button>
            </p>
            <div className={classNames.salaryContainer}>
              <div className={classNames.nameImage}>
                <p style={{ color: hasMoreProduct ? 'blue' : '' }}>
                  {hasMoreProduct ? (
                    <span
                      style={{
                        color: hasMoreProduct ? 'blue' : '',
                        cursor: 'pointer',
                        whiteSpace: 'nowrap',
                        textDecoration: 'underline',
                      }}
                    >
                      {T.show_more}
                    </span>
                  ) : (
                    orderItems[0].name
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${classNames.productCol} ${classNames.productType}`}>
          <div>
            <p>
              {state},{city},{address}
            </p>
          </div>
        </div>
        <div className={`${classNames.productCol} ${classNames.productDate}`}>
          <div>
            <p>{createdAt.split(' ')[0]}</p>
          </div>
        </div>
        <div className={`${classNames.productCol} ${classNames.productCode}`}>
          <div>
            <p
              style={{
                color: hasMoreProduct ? 'blue' : '',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              {hasMoreProduct ? T.more : orderItems[0].count}
            </p>
          </div>
        </div>
        <div className={`${classNames.productCol} ${classNames.productWarehouse}`}>
          <div>
            <p>
              {totalPrice}
              {IS_YEREVAN ? T.money_type_am : T.money_type}
            </p>
          </div>
        </div>
        <div className={`${classNames.productCol} ${classNames.productSize}`}>
          <div>
            <p style={{ color: colors[orderStatus] }}>{statuses[orderStatus]}</p>
          </div>
        </div>
        <div className={`${classNames.productCol} ${classNames.productPrice}`}>
          {orderStatus === null ? (
            <div className="d-flex">
              <button
                type="button"
                className={classNames.acceptButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handlerDoneModal('done');
                }}
              >
                {T.approve}
              </button>
              <button
                type="button"
                className={classNames.declineButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handlerRejectModal('rejected');
                }}
              >
                {T.reject}
              </button>
            </div>
          ) : (
            <span
              onClick={openConfirmModal}
              style={{ cursor: 'pointer', whiteSpace: 'nowrap', textDecoration: 'underline' }}
            >
              {T.change_status}
            </span>
          )}
        </div>
      </div>
      {visible && hasMoreProduct && <MoreInfo id={2} orderItems={orderItems} loading={loading} />}
    </div>
  );
};

export default StatesRow;

StatesRow.propTypes = {
  address: PropTypes.string,
  barcode: PropTypes.string,
  city: PropTypes.string,
  orderItems: PropTypes.array,
  orderStatus: PropTypes.string,
  state: PropTypes.string,
  createdAt: PropTypes.string,
  totalPrice: PropTypes.string,
  loading: PropTypes.bool,
  listItemId: PropTypes.number,
};
