import React from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import Dropdown from '../../../../../../components/dropdown/Dropdown';
import CloseSvg from '../../../../../../svgs/CloseSvg';
import { selectLang, selectUserRole } from '../../../../../../store/user/selectors';
import Options from '../../../../../../components/card/components/edit/components/options/Options';
import classNames from './listEditDropdown.module.scss';
import { Lang } from '../../../../../../T';

const ListEditDropdown = ({
  handleRemoveList,
  handleArchiveList,
  handleFilterList,
  archived,
  card = false,
  cardInfo = {},
  listType = '',
  loading = false,
  changeContent,
  setChangeContent,
}) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const role = useSelector(selectUserRole);
  return !card && (role === 'admin' || role === 'director') ? (
    <Dropdown
      listItemClass={` ColumnHeaderDropdwon ${card ? classNames.cardHeaderDropDown : ''}`}
      button={
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="26" viewBox="0 0 6 26">
          <g transform="translate(-277 -102)">
            <circle
              className="circle cirlce_1"
              cx="3"
              cy="3"
              r="3"
              transform="translate(277 102)"
              fill={card ? '#96a896' : '#fff'}
            />
            <circle
              className="circle cirlce_2"
              cx="3"
              cy="3"
              r="3"
              transform="translate(277 112)"
              fill={card ? '#96a896' : '#fff'}
            />
            <circle
              className="circle cirlce_3"
              cx="3"
              cy="3"
              r="3"
              transform="translate(277 122)"
              fill={card ? '#96a896' : '#fff'}
            />
          </g>
        </svg>
      }
    >
      <ul className="list-unstyled dropdownList">
        <div className="title">
          {T.actions}
          <div className="closeButton" onClick={() => {}}>
            <CloseSvg />
          </div>
        </div>
        {(listType === 'other' || listType === 'deliveryMan') && !loading ? (
          <li role="presentation" onClick={handleArchiveList}>
            {!archived ? T.move_to_archive : T.remove_from_archive}
          </li>
        ) : null}
        <li role="presentation" onClick={handleFilterList}>
          {T.filter}
        </li>
        {(role === 'admin' || role === 'director') && listType === 'newOrder' && (
          <li
            role="presentation"
            onClick={() => {
              setChangeContent((prev) => !prev);
            }}
          >
            {!changeContent ? T.to_count : T.cancel_count}
          </li>
        )}
        {role === 'admin' && (
          <li role="presentation" onClick={handleRemoveList}>
            {T.delete}
          </li>
        )}
      </ul>
    </Dropdown>
  ) : (
    <Dropdown
      listItemClass={` ColumnHeaderDropdwon ${card ? classNames.cardHeaderDropDown : ''}`}
      button={
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="26" viewBox="0 0 6 26">
          <g transform="translate(-277 -102)">
            <circle
              className="circle cirlce_1"
              cx="3"
              cy="3"
              r="3"
              transform="translate(277 102)"
              fill={card ? '#96a896' : '#fff'}
            />
            <circle
              className="circle cirlce_2"
              cx="3"
              cy="3"
              r="3"
              transform="translate(277 112)"
              fill={card ? '#96a896' : '#fff'}
            />
            <circle
              className="circle cirlce_3"
              cx="3"
              cy="3"
              r="3"
              transform="translate(277 122)"
              fill={card ? '#96a896' : '#fff'}
            />
          </g>
        </svg>
      }
    >
      <Options
        card={card}
        item={cardInfo.item}
        listId={cardInfo.listId}
        setShowMenu={cardInfo.setShowMenu}
        setChangeContent={setChangeContent}
        changeContent={changeContent}
        listType={listType}
        loading={loading}
        handleFilterList={handleFilterList}
      />
    </Dropdown>
  );
};

ListEditDropdown.propTypes = {
  handleArchiveList: PropTypes.func,
  handleRemoveList: PropTypes.func,
  handleFilterList: PropTypes.func,
  archived: PropTypes.bool,
  card: PropTypes.bool,
  cardInfo: PropTypes.object,
  listType: PropTypes.string,
  loading: PropTypes.bool,
  changeContent: PropTypes.bool,
  setChangeContent: PropTypes.func,
};
export default ListEditDropdown;
