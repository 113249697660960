import React from 'react';
import { useSelector } from 'react-redux';
import classNames from './notFoundPage.module.scss';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';

const NotFoundPage = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  return (
    <div className={`${classNames.notFoundPage} d-flex justify-content-center align-items-center h-100 w-100`}>
      <div className={classNames.notFound}>{T.page_not_found} :(</div>
    </div>
  );
};

export default NotFoundPage;
