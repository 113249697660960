import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/user/actions';
import { selectLoginLoading } from '../../store/user/selectors';

export const useAuthentication = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoginLoading);

  const [isVisible, setIVisible] = useState(false);

  const handleVisible = () => {
    setIVisible((prev) => !prev);
  };

  const handleSubmit = useCallback((values) => {
    dispatch(login({ values }));
  }, []);

  return { handleSubmit, loading, isVisible, handleVisible };
};
