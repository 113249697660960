import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../store/modals/actions';
import { modalNames } from '../store/modals/constants';
import { Lang } from '../T';
import { selectLang } from '../store/user/selectors';

const useComments = (productPicture) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const dispatch = useDispatch();
  const openCommentModal = ({ target }, rowData) => {
    const { dataset } = target;
    dispatch(
      openModal({
        name: modalNames.comments,
        title: T.comment,
        data: { id: dataset.id, product: rowData, productPicture },
      })
    );
  };
  return { openCommentModal };
};

export default useComments;
