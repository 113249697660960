import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { Droppable } from 'react-beautiful-dnd';
import { Virtuoso } from 'react-virtuoso';
import { useDispatch } from 'react-redux';
import Item from './components/item/Item';
import Row from './components/row/Row';
import { cheatTimerCall } from './helpers';
import { ColumnContext } from './context';
import { getDraggableTableListItems } from '../../../../../../store/draggable-board/actions';
import HeightPreservingItem from './components/height-item/HeightItem';
import './stiles.scss';

// Virtuoso's resize observer can this error,
// which is caught by DnD and aborts dragging.
window.addEventListener('error', (e) => {
  if (
    e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
    e.message === 'ResizeObserver loop limit exceeded'
  ) {
    e.stopImmediatePropagation();
  }
});
const ItemList = ({ column, index, openEdit, showMenu, setShowMenu, listId, archived, listType }) => {
  // There is an issue I have noticed with react-window that when reordered
  // react-window sets the scroll back to 0 but does not update the UI
  // I should raise an issue for this.
  // As a work around I am resetting the scroll to 0
  // on any list that changes it's index
  const listRef = useRef();
  useLayoutEffect(() => {
    const list = listRef?.current;
    if (list) {
      list.scrollTo(0);
    }
  }, [index]);

  const dispatch = useDispatch();

  const handleGetMoreData = useCallback(({ listId, offset, limit, key }) => {
    cheatTimerCall(
      () => {
        dispatch(getDraggableTableListItems({ listId, offset, limit }));
      },
      100,
      { limit, listId, key, offset }
    );
  }, []);
  const content = useMemo(() => ({ column, handleGetMoreData }), [column, handleGetMoreData]);
  return (
    <ColumnContext.Provider value={content}>
      <Droppable
        isDropDisabled={archived || listType === 'state3' || listType === 'deArchived'}
        droppableId={`${column.id}`}
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => (
          <Item
            provided={provided}
            isDragging={snapshot.isDragging}
            item={column.items[rubric.source.index]}
            column={column}
          />
        )}
      >
        {(provided, snapshot) => {
          const newItems = Array.from(
            {
              length: snapshot.isUsingPlaceholder
                ? +column.quantity?.count || column.items?.length
                : +column.quantity?.count || column.items?.length,
            },
            () => ({})
          );

          return (
            <Virtuoso
              components={{
                Item: HeightPreservingItem,
              }}
              scrollerRef={provided.innerRef}
              data={newItems}
              itemContent={(index) => {
                return (
                  <Row
                    index={index}
                    key={column.items[index]?.id || index}
                    data={column.items}
                    openEdit={openEdit}
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    listId={listId}
                    listType={listType}
                  />
                );
              }}
            />
          );
        }}
      </Droppable>
    </ColumnContext.Provider>
  );
};

ItemList.propTypes = {
  column: PropTypes.object,
  index: PropTypes.number,
  openEdit: PropTypes.func,
  showMenu: PropTypes.bool,
  setShowMenu: PropTypes.func,
  listId: PropTypes.string,
  archived: PropTypes.bool,
  listType: PropTypes.string,
};

export default React.memo(ItemList);
