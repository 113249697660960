export const objectToQuery = (obj = {}) => {
  const result = Object.keys(obj)
    .map((key) => {
      if (obj[key]) {
        return `${key}=${obj[key]}`;
      }
    })
    .filter((item) => !!item)
    .join('&');
  return result;
};
