export const axiosData = {
  getProducts: {
    url: '/api/product',
    method: 'get',
  },
  createProduct: {
    url: '/api/product',
    method: 'post',
  },
  changeStatus: {
    url: '/api/product/status/change/:id',
    method: 'put',
  },
  getEditProduct: {
    url: '/api/product/:id',
    method: 'get',
  },

  editProduct: {
    url: '/api/product/:id',
    method: 'put',
  },
  removeProduct: {
    url: '/api/product/:id',
    method: 'delete',
  },
  getAllStates: {
    url: '/api/users/board/state/orders?boardId=id',
    method: 'get',
  },
  getProductSummary: {
    url: '/api/admin/state/orders/summary?boardId=id&limit=20',
    method: 'get',
  },
  sendSummary: {
    url: '/api/admin/state/orders/accept',
    method: 'post',
  },
};
