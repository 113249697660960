import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from '../../helpers/debounce';
import { getProducts } from '../../store/products/actions';
import { selectProducts } from '../../store/products/selectors';
import {
  getAnaliticDomains,
  getPresetByNameRequest,
  getPresetListRequest,
  getProductUrls,
  getStatistic,
  getUtmByType,
} from './helpers';
import useConstant from './constants';
import { selectLang, selectUserRole } from '../../store/user/selectors';
import { Lang } from '../../T';

const useSpecial = () => {
  const dispatch = useDispatch();
  const { dateOption, deviceOptions, initialFilter, periodOptions, regionOptions } = useConstant();

  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const role = useSelector(selectUserRole);
  const products = useSelector(selectProducts);

  const [utmKey, setUtmKey] = useState(null);
  const [utmPage, setUtmPage] = useState(1);
  const [utmValueList, setUtmValueList] = useState([]);
  const [utmValueCount, setUtmValueCount] = useState(null);

  const [preset, setPreset] = useState(null);
  const [presetList, setPresetList] = useState([]);

  const [data, setData] = useState({});

  const [filter, setFilter] = useState(initialFilter);

  const [search, setSearch] = useState('');
  const [urlSearch, setUrlSearch] = useState('');

  const [domainOptions, setDomainOptions] = useState([]);
  const [urlsOptions, setUrlOptions] = useState([]);

  const handleUtmChange = (e) => {
    setFilter((prev) => ({ ...prev, utm: e }));
  };

  const handleChooseUtmKey = (newKey) => {
    setUtmPage(1);
    setUtmKey(newKey);
  };

  const handlePeriodChange = (e) => {
    setFilter((prev) => {
      const newState = { ...prev };
      if (e.length === 2) {
        const [startDate, endDate] = e;
        newState.startDate = startDate;
        newState.endDate = endDate;
        newState.period = {
          value: 'custom',
          label: `${e[0].toLocaleString('ru', dateOption)} - ${e[1].toLocaleString('ru', dateOption)}`,
        };
      } else {
        newState.period = e;
        newState.startDate = null;
        newState.endDate = null;
      }
      return newState;
    });
  };

  const handleRegionChange = (e) => setFilter((prev) => ({ ...prev, region: e }));

  const handleDeviceChange = (e) => setFilter((prev) => ({ ...prev, device: e }));

  const handleDomainChange = (e) => setFilter((prev) => ({ ...prev, domainIds: e }));

  const handlePercentageTypeChange = (e) =>
    setFilter((prev) => ({
      ...prev,
      percentageType: e,
      ignoreOutOfInterval: e.value === 'cr' ? true : prev.ignoreOutOfInterval,
    }));

  const handleUrlChange = (e) => setFilter((prev) => ({ ...prev, urls: e }));

  const handleUrlSearch = debounce((value) => setUrlSearch(value), 500);

  const handleSearch = debounce((value) => setSearch(value), 500);

  const handleReset = () => {
    setFilter(initialFilter);
    setPreset(null);
  };

  const getPresetByName = async () => {
    const { data } = await getPresetByNameRequest(preset);
    setFilter({
      ...data,
      startDate: data?.startDate ? new Date(data?.startDate) : null,
      endDate: data?.endDate ? new Date(data?.endDate) : null,
      percentageType: data?.percentageType || initialFilter.percentageType,
    });
  };

  const getUTM = async (type) => {
    const data = await getUtmByType(type);

    setUtmValueList(data?.result?.map((item) => ({ label: item, value: item })));
    setUtmValueCount(data?.count);
  };

  const getMoreUtm = async (scrollTop, offsetHeight, scrollHeight) => {
    if (utmValueList.length < utmValueCount && scrollTop + offsetHeight + 5 > scrollHeight) {
      setUtmPage((prev) => prev + 1);
      const data = await getUtmByType(utmKey, utmPage + 1);
      setUtmValueList((prev) => [...prev, ...data?.result?.map((item) => ({ label: item, value: item }))]);
    }
  };

  // const onResetTime = () => setFilter((prev) => ({ ...prev, startDate: null, endDate: null }));

  const productOptions = useMemo(
    () =>
      products?.data?.rows?.map((product) => ({
        label: `${product.name}${product.barcode ? ` - ${product.barcode}` : ''}`,
        value: product.id,
      })) || [],
    [products]
  );

  const isResetDisabled = useMemo(() => {
    const {
      period,
      productIds,
      ignoreOutOfInterval,
      startTime,
      endTime,
      startDate,
      endDate,
      domainIds,
      urls,
      region,
      device,
      utm,
    } = filter;
    return !(
      period.value !== periodOptions[2].value ||
      region.value !== regionOptions[0].value ||
      device.value !== deviceOptions[0].value ||
      !!productIds.length ||
      startDate !== null ||
      endDate !== null ||
      startTime !== null ||
      endTime !== null ||
      ignoreOutOfInterval ||
      !!urls.length ||
      !!domainIds.length ||
      !!utm.length
    );
  }, [filter]);

  useEffect(() => {
    if (utmKey !== null) {
      getUTM(utmKey);
    }
  }, [utmKey]);

  useEffect(() => {
    dispatch(getProducts({ searchWord: search, limit: 50, T }));

    return () => {
      dispatch(getProducts.reset());
    };
  }, [search]);

  useEffect(() => {
    const localFilters = JSON.parse(localStorage.getItem('filter') || '{}');
    if (localFilters.period) {
      const startDate = localFilters?.startDate ? new Date(localFilters?.startDate) : null;
      const endDate = localFilters?.endDate ? new Date(localFilters?.endDate) : null;

      setFilter({ ...localFilters, startDate, endDate });
      localStorage.removeItem('filter');
    }
    if (role !== 'accountant') {
      getAnaliticDomains(setDomainOptions);
    }
    getPresetListRequest(setPresetList);
  }, []);

  useEffect(() => {
    if (preset) {
      localStorage.setItem('preset', preset);
      getPresetByName();
    }
  }, [preset]);

  useEffect(() => {
    if (role !== 'accountant') {
      getProductUrls(setUrlOptions, filter.domainIds, urlSearch);
    }
  }, [filter.domainIds, urlSearch]);

  useEffect(() => {
    getStatistic({ ...filter, isAccountant: role === 'accountant' }, setData);

    localStorage.setItem('filters', JSON.stringify(filter));
  }, [filter]);

  return {
    filter,
    setFilter,
    handlePeriodChange,
    data,
    handleSearch,
    productOptions,
    // startTime,
    // setStartTime,
    // endTime,
    // setEndTime,
    // onResetTime,
    domainOptions,
    handleDomainChange,
    urlsOptions,
    handleUrlChange,
    handleUrlSearch,
    handleRegionChange,
    handleDeviceChange,
    handleReset,
    isResetDisabled,
    preset,
    setPreset,
    presetList,
    setPresetList,
    handleChooseUtmKey,
    handleUtmChange,
    handlePercentageTypeChange,
    utmValueList,
    getMoreUtm,
  };
};

export default useSpecial;
