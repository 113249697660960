import React from 'react';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import { initialValues } from './constants';
import { validationSchema } from './validationSchema';
import { useDomain } from './useDomain';
import Button from '../../components/button/Button';
import classNames from './domain.module.scss';
// eslint-disable-next-line import/no-cycle
import { useApp } from '../../useApp';
import DeleteIconSvg from '../../svgs/DeleteIconSvg';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';

const Domain = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { handleSubmit, createStatus, data, disableDelete, handleDeleteDomain } = useDomain();
  const { role } = useApp();
  return (
    <div className="container maxw-100">
      <div className={classNames.DomainSection}>
        {(role === 'admin' || role === 'chiefOperator') && (
          <>
            <div className={classNames.domainHeader}>
              <h2 className="title">{T.add_domain}</h2>
            </div>
          </>
        )}
        <div className={classNames.DomainsContainer}>
          <div className={classNames.DomainContentBig}>
            <Formik initialValues={initialValues} validationSchema={validationSchema(T)} onSubmit={handleSubmit}>
              <Form>
                <div>
                  <div className={classNames.DomainAdder}>
                    <Field type="text" name="name" placeholder={T.domain} />
                    <Button className="btn btn-primary" type="submit" loading={createStatus.loading}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="57" viewBox="0 0 26 57">
                        <text
                          transform="translate(0 42)"
                          fill="#fff"
                          fontSize="42"
                          fontFamily="NunitoSans-Regular, Nunito Sans"
                        >
                          <tspan x="0" y="0">
                            +
                          </tspan>
                        </text>
                      </svg>
                    </Button>
                  </div>
                  <ErrorMessage className="text-red" name="email" component="div" />
                </div>
              </Form>
            </Formik>
          </div>
          <div className={classNames.DomainsContent}>
            {data?.map(({ name, id }) => {
              return (
                <div
                  key={id}
                  className={`${classNames.DomainsItem} d-flex justify-content-between align-items-center flex-grow-1`}
                >
                  <p>{name}</p>
                  {(role === 'admin' || role === 'chiefOperator') && (
                    <div>
                      <button
                        type="button"
                        className="btn p-0"
                        onClick={() => handleDeleteDomain(id)}
                        disabled={disableDelete}
                      >
                        <DeleteIconSvg />
                      </button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Domain;
