import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { openModal } from '../../../../../../../store/modals/actions';
import { modalNames } from '../../../../../../../store/modals/constants';
import { logout } from '../../../../../../../store/user/actions';
import { selectUser } from '../../../../../../../store/user/selectors';
import { selectDraggableBoard } from '../../../../../../../store/draggable-board/selector';
import { toggleArchive } from '../../../../../../../store/draggable-board/actions';
import { routes } from '../../../../../../../routes';

export const useSettings = () => {
  const history = useHistory();
  const { firstName, lastName, profilePicture, salary, percent } = useSelector(selectUser);
  const { id } = useParams();
  const { archived } = useSelector(selectDraggableBoard);

  const boardPage = useMemo(() => {
    return history.location.pathname.includes('board') && id;
  }, [history.location.pathname, id]);

  const dispatch = useDispatch();

  const openCreateEditStickersModal = useCallback(() => {
    dispatch(openModal({ name: modalNames.createEditStickers, data: { boardId: id } }));
  }, [id]);

  const openEditUserProfileSettingsModal = useCallback(() => {
    dispatch(openModal({ name: modalNames.editUserProfileSettings }));
  }, [id]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
    history.push(routes.authentication);
  }, []);

  const toggleArchivedData = () => {
    dispatch(toggleArchive());
  };

  return {
    handleLogout,
    openCreateEditStickersModal,
    boardPage,
    openEditUserProfileSettingsModal,
    profilePicture,
    archived,
    toggleArchivedData,
    firstName,
    lastName,
    salary,
    percent,
  };
};
