import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import ItemList from './components/item-list/ItemList';
import ListEditDropdown from './components/list-edit-dropdown/ListEditDropdown';
import EditableTitle from './components/editable-title/EditableTitle';
import { useColumn } from './useColumn';
import AddOrder from './components/add-order/AddOrder';
import { Lang } from '../../../../T';
// eslint-disable-next-line import/no-cycle
import AddProductDeliveryman from '../add-product-deliveryman/AddProductDeliveryman';
import Edit from '../../../../components/card/components/edit/Edit';
// eslint-disable-next-line import/no-cycle
import Download from '../download-move-btn/download';
// eslint-disable-next-line import/no-cycle
import Move from '../download-move-btn/move';
import { selectLang, selectUserRole } from '../../../../store/user/selectors';

const Column = ({ column, index, changeContent, additionalInfo = {}, isOpenFilter, setChangeContent }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const IS_YEREVAN = process.env.REACT_APP_LANG === 'true';

  const role = useSelector(selectUserRole);
  const [columnId, setColumnId] = useState('');
  const { listType } = column;
  const {
    handleFilterList,
    handleRemoveList,
    deliveryList,
    handleArchiveList,
    loading,
    archived,
    openEdit,
    showMenu,
    setShowMenu,
    item,
    top,
    id,
    stateColumn,
    stateColumnFix,
  } = useColumn({
    column,
  });

  useEffect(() => {
    if (columnId) {
      handleFilterList(columnId);
    }
  }, [isOpenFilter]);
  return (
    <Draggable draggableId={`${column.id}_col`} index={index}>
      {(provided) => (
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        <div
          className="columnParent"
          onMouseMove={() => {
            if (column.items.length) {
              setColumnId(column.id);
            } else {
              setColumnId('');
            }
          }}
          onMouseOut={() => {
            setColumnId('');
          }}
        >
          <div className="column" {...provided.draggableProps} ref={provided.innerRef}>
            <h3
              className="column-title d-flex justify-content-between"
              {...provided.dragHandleProps}
              style={{
                background: deliveryList ? '#718191' : stateColumnFix ? '#546062FF' : undefined,
              }}
            >
              <EditableTitle
                title={changeContent ? `${column.title} (${additionalInfo.count || '__'})` : column.title}
                id={column.id}
                archived={archived}
              />

              <ListEditDropdown
                handleRemoveList={handleRemoveList}
                handleArchiveList={handleArchiveList}
                handleFilterList={() => handleFilterList(column.id)}
                archived={archived}
                listType={listType}
                loading={loading}
                setChangeContent={setChangeContent}
                changeContent={changeContent}
              />

              {changeContent && (role === 'director' || role === 'admin') && (
                <p className="total_price">
                  {additionalInfo.sum
                    ? `${additionalInfo.sum} ${IS_YEREVAN ? T.money_type_am : T.money_type}`
                    : '______'}
                </p>
              )}
            </h3>
            {!changeContent && (
              <>
                <ItemList
                  column={column}
                  index={index}
                  openEdit={openEdit}
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                  listId={column.id}
                  listType={column.listType}
                  archived={archived}
                />
                {!deliveryList && (
                  <AddOrder id={column.id} name={column.title} deliveryList={deliveryList} listType={listType} />
                )}

                {deliveryList && <AddProductDeliveryman userId={column.userId} boardId={id} listId={column.id} />}
                {stateColumn && (
                  <Move userId={column.userId} boardId={id} listId={column.id} disable={!column?.listItems.length} />
                )}
                {stateColumn && (
                  <Download
                    userId={column.userId}
                    boardId={id}
                    listId={column.id}
                    disable={!column?.listItems.length}
                  />
                )}
              </>
            )}
            {showMenu && (
              <div className="editMenu" style={{ top: `${top - 125}px` }}>
                <Edit item={item} listId={column.id} active={showMenu} setShowMenu={setShowMenu} />
              </div>
            )}
          </div>
          {showMenu && (
            <div
              className="shadow"
              onClick={() => {
                setShowMenu(false);
              }}
            />
          )}
        </div>
      )}
    </Draggable>
  );
};

Column.propTypes = {
  isOpenFilter: PropTypes.bool,
  column: PropTypes.object,
  index: PropTypes.number,
  changeContent: PropTypes.bool,
  additionalInfo: PropTypes.object,
  setChangeContent: PropTypes.func,
};
export default React.memo(Column);

//   , (prev, next) => {
//   return prev?.column?.items?.length === next?.column?.items?.length;
// });
