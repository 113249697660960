import { all, takeEvery, put, call } from 'redux-saga/effects';
import { createComment, getComments } from './actions';
import { errorLogger } from '../../helpers/errorLogger';
import Axios from '../../services/axios';
import { axiosData } from './requestGenerators';
import { createCommentDataHandler } from './helpers';

function* createCommentRequest(values, id) {
  const newUrl = axiosData.createComment.url.replace(':id', id);
  const res = yield call(Axios.request, {
    ...axiosData.createComment,
    url: newUrl,
    data: createCommentDataHandler(values),
  });
  return res;
}
function* watchCreatComment() {
  yield takeEvery(createComment, function* workCreateComment({ payload: { values, id } }) {
    try {
      yield call(createCommentRequest, values, id);
      yield put(createComment.success());
    } catch (e) {
      yield put(createComment.error());
      errorLogger(e);
    }
  });
}

function* getCommentsRequest(id) {
  const url = axiosData.getComments.url.replace(':id', id);

  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getComments,
    url,
  });
  return result;
}
function* watchGetComments() {
  yield takeEvery(getComments, function* workGetComments({ payload: { id } }) {
    try {
      const data = yield call(getCommentsRequest, id);
      yield put(getComments.success({ data }));
    } catch (e) {
      yield put(getComments.error());
      errorLogger(e);
    }
  });
}
export default function* comments() {
  yield all([watchGetComments(), watchCreatComment()]);
}
