export const getItemSize = (column) => (index) => {
  const item = column.items[index];
  if (item?.data?.totalPrice) {
    return 150;
  }
  return 150;
};

export const cheatTimerCall = (() => {
  let timerId;
  let clearOffsetTimerId;
  const lastLimits = {};

  return (callBack, timer, { listId, key, offset }) => {
    // todo check this Arman check Mher`s functionality
    if (lastLimits?.[listId]?.[key] && lastLimits?.[listId]?.offset >= offset) return;
    lastLimits[listId] = lastLimits[listId] || {};
    lastLimits[listId].offset = offset;
    lastLimits[listId][key] = true;

    // todo change this logic
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      callBack();
    }, timer);

    clearTimeout(clearOffsetTimerId);
    clearOffsetTimerId = setTimeout(() => {
      lastLimits[listId].offset = undefined;
    }, 5000);
  };
})();
