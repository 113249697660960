import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import CartSvg from '../../../svgs/CartSvg';
import MenuButtonSvg from '../../../svgs/MenuButtonSvg';
import ProfileSvg from '../../../svgs/ProfileSvg';
import classNames from './bottomMenu.module.scss';

const BottomMenu = ({ menuState, setMenuState }) => {
  const history = useHistory();
  useEffect(() => {
    if (history.location.pathname === '/home') {
      setMenuState('board');
    }
  }, []);
  return (
    <div className={classNames.bottomMenu}>
      <div
        className={`${classNames.menuButton} ${menuState === 'menu' && classNames.active}`}
        onClick={() => {
          setMenuState('menu');
        }}
      >
        <MenuButtonSvg />
      </div>
      <div
        className={`${classNames.menuButton} ${menuState === 'board' && classNames.active}`}
        onClick={() => {
          setMenuState('board');
          history.push('/home');
        }}
      >
        <CartSvg />
      </div>
      <div
        className={`${classNames.menuButton} ${menuState === 'profile' && classNames.active}`}
        onClick={() => {
          setMenuState('profile');
        }}
      >
        <ProfileSvg />
      </div>
    </div>
  );
};
BottomMenu.propTypes = {
  menuState: PropTypes.string,
  setMenuState: PropTypes.func,
};
export default BottomMenu;
