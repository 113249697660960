import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getStoreProducts as gsp, getUpsertDomains as gud } from './actions';

const initialState = {
  loading: false,
  error: false,
  success: false,
  data: [],
  total: 0,
};

const getStoreProducts = handleActions(
  {
    [gsp]: (state) => ({ ...state, loading: true, error: false }),
    [gsp.success]: (state, { payload: { total, data } }) => ({ ...state, loading: false, data, total }),
    [gsp.error]: (state) => ({ ...state, loading: false, error: true }),
  },
  initialState
);

const getUpsertDomains = handleActions(
  {
    [gud]: (state) => ({ ...state, loading: true, error: false }),
    [gud.success]: (state, { payload: data }) => ({ ...state, loading: false, data }),
    [gud.error]: (state) => ({ ...state, loading: false, error: true }),
  },
  initialState
);

export default combineReducers({ getUpsertDomains, getStoreProducts });
