import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { selectWorkerRegistration } from '../../store/workers/selectors';
import { workerRegistration } from '../../store/workers/actions';
import { useQueryString } from '../../hooks/useQueryString';
import { defaultValues } from './constants';
import { routes } from '../../routes';
import { selectLang } from '../../store/user/selectors';
import { Lang } from '../../T';

export const useWorkerRegistration = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const [eyeVisible, setEyeVisible] = useState({
    password: false,
    repeatPassword: false,
  });

  const handleEyeVisible = (key) => {
    setEyeVisible((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const history = useHistory();
  const {
    search: { email, token },
  } = useQueryString();

  const incorrectUrlError = useMemo(() => !email || !token, [email, token]);

  const { loading, error, success } = useSelector(selectWorkerRegistration);

  const initialValues = useMemo(() => {
    return { email, token, ...defaultValues };
  }, [email, token]);
  const dispatch = useDispatch();

  const handleSubmit = useCallback((values) => {
    dispatch(workerRegistration({ values, T }));
  }, []);

  useEffect(() => {
    if (success) {
      history.push(routes.authentication);
    }
  }, [success]);
  return {
    loading,
    error,
    handleSubmit,
    incorrectUrlError,
    initialValues,
    eyeVisible,
    handleEyeVisible,
  };
};
