import Axios from '../services/axios';
import { errorLogger } from '../helpers/errorLogger';

const getListsAdd = {
  url: '/api/admin/boards/:id/summery/v2',
  method: 'post',
};

export const getListsAdditional = async (id, filteredData, archived) => {
  const url = getListsAdd.url.replace(':id', id);
  try {
    const { data } = await Axios.request({
      ...getListsAdd,
      url,
      data: { ...filteredData.body, archived },
    });
    return data?.result;
  } catch (e) {
    errorLogger(e || 'something went wrong');
  }
};
