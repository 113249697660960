import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { selectIsAuthenticated } from '../../store/user/selectors';
import { routes } from '../../routes';

const PrivateRoute = ({ children, exact = true, ...rest }) => {
  const history = useHistory();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const redirectToAuth = useMemo(() => {
    return (
      history.location.pathname === routes.authentication &&
      history.location.pathname !== routes.workerRegistration &&
      history.location.pathname !== routes.welcomePage
    );
  }, [history]);

  return isAuthenticated ? (
    <Route exact={exact} {...rest}>
      {children}
    </Route>
  ) : (
    redirectToAuth && <Redirect exact={exact} to={routes.authentication} />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
  exact: PropTypes.bool,
};

export default PrivateRoute;
