/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
import React from 'react';
import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { array, func, number, object } from 'prop-types';
import { useHistory } from 'react-router-dom';
import classNames from './table.module.scss';
import NoDataSvg from '../../../../svgs/NoDataSvg';
import { selectLang } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';
import Pagination from '../../../../components/pagination/Pagination';
import { limitOptions } from '../../../special-product-page/constants';
import CopyItem from '../../../../components/copy-item/CopyItem';
import { routes } from '../../../../routes';
import { objectToQuery } from '../../../../helpers/objectToQuery';
import { defaultProductPicture } from '../../../../constants/constants';
import { formatNumberWithThousandsSeparator } from '../../../../helpers/formatNubmer';
import FilterIco from '../../../products/components/filterIcon';

const Table = ({ data, total, page, limit, changePage, changeLimit, pagesCount, filter, orderData }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const history = useHistory();

  const goTo = (id) => () => {
    const { period, startDate, endDate, urls } = filter;
    const startDates = startDate?.toLocaleDateString('ru-RU').split('.');
    const endDates = endDate?.toLocaleDateString('ru-RU').split('.');
    const search = objectToQuery({
      period: period.value,
      startDate: startDate ? `${startDates[2]}-${startDates[1]}-${startDates[0]}` : undefined,
      endDate: endDate ? `${endDates[2]}-${endDates[1]}-${endDates[0]}` : undefined,
      urls: JSON.stringify(urls.map(({ value }) => value)),
    });
    history.push({
      pathname: routes.productBuyingStats.replace(':productId', id),
      search: `?${search}`,
    });
  };

  return (
    <>
      <div className={classNames.table}>
        <div className={classNames.tableWrapper}>
          <table>
            <colgroup>
              {/* Id */}
              <col style={{ width: 100 }} />
              {/* Img */}
              <col style={{ width: 120 }} />
              {/* Name */}
              <col style={{ width: 300 }} />
              {/* Real/Test */}
              <col style={{ width: 130 }} />
              {/* New order */}
              <col style={{ width: 110 }} />
              {/* Accepted */}
              <col style={{ width: 140 }} />
              {/* Unaccepted */}
              <col style={{ width: 110 }} />
              {/* Current Sold Count */}
              <col style={{ width: 200 }} />
              {/* Sold Count */}
              <col style={{ width: 160 }} />
              {/* Return Count Pending */}
              <col style={{ width: 220 }} />
              {/* Return count done */}
              <col style={{ width: 220 }} />
              {/* Reject Count */}
              <col style={{ width: 200 }} />
              {/* Change count Pending */}
              <col style={{ width: 200 }} />
              {/* Change count Done */}
              <col style={{ width: 200 }} />
              {/* M. Count */}
              <col style={{ width: 110 }} />
              {/* R. Count */}
              <col style={{ width: 110 }} />
              {/* Gross profit */}
              <col style={{ width: 160 }} />
              {/* Delivery price */}
              {/* <col style={{ width: 100 }} /> */}
              {/* Net */}
              {/* <col style={{ width: 100 }} /> */}
              {/* link */}
              <col style={{ width: 100 }} />
            </colgroup>
            <thead>
              <tr>
                <th>{T.Id}</th>
                <th>{T.Img}</th>
                <th>{T.Name}</th>
                <th>{T.Real_Test}</th>
                <th>
                  {T.New_order} <FilterIco value="newOrderCount" setFilterMod={orderData} />
                </th>
                <th>
                  {T.Accepted} <FilterIco value="acceptedCount" setFilterMod={orderData} />
                </th>
                <th>
                  {T.Unaccepted} <FilterIco value="unacceptedCount" setFilterMod={orderData} />
                </th>
                <th>
                  {T.Current_Sold_Count} <FilterIco value="currentSoldCount" setFilterMod={orderData} />
                </th>
                <th>
                  {T.Sold_Count} <FilterIco value="overallSoldCount" setFilterMod={orderData} />
                </th>
                <th>
                  {T.Return_Count_Pending} <FilterIco value="pendingReturnCount" setFilterMod={orderData} />
                </th>
                <th>
                  {T.Return_Count_Done} <FilterIco value="doneReturnCount" setFilterMod={orderData} />
                </th>
                <th>
                  {T.Reject_Count} <FilterIco value="rejectedCount" setFilterMod={orderData} />
                </th>
                <th>
                  {T.Change_Count_Pending} <FilterIco value="pendingChangeCount" setFilterMod={orderData} />
                </th>
                <th>
                  {T.Change_Count_Done} <FilterIco value="doneChangeCount" setFilterMod={orderData} />
                </th>
                <th>
                  {T.M_Count} <FilterIco value="marketingCount" setFilterMod={orderData} />
                </th>
                <th>
                  {T.R_Count} <FilterIco value="realCount" setFilterMod={orderData} />
                </th>
                <th>{T.Gross_Profit}</th>
                {/* <th>Delivery price</th> */}
                {/* <th>Net</th> */}
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {data.map(
                ({
                  id,
                  imageUrl,
                  name,
                  type,
                  marketingCount,
                  realCount,
                  grossTotal,
                  // grossDeductionCash,
                  // totalCount,
                  newOrderCount,
                  acceptedCount,
                  unacceptedCount,
                  currentSoldCount,
                  overallSoldCount,
                  pendingChangeCount,
                  doneChangeCount,
                  pendingReturnCount,
                  doneReturnCount,
                  rejectedCount,
                }) => {
                  return (
                    <tr key={id}>
                      <td>
                        <CopyItem text={id} />
                      </td>
                      <td>
                        <div className={classNames.imageWrapper}>
                          <div className={classNames.picturePopUpContainer}>
                            <img
                              width={34}
                              height={34}
                              className={classNames.image}
                              src={
                                imageUrl && imageUrl !== '/productPictures/null'
                                  ? `${process.env.REACT_APP_BASE_ENDPOINT}${imageUrl}`
                                  : defaultProductPicture
                              }
                              alt="img"
                            />
                            <div className={classNames.picturePopUp}>
                              <p>
                                <img
                                  src={
                                    imageUrl && imageUrl !== '/productPictures/null'
                                      ? `${process.env.REACT_APP_BASE_ENDPOINT}${imageUrl}`
                                      : defaultProductPicture
                                  }
                                  alt="img"
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <CopyItem text={name} />
                      </td>
                      <td>{type}</td>

                      <td>{newOrderCount}</td>
                      <td>{acceptedCount}</td>
                      <td>{unacceptedCount}</td>
                      <td>{currentSoldCount}</td>
                      <td>{overallSoldCount}</td>
                      <td>{pendingReturnCount}</td>
                      <td>{doneReturnCount}</td>
                      <td>{rejectedCount}</td>
                      <td>{pendingChangeCount}</td>
                      <td>{doneChangeCount}</td>
                      <td>{marketingCount}</td>
                      <td>{realCount}</td>
                      <td>{formatNumberWithThousandsSeparator(grossTotal, ' ')}</td>
                      {/* <td>Delivery price</td> */}
                      {/* <td>Net</td> */}
                      <td>
                        <div onClick={goTo(id)} className={classNames.goToButton}>
                          {T.more}
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
          {total === 0 && (
            <p className="no-data-tag">
              <NoDataSvg />
              {T.no_data_found}
            </p>
          )}
        </div>
      </div>
      {total > 0 && (
        <div className={classNames.allPagination}>
          <Pagination onChange={changePage} totalPages={pagesCount} defaultPage={page} mode="products" />

          <div className={classNames.ProductsSeries}>
            <div className={classNames.ProductsSeriesItem}>
              <p>{T.row}</p>
              <div className={classNames.ProductsSeriesItemSelect}>
                <ReactSelect
                  placement="top"
                  menuPlacement="top"
                  options={limitOptions}
                  onChange={changeLimit}
                  placeholder=""
                  value={{ value: limit, label: limit }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Table.propTypes = {
  data: array,
  total: number,
  page: number,
  limit: number,
  changePage: func,
  changeLimit: func,
  orderData: func,
  pagesCount: number,
  filter: object,
};

export default Table;
