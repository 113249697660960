import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editList } from '../../../../../../store/draggable-board/actions';
import { selectEditBoardList } from '../../../../../../store/draggable-board/selector';
import { selectLang } from '../../../../../../store/user/selectors';
import { Lang } from '../../../../../../T';

export const useEditableTitle = ({ id }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const [showSendForm, setShowSendForm] = useState(false);
  const symbolKey = useMemo(() => Symbol('key'), []);

  const { loading, success } = useSelector(selectEditBoardList(symbolKey));

  const dispatch = useDispatch();
  const toggleSendForm = useCallback(() => {
    setShowSendForm(!showSendForm);
  }, [showSendForm]);

  const handleChangeListTitle = useCallback(
    (title) => {
      dispatch(editList({ T, title, key: symbolKey, id }));
    },
    [symbolKey, id]
  );

  useEffect(() => {
    if (success) {
      setShowSendForm(false);
    }
  }, [success]);

  return {
    toggleSendForm,
    handleChangeListTitle,
    showSendForm,
    loading,
    setShowSendForm,
  };
};
