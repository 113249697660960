import React from 'react';

const FilterArrows = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.32031 3.62187L4.70781 1.23437L7.10781 3.63437L6.58281 4.15937L5.08281 2.65937L5.08281 10.7719L4.33281 10.7719L4.33281 2.65937L2.84531 4.14687L2.32031 3.62187ZM6.53281 8.84687L7.05781 8.32187L8.54531 9.80937L8.54531 1.69687L9.29531 1.69687L9.29531 9.80937L10.7953 8.30938L11.3203 8.83437L8.92031 11.2344L6.53281 8.84687Z"
        fill="#7E8295"
      />
    </svg>
  );
};

export default FilterArrows;
