import React from 'react';

const UnavilableEmoji = () => {
  return (
    <svg
      id="Component_71_3"
      data-name="Component 71 – 3"
      xmlns="http://www.w3.org/2000/svg"
      width="33.241"
      height="33.68"
      viewBox="0 0 33.241 33.68"
    >
      <g id="Group_7289" data-name="Group 7289" transform="translate(-1333.017 -379.604)">
        <g id="Layer_1" data-name="Layer 1" transform="translate(1333.017 379.604)">
          <circle
            id="Ellipse_1564"
            data-name="Ellipse 1564"
            cx="14.404"
            cy="14.404"
            r="14.404"
            transform="translate(0 4.871)"
            fill="#ffd053"
          />
          <path
            id="Path_7000"
            data-name="Path 7000"
            d="M144.013,3.284,140.8,0l-2.061,1.448a19.317,19.317,0,0,1,2.7,1.645l-5.158,1.175L136,5.533l4.217,1.822,1.224-1.109-1.674-1.223Z"
            transform="translate(-119.235 0)"
            fill="#231f20"
          />
          <path
            id="Path_7001"
            data-name="Path 7001"
            d="M184.011,35.509l-3.366-2.089-1.428,1.628a16.517,16.517,0,0,1,2.6.822l-4.077,2.037.022,1.125,3.894.661.8-1.178-1.65-.682Z"
            transform="translate(-154.343 -28.722)"
            fill="#231f20"
          />
          <path
            id="Path_7002"
            data-name="Path 7002"
            d="M204.076,66.718,201.911,63.4l-1.984.876a16.572,16.572,0,0,1,2.006,1.844l-4.551.124-.453,1.025,3.249,2.249,1.222-.731-1.208-1.316Z"
            transform="translate(-170.835 -54.163)"
            fill="#231f20"
          />
        </g>
        <path
          id="Path_7003"
          data-name="Path 7003"
          d="M1039.244,306.451a14.23,14.23,0,0,1-19.434,19.789,14.229,14.229,0,1,0,19.434-19.789Z"
          transform="translate(315.731 80.48)"
          fill="#ffc448"
        />
        <path
          id="Path_7004"
          data-name="Path 7004"
          d="M73.551,176.792c-3.772,0-4.649-2.5-4.657-2.53a.429.429,0,0,1,.814-.273c.017.045.761,1.941,3.844,1.941a3.6,3.6,0,0,0,3.515-1.96.429.429,0,1,1,.8.311A4.421,4.421,0,0,1,73.551,176.792Z"
          transform="translate(1274.299 229.978)"
          fill="#231f20"
        />
        <g id="Group_7289-2" data-name="Group 7289" transform="translate(1334.21 385.683)">
          <path
            id="Path_6813"
            data-name="Path 6813"
            d="M1013.088,314.816a13.224,13.224,0,0,1,13.343-13.1,13.407,13.407,0,0,1,10.577,5.115,13.383,13.383,0,0,0-11.57-6.571,13.224,13.224,0,0,0-13.343,13.1,12.892,12.892,0,0,0,2.766,7.986A12.857,12.857,0,0,1,1013.088,314.816Z"
            transform="translate(-1012.095 -300.258)"
            fill="#ffd77a"
          />
        </g>
        <path
          id="Path_7005"
          data-name="Path 7005"
          d="M33.4,104.054a.408.408,0,0,0-.4.34,2.543,2.543,0,0,1-4.927-.008.408.408,0,0,0-.8.141,3.358,3.358,0,0,0,6.537,0,.408.408,0,0,0-.335-.47A.376.376,0,0,0,33.4,104.054Z"
          transform="translate(1309.417 289.51)"
          fill="#231f20"
        />
        <path
          id="Path_7006"
          data-name="Path 7006"
          d="M127.389,104.1a.406.406,0,0,0-.4.342,2.545,2.545,0,0,1-4.93-.008.408.408,0,0,0-.8.141,3.358,3.358,0,0,0,6.537,0,.409.409,0,0,0-.336-.47A.545.545,0,0,0,127.389,104.1Z"
          transform="translate(1229.873 289.471)"
          fill="#231f20"
        />
      </g>
    </svg>
  );
};

export default UnavilableEmoji;
