import React from 'react';

const MenuButtonSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30.51" height="30.51" viewBox="0 0 30.51 30.51">
      <path
        id="four_square_menu_tile"
        data-name="four square menu tile"
        d="M10.39,17.17V10.39h6.78v6.78ZM7,8.695A1.7,1.7,0,0,1,8.695,7h10.17A1.7,1.7,0,0,1,20.56,8.695v10.17a1.7,1.7,0,0,1-1.695,1.695H8.695A1.7,1.7,0,0,1,7,18.865ZM27.34,17.17V10.39h6.78v6.78ZM23.95,8.695A1.7,1.7,0,0,1,25.645,7h10.17A1.7,1.7,0,0,1,37.51,8.695v10.17a1.7,1.7,0,0,1-1.695,1.695H25.645a1.7,1.7,0,0,1-1.695-1.695ZM10.39,27.34v6.78h6.78V27.34ZM8.695,23.95A1.7,1.7,0,0,0,7,25.645v10.17A1.7,1.7,0,0,0,8.695,37.51h10.17a1.7,1.7,0,0,0,1.695-1.695V25.645a1.7,1.7,0,0,0-1.695-1.695ZM27.34,34.12V27.34h6.78v6.78Zm-3.39-8.475a1.7,1.7,0,0,1,1.695-1.695h10.17a1.7,1.7,0,0,1,1.695,1.695v10.17a1.7,1.7,0,0,1-1.695,1.695H25.645a1.7,1.7,0,0,1-1.695-1.695Z"
        transform="translate(-7 -7)"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default MenuButtonSvg;
