export const axiosData = {
  getCities: (offset = 0) => {
    return {
      url: '/api/places/states/:id/cities/',
      method: 'get',
      params: {
        offset,
        limit: 500,
      },
    };
  },
};
