import React from 'react';
import PropTypes, { string } from 'prop-types';
import classNames from './customMenu.module.scss';
import TableDots from '../../../../svgs/TableDots';
import { useCusomMenu } from './useCustomMenu';

const CustomMenu = ({ placement = 'right', items, className, children, id, mode, parentId = '', collapseContent }) => {
  const subMenusLength = collapseContent?.find((el) => el?.id === parentId)?.submenus?.length;
  const { isOpen, handleDots, ref, setIsOpen, controlButton, top, left, height } = useCusomMenu();
  return (
    <div
      className={`${classNames.filterDotsWrapper} ${placement ? classNames[placement] : ''} ${className || ''}`}
      ref={ref}
    >
      {!children ? (
        <button type="button" onClick={handleDots} ref={controlButton}>
          <TableDots />
        </button>
      ) : (
        <div onClick={handleDots}>{children}</div>
      )}
      {isOpen && (
        <div
          className={classNames.editDeleteWrapper}
          style={{
            position: placement === 'left' && 'fixed',
            top: placement === 'left' && `${top + height + 4}px`,
            left: placement === 'left' && `${left + 15}px`,
          }}
        >
          {items?.map(({ key, text, action }) => {
            return (
              <button
                key={key}
                disabled={
                  text === 'Delete'
                    ? mode === 'parent'
                      ? collapseContent?.length === 2
                      : (collapseContent?.length === 2 && collapseContent[1]?.submenus?.length === 1) ||
                        subMenusLength === 1
                    : false
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                  action(id, mode, parentId);
                }}
                type="button"
              >
                {text}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};
CustomMenu.propTypes = {
  placement: PropTypes.string,
  className: PropTypes.string,
  Target: PropTypes.node,
  items: PropTypes.array,
  children: PropTypes.elementType,
  id: string,
  mode: string,
  parentId: string,
  collapseContent: PropTypes.array,
};

export default CustomMenu;
