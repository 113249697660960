import am from './am.json';
import en from './en.json';

export const Lang = (lang) => {
  if (lang === 'am') {
    return am;
  }
  if (lang === 'en') {
    return en;
  }
  return en;
};
