import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
  deliverymanProductsGet,
  deliverymanProductsSet,
  deliverymanProductsClear,
  getDeliverymanProducts,
  searchDeliverymanProducts,
  saveProductCounts,
} from './actions';
import { errorLogger } from '../../helpers/errorLogger';
import Axios from '../../services/axios';
import { axiosData } from './requestGenerators';
import { notifyMessage } from '../message';
import { closeModal } from '../modals/actions';
import { modalNames } from '../modals/constants';
import { droppingErrors } from '../draggable-board/constants';

function* deliverymanProductsGetRequest(id, boardId, offset) {
  const url = axiosData.getDeliverymanProducts.url
    .replace(':id', id)
    .replace('boardId=', `boardId=${boardId}`)
    .replace('offset=', `offset=${offset}`);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getDeliverymanProducts,
    url,
  });

  return result;
}

function* watchDeliverymanProductsGet() {
  yield takeEvery(deliverymanProductsGet, function* workDeliverymanProductsGe({ payload: { id, boardId, offset, T } }) {
    try {
      const data = yield call(deliverymanProductsGetRequest, id, boardId, offset);
      yield put(deliverymanProductsGet.success({ data }));
    } catch (e) {
      yield call(notifyMessage, { body: T.error_something_went_wrong, timer: 1000 }, T);
      yield put(deliverymanProductsGet.error());
      errorLogger(e);
    }
  });
}

/// /////////////////////////////////////////////////////////products
function* ProductsGetRequest(id, boardId, offset, searchWord) {
  const url = axiosData.getProducts.url
    .replace(':id', id)
    .replace('boardId=', `boardId=${boardId}`)
    .replace('offset=', `offset=${offset}`)
    .replace('searchWord=', `searchWord=${searchWord}`);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getDeliverymanProducts,
    url,
  });

  return result;
}

function* watchProductsGet() {
  yield takeEvery(
    getDeliverymanProducts,
    function* workDeliverymanProductsGe({ payload: { id, boardId, offset, searchWord = '', T } }) {
      try {
        const data = yield call(ProductsGetRequest, id, boardId, offset, searchWord);

        yield put(getDeliverymanProducts.success({ data }));
        yield put(searchDeliverymanProducts.reset());
      } catch (e) {
        yield call(notifyMessage, { body: T.error_something_went_wrong, timer: 1000 }, T);
        yield put(getDeliverymanProducts.error());
        errorLogger(e);
      }
    }
  );
}

/// /////////////////////////////////////////////////////////////////////
function* ProductsSearchRequest(id, boardId, offset, searchWord) {
  const url = axiosData.getProducts.url
    .replace(':id', id)
    .replace('boardId=', `boardId=${boardId}`)
    .replace('offset=', `offset=${offset}`)
    .replace('searchWord=', `searchWord=${searchWord}`);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getDeliverymanProducts,
    url,
  });

  return result;
}

function* watchProductsSearch() {
  yield takeEvery(
    searchDeliverymanProducts,
    function* workDeliverymanProductsSearch({ payload: { id, boardId, offset, searchWord = '', T } }) {
      try {
        const data = yield call(ProductsSearchRequest, id, boardId, offset, searchWord);
        yield put(searchDeliverymanProducts.success({ data, offset }));
      } catch (e) {
        yield call(notifyMessage, { body: T.error_something_went_wrong, timer: 1000 }, T);
        yield put(getDeliverymanProducts.error());
        errorLogger(e);
      }
    }
  );
}

// /////////////////////////////////////////////////////////////////////
function* deliverymanProductsSetRequest({ changedProduct, id }) {
  const url = axiosData.setDeliverymanProducts.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.setDeliverymanProducts,
    url,
    data: {
      products: changedProduct,
    },
  });

  return result;
}

function* watchDeliverymanProductsSet() {
  yield takeEvery(
    deliverymanProductsSet,
    function* workDeliverymanProductsSet({ payload: { id, changedProduct, setChangedProduct, T } }) {
      try {
        yield call(deliverymanProductsSetRequest, { changedProduct, id });

        yield put(deliverymanProductsSet.success({ changedProduct }));
        yield put(closeModal({ name: modalNames.attachDeliverymenProduct }));
        setChangedProduct({});
      } catch (e) {
        yield call(notifyMessage, { body: T.error_something_went_wrong, timer: 1000 }, T);
        yield put(deliverymanProductsSet.error());
        errorLogger(e);
      }
    }
  );
}

function* deliverymanProductsClearRequest({ id }) {
  const url = axiosData.clearDeliverymanProducts.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.clearDeliverymanProducts,
    url,
  });

  return result;
}

function* watchDeliverymanProductsClear() {
  // OK
  yield takeEvery(deliverymanProductsClear, function* workDeliverymanProductsClear({ payload: { id, T } }) {
    try {
      yield call(deliverymanProductsClearRequest, { id });

      yield put(deliverymanProductsClear.success());
      yield put(closeModal({ name: modalNames.attachDeliverymenProduct }));
    } catch (e) {
      const { message: errorMessage } = e.response.data.error;
      yield call(
        notifyMessage,
        {
          body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage,
          timer: 3000,
        },
        T
      );
      yield put(deliverymanProductsClear.error());
      errorLogger(e);
    }
  });
}

function* watchSaveProducts() {
  // OK
  yield takeEvery(saveProductCounts, function* workSaveProducts({ payload: { productId, spareCount, T } }) {
    try {
      yield put(saveProductCounts.success({ productId, spareCount }));
    } catch (e) {
      yield call(notifyMessage, { body: T.error_something_went_wrong, timer: 1000 }, T);
      yield put(saveProductCounts.error());
      errorLogger(e);
    }
  });
}

export default function* domain() {
  yield all([
    watchDeliverymanProductsSet(),
    watchDeliverymanProductsGet(),
    watchDeliverymanProductsClear(),
    watchProductsGet(),
    watchProductsSearch(),
    watchSaveProducts(),
  ]);
}
