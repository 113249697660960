import React from 'react';
import { useSelector } from 'react-redux';
import { useCreateBoardButton } from './useCreateBoardButton';
import classNames from './createBoardButton.module.scss';
import { selectLang, selectUserRole } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';

const CreateBoardButton = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { openCreateBoardModal } = useCreateBoardButton();
  const role = useSelector(selectUserRole);

  return (
    (role === 'admin' || role === 'director') && (
      <div className={classNames.createNewBoar}>
        <button className={classNames.CreateBoardButton} type="button" onClick={openCreateBoardModal}>
          + {T.create_board}
        </button>
      </div>
    )
  );
};

export default CreateBoardButton;
