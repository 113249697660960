import React from 'react';

const EditIconSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.597" height="17.665" viewBox="0 0 17.597 17.665">
      <path
        id="border_color_FILL0_wght400_GRAD0_opsz48"
        d="M8.1,21.939V18.311L18.276,8.135,21.9,11.763,11.728,21.939ZM9.737,20.3h1.228l8.594-8.594-1.228-1.228L9.737,19.075ZM23.1,10.563,19.476,6.935l2.292-2.292a.926.926,0,0,1,.682-.368,1.044,1.044,0,0,1,.764.368l2.128,2.128a1.056,1.056,0,0,1,.355.75,1.146,1.146,0,0,1-.3.75ZM9.737,20.3Z"
        transform="translate(-8.1 -4.274)"
        fill="#a09fad"
      />
    </svg>
  );
};

export default EditIconSvg;
