import { analAxios } from '../../services/axios';

export const getOrderStatistic = (
  {
    period,
    productIds,
    ignoreOutOfInterval,
    startTime,
    endTime,
    startDate,
    endDate,
    domainIds,
    urls,
    region,
    device,
    limit,
    offset,
    type,
    isAccountant,
    utm,
  },
  setData,
  setTotal
) => {
  const utmParam = {};
  if (utm?.length) {
    utm.forEach(({ type, value }) => {
      if (!utmParam[`${type}`]) {
        utmParam[`${type}`] = [value];
      } else {
        utmParam[`${type}`].push(value);
      }
    });
  }
  const isBothTimeExact = (startTime !== null && endTime !== null) || (startTime === null && endTime === null);
  if (period && isBothTimeExact) {
    if (period?.value !== 'custom') {
      analAxios
        .post('/api/order/list/overall', {
          intervalType: period.value,
          productIds: productIds.map(({ value }) => value),
          ignoreOutOfInterval: isAccountant ? undefined : ignoreOutOfInterval,
          domainIds: isAccountant ? undefined : domainIds.map(({ value }) => `${value}`),
          urls: isAccountant ? undefined : urls.map(({ value }) => value),
          orderedFrom: isAccountant ? undefined : region.value || undefined,
          createdFrom: isAccountant ? undefined : device.value || undefined,
          limit: limit || 10,
          offset: offset || 0,
          type,
          utms: utm?.length ? utmParam : undefined,
        })
        .then((res) => {
          setData(res.data.result);
          setTotal(+res.data.count);
        })
        .catch((e) => console.log(e));
    } else if (period.value === 'custom' && startDate !== null && endDate !== null) {
      const startDateArray = startDate.toLocaleDateString('ru-RU').split('.');
      const endDateArray = endDate.toLocaleDateString('ru-RU').split('.');
      analAxios
        .post('/api/order/list/overall', {
          startDate: `${startDateArray[2]}-${startDateArray[1]}-${startDateArray[0]}`,
          endDate: `${endDateArray[2]}-${endDateArray[1]}-${endDateArray[0]}`,
          productIds: productIds.map(({ value }) => value),
          ignoreOutOfInterval: isAccountant ? undefined : ignoreOutOfInterval,
          domainIds: isAccountant ? undefined : domainIds.map(({ value }) => `${value}`),
          urls: isAccountant ? undefined : urls.map(({ value }) => value),
          orderedFrom: isAccountant ? undefined : region.value || undefined,
          createdFrom: isAccountant ? undefined : device.value || undefined,
          limit: limit || 10,
          offset: offset || 0,
          type,
          utms: utm?.length ? utmParam : undefined,
        })
        .then((res) => {
          setData(res.data.result);
          setTotal(+res.data.count);
        })
        .catch((e) => console.log(e));
    }
  }
};
