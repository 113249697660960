import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Dropdown from '../../../../../../../components/dropdown/Dropdown';
import { useSettings } from './useSettings';
import Image from '../../../../../../../components/image/Image';
import { selectLang, selectUserRole } from '../../../../../../../store/user/selectors';
import ArrowSvg from '../../../../../../../svgs/ArrowSvg';
import { Lang } from '../../../../../../../T';

const Settings = ({ path }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const {
    openCreateEditStickersModal,
    handleLogout,
    boardPage,
    openEditUserProfileSettingsModal,
    profilePicture,
    archived,
    toggleArchivedData,
    firstName,
    lastName,
  } = useSettings();
  const role = useSelector(selectUserRole);
  return (
    <div className="settings">
      <Dropdown
        placementRight
        listItemClass="HeaderUserDropDown"
        button={
          profilePicture ? (
            path !== '/board/:id/:filterId' ? (
              <div className="sidebarUser">
                <Image src={profilePicture} alt="" />
                <div className="userName">
                  <p>{firstName}</p>
                  <p> {lastName}</p>
                </div>
                <ArrowSvg />
              </div>
            ) : (
              <Image src={profilePicture} alt="" />
            )
          ) : (
            <div className="sidebarUser">
              <Image src="/images/avatar.png" alt="" />
              <div className="userName">
                <p>{firstName}</p>
                <p> {lastName}</p>
              </div>
              <ArrowSvg />
            </div>
          )
        }
      >
        <div className="userSettingDropdwon">
          {role !== 'deliveryMan' && boardPage ? (
            <button type="button" onClick={openCreateEditStickersModal}>
              {T.stickers}
            </button>
          ) : null}
          {role !== 'deliveryMan' && (
            <button onClick={openEditUserProfileSettingsModal} type="button">
              {T.account_settings}
            </button>
          )}
          {role !== 'deliveryMan' ? (
            boardPage && !archived ? (
              <button type="button" onClick={toggleArchivedData} className="btn">
                {T.archive}
              </button>
            ) : null
          ) : null}
          <button type="button" className="btn" onClick={handleLogout}>
            {T.log_out}
          </button>
        </div>
      </Dropdown>
    </div>
  );
};
Settings.propTypes = {
  path: PropTypes.string,
};
export default Settings;
