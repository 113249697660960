import React, { useLayoutEffect, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { bool, number, object, string } from 'prop-types';
import classNames from './TargetChart.module.scss';
import SmallArrowSvg from '../../../svgs/SmallArrowSvg';

export default function TargetChart({ mode = 'Am', data }) {
  const [sum, setSum] = useState(0);
  const [animatedData, setAnimatedData] = useState([]);

  useEffect(() => {
    let currentIndex = 0;
    const animationInterval = setInterval(() => {
      setAnimatedData(data.slice(0, currentIndex + 1));
      currentIndex += 1;

      if (currentIndex >= data.length) {
        clearInterval(animationInterval);
      }
    }, 500);

    return () => {
      clearInterval(animationInterval);
    };
  }, []);

  useLayoutEffect(() => {
    let sum = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data?.length; i++) {
      const { newAds = 0, manageAds = 0, turnOffAds = 0, leadServer = 0 } = data[i];
      sum += newAds + manageAds + turnOffAds + leadServer;
    }

    setSum(sum);
  }, [data]);

  const CustomBarNewAds = (props) => {
    const { x, y, width, fill, payload } = props;
    if (Number.isNaN(width)) {
      return <span />;
    }
    return (
      <g>
        <rect x={x} y={y} width={width} height={25} fill={fill} />
        <text
          className={!payload?.newAds ? classNames.hiddenText : ''}
          x={width < 380 ? x + width + 25 : x + width - 25}
          y={y + 25 / 2}
          fill={width < 380 ? 'black' : '#ffffff'}
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {((payload?.newAds / sum) * 100).toFixed()}%
        </text>
      </g>
    );
  };
  const CustomBarManage = ({ x, y, width, fill = '', payload }) => {
    if (Number.isNaN(width)) {
      return <span />;
    }
    return (
      <g>
        <rect x={x} y={y} width={width} height={25} fill={fill} />
        <text
          className={!payload?.manageAds ? classNames.hiddenText : ''}
          x={width < 380 ? x + width + 25 : x + width - 25}
          y={y + 25 / 2}
          fill={width < 380 ? 'black' : '#ffffff'}
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {((payload?.manageAds / sum) * 100).toFixed()}%
        </text>
      </g>
    );
  };

  const CustomBarTurnOffAds = ({ x, y, width, fill = '', payload }) => {
    if (Number.isNaN(width)) {
      return <span />;
    }
    return (
      <g>
        <rect x={x} y={y} width={width} height={25} fill={fill} />
        <text
          className={!payload?.turnOffAds ? classNames.hiddenText : ''}
          x={width < 380 ? x + width + 25 : x + width - 25}
          y={y + 25 / 2}
          fill={width < 380 ? 'black' : '#ffffff'}
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {((payload?.turnOffAds / sum) * 100).toFixed()}%
        </text>
      </g>
    );
  };
  const CustomBarLeadServer = ({ x, y, width, fill = '', payload }) => {
    if (Number.isNaN(width)) {
      return <span />;
    }
    return (
      <g>
        <rect x={x} y={y} width={width} height={25} fill={fill} />
        <text
          className={!payload?.leadServer ? classNames.hiddenText : ''}
          x={width < 380 ? x + width + 43 : x + width - 45}
          y={y + 25 / 2}
          fill={width < 380 ? 'black' : '#ffffff'}
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {/* {((payload?.leadServer / sum) * 100).toFixed()}% */}
          {`CPL ${payload.leadServer}$ 45`}
        </text>
      </g>
    );
  };
  const mixedPropTypes = {
    x: number,
    y: number,
    width: number,
    fill: string,
    payload: object,
  };
  CustomBarNewAds.propTypes = mixedPropTypes;
  CustomBarManage.propTypes = mixedPropTypes;
  CustomBarTurnOffAds.propTypes = mixedPropTypes;
  CustomBarLeadServer.propTypes = mixedPropTypes;

  const handleOpenPage = () => {
    if (mode === 'Am') {
      console.log(mode);
    } else {
      console.log(mode);
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { newAds, manageAds, turnOffAds, leadServer, name } = payload[0]?.payload;
      return (
        <div
          style={{
            backgroundColor: 'whitesmoke',
            opacity: '0.9',
            fontSize: '15px',
            fontWeight: 'normal',
            width: '120px',
          }}
        >
          {newAds && (
            <p>
              {name}: {newAds}
            </p>
          )}
          {manageAds && (
            <p>
              {name}: {manageAds}
            </p>
          )}
          {turnOffAds && (
            <p>
              {name}: {turnOffAds}
            </p>
          )}
          {leadServer && (
            <p>
              {name}: {leadServer}
            </p>
          )}
        </div>
      );
    }

    return null;
  };

  CustomTooltip.propTypes = {
    active: bool,
    payload: object,
    label: string,
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <span>
          {mode === 'AM' ? `Targeter Hayk Petrosyan | amount ${sum} $` : `Targeter Mark Simelo | amount ${sum} $`}{' '}
        </span>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={animatedData} layout="vertical">
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="newAds" barSize={-5} fill="#00C084" shape={<CustomBarNewAds />} />
          <Bar dataKey="manageAds" barSize={-11} fill="#1D9EF9" shape={<CustomBarManage />} />
          <Bar dataKey="turnOffAds" barSize={3} fill="#EC5D5D" shape={<CustomBarTurnOffAds />} />
          <Bar dataKey="leadServer" barSize={9} fill="#066BC6" shape={<CustomBarLeadServer />} />
        </BarChart>
      </ResponsiveContainer>
      <div className={classNames.chartControls}>
        <div className={classNames.chartItem}>
          <div />
          <p>Info</p>
          <span onClick={handleOpenPage}>
            more
            <SmallArrowSvg />
          </span>
        </div>
      </div>
    </>
  );
}

TargetChart.propTypes = {
  mode: string,
  data: object,
};
