import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { selectDeletedBoard, selectMainBoards } from '../../../../store/boards/selectors';
import { getBoards, deleteBoardById } from '../../../../store/boards/actions';
import { openModal } from '../../../../store/modals/actions';
import { modalNames } from '../../../../store/modals/constants';
import { toggleArchive } from '../../../../store/draggable-board/actions';
import { selectDraggableBoard } from '../../../../store/draggable-board/selector';
import { Lang } from '../../../../T';
import { selectLang } from '../../../../store/user/selectors';

export const useExistBoards = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const dispatch = useDispatch();
  const { data, loading } = useSelector(selectMainBoards);
  const { archived } = useSelector(selectDraggableBoard);
  const { success, loading: deleteLoading } = useSelector(selectDeletedBoard);

  const openEditBoardModal = useCallback((id) => {
    dispatch(openModal({ name: modalNames.createEditBoard, data: { id } }));
  }, []);

  const openDeleteModal = useCallback((name, id) => {
    dispatch(
      openModal({
        name: modalNames.confirmation,
        data: {
          title: T.delete,
          description: T.are_you_sure_delete_column,
          onOk: () => dispatch(deleteBoardById({ id, T })),
        },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(getBoards({ T }));
    if (archived) {
      dispatch(toggleArchive());
    }
    return () => {
      dispatch(getBoards.reset());
    };
  }, [success]);

  useEffect(() => {
    if (success) {
      dispatch(getBoards({ T }));
    }
  }, [success]);

  return {
    data,
    loading,
    openEditBoardModal,
    openDeleteModal,
    deleteLoading,
  };
};
