export const selectProducts = ({ products: { get } }) => get;

export const selectGetProductEdit = ({ products: { getEdit } }) => getEdit;
export const selectCreateProduct = ({ products: { create } }) => create;
export const selectStatesProduct = ({ products: { getStates } }) => getStates;
export const selectProductSummary = ({ products: { getSummary } }) => getSummary;
export const selectSendSummary = ({ products: { sendSummary } }) => sendSummary;
export const selectProductsMutated = ({ products: { get } }) => {
  return get?.data?.rows?.map((el) => {
    return {
      label: el.name,
      value: el.id,
    };
  });
};
