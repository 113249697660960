import React from 'react';

const MarketingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        d="M16 2a13.919 13.919 0 0 0-8.675 3.033A1.973 1.973 0 0 0 7 5a2 2 0 0 0-2 2a1.975 1.975 0 0 0 .033.325A13.986 13.986 0 1 0 16 2m0 26A11.977 11.977 0 0 1 6.359 8.886a1.831 1.831 0 0 0 1.153.04l1.463 1.463a8.986 8.986 0 0 0 11.831 13.207a1.992 1.992 0 0 0 2.79-2.79a8.93 8.93 0 0 0 0-9.612a1.992 1.992 0 0 0-2.79-2.79a8.945 8.945 0 0 0-10.417.571L8.926 7.512a1.83 1.83 0 0 0-.04-1.153A11.993 11.993 0 1 1 16 28m0-14a2 2 0 1 1-2 2a2.003 2.003 0 0 1 2-2m3.433-.019l2.055-2.055a2.044 2.044 0 0 0 .227.045a6.957 6.957 0 0 1 0 8.058a2.044 2.044 0 0 0-.227.045l-2.055-2.055a3.879 3.879 0 0 0 0-4.038m.596-3.696a2 2 0 0 0 .045.227l-2.055 2.055a3.879 3.879 0 0 0-4.038 0L11.816 10.4a6.952 6.952 0 0 1 8.213-.116m-7.462 3.696A3.951 3.951 0 0 0 12 16a4.005 4.005 0 0 0 4 4a3.952 3.952 0 0 0 2.019-.567l2.055 2.055a2 2 0 0 0-.045.227a6.988 6.988 0 0 1-9.627-9.9Z"
      />
    </svg>
  );
};

export default MarketingIcon;
