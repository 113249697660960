import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
  getWorkers,
  removeWorker,
  getWorkerEdit,
  workerEdit,
  workerCreate,
  workerRegistration,
  selectWorker,
  payWorkerSalary,
  getWorkerSalary,
  resendEmail,
  checkOrderEditibality,
} from './actions';

const checkEditibilatyInitialState = {
  loading: false,
  error: false,
  editable: false,
};

const checkEditibality = handleActions(
  {
    [checkOrderEditibality]: () => ({
      loading: true,
      error: false,
      data: [],
    }),
    [checkOrderEditibality.success]: (_, { payload: { editable } }) => {
      return {
        loading: false,
        error: false,
        editable,
      };
    },
    [checkOrderEditibality.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [checkOrderEditibality.reset]: () => ({
      loading: false,
      error: false,
      editable: false,
    }),
  },
  checkEditibilatyInitialState
);
const getInitialState = {
  loading: false,
  error: false,
  data: [],
};

const get = handleActions(
  {
    [getWorkers]: () => ({
      loading: true,
      error: false,
      data: [],
    }),
    [getWorkers.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      data,
    }),
    [getWorkers.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [getWorkers.reset]: () => ({
      loading: false,
      error: false,
      data: [],
    }),
  },
  getInitialState
);

const removeWorkerInitialState = { loading: false, error: false };

const remove = handleActions(
  {
    [removeWorker]: () => ({
      loading: true,
      error: false,
    }),
    [removeWorker.success]: () => ({
      loading: false,
      error: false,
    }),
    [removeWorker.error]: () => ({
      loading: false,
      error: true,
    }),
    [removeWorker.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  removeWorkerInitialState
);

const getEditInitialData = { loading: false, error: false, data: {} };

const getEdit = handleActions(
  {
    [getWorkerEdit]: () => ({
      loading: true,
      error: false,
      data: {},
    }),
    [getWorkerEdit.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      data,
    }),
    [getWorkerEdit.error]: () => ({
      loading: false,
      error: true,
      data: {},
    }),
    [getWorkerEdit.reset]: () => ({
      loading: false,
      error: false,
      data: {},
    }),
  },
  getEditInitialData
);

const editInitialData = { loading: false, error: false };

const edit = handleActions(
  {
    [workerEdit]: () => ({
      loading: true,
      error: false,
    }),
    [workerEdit.success]: () => ({
      loading: false,
      error: false,
    }),
    [workerEdit.error]: () => ({
      loading: false,
      error: true,
    }),
    [workerEdit.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  editInitialData
);

const createInitialState = {
  loading: false,
  error: false,
};
const create = handleActions(
  {
    [workerCreate]: () => ({
      loading: true,
      error: false,
    }),
    [workerCreate.success]: () => ({
      loading: false,
      error: false,
    }),
    [workerCreate.error]: () => ({
      loading: false,
      error: true,
    }),
    [workerCreate.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  createInitialState
);

const registrationInitialState = {
  loading: false,
  error: false,
  success: false,
};

const registration = handleActions(
  {
    [workerRegistration]: () => ({
      loading: true,
      error: false,
    }),
    [workerRegistration.success]: () => ({
      loading: false,
      error: false,
      success: true,
    }),
    [workerRegistration.error]: () => ({
      loading: false,
      error: true,
    }),
    [workerRegistration.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  registrationInitialState
);

const selectInitialState = {
  loading: false,
  error: false,
  success: false,
};

const select = handleActions(
  {
    [selectWorker]: () => ({
      loading: true,
      error: false,
      success: false,
    }),
    [selectWorker.success]: () => ({
      loading: false,
      error: false,
      success: true,
    }),
    [selectWorker.error]: () => ({
      loading: false,
      error: true,
      success: false,
    }),
  },
  selectInitialState
);
const payInitialState = {
  loading: false,
  error: false,
  success: [],
};
const pay = handleActions(
  {
    [payWorkerSalary]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: true,
        error: false,
        success: state[id]?.success,
      },
    }),
    [payWorkerSalary.success]: (state, { payload: { id, month } }) => {
      return {
        ...state,
        [id]: {
          ...state[id],
          loading: false,
          error: false,
          success: state[id].success ? [...state[id].success, month] : [month],
        },
      };
    },
    [payWorkerSalary.error]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: false,
        error: true,
        success: state[id]?.success,
      },
    }),
  },
  payInitialState
);
const getSalaryInitialState = {
  loading: false,
  error: false,
  success: false,
  data: [],
};

const getSalary = handleActions(
  {
    [getWorkerSalary]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: true,
        error: false,
        success: false,
      },
    }),
    [getWorkerSalary.success]: (state, { payload: { data, id } }) => ({
      ...state,
      [id]: {
        ...state[id],
        loading: false,
        error: false,
        success: true,
        data,
      },
    }),
    [getWorkerSalary.error]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: false,
        error: true,
        success: false,
      },
    }),
  },
  getSalaryInitialState
);

const resendEmailState = {
  loading: false,
  error: false,
  success: false,
};

const resend = handleActions(
  {
    [resendEmail]: () => ({
      loading: true,
      error: false,
      success: false,
    }),
    [resendEmail.success]: () => ({
      loading: false,
      error: false,
      success: true,
    }),
    [resendEmail.error]: () => ({
      loading: false,
      error: true,
      success: false,
    }),
  },
  resendEmailState
);

export default combineReducers({
  get,
  remove,
  getEdit,
  edit,
  create,
  registration,
  select,
  pay,
  getSalary,
  resend,
  checkEditibality,
});
