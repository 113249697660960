export const selectDraggableBoard = ({ draggableBoard: { getBoard } }) => getBoard;
export const selectDrag = ({ draggableBoard: { dragState } }) => dragState;
export const selectCreateBoardList = ({ draggableBoard: { createBoardList } }) => createBoardList;
export const selectRemoveBoardList = ({ draggableBoard: { removeBoardList } }) => removeBoardList;
export const selectPutFilteredValue = ({ draggableBoard: { putFilteredValue } }) => ({
  filteredValue: putFilteredValue.filteredValue,
  dateRange: putFilteredValue.dateRange,
});
export const selectListItemId = ({ draggableBoard: { getBoardListItemToEdit } }) =>
  getBoardListItemToEdit.data.listItemId;
export const selectDraggableBoardItemLoadMore =
  (id) =>
  ({
    draggableBoard: {
      getBoard: {
        data: { columns },
      },
    },
  }) => {
    return columns[id].loading || false;
  };

export const selectEditBoardList =
  (key) =>
  ({ draggableBoard: { editBoardList } }) =>
    editBoardList[key] || {
      loading: false,
      error: false,
      success: false,
    };

export const selectCopyListItem =
  (id) =>
  ({ draggableBoard: { editBoardList } }) =>
    editBoardList[id] || {
      loading: false,
      error: false,
      success: false,
    };

export const selectRemoveListItem =
  (id) =>
  ({ draggableBoard: { editBoardList } }) =>
    editBoardList[id] || {
      loading: false,
      error: false,
    };

export const selectArchiveListItem =
  (id) =>
  ({ draggableBoard: { archiveBoardListItem } }) =>
    archiveBoardListItem[id] || {
      loading: false,
      error: false,
    };

export const selectCreateBoardListItem = ({ draggableBoard: { createBoardListItem } }) => createBoardListItem;
export const selectGetBoardListItemToEdit = ({ draggableBoard: { getBoardListItemToEdit } }) => {
  return getBoardListItemToEdit;
};

export const selectResetReturnChangeListItemStatus = ({ draggableBoard: { resetReturnChangeListItem } }) =>
  resetReturnChangeListItem;
export const selectArchiveList =
  (id) =>
  ({ draggableBoard: { archiveBoardListItem } }) =>
    archiveBoardListItem[id] || {
      loading: false,
      error: false,
    };

export const selectArchiveListLoading = ({ draggableBoard: { archiveBoardListItem } }) => {
  return archiveBoardListItem;
};

export const selectStatusChangeSuccess = ({ draggableBoard }) => draggableBoard.editBoardListItem.success;
export const selectRevertedStatus = ({ draggableBoard }) => draggableBoard.revert.success;
export const selectClearStatusSuccess = ({ draggableBoard }) => draggableBoard.changeOrderStatusToBack.success;
export const selectGetBoardName = ({ draggableBoard }) => draggableBoard.getBoardNameReq;

export const statusShippingSuccess = ({ draggableBoard }) => draggableBoard.statusShipping.success;
export const selectMoreInfo = ({ draggableBoard }) => draggableBoard.getMore;
export const selectListItemIdReq = ({ draggableBoard }) => draggableBoard.save.listItemId;
