import { useState, useRef } from 'react';
import { useEffect } from 'react';
import { useOutsideDetect } from '../../hooks/useOutsideDetect';

export const useMultiSelect = ({ onChange, options, outerValue, onSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const inputRef = useRef(null);

  const handleSelectOpen = () => {
    setIsOpen((prev) => !prev);
    if (inputRef?.current === document.activeElement) {
      setIsOpen(true);
    }
  };

  const hanldeInputChange = (e) => {
    onSearch(e.target.value);
    setInputValue(e.target.value);
    setIsOpen(true);
    setIsDirty(true);
  };

  const handleChangeSelect = (e, label, value) => {
    if (e.target.value === 'all') {
      if (e.target.checked) {
        setSelected(options);
        onChange(options);
        setIsOpen(false);
      } else {
        setSelected([]);
      }
      return;
    }
    if (e.target.checked) {
      onChange([...selected, { label, value }]);
      setSelected((prev) => [...prev, { label, value }]);
    } else {
      onChange(selected.filter((item) => item.value !== value));
      setSelected((prev) => prev.filter((item) => item.value !== value));
    }
    setIsDirty(false);
  };

  const clearChips = (e) => {
    e.stopPropagation();
    setIsOpen(false);
    setSelected([]);
    onChange([]);
  };

  const ref = useOutsideDetect(setIsOpen);

  useEffect(() => {
    if (!isOpen) {
      setIsDirty(false);
      setInputValue('');
      onSearch('');
    }
  }, [isOpen]);

  useEffect(() => {
    setSelected(outerValue);
  }, [outerValue]);

  return {
    ref,
    handleSelectOpen,
    handleChangeSelect,
    isOpen,
    inputValue,
    hanldeInputChange,
    selected,
    isDirty,
    clearChips,
    inputRef,
  };
};
