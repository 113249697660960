export const axiosData = {
  getCD: {
    url: '/api/admin/cd',
    method: 'get',
  },
  setCD: {
    url: '/api/admin/cd',
    method: 'post',
  },
};
