import React from 'react';

const RightSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.667" height="22" viewBox="0 0 14.667 22">
      <g id="Group_6694" data-name="Group 6694" transform="translate(0)">
        <path
          id="Path_6180"
          data-name="Path 6180"
          d="M92.019,18.065h0c.021.021.043.041.0604.024.037.022.042.02.04.018.041.015.044.015.042.011.045.011.049.007.04.006a.922.922,0,0,0,.181,0l.04-.006.049-.007.045-.011.042-.011.044-.015.041-.015.04-.018.042-.02.037-.022.04-.024.039-.029.033-.024c.023-.019.045-.039.067-.06h0l6.417-6.417a.917.917,0,0,0-1.3-1.3L93.584,15.2V.917a.917.917,0,1,0-1.833,0V15.2L86.9,10.352a.917.917,0,0,0-1.3,1.3Z"
          transform="translate(-85.334)"
          fill="#fff"
        />
        <path
          id="Path_6181"
          data-name="Path 6181"
          d="M99.084,0,0-1.833Z"
          transform="translate(-85.334 -449.166)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default RightSvg;
