import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { postfix } from './constants';

const Image = ({ src, ...rest }) => {
  return <img alt="" {...rest} src={postfix + src} />;
};

Image.propTypes = {
  src: PropTypes.string,
};

export default memo(Image);
