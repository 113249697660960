import React from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useProducts } from './useProducts';
import Pagination from '../../components/pagination/Pagination';
import Button from '../../components/button/Button';
import Image from '../../components/image/Image';
import StatusSwitch from './components/status-switch/StatusSwitch';
import { imgStyles, imgStylesBig, limitOptions } from './constants';
import Search from '../../components/search/Search';
import FilterIco from './components/filterIcon';
import EditIconSvg from '../../svgs/EditIconSvg';
import DeleteIconSvg from '../../svgs/DeleteIconSvg';
import classNames from './products.module.scss';
import SmallCopySvg from '../../svgs/SmallCopySvg';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';
import CopyItem from '../../components/copy-item/CopyItem';
import { formatNumberWithThousandsSeparator } from '../../helpers/formatNubmer';

const Products = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const productStatuses = [
    { value: 'all', label: T.all },
    { value: 'real', label: T.real },
    { value: 'test', label: T.test },
  ];
  const {
    filteredProducts: { rows } = {},
    handleGetProducts,
    pagesCount,
    createProduct,
    handleSearchProduct,
    editProduct,
    page,
    deleteProduct,
    changeLimit,
    limit,
    setFilterMod,
    filterByBoard,
    filterByStatuses,
    boardData,
    // productCounts,
    copiedTextId,
    handleCopyProductName,
    role,
    setSearch,
  } = useProducts();

  return (
    <div className={classNames.ProductsSection}>
      <div className="container maxw-100">
        <div className={classNames.producstHeadeer}>
          <h2 className="title">{T.products}</h2>
          {(role === 'admin' || role === 'director' || role === 'productCreator') && (
            <Button type="button" className="text-right" onClick={createProduct}>
              {T.add_product}
            </Button>
          )}
        </div>
      </div>
      <div className={classNames.staticHeader}>
        <div className="container maxw-100">
          <div className="row justify-content-between align-items-center">
            <div className="d-flex align-items-start">
              <div className={classNames.ProductsSearch}>
                <div className={classNames.inputs}>
                  <div className={`${classNames.searchFilter} ${classNames.ProductsSearch}`}>
                    <Search onChange={handleSearchProduct} placeholder={T.search} setSearch={setSearch} />
                  </div>
                </div>
              </div>
              {role !== 'viewer' && (
                <div className={classNames.productStatuses}>
                  <p className={classNames.label}>{T.filter_by}</p>
                  <Select options={productStatuses} onChange={filterByStatuses} placeholder={T.filter_by} disable />
                </div>
              )}
              {role !== 'viewer' && (
                <div className={classNames.productStatuses}>
                  <p className={classNames.label}>{T.filter_by}</p>
                  <Select options={boardData} onChange={filterByBoard} placeholder={T.filter_by} disable />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container ml-0 maxw-100">
        <div className={classNames.ProductsTable}>
          <div className={classNames.productsTableHeader}>
            <div className={classNames.producstTableRoW}>
              <div className={`${classNames.productCol} ${classNames.productId}`}>ID</div>
              <div
                className={`${classNames.productCol} ${classNames.productNameImage} ${
                  role === 'viewer' ? classNames.viewerMode : ''
                }`}
              >
                <p className={classNames.nameImageTitle}>{T.product_name}</p>
              </div>
              {(role === 'productCreator' || role === 'admin') && (
                <div className={`${classNames.productCol} ${classNames.productCount}`}>
                  <p>
                    {T.marketing}
                    <br />
                    {T.count}
                  </p>
                </div>
              )}
              <div className={`${classNames.productCol} ${classNames.productType}`}>
                <p>{T.type}</p>
              </div>
              <div className={`${classNames.productCol} ${classNames.productDate}`}>
                <p>{T.date}</p>
              </div>
              {role !== 'viewer' && (
                <div className={`${classNames.productCol} ${classNames.productCode}`}>
                  <p>{T.code}</p>
                </div>
              )}
              {(role === 'storeKeeper' || role === 'admin' || role === 'director') && (
                <div className={`${classNames.productCol} ${classNames.productWarehouse}`}>
                  <p>
                    {T.warehouse}
                    <FilterIco value="count" setFilterMod={setFilterMod} />
                  </p>
                </div>
              )}
              {role !== 'viewer' && (
                <div className={`${classNames.productCol} ${classNames.productSize}`}>
                  <p>
                    {T.weight_gram}
                    <FilterIco value="weight" setFilterMod={setFilterMod} />
                  </p>
                </div>
              )}
              {role !== 'viewer' && (
                <div className={`${classNames.productCol} ${classNames.productPrice}`}>
                  <p>
                    {T.price}
                    <FilterIco value="price" setFilterMod={setFilterMod} />
                  </p>
                </div>
              )}
              {role !== 'viewer' && (
                <>
                  <div className={`${classNames.productCol} ${classNames.productStatus}`}>
                    <p>{T.status}</p>
                  </div>
                  <div className={`${classNames.productCol} ${classNames.productEdit}`}>
                    <p className="justify-content-center">{T.edit}</p>
                  </div>
                  <div className={`${classNames.productCol} ${classNames.productDelete}`}>
                    <p className="justify-content-center">{T.delete}</p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={classNames.productsTableBody}>
            {rows?.map(
              ({ name, count, type, barcode, photos, createdAt, id, weight, status, price, marketingCount }) => {
                const splittedDate = createdAt.split('T')[0];
                return (
                  <div className={classNames.productsTableItem} key={id}>
                    <div className={classNames.producstTableRoW}>
                      <div className={`${classNames.productCol} ${classNames.productId}`}>
                        <CopyItem text={id} />
                      </div>
                      <div
                        className={`${classNames.productCol} ${classNames.productNameImage} d-flex ${
                          role === 'viewer' ? classNames.viewerMode : ''
                        }`}
                      >
                        <div className={classNames.picturePopUpContainer}>
                          <Image src={photos[0]?.url} alt="" style={imgStyles} />
                          <div className={classNames.picturePopUp}>
                            <p>
                              <Image src={photos[0]?.url} alt="" style={imgStylesBig} />
                            </p>
                          </div>
                        </div>
                        <div className={classNames.salaryContainer}>
                          <div className={classNames.nameImage}>
                            <div className={classNames.textItem}>
                              <p className={copiedTextId === id ? classNames.copied : ''}>
                                {name}
                                <button
                                  onClick={() => handleCopyProductName(name, id)}
                                  type="button"
                                  className={role === 'viewer' ? classNames.nearText : ''}
                                >
                                  <SmallCopySvg />
                                </button>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {(role === 'productCreator' || role === 'admin') && (
                        <div className={`${classNames.productCol} ${classNames.productCount}`}>
                          {type === 'real' && (
                            <span>
                              {marketingCount?.count > 5 ? (
                                <p>{T.exists}</p>
                              ) : marketingCount?.count <= 0 ? (
                                <span className="modal_product_type_name_code_box_icon">
                                  <span style={{ color: 'red' }}>{marketingCount?.count}</span>
                                </span>
                              ) : (
                                <span className="modal_product_type_name_code_box_icon">
                                  <span>{marketingCount?.count}</span>
                                </span>
                              )}
                            </span>
                          )}
                        </div>
                      )}

                      <div className={`${classNames.productCol} ${classNames.productType}`}>
                        <div>
                          <p>{type === 'real' ? T.real : T.test}</p>
                        </div>
                      </div>
                      <div className={`${classNames.productCol} ${classNames.productDate}`}>
                        <div>
                          <p>{splittedDate}</p>
                        </div>
                      </div>

                      {role !== 'viewer' && (
                        <div className={`${classNames.productCol} ${classNames.productCode} `}>
                          {barcode ? <CopyItem text={barcode} /> : 0}
                        </div>
                      )}

                      {(role === 'storeKeeper' || role === 'admin' || role === 'director') && (
                        <div className={`${classNames.productCol} ${classNames.productWarehouse}`}>
                          <div>
                            <p>{count}</p>
                          </div>
                        </div>
                      )}
                      {role !== 'viewer' && (
                        <div className={`${classNames.productCol} ${classNames.productSize}`}>
                          <div>
                            <p>{weight}</p>
                          </div>
                        </div>
                      )}
                      {role !== 'viewer' && (
                        <div className={`${classNames.productCol} ${classNames.productPrice}`}>
                          <div>
                            <p>{formatNumberWithThousandsSeparator(price, ' ')}</p>
                          </div>
                        </div>
                      )}
                      {role !== 'viewer' && (
                        <>
                          <div className={`${classNames.productCol} ${classNames.productStatus}`}>
                            <div className={classNames.swichItem}>
                              <StatusSwitch
                                status={status}
                                id={id}
                                disabled={!(role === 'admin' || role === 'director')}
                              />
                            </div>
                          </div>
                          <div className={`${classNames.productCol} ${classNames.productEdit}`}>
                            <div className={classNames.productsActions}>
                              <button
                                disabled={!(role === 'admin' || role === 'director' || role === 'productCreator')}
                                onClick={() => editProduct(id)}
                                type="button"
                              >
                                <EditIconSvg />
                              </button>
                            </div>
                          </div>
                          <div className={`${classNames.productCol} ${classNames.productDelete}`}>
                            <div className={classNames.productsActions}>
                              <button disabled={!(role === 'admin')} onClick={() => deleteProduct(id)} type="button">
                                <DeleteIconSvg />
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
      {pagesCount > 1 ? (
        <div className={classNames.allPagination}>
          <Pagination onChange={handleGetProducts} totalPages={pagesCount} defaultPage={page} mode="products" />
          <div className={classNames.ProductsSeries}>
            <div className={classNames.ProductsSeriesItem}>
              <p>{T.row}</p>
              <div className={classNames.ProductsSeriesItemSelect}>
                <Select
                  placement="top"
                  menuPlacement="top"
                  options={limitOptions}
                  onChange={changeLimit}
                  placeholder=""
                  value={{ value: limit, label: limit }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Products;
