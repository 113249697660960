import React, { useLayoutEffect, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { bool, number, object, string } from 'prop-types';
import classNames from './accountAndFunChart.module.scss';
import SmallArrowSvg from '../../../svgs/SmallArrowSvg';

export default function AccountAndFunChart({ mode = 'adsAccount', data }) {
  const [sum, setSum] = useState(0);

  const [animatedData, setAnimatedData] = useState([]);

  useEffect(() => {
    let currentIndex = 0;
    const animationInterval = setInterval(() => {
      setAnimatedData(data.slice(0, currentIndex + 1));
      currentIndex += 1;

      if (currentIndex >= data.length) {
        clearInterval(animationInterval);
      }
    }, 500);

    return () => {
      clearInterval(animationInterval);
    };
  }, []);

  useLayoutEffect(() => {
    let sum = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data?.length; i++) {
      const { used = 0, notUsed = 0 } = data[i];
      sum += used + notUsed;
    }

    setSum(sum);
  }, [data]);

  const CustomBarUsed = (props) => {
    const { x, y, width, fill, payload } = props;
    if (Number.isNaN(width)) {
      return <span />;
    }
    return (
      <g>
        <rect x={x} y={y} width={width} height={25} fill={fill} strokeWidth={2} />
        <text
          className={!payload?.used ? classNames.hiddenText : ''}
          x={width < 380 ? x + width + 25 : x + width - 25}
          y={y + 25 / 2}
          fill={width < 380 ? 'black' : '#ffffff'}
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {((payload?.used / sum) * 100).toFixed()}%
        </text>
      </g>
    );
  };
  const CustomBarNotUsed = ({ x, y, width, fill = '', payload }) => {
    if (Number.isNaN(width)) {
      return <span />;
    }
    return (
      <g>
        <rect x={x} y={y} width={width} height={25} fill={fill} strokeWidth={2} />
        <text
          className={!payload?.notUsed ? classNames.hiddenText : ''}
          x={width < 380 ? x + width + 25 : x + width - 25}
          y={y + 25 / 2}
          fill={width < 380 ? 'black' : '#ffffff'}
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {((payload?.notUsed / sum) * 100).toFixed()}%
        </text>
      </g>
    );
  };
  const mixedPropTypes = {
    x: number,
    y: number,
    width: number,
    fill: string,
    payload: object,
  };
  CustomBarNotUsed.propTypes = mixedPropTypes;
  CustomBarUsed.propTypes = mixedPropTypes;

  const handleOpenPage = () => {
    if (mode === 'funPage') {
      console.log(mode);
    } else {
      console.log(mode);
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { used, notUsed, name } = payload[0]?.payload;
      return (
        <div
          style={{
            backgroundColor: 'whitesmoke',
            opacity: '0.9',
            fontSize: '15px',
            fontWeight: 'normal',
            width: '100px',
          }}
        >
          {used && (
            <p>
              {name}: {used}
            </p>
          )}
          {notUsed && (
            <p>
              {name}: {notUsed}
            </p>
          )}
        </div>
      );
    }

    return null;
  };

  CustomTooltip.propTypes = {
    active: bool,
    payload: object,
    label: string,
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <span>{mode === 'adsAccount' ? `Ads account ${sum}` : `Fun page ${sum}`} </span>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={animatedData} layout="vertical">
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="used" barSize={-10} fill="#1D9EF9" shape={<CustomBarUsed />} />
          <Bar dataKey="notUsed" barSize={23} fill="#252733" shape={<CustomBarNotUsed />} />
        </BarChart>
      </ResponsiveContainer>
      <div className={classNames.chartControls}>
        <div className={classNames.chartItem}>
          <div />
          <p>Used</p>
        </div>
        <div className={classNames.chartItem}>
          <div />
          <p>Not Used</p>
          <span onClick={handleOpenPage}>
            more
            <SmallArrowSvg />
          </span>
        </div>
      </div>
    </>
  );
}

AccountAndFunChart.propTypes = {
  mode: string,
  data: object,
};
