import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import Axios from '../../services/axios';
import { axiosData } from './requestGenerators.js';
import { notifyMessage } from '../message';
import { errorLogger } from '../../helpers/errorLogger';
import { createFilter, saveFilteredData } from './actions.js';
import { getDraggableTable } from '../draggable-board/actions';
import { handleGetListData } from '../draggable-board/helpers';
import { selectDraggableBoard } from '../draggable-board/selector';

function* createFilterRequest(body, id) {
  const { archived } = yield select(selectDraggableBoard);
  const url = axiosData.createFilter.url.replace(':id', id);

  return yield call(Axios.request, {
    ...axiosData.createFilter,
    url,
    data: { ...body, archived },
  });
}

function* watchCreateFilter() {
  // OK
  yield takeEvery(createFilter, function* workCreateFilter({ payload }) {
    const { id, body, T } = payload;
    try {
      const res = yield call(createFilterRequest, body, id);
      const data = handleGetListData(res.data?.result);
      yield put(getDraggableTable.success({ data }));
      yield put(createFilter.success());
    } catch (e) {
      yield call(notifyMessage, { body: T.error_something_went_wrong, timer: 1000 }, T);
      yield put(createFilter.error());
      errorLogger(e);
    }
  });
}

function* watchSaveFilter() {
  yield takeEvery(saveFilteredData, function* workSaveFilter({ payload }) {
    try {
      yield put(saveFilteredData.success(payload));
    } catch (e) {
      errorLogger(e);
    }
  });
}

export default function* filter() {
  yield all([watchCreateFilter(), watchSaveFilter()]);
}
