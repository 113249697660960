export const imgStyles = {
  width: '38px',
  height: '38px',
  borderRadius: '50%',
  objectFit: 'cover',
  marginRight: 0,
};

export const imgStylesBig = {
  width: '200px',
  height: '200px',
  borderRadius: '50%',
  objectFit: 'cover',
  marginRight: 0,
};

export const limitOptions = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 60, label: 60 },
  { value: 120, label: 120 },
  { value: 240, label: 240 },
];
