import React from 'react';

const SmallArrowSvg = () => {
  return (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.3474 8.01667L6.63073 7.31667L9.43073 4.51667H0.664062V3.51667H9.43073L6.61406 0.7L7.33073 0L11.3474 4.01667L7.3474 8.01667Z"
        fill="#066BC6"
      />
    </svg>
  );
};

export default SmallArrowSvg;
