import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { validationSchema } from '../../modals/components/create-edit-board/validationSchema';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';

const RadioGroupState = ({ setRadioValues }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  return (
    <Formik initialValues={{ date: '' }} validationSchema={validationSchema}>
      {({ values }) => {
        if (!values.date) {
          setRadioValues(localStorage.getItem('radioValue'));
        } else {
          setRadioValues(!values.date);
        }
        return (
          <Form>
            <div className="data-picker-radio">
              <label htmlFor="purchase" className="radiLabelItem">
                <Field type="radio" id="purchase" name="date" value="purchase" />
                <span className="checkMarkItem" />
                {T.by_purchase}
              </label>
              <label htmlFor="delivery" className="radiLabelItem">
                <Field type="radio" id="delivery" checked={values.date === 'delivery'} name="date" value="delivery" />
                {T.by_delivery}
                <span className="checkMarkItem" />
              </label>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

RadioGroupState.propTypes = {
  name: PropTypes.string,
  setRadioValues: PropTypes.func,
  values: PropTypes.object,
};

export default memo(RadioGroupState);
