import { all, takeEvery, put } from 'redux-saga/effects';
import { errorLogger } from '../../helpers/errorLogger';
import { initialFetchingSuccess, login, logout } from '../user/actions';
import { setPermisions } from './actions';

function* watchSetPermisions() {
  yield takeEvery([login.success, initialFetchingSuccess], function* workSetPermisions() {
    try {
      yield put(setPermisions.success());
    } catch (e) {
      errorLogger(e);
    }
  });
}

function* watchRemovePermisions() {
  yield takeEvery(logout.success, function* workRemovePermisions() {
    try {
      yield put(setPermisions({}));
    } catch (e) {
      errorLogger(e);
    }
  });
}

export default function* permissionsWatchers() {
  yield all([watchSetPermisions(), watchRemovePermisions()]);
}
