import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';
import { getStyle } from './helpers';
import Card from '../../../../../../../../components/card/Card';
import { ColumnContext } from '../../context';

const Item = ({ provided, item, isDragging, openEdit, showMenu, setShowMenu, listId }) => {
  const { column } = useContext(ColumnContext);
  const { id: boardId } = useParams();
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({
        draggableStyle: provided.draggableProps.style,
        isDragging,
      })}
      className={`item orderItem ${isDragging ? 'is-dragging orderItem' : ''}`}
    >
      {!item ? (
        'loading'
      ) : (
        <Card
          setShowMenu={setShowMenu}
          productName={item?.data?.products}
          item={item}
          column={column}
          boardId={boardId}
          openEdit={openEdit}
          showMenu={showMenu}
          listId={listId}
        />
      )}
    </div>
  );
};

Item.propTypes = {
  provided: PropTypes.object,
  item: PropTypes.object,
  style: PropTypes.object,
  isDragging: PropTypes.bool,
  column: PropTypes.object,
  openEdit: PropTypes.func,
  showMenu: PropTypes.bool,
  setShowMenu: PropTypes.func,
  listId: PropTypes.string,
};

export default Item;
