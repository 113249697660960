import React, { useMemo, Suspense, lazy, useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import UserInfo from './components/user-info/UserInfo';
import { isBoardPage } from './helpers';
import HouseSvg from '../../../svgs/HoseSvg';
import Button from '../../../components/button/Button';
import { selectDraggableBoard, selectGetBoardName } from '../../../store/draggable-board/selector';
import { getBoardName, toggleArchive } from '../../../store/draggable-board/actions';
import ControlArrow from '../../../svgs/ControlArrow';
import useWindowSize from '../../../hooks/useWindowSize';
import MobileViewSettings from './components/mobile-view-settings/MobileViewSettings';
import ThreePointSvg from '../../../svgs/ThreePointSvg';
import { selectLang, selectUserRole } from '../../../store/user/selectors';
import { Lang } from '../../../T';

const BoardFilters = lazy(() => import('./components/board-filters/BoardFilters'));

const Header = ({ path }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const { archived, loading } = useSelector(selectDraggableBoard);
  const { loading: boardNameLoading, boardName } = useSelector(selectGetBoardName);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const role = useSelector(selectUserRole);
  const boardPage = useMemo(() => isBoardPage(pathname), [pathname]);
  const { size } = useWindowSize();
  const [renderSideBar, setRenderSideBar] = useState(false);
  const headerStyles = useMemo(
    () => ({
      borderBottom: !boardPage || (boardPage && size[0] < 768) ? '' : '1px solid #DCE0E5',
    }),
    [boardPage, size]
  );
  const { id } = useParams();
  const handleGetBoardName = useCallback(() => {
    dispatch(getBoardName({ boardId: id }));
  }, [id]);
  useEffect(() => {
    if (id) handleGetBoardName();
  }, [id]);
  return (
    <div
      className={`d-flex justify-content-between pl-5 pr-5 headerPart ${
        path === '/board/:id/:filterId' ? 'BoardPage' : ''
      } ${archived && boardPage ? 'archive_page' : ''}
        ${!archived && !boardPage ? 'all-pages_header' : ''}
      `}
      style={headerStyles}
    >
      <div className="w-100 align-items-center h-100">
        {boardPage &&
          (size[0] > 768 ? (
            <Suspense fallback="...loading">
              {role !== 'deliveryMan' && (
                <div className="d-flex align-items-cen  ter w-100 h-100">
                  <div className="mr-5 d-flex align-items-center h-100 houseLinkParent">
                    <Link to="/home">
                      <HouseSvg />
                    </Link>
                    <span className="ml-3 text-white headerSpan">{boardNameLoading ? '...loading' : boardName}</span>
                  </div>
                  <BoardFilters />
                </div>
              )}
            </Suspense>
          ) : (
            <div className="d-flex align-items-cen  ter w-100 h-100">
              {role !== 'deliveryMan' && (
                <div className="mr-5 d-flex align-items-center h-100 houseLinkParent">
                  <Link to="/home">
                    <HouseSvg fillColor="#43425D" />
                  </Link>
                  <span className="ml-md-3 headerSpan">{loading ? '...loading' : boardName}</span>
                </div>
              )}
            </div>
          ))}
      </div>
      {archived && boardPage && (
        <Button
          className="goToBoardPage"
          onClick={() => {
            dispatch(toggleArchive());
          }}
        >
          <ControlArrow />
          {T.back} {size[0] > 768 && T.main}
        </Button>
      )}
      {size[0] < 769 && boardPage && <BoardFilters />}
      {path === '/board/:id/:filterId' && (
        <div>
          <UserInfo path={path} />
        </div>
      )}
      {size[0] < 769 && boardPage && (
        <div
          className="menuButton"
          onClick={() => {
            setRenderSideBar((prev) => !prev);
          }}
        >
          <ThreePointSvg />
        </div>
      )}
      {size[0] < 769 && boardPage && renderSideBar && <MobileViewSettings />}
    </div>
  );
};
Header.propTypes = {
  path: PropTypes.string,
};

export default Header;
