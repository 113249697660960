import React from 'react';

const SmilyEmoji = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Component_47_1"
      data-name="Component 47 – 1"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <rect id="Rectangle_11117" data-name="Rectangle 11117" width="30" height="30" fill="none" />
      <g id="Group_7036" data-name="Group 7036" transform="translate(5674.673 11113.473)">
        <g id="Group_7035" data-name="Group 7035" transform="translate(-5674.673 -11113.473)">
          <g id="Group_6988" data-name="Group 6988" transform="translate(0 0)">
            <g id="Group_6980" data-name="Group 6980" transform="translate(0)">
              <g id="Group_6979" data-name="Group 6979" transform="translate(0 0)">
                <g id="Group_6976" data-name="Group 6976">
                  <circle id="Ellipse_1455" data-name="Ellipse 1455" cx="15" cy="15" r="15" fill="#ffd053" />
                </g>
                <g id="Group_6977" data-name="Group 6977" transform="translate(2.36 2.197)">
                  <path
                    id="Path_6838"
                    data-name="Path 6838"
                    d="M126.6,742.414a15,15,0,0,1-20.488,20.865A15,15,0,1,0,126.6,742.414Z"
                    transform="translate(-106.113 -742.414)"
                    fill="#ffc448"
                  />
                </g>
                <g id="Group_6978" data-name="Group 6978" transform="translate(1.002 1.107)">
                  <path
                    id="Path_6839"
                    data-name="Path 6839"
                    d="M99.434,751.7a13.926,13.926,0,0,1,24.966-8.49,13.928,13.928,0,1,0-23.115,15.431A13.862,13.862,0,0,1,99.434,751.7Z"
                    transform="translate(-98.397 -736.221)"
                    fill="#ffd77a"
                  />
                </g>
              </g>
              <path
                id="Path_6840"
                data-name="Path 6840"
                d="M139.049,827.38a.5.5,0,0,0-.805.54,7.519,7.519,0,0,0,13.9.138.5.5,0,0,0-.8-.546C149.153,829.641,144.493,832.669,139.049,827.38Z"
                transform="translate(-130.197 -810.111)"
                fill="#2c2c2c"
              />
            </g>
            <g id="Group_6984" data-name="Group 6984" transform="translate(8.369 10.453)">
              <g id="Group_6983" data-name="Group 6983" transform="translate(0 0)">
                <g id="Group_6981" data-name="Group 6981">
                  <path
                    id="Path_6841"
                    data-name="Path 6841"
                    d="M142.466,791.187c0,1.029-.495,1.863-1.105,1.863s-1.105-.834-1.105-1.863.495-1.863,1.105-1.863S142.466,790.158,142.466,791.187Z"
                    transform="translate(-140.256 -789.324)"
                    fill="#2c2c2c"
                  />
                </g>
                <g id="Group_6982" data-name="Group 6982" transform="translate(11.052)">
                  <path
                    id="Path_6842"
                    data-name="Path 6842"
                    d="M205.271,791.187c0,1.029-.495,1.863-1.105,1.863s-1.105-.834-1.105-1.863.495-1.863,1.105-1.863S205.271,790.158,205.271,791.187Z"
                    transform="translate(-203.06 -789.324)"
                    fill="#2c2c2c"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SmilyEmoji;
