import { Lang } from '../../T';

const config = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

const converseObjectToString = (object, lang) => {
  const T = Lang(lang);
  const keys = {
    name: T.key_name,
    color: T.key_color,
    address: T.key_address,
    price: T.key_price,
    fullName: T.key_fullName,
    deliveryStart: T.key_deliveryStart,
    deliveryEnd: T.key_deliveryEnd,
    city: T.key_city,
    phone1: T.key_phone,
    phone2: T.key_phone,
    deliveryPrice: T.key_deliveryPrice,
    count: T.key_count,
  };

  if (lang === 'am') {
    return (
      object &&
      Object.keys(object)
        .map((key) => {
          return object[key]?.from || object[key]?.to
            ? `${keys[key] || key} "${
                (key.includes('Start') || key.includes('End')) && object[key]?.from !== null
                  ? new Date(object[key]?.from).toLocaleString('ru', config)
                  : object[key]?.from
              }"-ից "${
                (key.includes('Start') || key.includes('End')) && object[key]?.to !== null
                  ? new Date(object[key]?.to).toLocaleString('ru', config)
                  : object[key]?.to
              }"`
            : '';
        })
        .filter((item) => !!item)
        .join(', ')
    );
  }
  if (lang === 'en') {
    return (
      object &&
      Object.keys(object)
        .map((key) => {
          return object[key]?.from || object[key]?.to
            ? `${keys[key] || key} from "${
                (key.includes('Start') || key.includes('End')) && object[key]?.from !== null
                  ? new Date(object[key]?.from).toLocaleString('ru', config)
                  : object[key]?.from
              }" to "${
                (key.includes('Start') || key.includes('End')) && object[key]?.to !== null
                  ? new Date(object[key]?.to).toLocaleString('ru', config)
                  : object[key]?.to
              }"`
            : '';
        })
        .filter((item) => !!item)
        .join(', ')
    );
  }
  return JSON.stringify(object);
};

export const messages = (lang, item) => {
  if (lang === 'am') {
    const T = Lang(lang);
    return {
      // Order
      101: `Ստեղծեց պատվեր ${item?.listItemId} "${item?.board?.name}" հարթակում`,
      102: `Փոփոխեց ${
        item?.additionalData?.orderFields && converseObjectToString(item?.additionalData?.orderFields, 'am')
      } ${item?.listItemId} պատվերում "${item?.board?.name}" հարթակում`,
      103: `Փոփոխեց ${item?.listItemId} պատվերի տեսակը "${item?.board?.name}" հարթակում`,
      104: `Դարձրեց ${item?.listItemId} պատվերի կարգավիճակը "${T[item?.additionalData?.status]}" "${
        item?.board?.name
      }" հարթակում`,
      105: `Արխիվացրեց ${item?.listItemId} պատվերը "${item?.board?.name}" հարթակում`,
      106: `Ապաարխիվացրեց ${item?.listItemId} պատվերը "${item?.board?.name}" հարթակում`,
      107: `Տեղափոխեց ${item?.listItemId} պատվերը "${item?.additionalData?.oldList?.name}"-ից "${item?.list?.name}" "${item?.board?.name}" հարթակում `,
      108: `Ջնջեց ${item?.listItemId} պատվերը "${item?.board?.name}" հարթակում`,
      109: `Նշանակեց ${item?.additionalData?.user?.firstName} ${item?.additionalData?.user?.lastName}-ին ${item?.listItemId} պատվերի օպերատոր "${item?.board?.name}" հարթակում`,
      110: `Ավելացրեց "${item?.additionalData?.name}" պիտակը ${item?.listItemId} պատվերում "${item?.board?.name}" հարթակում`,
      111: `Ջնջեց "${item?.additionalData?.name}" պիտակը ${item?.listItemId} պատվերում "${item?.board?.name}" հարթակում`,
      112: `Փոխեց ${item?.listItemId} պատվերի վերադարձի կամ փոխանակման կարգավիճակը "${item?.board?.name}" հարթակում`,
      113: `Ավելացրեց նոր վեբ ապրանք ${item?.listItemId} պատվերում "${item?.board?.name}" հարթակում`,
      114: `Հետ բերեց ${item?.listItemId} պատվերի ընդհանուր կարգավիճակը "${item?.board?.name}" հարթակում`,
      115: `Պատճենեց ${item?.additionalData?.sourceListItemId} պատվերը և ստացավ ${item?.listItemId} նոր պատվեր "${item?.board?.name}" հարթակում`,
      116: `Ստեղծեց վերադարձի կամ փոխանակման հարցում ${item?.listItemId} պատվերի համար "${item?.board?.name}" հարթակում`,

      // Product

      201: `Ստեղծեց  "${item?.additionalData?.name}" ապրանք`,
      202: `Փոփոխեց ${item?.additionalData && converseObjectToString(item?.additionalData, 'am')} "${
        item?.additionalData?.product?.name
      }" ապրանքի  համար`,
      203: `Ջնջեց "${item?.additionalData?.name}" ապրանքը`,
      204: `Փոխեց "${item?.additionalData?.product?.name}" ապրանքի կարգավիճակը`,

      // Label

      301: `Ստեղծեց "${item?.additionalData?.name}" պիտակը`,
      302: `Փոփոխեց ${item?.additionalData && converseObjectToString(item?.additionalData, 'am')} "${
        item?.additionalData?.name?.from
      }" պիտակի համար`,
      303: `Ջնջեց "${item?.additionalData?.name}" պիտակը`,

      // User

      401: `Գրանցեց "${item?.additionalData?.user?.email}" էլ․ հասցեով նոր օգտատեր`,
      402: 'Փոփոխեց իր անձնական տվյալները',
      403: `Փոփոխեց ${item?.additionalData?.changes && converseObjectToString(item?.additionalData?.changes, 'am')} "${
        item?.additionalData?.firstName
      } ${item?.additionalData?.lastName}" օգտատերի համար`,
      404: `Ջնջեց "${item?.additionalData?.user?.email}" էլ․ հասցեով օգտատերին`,
      405: `Կրկին ուղարկեց հրավերը "${item?.additionalData?.user?.email}"-ին`,
      406: 'Հաջողությամբ գրանցվեց',

      // List

      501: `Ավելացրեց նոր "${item?.additionalData?.name}" սյունակ "${item?.board?.name}" հարթակում`,
      502: `Փոխեց սյունակի անունը "${item?.additionalData?.name?.from}"-ից "${item?.additionalData?.name?.to}" "${item?.board?.name}" հարթակում`,
      503: `Ջնջեց "${item?.list?.name}" սյունակը "${item?.board?.name}" հարթակում`,
      504: `Արխիվացրեց "${item?.additionalData?.name}" սյունակը "${item?.board?.name}" հարթակում`,
      505: `Ապաարխիվացրեց "${item?.additionalData?.name}" սյունակը "${item?.board?.name}" հարթակում`,

      // Domain

      601: `Ստեղծեց "${item?.additionalData?.name}" դոմենը`,
      602: `Փոփոխեց "${item?.additionalData?.name}" դոմենը`,
      603: `Ջնջեց "${item?.additionalData?.name}" դոմենը`,

      // Board

      701: `Ստեղծեց "${item?.additionalData?.name}" հարթակը`,
      702: `Փոփոխեց ${item?.additionalData && converseObjectToString(item?.additionalData, 'am')} "${
        item?.board?.name
      }" հարթակը`,
      703: `Ջնջեց "${item?.board?.name}" հարթակը`,

      // Deliveryman

      801: 'վերադարձրեց ապրանքները',
      802: 'ընդունեց ապրանքները',
      803: 'թարմացրեց ազատ ապրանքները',
      804: 'ջնջեց ազատ ապրանքները',

      // Rate

      901: `Ավելացրեց նոր գնահատական "${item?.additionalData?.phone}" հաճախորդին`,
      902: `Ջնջեց "${item?.additionalData?.phone}" հաճախորդի գնահատական`,

      // Operator

      1001: `Վճարեց "${item?.additionalData?.user?.firstName} ${item?.additionalData?.user?.lastName}" օպերատորի աշխատավարձը`,
      1002: `Ջնջեց "${item?.additionalData?.phone}" հաճախորդին`,
      1003: 'Ուղղարկեց պատվերները "Մարզեր" սյունակ',
      1004: 'հաստատեց մարզերի գումարը',

      // Comment

      1101: `Ավելացրեց նոր մեկնաբանություն ${item?.listItemId} պատվերում "${item?.board?.name}" հարթակում`,

      // Description

      1201: `Ավելացրեց նոր նկարագրություն "${item?.additionalData?.inputId}" ներմուծման հարցման մեջ `,

      // Warehouse

      1301: `Ստեղծեց ապրանքի ${item?.additionalData?.type} (${item?.additionalData?.value} միավոր)  հարցում "${item?.additionalData?.product?.name}" ապրանքի համար`,
      1302: `Փոխեց ${
        item?.additionalData?.value && converseObjectToString({ count: item?.additionalData?.value }, 'am')
      } "${item?.additionalData?.inputId}" ապրանքի մուտքագրման հարցում`,
      1303: `Վերանայեց "${item?.additionalData?.inputId}" ապրանքի ստուգման ${item?.additionalData?.input?.type} հարցումը: կարգավիճակը ${item?.additionalData?.status}`,
      1304: `Ստեղծեց ապրանքի ստուգման ${item?.additionalData?.type} հարցում "${item?.additionalData?.inputId}" մուտքագրման հարցման համար`,

      1305: `Փոխեց ${
        item?.additionalData?.value && converseObjectToString({ count: item?.additionalData?.value }, 'am')
      } "${item?.additionalData?.inputId}" ապրանքի ${item?.additionalData?.input?.type} հարցման մեջ`,
    };
  }

  return {
    // Order
    101: `Has created order ${item?.listItemId} on "${item?.board?.name}" board`,
    102: `Has edited ${
      item?.additionalData?.orderFields && converseObjectToString(item?.additionalData?.orderFields, 'en')
    } in ${item?.listItemId} order on "${item?.board?.name}" board`,
    103: `Has changed ${item?.listItemId} order type on "${item?.board?.name}" board`,
    104: `Has made order ${item?.listItemId} status "${item?.additionalData?.status}" on "${item?.board?.name}" board`,
    105: `Has archived order ${item?.listItemId} on "${item?.board?.name}" board`,
    106: `Has dearchived order ${item?.listItemId} on "${item?.board?.name}" board`,
    107: `Has moved order ${item?.listItemId} from "${item?.additionalData?.oldList?.name}" to "${item?.list?.name}" on "${item?.board?.name}" board `,
    108: `Has deleted order ${item?.listItemId} on "${item?.board?.name}" board`,
    109: `Has assigned ${item?.additionalData?.user?.firstName} ${item?.additionalData?.user?.lastName} as operator for order ${item?.listItemId} on "${item?.board?.name}" board`,
    110: `Has added label "${item?.additionalData?.name}" in order ${item?.listItemId} on "${item?.board?.name}" board`,
    111: `Has removed label "${item?.additionalData?.name}" in order ${item?.listItemId} on "${item?.board?.name}" board`,
    112: `Has cleared order ${item?.listItemId} return or change type on "${item?.board?.name}" board`,
    113: `add web product for order ${item?.listItemId} on "${item?.board?.name}" board`,
    114: `Has reverted order ${item?.listItemId} status on "${item?.board?.name}" board`,
    115: `Has copied order ${item?.additionalData?.sourceListItemId} and has got ${item?.listItemId} new order on "${item?.board?.name}" board`,
    116: `Has created product return or change request for order ${item?.listItemId} on "${item?.board?.name}" board`,

    // Product

    201: `Has created "${item?.additionalData?.name}" product`,
    202: `Has edited ${item?.additionalData && converseObjectToString(item?.additionalData, 'en')} for "${
      item?.additionalData?.product?.name
    }" product`,
    203: `Has deleted "${item?.additionalData?.name}" product `,
    204: `Has changed product "${item?.additionalData?.product?.name}" status`,

    // Label

    301: `Has created label "${item?.additionalData?.name}"`,
    302: `Has edited ${item?.additionalData && converseObjectToString(item?.additionalData, 'en')} for label "${
      item?.additionalData?.name?.from
    }"`,
    303: `Has deleted label "${item?.additionalData?.name}"`,

    // User

    401: `Has invited new user via "${item?.additionalData?.user?.email}"`,
    402: 'Has edited his(her) profile',
    403: `Has edited ${
      item?.additionalData?.changes && converseObjectToString(item?.additionalData?.changes, 'en')
    } for ${item?.additionalData?.firstName} ${item?.additionalData?.lastName} user`,
    404: `Has deleted user with "${item?.additionalData?.user?.email}" email`,
    405: `Has resended inivitation to "${item?.additionalData?.user?.email}" email`,
    406: 'Has signed up successfully',

    // List

    501: `Has created list "${item?.additionalData?.name}" on "${item?.board?.name}" board`,
    502: `Has renamed list from "${item?.additionalData?.name?.from}" to "${item?.additionalData?.name?.to}" on "${item?.board?.name}" board`,
    503: `Has deleted list "${item?.list?.name}" on "${item?.board?.name}" board`,
    504: `Has archived list "${item?.additionalData?.name}" on "${item?.board?.name}" board`,
    505: `Has dearchived list "${item?.additionalData?.name}" on "${item?.board?.name}" board`,

    // Domain

    601: `Has created "${item?.additionalData?.name}" domain`,
    602: `Has updated "${item?.additionalData?.name}" domain`,
    603: `Has deleted "${item?.additionalData?.name}" domain`,

    // Board

    701: `Has created "${item?.additionalData?.name}" board`,
    702: `Has changed ${item?.additionalData && converseObjectToString(item?.additionalData, 'en')} "${
      item?.board?.name
    }" board`,
    703: `Has deleted "${item?.board?.name}" board`,

    // Deliveryman

    801: 'has returned product',
    802: 'has accepted product',
    803: 'spare product update',
    804: 'delete deliveryman spare product',

    // Rate

    901: `Has added new rate for "${item?.additionalData?.phone}" customer`,
    902: `Has removed rate from "${item?.additionalData?.phone}" customer`,

    // Operator

    1001: `Has paid  "${item?.additionalData?.user?.firstName} ${item?.additionalData?.user?.lastName}" operator salary`,
    1002: `Has deleted "${item?.additionalData?.phone}" customer`,
    1003: 'Has sent orders to "State" column',
    1004: 'accept state money',

    // Comment

    1101: `Has added new comment in order ${item?.listItemId} on "${item?.board?.name}" board`,

    // Description

    1201: `Has added new descritpion for "${item?.additionalData?.inputId}" input request `,

    // Warehouse

    1301: `Has created product ${item?.additionalData?.type} (${item?.additionalData?.value} unit) request for "${item?.additionalData?.product?.name}" product`,
    1302: `Has edited ${
      item?.additionalData?.value && converseObjectToString({ count: item?.additionalData?.value }, 'en')
    } in product input "${item?.additionalData?.inputId}" request`,
    1303: `Has reviewed "${item?.additionalData?.inputId}" product ${item?.additionalData?.input?.type} request: status ${item?.additionalData?.status}`,
    1304: `Has created product ${item?.additionalData?.type} request for input request "${item?.additionalData?.inputId}"`,
    1305: `Has edited ${
      item?.additionalData?.value && converseObjectToString({ count: item?.additionalData?.value }, 'en')
    } in "${item?.additionalData?.inputId}" product ${item?.additionalData?.input?.type} request`,
  };
};
