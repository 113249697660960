import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCustomerByPhone,
  getAddressesByPhone,
  getCustomers,
  getCustomersCount,
  getRatesByPhone,
  printBuyers,
} from '../../store/customers/actions';
import {
  selectAddresses,
  selectCustomers,
  selectCustomersCount,
  selectDeletedSuccess,
  selectRates,
} from '../../store/customers/selectors';
import { openModal } from '../../store/modals/actions';
import { getUserOrdersByPhone } from '../../store/user/actions';
import { selectLang, selectUserOrdersByPhone, selectUserRole } from '../../store/user/selectors';
import { LIMIT_ORDERS } from './constants';
import { modalNames } from '../../store/modals/constants';
import { useOutsideDetect } from '../../hooks/useOutsideDetect';
import { Lang } from '../../T';

const useCustomers = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const dispatch = useDispatch();

  const { data: customers, success } = useSelector(selectCustomers);
  const { data: count } = useSelector(selectCustomersCount);
  const { data: orders } = useSelector(selectUserOrdersByPhone);

  const { data: addresses } = useSelector(selectAddresses);
  const { data: rates } = useSelector(selectRates);
  const { success: deletedSuccess } = useSelector(selectDeletedSuccess);
  const role = useSelector(selectUserRole);

  const [filterMenu, setFilterMenu] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const [showRatesDropDown, setShowRatesDropDown] = useState(false);

  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [orderOffset, setOrderOffset] = useState(0);

  const [id, setId] = useState('');

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [allCheckboxIds, setAllCheckboxIds] = useState('empty');
  const [checkboxIds, setCheckboxIds] = useState([]);
  const [deletedItems, setDeletedItems] = useState([]);
  const [copiedTextId, setCopiedTextId] = useState('');

  const [filterParams, setFilterParams] = useState({
    sex: '',
    status: '',
    state: '',
    searchWord: '',
    minOrderDate: '',
    maxOrderDate: '',
    minOrderCount: undefined,
    maxOrderCount: undefined,
    minSpent: undefined,
    maxSpent: undefined,
    limit,
    offset,
  });

  const handleResetSearch = () => {
    setFilterParams((prev) => ({ ...prev, searchWord: '' }));
  };

  const pagesCount = useMemo(() => {
    if (customers?.length && !count) {
      return Math.ceil(customers?.length / limit);
    }
    return Math.ceil(count / limit);
  }, [count, limit, customers?.length]);

  const changeLimit = ({ value }) => {
    setLimit(value);
    setOffset(0);
  };

  const handleFilterCustomers = (data, key) => {
    setFilterParams((prev) => ({ ...prev, [key]: data }));
  };
  const [timerId, setTimerId] = useState();

  const handleChange = useCallback(
    ({ target: { value } }) => {
      if (timerId) {
        clearTimeout(timerId);
      }
      setTimerId(
        setTimeout(() => {
          dispatch(
            getCustomers({
              T,
              searchWord: value,
              limit,
              offset,
              ...(filterParams.minOrderDate?.length ? { minOrderDate: filterParams.minOrderDate } : {}),
              ...(filterParams.maxOrderDate?.length ? { maxOrderDate: filterParams.maxOrderDate } : {}),
              sex: filterParams.sex.value,
              status: filterParams.status.value,
              state: filterParams.state.value,
              minOrderCount: filterParams.minOrderCount,
              maxOrderCount: filterParams.maxOrderCount,
              minSpent: filterParams.minSpent || 0,
              maxSpent: filterParams.maxSpent || 0,
            })
          );
        }, 500)
      );
    },
    [timerId, filterParams]
  );

  useEffect(() => {
    getInitialCustomersCount();
  }, [deletedSuccess]);

  useEffect(() => {
    getInitialCustomersCount();
  }, [customers?.length]);

  useEffect(() => {
    if (!filterParams.searchWord.length) {
      getSearch();
      getInitialCustomersCount();
    }
  }, [filterParams.searchWord]);

  useEffect(() => {
    getSearch();
    getInitialCustomersCount();
  }, [filterParams]);

  const getSearch = () => {
    setOffset(0);
    dispatch(
      getCustomers({
        T,
        sex: filterParams.sex.value,
        status: filterParams.status.value,
        state: filterParams.state.value,
        minOrderCount: filterParams.minOrderCount,
        maxOrderCount: filterParams.maxOrderCount,
        minSpent: filterParams.minSpent || 0,
        maxSpent: filterParams.maxSpent || 0,
        limit,
        offset,
        ...(filterParams.searchWord?.length ? { searchWord: filterParams.searchWord } : {}),
        ...(filterParams.minOrderDate?.length ? { minOrderDate: filterParams.minOrderDate } : {}),
        ...(filterParams.maxOrderDate?.length ? { maxOrderDate: filterParams.maxOrderDate } : {}),
      })
    );
  };

  const getInitialCustomersCount = useCallback(() => {
    dispatch(
      getCustomersCount({
        T,
        sex: filterParams.sex.value,
        status: filterParams.status.value,
        state: filterParams.state.value,
        minOrderCount: filterParams.minOrderCount,
        maxOrderCount: filterParams.maxOrderCount,
        minSpent: filterParams.minSpent || 0,
        maxSpent: filterParams.maxSpent || 0,
        ...(filterParams.searchWord?.length ? { searchWord: filterParams.searchWord } : {}),
        ...(filterParams.minOrderDate?.length ? { minOrderDate: filterParams.minOrderDate } : {}),
        ...(filterParams.maxOrderDate?.length ? { maxOrderDate: filterParams.maxOrderDate } : {}),
      })
    );
  }, [filterParams]);

  const resetFilters = () => {
    setFilterParams((prev) => ({
      sex: '',
      status: '',
      state: '',
      searchWord: prev.searchWord,
      minOrderDate: '',
      maxOrderDate: '',
      minOrderCount: undefined,
      maxOrderCount: undefined,
      minSpent: undefined,
      maxSpent: undefined,
      limit,
      offset,
    }));
    setDateRange([]);
  };

  const handleDeleteCustomerByPhone = (phone) => {
    dispatch(
      openModal({
        name: modalNames.confirmation,
        data: {
          title: T.delete,
          description: T.are_you_sure_delete,
          okText: T.delete,
          onOk: () => {
            dispatch(deleteCustomerByPhone({ phone, T }));
          },
        },
      })
    );
  };

  const toggleFilterMenu = () => {
    setFilterMenu(!filterMenu);
  };

  const isDirty = useMemo(() => {
    const { maxOrderDate, maxOrderCount, minOrderDate, maxSpent, minSpent, status, sex, state, minOrderCount } =
      filterParams;

    return (
      !!(
        minOrderDate ||
        maxOrderDate ||
        maxOrderCount ||
        minOrderCount ||
        maxSpent ||
        minSpent ||
        status ||
        sex ||
        state
      ) || false
    );
  }, [filterParams]);

  const handleAllCheckboxIds = () => {
    setAllCheckboxIds((prev) =>
      prev === 'empty' ? 'all' : prev === 'all' ? 'empty' : prev === 'partial' ? 'all' : ''
    );
    if (allCheckboxIds === 'all') {
      setCheckboxIds([]);
    }
  };

  useEffect(() => {
    if (allCheckboxIds === 'all') {
      setCheckboxIds([]);
      setDeletedItems([]);
    } else if (allCheckboxIds === 'partial') {
      setCheckboxIds([]);
    }
  }, [allCheckboxIds]);

  useEffect(() => {
    if (deletedItems.length && allCheckboxIds === 'all') {
      setAllCheckboxIds('partial');
    } else if (!deletedItems.length && allCheckboxIds !== 'empty') {
      setAllCheckboxIds('all');
    }
  }, [deletedItems]);

  const handleCheckboxId = (event) => {
    const { id } = event.target;
    const stringyId = id?.toString();
    if (deletedItems.length && deletedItems.includes(stringyId)) {
      setDeletedItems((prev) => prev.filter((el) => el.toString() !== stringyId));
    } else if (!deletedItems.includes(stringyId) && checkboxIds.includes(stringyId)) {
      if (allCheckboxIds === 'all' || allCheckboxIds === 'partial') {
        setDeletedItems((prev) => [...prev, stringyId]);
      }
      setCheckboxIds((prev) => prev.filter((el) => el.toString() !== stringyId));
    } else if (deletedItems.length && !deletedItems.includes(stringyId)) {
      if (allCheckboxIds === 'all' || allCheckboxIds === 'partial') {
        setDeletedItems((prev) => [...prev, stringyId]);
      }
    } else if (!deletedItems.includes(stringyId) && !checkboxIds.includes(stringyId)) {
      if (allCheckboxIds === 'all') {
        setDeletedItems((prev) => [...prev, stringyId]);
        setAllCheckboxIds('partial');
      } else if (allCheckboxIds === 'empty') {
        setCheckboxIds((prev) => [...prev, stringyId]);
      }
    } else if (checkboxIds.includes(stringyId)) {
      if (allCheckboxIds === 'all' || allCheckboxIds === 'partial') {
        setDeletedItems((prev) => [...prev, stringyId]);
      }
      setCheckboxIds((prev) => prev.filter((el) => el.toString() !== stringyId));
    } else {
      setCheckboxIds((prev) => [...prev, stringyId]);
    }
  };

  const handleMoreCustomerData = (phoneId) => {
    setId(phoneId);
    if (phoneId === id && showMore) {
      setShowMore((prev) => !prev);
    } else {
      setShowMore(true);
      dispatch(getUserOrdersByPhone({ mainPhone: phoneId, offset: 0, T }));
      dispatch(getAddressesByPhone({ T, phoneId }));
    }
    dispatch(getUserOrdersByPhone.reset());
    setOrderOffset(0);
  };

  const getMoreOrders = (phoneId) => {
    const newOffset = orderOffset + LIMIT_ORDERS;
    if (phoneId !== id) {
      setOrderOffset(0);
    } else {
      setOrderOffset(newOffset);
    }
    dispatch(getUserOrdersByPhone({ mainPhone: phoneId, offset: newOffset, T }));
  };

  const loadMoreRates = (phoneId) => {
    dispatch(getRatesByPhone({ T, phoneId }));
    setShowRatesDropDown((prev) => !prev);
    setId(phoneId);
  };

  const handlePrintBuyers = () => {
    dispatch(
      printBuyers({
        T,
        checkboxIds,
        deletedItems,
        allCheckboxIds,
        ...(allCheckboxIds === 'all' || allCheckboxIds === 'partial' ? { filterParams } : {}),
      })
    );
  };

  const handleCopyCustomerName = (name, phone1) => {
    setCopiedTextId(phone1);
    setTimeout(() => setCopiedTextId(''), 1000);
    navigator.clipboard.writeText(name);
  };
  const outSideRef = useOutsideDetect(setShowRatesDropDown);

  useEffect(() => {
    setOffset(page * limit - limit);
  }, [page]);

  useEffect(() => {
    setFilterParams((prev) => ({
      ...prev,
      maxOrderDate: endDate?.toLocaleString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
      minOrderDate: startDate?.toLocaleString('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    }));
  }, [startDate, endDate]);

  useEffect(() => {
    dispatch(
      getCustomers({
        T,
        searchWord: filterParams.searchWord,
        limit,
        offset,
        ...(filterParams.minOrderDate?.length ? { minOrderDate: filterParams.minOrderDate } : {}),
        ...(filterParams.maxOrderDate?.length ? { maxOrderDate: filterParams.maxOrderDate } : {}),
        sex: filterParams.sex.value,
        status: filterParams.status.value,
        state: filterParams.state.value,
        minOrderCount: filterParams.minOrderCount,
        maxOrderCount: filterParams.maxOrderCount,
        minSpent: filterParams.minSpent || 0,
        maxSpent: filterParams.maxSpent || 0,
      })
    );
  }, [offset, limit, deletedSuccess]);

  useEffect(() => {
    if (success) getInitialCustomersCount();
  }, [success]);

  useEffect(() => {
    setPage(1);
  }, [limit]);

  useEffect(() => {
    return () => dispatch(getUserOrdersByPhone.reset());
  }, []);

  const IS_YEREVAN = process.env.REACT_APP_LANG === 'true';

  const genders = [
    { label: T.all, value: '' },
    { label: T.male, value: 'male' },
    { label: T.female, value: 'female' },
  ];
  const types = [
    { label: T.all, value: '' },
    {
      label: `${T.gold} > ${IS_YEREVAN ? '50.000' : '351'}  ${IS_YEREVAN ? T.money_type_am : T.money_type}`,
      value: 'gold',
    },
    {
      label: `${T.silver} ${IS_YEREVAN ? '20.001 - 50.000' : '151-350'} ${IS_YEREVAN ? T.money_type_am : T.money_type}`,
      value: 'silver',
    },
    {
      label: `${T.bronze} ${IS_YEREVAN ? '1 - 20.000' : '1-150'} ${IS_YEREVAN ? T.money_type_am : T.money_type}`,
      value: 'bronze',
    },
  ];
  const statuses = [
    { label: T.all, value: '' },
    { label: 'New order', value: 'gold' },
    { label: 'Silver', value: 'silver' },
    { label: 'Bronze', value: 'bronze' },
  ];
  const states = [
    { label: T.all, value: '' },
    { label: T.new, value: 'new' },
    { label: T.sailed, value: 'sale' },
    { label: T.rejected, value: 'rejected' },
    { label: T.edited, value: 'change' },
    { label: T.return, value: 'return' },
  ];

  return {
    genders,
    types,
    statuses,
    states,
    filterMenu,
    allCheckboxIds,
    filterParams,
    startDate,
    endDate,
    customers,
    checkboxIds,
    count,
    limit,
    pagesCount,
    page,
    showMore,
    id,
    orders,
    addresses,
    showRatesDropDown,
    rates,
    orderOffset,
    handleChange,
    handleResetSearch,
    setPage,
    setDateRange,
    handleFilterCustomers,
    toggleFilterMenu,
    handleAllCheckboxIds,
    handleCheckboxId,
    resetFilters,
    getSearch,
    changeLimit,
    handleMoreCustomerData,
    handleDeleteCustomerByPhone,
    getMoreOrders,
    loadMoreRates,
    handlePrintBuyers,
    role,
    handleCopyCustomerName,
    copiedTextId,
    outSideRef,
    isDirty,
    deletedItems,
  };
};

export default useCustomers;
