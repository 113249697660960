import React from 'react';

const HipnosSvg = () => {
  return (
    <svg width="1920" height="1011" viewBox="0 0 1920 1011" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_501_159"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="1920"
        height="1011"
      >
        <rect width="1920" height="1010.92" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_501_159)">
        <path
          d="M730.51 922.259C714.355 1127.43 792.085 1360.58 947.274 1572.45C1017.19 1668.66 1095.57 1761.09 1181.16 1848.26C1336.87 2000.93 1515 2131.4 1704.23 2231.38L1797.07 2150.44C1733.66 2120.36 1670.94 2087.25 1609.22 2051.27C1388.51 1926.23 1189.88 1753.62 1037.2 1554.18C915.328 1395.78 830.667 1223.79 791.521 1055.08C758.06 888.553 784.376 736.235 866.345 622C806.305 687.444 763.122 766.71 738.629 856.437C732.5 888 731.697 898.845 730.51 922.259Z"
          fill="white"
        />
        <path
          d="M998.275 1012.51C1081.2 1162.57 1195.14 1304.8 1330.8 1427.59C1461.28 1539.6 1603.87 1634.89 1751.85 1708.96C1865.21 1760.81 1979.52 1803.23 2093.16 1835.64L2201.91 1739.66C2185.6 1737.38 2168.55 1734.37 2150.66 1733.48C1921.71 1692.63 1684.16 1591.46 1464.74 1441.36C1305.98 1334.64 1167.43 1194.75 1066.62 1039.38C965.82 884.012 907.287 720.147 898.429 568.512C885.989 705.731 920.691 860.044 998.275 1012.51Z"
          fill="white"
        />
        <path
          d="M919.482 1181.14C1106.51 1587.51 1482.36 1887.88 1888.48 2055.87L1985.89 1970.31L1961.56 1962.46C1844.94 1920.11 1729.04 1865.3 1616.49 1799.29C1500.07 1731.03 1389.24 1649.05 1287.89 1556.24C1156.75 1439.4 1047.78 1302.69 970.754 1158.37C962.924 1134.35 952.038 1110.24 938.362 1086.61C863.016 919.946 842.615 754.333 880.346 615.634C842.66 691.837 826.67 781.546 833.412 878.958C840.153 976.37 869.475 1079.32 919.482 1181.14Z"
          fill="white"
        />
        <path
          d="M533.622 773.296C476.483 868.475 447.694 981.724 449.201 1105.39C455.68 1258.6 495.263 1419.45 565.812 1579.24C603.725 1657.37 649.029 1734.34 701.07 1809.05C875.396 2071.19 1108.93 2308.22 1378.71 2496.86L1470.85 2417.15C1248.42 2267.78 1047.85 2085.48 881.953 1881.9L818.184 1795.5C650.346 1573.07 552.417 1328.11 539.808 1099.2C541.287 1075.27 536.552 1045.39 537.451 1021.96C542.069 906.358 577.267 802.575 640.353 718.554C703.44 634.534 792.744 572.5 901.433 537.2C739.512 566.757 610.669 649.461 533.622 773.296Z"
          fill="white"
        />
        <path
          d="M340.646 398.176C302.245 406.854 262.096 404.726 223.696 413.404C147.652 436.313 76.9561 467.225 12.5948 505.709C-118.097 582.893 -216.191 694.882 -274.198 833.126C-320.144 951.78 -344.006 1082.46 -345.054 1221.15L-191.846 1368.53C-199.404 1282.64 -199.434 1198.71 -191.934 1117.54C-183.847 948.349 -126.302 799.258 -24.6412 684.116C77.0191 568.973 219.498 491.511 389.567 458.923C466.918 447.509 549.091 445.447 633.43 451.696C672.93 458.696 677.43 459.05 714.871 466.213C783.85 479.409 853.816 501.068 922.891 530.608C727.609 431.625 523.703 385.246 340.646 398.176Z"
          fill="white"
        />
        <path
          d="M149.848 704.786C44.7371 843.168 -6.16336 1019.63 2.43714 1215.82C5.63059 1294.87 16.4994 1375.9 34.9236 1457.99L222.793 1637.89C204.846 1591.15 188.635 1542.92 174.359 1494.42C160.083 1445.92 159.451 1429.49 148.504 1393.68C106.154 1224.28 109.157 1062.01 157.242 921.565C205.327 781.122 296.974 666.947 423.877 589.386C534.74 523.466 677.373 498.651 833.302 518.158C847.758 523.223 862.25 526.83 876.487 528.907C726.693 525.251 592.154 559.785 486.249 629.073C380.344 698.362 306.763 799.991 272.822 923.854C266.717 951.778 259.032 969.748 252.927 997.671C210.524 1268.54 287.241 1579.69 468.887 1873.54L1239 2610.99L1283 2572.92C912.355 2305.99 616.896 1951.18 450.151 1572.78C441.273 1541.61 429.251 1510.31 414.256 1479.31C375.409 1374.11 355.251 1269.48 354.664 1170.02C356.925 1145.22 356.132 1119.35 352.296 1092.83C351.41 936.23 403.855 799.274 502.94 699.43C602.025 599.585 743.257 541.379 908.619 532.237C752.967 489.365 601.356 482.721 468.438 512.947C335.52 543.173 225.789 609.248 149.848 704.786Z"
          fill="white"
        />
        <path
          d="M637.284 928.62C620.213 1101.57 663.719 1293.95 762.342 1481.6C938.855 1811.71 1226.61 2108.87 1568.89 2314.52L1661.81 2233.91C1324.98 2047.67 1034.85 1768.68 848.529 1451.86C833.029 1420.27 819.123 1398.56 803.622 1366.97C747.657 1249 714.472 1129.63 705.904 1015.46C708.249 990.733 707.564 964.915 703.86 938.418C707.152 768.18 779.202 625.962 907.394 536.666C755.496 614.807 659.74 753.758 637.284 928.62Z"
          fill="white"
        />
        <path
          d="M1383.24 852.85C1411.49 862.124 1435.68 876.349 1464.73 886.391C1565.81 926.67 1668.25 949.282 1765.8 952.846C1794.49 951.68 1824.26 953.457 1854.62 958.147C1899.85 958.333 1943.97 955.468 1986.66 949.571C2013.36 941.143 2042.08 935.998 2072.29 934.232C2385.98 863.624 2617.09 671.195 2719.19 395.613L2583.79 266.752C2527.28 469.903 2400.22 633.667 2217.43 738.956L2183.46 768.242C2115.01 805.96 2038.05 832.983 1954.57 848.603C1922.26 853.065 1901.25 859.48 1869.55 863.419C1713.14 884.159 1538.91 861.647 1365.18 798.251C1336.13 788.21 1311.94 773.985 1282.89 763.944C1162.41 707.119 1048.05 631.212 946.602 540.736C1070.07 672.077 1221.15 780.074 1383.24 852.85Z"
          fill="white"
        />
        <path
          d="M1810.67 777.631C1990.38 745.122 2144.61 669.735 2261.2 557.408C2377.8 445.081 2453.58 298.884 2482.57 130.338L2346.3 0.298644C2324.75 172.46 2261.32 326.268 2159.95 452.169C2056.44 581.054 1906.46 669.383 1725.1 708.271C1598.95 730.768 1459.64 725.483 1316.22 692.76C1191.5 665.654 1065.81 610.37 951 532.122C1084.73 631.168 1232.87 705.274 1382.64 748.046C1532.41 790.818 1679.31 800.971 1810.67 777.631Z"
          fill="white"
        />
        <path
          d="M985.056 793.127C1067.01 967.851 1203.47 1131.71 1372.48 1258.31C1529.07 1375.61 1700.21 1467.24 1874.24 1526.95C2048.27 1586.66 2221.1 1613.04 2380.95 1604.31L2566.72 1442.56C2516.49 1455.59 2464.18 1465.12 2410.12 1471.07C2059.22 1509.1 1649.44 1407.91 1302.11 1119.3C1206.39 1039.88 1122.53 948.19 1055.35 849.506C988.16 750.821 938.969 647.081 910.595 544.238C920.674 601.595 935.646 659.745 955.367 718.138C959.775 740.911 977.398 766.182 985.056 793.127Z"
          fill="white"
        />
        <path
          d="M1213.66 962.182C1258.63 1003.52 1305.79 1042.73 1354.81 1079.5C1486.82 1174.91 1630.51 1249.84 1777.17 1299.77C1923.83 1349.7 2070.41 1373.58 2208.03 1369.96C2238.87 1365.93 2271.11 1364.96 2304.3 1367.07C2687.12 1339.3 2995.42 1169.82 3168.82 891.828L3040.29 769.34C2907.33 980.185 2700.59 1133.84 2441.15 1214.65C2369.21 1234.87 2293.37 1248.58 2214.44 1255.62C2139.24 1259.3 2061.97 1257.55 1983.22 1250.36C1905.27 1237.09 1826.67 1219.3 1747.9 1197.09C1618.08 1156.76 1489.5 1097.83 1367.49 1022.75C1179.67 897.798 1024.33 728.895 926.96 543.77C961.638 628.221 1007.12 711.688 1062.28 792.112C1109.95 850.466 1160.49 907.25 1213.66 962.182Z"
          fill="white"
        />
        <path
          d="M1765.71 1111.51C1924.72 1144.19 2079.51 1151.01 2222.82 1131.66C2546.2 1069.91 2798.17 895.473 2936.98 637.242L2806.5 513.043C2770.84 585.757 2727.8 653.741 2677.77 716.378C2518.57 925.223 2254.93 1041.76 1939.01 1042.93C1909.05 1038.3 1879.66 1036.54 1851.34 1037.69C1628.72 1024.36 1391.74 934.012 1185.04 783.674C1088.07 715.01 1002.73 631.185 935.418 538.478C1029.22 678.994 1155.13 805.75 1300.73 906.241C1446.34 1006.73 1606.6 1077.48 1765.71 1111.51Z"
          fill="white"
        />
        <path
          d="M1396.66 -482.912C1388.87 -498.825 1391.93 -512.802 1384.13 -528.715C1222.37 -859.345 957.474 -1166.09 631.673 -1400.04L577.311 -1439.4L483.372 -1358.14L509.967 -1340.08C865.416 -1104.5 1152.92 -777.24 1315.06 -423.654C1383.75 -270.142 1415.46 -115.584 1407.58 27.3254C1403.27 143.144 1368.1 247.089 1304.79 331.08C1241.48 415.071 1151.74 476.846 1042.54 511.604C1491.69 450.179 1610.66 -35.0591 1396.66 -482.912Z"
          fill="white"
        />
        <path
          d="M1277.45 -182.736C1266.73 -209.859 1258.87 -236.998 1253.98 -263.718C1199.21 -411.069 1117.72 -556.942 1013.5 -694.151C882.894 -865.607 721.744 -1020.55 540.964 -1148.49L435.693 -1224.67L380.193 -1262.39L287.233 -1181.75C304.734 -1172.61 321.973 -1162.65 338.858 -1151.93C457.609 -1075.56 571.854 -990.864 680.068 -898.964C806.557 -786.006 919.335 -660.685 1014.41 -527.436C1075.88 -433.154 1127.32 -336.098 1167.73 -238.166C1219.7 -108.546 1242.98 20.5964 1235.99 140.377C1234.62 164.089 1227.66 181.387 1226.28 205.1C1204.35 334.14 1136.24 439.813 1030.78 508.407C1149.28 450.04 1235.11 354.494 1278.67 232.445C1322.23 110.395 1321.81 -33.3518 1277.45 -182.736Z"
          fill="white"
        />
        <path
          d="M1577.99 335.511C1695.33 185.848 1741.22 -13.549 1708.88 -233.168C1678.96 -392.739 1624.82 -555.209 1548.09 -715.76C1540.27 -731.714 1521.57 -749.601 1513.75 -765.555L1475.31 -833.078L719.134 -1556L676.592 -1519.23C841.287 -1398.1 993.094 -1260.12 1127.14 -1109.71C1355.16 -861.11 1514.24 -576.397 1582.69 -294.393L1596.86 -204.463C1664.48 178.44 1453.39 507.072 1045.63 520.371C1156.41 530.309 1260.62 519.067 1352.07 487.312C1443.52 455.557 1520.34 403.936 1577.99 335.511Z"
          fill="white"
        />
        <path
          d="M1145.1 -6.83227C1044.27 -458.944 649.414 -854.81 190.482 -1099.69L98.0935 -1019.16C384.889 -886.957 649.293 -680.461 850.623 -431.441C980.243 -276.952 1066.41 -103.751 1096.94 63.6864C1127.47 231.124 1100.82 384.33 1020.76 501.656C1082.82 442.566 1125.85 367.019 1147.22 279.607C1168.6 192.194 1167.87 94.7409 1145.1 -6.83227Z"
          fill="white"
        />
        <path
          d="M1132.24 556.659C1299.65 577.334 1454.59 555.204 1580.6 492.619C1739.11 407.145 1849.67 269.745 1898.81 97.1726C1946.8 -72.6177 1947.86 -263.597 1901.92 -462.288L1707.67 -648C1724.67 -602.502 1740.43 -555.936 1754.13 -509.092C1767.83 -462.248 1770.76 -439.199 1782.38 -401.092C1789.13 -322.653 1806.6 -242.955 1808.4 -160.243C1807.86 -29.9184 1780.84 91.2556 1728.59 197.659C1682.37 298.771 1609.17 382.029 1513.67 442.13C1370.51 534.464 1176.28 561.924 966.309 519.512C993.778 529.978 1021.41 538.325 1048.87 544.451C1085.5 550 1102 553 1132.24 556.659Z"
          fill="white"
        />
        <path
          d="M1534.54 656.571C1562.94 656.536 1584.62 650.033 1613.03 649.997C1853.26 601.949 2035.57 465.056 2127.39 263.769C2188.33 141.304 2220.83 1.77718 2223.45 -148.644L2063.7 -302.243C2095.5 -29.1451 2030.23 215.543 1877.43 396.028C1782.92 504.522 1646.22 573.963 1482.71 596.527C1319.21 619.092 1135.4 593.884 952 523.743C1146.68 630.039 1353.51 677.2 1534.54 656.571Z"
          fill="white"
        />
        <path
          d="M863.406 -238.683L810.437 -314.609C599.468 -583.348 312.351 -800.96 6.84347 -923.671L-89.3843 -839.123C-48.4598 -824.98 -9.14817 -809.421 30.3745 -792.655C127.4 -750.813 223.475 -702.079 317.567 -646.975C445.691 -565.755 566.121 -468.939 674.054 -360.387C927.44 -95.937 1086.75 251.172 1008.46 509.226C1054.19 407.685 1064.87 285.561 1039.47 154.585C1014.07 23.6099 953.439 -111.816 863.406 -238.683Z"
          fill="white"
        />
        <path
          d="M-943.203 49.8414C-793.99 -69.6322 -605.364 -146.839 -390.614 -176.342C-374.829 -178.506 -357.475 -170.57 -341.69 -172.734C105.207 -227.557 709.636 65.6337 938.886 525.013C758.771 53.3102 193.044 -280.429 -290.291 -295.822C-512.473 -300.873 -717.842 -261.701 -893.619 -180.743C-1069.4 -99.7861 -1211.82 21.2246 -1312.04 174.76L-1167.74 312.277C-1106.69 213.968 -1031.41 125.981 -943.203 49.8414Z"
          fill="white"
        />
        <path
          d="M234.233 -9.22502C204.072 -14.9866 173.616 -23.5756 143.356 -34.8541C-101.17 -95.341 -336.039 -89.5045 -531.058 -18.0952C-587.718 1.813 -641.646 25.6317 -692.515 53.2171C-726.071 82.1438 -771.766 98.4303 -805.323 127.357C-913.155 213.398 -1001.24 318.016 -1066.95 438.084L-941.162 557.804C-781.789 226.922 -456.908 -4.03869 17.8096 27.1622C179.749 36.7908 350.617 88.3974 511.914 176.394C673.211 264.391 818.791 385.424 932.889 526.389C854.576 404.155 750.419 291.876 628.968 198.768C507.518 105.659 372.237 34.3778 234.233 -9.22502Z"
          fill="white"
        />
        <path
          d="M370.244 168.709C298.948 149.114 227.872 134.1 157.577 123.786C97.7764 117.565 38.7046 114.277 -19.3964 113.934C-123.984 116.568 -222.326 134.293 -311.255 166.539C-514.151 243.435 -671.196 378.813 -766.024 558.562C-795.602 598.69 -817.304 640.807 -834.365 686.24L-707.717 806.777C-628.151 521.136 -415.857 313.005 -114.375 225.071C-2.08278 194.69 123.97 189.104 256.009 208.658C395.455 224.256 539.981 271.453 677.005 346.137L749.658 388.854C813.324 429.368 873.803 475.468 929.816 526.179C775.015 361.024 576.872 234.445 370.244 168.709Z"
          fill="white"
        />
        <path
          d="M70.1404 274.867C-304.171 329.366 -528.174 598.93 -599.463 933.688L-461.501 1065.36C-440.523 771.159 -286.082 536.044 -30.984 409.961C-4.71922 399.224 16.6217 392.739 42.8865 382.003C166.127 334.764 311.792 322.819 466.34 347.278C620.887 371.736 779.299 431.804 926.848 521.897C793.244 424.352 645.961 351.113 497.054 308.176C348.148 265.239 201.852 253.825 70.1404 274.867Z"
          fill="white"
        />
        <path
          d="M695.845 -197.04C571.257 -329.037 427.999 -444.612 274.692 -536.809C121.385 -629.007 -38.8021 -695.922 -196.229 -733.527L-301.73 -640.394C-20.5434 -604.172 278.085 -466.301 535.279 -253.964C646.406 -167.323 744.054 -63.8067 820.813 48.7293C897.573 161.265 951.427 279.864 978.29 395.527C983.441 423.122 984.941 424.622 990.351 462.679C992.901 484.811 993.288 506.323 991.507 526.955C1000.53 414.034 978.465 289.665 927.104 163.896C875.743 38.1273 796.523 -85.5148 695.845 -197.04Z"
          fill="white"
        />
        <path
          d="M903.5 325C849.633 215.575 771.48 113.501 680.421 18.3143C559.839 -106.231 418.588 -212.223 267.41 -291.603C234.066 -307.392 211.296 -321.183 177.951 -336.972C-45.4307 -437.769 -273.463 -488.011 -483.223 -482.65L-678 -313.067C-657.708 -318.129 -637.213 -321.966 -616.158 -326.291C-595.104 -330.616 -587.673 -339.887 -572.62 -341.787C-420.358 -359.236 -257.976 -353.921 -90.4356 -326.003C100.746 -289.792 296.269 -205.668 474.816 -82.803C595.847 0.0438271 703.905 102.33 790.762 216.27C877.619 330.21 918.471 374.715 953.525 496.622C944.999 439.199 947.5 462 933.714 400.061C916 344 918.394 353.525 903.5 325Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default HipnosSvg;
