import { useCallback, useState } from 'react';

export const useHorizontalTabs = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const changeTab = useCallback((key) => {
    setTabIndex(key);
  }, []);
  return {
    tabIndex,
    changeTab,
  };
};
