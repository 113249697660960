export const handleCreateProductValues = (values) => {
  const formData = new FormData();
  formData.append('name', values.name);
  formData.append('type', values.type);
  formData.append('boardIds', JSON.stringify(values.boardIds));
  if (typeof values.photo === 'object') formData.append('image', values.photo);
  formData.append('price', values.price);
  if (values.type === 'real') {
    formData.append('weight', values.weight);
    formData.append('count', values.count);
  }
  return formData;
};

export const getProductsParams = ({ page, limit, boardId, ...rest }) => {
  return {
    ...rest,
    offset: (page - 1) * limit,
    limit,
    ...(boardId !== 'all' && { boardId }),
    marketing: true,
  };
};

export const getPagesCount = ({ count, limit }) => {
  return Math.ceil(count / limit);
};
