export const LIST_LIMIT = 14;

export const droppingErrors = (T) => ({
  'Order status is done, you can not drag from deliveryman list out or in': T.error_ending_status,
  'Insufficient spareProduct count': T.error_insufficient_spareProduct,
  'Cannot drag to list De Archived': T.error_cannot_drag,
  'Order must not have status for moving to deliveryman ': T.error_without_status,
  'Domain with that name already exists': T.error_domain_exists,
  'You may have included the same product many times': T.error_same_product,
  "Can't add test or not active product to deliveryman": T.error_test_or_not_active,
  'Invalid types': T.error_invalid_types,
  'Must download list to use this route': T.error_at_first_download,
  'You can update order status only on deliveryman list which has products accepted': T.error_need_accept_product,
  'You can not use this route for drug and drop in the same list': T.error_same_list,
  defaultMessage: T.error_something_went_wrong,
  'can subtract only products without order': T.error_only_without_order,
  'can drag only warehouse man and above users': T.error_drag_warehouseman,
  'can drag to state2 only from state': T.error_drag_only_from_state,
  'Board name must be unique': T.error_bord_name_is_not_unique,
  'You can not clear order which list is deliveryMan': T.error_unable_clear_deliveryman_list,
  'can not drag to state2 not sale orders, or orders with status': T.error_cannot_drag_to,
});
