import { all, takeEvery, put, call } from 'redux-saga/effects';
import Axios from '../../services/axios';

import { axiosData } from './requestGenerator.js';
import { getRegions } from './action.js';
import { errorLogger } from '../../helpers/errorLogger';

function* getRegionsRequest() {
  const { url } = axiosData.getRegions;
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getRegions,
    url,
  });

  return result;
}

function* watchGetRegions() {
  yield takeEvery(getRegions, function* workGetRegions() {
    try {
      const data = yield call(getRegionsRequest);
      yield put(getRegions.success({ data }));
    } catch (e) {
      yield put(getRegions.error());
      errorLogger(e);
    }
  });
}

export default function* regions() {
  yield all([watchGetRegions()]);
}
