import React from 'react';

const GreenEmoji = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30.001" height="30" viewBox="0 0 30.001 30">
      <g id="Component_49_1" data-name="Component 49 – 1" transform="translate(0 0)">
        <rect id="Rectangle_11119" data-name="Rectangle 11119" width="30" height="30" fill="none" />
        <g id="Group_7042" data-name="Group 7042" transform="translate(5131.674 11166.008)">
          <g id="Group_7022" data-name="Group 7022" transform="translate(-5131.472 -11165.807)">
            <circle
              id="Ellipse_1457"
              data-name="Ellipse 1457"
              cx="15"
              cy="15"
              r="15"
              transform="translate(-0.201 -0.202)"
              fill="#67b82f"
            />
            <g id="Group_6986" data-name="Group 6986" transform="translate(5.071 9.121)">
              <path
                id="Path_6832"
                data-name="Path 6832"
                d="M1285.741,830.973a2.724,2.724,0,0,0-2.468-2.134,2.218,2.218,0,0,0-1.2.359,3.142,3.142,0,0,0-.681.66c-.1.116-.191.226-.288.325a2.076,2.076,0,0,1-.732.468,1.662,1.662,0,0,1-1.869-.6,2.579,2.579,0,0,0-2.071-1.207,2.3,2.3,0,0,0-1.614.731,3.8,3.8,0,0,0-.308.36,3.425,3.425,0,0,1-.249.295,2.022,2.022,0,0,1-.6.372,1.485,1.485,0,0,1-.862.137,1.931,1.931,0,0,1-1.036-.555c-.09-.093-.179-.2-.274-.305a3.388,3.388,0,0,0-.717-.684,2.212,2.212,0,0,0-1.18-.35,2.716,2.716,0,0,0-2.449,2.073.507.507,0,0,0,.221.6.4.4,0,0,0,.52-.115,2.129,2.129,0,0,1,2.229-.942,2.694,2.694,0,0,1,1.063.887,2.46,2.46,0,0,0,1.531.9,2.142,2.142,0,0,0,.292.02,2.417,2.417,0,0,0,1.873-.982l.09-.116a1.994,1.994,0,0,1,.5-.517,1.963,1.963,0,0,1,.764-.284,1.475,1.475,0,0,1,.847.155,2.389,2.389,0,0,1,.425.27,3.069,3.069,0,0,1,.421.44,3.667,3.667,0,0,0,.491.511,2.175,2.175,0,0,0,2.972-.135c.069-.065.189-.191.328-.336.181-.19.518-.543.6-.6a1.591,1.591,0,0,1,1.938.05,3.831,3.831,0,0,1,.733.73.408.408,0,0,0,.52.119A.5.5,0,0,0,1285.741,830.973Z"
                transform="translate(-1266.724 -820.76)"
                fill="#2c2c2c"
              />
              <g id="Group_6965" data-name="Group 6965" transform="translate(0 0)">
                <g id="Group_6963" data-name="Group 6963" transform="translate(0 0)">
                  <rect
                    id="Rectangle_11102"
                    data-name="Rectangle 11102"
                    width="1.373"
                    height="6.186"
                    transform="translate(0 0.971) rotate(-45)"
                    fill="#2c2c2c"
                  />
                  <rect
                    id="Rectangle_11103"
                    data-name="Rectangle 11103"
                    width="1.373"
                    height="6.186"
                    transform="translate(4.374 0) rotate(45.001)"
                    fill="#2c2c2c"
                  />
                </g>
                <g id="Group_6964" data-name="Group 6964" transform="translate(14.1 0)">
                  <rect
                    id="Rectangle_11104"
                    data-name="Rectangle 11104"
                    width="1.373"
                    height="6.186"
                    transform="translate(0 0.971) rotate(-45.001)"
                    fill="#2c2c2c"
                  />
                  <rect
                    id="Rectangle_11105"
                    data-name="Rectangle 11105"
                    width="1.373"
                    height="6.186"
                    transform="translate(4.374) rotate(45)"
                    fill="#2c2c2c"
                  />
                </g>
              </g>
            </g>
            <g id="Group_7020" data-name="Group 7020" transform="translate(2.148 1.985)">
              <path
                id="Path_6830"
                data-name="Path 6830"
                d="M1268.731,742.414a15.008,15.008,0,0,1-20.5,20.872,15.007,15.007,0,1,0,20.5-20.872Z"
                transform="translate(-1248.235 -742.414)"
                fill="#50ab37"
              />
            </g>
            <g id="Group_7021" data-name="Group 7021" transform="translate(0.79 0.895)">
              <path
                id="Path_6831"
                data-name="Path 6831"
                d="M1241.556,751.7a13.932,13.932,0,0,1,24.975-8.493,13.933,13.933,0,1,0-23.124,15.437A13.869,13.869,0,0,1,1241.556,751.7Z"
                transform="translate(-1240.519 -736.221)"
                fill="#7bc228"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GreenEmoji;
