export const axiosData = {
  getSelectableBoards: {
    method: 'get',
    url: '/api/admin/boards',
  },

  getByIdBoard: {
    method: 'get',
    url: '/api/admin/boards/:id',
  },
  createBoard: {
    method: 'post',
    url: '/api/admin/boards',
  },
  editBoard: {
    method: 'put',
    url: '/api/admin/boards/:id',
  },

  deleteBoardById: {
    method: 'delete',
    url: '/api/admin/boards/:id',
  },
  getStatisticById: {
    method: 'post',
    url: '/api/admin/boards/lists/:id/statistic/v2',
  },
};
