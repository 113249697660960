import { all, takeEvery, put, call } from 'redux-saga/effects';
import Axios from '../../services/axios';
import { errorLogger } from '../../helpers/errorLogger';
import { axiosData } from './requestGenerators';
import { getPosts } from './actions';

function* getPostsRequest({ payload }) {
  const stateUrl = axiosData.getPosts.url.replace(':stateId', payload.stateId);
  const url = stateUrl.replace(':cityId', payload.cityId);
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.getPosts, url });
  return result;
}

function* watchGetPosts() {
  yield takeEvery(getPosts, function* workGetPosts({ payload }) {
    try {
      const response = yield call(getPostsRequest, { payload });
      yield put(
        getPosts.success(
          response.map((el) => {
            return {
              value: el.hayPostId,
              label: el.name,
            };
          })
        )
      );
    } catch (e) {
      errorLogger(e);
      yield put(getPosts.error());
    }
  });
}

export default function* getAllPosts() {
  yield all([watchGetPosts()]);
}
