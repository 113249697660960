import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getUpsertDomains, getStoreProducts } from './actions';
import { axiosData } from './requests';
import { storeAxios } from '../../services/axios';
import { errorLogger } from '../../helpers/errorLogger';

function* getUpsertDomainsRequest() {
  const response = yield call(storeAxios.request, { ...axiosData.getUpsertDomains });
  return response?.data.result;
}

function* watchGetUpsertDomains() {
  yield takeEvery(getUpsertDomains, function* worker({ payload }) {
    try {
      const response = yield call(getUpsertDomainsRequest, payload);
      yield put(getUpsertDomains.success(response));
    } catch (e) {
      yield put(getUpsertDomains.error());
      errorLogger(e);
    }
  });
}

function* getStoreProductsRequest(payload) {
  const { limit = 10, search = '', crmDomainIds } = payload;
  const response = yield call(storeAxios.request, {
    ...axiosData.getStoreProducts,
    params: {
      offset: 0,
      limit,
      search: search || undefined,
      crmDomainIds,
    },
  });
  return { data: response?.data?.result, total: response?.data?.count };
}

function* watchGetStoreProducts() {
  yield takeEvery(getStoreProducts, function* worker({ payload }) {
    try {
      const response = yield call(getStoreProductsRequest, payload);
      yield put(getStoreProducts.success(response));
    } catch (e) {
      yield put(getStoreProducts.error());
      errorLogger(e);
    }
  });
}
export default function* workers() {
  yield all([watchGetUpsertDomains(), watchGetStoreProducts()]);
}
