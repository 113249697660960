import React, { Suspense } from 'react';
import Modal from '../components/modal/Modal';
import { useModals } from './useModals';

const Modals = () => {
  const { modalsComponents, modalsStatuses, onClose } = useModals();

  return (
    <div>
      <Suspense fallback=" ">
        {modalsComponents.map(({ name, Component, className = '', isHiddenCloser }) => {
          const { data, opened: visible, title } = modalsStatuses[name];
          return (
            <Modal
              className={className}
              visible={visible}
              onClose={onClose}
              name={name}
              key={name}
              isHiddenCloser={isHiddenCloser}
              title={title}
            >
              {visible && <Component data={data} onClose={onClose} name={name} />}
            </Modal>
          );
        })}
      </Suspense>
    </div>
  );
};
export default Modals;
