import { createCombinedAction as cCA } from '../helpers';

export const getStorage = cCA({ name: 'getStorage', prefix: 'warehouse@@' });
export const getRequestCounts = cCA({ name: 'getRequestCounts', prefix: 'warehouse@@' });

export const getRequests = cCA({ name: 'getRequests', prefix: 'warehouse@@' });
export const reviewRequest = cCA({ name: 'reviewRequest', prefix: 'warehouse@@' });

export const getInputRequests = cCA({ name: 'getInputRequests', prefix: 'warehouse@@' });
export const getExportRequests = cCA({ name: 'getExportRequests', prefix: 'warehouse@@' });

export const createProductRequest = cCA({ name: 'createProductRequest', prefix: 'warehouse@@' });
export const editProductInput = cCA({ name: 'editProductInput', prefix: 'warehouse@@' });

export const getDescription = cCA({ name: 'getDescription', prefix: 'warehouse@@' });
export const addDescription = cCA({ name: 'addDescription', prefix: 'warehouse@@' });

export const getWarehouseCount = cCA({ name: 'getWarehouseCount', prefix: 'warehouse@@' });
