import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AreaChart, Area, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { array, bool, number } from 'prop-types';
import SmallArrowSvg from '../../../svgs/SmallArrowSvg';
import classNames from './areaChart.module.scss';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload?.length) {
    const data = payload?.[0].payload;

    return (
      <div
        style={{
          display: 'flex',
          gap: '10px',
          backgroundColor: 'whitesmoke',
          opacity: '0.9',
          fontSize: '11px',
          fontWeight: 'normal',
        }}
      >
        <div className="custom-tooltip">
          <p>{`FB_Date: ${data.fbDate}`}</p>
          <p>{`FB_Time: ${data.fbTime}`}</p>
          <p>{`FB_Lead: ${data.fbLead}`}</p>
          <p>{`FB_Type: ${data.fbType}`}</p>
        </div>
        <div className="custom-tooltip">
          <p>{`ALL_Date: ${data.allDate}`}</p>
          <p>{`ALL_Time: ${data.allTime}`}</p>
          <p>{`ALL_Lead: ${data.allLead}`}</p>
          <p>{`ALL_Type: ${data.allType}`}</p>
        </div>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: bool,
  payload: array,
  label: number,
};

export default function TestRealAreaChart({ data }) {
  const [isReal, setIsReal] = useState(true);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '16px' }}>
        <div>
          <input type="radio" name="real" checked={isReal} onClick={() => setIsReal(true)} />
          <span>Lead real 50(fb)/50(all)</span>
        </div>

        <div>
          <input type="radio" name="test" checked={!isReal} onClick={() => setIsReal(false)} />
          <span>Lead test 13(fb)/13(all)</span>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="2 3" />
          <Tooltip content={CustomTooltip} />
          <Area fbType="monotone" dataKey="all" stroke="#1D9EF9" fill="rgba(29, 158, 249, 0.6)" />
          <Area fbType="linear" dataKey="fb" stroke="#252733" fill="rgba(37, 39, 51, 0.6)" />
        </AreaChart>
      </ResponsiveContainer>
      <div className={classNames.areainfoWrapper}>
        <div className={classNames.areaInfoLeft}>
          <div className={classNames.areainfoItem}>
            <p>Server</p>
          </div>
          <div className={classNames.areainfoItem}>
            <div />
            <p>All</p>
          </div>
          <div className={classNames.areainfoItem}>
            <div />
            <p>Facebook</p>
          </div>
        </div>
        <span>
          More <SmallArrowSvg />
        </span>
      </div>
    </>
  );
}

TestRealAreaChart.propTypes = {
  data: Object,
};
