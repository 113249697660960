import { createActions } from 'redux-actions';
import { createCombinedAction } from '../helpers';

export const getProducts = createCombinedAction({ name: 'getProducts', prefix: 'products@@' });
export const getEditProduct = createCombinedAction({
  name: 'getEditProduct',
  prefix: 'products@@',
});
export const editProduct = createCombinedAction({ name: 'editProduct', prefix: 'products@@' });

export const createProduct = createCombinedAction({ name: 'createProduct', prefix: 'products@@' });
export const mutateProduct = createCombinedAction({ name: 'mutateProduct', prefix: 'products@@' });
export const { changeStatus, removeProduct, setLimit } = createActions('CHANGE_STATUS', 'REMOVE_PRODUCT', 'SET_LIMIT', {
  prefix: 'products@@',
});
export const getAllStates = createCombinedAction({ name: 'getAllStates', prefix: 'products@@' });
export const getProductSummary = createCombinedAction({
  name: 'getProductSummary',
  prefix: 'products@@',
});
export const handleSendSummary = createCombinedAction({
  name: 'handleSendSummary',
  prefix: 'products@@',
});
