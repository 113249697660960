import React from 'react';

const RedPinSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
      <g id="Group_6790" data-name="Group 6790" transform="translate(-1633 -297)">
        <circle
          id="Ellipse_1386"
          data-name="Ellipse 1386"
          cx="10.5"
          cy="10.5"
          r="10.5"
          transform="translate(1633 297)"
          fill="#f3373d"
        />
        <path
          id="_3994394_label_marker_pin_pushpin_thumbtack_icon"
          data-name="3994394_label_marker_pin_pushpin_thumbtack_icon"
          d="M12.649,7.016,7.535,1.9A.723.723,0,0,0,6.3,2.414V4.16L4.679,5.782H2.933a.723.723,0,0,0-.511,1.235L4.467,9.062,1.91,11.618a.723.723,0,1,0,1.023,1.023L5.49,10.084,7.535,12.13a.723.723,0,0,0,1.235-.511V9.872l1.622-1.622h1.746a.723.723,0,0,0,.511-1.235ZM10.092,6.8a.723.723,0,0,0-.511.212L7.535,9.062a.723.723,0,0,0-.212.511v.3L4.679,7.228h.3a.723.723,0,0,0,.511-.212L7.535,4.971a.723.723,0,0,0,.212-.511v-.3L10.391,6.8Z"
          transform="translate(1636.692 299.967)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default RedPinSvg;
