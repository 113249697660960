export const axiosData = {
  changeOrderStatus: {
    url: '/api/admin/order/:id/change/status/warehouse',
    method: 'put',
  },
  changeOrderStatusToBack: {
    url: '/api/order/:id/returnChange/clear',
    method: 'put',
  },
  getBoardLists: {
    url: '/api/users/board/:id',
    method: 'post',
  },
  getBoardListsName: {
    url: '/api/admin/boards/:id',
    method: 'get',
  },
  createList: {
    url: '/api/admin/lists',
    method: 'post',
  },
  removeList: {
    url: '/api/admin/lists/:id',
    method: 'delete',
  },
  editList: {
    url: '/api/admin/lists/:id',
    method: 'put',
  },
  createListItem: {
    url: '/api/order',
    method: 'post',
  },
  getListItemToEdit: {
    url: '/api/order/listItem/:id',
    method: 'get',
  },

  getListItem: {
    url: '/api/admin/lists/item/:id',
    method: 'get',
  },
  editListItem: {
    url: '/api/order/:id',
    method: 'put',
  },

  editListItemReturnChange: {
    url: '/api/order/:id/returnChange',
    method: 'put',
  },
  resetReturnChangeListItemStatus: {
    url: '/api/order/:id/returnChange/clear',
    method: 'put',
  },
  moveList: {
    url: '/api/move/list/:id',
    method: 'put',
  },
  copyListItem: {
    url: '/api/order/copy/:id',
    method: 'post',
  },
  removeListItem: {
    url: '/api/order/:id',
    method: 'delete',
  },
  archiveList: {
    url: '/api/admin/lists/:id/archive',
    method: 'put',
  },
  archiveListItem: {
    url: '/api/admin/list/item/:id/archive',
    method: 'put',
  },
  moveListItemInSameList: {
    url: '/api/move/list/item/:id',
    method: 'put',
  },
  moveListItemInOtherList: {
    url: '/api/move/list/out/item/:id',
    method: 'put',
  },
  getDraggableTableListItems: {
    url: '/api/users/list/:id',
    method: 'get',
  },
  getListById: {
    url: '/api/admin/lists/:id',
    method: 'get',
  },
  statusShipping: {
    stateUrl: '/api/admin/order/:id/change/status/state',
    url: '/api/admin/order/:id/change/status',
    method: 'put',
  },
  getOrderInfo: {
    url: '/api/order/listitem/:id/change/products',
    method: 'get',
  },
  revertStatusApi: {
    url: '/api/admin/order/:id/revert/status',
    method: 'put',
  },
  addLabel: {
    url: '/api/order/:listItemId/label/:labelId',
    method: 'post',
  },
  removeLabel: {
    url: '/api/order/:listItemId/label/:labelId',
    method: 'delete',
  },
};
