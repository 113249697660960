import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePagination from '../../../../hooks/usePagination';
import { addDescription, getRequests, reviewRequest } from '../../../../store/warehouse/action';
import {
  selectAddDescriptionData,
  selectReviewData,
  selectWarehouseRequests,
} from '../../../../store/warehouse/selector';
import Table from './components/table/Table';
import { selectLang } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';

const DefectTab = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { total } = useSelector(selectWarehouseRequests);
  const dispatch = useDispatch();
  const { success } = useSelector(selectReviewData);
  const { success: successComment } = useSelector(selectAddDescriptionData);
  const { page, limit, changePage, changeLimit, pagesCount } = usePagination(total);

  useEffect(() => {
    dispatch(getRequests({ offset: (page - 1) * limit, limit, type: 'EXPORT', T }));
  }, [page, limit]);
  useEffect(() => {
    if (success || successComment) {
      dispatch(getRequests({ offset: (page - 1) * limit, limit, type: 'EXPORT', T }));
      dispatch(reviewRequest.reset());
      dispatch(addDescription.reset());
    }
  }, [success, successComment]);
  return (
    <>
      <Table page={page} limit={limit} changePage={changePage} changeLimit={changeLimit} pagesCount={pagesCount} />
    </>
  );
};

export default DefectTab;
