import React, { useCallback, useEffect, useState } from 'react';
import ResponsivePagination from 'react-responsive-pagination';
import PropTypes from 'prop-types';

const Pagination = ({ defaultPage = 1, totalPages = 17, onChange }) => {
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const handleChangePage = useCallback(
    (...data) => {
      onChange(...data);
      setCurrentPage(...data);
    },
    [onChange]
  );

  useEffect(() => {
    setCurrentPage(defaultPage);
  }, [defaultPage]);

  return (
    <ResponsivePagination current={currentPage} total={totalPages} onPageChange={handleChangePage} maxWidth={500} />
  );
};

Pagination.propTypes = {
  defaultPage: PropTypes.number,
  totalPages: PropTypes.number,
  onChange: PropTypes.func,
};

export default Pagination;
