import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { downLoadBuyer, downLoadCSF, referForDownloadAction } from './actions';

const downLoadCSFInitialState = {
  error: false,
  loading: false,
  data: [],
};
const downLoadCSFAction = handleActions(
  {
    [downLoadCSF]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),

    [downLoadCSF.success]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      data: payload,
    }),
    [downLoadCSF.error]: (state) => ({ ...state, loading: false, error: true }),
    [downLoadCSF.reset]: (state) => ({ ...state, loading: false, error: false, data: [] }),
  },
  downLoadCSFInitialState
);

const referForDownloadCsvInitial = {
  error: false,
  loading: false,
  success: false,
};
const referForDownloadCsv = handleActions(
  {
    [referForDownloadAction]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),

    [referForDownloadAction.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [referForDownloadAction.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
  },
  referForDownloadCsvInitial
);

const downLoadBuyerInitialState = {
  error: false,
  loading: false,
  data: [],
};
const downLoadBuyerAction = handleActions(
  {
    [downLoadBuyer]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),

    [downLoadBuyer.success]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      data: payload,
    }),
    [downLoadBuyer.error]: (state) => ({ ...state, loading: false, error: true }),
    [downLoadBuyer.reset]: (state) => ({ ...state, loading: false, error: false, data: [] }),
  },
  downLoadBuyerInitialState
);

export default combineReducers({
  downLoadCSFAction,
  referForDownloadCsv,
  downLoadBuyerAction,
});
