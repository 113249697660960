import React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useWorkerRegistration } from './useWorkerRegistration';
import { validationSchema } from './validationSchema';
import Upload from '../../components/upload/Upload';
import classNames from './workerRegistration.module.scss';
import LogoSvg from '../../svgs/LogoSvg';
import Button from '../../components/button/Button';
import CloseEyeSvg from '../../svgs/CloseEyeSvg';
import EyeSvg from '../../svgs/EyeSvg';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';

const WorkerRegistration = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { loading, handleSubmit, incorrectUrlError, initialValues, eyeVisible, handleEyeVisible } =
    useWorkerRegistration();

  if (incorrectUrlError) return 'incorrect url error';

  return (
    <div className={`${classNames.loginPage} d-flex justify-content-center align-items-center h-100 w-100`}>
      <div className={classNames.AuthenticationContent}>
        <h1>
          <LogoSvg />
        </h1>

        <Formik initialValues={initialValues} validationSchema={validationSchema(T)} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => (
            <Form className="d-flex justify-content-center mt-lg-5 mt-0">
              <div>
                <div className="container">
                  <div className="row">
                    <div className={`${classNames.colWithoutadding} col-md-12 col-12`}>
                      <p className={classNames.workerRegP}>{T.invite_for_log_in}</p>
                    </div>
                    <div className={`${classNames.colWithoutadding} col-md-12 col-12`}>
                      <div className={classNames.InputParent}>
                        <Field type="email" name="email" placeholder="email" disabled />
                        <ErrorMessage className="text-red" name="email" component="div" />
                      </div>
                    </div>
                    <div className={`${classNames.colWithoutadding} col-md-6 col-12`}>
                      <div className={classNames.InputParent}>
                        <Field type="text" name="name" placeholder={`${T.name}*`} autocomplete="new-password" />
                        <ErrorMessage className="text-red" name="name" component="div" />
                      </div>
                    </div>
                    <div className={`${classNames.colWithoutadding} col-md-6 col-12`}>
                      <div className={classNames.InputParent}>
                        <Field type="text" name="lastName" placeholder={`${T.surname}*`} autocomplete="new-password" />
                        <ErrorMessage className="text-red" name="lastName" component="div" />
                      </div>
                    </div>
                    <div className={`${classNames.colWithoutadding} col-md-6 col-12`}>
                      <div className={classNames.InputParent}>
                        <div className={classNames.inputPassword}>
                          <Field
                            className="w-100"
                            type={!eyeVisible.password ? 'password' : 'text'}
                            name="password"
                            placeholder={`${T.password}*`}
                            autocomplete="new-password"
                          />
                          <Button onClick={() => handleEyeVisible('password')}>
                            {!eyeVisible.password ? <CloseEyeSvg /> : <EyeSvg />}
                          </Button>
                        </div>
                        <ErrorMessage className="text-red" name="password" component="div" />
                      </div>
                    </div>
                    <div className={`${classNames.colWithoutadding} col-md-6 col-12`}>
                      <div className={classNames.InputParent}>
                        <div className={classNames.inputPassword}>
                          <Field
                            className="w-100"
                            type={!eyeVisible.repeatPassword ? 'password' : 'text'}
                            name="repeatPassword"
                            placeholder={`${T.repeat_password}*`}
                            autocomplete="new-password"
                          />
                          <Button onClick={() => handleEyeVisible('repeatPassword')}>
                            {!eyeVisible.repeatPassword ? <CloseEyeSvg /> : <EyeSvg />}
                          </Button>
                        </div>
                        <ErrorMessage className="text-red" name="repeatPassword" component="div" />
                      </div>
                    </div>
                  </div>
                </div>
                <Upload
                  file={values.photo?.name ? values.photo : null}
                  onChange={(file) => {
                    setFieldValue('photo', file);
                  }}
                >
                  {T.add_image}
                </Upload>
                <ErrorMessage className="text-red" name="photo.name" component="div" />
                <div className={classNames.AuthenticationBtn}>
                  {!loading ? (
                    <button type="submit" className="btn btn-success">
                      {T.registration}
                    </button>
                  ) : (
                    'loading'
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default WorkerRegistration;
