import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import {
  addRate,
  deleteCustomerByPhone,
  deleteRate,
  getAddressesByPhone,
  getCustomers,
  getCustomersCount,
  getOrdersByPhone,
  getRatesByPhone,
  printBuyers,
} from './actions';

const getInitialState = {
  loading: false,
  error: false,
  data: [],
};

const get = handleActions(
  {
    [getCustomers]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [getCustomers.error]: () => ({
      loading: false,
      error: true,
    }),
    [getCustomers.success]: (state, { payload: { data } }) => ({
      ...state,
      loading: false,
      error: false,
      data,
    }),
  },
  getInitialState
);

const getInitialCustomersCount = {
  loading: false,
  error: false,
  success: false,
  data: [],
};

const getCount = handleActions(
  {
    [getCustomersCount]: () => ({
      loading: true,
      error: false,
      success: false,
      data: [],
    }),
    [getCustomersCount.error]: () => ({
      loading: false,
      error: true,
      success: false,
    }),
    [getCustomersCount.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      success: true,
      data,
    }),
  },
  getInitialCustomersCount
);

const deleteInitialState = {
  loading: false,
  success: false,
  error: false,
};

const deleteCustomer = handleActions(
  {
    [deleteCustomerByPhone]: () => ({
      loading: true,
      error: false,
      success: false,
    }),
    [deleteCustomerByPhone.error]: () => ({
      loading: false,
      error: true,
      success: false,
    }),
    [deleteCustomerByPhone.success]: () => ({
      loading: false,
      error: false,
      success: true,
    }),
  },
  deleteInitialState
);

const getInitialOrders = {
  loading: false,
  error: false,
  data: [],
};

const getOrders = handleActions(
  {
    [getOrdersByPhone]: () => ({
      loading: true,
      error: false,
      data: [],
    }),
    [getOrdersByPhone.success]: (state, { payload }) => ({
      loading: false,
      error: false,
      data: [...state.data, ...payload.data.rows],
    }),
    [getOrdersByPhone.error]: () => ({
      loading: false,
      error: true,
    }),
  },
  getInitialOrders
);

const getInitialAddresses = {
  loading: false,
  error: false,
  data: [],
};

const getAddresses = handleActions(
  {
    [getAddressesByPhone]: () => ({
      loading: true,
      error: false,
      data: [],
    }),
    [getAddressesByPhone.error]: () => ({
      loading: false,
      error: true,
    }),
    [getAddressesByPhone.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      data,
    }),
  },
  getInitialAddresses
);

const getInitialRates = {
  loading: false,
  error: false,
  data: [],
};

const getRates = handleActions(
  {
    [getRatesByPhone]: () => ({
      loading: true,
      error: false,
      data: [],
    }),
    [getRatesByPhone.error]: () => ({
      loading: false,
      error: true,
    }),
    [getRatesByPhone.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      data,
    }),
  },
  getInitialRates
);

const getInitialPrintBuyers = {
  loading: false,
  error: false,
  data: [],
};

const getPrintBuyers = handleActions(
  {
    [printBuyers]: () => ({
      loading: true,
      error: false,
      data: [],
    }),
    [printBuyers.error]: () => ({
      loading: false,
      error: true,
    }),
    [printBuyers.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      data,
    }),
  },
  getInitialPrintBuyers
);
const getInitialAddedRates = {
  loading: false,
  error: false,
  data: [],
};

const getAddRates = handleActions(
  {
    [addRate]: () => ({
      loading: true,
      error: false,
      data: [],
    }),
    [addRate.error]: () => ({
      loading: false,
      error: true,
    }),
    [addRate.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      data,
    }),
  },
  getInitialAddedRates
);

const getInitialDeletedRates = {
  loading: false,
  error: false,
  data: [],
};

const getDeleteRates = handleActions(
  {
    [deleteRate]: () => ({
      loading: true,
      error: false,
      data: [],
    }),
    [deleteRate.error]: () => ({
      loading: false,
      error: true,
    }),
    [deleteRate.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      data,
    }),
  },
  getInitialDeletedRates
);

export default combineReducers({
  get,
  getCount,
  deleteCustomer,
  getOrders,
  getAddresses,
  getRates,
  getPrintBuyers,
  getAddRates,
  getDeleteRates,
});
