import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../../../../store/modals/actions';
import { modalNames } from '../../../../../../store/modals/constants';
import { selectUserRole } from '../../../../../../store/user/selectors';

const useRow = () => {
  const dispatch = useDispatch();

  const role = useSelector(selectUserRole);
  const [isCopied, setIsCopied] = useState(false);

  const hasPermission = useMemo(() => role === 'admin' || role === 'director', [role]);
  const isAccountant = useMemo(() => role === 'accountant', [role]);
  const isMarketing = useMemo(() => role === 'marketingExpert', [role]);

  const onImport = useCallback((data) => {
    dispatch(
      openModal({
        name: modalNames.importProduct,
        data,
        title: `${data.name} | ${data.barcode || '—'}`,
      })
    );
  }, []);

  const onDefectExport = useCallback((data) => {
    dispatch(
      openModal({
        name: modalNames.exportProduct,
        data,
        title: `${data.name} | ${data.barcode || '—'}`,
      })
    );
  }, []);

  const onProductCheck = useCallback((data) => {
    dispatch(
      openModal({
        name: modalNames.checkProduct,
        data,
        title: `${data.name} | ${data.barcode || '—'}`,
      })
    );
  }, []);

  const handleCopy = (value) => () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 3000);
    }
  }, [isCopied]);

  return {
    isAccountant,
    isMarketing,
    onImport,
    onDefectExport,
    onProductCheck,
    hasPermission,
    handleCopy,
    isCopied,
  };
};

export default useRow;
