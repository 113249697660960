import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getStorePermission as gsp, setStorePermission as ssp } from './actions';

const initialState = {
  loading: false,
  error: false,
  success: false,
  data: {},
  savedData: null,
};

const get = handleActions(
  {
    [gsp]: (state) => ({ ...state, loading: true, error: false }),
    [gsp.success]: (state, { payload: data }) => ({ ...state, loading: false, data }),
    [gsp.error]: (state) => ({ ...state, loading: false, error: true }),
    [gsp.reset]: (state) => ({ ...initialState, savedData: state.savedData }),
    [ssp]: (state) => ({ ...state, loading: true, error: false }),
    [ssp.error]: (state) => ({ ...state, loading: false, error: true }),
  },
  initialState
);

export default combineReducers({ get });
