import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getCD, setCD } from './actions';

const setInitialState = {
  loading: false,
  error: false,
  success: false,
};

const set = handleActions(
  {
    [setCD]: () => ({
      loading: true,
      error: false,
    }),
    [setCD.success]: () => ({
      loading: false,
      error: false,
    }),
    [setCD.error]: () => ({
      loading: false,
      error: true,
    }),
    [setCD.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  setInitialState
);

const getInitialState = {
  loading: false,
  error: false,
  value: '',
};

const get = handleActions(
  {
    [getCD]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [getCD.success]: (state, { payload: { value } }) => ({
      ...state,
      loading: false,
      error: false,
      value,
    }),
    [getCD.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [getCD.reset]: (state) => ({
      ...state,
      loading: false,
      error: false,
    }),
  },
  getInitialState
);

export default combineReducers({ set, get });
