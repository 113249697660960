export const initialVisibles = {
  whereCome: false,
  sex: false,
  region: false,
  state: false,
  filterDate: false,
};

export const CollapseNames = {
  whereCome: 'whereCome',
  sex: 'sex',
  region: 'region',
  state: 'state',
  filterDate: 'filterDate',
};

export const dateOption = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const defaultProductPicture =
  'https://previews.123rf.com/images/alekseyvanin/alekseyvanin1705/alekseyvanin170502596/77916594-caja-icono-de-la-l%C3%ADnea-del-paquete-muestra-rellena-del-vector-del-esquema-pictograma-colorido.jpg';
