import React from 'react';
import moment from 'moment';
import Image from '../../../components/image/Image';

let counter = 0;

export const getEmptyProduct = () => ({
  name: '',
  count: 0,
  sale: 0,
  selectedProduct: null,
  price: 0,
  uId: `${(counter += 1)}_uid`,
});

export const getReturnProducts = ({ products, returnProducts }) => {
  return products.map((product) => {
    const newProduct = { ...product };
    const findElement =
      returnProducts.find(({ productId }) => {
        return productId === newProduct.selectedProduct.value.id;
      }) || {};
    return {
      price: newProduct.price,
      name: newProduct.selectedProduct?.value?.name,
      sale: newProduct.sale || 0,
      returnPrice: newProduct.price * findElement.count + (findElement.extraSale ? -findElement.extraSale : 0),
      returnCount: findElement.returnCount || findElement.count,
      count: newProduct.count,
      checked: !!Object.keys(findElement).length,
      productId: newProduct.selectedProduct?.value?.id,
    };
  });
};

const getProductToChange = ({ newProduct, findElement }) => {
  if (Object.keys(findElement).length) {
    return {
      ...findElement,
      label: findElement.changeNewProduct?.name,
      value: {
        id: newProduct.selectedProduct.value.id,
        name: findElement.changeNewProduct?.name,
        label: findElement.changeNewProduct?.name,
        value: findElement.changeNewProduct.id,
        price: newProduct.price,
      },
    };
  }
  return {
    ...newProduct.selectedProduct,
    value: { ...newProduct.selectedProduct?.value, price: newProduct.price },
  };
};

// export const getCeilPrice = (price) => price.toFixed();

export const getSalePrice = ({ count, price, sale, totalCount }) => {
  const salePerProduct = sale / totalCount.toFixed();
  const productDiscountedPrice = price - salePerProduct;

  return (count || 0) * productDiscountedPrice;
};

export const getPrice = ({ count, price }) => {
  return count * price;
};

export const refundingPrice = ({ count, price, withholding = 0 }) => {
  return count * price - withholding;
};

export const getChangeProducts = ({ products, changeProducts }) => {
  return products.map((product) => {
    const newProduct = { ...product };
    const findElement =
      changeProducts.find(({ productId }) => {
        return productId === newProduct.selectedProduct.value.id;
      }) || {};

    const productToChange = getProductToChange({ newProduct, findElement });
    return {
      price: newProduct.price,
      returnPrice: getSalePrice({
        count: findElement.count,
        totalCount: newProduct.count,
        price: newProduct.price,
        sale: newProduct.sale || 0,
      }),
      totalCount: newProduct.count,
      returnCount: findElement.count || 0,
      name: newProduct.selectedProduct?.value?.name,
      sale: newProduct.sale || 0,
      extraSale: findElement?.extraSale || 0,
      productToChange,
      changePrice: findElement.returnChangePrice || 0,
      changeCount: findElement.newProductCount || 0,
      count: newProduct.count,
      checked: !!Object.keys(findElement).length,
      productId: newProduct.selectedProduct?.value?.id,
      changeProducts,
    };
  });
};

const getIsOrderEditable = (data) => {
  return (
    (data.orderStatus === 'then' || data.orderStatus === null) &&
    data.orderType !== 'return' &&
    data.orderType !== 'change'
  );
};

const getStickers = (data) =>
  (data.labels || []).map(({ id, name, color }) => ({
    label: name,
    value: id,
    color,
  }));

const getProducts = (data, T) =>
  (data.orderItems || []).map(({ id, name, count, productId, price, sale, orderedProduct }) => {
    const profilePicture = orderedProduct.photos[0]?.url;

    const productTypes = {
      test: T.test,
      real: T.real,
    };
    return {
      id,
      name,
      count,
      price,
      sale: sale || 0,
      uId: `${(counter += 1)}_uid`,
      selectedProduct: {
        label: (
          <div>
            <Image src={profilePicture} alt="" className="productTypeSelectImage" mode="withBoarder" />
            <Image src={profilePicture} alt="" className="hoveredImage" mode="withBoarder" />
            <span>
              {`${orderedProduct.name} | ${productTypes[orderedProduct.type]} ${
                (orderedProduct.barcode && `| ${orderedProduct?.barcode}`) || ''
              } `}
              {(orderedProduct?.marketingCount && (
                <>
                  | {T.count}{' '}
                  <span className={orderedProduct?.marketingCount?.count < 0 ? 'negativeValue' : ''}>
                    {orderedProduct?.marketingCount?.count}
                  </span>
                </>
              )) ||
                ''}
            </span>
          </div>
        ),
        value: { id: productId, name },
      },
    };
  });

export const getRegion = (data) => {
  return data?.orderedFrom === 'yerevan'
    ? 'Երևան'
    : data?.orderedFrom === 'region'
    ? 'state'
    : data?.orderedFrom === 'warehouse'
    ? 'warehouse'
    : '';
};
const getDefaultReturnProducts = (data) =>
  data.orderType === 'return'
    ? getReturnProducts({
        returnProducts: data.returnChangeProducts || [],
        products: data.products,
      })
    : [];

const getDefaultChangeProducts = (data) => {
  if (data.orderType === 'change') {
    return getChangeProducts({
      changeProducts: data.returnChangeProducts || [],
      products: data.products,
    });
  }
  return [];
};

export const getInitialValues = (
  { listId, name, productId, data = {}, defaultCd, useData, canUse, formValues = {} } = {},
  T
) => {
  const renderDeliveryDate = data.deliveryStart?.split(' ')[0].replaceAll('-', '/');

  if (Object.keys(formValues).length) {
    return { ...formValues, ...useData };
  }
  if (Object.keys(data || {}).length && data.createdFrom !== 'web') {
    const region = getRegion(data);
    const startDate = data.deliveryStart ? moment(data.deliveryStart) : null;
    const endDate = data.deliveryEnd ? moment(data.deliveryEnd) : null;
    // TODO
    const products = getProducts(data, T);

    const startHour =
      startDate?.hour().toString().length === 1 &&
      startDate?.hour().toString() - 4 === 0 &&
      startDate?.minute().toString() === '0'
        ? ''
        : startDate?.hour().toString().length === 1
        ? `0${startDate?.hour()}`
        : startDate?.hour();
    const startMinute =
      startDate?.minute().toString().length === 1 &&
      startDate?.minute().toString() === '0' &&
      startDate?.hour().toString() - 4 === 0
        ? ''
        : startDate?.minute().toString().length === 1
        ? `0${startDate?.minute()}`
        : startDate?.minute();
    const endHour = endDate?.hour().toString().length === 1 ? `0${endDate?.hour()}` : endDate?.hour();
    const endMinute = endDate?.minute().toString().length === 1 ? `0${endDate?.minute()}` : endDate?.minute();
    const [firstName, lastName] = data.fullName ? data.fullName?.split(' ') : ['', ''];
    const IS_YEREVAN = process.env.REACT_APP_LANG === 'true';
    return {
      productId,
      products,
      date: renderDeliveryDate || '',
      createType: data.createdFrom,
      firstName,
      lastName,
      building: data.building || '',
      apartment: data.apartment || '',
      sex: data.sex,
      comments: data.comments,
      mainPhone: data.phone1 || '',
      userId: data.userID,
      otherPhone: data.phone2 || '',
      state: data?.state === 'Երևան' && !IS_YEREVAN ? 'Tbilisi' : data?.state,
      city: data.city || '',
      region: region || '',
      address: data.address,
      deliveryPrice: data.deliveryPrice,
      hourMinute: {
        startHour: startHour || '',
        startMinute: startMinute || '',
        endHour: endHour || '',
        endMinute: endMinute || '',
      },

      list: {
        label: name,
        value: listId,
      },
      index: data.index || '',
      cd: data.cd || defaultCd || '',
      stickers: getStickers(data),
      orderStatus: data.orderStatus, // 'done', 'then', 'rejected', 'callRejected' , null // then || null editable
      orderEditable: getIsOrderEditable(data),
      orderType: data.orderType, // 'sale', 'return', 'change' , null

      returnProducts: getDefaultReturnProducts({ ...data, products }),
      changeProducts: getDefaultChangeProducts({ ...data, products }),
      returnChangePlace: data.returnChangePlace || 'warehouse', // 'deliveryman', 'warehouse'
      returnChangeState: {
        label: data.returnChangeState,
        value: data.returnChangeState,
      },
      returnChangeAddress: data.returnChangeAddress,
      returnChangeDeliveryPrice: data.returnChangeDeliveryPrice,
    };
  }
  if (Object.keys(data || {}).length && data.createdFrom === 'web' && !useData) {
    const region = getRegion(data);
    const startDate = data.deliveryStart ? moment(data.deliveryStart) : null;
    const endDate = data.deliveryEnd ? moment(data.deliveryEnd) : null;
    const products = getProducts(data, T);

    const startHour =
      startDate?.hour().toString().length === 1 &&
      startDate?.hour().toString() - 4 === 0 &&
      startDate?.minute().toString() === '0'
        ? ''
        : startDate?.hour().toString().length === 1
        ? `0${startDate?.hour()}`
        : startDate?.hour();
    const startMinute =
      startDate?.minute().toString().length === 1 &&
      startDate?.minute().toString() === '0' &&
      startDate?.hour().toString() - 4 === 0
        ? ''
        : startDate?.minute().toString().length === 1
        ? `0${startDate?.minute()}`
        : startDate?.minute();
    const endHour = endDate?.hour().toString().length === 1 ? `0${endDate?.hour()}` : endDate?.hour();
    const endMinute = endDate?.minute().toString().length === 1 ? `0${endDate?.minute()}` : endDate?.minute();
    const [firstName, lastName] = data.fullName ? data.fullName?.split(' ') : ['', ''];
    return {
      productId,
      products,
      date: renderDeliveryDate || '',
      createType: data.createdFrom,
      firstName,
      lastName,
      building: data.building || '',
      apartment: data.apartment || '',
      sex: data.sex,
      comments: data.comments,
      mainPhone: data.phone1,
      userId: data.userID,
      otherPhone: data.phone2 || '',
      state: data.state || '',
      city: data.city || '',
      region: region || '',
      address: data.address,
      deliveryPrice: data.deliveryPrice,
      hourMinute: {
        startHour: startHour || '',
        startMinute: startMinute || '',
        endHour: endHour || '',
        endMinute: endMinute || '',
      },

      list: {
        label: name,
        value: listId,
      },
      index: data.index || '',
      cd: data.cd || defaultCd || '',
      stickers: getStickers(data),
      orderStatus: data.orderStatus, // 'done', 'then', 'rejected', 'callRejected' , null // then || null editable
      orderEditable: getIsOrderEditable(data),
      orderType: data.orderType, // 'sale', 'return', 'change' , null

      returnProducts: getDefaultReturnProducts({ ...data, products }),
      changeProducts: getDefaultChangeProducts({ ...data, products }),
      returnChangePlace: data.returnChangePlace || 'warehouse', // 'deliveryman', 'warehouse'
      returnChangeState: {
        label: data.returnChangeState,
        value: data.returnChangeState,
      },
      returnChangeAddress: data.returnChangeAddress,
      returnChangeDeliveryPrice: data.returnChangeDeliveryPrice,
    };
  }
  const userInformation = {
    productId,
    sex: '',
    products: [getEmptyProduct()],
    date: renderDeliveryDate || '',
    createType: null,
    comments: [],
    building: '',
    apartment: '',
    mainPhone: localStorage.getItem('phone') || '',
    otherPhone: '',
    index: '',
    cd: defaultCd || '',
    state: '',
    city: '',
    region: '',
    address: '',
    deliveryPrice: 0,
    deliveryStart: '',
    deliveryEnd: '',
    hourMinute: {
      startHour: '',
      startMinute: '',
      endHour: '',
      endMinute: '',
    },

    list: {
      label: name,
      value: listId,
    },
    stickers: [],
    orderStatus: null,
    orderType: null,
    orderEditable: true,
    returnChangeState: undefined,
    returnChangeAddress: 'warehouse',
    returnChangeDeliveryPrice: '',
    firstName: '',
    lastName: '',
  };

  if (Object.keys(data || {}).length && data.createdFrom === 'web' && useData && canUse) {
    return {
      ...userInformation,
      ...useData,
      ...{ createType: 'web', products: getProducts(data, T) },
    };
  }

  return useData && canUse ? { ...userInformation, ...useData } : { ...userInformation };
};

export const getOneProductTotalPrice = ({ price, count, sale }) => {
  const priceToString = price.toString();
  const totalPrice = +priceToString.replaceAll(',', '') * count - sale;
  return totalPrice;
};
