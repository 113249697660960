import { useParams, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { drag, getDraggableTable } from '../../store/draggable-board/actions';
import {
  selectClearStatusSuccess,
  selectCopyListItem,
  selectCreateBoardListItem,
  selectDrag,
  selectDraggableBoard,
  selectStatusChangeSuccess,
  statusShippingSuccess,
} from '../../store/draggable-board/selector';
import { getListsAdditional } from '../../service-requests/getListsAdditional';
import { selectDeliverymanProductsClear } from '../../store/deliveryman-products/selectors';
import { selectMoveAllItems } from '../../store/lists/selectors';
import { selectSavedFilter } from '../../store/filter/selectors';
import { selectLang } from '../../store/user/selectors';
import { Lang } from '../../T';
import { modalNames } from '../../store/modals/constants';
import { openModal } from '../../store/modals/actions';

export const useDraggableBoard = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const dispatch = useDispatch();
  const { id } = useParams();
  const [changeContent, setChangeContent] = useState(false);
  const [additional, setAdditional] = useState({});
  const { filteredData } = useSelector(selectSavedFilter);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const { data, loading, archived } = useSelector(selectDraggableBoard);
  const { success: moveItemsSuccess } = useSelector(selectMoveAllItems);
  const editSuccess = useSelector(selectStatusChangeSuccess);
  const rejectSuccess = useSelector(statusShippingSuccess);
  const createStatus = useSelector(selectCreateBoardListItem);
  const { success: copySuccess } = useSelector(selectCopyListItem);
  const { success: clearDeliveryManProductSuccess } = useSelector(selectDeliverymanProductsClear);
  const clearReturnChangeSuccess = useSelector(selectClearStatusSuccess);
  const { search } = useLocation();

  const orderId = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('order');
  }, []);

  const { error } = useSelector(selectDrag);
  const timeoutIndexRef = useRef();

  const changeListOrder = useCallback((result) => {
    dispatch(drag({ result, T }));
  }, []);

  useEffect(() => {
    if (orderId) {
      dispatch(openModal({ name: modalNames.createEditOrder, data: { listItemId: orderId, mode: 'edit' } }));
    }
  }, [orderId]);

  useEffect(async () => {
    if (changeContent) {
      const data = await getListsAdditional(id, filteredData, archived);
      setAdditional(
        data?.reduce((acc, col) => {
          const listInfo = col[0];
          acc[listInfo?.id] = listInfo;
          return acc;
        }, {})
      );
    }
  }, [changeContent]);

  const handleUserKeyPress = useCallback((event) => {
    const { key } = event;
    const path = event.path || (event.composedPath && event.composedPath());
    const isDragableBoard = path.filter((item) => item.className === 'headerSearchInput')[0];

    const isOtherComponentRendered = document.querySelector('[data-name="createEditOrder"]');

    const isCreateEditModalOpen = isOtherComponentRendered.classList.value.includes('visible');

    if (key === 'q' && !isDragableBoard && !isCreateEditModalOpen) {
      setChangeContent((prev) => !prev);
    }

    if (key === 'w') {
      setIsOpenFilter((prev) => !prev);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, []);

  useEffect(() => {
    dispatch(getDraggableTable({ id, archived }));
  }, [id, archived]);

  useEffect(() => {
    if (createStatus?.success) {
      dispatch(getDraggableTable({ id, archived }));
    }
  }, [createStatus?.success]);

  useEffect(() => {
    if (rejectSuccess) {
      dispatch(getDraggableTable({ id, archived }));
    }
  }, [rejectSuccess]);

  useEffect(() => {
    if (clearDeliveryManProductSuccess) {
      dispatch(getDraggableTable({ id, archived }));
    }
  }, [clearDeliveryManProductSuccess]);

  useEffect(() => {
    if (moveItemsSuccess) {
      dispatch(getDraggableTable({ id, archived }));
    }
  }, [moveItemsSuccess]);

  useEffect(() => {
    if (copySuccess) {
      dispatch(getDraggableTable({ id, archived }));
    }
  }, [copySuccess]);

  useEffect(() => {
    if (clearReturnChangeSuccess) {
      dispatch(getDraggableTable({ id, archived }));
    }
  }, [clearReturnChangeSuccess]);

  const updateTable = useCallback(() => {
    timeoutIndexRef.current = setTimeout(() => {
      dispatch(getDraggableTable({ id, archived }));
    }, 2000);
  }, []);

  useEffect(() => {
    if (editSuccess) {
      dispatch(getDraggableTable({ id, archived }));
    }
    if (error) {
      updateTable();
    }
  }, [editSuccess, error]);

  useEffect(() => {
    return () => {
      dispatch(getDraggableTable.reset());
    };
  }, []);
  return {
    data,
    loading,
    changeListOrder,
    changeContent,
    setChangeContent,
    additional,
    archived,
    isOpenFilter,
  };
};
