import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { createComment, getComments } from './actions';

// get
const getInitialState = {
  loading: false,
  success: false,
  error: false,
  data: [],
};
const get = handleActions(
  {
    [getComments]: () => ({
      loading: true,
      error: false,
      success: false,
      data: [],
    }),
    [getComments.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      success: true,
      data,
    }),
    [getComments.error]: () => ({
      loading: false,
      error: true,
      success: false,
      data: [],
    }),
    [getComments.reset]: () => ({
      loading: false,
      error: false,
      success: false,
      data: [],
    }),
  },
  getInitialState
);
// create
const createInitialState = {
  loading: false,
  error: false,
  success: false,
};
const create = handleActions(
  {
    [createComment]: () => ({
      loading: true,
      error: false,
      success: false,
    }),
    [createComment.success]: () => ({
      loading: false,
      error: false,
      success: true,
    }),
    [createComment.error]: () => ({
      loading: false,
      error: true,
      success: false,
    }),
    [createComment.reset]: () => ({
      loading: false,
      error: false,
      success: false,
    }),
  },
  createInitialState
);

export default combineReducers({ create, get });
