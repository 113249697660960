import { createCombinedAction } from '../helpers';

export const getDraggableTable = createCombinedAction({
  name: 'getDraggableTable',
  prefix: 'draggableTable@@',
});

export const changeOrderStatusBack = createCombinedAction({
  name: 'changeOrderStatusBack',
  prefix: 'changeOrderStatusBack@@',
});

export const changeOrderStatusDone = createCombinedAction({
  name: 'changeOrderStatusDone',
  prefix: 'changeOrderStatusDone@@',
});

export const toggleArchive = createCombinedAction({
  name: 'toggleArchive',
  prefix: 'draggableTable@@',
});

export const getDraggableTableListItems = createCombinedAction({
  name: 'getDraggableTableListItems',
  prefix: 'draggableTable@@',
});

export const drag = createCombinedAction({
  name: 'drag',
  prefix: 'draggableTable@@',
});

export const createList = createCombinedAction({
  name: 'createList',
  prefix: 'draggableTable@@',
});

export const removeList = createCombinedAction({
  name: 'removeList',
  prefix: 'draggableTable@@',
});

export const editList = createCombinedAction({
  name: 'editList',
  prefix: 'draggableTable@@',
});

export const createListItem = createCombinedAction({
  name: 'createListItem',
  prefix: 'draggableTable@@',
});

export const getListItemToEdit = createCombinedAction({
  name: 'getListItemToEdit',
  prefix: 'draggableTable@@',
});

export const editListItem = createCombinedAction({
  name: 'editListItem',
  prefix: 'draggableTable@@',
});

export const clearErrorMessage = createCombinedAction({
  name: 'clearErrorMessage',
  prefix: 'draggableTable@@',
});

export const archiveListItem = createCombinedAction({
  name: 'archiveListItem',
  prefix: 'draggableTable@@',
});

export const archiveList = createCombinedAction({
  name: 'archiveList',
  prefix: 'draggableTable@@',
});

export const copyListItem = createCombinedAction({
  name: 'copyListItem',
  prefix: 'draggableTable@@',
});

export const removeListItem = createCombinedAction({
  name: 'removeListItem',
  prefix: 'draggableTable@@',
});

export const resetReturnChangeListItemStatus = createCombinedAction({
  name: 'resetReturnChangeListItemStatus',
  prefix: 'draggableTable@@',
});

export const statusShippingPut = createCombinedAction({
  name: 'statusShipping',
  prefix: 'draggableTable@@',
});

export const boardArchiveds = createCombinedAction({
  name: 'archive',
  prefix: 'draggableTable@@',
});

export const putFilteredValueInStore = createCombinedAction({
  name: 'filteredValue',
  prefix: 'draggableTable@@',
});

export const getMoreInfoOrder = createCombinedAction({
  name: 'getMoreInfoOrder',
  prefix: 'draggableTable@@',
});

export const revertStatus = createCombinedAction({
  name: 'revertStatus',
  prefix: 'draggableTable@@',
});
export const getBoardName = createCombinedAction({
  name: 'getBoardName',
  prefix: 'draggableTable@@',
});
export const saveListItemId = createCombinedAction({
  name: 'saveListItemId',
  prefix: 'draggableTable@@',
});
export const addLabel = createCombinedAction({
  name: 'addLabel',
  prefix: 'draggableTable@@',
});
export const removeLabel = createCombinedAction({
  name: 'removeLabel',
  prefix: 'draggableTable@@',
});
