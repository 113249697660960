import { all, takeEvery, put, call } from 'redux-saga/effects';
import Axios, { csvAxios } from '../../services/axios';
import { errorLogger } from '../../helpers/errorLogger';
import { downLoadBuyer, downLoadCSF, referForDownloadAction } from './actions';
import { axiosData } from './requestGenerators';

import { closeModal } from '../modals/actions';
import { modalNames } from '../modals/constants';
import showNotification from '../../modals/components/notification/showNotification';

function* referForDownloadCsv({ payload }) {
  const { url } = axiosData.referForDownload;
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.referForDownload, data: payload, url });
  return result;
}

function* watchReferDownLoadCsv() {
  yield takeEvery(referForDownloadAction, function* workReferDownLoadCsv({ payload }) {
    const { dispatch, T } = payload;
    try {
      yield call(referForDownloadCsv, { payload });
      yield put(referForDownloadAction.success());
      dispatch(closeModal({ name: modalNames.downLoadCSF }));
      showNotification(dispatch, T.referSuccess);
    } catch (e) {
      errorLogger(e);
      yield put(referForDownloadAction.error());
      showNotification(dispatch, T.error);
    }
  });
}

function* downloadCSFRequest({ payload }) {
  // const { url } = axiosData.downLoadCSF;
  const { data } = yield call(csvAxios.request, {
    ...axiosData.downLoadCSF,
    url: `/api/admin/download/${payload.path}`,
  });
  return data;
}

function* watchDownloadCSF() {
  yield takeEvery(downLoadCSF, function* downloadFileCSF({ payload }) {
    try {
      const response = yield call(downloadCSFRequest, { payload });
      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        // type: 'html',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', payload.path);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      yield put(downLoadCSF.success(response));
    } catch (e) {
      errorLogger(e);
      yield put(downLoadCSF.error());
    }
  });
}

function* downloadBuyerRequest({ payload }) {
  const url = axiosData.downLoadBuyer.url.replace(':path', payload.path);
  const { data } = yield call(csvAxios.request, {
    ...axiosData.downLoadBuyer,
    url,
  });
  return data;
}

function* watchDownloadBuyer() {
  yield takeEvery(downLoadBuyer, function* workDownloadBuyer({ payload }) {
    try {
      const response = yield call(downloadBuyerRequest, { payload });
      const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        // type: 'html',
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', payload.path);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      yield put(downLoadBuyer.success(response));
    } catch (e) {
      errorLogger(e);
      yield put(downLoadBuyer.error());
    }
  });
}

export default function* download() {
  yield all([watchDownloadCSF(), watchReferDownLoadCsv(), watchDownloadBuyer()]);
}
