import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { openModal } from '../../store/modals/actions';
import { modalNames } from '../../store/modals/constants';
import RedPinSvg from '../../svgs/RedPinSvg';
import GreenPinSvg from '../../svgs/GreenPinSvg';
import CommentsSvg from '../../svgs/CommentsSvg';
import ControlArrow from '../../svgs/ControlArrow';
import AddressInfo from './components/addressInfo/AddressInfo';
import classNames from './card.module.scss';
import ListEditDropdown from '../../pages/draggable-board/components/column/components/list-edit-dropdown/ListEditDropdown';
import { handleRemoveList } from '../../store/draggable-board/helpers';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';
import { formatNumberWithThousandsSeparator } from '../../helpers/formatNubmer';

const Card = ({ mode, name, item, column, boardId, productName, openEdit, showMenu, setShowMenu, listId, ...rest }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const isUrlCurrect = item?.listItemOrder?.operator?.profilePicture || '/images/avatar.png';
  const barcode = item?.listItemOrder?.barcode;
  const url = `${process.env.REACT_APP_BASE_ENDPOINT}${isUrlCurrect}`;
  const IS_YEREVAN = process.env.REACT_APP_LANG === 'true';
  const payments = { cash: T.cache, 'no-cash': T.non_cache };

  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  let renderedOrderStatus;
  const deliveryStartTime = `${item?.listItemOrder?.deliveryStart?.split(' ')[1]?.split(':')[0]} : ${
    item?.listItemOrder?.deliveryStart?.split(' ')[1]?.split(':')[1]
  }`;

  const deliveryEndTime = `${item?.listItemOrder?.deliveryEnd?.split(' ')[1]?.split(':')[0]} : ${
    item?.listItemOrder?.deliveryEnd?.split(' ')[1]?.split(':')[1]
  }`;
  const deliveryDate = `${item?.listItemOrder?.deliveryStart?.split(' ')[0]}`;
  const isDateNull = item?.listItemOrder?.deliveryStart;
  const newDate = new Date();
  const dateNow = moment(newDate).format('YYYY-MM-DD');
  const delyveryYear = deliveryDate.split('-')[0];
  const delyveryMonth = deliveryDate.split('-')[1];
  const delyveryDay = deliveryDate.split('-')[2];

  const nowYear = dateNow.split('-')[0];
  const nowMonth = dateNow.split('-')[1];
  const nowDay = dateNow.split('-')[2];
  const isDateNear = delyveryYear === nowYear && delyveryMonth === nowMonth;

  const renderIfStatusDone = {
    sale: T.sold,
    return: T.returned,
    change: T.changed,
  };
  const renderIfStatusNull = {
    sale: T.current,
    return: T.for_return,
    change: T.for_change,
  };

  const renderIfStatusThen = {
    sale: T.sale_then,
    return: T.return_then,
    change: T.change_then,
  };
  const determinItemStatuses = {
    done: (orderType) => (renderedOrderStatus = renderIfStatusDone[orderType]),
    null: (orderType) => (renderedOrderStatus = renderIfStatusNull[orderType]),
    rejected: () => (renderedOrderStatus = T.rejected_in_place),
    callRejected: () => (renderedOrderStatus = T.rejected_by_call),
    then: (orderType) => (renderedOrderStatus = renderIfStatusThen[orderType]),
    default: () => (renderedOrderStatus = T.current),
  };
  const determineStatus = () => {
    const orderStatus = item.listItemOrder?.orderStatus || 'default';
    const orderType = item.listItemOrder?.orderType;
    determinItemStatuses[orderStatus](orderType);
    return renderedOrderStatus;
  };

  const handleOpenToEditModal = useCallback(() => {
    dispatch(
      openModal({
        name: modalNames.createEditOrder,
        data: {
          listItemId: item.id,
          listId: column.id,
          name: column.name,
          boardId,
          mode: 'edit',
        },
      })
    );
  }, [item, column, boardId]);

  return mode !== 'search' ? (
    <div {...rest} className={classNames.columnInnerItem}>
      <div
        data-order-id={item.id}
        onClick={() => {
          handleOpenToEditModal();
          setShowMenu(false);
        }}
        className="w-100"
      >
        <div className="w-100 align-items-center justify-content-between">
          {productName?.map((product) => {
            return (
              <div key={crypto.randomUUID()}>
                <p className={`${classNames.innerItem} ${classNames.innerItemTitle}`}>
                  {product.name}
                  {item?.listItemOrder?.returnChangeProducts?.map((el) => {
                    if (el.newProductId && el.productId && el.productId === product.id) {
                      return <GreenPinSvg key={crypto.randomUUID()} />;
                    }

                    if (!el.newProductId && el.productId && el.productId === product.id) {
                      return <RedPinSvg key={crypto.randomUUID()} />;
                    }
                  })}
                </p>
                {showMore && (
                  <div className={classNames.openedInnerItems}>
                    <p className={`${classNames.innerItem} ${classNames.innerItemDate}`}>
                      <span>{T.count}</span>
                      <span>{product?.count}</span>
                    </p>
                    <p className={`${classNames.innerItem} ${classNames.innerItemDate}`}>
                      <span>{T.price}</span>
                      <span>{product?.price * product?.count}</span>
                    </p>
                  </div>
                )}
              </div>
            );
          })}
          <AddressInfo item={item} />
          <div className={classNames.cardDeliveryInfo}>
            <p className={`${classNames.innerItem} ${classNames.innerItemQuantity}`}>
              <span>{T.status}</span>
              <span>{determineStatus()}</span>
            </p>
            {item?.listItemOrder?.paymentType && (
              <p className={`${classNames.innerItem} ${classNames.innerItemQuantity}`}>
                <span>{T.payment_type}</span>
                <span>{payments[item?.listItemOrder?.paymentType]}</span>
              </p>
            )}
          </div>
          <div className={classNames.cardDeliveryInfo}>
            {item.listItemOrder?.deliveryEnd && !item.listItemOrder?.deliveryStart && (
              <p className={`${classNames.innerItem} ${classNames.innerItemDate}`}>
                <span>{T.hour}</span>
                <span>{`${T.to} ${deliveryEndTime}`}</span>
              </p>
            )}
            {item.listItemOrder?.deliveryStart && !item.listItemOrder?.deliveryEnd ? (
              deliveryStartTime === '04 : 00' ? (
                ''
              ) : (
                <p className={`${classNames.innerItem} ${classNames.innerItemDate}`}>
                  <span>{T.hour}</span>
                  <span>{`${deliveryStartTime}-${T.of_then}`}</span>
                </p>
              )
            ) : null}
            {item.listItemOrder?.deliveryEnd &&
              item.listItemOrder?.deliveryStart &&
              (deliveryStartTime === '04 : 00' ? (
                <p className={`${classNames.innerItem} ${classNames.innerItemDate}`}>
                  <span>{T.hour}</span>
                  <span>{`${T.to} ${deliveryEndTime}`}</span>
                </p>
              ) : (
                <p className={`${classNames.innerItem} ${classNames.innerItemDate}`}>
                  <span>{T.hour}</span>
                  <span>{`${deliveryStartTime} - ${deliveryEndTime}`}</span>
                </p>
              ))}
            {isDateNull && (
              <p className={`${classNames.innerItem} ${classNames.innerItemQuantity}`}>
                <span>{T.date}</span>
                <span>
                  {deliveryDate === dateNow
                    ? T.today
                    : isDateNear && delyveryDay - 1 === +nowDay
                    ? T.tomorrow
                    : deliveryDate.replaceAll('-', '.')}
                </span>
              </p>
            )}
            <p className={`${classNames.innerItem} ${classNames.innerItemQuantity}`}>
              <span>{T.count}</span>
              <span>{item.data?.totalProductsCount}</span>
            </p>
            <p className={`${classNames.innerItem} ${classNames.innerItemTotal}`}>
              <span>{T.total}</span>
              <span>
                {formatNumberWithThousandsSeparator(item.data?.totalPrice, ' ')}{' '}
                {IS_YEREVAN ? T.money_type_am : T.money_type}
              </span>
            </p>
            <p className={`${classNames.innerItem} ${classNames.innerItemPostINdex}`}>
              <span>{T.sales_code}</span>
              <span>{barcode}</span>
            </p>
          </div>
          <div className={`${classNames.innerItemStickers} colors d-flex carItemColor`}>
            {item.labels?.map(({ color, id, name }) => {
              return (
                <p key={id} style={{ background: color }}>
                  {name}
                </p>
              );
            })}{' '}
          </div>
          <p className={`${classNames.innerItem} ${classNames.innerItemComment}`}>
            <span>
              {' '}
              <CommentsSvg />({item?.commentsCount})
            </span>
            <span>
              <img src={url} alt="" />
            </span>
          </p>
          <p className={`${classNames.innerItem} ${classNames.innerItemExpand} ${showMore ? classNames.expanded : ''}`}>
            {productName?.length > 1 && (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowMore((prev) => !prev);
                }}
              >
                {' '}
                <ControlArrow />
              </span>
            )}
          </p>

          <ListEditDropdown
            handleRemoveList={handleRemoveList}
            handleArchiveList={() => {}}
            handleFilterList={() => handleFilterList(column.id)}
            archived={false}
            cardInfo={{ item, listId, setShowMenu }}
            card
          />
        </div>
      </div>
    </div>
  ) : (
    <div {...rest} className={`${classNames.columnInnerItem} ${classNames.mainSearchInnerItem}`}>
      <div
        data-order-id={item.id}
        onClick={() => {
          handleOpenToEditModal();
          setShowMenu(false);
        }}
        className="w-100"
      >
        <div className="w-100 align-items-center justify-content-between">
          <p className={`${classNames.innerItem} ${classNames.innerItemAddress}`}>{item.data?.address} </p>
          <p
            className={
              mode !== 'search' ? `${classNames.innerItem} ${classNames.innerItemQuantity}` : classNames.search
            }
          >
            <span>{item?.productName[0]}</span>
          </p>
          <div className={`${classNames.innerItemStickers} colors d-flex carItemColor`}>
            {item.labels?.map(({ color, id, name }) => {
              return (
                <p key={id} style={{ background: color }}>
                  {name}
                </p>
              );
            })}{' '}
          </div>

          <div className={classNames.seachCardItemImage}>
            <img src={url} alt="avatar" />
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  mode: PropTypes.string,
  name: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    labels: PropTypes.array,
    data: PropTypes.object,
    commentsCount: PropTypes.number,
    listItemOrder: PropTypes.object,
    createdAt: PropTypes.string,
    productName: PropTypes.array,
  }),
  productName: PropTypes.array,
  column: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
  }),
  boardId: PropTypes.string.isRequired,
  openEdit: PropTypes.func,
  showMenu: PropTypes.bool,
  setShowMenu: PropTypes.func,
  listId: PropTypes.string,
};
export default Card;
