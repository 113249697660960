import React from 'react';
import { useSelector } from 'react-redux';
import { useWorkers } from './useWorkers';
import WorkerRow from './components/worker-row/WorkerRow';
import classNames from './workers.module.scss';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';

const Workers = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { loading, data, openInviteModal, resendLoading, resendSuccess } = useWorkers();
  return (
    <div className={`${classNames.WorkersSection} WorkersSection_global`}>
      <div className="container">
        <div className={classNames.workersHeader}>
          <h2 className="title">{T.administrators}</h2>
          <button type="button" className={`${classNames.workersButton} text-right`} onClick={openInviteModal}>
            {T.register_new_admin}
          </button>
        </div>
      </div>

      {loading ? (
        'loading'
      ) : (
        <div className="container ml-0 maxw-1000">
          <div className={classNames.table}>
            <div className={classNames.tableHeader}>
              <div className={classNames.tableRow}>
                <div className={classNames.row_item}>
                  <p>ID</p>
                </div>
                <div className={classNames.row_item}>
                  <p>{T.image}</p>
                </div>
                <div className={classNames.row_item}>
                  <p>{T.name}</p>
                </div>
                <div className={classNames.row_item}>
                  <p>{T.status}</p>
                </div>
                <div className={classNames.row_item}>
                  <p>{T.date}</p>
                </div>
                <div className={classNames.row_item}>
                  <p>{T.role}</p>
                </div>
                <div className={classNames.row_item}>
                  <p>{T.email}</p>
                </div>
                <div className={classNames.row_item}>
                  <p>{T.salary}</p>
                </div>
                <div className={classNames.row_item}>
                  <p>{T.edit}</p>
                </div>
                <div className={classNames.row_item}>
                  <p>{T.delete}</p>
                </div>
              </div>
            </div>
            <div className={classNames.tableBody}>
              {data.map((params) => (
                <WorkerRow
                  openInviteModal={openInviteModal}
                  key={params.id}
                  {...params}
                  resendLoading={resendLoading}
                  resendSuccess={resendSuccess}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Workers;
