import React from 'react';

const FilterMobileSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.699" height="19.102" viewBox="0 0 16.699 19.102">
      <path
        id="filter_icon"
        data-name="filter icon"
        d="M15.24,1H1.459A.459.459,0,0,0,1,1.459V3.554a.459.459,0,0,0,.149.339l5.214,4.78a.459.459,0,0,1,.149.339v8.616a.459.459,0,0,0,.7.39l2.756-1.723a.459.459,0,0,0,.216-.39V9.011a.459.459,0,0,1,.149-.339l5.214-4.78a.459.459,0,0,0,.149-.339V1.459A.459.459,0,0,0,15.24,1Z"
        fill="none"
        stroke="#fff"
        strokeWidth="2"
      />
    </svg>
  );
};

export default FilterMobileSvg;
