import { all, takeEvery, put, call } from 'redux-saga/effects';
import Axios from '../../services/axios';
import { errorLogger } from '../../helpers/errorLogger';
import { getRegions } from './actions';
import { axiosData } from './requestGenerators';

function* getRegionsRequest() {
  const { url } = axiosData.getRegions;

  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.getRegions, url });

  return result;
}

function* watchGetRegions() {
  yield takeEvery(getRegions, function* workGetRegions() {
    try {
      const response = yield call(getRegionsRequest);
      yield put(
        getRegions.success(
          response.map((el) => {
            return {
              value: el.hayPostId,
              label: el.name,
            };
          })
        )
      );
    } catch (e) {
      errorLogger(e);
      yield put(getRegions.error());
    }
  });
}

export default function* getAllRegions() {
  yield all([watchGetRegions()]);
}
