import { all, call, put, takeEvery } from 'redux-saga/effects';
import { downloadReports, getOperatorsOrDeliveryMans } from './actions';
import { errorLogger } from '../../helpers/errorLogger';
import { handleChangeStructure } from './helpers';
import showNotification from '../../modals/components/notification/showNotification';
import { excelAxios } from '../../services/axios';
import { axiosData } from './requestGenerators';
import Axios from '../../services/axios';

function* downloadReportsRequest(reportValues) {
  const { dateRange, boardId, operatorIds, productIds, deliveryIds, type } = reportValues;

  const data = yield call(excelAxios.request, {
    method: 'post',
    url: '/excel/products',
    responseType: 'arraybuffer',
    data: {
      startDate: dateRange[0].toISOString().split('T')[0],
      endDate: dateRange[1].toISOString().split('T')[0],
      boardId,
      productIds,
      deliveryIds,
      operatorIds,
      type,
    },
  });

  return data;
}

function* watchDownLoadReports() {
  yield takeEvery(downloadReports, function* workDownloadFileCSF({ payload }) {
    const { dispatch, reportValues } = payload;
    try {
      const response = yield call(downloadReportsRequest, reportValues);
      const downloadFile = (blob, fileName) => {
        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
      };

      downloadFile(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${reportValues.type}.xlsx`
      );

      showNotification(dispatch, T.referSuccess);
      yield put(downloadReports.success(response));
    } catch (e) {
      errorLogger(e);
      showNotification(dispatch, T.error);
      yield put(downloadReports.error());
    }
  });
}
function* getOperatorOrDeliveryRequest({ payload }) {
  const url = axiosData.getOperatorOrDelivery.url
    .replace('selectedRole', payload.role)
    .replace('offsetNum', payload.offset);
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.getOperatorOrDelivery, url });
  return result;
}

function* watchGetOperatorOrDelivey() {
  yield takeEvery(getOperatorsOrDeliveryMans, function* workGetOperatorOrDelivey({ payload }) {
    try {
      const response = yield call(getOperatorOrDeliveryRequest, { payload });
      yield put(getOperatorsOrDeliveryMans.success(handleChangeStructure(response, payload.role)));
    } catch (e) {
      errorLogger(e);
      yield put(getOperatorsOrDeliveryMans.error());
    }
  });
}
export default function* rootProducts() {
  yield all([watchDownLoadReports(), watchGetOperatorOrDelivey()]);
}
