import React from 'react';

const ExportSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.21191 11.7021L4.33691 10.8271L8.12858 7.03548H4.83691V5.78548H10.2536V11.2021H9.00358V7.91048L5.21191 11.7021ZM14.2119 8.07715V1.82715H7.96191V0.577148H15.4619V8.07715H14.2119ZM1.71191 15.5771C1.37858 15.5771 1.08691 15.4521 0.836914 15.2021C0.586914 14.9521 0.461914 14.6605 0.461914 14.3271V0.577148H1.71191V14.3271H15.4619V15.5771H1.71191Z"
        fill="#FF6565"
      />
    </svg>
  );
};

export default ExportSvg;
