import React from 'react';
import classNames from './ads.module.scss';
import AdsFilters from './components/ads-filters/AdsFilters';
import Table from './components/table/Table';

const Ads = () => {
  return (
    <div className={classNames.adsWrapper}>
      <AdsFilters />
      <Table />
    </div>
  );
};

export default Ads;
