import { useSelector } from 'react-redux';
import { selectLang } from '../../store/user/selectors';
import { Lang } from '../../T';

const useConstant = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const periodOptions = [
    { label: T.Today, value: 'today' },
    { label: T.Yesterday, value: 'yesterday' },
    { label: T.CurrentWeek, value: 'currentWeek' },
    { label: T.CurrentMonth, value: 'currentMonth' },
    { label: T.CurrentYear, value: 'currentYear' },
    { label: T.PreviousWeek, value: 'previousWeek' },
    { label: T.PreviousMonth, value: 'previousMonth' },
    { label: T.PreviousYear, value: 'previousYear' },
  ];

  const regionOptions = [
    { label: T.All_regions, value: '' },
    {
      label: process.env.REACT_APP_LANG === 'true' ? T.city_type_am : T.city_type,
      value: 'yerevan',
    },
    { label: T.region, value: 'region' },
  ];

  const deviceOptions = [
    { label: T.All_sources, value: '' },
    { label: T.Web, value: 'web' },
    { label: T.Phone, value: 'phone' },
    { label: T.Social, value: 'social' },
    { label: T.Other, value: 'other' },
  ];

  const percentageTypeOptions = [
    { label: T.Compare, value: 'compare' },
    { label: 'CR', value: 'cr' },
  ];

  const initialFilter = {
    percentageType: percentageTypeOptions[0],
    period: periodOptions[2],
    region: regionOptions[0],
    device: deviceOptions[0],
    productIds: [],
    ignoreOutOfInterval: false,
    startTime: null,
    endTime: null,
    startDate: null,
    endDate: null,
    domainIds: [],
    urls: [],
    utm: [],
  };
  const dateOption = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  const utmOptions = [
    { label: 'MB', value: 0 },
    { label: 'Source', value: 1 },
    { label: 'Campaign', value: 2 },
    { label: 'Medium', value: 3 },
    { label: 'ID', value: 4 },
    { label: 'Term', value: 5 },
    { label: 'New', value: 6 },
  ];
  return {
    utmOptions,
    dateOption,
    initialFilter,
    percentageTypeOptions,
    deviceOptions,
    regionOptions,
    periodOptions,
  };
};

export default useConstant;
