import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from './adsFilters.module.scss';
import Search from '../../../../../../components/search/Search';
import Select from '../../../../../../components/select/Select';
import FilterIcon from '../../../../../../svgs/FilterIcon';
import { useAdsFilters } from './useAdsFilters';
import { openModal } from '../../../../../../store/modals/actions';
import { modalNames } from '../../../../../../store/modals/constants';

const AdsFilters = () => {
  const dispatch = useDispatch();
  const handleOpenCreateModal = () => {
    dispatch(
      openModal({
        name: modalNames.createFBAds,
        data: { modaliks: 'modalik' },
      })
    );
  };
  const { showFilters, handleFiltersShow } = useAdsFilters();
  return (
    <div className="container maxw-100">
      <div className={classNames.adsFiltersWrapper}>
        <div className={classNames.searchWithButton}>
          <div className={classNames.inputWrapper}>
            <Search placeholder="Որոնել" />
          </div>
          <button type="button" onClick={handleOpenCreateModal}>
            Create for FB
          </button>
        </div>
        <div className={classNames.allfilersWrapper}>
          <div className={classNames.adsRow}>
            <div className={`${classNames.col} ${classNames.small_col}`}>
              <div className={classNames.filterItem}>
                <Select
                  placeholder="ընտրել"
                  label="Type"
                  onChange={(value) => console.log(value)}
                  options={[
                    { label: 'aaa', value: 'aaa' },
                    { label: 'ccc', value: 'ccc' },
                    { label: 'vvv', value: 'vvv' },
                    { label: 'vyyy', value: 'vyyy' },
                  ]}
                  widthDate
                />
              </div>
            </div>
            <div className={`${classNames.col} ${classNames.small_col}`}>
              <div className={classNames.filterItem}>
                <Select
                  placeholder="ընտրել"
                  label="Type"
                  onChange={(value) => console.log(value)}
                  options={[
                    { label: 'aaa', value: 'aaa' },
                    { label: 'ccc', value: 'ccc' },
                    { label: 'vvv', value: 'vvv' },
                    { label: 'vyyy', value: 'vyyy' },
                  ]}
                  widthDate
                />
              </div>
            </div>
            <div className={`${classNames.col} ${classNames.small_col}`}>
              <div className={classNames.filterItem}>
                <Select
                  placeholder="ընտրել"
                  label="Type"
                  onChange={(value) => console.log(value)}
                  options={[
                    { label: 'aaa', value: 'aaa' },
                    { label: 'ccc', value: 'ccc' },
                    { label: 'vvv', value: 'vvv' },
                    { label: 'vyyy', value: 'vyyy' },
                  ]}
                  widthDate
                />
              </div>
            </div>
            <div className={`${classNames.col} ${classNames.small_col}`}>
              <div className={classNames.filterItem}>
                <Select
                  placeholder="ընտրել"
                  label="Type"
                  onChange={(value) => console.log(value)}
                  options={[
                    { label: 'aaa', value: 'aaa' },
                    { label: 'ccc', value: 'ccc' },
                    { label: 'vvv', value: 'vvv' },
                    { label: 'vyyy', value: 'vyyy' },
                  ]}
                  widthDate
                />
              </div>
            </div>
            <div className={`${classNames.col} ${classNames.small_col}`}>
              <div className={classNames.filterItem}>
                <Select
                  placeholder="ընտրել"
                  label="Type"
                  onChange={(value) => console.log(value)}
                  options={[
                    { label: 'aaa', value: 'aaa' },
                    { label: 'ccc', value: 'ccc' },
                    { label: 'vvv', value: 'vvv' },
                    { label: 'vyyy', value: 'vyyy' },
                  ]}
                  widthDate
                />
              </div>
            </div>
            <div className={`${classNames.col} ${classNames.small_col}`} onClick={handleFiltersShow}>
              <button type="button">
                <FilterIcon />
              </button>
            </div>
          </div>
          {showFilters && (
            <div className={classNames.hiddenFilterWrapper}>
              <div className={classNames.adsRow}>
                <div className={`${classNames.col} ${classNames.small_col}`}>
                  <div className={classNames.filterItem}>
                    <Select
                      placeholder="ընտրել"
                      label="Type"
                      onChange={(value) => console.log(value)}
                      options={[
                        { label: 'aaa', value: 'aaa' },
                        { label: 'ccc', value: 'ccc' },
                        { label: 'vvv', value: 'vvv' },
                        { label: 'vyyy', value: 'vyyy' },
                      ]}
                      widthDate
                    />
                  </div>
                </div>
                <div className={`${classNames.col} ${classNames.small_col}`}>
                  <div className={classNames.filterItem}>
                    <Select
                      placeholder="ընտրել"
                      label="Type"
                      onChange={(value) => console.log(value)}
                      options={[
                        { label: 'aaa', value: 'aaa' },
                        { label: 'ccc', value: 'ccc' },
                        { label: 'vvv', value: 'vvv' },
                        { label: 'vyyy', value: 'vyyy' },
                      ]}
                      widthDate
                    />
                  </div>
                </div>
                <div className={`${classNames.col} ${classNames.small_col}`}>
                  <div className={classNames.filterItem}>
                    <Select
                      placeholder="ընտրել"
                      label="Type"
                      onChange={(value) => console.log(value)}
                      options={[
                        { label: 'aaa', value: 'aaa' },
                        { label: 'ccc', value: 'ccc' },
                        { label: 'vvv', value: 'vvv' },
                        { label: 'vyyy', value: 'vyyy' },
                      ]}
                      widthDate
                    />
                  </div>
                </div>
                <div className={`${classNames.col} ${classNames.small_col}`}>
                  <div className={classNames.filterItem}>
                    <Select
                      placeholder="ընտրել"
                      label="Type"
                      onChange={(value) => console.log(value)}
                      options={[
                        { label: 'aaa', value: 'aaa' },
                        { label: 'ccc', value: 'ccc' },
                        { label: 'vvv', value: 'vvv' },
                        { label: 'vyyy', value: 'vyyy' },
                      ]}
                      widthDate
                    />
                  </div>
                </div>
                <div className={`${classNames.col} ${classNames.small_col}`}>
                  <div className={classNames.filterItem}>
                    <Select
                      placeholder="ընտրել"
                      label="Type"
                      onChange={(value) => console.log(value)}
                      options={[
                        { label: 'aaa', value: 'aaa' },
                        { label: 'ccc', value: 'ccc' },
                        { label: 'vvv', value: 'vvv' },
                        { label: 'vyyy', value: 'vyyy' },
                      ]}
                      widthDate
                    />
                  </div>
                </div>
                <div className={`${classNames.col} ${classNames.small_col}`} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdsFilters;
