import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import HipnosSvg from '../../svgs/HipnosSvg';
import RingSvg from '../../svgs/RingSvg';
import TextRotateSvg from '../../svgs/TextRotateSvg';
import CactusSvg from '../../svgs/CactusSvg';
import AvocadoSvg from '../../svgs/AvocadoSvg';
import MandarinSvg from '../../svgs/MandarinSvg';
import WatermelonSvg from '../../svgs/WatermelonSvg';
import GrassSvg from '../../svgs/GrassSvg';
import VerticalDarkDotSvg from '../../svgs/VerticalDarkDotSvg';
import classNames from './welcomePage.module.scss';

const icons = {
  1: CactusSvg,
  2: AvocadoSvg,
  3: MandarinSvg,
  4: WatermelonSvg,
  5: GrassSvg,
};
const WelcomPage = () => {
  const { pathname } = useLocation();
  const [counter, setCounter] = useState(0);
  const [openMessage, setOpenMessage] = useState(false);
  const [dragOne, setDragOne] = useState([]);
  const [dragTwo, setDragTwo] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const onSubmit = () => {
    if (!inputValue) {
      return;
    }
    setCounter((prevCounter) => (prevCounter < 5 ? prevCounter + 1 : 2));
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const onEnterPress = (e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  useEffect(() => {
    if (counter) {
      const newDragItem = {
        id: Math.random(),
        value: inputValue,
        price: (Math.random() * 100 + 5).toFixed(2),
        Icon: icons[counter],
      };
      if (counter % 2 === 0) {
        setDragTwo((prev) => [...prev, newDragItem]);
      } else {
        setDragOne((prev) => [...prev, newDragItem]);
      }
    }

    setInputValue('');
  }, [counter]);

  useEffect(() => {
    if (pathname === '/') {
      document.body.style.overflow = 'hidden';
    }
  }, []);

  const handleOpenMessage = () => {
    setOpenMessage((prev) => !prev);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const reorderHorizontal = (list, destList, sourceIdx, destIdx) => {
    const sourceResult = [...list];
    const [item] = sourceResult.splice(sourceIdx, 1);
    const destResult = [...destList.slice(0, destIdx), item, ...destList.slice(destIdx)];
    return {
      sourceResult,
      destResult,
    };
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination?.droppableId) {
      if (source.droppableId === 'list') {
        const items = reorder(dragOne, source.index, destination?.index);

        return setDragOne(items);
      }
      if (source.droppableId === 'list2') {
        const items = reorder(dragTwo, source.index, destination?.index);

        return setDragTwo(items);
      }
    }

    if (source.droppableId === 'list' && destination?.droppableId === 'list2') {
      const result = reorderHorizontal(dragOne, dragTwo, source.index, destination?.index || 0);
      setDragOne(result.sourceResult);
      setDragTwo(result.destResult);
    }

    if (source.droppableId === 'list2' && destination?.droppableId === 'list') {
      const result = reorderHorizontal(dragTwo, dragOne, source.index, destination?.index || 0);
      setDragTwo(result.sourceResult);
      setDragOne(result.destResult);
    }
  };
  return (
    <div className={classNames.welcome_page}>
      <HipnosSvg />
      <div className={classNames.welcome_page_container}>
        <main className={classNames.welcome_main}>
          <div className={classNames.welcomRow}>
            <div className={classNames.leftSide}>
              <div className={classNames.leftContent}>
                <h1 className={classNames.title}>Moulk.</h1>
                <p className={classNames.subTitle}>CRM system</p>
                <p className={classNames.description}>A convenient web program for sales analysis</p>
                <div className={classNames.clickBlock}>
                  <div className={classNames.clickContainer}>
                    <RingSvg />
                    <div className={classNames.clickHere}>
                      <button type="button" onClick={handleOpenMessage}>
                        <TextRotateSvg />
                      </button>
                      {openMessage && (
                        <div className={classNames.clickMessage}>
                          <div className={classNames.textItems}>
                            <p>hi</p>
                            <p>You have new avocado order :)</p>
                          </div>
                          <div className={classNames.buttonWrapper} onClick={handleOpenMessage}>
                            <span>OK</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.rightSide}>
              <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                <div className={classNames.addToList}>
                  <input
                    type="text"
                    placeholder="Board name"
                    value={inputValue}
                    onChange={onInputChange}
                    onKeyDown={onEnterPress}
                  />
                  <button type="button" disabled={!inputValue} onClick={onSubmit}>
                    Add
                  </button>
                </div>
                <div className={classNames.dragDropAreas}>
                  <div className={classNames.dropArea}>
                    <div className={classNames.areaName}>
                      Orders
                      <VerticalDarkDotSvg />
                    </div>
                    <Droppable isDropDisabled={false} droppableId="list">
                      {(provided) => (
                        <div className={classNames.dropAbleMain} ref={provided.innerRef} {...provided.droppableProps}>
                          {dragOne.map(({ id, value, price, Icon }, index) => (
                            <Draggable key={id} draggableId={`${id}`} index={index}>
                              {(provided) => (
                                <div
                                  className={classNames.dragItem}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className={classNames.innerItem}>
                                    <Icon />
                                    <p>
                                      <span>{value}</span>
                                      <span>${price}</span>
                                    </p>
                                    <VerticalDarkDotSvg />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                  <div className={classNames.dropArea}>
                    <div className={`${classNames.areaName} ${classNames.areaNameDark}`}>
                      Delivery
                      <VerticalDarkDotSvg />
                    </div>
                    <Droppable isDropDisabled={false} droppableId="list2">
                      {(provided) => (
                        <div className={classNames.dropAbleMain} ref={provided.innerRef} {...provided.droppableProps}>
                          {dragTwo.map(({ id, value, price, Icon }, index) => (
                            <Draggable key={id} draggableId={`${id}`} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={classNames.dragItem}
                                >
                                  <div className={classNames.innerItem}>
                                    <Icon />
                                    <p>
                                      <span>{value}</span>
                                      <span>${price}</span>
                                    </p>
                                    <VerticalDarkDotSvg />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </div>
              </DragDropContext>
            </div>
          </div>
        </main>
        <footer className={classNames.welcome_footer}>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-12 col-md-6 text-md-left text-center">
                <p className="m-0">
                  © moulk.{' '}
                  <a className={classNames.hiddenLink} href="/signinmoulk">
                    CRM
                  </a>
                  , All rights reserved
                </p>
              </div>
              <div className="col-12 col-md-6 text-md-right text-center">
                <p className="m-0">
                  Designed and developed by{' '}
                  <a href="https://aimit.company/" rel="norefferer noopener noreferrer" target="_blank">
                    AimIT software company
                  </a>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default WelcomPage;
