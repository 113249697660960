import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getCities } from './actions';

const getCitiesInitialState = {
  error: false,
  loading: false,
  data: [],
  isArrayEmpty: false,
};
const getCitiesAction = handleActions(
  {
    [getCities]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),

    [getCities.success]: (state, { payload }) => {
      if (payload.length) {
        return {
          ...state,
          loading: false,
          error: false,
          isArrayEmpty: false,
          data: [...state.data, ...payload],
        };
      }
      return {
        ...state,
        loading: false,
        error: false,
        isArrayEmpty: true,
        data: [...state.data, ...payload],
      };
    },
    [getCities.error]: (state) => ({ ...state, loading: false, error: true }),
    [getCities.reset]: (state) => {
      return {
        ...state,
        loading: false,
        error: false,
        isArrayEmpty: false,
        data: [],
      };
    },
  },
  getCitiesInitialState
);

export default combineReducers({ getCitiesAction });
