import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectProducts } from '../../store/products/selectors';
import { getProducts, removeProduct, setLimit } from '../../store/products/actions';
import { openModal } from '../../store/modals/actions';
import { modalNames } from '../../store/modals/constants';
import { getAllBoards, getProductsCount } from '../../store/lists/actions';
import { selectAllGetBoardNames, selectGetProductCounts } from '../../store/lists/selectors';
import { selectLang, selectUserRole } from '../../store/user/selectors';
import { Lang } from '../../T';

export const useProducts = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const [searchValues, setSearchValues] = useState({});
  const [boardId, setBoardId] = useState('all');
  const [copiedTextId, setCopiedTextId] = useState('');
  const [search, setSearch] = useState('');
  const { data, loading, pagesCount, page, limit } = useSelector(selectProducts);
  const role = useSelector(selectUserRole);

  const { data: boards } = useSelector(selectAllGetBoardNames);
  const productCounts = useSelector(selectGetProductCounts);

  const boardData = [{ label: T.all, value: 'all' }, ...boards];
  const [filterMod, setFilterMod] = useState({
    mod: 'imgDec',
    value: 'count',
  });

  useEffect(() => {
    dispatch(getAllBoards());
    handleGetProducts();
    dispatch(getProductsCount());
  }, []);

  const filteredProducts = useMemo(() => {
    if (data.rows) {
      // eslint-disable-next-line no-unused-expressions
      data?.rows.sort((a, b) => {
        if (filterMod.mod === 'imgDec') {
          return b[filterMod.value] - a[filterMod.value];
        }
        return a[filterMod.value] - b[filterMod.value];
      });
    }
    return data;
  }, [data.rows, filterMod]);

  const dispatch = useDispatch();

  const createProduct = useCallback(() => {
    dispatch(openModal({ name: modalNames.createEditProduct }));
  }, []);

  const editProduct = useCallback((id) => {
    dispatch(openModal({ name: modalNames.createEditProduct, data: { id } }));
  }, []);

  const handleDelete = useCallback((id) => {
    dispatch(removeProduct({ id, T }));
  }, []);

  const handleCopyProductName = (name, id) => {
    setCopiedTextId(id);
    setTimeout(() => setCopiedTextId(''), 1000);
    navigator.clipboard.writeText(name);
  };

  const deleteProduct = useCallback(
    (id) => {
      dispatch(
        openModal({
          name: modalNames.confirmation,
          data: {
            id,
            title: T.delete,
            description: T.are_you_sure_delete_product,
            onOk: () => handleDelete(id),
          },
        })
      );
    },
    [handleDelete]
  );

  const handleGetProducts = useCallback(
    (page = 1) => {
      setSearchValues((prev) => {
        // todo marketing
        dispatch(getProducts({ T, page, status: true, marketing: true, ...prev }));

        return prev;
      });
    },
    [page, searchValues]
  );

  const changeLimit = useCallback(
    ({ value }) => {
      dispatch(setLimit({ value, boardId }));
    },
    [boardId]
  );

  const handleSearchProduct = useCallback(
    (searchWord) => {
      if (searchWord === '') {
        setSearch('');
        const newValues = { ...searchValues };
        delete newValues.searchWord;
        setSearchValues(newValues);
      } else {
        setSearch(searchWord);
        setSearchValues((prev) => {
          return { ...prev, searchWord };
        });
      }

      handleGetProducts();
    },
    [handleGetProducts]
  );

  useEffect(() => {
    handleSearchProduct(search);
  }, [search]);

  const filterByStatuses = useCallback(
    (searchInfo) => {
      if (searchInfo.value === 'all') {
        const newValues = { ...searchValues };
        delete newValues.type;
        setSearchValues(newValues);
      } else {
        setSearchValues((prev) => {
          return { ...prev, type: searchInfo.value };
        });
      }
      handleGetProducts();
    },
    [handleGetProducts]
  );

  const filterByBoard = useCallback(
    (searchInfo) => {
      if (searchInfo.value === 'all') {
        const newValues = { ...searchValues };
        delete newValues.boardId;
        setBoardId('all');
        setSearchValues(newValues);
      } else {
        setSearchValues((prev) => {
          setBoardId(searchInfo.value);
          return { ...prev, boardId: searchInfo.value };
        });
      }

      handleGetProducts();
    },
    [handleGetProducts, boardId]
  );

  return {
    filteredProducts,
    loading,
    handleGetProducts,
    pagesCount,
    createProduct,
    handleSearchProduct,
    filterByBoard,
    filterByStatuses,
    editProduct,
    page,
    deleteProduct,
    changeLimit,
    limit,
    setFilterMod,
    boardData,
    productCounts,
    copiedTextId,
    handleCopyProductName,
    role,
    setSearchValues,
    setSearch,
  };
};
