import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLang } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';
import { selectWorkers } from '../../../../store/workers/selectors';
import { getWorkers } from '../../../../store/workers/actions';

const useFilters = ({ filter, setFilter, initialFilter, periodOptions, changePage }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const config = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const { data: users } = useSelector(selectWorkers);
  const dispatch = useDispatch();

  const isResetDisabled = useMemo(() => {
    const { period, startDate, endDate, userId } = filter;
    return !(
      period.value !== periodOptions[2].value ||
      startDate !== null ||
      endDate !== null ||
      (userId?.value !== undefined && userId !== null)
    );
  }, [filter, T]);

  const handleReset = () => {
    setFilter(initialFilter);
    changePage(1);
  };

  const handlePeriodChange = (e) => {
    changePage(1);
    setFilter((prev) => {
      if (e.length === 2) {
        const label = `${e[0].toLocaleString('ru', config)} - ${e[1].toLocaleString('ru', config)}`;

        return { ...prev, startDate: e[0], endDate: e[1], period: { value: 'custom', label } };
      }

      return { ...prev, period: e, startDate: null, endDate: null };
    });
  };

  const handleUserChange = useCallback(
    (e) => {
      changePage(1);
      setFilter((prev) => ({ ...prev, userId: e }));
    },
    [setFilter]
  );

  const userOptions = useMemo(() => {
    return (
      users?.map(({ firstName, lastName, id }) => ({
        label: `${firstName} ${lastName}`,
        value: id,
      })) || []
    );
  }, [users]);

  useEffect(() => {
    dispatch(getWorkers({}));
  }, []);

  return { isResetDisabled, handleReset, handlePeriodChange, userOptions, handleUserChange };
};

export default useFilters;
