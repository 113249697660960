import React from 'react';
import classNames from './table.module.scss';
import HorizontalTabs from '../../../../../../components/horizontal-tabs/HorizontalTabs';
import { useTable } from './useTable';

const Table = () => {
  const { tabs } = useTable();
  return (
    <div className={classNames.tableContainer}>
      <div className={classNames.tableData}>
        <div className={classNames.dataHeader}>
          <div className={classNames.headerLeft}>
            <div className={classNames.dataItem}>
              <p>Real Running</p>
              <p>37</p>
            </div>
            <div className={classNames.dataItem}>
              <p>Test Running</p>
              <p>3</p>
            </div>
            <div className={classNames.dataItem}>
              <p>Must Run</p>
              <p>120</p>
            </div>
            <div className={classNames.lockedContent}>
              <div className={classNames.dataItem}>
                <p>User Creo</p>
                <p>37</p>
              </div>
              <div className={classNames.dataItem}>
                <p>Not User</p>
                <p>3</p>
              </div>
            </div>
            <div className={classNames.dataItem}>
              <p>User ads text</p>
              <p>37</p>
            </div>
            <div className={classNames.dataItem}>
              <p>Not used</p>
              <p>3</p>
            </div>
          </div>
          <div className={classNames.headerRight}>
            <div className={classNames.rightInfo}>
              <p>Result</p> <span>300</span> / <p>CPL</p> <span>3 $</span> / <p>Amount</p> <span>900 $</span>
            </div>
          </div>
        </div>
        <HorizontalTabs className="tableTabs" tabs={tabs} />
      </div>
    </div>
  );
};

export default Table;
