import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getRegions } from './actions';

const getRegionsInitialState = {
  error: false,
  loading: false,
  data: [],
};
const get = handleActions(
  {
    [getRegions]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),

    [getRegions.success]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      data: payload,
    }),
    [getRegions.error]: (state) => ({ ...state, loading: false, error: true }),
  },
  getRegionsInitialState
);

export default combineReducers({ get });
