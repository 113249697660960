import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLang, selectUserRole } from '../../store/user/selectors';
import { getRequestCounts } from '../../store/warehouse/action';
import { selectReviewData, selectWarehouseCounts } from '../../store/warehouse/selector';
import cn from './store.module.scss';
import DefectTab from './tabs/defect-tab/DefectTab';
import ProductCheckTab from './tabs/product-check-tab/ProductCheckTab';
import ReturnTab from './tabs/return-tab/ReturnTab';
import StoreTab from './tabs/store-tab/StoreTab';
import { Lang } from '../../T';

const Store = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const dispatch = useDispatch();
  const role = useSelector(selectUserRole);
  const hasPermission = useMemo(() => {
    return role === 'admin' || role === 'director';
  }, [role]);
  const counts = useSelector(selectWarehouseCounts);
  const { success } = useSelector(selectReviewData);
  const [tabState, setTabState] = useState('store');
  const onTabClick = useCallback(
    ({ target }) => {
      const { dataset } = target;
      const { state } = dataset;
      setTabState(state);
    },
    [setTabState]
  );
  useEffect(() => {
    if (hasPermission || success) {
      dispatch(getRequestCounts({ T }));
    }
  }, [tabState, success]);
  return (
    <div className="container maxw-100">
      <div className={cn.mainContainer}>
        <div className={cn.tabs}>
          <div data-state="store" onClick={onTabClick} className={`${cn.tab} ${tabState === 'store' ? cn.active : ''}`}>
            {T.warehouse}
          </div>
          {hasPermission && (
            <div
              data-state="product"
              onClick={onTabClick}
              className={`${cn.tab} ${tabState === 'product' ? cn.active : ''}`}
            >
              {T.product_request} ({counts.product})
            </div>
          )}
          {hasPermission && (
            <div
              data-state="defect"
              onClick={onTabClick}
              className={`${cn.tab} ${tabState === 'defect' ? cn.active : ''}`}
            >
              {T.defect_request} ({counts.defect})
            </div>
          )}
          {hasPermission && (
            <div
              data-state="return"
              onClick={onTabClick}
              className={`${cn.tab} ${tabState === 'return' ? cn.active : ''}`}
            >
              {T.return_request} ({counts.export})
            </div>
          )}
        </div>
        <div>
          {tabState === 'store' && <StoreTab hasPermission={hasPermission} />}
          {tabState === 'product' && hasPermission && <ProductCheckTab />}
          {tabState === 'defect' && hasPermission && <DefectTab />}
          {tabState === 'return' && hasPermission && <ReturnTab />}
        </div>
      </div>
    </div>
  );
};

export default Store;
