import React from 'react';
import { useSelector } from 'react-redux';
import classNames from './productBuying.module.scss';
import Table from './components/table/Table';
import Filters from './components/filters/Filter';

import useProductBuying from './useProductBuying';
import { selectLang } from '../../store/user/selectors';
import { Lang } from '../../T';

const ProductBuying = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const { filter, setFilter, total, page, limit, changePage, changeLimit, pagesCount, orderData, orderedData } =
    useProductBuying();
  return (
    <div className={classNames.pageContainer}>
      <div className={classNames.ProductBuyingHeader}>
        <div className="container maxw-100">
          <div className={classNames.statesHeader}>
            <h2 className="title">{T.productBuying}</h2>
          </div>
        </div>
      </div>
      <div className="container maxw-100">
        <Filters filter={filter} setFilter={setFilter} data={orderedData} />
        <Table
          filter={filter}
          data={orderedData}
          total={total}
          page={page}
          limit={limit}
          changePage={changePage}
          changeLimit={changeLimit}
          pagesCount={pagesCount}
          orderData={orderData}
        />
      </div>
    </div>
  );
};

export default ProductBuying;
