import { put } from 'redux-saga/effects';
import { openModal } from './modals/actions';
import { modalNames } from './modals/constants';

export function* notifyMessage(data = {}, T) {
  const messages = {
    'Product weight must be specified': T.product_dont_have_weight,
    'Given value is greater than unchecked products count for this input': T.too_much_price,
    '"value" must be a number': T.price_should_be_number,
  };
  if (messages[data.body]) {
    data.body = messages[data.body];
  } else if (messages[data.body?.split(':')[0]]) {
    data.body = messages[data.body?.split(':')[0]];
  }
  yield put(openModal({ name: modalNames.alert, data }));
}
