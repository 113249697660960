import React from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import Pagination from '../../components/pagination/Pagination';
import CalendarSvg from '../../svgs/CalendarSvg';
import { limitOptions } from '../states/constants';
import DeleteIconSvg from '../../svgs/DeleteIconSvg';

import classNames from './customers.module.scss';
import useCustomers from './useCustomers';
import { handleDate } from './helpers';
import CustomerMore from './components/CustomerMore';
import { handleEmoji } from '../../helpers/handleEmoji';
import SearchSvg from '../../svgs/SearchSvg';
import FilterMobileSvg from '../../svgs/FilterMobileSvg';
import DownloadSvg from '../../svgs/DownloadSvg';
import SmallCopySvg from '../../svgs/SmallCopySvg';
import { Lang } from '../../T';
import CloseSvg from '../../svgs/CloseSvg';
import FilterClearSvg from '../../svgs/FilterClearSvg';
import { selectLang } from '../../store/user/selectors';
import { formatNumberWithThousandsSeparator } from '../../helpers/formatNubmer';

const Customers = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const {
    filterMenu,
    filterParams,
    startDate,
    endDate,
    checkboxIds,
    customers,
    count,
    limit,
    pagesCount,
    page,
    showMore,
    id,
    orders,
    addresses,
    showRatesDropDown,
    role,
    rates,
    orderOffset,
    handleResetSearch,
    handleChange,
    setPage,
    handleCheckboxId,
    setDateRange,
    toggleFilterMenu,
    handleFilterCustomers,
    resetFilters,
    handleAllCheckboxIds,
    changeLimit,
    handleMoreCustomerData,
    handleDeleteCustomerByPhone,
    getMoreOrders,
    loadMoreRates,
    handlePrintBuyers,
    handleCopyCustomerName,
    copiedTextId,
    outSideRef,
    isDirty,
    allCheckboxIds,
    deletedItems,
    genders,
    types,
    states,
  } = useCustomers();

  return (
    <div className="container maxw-100">
      <div className={classNames.customersSection}>
        <div className={classNames.customersHeader}>
          <h2>{T.customer}</h2>
        </div>

        <div className={classNames.customersFilterBlock}>
          <div className={classNames.filterSelectors}>
            <div className={classNames.filterSelectorsLeft}>
              <button type="button" className={classNames.toggleFilterMenu} onClick={toggleFilterMenu}>
                <span>
                  <FilterMobileSvg />
                </span>
                {T.filter}
              </button>
              <div className={classNames.searchInputBlock}>
                <div className={classNames.searchInputWrapper}>
                  <input
                    type="text"
                    value={filterParams.searchWord}
                    placeholder={T.search}
                    onChange={(event) => {
                      handleFilterCustomers(event.target.value, 'searchWord');
                      handleChange(event);
                    }}
                  />
                  <div className={classNames.iconWrapper}>
                    {filterParams?.searchWord?.length ? (
                      <span className={classNames.resetIcon} onClick={handleResetSearch}>
                        <CloseSvg />
                      </span>
                    ) : (
                      <span>
                        <SearchSvg />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames.filterSelectorsRight}>
              <span>
                {T.search_result}: {count}
              </span>
              {(role === 'admin' || role === 'director') && (
                <button
                  type="button"
                  onClick={handlePrintBuyers}
                  disabled={!(allCheckboxIds === 'all' || allCheckboxIds === 'partial' || checkboxIds.length !== 0)}
                >
                  <DownloadSvg />
                </button>
              )}
            </div>
          </div>
        </div>
        {filterMenu && (
          <div className={classNames.filterOptions}>
            <div className={classNames.rowItem}>
              <div className={classNames.field}>
                <p>{T.date}*</p>
                <div className={classNames.datePickerParent}>
                  <CalendarSvg />
                  <DatePicker
                    selectsRange
                    autoComplete="off"
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText={T.date}
                    wrapperClassName={classNames.datePicker}
                    onChange={(data) => {
                      setDateRange(data);
                    }}
                    isClearable
                  />
                </div>
              </div>
            </div>
            <div className={classNames.rowItem}>
              <div className={classNames.field}>
                <p>{T.gender}</p>
                <Select
                  options={genders}
                  placeholder={T.select}
                  value={filterParams.sex}
                  className={classNames.reportSelects}
                  classNamePrefix="select"
                  onChange={(e) => {
                    handleFilterCustomers(e, 'sex');
                  }}
                  disable
                />
              </div>
            </div>
            <div className={classNames.rowItem}>
              <div className={classNames.field}>
                <p>{T.just_status}</p>
                <Select
                  options={states}
                  placeholder={T.select}
                  hideSelectedOptions={false}
                  className={classNames.reportSelects}
                  classNamePrefix="select"
                  onChange={(e) => handleFilterCustomers(e, 'state')}
                  value={filterParams.state}
                />
              </div>
            </div>
            <div className={classNames.rowItem}>
              <div className={classNames.field}>
                <p>{T.type}</p>
                <Select
                  options={types}
                  placeholder={T.select}
                  hideSelectedOptions={false}
                  className={classNames.reportSelects}
                  classNamePrefix="select"
                  onChange={(e) => handleFilterCustomers(e, 'status')}
                  value={filterParams.status}
                />
              </div>
            </div>
            <div className={`${classNames.rowItem} ${classNames.inputsWrapper}`}>
              <div className={classNames.field}>
                <p>{T.order_count}</p>
                <div className={classNames.ordersCount}>
                  <input
                    type="number"
                    onChange={({ target: { value } }) => {
                      handleFilterCustomers(Number(value), 'minOrderCount');
                    }}
                    value={filterParams.minOrderCount || ''}
                  />
                  <span>-</span>
                  <input
                    type="number"
                    onChange={({ target: { value } }) => {
                      handleFilterCustomers(Number(value), 'maxOrderCount');
                    }}
                    value={filterParams.maxOrderCount || ''}
                  />
                </div>
              </div>
            </div>
            <div className={`${classNames.rowItem} ${classNames.inputsWrapper}`}>
              <div className={classNames.field}>
                <p>{T.price}</p>
                <div className={classNames.ordersCount}>
                  <input
                    type="text"
                    onChange={({ target: { value } }) => {
                      handleFilterCustomers(value, 'minSpent');
                    }}
                    value={filterParams.minSpent || ''}
                  />
                  <span>-</span>
                  <input
                    type="text"
                    onChange={({ target: { value } }) => {
                      handleFilterCustomers(value, 'maxSpent');
                    }}
                    value={filterParams.maxSpent || ''}
                  />
                </div>
              </div>
            </div>
            {/* todo unnecessary filter button */}

            <div className={classNames.rowItem}>
              <div className={classNames.searchButton}>
                <button disabled={!isDirty} type="button" onClick={resetFilters}>
                  <span>
                    <FilterClearSvg />
                  </span>
                  {T.reset_filter}
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="container ml-0 maxw-100 p-0">
          <div className={classNames.ProductsTable}>
            <div className={classNames.productsTableHeader}>
              <div className={classNames.producstTableRoW}>
                <div className={`${classNames.productCol} ${classNames.customerNameImage}`}>
                  <div
                    className={`${classNames.nameCheckbox} ${
                      allCheckboxIds === 'partial' || checkboxIds.length ? classNames.partialChecked : ''
                    }`}
                  >
                    <label>
                      <input
                        type="checkbox"
                        id="selectAll"
                        onChange={handleAllCheckboxIds}
                        checked={allCheckboxIds === 'all'}
                      />
                      <span />
                    </label>
                    <p>{T.name}</p>
                  </div>
                </div>
                <div className={`${classNames.productCol} ${classNames.customerSex}`}>
                  <p>{T.gender}</p>
                </div>
                <div className={`${classNames.productCol} ${classNames.customerStatus}`}>
                  <p>{T.just_status}</p>
                </div>
                <div className={`${classNames.productCol} ${classNames.customerRate}`}>
                  <p>{T.rate}</p>
                </div>
                <div className={`${classNames.productCol} ${classNames.customerDate}`}>
                  <p>{T.last_order}</p>
                </div>
                <div className={`${classNames.productCol} ${classNames.customerCount}`}>
                  <p>{T.order_count}</p>
                </div>
                <div className={`${classNames.productCol} ${classNames.customerSpend}`}>
                  <p>{T.spend_money}</p>
                </div>
                <div className={`${classNames.productCol} ${classNames.customerMore}`}>
                  <p>{T.more}</p>
                </div>
                <div className={`${classNames.productCol} ${classNames.customerDelete}`}>
                  <p className="justify-content-center" />
                </div>
              </div>
            </div>
            <div className={classNames.productsTableBody}>
              {customers?.map(
                ({ name, phone1, rateCount, sex, orderCount, totalSpent, status, rate, lastOrderDate }) => (
                  <div className={classNames.productsTableItem} key={phone1}>
                    <div className={classNames.producstTableRoW}>
                      <div className={`${classNames.productCol} ${classNames.customerNameImage}`}>
                        <div className={`${classNames.nameCheckbox}`}>
                          <label>
                            <input
                              key={phone1}
                              id={phone1}
                              name="checkbox"
                              type="checkbox"
                              checked={
                                checkboxIds.includes(phone1.toString()) ||
                                ((allCheckboxIds === 'all' || allCheckboxIds === 'partial') &&
                                  !deletedItems.includes(phone1.toString()))
                              }
                              onChange={handleCheckboxId}
                            />
                            <span />
                          </label>
                          {!name ? (
                            <div className={classNames.empty} />
                          ) : (
                            <div className={classNames.textItem}>
                              <p className={copiedTextId === phone1 ? classNames.copied : ''}>
                                {name}
                                <button onClick={() => handleCopyCustomerName(name, phone1)} type="button">
                                  <SmallCopySvg />
                                </button>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={`${classNames.productCol} ${classNames.customerSex}`}>
                        <div>{!sex ? <div className={classNames.empty} /> : <p>{sex}</p>}</div>
                      </div>
                      <div className={`${classNames.productCol} ${classNames.customerStatus}`}>
                        {!status ? (
                          <div className={classNames.empty} />
                        ) : (
                          <div
                            className={
                              status === 'gold'
                                ? classNames.statusRateGold
                                : status === 'bronze'
                                ? classNames.statusRateBronze
                                : classNames.statusRateSilver
                            }
                          >
                            <p>{status}</p>
                          </div>
                        )}
                      </div>
                      <div className={`${classNames.productCol} ${classNames.customerRate}`}>
                        <div className={classNames.productRate}>
                          {!rate ? <div className={classNames.empty} /> : handleEmoji(rate)}
                          <div ref={outSideRef} className={classNames.ratesCount} onClick={() => loadMoreRates(phone1)}>
                            {rateCount}
                          </div>
                          {showRatesDropDown && rates?.length && id === phone1 ? (
                            <div className={classNames.tableRateDropdown}>
                              {rates.map(({ userId, user, rate }) => {
                                return (
                                  <span key={userId}>
                                    <span>
                                      {user.firstName} {user.lastName}
                                    </span>
                                    {handleEmoji(rate)}
                                  </span>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className={`${classNames.productCol} ${classNames.customerDate}`}>
                        <div>
                          <p>{handleDate(lastOrderDate)}</p>
                        </div>
                      </div>
                      <div className={`${classNames.productCol} ${classNames.customerCount}`}>
                        <div>
                          <p>{orderCount}</p>
                        </div>
                      </div>
                      <div className={`${classNames.productCol} ${classNames.customerSpend}`}>
                        <div>
                          <p>{formatNumberWithThousandsSeparator(totalSpent, ' ')}</p>
                        </div>
                      </div>
                      <div className={`${classNames.productCol} ${classNames.customerMore}`}>
                        <div className={classNames.swichItem} onClick={() => handleMoreCustomerData(phone1)}>
                          <button type="button">{T.more}</button>
                        </div>
                      </div>
                      <div className={`${classNames.productCol} ${classNames.customerDelete}`}>
                        {role !== 'viewer' && (
                          <div className={classNames.productsActions}>
                            <button onClick={() => handleDeleteCustomerByPhone(phone1)} type="button">
                              <DeleteIconSvg />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    {showMore && id === phone1 && (
                      <div className={classNames.moreContainer}>
                        <div className={classNames.moreTableHeader}>
                          <div className={classNames.moreTableHeaderLeft}>
                            <p>{T.data}։</p>
                          </div>
                          <div className={classNames.moreTableHeaderRight}>
                            <div className={classNames.numberTable}>
                              <p>{phone1}</p>
                            </div>
                            <div className={classNames.addressTable}>
                              <p>{addresses?.[0]?.length ? addresses?.[0] : 'Address'}</p>
                              {addresses?.length && addresses?.[0]?.length ? (
                                <div className={classNames.addressDropdown}>
                                  {addresses?.map((item, index) => {
                                    return <>{item?.length && index !== 0 ? <p key={item}>{item}</p> : null}</>;
                                  })}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <CustomerMore orders={orders} />
                        {orders.length >= orderOffset && (
                          <button type="button" onClick={() => getMoreOrders(phone1)} className={classNames.loadMore}>
                            {T.show_more}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        {pagesCount > 1 ? (
          <div className={classNames.allPagination}>
            <Pagination onChange={(e) => setPage(e)} totalPages={pagesCount} defaultPage={page} />
            <div className={classNames.ProductsSeries}>
              <div className={classNames.ProductsSeriesItem}>
                <p>{T.row}</p>
                <div className={classNames.ProductsSeriesItemSelect}>
                  <Select
                    placement="top"
                    menuPlacement="top"
                    options={limitOptions}
                    onChange={changeLimit}
                    placeholder=""
                    value={{ value: limit, label: limit }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Customers;
