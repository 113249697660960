import React, { useEffect, useState } from 'react';
import { object, string } from 'prop-types';
import { useSelector } from 'react-redux';
import useRow from './useRow';
import ImportSvg from '../../../../../../svgs/ImportSvg';
import ExportSvg from '../../../../../../svgs/ExportSvg';
import classNames from './row.module.scss';
import { dateOption, defaultProductPicture } from '../../../../../../constants/constants';
import SmallCopySvg from '../../../../../../svgs/SmallCopySvg';
import { Lang } from '../../../../../../T';
import { selectLang } from '../../../../../../store/user/selectors';
import CopyItem from '../../../../../../components/copy-item/CopyItem';
import { formatNumberWithThousandsSeparator } from '../../../../../../helpers/formatNubmer';

const Row = ({ item, type }) => {
  const {
    id,
    name,
    createdAt,
    barcode,
    count,
    defectCount,
    defectPercent,
    marketingCount,
    weight,
    price,
    needToCheck,
    lowestTime,
    photos: { url } = {},
  } = item || {};
  const { isAccountant, isMarketing, onImport, onDefectExport, onProductCheck, hasPermission, handleCopy, isCopied } =
    useRow();
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const [startDate, setStartDate] = useState();

  useEffect(() => {
    if (!lowestTime) {
      setStartDate('-- : -- : --');
      return;
    }
    setStartDate(() => {
      const offset = new Date(lowestTime)?.setHours(new Date(lowestTime).getHours() + 72) - new Date();
      const h = Math.floor(offset / 1000 / 60 / 60);
      const m = Math.floor((offset / 1000 / 60 / 60 - h) * 60);
      const s = Math.floor(((offset / 1000 / 60 / 60 - h) * 60 - m) * 60);
      return `${h.toString().length === 1 ? `0${h}` : h}:${m.toString().length === 1 ? `0${m}` : m}:${
        s.toString().length === 1 ? `0${s}` : s
      }`;
    });
  }, [lowestTime]);

  return (
    <tr className={classNames.trItem}>
      <td className={classNames.tdItem}>
        <CopyItem text={id} />
      </td>
      <td className={classNames.tdItem}>
        <div className={classNames.imageWrapper}>
          <div className={classNames.picturePopUpContainer}>
            <img
              width={34}
              height={34}
              src={
                url && url !== '/productPictures/null'
                  ? `${process.env.REACT_APP_BASE_ENDPOINT}${url}`
                  : defaultProductPicture
              }
              alt="img"
            />
            <div className={classNames.picturePopUp}>
              <p>
                <img
                  src={
                    url && url !== '/productPictures/null'
                      ? `${process.env.REACT_APP_BASE_ENDPOINT}${url}`
                      : defaultProductPicture
                  }
                  alt="img"
                />
              </p>
            </div>
          </div>

          <div className={classNames.textItem}>
            <p className={isCopied ? classNames.copied : ''}>
              {name}
              <button type="button" onClick={handleCopy(name)}>
                <SmallCopySvg />
              </button>
            </p>
            <div className={classNames.popupName}>
              <div onClick={handleCopy(name)}>
                <SmallCopySvg />
              </div>
              <div className={isCopied ? classNames.copied : undefined}>{name}</div>
            </div>
          </div>
        </div>
      </td>
      {!isAccountant && (
        <td className={classNames.tdItem}>
          <p>{new Date(createdAt).toLocaleString('ru', dateOption)}</p>
        </td>
      )}
      <td className={classNames.tdItem}>
        <CopyItem text={barcode} />
      </td>
      {!isAccountant && !type && (
        <td className={classNames.tdItem}>
          <p>{barcode ? T.real : T.test}</p>
        </td>
      )}
      <td className={classNames.tdItem}>
        <p>{count || '0'}</p>
      </td>
      {!isMarketing && (
        <td className={classNames.tdItem}>
          <p>{defectCount || '0'}</p>
        </td>
      )}
      {!isAccountant && !isMarketing && (
        <td className={classNames.tdItem}>
          <p>{`${defectPercent?.toFixed(2)}%` || '0'}</p>
        </td>
      )}
      {(hasPermission || isMarketing) && (
        <td className={classNames.tdItem}>
          <p style={{ color: marketingCount?.count < 0 ? 'red' : '' }}>{marketingCount?.count || '0'}</p>
        </td>
      )}
      {!isAccountant && !isMarketing && (
        <td className={classNames.tdItem}>
          <p>{weight || '0'}</p>
        </td>
      )}
      {!isAccountant && (
        <td className={classNames.tdItem}>
          <p>{price ? formatNumberWithThousandsSeparator(price, ' ') : '0'}</p>
        </td>
      )}
      {!isMarketing && (
        <td
          className={`${classNames.tdItem} ${
            !isAccountant && !isMarketing ? classNames.noaccountant : classNames.accountant
          }`}
        >
          <button title={T.import} className={classNames.importBtn} type="button" onClick={() => onImport(item)}>
            <ImportSvg />
          </button>
        </td>
      )}
      {!isAccountant && !isMarketing && (
        <>
          <td className={`${classNames.tdItem} ${!isAccountant && !isMarketing ? classNames.noaccountant : ''}`}>
            <button
              title={T.export}
              className={classNames.exportBtn}
              type="button"
              onClick={() => onDefectExport(item)}
            >
              <ExportSvg />
            </button>
          </td>
          <td className={`${classNames.tdItem} ${!isAccountant && !isMarketing ? classNames.noaccountant : ''}`}>
            <button
              style={{
                color: startDate?.[0] === '-' && startDate?.[1] !== '-' ? 'red' : '',
              }}
              title={T.check}
              className={classNames.checkBtn}
              type="button"
              onClick={() => onProductCheck(item)}
            >
              {` ${needToCheck || 0} | ${startDate}`}
            </button>
          </td>
        </>
      )}
    </tr>
  );
};

Row.propTypes = {
  item: object,
  cn: object,
  type: string,
};

export default Row;
