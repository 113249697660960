import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProductStatsRequest } from './helpers';
import { getEditProduct } from '../../store/products/actions';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';

const useProductsBuyingStats = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const [data, setData] = useState({});
  const { search } = useLocation();

  const getProductStats = async (productId) => {
    const searchParam = new URLSearchParams(search);

    const data = await getProductStatsRequest(productId, {
      period: searchParam.get('period'),
      startDate: searchParam.get('startDate') || undefined,
      endDate: searchParam.get('endDate') || undefined,
      urls: JSON.parse(searchParam.get('urls')),
    });
    setData(data);
  };

  useEffect(() => {
    getProductStats(productId);
    dispatch(getEditProduct({ id: productId, T }));
  }, []);

  return { data };
};

export default useProductsBuyingStats;
