import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { useSelector } from 'react-redux';
import { useRecoverPassword } from './useRecoverPassword';
import { validationSchema } from './validationSchema';
import { initialValues } from './constants';
import Button from '../../components/button/Button';
import classNames from './recoverPassword.module.scss';
import LogoSvg from '../../svgs/LogoSvg';
import CloseEyeSvg from '../../svgs/CloseEyeSvg';
import EyeSvg from '../../svgs/EyeSvg';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';

const RecoverPassword = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { handleSubmit, loading, eyeVisible, handleEyeVisible } = useRecoverPassword();

  return (
    <div className={`${classNames.loginPage} d-flex justify-content-center align-items-center h-100 w-100`}>
      <div className={classNames.AuthenticationContent}>
        <h1>
          <LogoSvg />
        </h1>
        <Formik initialValues={initialValues} validationSchema={validationSchema(T)} onSubmit={handleSubmit}>
          <Form className="w-100">
            <div className={classNames.InputParent}>
              <div className={classNames.inputPassword}>
                <Field
                  className="w-100"
                  type={!eyeVisible.password ? 'password' : 'text'}
                  name="password"
                  placeholder={`${T.enter_new_password}*`}
                  autocomplete="off"
                />
                <Button onClick={() => handleEyeVisible('password')}>
                  {!eyeVisible.password ? <CloseEyeSvg /> : <EyeSvg />}
                </Button>
              </div>
              <ErrorMessage className="text-red" name="password" component="div" />
            </div>
            <div className={classNames.InputParent}>
              <div className={classNames.inputPassword}>
                <Field
                  className="w-100"
                  type={!eyeVisible.retryPassword ? 'password' : 'text'}
                  name="retryPassword"
                  placeholder={`${T.repeat_password}*`}
                  autocomplete="off"
                />
                <Button onClick={() => handleEyeVisible('retryPassword')}>
                  {!eyeVisible.retryPassword ? <CloseEyeSvg /> : <EyeSvg />}
                </Button>
              </div>
              <ErrorMessage className="text-red" name="retryPassword" component="div" />
            </div>
            <div className={classNames.AuthenticationBtn}>
              <Button type="submit" className="btn btn-success" loading={loading}>
                {T.approve}
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default RecoverPassword;
