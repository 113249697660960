import React from 'react';
import classNames from './crmTable.module.scss';
import FilterArrows from '../../../../../../../svgs/FilterArrows';

const CrmTable = () => {
  return (
    <div className={classNames.tableWrapper}>
      <table>
        <colgroup>
          <col style={{ width: 70 }} />
          <col style={{ width: 100 }} />
          <col style={{ width: 200 }} />
          <col style={{ width: 220 }} />
          <col style={{ width: 100 }} />
          <col style={{ width: 100 }} />
          <col style={{ width: 140 }} />
          <col style={{ width: 100 }} />
          <col style={{ width: 100 }} />
          <col style={{ width: 100 }} />
          <col style={{ width: 80 }} />
          <col style={{ width: 80 }} />
          <col style={{ width: 80 }} />
          <col style={{ width: 91 }} />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">
              <p>
                <input type="checkbox" />N
              </p>
            </th>
            <th scope="col">
              <p>Status </p>
            </th>
            <th scope="col">
              <p>CRM product </p>
            </th>
            <th scope="col">
              <p>Date and time</p>
            </th>
            <th scope="col">
              <p>Product type</p>
            </th>
            <th scope="col">
              <p>Target type</p>
            </th>
            <th scope="col">
              <p>
                Fb / Web result
                <span>
                  <FilterArrows />
                </span>
              </p>
            </th>
            <th scope="col">
              <p>
                CPL
                <span>
                  <FilterArrows />
                </span>
              </p>
            </th>
            <th scope="col">
              <p>
                Spent
                <span>
                  <FilterArrows />
                </span>
              </p>
            </th>
            <th scope="col">
              <p>Budget</p>
            </th>
            <th scope="col">
              <p>Domain</p>
            </th>
            <th scope="col">
              <p>Fun page</p>
            </th>
            <th scope="col">
              <p>Ads account</p>
            </th>
            <th scope="col">
              <p>Targets</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>
                <input type="checkbox" />1
              </p>
            </td>
            <td>
              <p>
                <div>
                  <input type="checkbox" />
                </div>
              </p>
            </td>
            <td>
              <p>
                <img src="" alt="" />
                Product name
              </p>
            </td>
            <td>
              <p>09.07.2023, 12:30</p>
            </td>
            <td>
              <p>Real</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>120 / 121</p>
            </td>
            <td>
              <p>12 $ / 11$</p>
            </td>
            <td>
              <p>39 $</p>
            </td>
            <td>
              <p>20 $</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>6</p>
            </td>
            <td>
              <p>4</p>
            </td>
            <td>
              <p>4</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <input type="checkbox" />1
              </p>
            </td>
            <td>
              <p>
                <div>
                  <input type="checkbox" />
                </div>
              </p>
            </td>
            <td>
              <p>
                <img src="" alt="" />
                Product name
              </p>
            </td>
            <td>
              <p>09.07.2023, 12:30</p>
            </td>
            <td>
              <p>Real</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>120 / 121</p>
            </td>
            <td>
              <p>12 $ / 11$</p>
            </td>
            <td>
              <p>39 $</p>
            </td>
            <td>
              <p>20 $</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>6</p>
            </td>
            <td>
              <p>4</p>
            </td>
            <td>
              <p>4</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <input type="checkbox" />1
              </p>
            </td>
            <td>
              <p>
                <div>
                  <input type="checkbox" />
                </div>
              </p>
            </td>
            <td>
              <p>
                <img src="" alt="" />
                Product name
              </p>
            </td>
            <td>
              <p>09.07.2023, 12:30</p>
            </td>
            <td>
              <p>Real</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>120 / 121</p>
            </td>
            <td>
              <p>12 $ / 11$</p>
            </td>
            <td>
              <p>39 $</p>
            </td>
            <td>
              <p>20 $</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>6</p>
            </td>
            <td>
              <p>4</p>
            </td>
            <td>
              <p>4</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <input type="checkbox" />1
              </p>
            </td>
            <td>
              <p>
                <div>
                  <input type="checkbox" />
                </div>
              </p>
            </td>
            <td>
              <p>
                <img src="" alt="" />
                Product name
              </p>
            </td>
            <td>
              <p>09.07.2023, 12:30</p>
            </td>
            <td>
              <p>Real</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>120 / 121</p>
            </td>
            <td>
              <p>12 $ / 11$</p>
            </td>
            <td>
              <p>39 $</p>
            </td>
            <td>
              <p>20 $</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>6</p>
            </td>
            <td>
              <p>4</p>
            </td>
            <td>
              <p>4</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <input type="checkbox" />1
              </p>
            </td>
            <td>
              <p>
                <div>
                  <input type="checkbox" />
                </div>
              </p>
            </td>
            <td>
              <p>
                <img src="" alt="" />
                Product name
              </p>
            </td>
            <td>
              <p>09.07.2023, 12:30</p>
            </td>
            <td>
              <p>Real</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>120 / 121</p>
            </td>
            <td>
              <p>12 $ / 11$</p>
            </td>
            <td>
              <p>39 $</p>
            </td>
            <td>
              <p>20 $</p>
            </td>
            <td>
              <p>3</p>
            </td>
            <td>
              <p>6</p>
            </td>
            <td>
              <p>4</p>
            </td>
            <td>
              <p>4</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CrmTable;
