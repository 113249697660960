import Axios from 'axios';

import { localStorageNames } from '../localStorageNames';
import { logout } from '../store/user/actions';

const getStorageData = (name) => {
  if (typeof window === 'undefined') return '';
  return localStorage.getItem(name) || '';
};

export const createAxios = ({ store: headers = {}, changeApi = false, baseURL }) => {
  const newAxios = Axios.create({
    baseURL: baseURL || (changeApi ? process.env.REACT_APP_SECOND_ENDPOINT : process.env.REACT_APP_BASE_ENDPOINT),
  });
  newAxios.defaults.headers = { ...newAxios.defaults.headers, ...headers };
  newAxios.interceptors.request.use(
    (config) => {
      const newConfig = { ...config };
      newConfig.url = newConfig.baseURL + newConfig.url;
      const token = getStorageData(localStorageNames.authentication) || newAxios.token;
      if (token) {
        newConfig.headers.Authorization = newConfig.headers.Authorization || `Bearer ${token}`;
      }
      return newConfig;
    },
    (error) => Promise.reject(error)
  );
  newAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      const {
        status,
        config: { url },
      } = error?.response || {};
      if (status === 401) {
        if (error.response.data.errorName === 'ACCESS_FORBIDDEN_ERROR') {
          const refreshToken = localStorage.getItem('refreshToken');
          Axios.request({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_ENDPOINT}/api/users/reissue-token`,
            headers: { Authorization: `Bearer ${refreshToken}` },
          })
            .then((res) => {
              localStorage.setItem(localStorageNames.authentication, res.data.result);
            })
            .catch((error) => {
              if (
                status === 401 &&
                !url.includes('login') &&
                !url.includes('logout') &&
                error?.response?.data?.errorName === 'REFRESH_FORBIDDEN_ERROR'
              ) {
                localStorage.removeItem(localStorageNames.authentication);
                localStorage.removeItem(localStorageNames.refreshToken);
                window.location.replace(`${window.location.origin}/signinmoulk`);
              }
            });
        }
      }

      return Promise.reject(error);
    }
  );
  return newAxios;
};

export default createAxios({ logout });

const headers = {
  'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
export const excelAxios = createAxios({ logout, headers, changeApi: true });
export const csvAxios = createAxios({ logout, headers, changeApi: false });
export const analAxios = createAxios({ baseURL: process.env.REACT_APP_ANALITICS_ENDPOINT, logout });
export const storeAxios = createAxios({ baseURL: process.env.REACT_APP_STORE_ENDPOINT, logout });
