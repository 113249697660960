import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool } from 'prop-types';
import usePagination from '../../../../hooks/usePagination';
import { getStorage, getWarehouseCount } from '../../../../store/warehouse/action';
import { selectWarehouseData } from '../../../../store/warehouse/selector';
import Filters from './components/filters/Filters';
import Table from './components/table/Table';
import { selectLang, selectUserRole } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';

const StoreTab = ({ hasPermission }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const dispatch = useDispatch();
  const { total } = useSelector(selectWarehouseData);
  const role = useSelector(selectUserRole);
  const [type, setType] = useState(null);
  const [boardId, setBoardId] = useState(null);
  const [selectOnlyWaiting, setSelectOnlyWaiting] = useState(null);
  const [search, setSearch] = useState('');
  const { page, setPage, limit, changePage, changeLimit, pagesCount } = usePagination(total);

  const [filterCount, setFilterCount] = useState(0);
  const [filterDefect, setFilterDefect] = useState(0);
  const [filterPrice, setFilterPrice] = useState(0);
  const [filterWeight, setFilterWeight] = useState(0);
  const [filterDate, setFilterDate] = useState(0);

  const isAccountant = useMemo(() => role === 'accountant', [role]);
  const isMarketing = useMemo(() => role === 'marketingExpert', [role]);

  const handleGetStorage = useCallback(() => {
    dispatch(
      getStorage({
        page,
        limit,
        search,
        boardId,
        selectOnlyWaiting,
        type,
        filterCount,
        filterDefect,
        filterPrice,
        filterWeight,
        filterDate,
        T,
      })
    );
  }, [
    filterCount,
    filterDefect,
    filterPrice,
    filterWeight,
    filterDate,
    page,
    limit,
    search,
    boardId,
    selectOnlyWaiting,
    type,
  ]);

  useEffect(() => {
    handleGetStorage();
  }, [
    page,
    limit,
    search,
    boardId,
    selectOnlyWaiting,
    type,
    filterCount,
    filterDefect,
    filterPrice,
    filterWeight,
    filterDate,
  ]);

  useEffect(() => {
    if (!isAccountant && !isMarketing) {
      dispatch(getWarehouseCount({ search, boardId, selectOnlyWaiting, type, T }));
    }
  }, [search, boardId, selectOnlyWaiting, type]);
  return (
    <div className="container maxw-100">
      <Filters
        setPage={setPage}
        setBoardId={setBoardId}
        setSearch={setSearch}
        setSelectOnlyWaiting={setSelectOnlyWaiting}
        selectOnlyWaiting={selectOnlyWaiting}
        setType={setType}
        hasPermission={hasPermission}
      />

      <Table
        setFilterCount={setFilterCount}
        filterCount={filterCount}
        setFilterDefect={setFilterDefect}
        filterDefect={filterDefect}
        filterPrice={filterPrice}
        setFilterPrice={setFilterPrice}
        filterWeight={filterWeight}
        setFilterWeight={setFilterWeight}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        page={page}
        limit={limit}
        type={type}
        changePage={changePage}
        changeLimit={changeLimit}
        pagesCount={pagesCount}
      />
    </div>
  );
};
StoreTab.propTypes = {
  hasPermission: bool,
};
export default StoreTab;
