import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Switch from '../../../../components/switch/Switch';
import { useStatusSwitch } from './useStatusSwitch';

const StatusSwitch = ({ status, id, disabled }) => {
  const { inputProps } = useStatusSwitch({ status, id });

  return <Switch checked={status} isFormik={false} disabled={disabled} inputProps={inputProps} />;
};

StatusSwitch.propTypes = {
  status: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.number,
};
export default memo(StatusSwitch);
