import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import {
  changeStatus,
  createProduct,
  getProducts,
  mutateProduct,
  getEditProduct,
  editProduct,
  removeProduct,
  setLimit,
  getAllStates,
  getProductSummary,
  handleSendSummary,
} from './actions';
import { errorLogger } from '../../helpers/errorLogger';
import Axios from '../../services/axios';
import { axiosData } from './requestGenerators';
import { notifyMessage } from '../message';
import { getPagesCount, getProductsParams, handleCreateProductValues } from './helpers';
import { closeModal } from '../modals/actions';
import { modalNames } from '../modals/constants';
import { selectProducts } from './selectors';
import { droppingErrors } from '../draggable-board/constants';

// sendSummary
function* sendSummaryProductsRequest({ boardId, subtractProducts }) {
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.sendSummary,
    data: { boardId, subtractProducts },
  });
  return result;
}

function* watchSendSummaryProducts() {
  // OK
  yield takeEvery(handleSendSummary, function* workSendSummaryProducts({ payload: { T, ...payload } }) {
    try {
      yield call(sendSummaryProductsRequest, payload);
      yield put(handleSendSummary.success());
    } catch (e) {
      yield put(handleSendSummary.error());
      const { message: errorMessage } = e.response.data.error;
      yield call(
        notifyMessage,
        {
          body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage,
          timer: 3000,
        },
        T
      );
      errorLogger(e);
    }
  });
}
// summary

function* getSummaryProductsRequest({ boardId, offset }) {
  const url = axiosData.getProductSummary.url.replace('id', boardId);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getProductSummary,
    url,
    params: { offset },
  });
  return result;
}

function* watchGetSummaryProducts() {
  // OK
  yield takeEvery(getProductSummary, function* workGetSummaryProducts({ payload: { T, ...payload } }) {
    try {
      const data = yield call(getSummaryProductsRequest, payload);
      yield put(getProductSummary.success({ data }));
    } catch (e) {
      yield put(getProductSummary.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

// states
function* getStatesProductsRequest({ boardId, offset, status, region, dateRange, searchWord, limit }) {
  const url = axiosData.getAllStates.url.replace('id', boardId);
  const type = localStorage.getItem('radioValue');
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getAllStates,
    url,
    params: {
      offset,
      ...(status ? { orderStatus: status } : {}),
      ...(region ? { state: region } : {}),
      ...(type === 'purchase' && dateRange[0] && dateRange[1]
        ? {
            createdAtStart: dateRange[0].toISOString().split('T')[0],
            createdAtEnd: dateRange[1].toISOString().split('T')[0],
          }
        : {}),
      ...(type === 'delivery' && dateRange[0] ? { deliveryDate: dateRange[0].toISOString().split('T')[0] } : {}),
      ...(searchWord ? { searchWord } : {}),
      ...(limit ? { limit } : {}),
    },
  });
  return result;
}

function* watchGetStatesProducts() {
  // OK
  yield takeEvery(getAllStates, function* workGetStatesProducts({ payload: { T, ...payload } }) {
    try {
      const { orders, count, statesInfo } = yield call(getStatesProductsRequest, payload);
      yield put(getAllStates.success({ data: orders, count, statesInfo }));
    } catch (e) {
      yield put(getAllStates.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

// products
function* getProductsRequest(data) {
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getProducts,
    params: getProductsParams(data),
  });
  return result;
}

function* watchGetProducts() {
  // OK
  yield takeEvery(getProducts, function* workGetProducts({ payload: { T, ...payload } }) {
    try {
      const { page: lastPage, limit, payload: lastPayload } = yield select(selectProducts);
      const data = yield call(getProductsRequest, {
        ...(payload || lastPayload || {}),
        page: (payload || lastPayload)?.page || lastPage,
        limit: payload?.limit || limit,
      });
      yield put(
        getProducts.success({
          data,
          pagesCount: getPagesCount({ count: data.count, limit }),
          page: (payload || lastPayload)?.page || 1,
          payload: payload ? { ...(lastPayload || {}), ...payload } : lastPayload,
        })
      );
    } catch (e) {
      yield put(getProducts.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

function* createProductRequest(values) {
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.createProduct,
    data: handleCreateProductValues(values),
  });
  return result;
}

function* watchCreateProduct() {
  // OK
  yield takeEvery(createProduct, function* workCreateProduct({ payload: { values, T } }) {
    try {
      yield call(createProductRequest, values);
      yield put(createProduct.success());
      yield put(closeModal({ name: modalNames.createEditProduct }));
      yield put(getProducts({ T }));
    } catch (e) {
      yield put(createProduct.error());
      yield put(closeModal({ name: modalNames.createEditProduct }));
      if (e.message && e.message === "Cannot read properties of undefined (reading 'url')") {
        yield call(notifyMessage, { body: T.error_upload_normal_img, timer: 10000 }, T);
      } else {
        yield call(notifyMessage, { body: T.error_something_went_wrong, timer: 10000 }, T);
      }
      errorLogger(e);
    }
  });
}

function* editProductRequest({ id, ...values }) {
  const url = axiosData.editProduct.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.editProduct,
    url,
    data: handleCreateProductValues(values),
  });
  return result;
}

function* watchEditProduct() {
  // OK
  yield takeEvery(editProduct, function* workEditProduct({ payload: { values, T } }) {
    try {
      yield call(editProductRequest, values);

      yield put(editProduct.success());
      yield put(closeModal({ name: modalNames.createEditProduct }));
      yield put(getProducts({ T }));
    } catch (e) {
      yield put(editProduct.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

function* getEditProductRequest(id) {
  const url = axiosData.getEditProduct.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getEditProduct,
    url,
  });
  return result;
}

function* watchGetEditProduct() {
  // OK
  yield takeEvery(getEditProduct, function* workCreateProduct({ payload: { id, T } }) {
    try {
      const data = yield call(getEditProductRequest, id);

      yield put(getEditProduct.success({ data }));
    } catch (e) {
      yield put(getEditProduct.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

function* changeStatusRequest({ id, status }) {
  const url = axiosData.changeStatus.url.replace(':id', id);
  const res = yield call(Axios.request, {
    ...axiosData.changeStatus,
    url,
    data: { status },
  });
  return res;
}

function* watchChangeStatus() {
  yield takeEvery(changeStatus, function* workChangeStatus({ payload: { status, id } }) {
    yield put(mutateProduct({ id }));
    try {
      yield call(changeStatusRequest, { id, status });
      yield put(mutateProduct.success({ id, status }));
    } catch (e) {
      errorLogger(e);
      yield put(mutateProduct.error({ id }));
    }
  });
}

function* removeProductRequest({ id }) {
  const url = axiosData.removeProduct.url.replace(':id', id);
  const res = yield call(Axios.request, {
    ...axiosData.removeProduct,
    url,
  });
  return res;
}

function* watchRemoveProduct() {
  // OK
  yield takeEvery(removeProduct, function* workRemoveProduct({ payload: { id, T } }) {
    yield put(mutateProduct({ id }));
    try {
      yield call(removeProductRequest, { id });
      const {
        data: { rows },
        page,
      } = yield select(selectProducts);

      if (rows.length === 1 || page !== 1) {
        yield put(getProducts({ page: page - 1, T }));
      }
      yield put(getProducts({ T }));
    } catch (e) {
      errorLogger(e);
      yield put(mutateProduct.error({ id }));
    }
  });
}

function* watchSetLimit() {
  yield takeEvery(setLimit, function* workSetLimit({ payload: { boardId, T } }) {
    try {
      yield put(getProducts({ page: 1, boardId, T }));
    } catch (e) {
      errorLogger(e);
    }
  });
}

export default function* rootProducts() {
  yield all([
    watchGetProducts(),
    watchCreateProduct(),
    watchChangeStatus(),
    watchGetEditProduct(),
    watchEditProduct(),
    watchRemoveProduct(),
    watchSetLimit(),
    watchGetStatesProducts(),
    watchGetSummaryProducts(),
    watchSendSummaryProducts(),
  ]);
}
