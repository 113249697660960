import React from 'react';

const NoDataSvg = () => {
  return (
    <svg width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M62 124C96.2417 124 124 96.2417 124 62C124 27.7583 96.2417 0 62 0C27.7583 0 0 27.7583 0 62C0 96.2417 27.7583 124 62 124Z"
        fill="#212529"
      />
      <path
        d="M80.5673 108.97L21.9484 92.398C20.7446 92.0256 20.0963 90.8153 20.3742 89.6049L40.7472 16.6124C41.1176 15.4021 42.3215 14.7504 43.5254 15.0297L102.052 31.602C103.255 31.9744 103.904 33.1847 103.626 34.395L83.2528 107.388C82.975 108.598 81.6785 109.25 80.5673 108.97Z"
        fill="#E6E9EE"
      />
      <path
        d="M92.7877 101.552H31.1183C29.8962 101.552 28.8621 100.529 28.8621 99.3195V23.6115C28.8621 22.4024 29.8962 21.3793 31.1183 21.3793H92.8817C94.1038 21.3793 95.1379 22.4024 95.1379 23.6115V99.3195C95.0439 100.529 94.1038 101.552 92.7877 101.552Z"
        fill="white"
      />
      <path d="M59.7655 27.3766H32.6075V29.8694H59.7655V27.3766Z" fill="#E6E9EE" />
      <path d="M91.3879 27.3766H64.2299V29.8694H91.3879V27.3766Z" fill="#E6E9EE" />
      <path d="M59.7655 34.7412H32.6075V37.234H59.7655V34.7412Z" fill="#E6E9EE" />
      <path d="M91.3879 34.7412H64.2299V37.234H91.3879V34.7412Z" fill="#E6E9EE" />
      <path d="M59.7655 42.2211H32.6075V44.7139H59.7655V42.2211Z" fill="#E6E9EE" />
      <path d="M91.3879 42.2211H64.2299V44.7139H91.3879V42.2211Z" fill="#E6E9EE" />
      <path d="M59.7655 49.6989H32.6075V52.1917H59.7655V49.6989Z" fill="#E6E9EE" />
      <path d="M91.3879 49.6989H64.2299V52.1917H91.3879V49.6989Z" fill="#E6E9EE" />
      <path d="M59.7655 57.1778H32.6075V59.6706H59.7655V57.1778Z" fill="#E6E9EE" />
      <path d="M91.3879 57.1778H64.2299V59.6706H91.3879V57.1778Z" fill="#E6E9EE" />
      <path d="M59.7655 64.6557H32.6075V67.1485H59.7655V64.6557Z" fill="#E6E9EE" />
      <path d="M91.3879 64.6557H64.2299V67.1485H91.3879V64.6557Z" fill="#E6E9EE" />
      <path d="M59.7655 72.1346H32.6075V74.6274H59.7655V72.1346Z" fill="#E6E9EE" />
      <path d="M91.3879 72.1346H64.2299V74.6274H91.3879V72.1346Z" fill="#E6E9EE" />
      <path d="M59.7655 79.6125H32.6075V82.1053H59.7655V79.6125Z" fill="#E6E9EE" />
      <path d="M91.3879 79.6125H64.2299V82.1053H91.3879V79.6125Z" fill="#E6E9EE" />
      <path d="M59.7655 87.0904H32.6075V89.5832H59.7655V87.0904Z" fill="#E6E9EE" />
      <path d="M91.3879 87.0904H64.2299V89.5832H91.3879V87.0904Z" fill="#E6E9EE" />
      <path d="M59.7655 94.5693H32.6075V97.0621H59.7655V94.5693Z" fill="#E6E9EE" />
      <path d="M91.3879 94.5693H64.2299V97.0621H91.3879V94.5693Z" fill="#E6E9EE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.8078 55.2116C70.5091 52.6428 71.5001 49.5621 71.5001 46.25C71.5001 37.2755 64.2246 30 55.25 30C46.2755 30 39 37.2755 39 46.25C39 55.2246 46.2755 62.5001 55.25 62.5001C58.5621 62.5001 61.6428 61.5091 64.2116 59.8078L72.452 68.0482C73.7211 69.3173 75.779 69.3173 77.0482 68.0482C78.3173 66.779 78.3173 64.7211 77.0482 63.452L68.8078 55.2116ZM45.5 46.25C45.5 40.8651 49.8651 36.5 55.25 36.5C60.635 36.5 65.0001 40.8651 65.0001 46.25C65.0001 51.635 60.635 56.0001 55.25 56.0001C49.8651 56.0001 45.5 51.635 45.5 46.25Z"
        fill="#212529"
      />
      <path
        d="M65 46C65 51.5228 60.5228 56 55 56C49.4772 56 45 51.5228 45 46C45 40.4772 49.4772 36 55 36C60.5228 36 65 40.4772 65 46Z"
        fill="white"
        fillOpacity="0.3"
      />
    </svg>
  );
};

export default NoDataSvg;
