import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { downloadReports, getOperatorsOrDeliveryMans } from './actions';

const getReportsInitialState = {
  loading: false,
  error: false,
  success: false,
};

const getReports = handleActions(
  {
    [downloadReports]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [downloadReports.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [downloadReports.error]: (state) => ({ ...state, loading: false, error: true, success: false }),
  },
  getReportsInitialState
);

const getOperatorDeliveryManInitialState = {
  loading: false,
  error: false,
  success: false,
  operators: [],
  deliveryMans: [],
};

const getOperatorDeliveryMan = handleActions(
  {
    [getOperatorsOrDeliveryMans]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [getOperatorsOrDeliveryMans.success]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        operators: payload.operator ? [...state.operators, ...payload.operator] : [],
        deliveryMans: payload.deliveryMan ? [...state.deliveryMans, ...payload.deliveryMan] : [],
      };
    },
    [getOperatorsOrDeliveryMans.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
  },
  getOperatorDeliveryManInitialState
);

const reportsReducer = combineReducers({ getReports, getOperatorDeliveryMan });

export default reportsReducer;
