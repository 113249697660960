import { all, takeEvery, put, call } from 'redux-saga/effects';
import { setCD, getCD } from './actions';
import { errorLogger } from '../../helpers/errorLogger';
import Axios from '../../services/axios';
import { axiosData } from './requestGenerators';
import { notifyMessage } from '../message';
import { closeModal } from '../modals/actions';
import { modalNames } from '../modals/constants';

function* setCDRequest(value) {
  const res = yield call(Axios.request, {
    ...axiosData.setCD,
    data: {
      CD: value,
    },
  });

  return res;
}

function* watchSetCDRequest() {
  // OK
  yield takeEvery(setCD, function* workSetCDRequest({ payload: { value, T } }) {
    try {
      yield call(setCDRequest, value);
      yield put(setCD.success());
      yield put(closeModal({ name: modalNames.createEditCD }));
    } catch (e) {
      yield call(notifyMessage, { body: T.error_something_went_wrong, timer: 1000 }, T);
      yield put(setCD.error());
      errorLogger(e);
    }
  });
}

function* getCDRequest() {
  const {
    data: {
      result: { CD },
    },
  } = yield call(Axios.request, {
    ...axiosData.getCD,
  });

  return CD;
}

function* watchGetCD() {
  yield takeEvery(getCD, function* workGetCD() {
    try {
      const value = yield call(getCDRequest);
      yield put(getCD.success({ value }));
    } catch (e) {
      yield put(getCD.error());
      errorLogger(e);
    }
  });
}

export default function* domain() {
  yield all([watchSetCDRequest(), watchGetCD()]);
}
