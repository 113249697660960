import { all } from 'redux-saga/effects';
import user from './user/saga';
import brands from './boards/saga';
import workers from './workers/saga';
import getData from './get-data/saga';
import domain from './domain/saga';
import draggableBoard from './draggable-board/saga';
import products from './products/saga';
import searchData from './search-data/saga';
import stickers from './stickers/saga';
import CD from './c-d/saga';
import deliverymanProducts from './deliveryman-products/saga';
import listsNames from './lists/saga';
import download from './download/saga';
import getAllRegions from './regions/saga';
import getAllCities from './cities/saga';
import getAllPosts from './posts/saga';
import regions from './region/saga';
import filter from './filter/saga';
import comments from './comments/saga';
import permissionsWatchers from './permissions/saga';
import address from './address/saga';
import reports from './reports/saga';
import warehouse from './warehouse/saga';
import customers from './customers/saga';
import storePermission from './store/saga';
import storeSerivce from './store-service/saga';

export default function* rootSaga() {
  yield all([
    user(),
    comments(),
    brands(),
    workers(),
    draggableBoard(),
    getData(),
    domain(),
    products(),
    searchData(),
    stickers(),
    CD(),
    deliverymanProducts(),
    listsNames(),
    download(),
    getAllRegions(),
    getAllCities(),
    getAllPosts(),
    regions(),
    filter(),
    permissionsWatchers(),
    address(),
    reports(),
    warehouse(),
    customers(),
    storePermission(),
    storeSerivce(),
  ]);
}
