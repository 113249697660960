import queryString from 'query-string';

import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export const useQueryString = () => {
  const location = useLocation();

  const search = useMemo(() => queryString.parse(location.search), [location.search]);
  return { search };
};
