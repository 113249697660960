import React from 'react';
import { array, func, string } from 'prop-types';
import classNames from './treeSelect.module.scss';
import CloseSvg from '../../svgs/CloseSvg';
import ChevronSvg from '../../svgs/ChevronSvg';
import useTreeSelect from './useTreeSelect';

const TreeSelect = ({
  placeholder = '',
  label = '',
  value: outerValue = {},
  options = [],
  onChange = () => {},
  subMenuOptions = [],
  onSubMenuOpen = () => {},
  onLoadMore = () => {},
}) => {
  const {
    ref,
    refSub,
    handleSelectOpen,
    selected,
    clearChips,
    inputRef,
    inputValue,
    isOpen,
    handleChangeSelect,
    isSubMenuOpen,
    handleSubSelectOpen,
    type,
    handleScroll,
  } = useTreeSelect({ onChange, options, outerValue, subMenuOptions, onSubMenuOpen, onLoadMore });

  return (
    <div className={classNames.selectWrapper}>
      <ul>
        {label && <label>{label}</label>}
        <li ref={ref}>
          <div onClick={handleSelectOpen} className={classNames.checkWrapper}>
            {selected.length ? (
              <div className={classNames.selelctedCount}>
                <p>{`+ ${selected.length}`}</p>
                <button type="button" onClick={clearChips}>
                  <CloseSvg />
                </button>
              </div>
            ) : null}
            <input
              ref={inputRef}
              type="text"
              value={inputValue}
              readOnly
              placeholder={selected.length ? '' : placeholder}
              onChange={() => {}}
            />
            <ChevronSvg />
          </div>
          {isOpen && (
            <>
              {options.length ? (
                <ul>
                  {options?.map(({ value, label }) => (
                    <li key={value} onClick={() => handleSubSelectOpen(value)} role="presentation">
                      <label>
                        {/* <input
                          value={value}
                          checked={!!selected?.find((item) => item.value === value)}
                          onChange={(e) => handleChangeSelect(e, label, value)}
                          type="checkbox"
                        />
                        <span /> */}
                        {label}
                      </label>
                      <div className={classNames.openSubMenu}>
                        <ChevronSvg />
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className={classNames.noDataItem}>no data</p>
              )}
              {isSubMenuOpen && (
                <div className={classNames.subMenuWrapper}>
                  {subMenuOptions.length ? (
                    <ul className={classNames.subMenu} ref={refSub} onScroll={handleScroll}>
                      {subMenuOptions.map(({ value, label }) => (
                        <li key={value}>
                          <label>
                            <input
                              value={value}
                              checked={!!selected?.find((item) => item.id === `${type} - ${value}`)}
                              onChange={(e) => handleChangeSelect(e, label, value)}
                              type="checkbox"
                              id={`${type} - ${value}`}
                            />
                            <span />
                            <p>{label}</p>
                          </label>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className={classNames.noDataItem}>no data</p>
                  )}
                </div>
              )}
            </>
          )}
        </li>
      </ul>
    </div>
  );
};
TreeSelect.propTypes = {
  placeholder: string,
  label: string,
  value: array,
  options: array,
  onChange: func,
  subMenuOptions: array,
  onSubMenuOpen: func,
  onLoadMore: func,
};

export default TreeSelect;
