import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { useOutsideDetect } from '../../hooks/useOutsideDetect';
import styles from './DateRangePicker.module.scss';
import ArrowSvg from '../../svgs/ArrowSvg';

const DateRangePicker = ({ startDate, endDate, handleChange }) => {
  const [openDropDown, setOpenDropDown] = useState('');

  const monthRef = useOutsideDetect(() => setOpenDropDown(''));
  const yearRef = useOutsideDetect(() => setOpenDropDown(''));

  const range = (start, end) => {
    const len = end - start + 1;
    const a = new Array(len);
    for (let i = 0; i < len; i += 1) a[i] = start + i;
    return a;
  };

  const years = range(1950, new Date().getFullYear()).reverse();

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const handleOpenDropDown = (picker) => {
    if (openDropDown) {
      setOpenDropDown('');
    } else {
      setOpenDropDown(picker);
    }
  };
  const handleChangeYear = (date, year, changeYear) => {
    const yearDate = new Date(date);
    date.setFullYear(year);

    changeYear(year);
    setValue(yearDate);
  };

  const handleChangeMonth = (date, month, changeMonth) => {
    const monthDate = new Date(date);
    monthDate.setMonth(month);
    changeMonth(month);
    setValue(monthDate);
  };
  return (
    <div className={styles.customDatePickerWrapper}>
      <ReactDatePicker
        selected={startDate}
        inline
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => {
          const currentYear = new Date(date).getFullYear();
          const currentMonth = months[new Date(date).getMonth()];

          return (
            <div className={styles.datepickerHeader}>
              <div className={styles.headerContent}>
                <div className={styles.montsWrapper}>
                  <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    <ArrowSvg />
                  </button>
                  <ul className={styles.selectWrapper}>
                    <li ref={monthRef}>
                      <p role="presentation" onClick={() => handleOpenDropDown('month')}>
                        {currentMonth}
                      </p>
                      <ul className={openDropDown === 'month' ? styles.openedDropDown : ''}>
                        {months.map((month) => {
                          return (
                            <li
                              key={month}
                              role="presentation"
                              onClick={() => handleChangeMonth(date, new Date(date).getMonth(), changeMonth)}
                            >
                              {month}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                  <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    <ArrowSvg />
                  </button>
                </div>
                <div className={styles.yearsWrapper}>
                  <button
                    type="button"
                    onClick={() => handleChangeYear(date, new Date(date).getFullYear() - 1, changeYear)}
                    disabled={currentYear <= 1900}
                  >
                    <ArrowSvg />
                  </button>
                  <ul className={styles.selectWrapper}>
                    <li ref={yearRef}>
                      <p role="presentation" onClick={() => handleOpenDropDown('year')}>
                        {currentYear}
                      </p>
                      <ul className={openDropDown === 'year' ? styles.openedDropDown : ''}>
                        {years.map((year) => {
                          return (
                            <li role="presentation" key={year} onClick={() => handleChangeYear(date, year, changeYear)}>
                              {year}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                  <button
                    disabled={currentYear >= new Date().getFullYear()}
                    type="button"
                    onClick={() => handleChangeYear(date, new Date(date).getFullYear() + 1, changeYear)}
                  >
                    <ArrowSvg />
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

DateRangePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  handleChange: PropTypes.func,
};

export default DateRangePicker;
