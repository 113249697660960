/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useMemo, useState } from 'react';
import { object } from 'prop-types';
import { useSelector } from 'react-redux';
import { selectLang, selectUserRole } from '../../../../store/user/selectors';
import CopyItem from '../../../../components/copy-item/CopyItem';
import { stringifyDate } from '../../../../helpers/stringifyDate';
import { Lang } from '../../../../T';
import LinkSvg from '../../../../svgs/LinkSvg';

import classNames from './row.module.scss';
import { formatNumberWithThousandsSeparator } from '../../../../helpers/formatNubmer';

const Row = ({ item }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const {
    listItemId,
    id,
    createdAt,
    phone1,
    orderedFrom,
    domain,
    deliveryPrice,
    totalPrice,
    orderStatus,
    products,
    orderType,
    barcode,
    soldAt,
    phone2,
    createdFrom,
    returnChangeAt,
    archivedAt,
    url,
    paymentType,
    rejectInPlacePrice,
    boardId,
  } = item;
  const [isOpened, setIsOpened] = useState(false);

  const role = useSelector(selectUserRole);
  const isYerevan = useMemo(() => process.env.REACT_APP_LANG === 'true', []);
  const createDate = useMemo(() => (createdAt ? stringifyDate(createdAt) : null), [createdAt]);

  const returnDate = useMemo(() => (returnChangeAt ? stringifyDate(returnChangeAt) : null), [returnChangeAt]);

  const archivedDate = useMemo(() => (archivedAt ? stringifyDate(archivedAt) : null), [archivedAt]);

  const soldDate = useMemo(() => (soldAt ? stringifyDate(soldAt) : null), [soldAt]);

  const statusTypes = {
    done: T.sold,
    rejected: T.rejected,
    callRejected: T.rejected_by_call,
  };

  const regionTypes = {
    region: T.region,
    yerevan: isYerevan ? T.city_type_am : T.city_type,
    warehouse: T.warehouse,
  };

  const orderTypes = {
    sale: T.sell,
    return: T.return,
    change: T.change,
  };

  return (
    <>
      <tr className={classNames.trItem}>
        <td className={classNames.tdItem}>
          <p>
            {id ? <CopyItem text={id} /> : '---'}
            <span className={classNames.linkToBoard}>
              <a href={`/board/${boardId}/default?order=${listItemId}`} target="_blank" rel="noreferrer">
                <LinkSvg />
              </a>
            </span>
          </p>
        </td>
        {role !== 'accountant' && (
          <>
            <td className={classNames.tdItem}>
              <p>{createDate || '---'}</p>
            </td>
            <td className={classNames.tdItem}>
              <p>{phone1 ? <CopyItem text={phone1} /> : '---'}</p>
            </td>
            <td className={classNames.tdItem}>
              <p>{phone2 ? <CopyItem text={phone2} /> : '---'}</p>
            </td>
            <td className={classNames.tdItem}>
              <p>{orderedFrom ? regionTypes[orderedFrom] : '---'}</p>
            </td>
          </>
        )}
        <td className={classNames.tdItem}>
          <p>{orderType ? orderTypes[orderType] : '---'}</p>
        </td>
        <td className={classNames.tdItem}>
          <p>{domain || '---'}</p>
        </td>
        <td className={classNames.tdItem}>
          <p>{barcode ? <CopyItem text={barcode?.toUpperCase()} /> : '---'}</p>
        </td>
        <td className={classNames.tdItem}>
          <p>{soldDate || '---'}</p>
        </td>
        <td className={classNames.tdItem}>
          <p>{returnDate || '---'}</p>
        </td>
        <td className={classNames.tdItem}>
          <p>{archivedDate || '---'}</p>
        </td>
        {role !== 'accountant' && (
          <>
            <td className={classNames.tdItem}>
              <p>{createdFrom || '---'}</p>
            </td>
            <td className={`${classNames.tdItem} ${classNames.copy}`}>
              <p>
                {url ? (
                  <CopyItem text={url} label={url?.substring(0, 33)} type="link" href={url} target="_blank" />
                ) : (
                  '---'
                )}
              </p>
            </td>
          </>
        )}
        <td className={classNames.tdItem}>
          <div className={classNames.moreButton} onClick={() => setIsOpened((prev) => !prev)}>
            {!isOpened ? T.more : T.hide}
          </div>
        </td>
        <td className={classNames.tdItem}>
          <p>{paymentType || '---'}</p>
        </td>
        <td className={classNames.tdItem}>
          <p>{rejectInPlacePrice ? formatNumberWithThousandsSeparator(rejectInPlacePrice, ' ') : '---'}</p>
        </td>
        <td className={classNames.tdItem}>
          <p>{deliveryPrice ? formatNumberWithThousandsSeparator(deliveryPrice, ' ') : '---'}</p>
        </td>
        <td className={classNames.tdItem}>
          <p>{totalPrice ? formatNumberWithThousandsSeparator(totalPrice, ' ') : '---'}</p>
        </td>
        <td className={classNames.tdItem}>
          <p>{orderStatus ? statusTypes[orderStatus] : '---'}</p>
        </td>
      </tr>
      {isOpened && (
        <tr>
          <td className={classNames.moreContent} colSpan="17">
            <table>
              <colgroup>
                <col style={{ width: 60 }} />
                <col style={{ width: 60 }} />
                <col style={{ width: 120 }} />
                <col style={{ width: 60 }} />
                <col style={{ width: 160 }} />
                <col style={{ width: 160 }} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">{T.Img}</th>
                  <th scope="col">{T.Name}</th>
                  <th scope="col">{T.code}</th>
                  <th scope="col">{T.price}</th>
                  <th scope="col">{T.count}</th>
                </tr>
              </thead>
              <tbody>
                {products.map(({ id, name, url, barcode, price, count }) => {
                  return (
                    <tr className={`${classNames.trItem} ${classNames.product}`} key={`${item.id}__${id}`}>
                      <td className={classNames.tdItem}>
                        <CopyItem text={id} />
                      </td>
                      <td className={classNames.tdItem}>
                        {url && url !== '/productPictures/null' ? (
                          <img src={`${process.env.REACT_APP_BASE_ENDPOINT}${url}`} alt="nkar" />
                        ) : (
                          '---'
                        )}
                      </td>
                      <td className={classNames.tdItem}>
                        <CopyItem text={name} />
                      </td>
                      <td className={classNames.tdItem}>
                        <CopyItem text={barcode} />
                      </td>
                      <td className={classNames.tdItem}>
                        {price ? formatNumberWithThousandsSeparator(price, ' ') : '---'}
                      </td>
                      <td className={classNames.tdItem}>{count}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
};

Row.propTypes = {
  item: object,
};
export default Row;
