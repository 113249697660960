export const axiosData = {
  createAddress: { url: '/api/addresses', method: 'post' },
  getAddress: { url: '/api/addresses', method: 'get' },
  removeAddress: { url: '/api/addresses/:id', method: 'delete' },
  getAddressInfoToEdit: {
    url: '/api/addresses/:id',
    method: 'get',
  },
  getAddressInfoToEditLists: {
    url: '/api/addresses/:id',
    method: 'get',
  },

  addressEdit: {
    url: '/api/addresses/:id',
    method: 'put',
  },
};
