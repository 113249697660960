import { useEffect, useRef, useState } from 'react';
import { useOutsideDetect } from '../../hooks/useOutsideDetect';

const useTreeSelect = ({ onChange, outerValue, onSubMenuOpen, onLoadMore }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState([]);
  const [type, setType] = useState(null);
  const inputRef = useRef(null);

  const handleSelectOpen = () => {
    setIsOpen((prev) => !prev);
    if (inputRef?.current === document.activeElement) {
      setIsOpen(true);
    }
  };
  const handleSubSelectOpen = (e) => {
    onSubMenuOpen(e);
    setIsSubMenuOpen(true);
    setType(e);
  };

  const handleChangeSelect = (e, label, value) => {
    if (e.target.checked) {
      onChange([...selected, { id: e.target.id, type, label, value }]);
      setSelected((prev) => [...prev, { id: e.target.id, type, label, value }]);
    } else {
      onChange(selected.filter((item) => item.id !== e.target.id));
      setSelected((prev) => prev.filter((item) => item.id !== e.target.id));
    }
  };

  const clearChips = (e) => {
    e.stopPropagation();
    setIsOpen(false);
    setSelected([]);
    onChange([]);
  };

  const handleScroll = (e) => {
    const { scrollTop, offsetHeight, scrollHeight } = e.target;
    onLoadMore(scrollTop, offsetHeight, scrollHeight);
  };

  const ref = useOutsideDetect(setIsOpen);

  const refSub = useOutsideDetect(setIsSubMenuOpen);

  useEffect(() => {
    if (!isOpen) {
      setIsSubMenuOpen(false);
      setInputValue('');
    }
  }, [isOpen]);

  useEffect(() => {
    setSelected(outerValue);
  }, [outerValue]);

  return {
    ref,
    refSub,
    handleSelectOpen,
    handleChangeSelect,
    isOpen,
    inputValue,
    selected,
    clearChips,
    inputRef,
    isSubMenuOpen,
    handleSubSelectOpen,
    type,
    handleScroll,
  };
};

export default useTreeSelect;
