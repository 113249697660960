import React from 'react';
import { array, func, object } from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from './filter.module.scss';
import Select from '../../../../components/select/Select';
import MultiSelect from '../../../../components/multi-select/MultiSelect';
import useConstants from '../../constants';
import useFilter from './useFilter';
import { Lang } from '../../../../T';
import { selectLang } from '../../../../store/user/selectors';

const Filters = ({ filter, setFilter, data }) => {
  const { periodOptions } = useConstants();
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const {
    urlsOptions,
    productOptions,
    isResetDisabled,
    handlePeriodChange,
    handleProductChange,
    handleUrlChange,
    handleUrlSearch,
    handleSearch,
    handleReset,
    handleCopyTable,
    copied,
  } = useFilter({ filter, setFilter, data });
  return (
    <div className={classNames.filterWrapper}>
      <div className={classNames.filters}>
        <div className={classNames.period}>
          <Select
            options={periodOptions}
            onChange={handlePeriodChange}
            label={T.Date}
            value={filter.period}
            placeholder=""
            widthDate
          />
        </div>

        <div className={classNames.crmProduct}>
          <MultiSelect
            label={T.CRM_Products}
            options={productOptions}
            onChange={handleProductChange}
            value={filter.productIds}
            isSearchable
            onSearch={handleSearch}
            placeholder={T.crmProducts}
          />
        </div>

        <div className={classNames.productUrl}>
          <MultiSelect
            options={urlsOptions}
            onChange={handleUrlChange}
            label={T.Store_Products}
            value={filter.urls}
            isSearchable
            onSearch={handleUrlSearch}
            placeholder={T.crmProducts}
          />
        </div>
        {!isResetDisabled ? (
          <button className={classNames.resetFilter} type="button" disabled={isResetDisabled} onClick={handleReset}>
            {T.reset_filter}
          </button>
        ) : null}
        <button className={classNames.filterButton} type="button" disabled={copied} onClick={handleCopyTable}>
          {copied ? 'Copied' : 'Copy Table'}
        </button>
      </div>
    </div>
  );
};

Filters.propTypes = {
  filter: object,
  setFilter: func,
  data: array,
};

export default Filters;
