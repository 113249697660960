import { all, call, put, takeEvery } from 'redux-saga/effects';
import { errorLogger } from '../../helpers/errorLogger';
import axios from '../../services/axios';
import { notifyMessage } from '../message';
import {
  addDescription,
  createProductRequest,
  editProductInput,
  getDescription,
  getExportRequests,
  getInputRequests,
  getRequestCounts,
  getRequests,
  getStorage,
  getWarehouseCount,
  reviewRequest,
} from './action';
import { axiosData, sortDirection } from './requestGenerator';

// GET

function* getRequest(params) {
  const { data } = yield call(axios.request, { ...axiosData.get, params });

  return data.result;
}

function* watchGet() {
  // OK
  yield takeEvery(
    getStorage,
    function* worker({
      payload: {
        page,
        limit,
        search,
        boardId,
        selectOnlyWaiting,
        type,
        filterCount,
        filterDefect,
        filterPrice,
        filterWeight,
        filterDate,
        T,
      },
    }) {
      try {
        const { needToCheck, products, totalCount, totalMarketingCount } = yield call(getRequest, {
          boardId,
          searchWord: search || undefined,
          type: type || undefined,
          limit,
          offset: (page - 1) * limit <= 0 ? 0 : (page - 1) * limit,
          selectOption: selectOnlyWaiting || '',
          ...(filterCount && { orderBy: 'COUNT', sortDirection: sortDirection[filterCount] }),
          ...(filterDefect && { orderBy: 'DEFECT', sortDirection: sortDirection[filterDefect] }),
          ...(filterPrice && { orderBy: 'PRICE', sortDirection: sortDirection[filterPrice] }),
          ...(filterWeight && { orderBy: 'WEIGHT', sortDirection: sortDirection[filterWeight] }),
          ...(filterDate && { orderBy: 'DATE', sortDirection: sortDirection[filterDate] }),
        });
        const { count: total, rows: data } = products || {};
        yield put(
          getStorage.success({
            needToCheck: needToCheck || 0,
            total: total || 0,
            data: data || [],
            totalCount: totalCount || 0,
            totalMarketingCount: totalMarketingCount || 0,
          })
        );
      } catch (e) {
        yield put(getStorage.error());
        yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
        errorLogger(e);
      }
    }
  );
}

// GET COUNTS

function* getCountRequest() {
  const { data } = yield call(axios.request, { ...axiosData.getCounts });
  return data.result;
}

function* watchGetCount() {
  // OK
  yield takeEvery(getRequestCounts, function* worker({ payload: { T } }) {
    try {
      const data = yield call(getCountRequest);
      yield put(getRequestCounts.success(data));
    } catch (e) {
      yield put(getRequestCounts.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

// GET WAREHOUSE COUNTS

function* getWarehouseCountRequest(params) {
  const { data } = yield call(axios.request, {
    ...axiosData.getWarehouseCounts,
    params,
  });
  return data.result;
}

function* watchGetWarehouseCount() {
  // OK
  yield takeEvery(getWarehouseCount, function* worker({ payload: { search, boardId, selectOnlyWaiting, type, T } }) {
    try {
      const data = yield call(getWarehouseCountRequest, {
        searchWord: search || undefined,
        boardId,
        selectOption: selectOnlyWaiting || '',
        type: type || undefined,
      });
      yield put(getWarehouseCount.success(data));
    } catch (e) {
      yield put(getWarehouseCount.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

// GET REQUESTS

function* getRequestsRequest(params) {
  const { data } = yield call(axios.request, {
    ...axiosData.getRequests,
    params,
  });
  return data.result;
}

function* watchGetRequest() {
  // OK
  yield takeEvery(getRequests, function* worker({ payload: { offset, limit, type, searchWord, T } }) {
    try {
      const { rows: data, count: total } = yield call(getRequestsRequest, {
        offset: offset <= 0 ? 0 : offset,
        limit,
        type,
        searchWord,
      });
      yield put(getRequests.success({ data, total }));
    } catch (e) {
      yield put(getRequests.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

// GET INPUT REQUESTS

function* getInputRequest(params) {
  const { data } = yield call(axios.request, { ...axiosData.getInput, params });
  return data.result;
}

function* watchGetInputRequest() {
  // OK
  yield takeEvery(
    getInputRequests,
    function* worker({ payload: { page = 0, limit = 20, productId = -1, onlyApproved = false, T } }) {
      try {
        const {
          rows: data,
          count: total,
          product,
        } = yield call(getInputRequest, {
          offset: (page - 1) * limit <= 0 ? 0 : (page - 1) * limit,
          limit,
          productId,
          onlyApproved,
        });
        yield put(getInputRequests.success({ data, total, product }));
      } catch (e) {
        yield put(getInputRequests.error());
        yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
        errorLogger(e);
      }
    }
  );
}

// GET EXPORT REQUESTS

function* getExportRequest(params) {
  const { data } = yield call(axios.request, {
    ...axiosData.getExport,
    params,
  });
  return data.result;
}

function* watchGetExportRequest() {
  // OK
  yield takeEvery(getExportRequests, function* worker({ payload: { page = 0, limit = 20, productId = 1, T } }) {
    try {
      const {
        rows: data,
        count: total,
        product,
      } = yield call(getExportRequest, {
        offset: (page - 1) * limit <= 0 ? 0 : (page - 1) * limit,
        limit,
        productId,
      });
      yield put(getExportRequests.success({ data, total, product }));
    } catch (e) {
      yield put(getExportRequests.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

// REVIEW REQUEST

function* reviewRequestRequest(data) {
  yield call(axios.request, { ...axiosData.reviewRequest, data });
}

function* watchReviewRequest() {
  // OK
  yield takeEvery(
    reviewRequest,
    function* worker({ payload: { inputId, status, description, updatedAt, details, T } }) {
      try {
        yield call(reviewRequestRequest, {
          inputId,
          status,
          description,
          updatedAt,
          details,
        });
        yield put(reviewRequest.success());
      } catch (e) {
        yield put(reviewRequest.error());
        yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
        errorLogger(e);
      }
    }
  );
}

// CREATE REQUEST

function* createRequest(data) {
  yield call(axios.request, { ...axiosData.createProductRequest, data });
}

function* watchCreateRequest() {
  // OK
  yield takeEvery(
    createProductRequest,
    function* worker({ payload: { type, productId, inputId, value, description, T } }) {
      try {
        yield call(createRequest, {
          type,
          productId,
          inputId,
          value,
          description,
        });
        yield put(createProductRequest.success());
      } catch (e) {
        yield put(createProductRequest.error());
        yield call(
          notifyMessage,
          {
            body:
              e?.response?.data?.error?.message?.value ||
              e?.response?.data?.error?.message ||
              e?.response?.data?.error ||
              T.error_something_went_wrong,
          },
          T
        );
        errorLogger(e);
      }
    }
  );
}

// EDIT INPUT

function* editInputRequest(data) {
  yield call(axios.request, { ...axiosData.editProductInput, data });
}

function* watchEditInput() {
  // OK
  yield takeEvery(editProductInput, function* worker({ payload: { type, inputId, value, T } }) {
    try {
      yield call(editInputRequest, { type, inputId, value });
      yield put(editProductInput.success());
    } catch (e) {
      yield put(editProductInput.error());
      yield call(notifyMessage, { body: e?.response?.data?.error || T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

// ADD DESCRIPTION

function* addDescriptionRequest(data) {
  yield call(axios.request, { ...axiosData.addDescription, data });
}

function* watchAddDescription() {
  // OK
  yield takeEvery(addDescription, function* worker({ payload: { inputId, description, T } }) {
    try {
      yield call(addDescriptionRequest, { inputId, description });
      yield put(addDescription.success());
    } catch (e) {
      yield put(addDescription.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}

// GET EXPORT REQUESTS

function* getDescriptionRequest(params) {
  const { data } = yield call(axios.request, {
    ...axiosData.getDescription,
    params,
  });
  return data.result;
}

function* watchGetDescription() {
  // OK
  yield takeEvery(getDescription, function* worker({ payload: { inputId, T } }) {
    try {
      const { rows: data, count: total } = yield call(getDescriptionRequest, {
        inputId,
      });
      yield put(getDescription.success({ data, total }));
    } catch (e) {
      yield put(getDescription.error());
      yield call(notifyMessage, { body: T.error_something_went_wrong }, T);
      errorLogger(e);
    }
  });
}
export default function* rootProducts() {
  yield all([
    watchGet(),
    watchGetCount(),
    watchGetRequest(),
    watchGetInputRequest(),
    watchGetExportRequest(),
    watchReviewRequest(),
    watchCreateRequest(),
    watchEditInput(),
    watchAddDescription(),
    watchGetDescription(),
    watchGetWarehouseCount(),
  ]);
}
