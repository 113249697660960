import React from 'react';

const FilterIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24Z"
        fill="white"
      />
      <path
        d="M21.036 35V32.635H27.342V35H21.036ZM14.73 26.723V24.358H33.648V26.723H14.73ZM10 18.447V16.082H38.377V18.447H10Z"
        fill="#364364"
      />
    </svg>
  );
};

export default FilterIcon;
