import { openModal } from '../../../store/modals/actions';
import { modalNames } from '../../../store/modals/constants';

function showNotification(dispatch, title, firebaseMode = false, timer = 3500) {
  dispatch(
    openModal({
      name: modalNames.notification,
      data: {
        title,
        firebaseMode,
        timer,
      },
    })
  );
}

export default showNotification;
