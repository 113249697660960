import React from 'react';

const VerticalDarkDotSvg = () => {
  return (
    <svg width="6" height="23" viewBox="0 0 6 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.83914 5.67851C4.40716 5.67851 5.67829 4.40739 5.67829 2.83937C5.67829 1.27136 4.40716 0.000228882 2.83914 0.000228882C1.27113 0.000228882 0 1.27136 0 2.83937C0 4.40739 1.27113 5.67851 2.83914 5.67851Z"
        fill="black"
      />
      <path
        d="M2.83914 14.1955C4.40716 14.1955 5.67829 12.9244 5.67829 11.3564C5.67829 9.78839 4.40716 8.51726 2.83914 8.51726C1.27113 8.51726 0 9.78839 0 11.3564C0 12.9244 1.27113 14.1955 2.83914 14.1955Z"
        fill="black"
      />
      <path
        d="M2.83914 22.7132C4.40716 22.7132 5.67829 21.442 5.67829 19.874C5.67829 18.306 4.40716 17.0349 2.83914 17.0349C1.27113 17.0349 0 18.306 0 19.874C0 21.442 1.27113 22.7132 2.83914 22.7132Z"
        fill="black"
      />
    </svg>
  );
};

export default VerticalDarkDotSvg;
