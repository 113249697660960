import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useWorkerRow } from './useWorkerRow';
import Salary from '../salary/salary';
import Image from '../../../../components/image/Image';
import { getWorkerSalary } from '../../../../store/workers/actions';
import { resendEmail } from '../../../../store/workers/actions';
import ArrowSvg from '../../../../svgs/ArrowSvg';
import classNames from '../../workers.module.scss';
import { imgStylesBig } from '../../../products/constants';
import { selectLang, selectUserRole } from '../../../../store/user/selectors';
import EditIconSvg from '../../../../svgs/EditIconSvg';
import DeleteIconSvg from '../../../../svgs/DeleteIconSvg';
import { Lang } from '../../../../T';

const WorkerRow = ({
  id,
  firstName,
  lastName,
  createdAt,
  email,
  profilePicture,
  role,
  resendLoading,
  invitationStatus,
}) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const roleData = {
    admin: T.admin,
    director: T.director,
    deliveryMan: T.deliveryman,
    storeKeeper: T.of_warehouseman,
    operator: T.operator,
    viewer: T.viewer,
    chiefOperator: T.chief_operator,
    marketingExpert: T.marketingExpert,
    productCreator: T.productCreator,
    accountant: T.accountant,
  };

  const dispatch = useDispatch();
  const { handleDelete, loading, openEditModal } = useWorkerRow({ id });
  const [visible, setVisible] = useState(false);
  const userRole = useSelector(selectUserRole);
  const imgStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    objectFit: 'contain',
  };
  return (
    <>
      <div className={classNames.rowItemWrapper}>
        <div className={classNames.tableRow}>
          <div className={classNames.row_item}>
            <p>{id}</p>
          </div>
          <div className={classNames.row_item}>
            <div className={classNames.picturePopUpContainer}>
              <p>
                {profilePicture ? (
                  <Image src={profilePicture} alt="" style={imgStyle} />
                ) : (
                  <Image src="/images/avatar.png" alt="" style={imgStyle} />
                )}
              </p>
              <div className={classNames.picturePopUp}>
                {profilePicture ? (
                  <Image src={profilePicture} alt="" style={imgStylesBig} />
                ) : (
                  <Image src="/images/avatar.png" alt="" style={imgStylesBig} />
                )}
              </div>
            </div>
          </div>
          <div className={classNames.row_item}>
            <p>
              {firstName} {lastName}
            </p>
          </div>
          <div className={classNames.row_item}>
            <p>
              <span className={invitationStatus === '2' ? classNames.worketActive : classNames.worketWaiting} />
              {invitationStatus === '2' ? T.active : T.waiting}
            </p>
          </div>
          <div className={classNames.row_item}>
            <p>{createdAt}</p>
          </div>
          <div className={classNames.row_item}>
            <p>{roleData[role]}</p>
          </div>
          <div className={classNames.row_item}>
            <p className={classNames.workersEmail}>{email}</p>
          </div>
          <div className={classNames.row_item}>
            <p>
              {invitationStatus !== '2' ? (
                <button
                  type="button"
                  disabled={resendLoading}
                  className={`${classNames.salaryButton} ${visible ? classNames.salaryButtonActive : ''}`}
                  onClick={() => {
                    dispatch(resendEmail({ email }));
                  }}
                >
                  {T.send_again}
                </button>
              ) : (
                <button
                  type="button"
                  className={`${classNames.salaryButton} ${visible ? classNames.salaryButtonActive : ''}`}
                  onClick={() => {
                    if (!visible) {
                      dispatch(getWorkerSalary({ id }));
                    }
                    setVisible((prev) => !prev);
                  }}
                >
                  {T.salary}
                  <span>
                    <ArrowSvg />
                  </span>
                </button>
              )}
            </p>
          </div>
          <div className={classNames.row_item}>
            <p className={classNames.controls_btn_content}>
              <button disabled={loading} onClick={openEditModal} type="button">
                <EditIconSvg />
              </button>
            </p>
          </div>
          <div className={classNames.row_item}>
            <p className={classNames.controls_btn_content}>
              <button
                disabled={loading || userRole === 'director'}
                onClick={() => {
                  handleDelete(email);
                }}
                type="button"
              >
                <DeleteIconSvg />
              </button>
            </p>
          </div>
        </div>
      </div>
      {visible && <Salary id={id} firstName={firstName} lastName={lastName} />}
    </>
  );
};

WorkerRow.propTypes = {
  id: PropTypes.number,
  firstName: PropTypes.string,
  createdAt: PropTypes.string,
  permission: PropTypes.string,
  email: PropTypes.string,
  lastName: PropTypes.string,
  setVisible: PropTypes.func,
  profilePicture: PropTypes.string,
  role: PropTypes.string,
  resendLoading: PropTypes.bool,
  resendSuccess: PropTypes.bool,
  invitationStatus: PropTypes.string,
};

export default WorkerRow;
