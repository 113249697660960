import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectSendSummary, selectStatesProduct } from '../../store/products/selectors';
import { getAllBoards } from '../../store/lists/actions';
import { selectAllGetBoardNames } from '../../store/lists/selectors';
import { getAllStates } from '../../store/products/actions';
import { selectRevertedStatus, statusShippingSuccess } from '../../store/draggable-board/selector';
import { getRegions } from '../../store/region/action';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';

export const useStates = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const dispatch = useDispatch();
  const { data, count, loading, statesInfo } = useSelector(selectStatesProduct);
  const { data: boards } = useSelector(selectAllGetBoardNames);
  const { success: sendSuccess } = useSelector(selectSendSummary);
  const statusSuccess = useSelector(statusShippingSuccess);
  const revertSuccess = useSelector(selectRevertedStatus);
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);

  const [boardId, setBoardId] = useState();
  const [status, setStatus] = useState('');
  const [limit, setLimit] = useState(20);
  const [region, setRegion] = useState('');
  const [searchWord, setSearchWord] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const pagesCount = useMemo(() => Math.ceil(count / limit), [count, limit]);

  const regions = statesInfo?.map((region) => {
    return { label: `${region.state} - ${region.count}`, value: region.state };
  });

  const handleSearch = (value) => {
    setOffset(0);
    setSearchWord(value);
  };
  const changeLimit = ({ value }) => {
    setLimit(value);
    setOffset(0);
  };

  useEffect(() => {
    dispatch(getAllBoards());
  }, []);

  const resetFilters = () => {
    setBoardId(boards[0].value);
    setStatus('');
    setRegion('');
    setDateRange([null, null]);
  };

  useEffect(() => {
    dispatch(getRegions());
  }, []);
  const handleGetStates = useCallback(() => {
    if (boardId) {
      dispatch(
        getAllStates({
          T,
          boardId: boardId?.value || boards[0]?.value,
          offset,
          status: status.value,
          ...(region.value !== 'Բոլորը' ? { region: region.value } : {}),
          dateRange,
          searchWord,
          limit,
        })
      );
    }
  }, [boardId?.value, offset, status.value, region.value, dateRange, searchWord, limit, boardId]);

  useEffect(() => {
    if (statusSuccess || revertSuccess || sendSuccess) handleGetStates();
  }, [statusSuccess, revertSuccess, sendSuccess]);

  useEffect(() => {
    setBoardId(boards[0]?.value);
  }, [boards]);

  useEffect(() => {
    setOffset(page * limit - limit);
  }, [page]);

  useEffect(() => {
    handleGetStates();
  }, [offset, boardId, boardId?.value, status.value, region.value, dateRange, searchWord, limit]);
  return {
    loading,
    page,
    setPage,
    data,
    boardData: boards,
    pagesCount,
    setBoardId,
    setStatus,
    regions: [{ value: 'Բոլորը', label: T.all }, ...regions],
    region,
    setRegion,
    startDate,
    endDate,
    setDateRange,
    resetFilters,
    status,
    handleSearch,
    changeLimit,
    limit,
    boardId,
    setSearchWord,
  };
};
