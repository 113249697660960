import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDescription, getRequests, reviewRequest } from '../../../../store/warehouse/action';
import { selectAddDescriptionData, selectReviewData } from '../../../../store/warehouse/selector';
import Table from './components/table/Table';
import { selectLang } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';

const ProductCheckTab = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const dispatch = useDispatch();

  const { success } = useSelector(selectReviewData);
  const { success: successComment } = useSelector(selectAddDescriptionData);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(getRequests({ offset: (page - 1) * limit, limit, searchWord: search, type: 'NORMAL', T }));
  }, [page, limit]);

  useEffect(() => {
    dispatch(getRequests({ offset: (page - 1) * limit, limit, searchWord: search, type: 'NORMAL', T }));
  }, [search]);

  useEffect(() => {
    if (success || successComment) {
      dispatch(getRequests({ offset: (page - 1) * limit, searchWord: search, limit, type: 'NORMAL', T }));
      dispatch(reviewRequest.reset());
      dispatch(addDescription.reset());
    }
  }, [success, successComment]);

  return (
    <>
      <Table page={page} setPage={setPage} limit={limit} setLimit={setLimit} setSearch={setSearch} search={search} />
    </>
  );
};

export default ProductCheckTab;
