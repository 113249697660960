import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
  deliverymanProductsGet,
  deliverymanProductsSet,
  deliverymanProductsClear,
  getDeliverymanProducts,
  searchDeliverymanProducts,
  saveProductCounts,
} from './actions';

const deliverymanProductsSetInitialState = {
  loading: false,
  error: false,
  success: false,
  data: {},
  dataClone: {},
};

const set = handleActions(
  {
    [deliverymanProductsSet]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [deliverymanProductsSet.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
    }),

    [deliverymanProductsSet.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [deliverymanProductsSet.reset]: () => ({
      loading: false,
      error: false,
      data: {},
    }),
  },
  deliverymanProductsSetInitialState
);
const saveProductsInitialState = {
  loading: false,
  error: false,
  success: false,
  data: {},
};

const saveProductWarehouse = handleActions(
  {
    [saveProductCounts]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [saveProductCounts.success]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      data: { ...state.data, [payload.productId]: { ...payload } },
    }),

    [saveProductCounts.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [saveProductCounts.reset]: () => ({
      loading: false,
      error: false,
      data: {},
    }),
  },
  saveProductsInitialState
);

const deliverymanProductsClearInitialState = {
  loading: false,
  error: false,
  success: false,
  data: {},
  dataClone: {},
};

const clear = handleActions(
  {
    [deliverymanProductsClear]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [deliverymanProductsClear.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),

    [deliverymanProductsClear.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
    [deliverymanProductsClear.reset]: () => ({
      loading: false,
      error: false,
      data: {},
      success: false,
    }),
  },
  deliverymanProductsClearInitialState
);

const deliverymanProductsGetInitialState = {
  loading: false,
  error: false,
  success: false,
  data: {
    products: {
      productCounts: [],
    },
    summery: [],
  },
  dataClone: {},
};

const get = handleActions(
  {
    [deliverymanProductsGet]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [deliverymanProductsGet.success]: (state, { payload: { data } }) => {
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: {
          ...state.data,
          // products: {
          //   ...state.data.products,
          //   productCounts: [...state.data.products?.productCounts, ...data.productsInfo.rows],
          // },
          summery: [...state.data?.summery, ...data.summery],
        },
        gas: data.gasPrice,
        dataClone: data,
      };
    },

    [deliverymanProductsGet.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
    [deliverymanProductsGet.reset]: () => ({
      ...deliverymanProductsGetInitialState,
    }),
  },
  deliverymanProductsGetInitialState
);

const ProductsGetInitialState = {
  loading: false,
  error: false,
  success: false,
  data: {
    products: {
      productCounts: [],
    },
    summery: [],
  },
  dataClone: {},
};

const ProductsSearchInitialState = {
  loading: false,
  error: false,
  success: false,
  searchData: {
    products: {
      productCounts: [],
    },
    summery: [],
  },
  dataClone: {},
};
const getProducts = handleActions(
  {
    [getDeliverymanProducts]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [getDeliverymanProducts.success]: (state, { payload: { data } }) => {
      return {
        ...state,
        loading: false,
        error: false,
        searchData: {
          products: {
            productCounts: [],
          },
          summery: [],
        },
        data: {
          ...state.data,
          products: {
            ...state.data.products,
            productCounts: [...state.data.products?.productCounts, ...data.products.rows],
          },
        },
        gas: data.gasPrice || 0,
        // dataClone: data,
        end: !data.products.rows.length,
        acceptedByDeliveryman: data.dataValues.acceptedByDeliveryman,
        acceptedByModerator: data.dataValues.acceptedByModerator,
        returnedByDeliveryman: data.dataValues.returnedByDeliveryman,
        summary: data?.summary || {},
      };
    },
    [getDeliverymanProducts.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [getDeliverymanProducts.reset]: () => ({
      loading: false,
      error: false,
      data: {
        products: {
          productCounts: [],
        },
        summery: [],
      },
    }),
  },
  ProductsGetInitialState
);
const search = handleActions(
  {
    [searchDeliverymanProducts]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),

    [searchDeliverymanProducts.success]: (state, { payload: { data, offset } }) => {
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        searchData: {
          ...state.searchData,
          products: {
            ...state.searchData.products,
            productCounts: offset
              ? [...state.searchData.products?.productCounts, ...data.products.rows]
              : [...data.products.rows],
          },
        },
        gas: data.gasPrice || 0,
        // dataClone: data,
        end: !data.products.rows.length,
        acceptedByDeliveryman: data.dataValues.acceptedByDeliveryman,
        acceptedByModerator: data.dataValues.acceptedByModerator,
        returnedByDeliveryman: data.dataValues.returnedByDeliveryman,
      };
    },
    [searchDeliverymanProducts.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [searchDeliverymanProducts.reset]: () => ({
      loading: false,
      error: false,
      searchData: {
        products: {
          productCounts: [],
        },
        summery: [],
      },
      data: {
        products: {
          productCounts: [],
        },
        summery: [],
      },
    }),
  },
  ProductsSearchInitialState
);

export default combineReducers({ get, set, clear, getProducts, search, saveProductWarehouse });
