export const createWorkerDataHandler = (values) => {
  return {
    salary: values.salary,
    percent: values.percent,
    role: values.position,
    email: values.email,
    permissionGroupId: values.permissionsGroup.id,
    permissions: values.boardPermissions.map(({ board: { value: boardId }, lists, permissions }) => {
      return {
        boardId,
        listIds: lists.map(({ value }) => value),
        permissionIds: Object.keys(permissions),
      };
    }),
  };
};

export const workerRegistrationDataHandler = (values) => {
  const formData = new FormData();

  formData.append('firstName', values.name);
  formData.append('lastName', values.lastName);
  formData.append('password', values.password);
  formData.append('retryPassword', values.repeatPassword);
  formData.append('image', values.photo);

  return formData;
};
