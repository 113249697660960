export const typeNames = {
  grossProfit: 'grossProfit',
  netTotal: 'netTotal',
  newOrderCount: 'newOrderCount',
  acceptedCount: 'acceptedCount',
  totalCount: 'totalCount',
  unAcceptedCount: 'unAcceptedCount',
  currentSoldCount: 'currentSoldCount',
  changeCount: 'changeCount',
  returnCount: 'returnCount',
  saleOverall: 'saleOverall',
  pendingChangeCount: 'pendingChangeCount',
  pendingReturnCount: 'pendingReturnCount',
  rejectedCount: 'rejectedCount',
  callRejectedCount: 'callRejectedCount',
  rejectedInPlaceCount: 'rejectedInPlaceCount',
  pending: 'pending',
  sentToStateCount: 'sentToStateCount',
};

export const limitOptions = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 150, label: 150 },
  { value: 200, label: 200 },
];

export const typeOptions = {
  grossProfit: 'gross',
  netTotal: 'net',
  newOrderCount: 'new',
  acceptedCount: 'accepted',
  unAcceptedCount: 'unaccepted',
  totalCount: 'total',
  currentSoldCount: 'sale',
  saleOverall: 'saleOverall',
  pendingChangeCount: 'changePending',
  changeCount: 'change',
  returnCount: 'return',
  pendingReturnCount: 'returnPending',
  rejectedCount: 'rejected',
  callRejectedCount: 'callRejected',
  rejectedInPlaceCount: 'rejectedInPlace',
  pending: 'pending',
  sentToStateCount: 'sentToState',
};
