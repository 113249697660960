import React from 'react';

const CalendarSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23.25" viewBox="0 0 22 23.25">
      <g id="Group_7193" data-name="Group 7193" transform="translate(-567 -149.75)">
        <g
          id="Rectangle_10342"
          data-name="Rectangle 10342"
          transform="translate(567 153)"
          fill="#fff"
          stroke="#397ae8"
          strokeWidth="1.5"
        >
          <rect width="22" height="20" rx="3" stroke="none" />
          <rect x="0.75" y="0.75" width="20.5" height="18.5" rx="2.25" fill="none" />
        </g>
        <line
          id="Line_2021"
          data-name="Line 2021"
          y2="5"
          transform="translate(573.565 150.5)"
          fill="none"
          stroke="#397ae8"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_2022"
          data-name="Line 2022"
          y2="5"
          transform="translate(582.565 150.5)"
          fill="none"
          stroke="#397ae8"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_2023"
          data-name="Line 2023"
          x1="11"
          transform="translate(572.565 159.5)"
          fill="none"
          stroke="#397ae8"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default CalendarSvg;
