import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { openModal } from '../../../../../../store/modals/actions';
import { modalNames } from '../../../../../../store/modals/constants';
import { selectDraggableBoard } from '../../../../../../store/draggable-board/selector';

const useAddOrder = ({ id, name, deliveryList }) => {
  const dispatch = useDispatch();
  const { id: boardId } = useParams();
  const { archived } = useSelector(selectDraggableBoard);
  const handleAddOrder = useCallback(() => {
    dispatch(
      openModal({
        name: modalNames.createEditOrder,
        data: {
          listId: id,
          name,
          boardId,
          deliveryList,
        },
      })
    );
  }, [id, name, boardId, deliveryList]);

  return { handleAddOrder, archived };
};
export default useAddOrder;
