import React, { Fragment, useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import useSpecialProductPage from './useSpecialProductPage';
import classNames from './specialProductPage.module.scss';
import Row from './components/row/Row';
import NoDataSvg from '../../svgs/NoDataSvg';
import Pagination from '../../components/pagination/Pagination';
import { limitOptions } from './constants';
import usePagination from '../../hooks/usePagination';
import { Lang } from '../../T';
import RightSvg from '../../svgs/rightArrow';
import { selectLang } from '../../store/user/selectors';
import Filters from '../special/components/filters/Filters';

const SpecialProductPage = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const [total, setTotal] = useState(0);

  const { page, limit, changePage, changeLimit, pagesCount } = usePagination(total);
  const {
    filter,
    data,
    typeName,
    setFilter,
    handlePeriodChange,
    handleSearch,
    productOptions,
    domainOptions,
    handleDomainChange,
    urlsOptions,
    handleUrlChange,
    handleUrlSearch,
    handleRegionChange,
    handleDeviceChange,
    handleReset,
    isResetDisabled,
    preset,
    setPreset,
    presetList,
    setPresetList,
    handleChooseUtmKey,
    handleUtmChange,
    utmValueList,
    getMoreUtm,
    goBackToSpecial,
    role,
    typeHeaders,
  } = useSpecialProductPage(limit, page, setTotal);

  return (
    <div className={classNames.container}>
      <div className={classNames.pageTitle}>
        <h2>{T.analytics}</h2>
      </div>
      <div className={classNames.header}>
        <div className={classNames.backButton} onClick={goBackToSpecial}>
          <RightSvg />
        </div>
        {typeHeaders[typeName]}
      </div>

      <Filters
        filter={filter}
        setFilter={setFilter}
        handlePeriodChange={handlePeriodChange}
        productOptions={productOptions}
        handleSearch={handleSearch}
        urlsOptions={urlsOptions}
        handleUrlChange={handleUrlChange}
        handleUrlSearch={handleUrlSearch}
        domainOptions={domainOptions}
        handleDomainChange={handleDomainChange}
        handleRegionChange={handleRegionChange}
        handleDeviceChange={handleDeviceChange}
        handleReset={handleReset}
        isResetDisabled={isResetDisabled}
        preset={preset}
        setPreset={setPreset}
        presetList={presetList}
        setPresetList={setPresetList}
        handleChooseUtmKey={handleChooseUtmKey}
        handlePercentageTypeChange={() => {}}
        handleUtmChange={handleUtmChange}
        utmValueList={utmValueList}
        getMoreUtm={getMoreUtm}
      />
      <div />

      <div className="container maxw-100">
        <div className={classNames.table}>
          <div className={classNames.tableWrapper}>
            <table>
              <colgroup>
                <col style={{ width: 140 }} />
                {role !== 'accountant' && (
                  <>
                    <col style={{ width: 150 }} />
                    <col style={{ width: 150 }} />
                    <col style={{ width: 150 }} />
                    <col style={{ width: 120 }} />
                  </>
                )}
                <col style={{ width: 120 }} />
                <col style={{ width: 160 }} />
                <col style={{ width: 120 }} />
                <col style={{ width: 120 }} />
                <col style={{ width: 120 }} />
                <col style={{ width: 120 }} />
                {role !== 'accountant' && (
                  <>
                    <col style={{ width: 100 }} />
                    <col style={{ width: 320 }} />
                  </>
                )}
                <col style={{ width: 100 }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 120 }} />
                <col style={{ width: 100 }} />
                <col style={{ width: 80 }} />
                <col style={{ width: 120 }} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  {role !== 'accountant' && (
                    <>
                      <th scope="col">{T.Date}</th>
                      <th scope="col">{T.phone}</th>
                      <th scope="col">{T.phone} 2</th>
                      <th scope="col">{T.Region}</th>
                    </>
                  )}
                  <th scope="col">{T.type}</th>
                  <th scope="col">{T.domain}</th>
                  <th scope="col">{T.code}</th>
                  <th scope="col">{T.Sold_At}</th>
                  <th scope="col">{T.Return_Change_At}</th>
                  <th scope="col">{T.Archived_At}</th>
                  {role !== 'accountant' && (
                    <>
                      <th scope="col">{T.Device}</th>
                      <th scope="col">URL</th>
                    </>
                  )}
                  <th scope="col"> </th>
                  <th scope="col">{T.payment_type}</th>
                  <th scope="col">{T.rejected_in_place_price}</th>
                  <th scope="col">{T.Delivery_price}</th>
                  <th scope="col">{T.Total_Price}</th>
                  <th scope="col">{T.status}</th>
                </tr>
              </thead>
              {total > 0 && (
                <tbody>
                  {data?.map((item) => {
                    return (
                      <Fragment key={item.id}>
                        <Row item={item} />
                      </Fragment>
                    );
                  })}
                </tbody>
              )}
            </table>
            {total === 0 && (
              <p className="no-data-tag">
                <NoDataSvg />
                {T.no_data_found}
              </p>
            )}
          </div>
        </div>
        {total > 0 && (
          <div className={classNames.allPagination}>
            <Pagination onChange={changePage} totalPages={pagesCount} defaultPage={page} mode="products" />
            <div className={classNames.ProductsSeries}>
              <div className={classNames.ProductsSeriesItem}>
                <p>{T.row}</p>
                <div className={classNames.ProductsSeriesItemSelect}>
                  <Select
                    placement="top"
                    menuPlacement="top"
                    options={limitOptions}
                    onChange={changeLimit}
                    placeholder=""
                    value={{ value: limit, label: limit }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpecialProductPage;
