import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModalsComponents } from './helpers';
import { selectModals } from '../store/modals/selectors';
import { closeModal } from '../store/modals/actions';

export const useModals = () => {
  const modalsStatuses = useSelector(selectModals);
  const dispatch = useDispatch();
  const onClose = useCallback((event, extraName) => {
    const name = extraName || event.target.closest('[data-name]').getAttribute('data-name');

    dispatch(closeModal({ name }));
  }, []);
  const modalsComponents = useMemo(() => getModalsComponents(), []);
  const haseOpenedModal = useMemo(() => {
    if (!modalsStatuses) return false;
    return Object.values(modalsStatuses).reduce((acc, item) => {
      return acc || !!item.opened;
    }, false);
  }, [modalsStatuses]);

  useEffect(() => {
    document.body.style.overflow = haseOpenedModal ? 'visible' : '';
    const mainPage = document.querySelector('.page');
    const createEditModal = document.querySelector('.board-bg-color');
    if (mainPage) {
      mainPage.style.overflow = haseOpenedModal ? 'visible' : '';
    }
    if (createEditModal) {
      createEditModal.querySelector('#root').style.overflow = haseOpenedModal ? '' : '';
    }
  }, [haseOpenedModal]);

  return { modalsComponents, modalsStatuses, onClose };
};
