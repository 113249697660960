import { handleActions } from 'redux-actions';
import { setPermisions } from './actions';

const initialState = {
  permissions: [],
  mainPermissions: [],
};

const permisions = handleActions(
  {
    [setPermisions.success]: (_state, { payload }) => ({
      permissions: payload,
    }),
  },

  initialState
);

export default permisions;
