import { createCombinedAction } from '../helpers';

export const downloadReports = createCombinedAction({
  name: 'downloadReports',
  prefix: 'reports@@',
});

export const getOperatorsOrDeliveryMans = createCombinedAction({
  name: 'getOperatorsOrDeliveryMans',
  prefix: 'reports@@',
});
