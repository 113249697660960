import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectArchiveListItem,
  selectCopyListItem,
  selectRemoveListItem,
} from '../../../../store/draggable-board/selector';

export const useEdit = ({ id }, active) => {
  const { loading: copyLoading } = useSelector(selectCopyListItem(id));
  const { loading: removeLoading } = useSelector(selectRemoveListItem(id));
  const { loading: archiveLoading } = useSelector(selectArchiveListItem(id));
  const loading = useMemo(
    () => copyLoading || removeLoading || archiveLoading,
    [copyLoading, removeLoading, archiveLoading]
  );
  useEffect(() => {
    document.body.style.overflow = active ? 'hidden' : '';
    const mainPage = document.querySelector('.page');
    if (mainPage) {
      mainPage.style.overflow = active ? 'hidden' : '';
    }
  }, [active]);

  return { loading };
};
