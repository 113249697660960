import { useCallback, useState } from 'react';

const useSearch = ({ timer, onChange, setSearch }) => {
  const [value, setValue] = useState('');
  const [timerId, setTimerId] = useState();

  const handleChange = useCallback(
    ({ target: { value } }) => {
      setValue(value);
      if (timerId) {
        clearTimeout(timerId);
      }
      setTimerId(
        setTimeout(() => {
          onChange(value);
        }, timer)
      );
    },
    [timerId]
  );

  const handleResetSearch = useCallback(() => {
    setValue('');
    setSearch('');
  }, []);

  return { value, handleChange, handleResetSearch };
};
export default useSearch;
