export const axiosData = {
  getStickers: {
    url: '/api/admin/boards/:id/labels',
    method: 'get',
  },
  createSticker: {
    url: '/api/admin/labels',
    method: 'post',
  },
  editSticker: {
    url: '/api/admin/labels/:id',
    method: 'put',
  },
  removeSticker: {
    url: '/api/admin/labels/:id',
    method: 'delete',
  },
};
