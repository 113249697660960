import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../../store/modals/actions';
import { modalNames } from '../../../../store/modals/constants';
import { getWorkerSalary, payWorkerSalary } from '../../../../store/workers/actions';
import { selectPaySalary, selectWorkerSalary } from '../../../../store/workers/selectors';
import { Lang } from '../../../../T';
import { selectLang } from '../../../../store/user/selectors';

export const useSalary = (userId) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  // useDispatch
  const dispatch = useDispatch();
  // useState
  const [bonusValue, setBonusValue] = useState({ defaultValue: '' });
  // useSelector
  const { data: salary, loading: salaryLoading } = useSelector(selectWorkerSalary(userId));
  const { success } = useSelector(selectPaySalary(userId));
  // const {success:countSuccess} =useSelector(selectCountedSalary(userId,))
  const countSuccess = false;
  // today
  const salaryDate = new Date();
  const year = salaryDate.getFullYear();
  const month = salaryDate.getMonth() + 1;
  const [startDate, setStartDate] = useState(new Date());
  const filteredYear = startDate ? startDate.getFullYear() : '';
  const isOverYear = filteredYear > new Date().getFullYear();
  useEffect(() => {
    setBonusValue({ defaultValue: '' });
  }, [startDate]);

  useEffect(() => {
    dispatch(getWorkerSalary({ id: userId, year: filteredYear }));
  }, [startDate, userId]);

  useEffect(() => {
    if (Array.isArray(success) && success?.length) {
      dispatch(getWorkerSalary({ id: userId, year: filteredYear }));
    }
  }, [success]);

  // func
  const handlePaySalary = (inputId, payMonth) => {
    const splitedMonth = payMonth[0] === '0' ? +payMonth[1] : payMonth;
    dispatch(
      payWorkerSalary({
        id: userId,
        data: {
          month: splitedMonth,
          year: filteredYear,
          bonus: +!bonusValue[inputId] ? 0 : +bonusValue[inputId],
        },
      })
    );
  };

  const handleBonusChange = ({ target: { value } }, id) => {
    setBonusValue((prev) => {
      return { ...prev, [id]: value };
    });
  };

  const handlePay = (inputId, payMonth, firstName, lastName) => {
    dispatch(
      openModal({
        name: modalNames.confirmation,
        data: {
          title: T.pay_salary,
          okText: T.to_pay,
          description: `${T.are_you_sure_to_pay_salary} ${firstName} ${lastName}`,
          onOk: () => {
            handlePaySalary(inputId, payMonth, filteredYear);
          },
        },
      })
    );
  };

  return {
    handlePaySalary,
    bonusValue,
    handleBonusChange,
    salary,
    salaryLoading,
    month,
    success,
    handlePay,
    year,
    startDate,
    setStartDate,
    countSuccess,
    isOverYear,
  };
};
