import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
  getSelectableBoards,
  createBoard,
  getBoards,
  getByIdBoard,
  editBoard,
  deleteBoardById,
  getStatisticById,
} from './actions';

// editBoard
const creteInitialState = {
  loading: false,
  success: false,
};

const create = handleActions(
  {
    [createBoard]: () => ({
      loading: true,
      error: false,
    }),
    [createBoard.error]: () => ({
      loading: false,
      error: true,
    }),
    [createBoard.success]: () => ({
      loading: false,
      error: false,
    }),
  },
  creteInitialState
);

const editInitialState = {
  loading: false,
  success: false,
};

const edit = handleActions(
  {
    [editBoard]: () => ({
      loading: true,
      error: false,
    }),
    [editBoard.error]: () => ({
      loading: false,
      error: true,
    }),
    [editBoard.success]: () => ({
      loading: false,
      error: false,
    }),
  },
  editInitialState
);

const getByIdInitialState = {
  loading: false,
  success: false,
  data: null,
};

const getById = handleActions(
  {
    [getByIdBoard]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [getByIdBoard.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [getByIdBoard.reset]: () => ({
      loading: false,
      error: false,
      data: null,
    }),
    [getByIdBoard.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      data,
    }),
  },
  getByIdInitialState
);

const cetInitialState = {
  loading: false,
  success: false,
  data: [],
};

const get = handleActions(
  {
    [getBoards]: () => ({
      loading: true,
      error: false,
      data: [],
    }),
    [getBoards.error]: () => ({
      loading: false,
      error: true,
      data: [],
    }),
    [getBoards.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      data,
    }),
    [getBoards.reset]: () => ({ loading: false, success: false, data: [] }),
  },
  cetInitialState
);
const deleteInitialState = {
  loading: false,
  success: false,
  error: false,
};

const deleteBoard = handleActions(
  {
    [deleteBoardById]: () => ({
      loading: true,
      error: false,
      success: false,
    }),
    [deleteBoardById.error]: () => ({
      loading: false,
      error: true,
      success: false,
    }),
    [deleteBoardById.success]: () => ({
      loading: false,
      error: false,
      success: true,
    }),
  },
  deleteInitialState
);

const selectableBoardsInitialState = {
  loading: false,
  error: false,
  data: [],
};

// getSelectableBoards
const selectableBoards = handleActions(
  {
    [getSelectableBoards]: () => ({
      loading: true,
      error: false,
      data: [],
    }),
    [getSelectableBoards.error]: () => ({
      loading: false,
      error: true,
      data: [],
    }),
    [getSelectableBoards.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      data,
    }),
    [getSelectableBoards.reset]: () => ({
      loading: false,
      success: false,
      data: [],
    }),
  },
  selectableBoardsInitialState
);

const statisticInitialState = {
  loading: false,
  success: false,
  data: [],
};

const getStatistic = handleActions(
  {
    [getStatisticById]: () => ({
      loading: true,
      error: false,
    }),
    [getStatisticById.error]: () => ({
      loading: false,
      error: true,
    }),
    [getStatisticById.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      data,
    }),
  },
  statisticInitialState
);

export default combineReducers({
  create,
  get,
  selectableBoards,
  getById,
  edit,
  deleteBoard,
  getStatistic,
});
