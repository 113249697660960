import React from 'react';
import PropTypes from 'prop-types';
import { useEditInput } from './useEditInput';

const EditInput = ({ value, onSave, className, ...rest }) => {
  const { localValue, handleChange, handleSave } = useEditInput({ value, onSave });
  return (
    <input
      /* eslint-disable-next-line jsx-a11y/no-autofocus */
      autoFocus
      className={className}
      {...rest}
      value={localValue}
      onChange={handleChange}
      onKeyPress={(e) => e.key === 'Enter' && handleSave()}
      onBlur={handleSave}
    />
  );
};

EditInput.propTypes = {
  value: PropTypes.string,
  onSave: PropTypes.func,
  className: PropTypes.string,
};
export default EditInput;
