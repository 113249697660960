import { createCombinedAction } from '../helpers';

export const downLoadCSF = createCombinedAction({
  name: 'downLoadCSF',
  prefix: 'downLoadCSF@@',
});

export const referForDownloadAction = createCombinedAction({
  name: 'referForDownloadAction',
  prefix: 'referForDownloadAction@@',
});

export const downLoadBuyer = createCombinedAction({
  name: 'downLoadBuyer',
  prefix: 'downLoadBuyer@@',
});
