import { func, number, string } from 'prop-types';
import React from 'react';
import { useMemo } from 'react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import Pagination from '../../../../../../components/pagination/Pagination';
import { selectWarehouseData } from '../../../../../../store/warehouse/selector';
import { Lang } from '../../../../../../T';
import Row from '../row/Row';
import { limitOptions } from '../../../../constants';
import classNames from './table.module.scss';
import { selectLang, selectUserRole } from '../../../../../../store/user/selectors';
import NoDataSvg from '../../../../../../svgs/NoDataSvg';
import { FilterIcoBack } from '../../../../../products/components/filterIcon';

const Table = ({
  page,
  limit,
  changePage,
  changeLimit,
  pagesCount,
  type,
  setFilterCount,
  filterCount,
  setFilterDefect,
  filterDefect,
  filterPrice,
  setFilterPrice,
  filterWeight,
  setFilterWeight,
  filterDate,
  setFilterDate,
}) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { data, total } = useSelector(selectWarehouseData);
  const role = useSelector(selectUserRole);

  const isAccountant = useMemo(() => role === 'accountant', [role]);
  const isMarketing = useMemo(() => role === 'marketingExpert', [role]);
  const hasPermission = useMemo(() => role === 'admin' || role === 'director', [role]);

  const onResetOtherFilters = (mode) => {
    setFilterCount((prev) => (mode === 'count' ? prev : 0));
    setFilterPrice((prev) => (mode === 'price' ? prev : 0));
    setFilterWeight((prev) => (mode === 'weight' ? prev : 0));
    setFilterDefect((prev) => (mode === 'defect' ? prev : 0));
    setFilterDate((prev) => (mode === 'date' ? prev : 0));
  };

  return (
    <div className="container ml-0 maxw-100">
      <div className={classNames.table}>
        <div className={classNames.tableWrapper}>
          <table>
            <colgroup>
              {/* id */}
              <col style={{ width: 110 }} />
              {/* name */}
              <col style={{ width: !isAccountant ? 245 : 400 }} />
              {/* date */}
              {!isAccountant && <col style={{ width: 128 }} />}
              {/* code */}
              <col style={{ width: 110 }} />
              {/* real/test */}
              {!isAccountant && !type && <col style={{ width: 80 }} />}
              {/* real count */}
              <col style={{ width: 110 }} />
              {/* defect count */}
              {!isMarketing && <col style={{ width: 100 }} />}
              {/* defect percent */}
              {!isAccountant && !isMarketing && <col style={{ width: 95 }} />}
              {/* marketing count */}
              {!isAccountant && <col style={{ width: 110 }} />}
              {/* weight */}
              {!isAccountant && !isMarketing && hasPermission && <col style={{ width: 80 }} />}
              {/* price */}
              {!isAccountant && <col style={{ width: 70 }} />}
              {/* import button  */}
              {!isMarketing && <col style={{ width: 100 }} />}
              {/* defect button */}
              {!isAccountant && !isMarketing && <col style={{ width: 120 }} />}
              {/* check button */}
              {!isAccountant && !isMarketing && <col style={{ width: 160 }} />}
            </colgroup>
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">{T.product_name}</th>
                {!isAccountant && (
                  <th scope="col">
                    <span>
                      {T.date}
                      <button type="button" style={{ border: 'none', outline: 'none' }}>
                        <FilterIcoBack
                          setFilterMode={setFilterDate}
                          filterMode={filterDate}
                          onResetOtherFilters={() => onResetOtherFilters('date')}
                        />
                      </button>
                    </span>
                  </th>
                )}
                <th scope="col">{T.code}</th>
                {!isAccountant && !type && (
                  <th scope="col">
                    {T.real} /<br />
                    {T.test}
                  </th>
                )}
                <th scope="col">
                  <span>
                    {T.real_remainder}
                    <button type="button" style={{ border: 'none', outline: 'none' }}>
                      <FilterIcoBack
                        setFilterMode={setFilterCount}
                        filterMode={filterCount}
                        onResetOtherFilters={() => onResetOtherFilters('count')}
                      />
                    </button>
                  </span>
                </th>
                {!isMarketing && (
                  <th scope="col">
                    <span>
                      {T.defect}
                      <button type="button" style={{ border: 'none', outline: 'none' }}>
                        <FilterIcoBack
                          setFilterMode={setFilterDefect}
                          filterMode={filterDefect}
                          onResetOtherFilters={() => onResetOtherFilters('defect')}
                        />
                      </button>
                    </span>
                  </th>
                )}
                {!isAccountant && !isMarketing && <th scope="col">{T.defect} %</th>}
                {(hasPermission || isMarketing) && (
                  <th scope="col">
                    {T.marketing}
                    <br />
                    {T.remainder}
                  </th>
                )}
                {!isAccountant && !isMarketing && (
                  <th scope="col">
                    <span>
                      {T.weight}
                      <button type="button" style={{ border: 'none', outline: 'none' }}>
                        <FilterIcoBack
                          setFilterMode={setFilterWeight}
                          filterMode={filterWeight}
                          onResetOtherFilters={() => onResetOtherFilters('weight')}
                        />
                      </button>
                    </span>
                  </th>
                )}
                {!isAccountant && (
                  <th scope="col">
                    <span>
                      {T.price}
                      <button type="button" style={{ border: 'none', outline: 'none' }}>
                        <FilterIcoBack
                          setFilterMode={setFilterPrice}
                          filterMode={filterPrice}
                          onResetOtherFilters={() => onResetOtherFilters('price')}
                        />
                      </button>
                    </span>
                  </th>
                )}
                {!isMarketing && (
                  <th scope="col" className={!isAccountant && !isMarketing ? classNames.noaccountant : ''}>
                    {T.import}
                  </th>
                )}
                {!isAccountant && !isMarketing && (
                  <>
                    <th scope="col" className={!isAccountant && !isMarketing ? classNames.noaccountant : ''}>
                      {T.defect} <br /> {T.export}
                    </th>
                    <th scope="col" className={!isAccountant && !isMarketing ? classNames.noaccountant : ''}>
                      {T.of_product} <br /> {T.check}
                    </th>
                  </>
                )}
              </tr>
            </thead>
            {total > 0 && (
              <tbody>
                {data?.map((item) => {
                  return (
                    <Fragment key={item.id}>
                      <Row item={item} type={type} />
                    </Fragment>
                  );
                })}
              </tbody>
            )}
          </table>
          {total === 0 && (
            <p className="no-data-tag">
              <NoDataSvg />
              {T.no_data_found}
            </p>
          )}
        </div>
      </div>
      {total > 0 && (
        <div className={classNames.allPagination}>
          <Pagination onChange={changePage} totalPages={pagesCount} defaultPage={page} mode="products" />
          <div className={classNames.ProductsSeries}>
            <div className={classNames.ProductsSeriesItem}>
              <p>{T.row}</p>
              <div className={classNames.ProductsSeriesItemSelect}>
                <Select
                  placement="top"
                  menuPlacement="top"
                  options={limitOptions}
                  onChange={changeLimit}
                  placeholder=""
                  value={{ value: limit, label: limit }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Table.propTypes = {
  page: number,
  changePage: func,
  limit: number,
  changeLimit: func,
  type: string,
  pagesCount: number,
  setFilterCount: func,
  filterCount: number,
  setFilterDefect: func,
  filterDefect: number,
  filterPrice: number,
  setFilterPrice: func,
  filterWeight: number,
  setFilterWeight: func,
  filterDate: number,
  setFilterDate: func,
};
export default Table;
