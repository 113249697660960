import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Thumb from './components/thumb/Thumb';
import classNames from './upload.module.scss';

const Upload = ({ onChange, file = null, children }) => {
  const inputRef = useRef();
  return (
    <div>
      <input
        ref={inputRef}
        id="file"
        name="file"
        type="file"
        onChange={(event) => {
          // setFieldValue("file", event.currentTarget.files[0]);
          const file = event.currentTarget.files[0];
          if (file) onChange(file, event);
        }}
        className={`form-control ${children ? 'd-none' : ''}`}
      />
      <div className={classNames.UplaodItem}>
        <div className={classNames.uploadedItem}>
          {file ? (
            <Thumb file={file} />
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="40.684" height="40.686" viewBox="0 0 40.684 40.686">
              <path
                d="M16.25,35.27l-5.871,3.505a5.31,5.31,0,0,0-.942.734,18.38,18.38,0,0,0,25.265.072,5.2,5.2,0,0,0-1.035-.759l-6.287-3.44a2.66,2.66,0,0,1-1.325-2.347v-2.7c.177-.22.379-.5.595-.837A16.48,16.48,0,0,0,28.6,25.186,2.145,2.145,0,0,0,30,23.13V20.248a2.245,2.245,0,0,0-.658-1.6V14.484S30.128,8,22.107,8s-7.239,6.483-7.239,6.483v4.166a2.241,2.241,0,0,0-.658,1.6V23.13a2.215,2.215,0,0,0,.91,1.813,14.823,14.823,0,0,0,2.38,5.391v2.633A2.674,2.674,0,0,1,16.25,35.27Z"
                transform="translate(-1.767 -3.889)"
                fill="#fff"
              />
              <g transform="translate(0 0)">
                <path
                  d="M20.691,0A20.272,20.272,0,0,0,0,19.841a20.061,20.061,0,0,0,7.25,15.778,5.405,5.405,0,0,1,.965-.681l6.07-3.285a2.458,2.458,0,0,0,1.292-2.16V27.025a13.355,13.355,0,0,1-2.461-5.053,2.022,2.022,0,0,1-.941-1.7v-2.7a2.01,2.01,0,0,1,.68-1.5v-3.9s-.808-6.076,7.485-6.076,7.485,6.076,7.485,6.076v3.9a2.007,2.007,0,0,1,.68,1.5v2.7A2.028,2.028,0,0,1,27.059,22.2a14.8,14.8,0,0,1-2.02,4.04,9.753,9.753,0,0,1-.615.785v2.53a2.457,2.457,0,0,0,1.37,2.2l6.5,3.224a5.415,5.415,0,0,1,1.067.71A20.147,20.147,0,0,0,20.691,0Z"
                  transform="translate(0 0)"
                  fill="#a6a6ab"
                />
              </g>
            </svg>
          )}
        </div>
        {children ? (
          <p role="presentation" onClick={() => inputRef.current.click()}>
            {children}
          </p>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

Upload.propTypes = {
  onChange: PropTypes.func,
  file: PropTypes.any,
  children: PropTypes.node,
};

export default Upload;
