import React from 'react';
import { object } from 'prop-types';
import { useSelector } from 'react-redux';
import useRow from './useRow';
import classNames from './row.module.scss';
import { dateOption, defaultProductPicture } from '../../../../../../constants/constants';
import useComments from '../../../../../../hooks/useComments';
import { Lang } from '../../../../../../T';
import { selectLang } from '../../../../../../store/user/selectors';

const Row = ({ item }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { id, user, createdAt, value, product, updatedAt, descriptionsCount } = item;
  const { photos } = product;
  const { url } = photos[0];
  const { onSubmit, onReject } = useRow(id, updatedAt, product, value);
  const { openCommentModal } = useComments(url);
  return (
    <tr className={classNames.trItem}>
      <td className={classNames.tdItem}>
        <p>{id}</p>
      </td>
      <td className={classNames.tdItem}>
        <div className={classNames.imageWrapper}>
          <div className={classNames.picturePopUpContainer}>
            <img
              width={34}
              height={34}
              src={
                url && url !== '/productPictures/null'
                  ? `${process.env.REACT_APP_BASE_ENDPOINT}${url}`
                  : defaultProductPicture
              }
              alt="img"
            />
            <div className={classNames.picturePopUp}>
              <p>
                <img
                  src={
                    url && url !== '/productPictures/null'
                      ? `${process.env.REACT_APP_BASE_ENDPOINT}${url}`
                      : defaultProductPicture
                  }
                  alt="img"
                />
              </p>
            </div>
          </div>
          <div className={classNames.textItem}>
            <p>{product.name}</p>
            <p className={classNames.popupName}>{product.name}</p>
          </div>
        </div>
      </td>
      <td className={classNames.tdItem}>
        <p>{new Date(createdAt).toLocaleString('ru', dateOption)}</p>
      </td>
      <td className={classNames.tdItem}>
        <p>{product.barcode}</p>
      </td>
      <td className={classNames.tdItem}>
        <p>{`${user?.firstName} ${user?.lastName}`}</p>
      </td>
      <td className={classNames.tdItem}>
        <p>{value}</p>
      </td>
      <td className={classNames.tdItem}>
        <p
          className={classNames.commentLine}
          role="presentation"
          onClick={(e) => openCommentModal(e, item)}
          data-id={id}
        >
          {T.comment} {`(${descriptionsCount})`}
        </p>
      </td>
      <td className={classNames.tdItem}>
        <button type="button" onClick={onSubmit} className={classNames.acceptBtn}>
          {T.approve}
        </button>
        <button type="button" onClick={onReject} className={classNames.cancelBtn}>
          {T.reject}
        </button>
      </td>
    </tr>
  );
};

Row.propTypes = {
  item: object,
};

export default Row;
