import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
  editProfile,
  login,
  logout,
  sendResetPasswordToMail,
  sendConfirmedPasswords,
  setInitialFetching,
  getUserByPhone,
  getUserOrdersByPhone,
  getUserRatesByPhone,
  getUserAddressesByPhone,
  useInfo,
  saveUserData,
  setFormValues,
  setLang,
  setNavbarState,
} from './actions';
import { getUserData } from './helpers';

const langInitialState = { lang: 'en' };
const lang = handleActions({ [setLang]: (_, { payload }) => ({ lang: payload }) }, langInitialState);

const navbarInitialState = { open: false };
const navbarState = handleActions({ [setNavbarState]: (_, { payload }) => ({ open: payload }) }, navbarInitialState);

const getInitialState = {
  isFetched: false,
  isAuthenticated: false,
  loading: false,
  isAdmin: false,
  name: '',
  email: '',
};

const get = handleActions(
  {
    [setInitialFetching]: (state, { payload: { isAuthenticated, isAdmin = false, ...rest } }) => ({
      ...state,
      isFetched: true,
      isAdmin,
      isAuthenticated: !!isAuthenticated,
      ...rest,
    }),

    [login]: (state) => ({ ...state, loading: true, error: false }),
    [login.success]: (state, { payload = {} } = {}) => ({
      ...state,
      loading: false,
      error: false,
      isAuthenticated: true,
      ...payload,
    }),
    [logout]: (state) => ({ ...state, isAuthenticated: false, isAdmin: false }),
    [login.error]: (state) => ({ ...state, loading: false, error: true }),
  },
  getInitialState
);

const editInitialState = {
  loading: false,
  error: false,
};

const edit = handleActions(
  {
    [editProfile]: (state) => ({ ...state, loading: true, error: false }),
    [editProfile.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
    }),
    [editProfile.error]: (state) => ({ ...state, loading: false, error: true }),
    [editProfile.reset]: (state) => ({ ...state, loading: false, error: false }),
  },
  editInitialState
);

const postToEmailInitialState = {
  loading: false,
  error: false,
  success: false,
};

const postToMail = handleActions(
  {
    [sendResetPasswordToMail]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [sendResetPasswordToMail.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [sendResetPasswordToMail.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
  },
  postToEmailInitialState
);

const postConfirmedInitialState = {
  loading: false,
  error: false,
  success: false,
};

const postConfirmedPassword = handleActions(
  {
    [sendConfirmedPasswords]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [sendConfirmedPasswords.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [sendConfirmedPasswords.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
  },
  postConfirmedInitialState
);

const userByPhoneInitialState = {
  loading: false,
  error: false,
  success: false,
  data: {},
};
const userByPhone = handleActions(
  {
    [getUserByPhone]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [getUserByPhone.success]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
      data: payload.data,
    }),
    [getUserByPhone.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
      data: {},
    }),
    [getUserByPhone.reset]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: false,
      data: {},
    }),
  },
  userByPhoneInitialState
);

const userOrdersByPhoneInitialState = {
  loading: false,
  error: false,
  success: false,
  data: [],
  end: false,
};
const userOrdersByPhone = handleActions(
  {
    [getUserOrdersByPhone]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
      end: false,
    }),
    [getUserOrdersByPhone.success]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
      data: [...state.data, ...payload.data.rows],
      end: !payload?.data?.rows?.length,
    }),
    [getUserOrdersByPhone.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
      data: [],
      end: false,
    }),
    [getUserOrdersByPhone.reset]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: false,
      data: [],
      end: false,
    }),
  },
  userOrdersByPhoneInitialState
);

const userRatesByPhoneInitialState = {
  loading: false,
  error: false,
  success: false,
  data: {},
};
const userRatesByPhone = handleActions(
  {
    [getUserRatesByPhone]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [getUserRatesByPhone.success]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
      data: payload.data,
    }),
    [getUserRatesByPhone.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
      data: {},
    }),
    [getUserRatesByPhone.reset]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: false,
      data: {},
    }),
  },
  userRatesByPhoneInitialState
);

const userAddressesByPhoneInitialState = {
  loading: false,
  error: false,
  success: false,
  data: {},
};
const userAddressesByPhone = handleActions(
  {
    [getUserAddressesByPhone]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [getUserAddressesByPhone.success]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
      data: payload.data,
    }),
    [getUserAddressesByPhone.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
      data: {},
    }),
    [getUserAddressesByPhone.reset]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: false,
      data: {},
    }),
  },
  userAddressesByPhoneInitialState
);

const canUse = {
  canUse: false,
};
const useUserInfo = handleActions(
  {
    [useInfo.success]: (state, { payload: { canUse } }) => ({
      ...state,
      canUse,
    }),

    [useInfo.reset]: (state) => ({
      ...state,
      canUse: false,
    }),
  },
  canUse
);

const useData = {
  useData: null,
};

const userData = handleActions(
  {
    [saveUserData.success]: (state, { payload }) => {
      return {
        ...state,
        useData: getUserData(payload),
      };
    },
    // todo really it is not error it is edit
    [saveUserData.error]: (state, { payload }) => {
      return {
        ...state,
        useData: { ...state.useData, ...payload },
      };
    },
    [saveUserData.reset]: (state) => ({
      ...state,
      useData: null,
    }),
  },
  useData
);

const formValuesInitial = {
  formValues: {},
};
const formValues = handleActions(
  {
    [setFormValues]: (state, { payload }) => {
      return {
        ...state,
        formValues: payload,
      };
    },

    [setFormValues.reset]: (state) => ({
      ...state,
      formValues: {},
    }),
  },
  formValuesInitial
);

export default combineReducers({
  navbarState,
  lang,
  get,
  edit,
  postToMail,
  postConfirmedPassword,
  userByPhone,
  userOrdersByPhone,
  userRatesByPhone,
  userAddressesByPhone,
  useUserInfo,
  userData,
  formValues,
});
