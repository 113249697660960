import React from 'react';

const TableDots = () => {
  return (
    <svg width="5" height="16" viewBox="0 0 5 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.85938 4C3.96394 4 4.85938 3.10457 4.85938 2C4.85938 0.89543 3.96394 0 2.85938 0C1.75481 0 0.859375 0.89543 0.859375 2C0.859375 3.10457 1.75481 4 2.85938 4Z"
        fill="#252733"
      />
      <path
        d="M2.85938 10C3.96394 10 4.85938 9.10457 4.85938 8C4.85938 6.89543 3.96394 6 2.85938 6C1.75481 6 0.859375 6.89543 0.859375 8C0.859375 9.10457 1.75481 10 2.85938 10Z"
        fill="#252733"
      />
      <path
        d="M2.85938 16C3.96394 16 4.85938 15.1046 4.85938 14C4.85938 12.8954 3.96394 12 2.85938 12C1.75481 12 0.859375 12.8954 0.859375 14C0.859375 15.1046 1.75481 16 2.85938 16Z"
        fill="#252733"
      />
    </svg>
  );
};

export default TableDots;
