import { array, bool, func, object, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import useConstant from '../../constants';
import Switch from '../../../../components/switch/Switch';
import FilterTriangleSvg from '../../../../svgs/FilterTriangleSvg';
import FilterTriangleCrossedSvg from '../../../../svgs/FilterTriangleCrossedSvg';

import Select from '../../../../components/select/Select';
import MultiSelect from '../../../../components/multi-select/MultiSelect';
import TreeSelect from '../../../../components/tree-select/TreeSelect';

import classNames from './filters.module.scss';
import useFilters from './useFilters';
import { selectLang } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';

const Filters = ({
  filter,
  setFilter,
  handlePeriodChange,
  productOptions,
  handleSearch,
  urlsOptions,
  handleUrlChange,
  handleUrlSearch,
  domainOptions,
  handleDomainChange,
  handleRegionChange,
  handleDeviceChange,
  handlePercentageTypeChange,
  isResetDisabled,
  handleReset,
  preset,
  setPreset,
  presetList,
  setPresetList,
  handleChooseUtmKey,
  handleUtmChange,
  utmValueList,
  getMoreUtm,
  withPersentage = false,
}) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const { period, productIds, ignoreOutOfInterval, domainIds, urls, region, device, utm, percentageType } = filter;
  const { deviceOptions, percentageTypeOptions, periodOptions, regionOptions, utmOptions } = useConstant();
  const { role, filterView, toggleFilterView, handleCreatePreset, choosePreset, deletePreset, changed } = useFilters({
    filter,
    handleReset,
    preset,
    setPreset,
    presetList,
    setPresetList,
  });

  return (
    <div className="container maxw-100">
      <div className={classNames.filterContent}>
        <div className={classNames.filterViewed}>
          <div className={classNames.period}>
            <Select
              options={periodOptions}
              onChange={(e) => {
                handlePeriodChange(e);
                if (preset !== null && !filterView) {
                  toggleFilterView();
                }
              }}
              label={T.Date}
              value={period}
              placeholder=""
              widthDate
            />
          </div>

          <div className={classNames.crmProduct}>
            <MultiSelect
              label={T.CRM_Products}
              options={productOptions}
              onChange={(selected) => {
                setFilter((prev) => ({ ...prev, productIds: selected }));
                if (preset !== null && !filterView) {
                  toggleFilterView();
                }
              }}
              value={productIds}
              isSearchable
              onSearch={handleSearch}
              placeholder={T.crmProducts}
            />
          </div>
          {role !== 'accountant' && (
            <>
              <div className={classNames.productUrl}>
                <MultiSelect
                  options={urlsOptions}
                  onChange={(e) => {
                    handleUrlChange(e);
                    if (preset !== null && !filterView) {
                      toggleFilterView();
                    }
                  }}
                  label={T.Store_Products}
                  value={urls}
                  isSearchable
                  onSearch={handleUrlSearch}
                  placeholder={T.crmProducts}
                />
              </div>

              <div className={classNames.ignoreOutOfIntervalField}>
                <span className={classNames.label}>{T.Ignore_out_of_interval}</span>
                <Switch
                  disabled={withPersentage && percentageType.value === 'cr'}
                  checked={ignoreOutOfInterval}
                  isFormik={false}
                  inputProps={{
                    onChange: (e) => {
                      setFilter((prev) => ({ ...prev, ignoreOutOfInterval: e.target.checked }));
                      if (preset !== null && !filterView) {
                        toggleFilterView();
                      }
                    },
                  }}
                />
              </div>
            </>
          )}
          {role !== 'accountant' && (
            <div className={classNames.filterButton} onClick={toggleFilterView}>
              {filterView ? <FilterTriangleCrossedSvg /> : <FilterTriangleSvg />}
            </div>
          )}
          {!isResetDisabled ? (
            <button className={classNames.resetFilter} type="button" disabled={isResetDisabled} onClick={handleReset}>
              {T.reset_filter}
            </button>
          ) : null}
        </div>
        {role !== 'accountant' && filterView && (
          <div className={classNames.filterHidden}>
            <div className={classNames.domain}>
              <MultiSelect
                options={domainOptions}
                onChange={handleDomainChange}
                value={domainIds}
                label={T.Brand}
                placeholder={T.All_domains}
                className={classNames.period}
              />
            </div>
            <div className={classNames.domain}>
              <Select
                label={T.Region}
                options={regionOptions}
                onChange={handleRegionChange}
                value={region}
                placeholder={T.All_regions}
                className={classNames.region}
              />
            </div>
            <div className={classNames.domain}>
              <Select
                label={T.Device}
                options={deviceOptions}
                onChange={handleDeviceChange}
                value={device}
                placeholder={T.All_sources}
                className={classNames.region}
              />
            </div>
            {withPersentage && (
              <div className={classNames.domain}>
                <Select
                  label={T.Percentage_Type}
                  options={percentageTypeOptions}
                  onChange={handlePercentageTypeChange}
                  value={percentageType}
                  placeholder=""
                  className={classNames.region}
                />
              </div>
            )}
            <div className={classNames.domain}>
              <TreeSelect
                label="UTM"
                options={utmOptions}
                subMenuOptions={utmValueList}
                onChange={handleUtmChange}
                value={utm}
                onLoadMore={getMoreUtm}
                onSubMenuOpen={handleChooseUtmKey}
                placeholder={T.Select_UTM}
              />
            </div>
            <button
              className={`${classNames.createPreset} ${changed ? classNames.changed : ''}`}
              type="button"
              onClick={handleCreatePreset}
              disabled={changed}
            >
              {preset ? (!changed ? T.edit : T.changed) : T.create}
            </button>
          </div>
        )}
        <div className={classNames.presetList}>
          {presetList.map((presetItem) => (
            <div
              key={crypto.randomUUID()}
              className={`${classNames.preset} ${preset === presetItem && classNames.active}`}
            >
              <div className={classNames.presetName} onClick={choosePreset(presetItem)}>
                {presetItem}
              </div>
              <button type="button" className={classNames.deletePreset} onClick={deletePreset(presetItem)}>
                x
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Filters.propTypes = {
  filter: object,
  setFilter: func,
  handlePeriodChange: func,
  productOptions: array,
  handleSearch: func,
  urlsOptions: array,
  handleUrlChange: func,
  handleUrlSearch: func,
  domainOptions: array,
  handleDomainChange: func,
  handleRegionChange: func,
  handleDeviceChange: func,
  isResetDisabled: bool,
  handleReset: func,
  // startDate,
  // setStartDate,
  // endDate,
  // setEndDate,
  preset: string,
  setPreset: func,
  presetList: array,
  setPresetList: func,
  handleChooseUtmKey: func,
  handleUtmChange: func,
  utmValueList: array,
  getMoreUtm: func,
  handlePercentageTypeChange: func,
  withPersentage: bool,
};
export default Filters;
