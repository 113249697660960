import React from 'react';

const DoubleArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.558" height="19.341" viewBox="0 0 21.558 19.341">
      <g id="Group_7090" data-name="Group 7090" transform="translate(-247.649 -65.329)">
        <path
          id="keyboard_double_arrow_down_FILL0_wght400_GRAD0_opsz48"
          d="M249.671,342.558,240,332.887l1.692-1.692,7.978,7.978,7.978-7.978,1.692,1.692Zm0-10.195L240,322.692,241.692,321l7.978,7.978L257.649,321l1.692,1.692Z"
          transform="translate(590.207 -174.671) rotate(90)"
          fill="#066bc6"
        />
      </g>
    </svg>
  );
};

export default DoubleArrow;
