import React from 'react';
import Select from 'react-select';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { func, number } from 'prop-types';
import Pagination from '../../../../../../components/pagination/Pagination';
import { selectWarehouseRequests } from '../../../../../../store/warehouse/selector';

import Row from '../row/Row';
import classNames from './table.module.scss';
import { limitOptions } from '../../../../constants';
import NoDataSvg from '../../../../../../svgs/NoDataSvg';
import { Lang } from '../../../../../../T';
import { selectLang } from '../../../../../../store/user/selectors';

const Table = ({ page, limit, changePage, changeLimit, pagesCount }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { total, data } = useSelector(selectWarehouseRequests);

  return (
    <div className="container ml-0 maxw-100">
      <div className={classNames.table}>
        <div className={classNames.tableWrapper}>
          <table>
            <colgroup>
              <col style={{ width: 379 }} />
              <col style={{ width: 160 }} />
              <col style={{ width: 94 }} />
              <col style={{ width: 220 }} />
              <col style={{ width: 180 }} />
              <col style={{ width: 230 }} />
              <col style={{ width: 309 }} />
            </colgroup>
            <thead>
              <tr>
                <th>{T.product_name}</th>
                <th>{T.date_hour}</th>
                <th>{T.code}</th>
                <th>{T.added}</th>
                <th>
                  {T.export_BR} <br /> {T.BR_number}
                </th>
                <th>{T.comment}</th>
                <th>{T.just_status}</th>
              </tr>
            </thead>
            <tbody>
              {total > 0 &&
                data?.map((item) => {
                  return (
                    <Fragment key={item.id}>
                      <Row item={item} />
                    </Fragment>
                  );
                })}
            </tbody>
          </table>
          {total === 0 && (
            <p className="no-data-tag">
              <NoDataSvg />
              {T.no_data_found}
            </p>
          )}
        </div>
      </div>

      {total > 0 && (
        <div className={classNames.allPagination}>
          <Pagination onChange={changePage} totalPages={pagesCount} defaultPage={page} mode="products" />
          <div className={classNames.ProductsSeries}>
            <div className={classNames.ProductsSeriesItem}>
              <p>{T.row}</p>
              <div className={classNames.ProductsSeriesItemSelect}>
                <Select
                  placement="top"
                  menuPlacement="top"
                  options={limitOptions}
                  onChange={changeLimit}
                  placeholder=""
                  value={{ value: limit, label: limit }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
Table.propTypes = {
  page: number,
  changePage: func,
  limit: number,
  changeLimit: func,
  pagesCount: number,
};
export default Table;
