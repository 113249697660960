import React from 'react';
import classNames from './productBuyingStats.module.scss';
import PageHeader from './components/page-header/PageHeader';
import Cards from './components/cards/Cards';
import useProductsBuyingStats from './useProductsBuyingStats';

const ProductBuyingStats = () => {
  const { data } = useProductsBuyingStats();

  return (
    <div className={classNames.pageContainer}>
      <PageHeader data={data} />
      <Cards data={data} />
    </div>
  );
};

export default ProductBuyingStats;
