import React from 'react';
import { useSelector } from 'react-redux';
import classNames from './history.module.scss';
import { selectLang } from '../../store/user/selectors';
import { Lang } from '../../T';
import Filters from './components/filters/Filters';
import useHistory from './useHistory';
import Content from './components/content/Content';

const History = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const {
    filter,
    setFilter,
    periodOptions,
    initialFilter,
    page,
    limit,
    changePage,
    changeLimit,
    pagesCount,
    data,
    total,
  } = useHistory();

  return (
    <div className={classNames.pageContainer}>
      <div className={classNames.ProductBuyingHeader}>
        <div className="container maxw-100">
          <div className={classNames.statesHeader}>
            <h2 className="title">{T.History}</h2>
          </div>
        </div>
      </div>
      <div className="container maxw-100">
        <Filters
          filter={filter}
          setFilter={setFilter}
          periodOptions={periodOptions}
          initialFilter={initialFilter}
          changePage={changePage}
        />
        <Content
          data={data}
          page={page}
          limit={limit}
          changePage={changePage}
          changeLimit={changeLimit}
          pagesCount={pagesCount}
          total={total}
        />
      </div>
    </div>
  );
};

export default History;
