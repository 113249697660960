import { useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBoards, getProductsCount } from '../../../../../../store/lists/actions';
import { selectAllGetBoardNames, selectGetProductCounts } from '../../../../../../store/lists/selectors';
import { selectWarehouseCountsData } from '../../../../../../store/warehouse/selector';
import { Lang } from '../../../../../../T';
import { selectLang, selectUserRole } from '../../../../../../store/user/selectors';

const useFilters = (setPage, setBoardId, setSearch, setSelectOnlyWaiting, setType) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const dispatch = useDispatch();

  const { data: boards } = useSelector(selectAllGetBoardNames);
  const productCounts = useSelector(selectGetProductCounts);
  const role = useSelector(selectUserRole);

  const isAccountant = useMemo(() => role === 'accountant', [role]);
  const isMarketing = useMemo(() => role === 'marketingExpert', [role]);

  const { totalCount, totalMarketingCount, totalDefectCount, totalNeedToCheckCount } =
    useSelector(selectWarehouseCountsData);

  const boardData = useMemo(() => [{ label: T.all, value: null }, ...boards], [boards]);

  const filterByBoard = useCallback((searchInfo) => {
    setPage(1);
    setBoardId(searchInfo.value);
  }, []);

  const filterWaiting = useCallback((searchInfo) => {
    setPage(1);
    setSelectOnlyWaiting(searchInfo.value);
  }, []);

  const filterTypes = useCallback((selected) => {
    setPage(1);
    setType(selected.value);
  }, []);

  const handleSearchProduct = useCallback((value) => {
    setPage(1);
    setSearch(value);
  }, []);

  useEffect(() => {
    if (!isMarketing) {
      dispatch(getAllBoards());
    }

    if (!isAccountant) {
      dispatch(getProductsCount());
    }
  }, [isAccountant, isMarketing]);

  return {
    boardData,
    filterByBoard,
    handleSearchProduct,
    totalCount,
    totalMarketingCount,
    filterWaiting,
    filterTypes,
    productCounts,
    totalDefectCount,
    totalNeedToCheckCount,
    isAccountant,
    isMarketing,
  };
};

export default useFilters;
