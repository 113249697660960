import React from 'react';

const SmallCopySvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
      <rect x="0.692505" y="0.754883" width="32" height="32" rx="3" fill="white" />
      <path
        d="M9.40076 26.7295C8.98409 26.7295 8.61951 26.5795 8.30701 26.2795C7.99451 25.9795 7.83826 25.6295 7.83826 25.2295V10.1545H9.40076V25.2295H21.7445V26.7295H9.40076ZM12.5258 23.7295C12.1091 23.7295 11.7445 23.5795 11.432 23.2795C11.1195 22.9795 10.9633 22.6295 10.9633 22.2295V8.22949C10.9633 7.82949 11.1195 7.47949 11.432 7.17949C11.7445 6.87949 12.1091 6.72949 12.5258 6.72949H23.9841C24.4008 6.72949 24.7653 6.87949 25.0778 7.17949C25.3903 7.47949 25.5466 7.82949 25.5466 8.22949V22.2295C25.5466 22.6295 25.3903 22.9795 25.0778 23.2795C24.7653 23.5795 24.4008 23.7295 23.9841 23.7295H12.5258ZM12.5258 22.2295H23.9841V8.22949H12.5258V22.2295Z"
        fill="#7E8295"
      />
    </svg>
  );
};

export default SmallCopySvg;
