import { createCombinedAction } from '../helpers';

export const createComment = createCombinedAction({
  name: 'createComment',
  prefix: 'comments@@',
});

export const getComments = createCombinedAction({
  name: 'getComments',
  prefix: 'comments@@',
});
