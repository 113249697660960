import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getData } from './actions';
import { errorLogger } from '../../helpers/errorLogger';
import Axios from '../../services/axios';
import { axiosData, dataHandlers } from './requestGenerators';

function* getDataRequest({ name, payload }) {
  const url = axiosData[name].url.replace(':id', payload.id);
  const res = yield call(Axios.request, {
    ...axiosData[name],
    url,
    data: payload,
  });
  return dataHandlers[name](res);
}

function* watchGetData() {
  yield takeEvery(getData, function* workGetData({ payload: { name, key, ...payload } }) {
    try {
      const data = yield call(getDataRequest, { name, payload });
      yield put(getData.success({ data, name, key }));
    } catch (e) {
      yield put(getData.error({ name, key }));
      errorLogger(e);
    }
  });
}

export default function* rootGetData() {
  yield all([watchGetData()]);
}
