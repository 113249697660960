import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../../store/modals/actions';
import { modalNames } from '../../../../store/modals/constants';
import { selectDraggableBoard } from '../../../../store/draggable-board/selector';
// eslint-disable-next-line import/no-cycle
import { useApp } from '../../../../useApp';
import { getAllListsNames } from '../../../../store/lists/actions';
import DownloadSvg from '../../../../svgs/DownloadSvg';
import { Lang } from '../../../../T';
import { selectLang } from '../../../../store/user/selectors';

const Download = ({ userId, listId, disable }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const dispatch = useDispatch();
  const { archived } = useSelector(selectDraggableBoard);
  const { role } = useApp();

  const handleDownloadCsv = useCallback(() => {
    dispatch(openModal({ name: modalNames.downLoadCSF, data: { listId } }));
    dispatch(getAllListsNames());
  }, [userId]);

  return (
    !archived &&
    (role === 'storeKeeper' || role === 'admin' || role === 'director') && (
      <button type="button" className="downloadBoardBtn" onClick={handleDownloadCsv} disabled={disable}>
        <DownloadSvg />
        {T.download}
      </button>
    )
  );
};

Download.propTypes = {
  userId: PropTypes.number,
  listId: PropTypes.string,
  disable: PropTypes.bool,
};

export default Download;
