import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { searchData } from './actions';
import { errorLogger } from '../../helpers/errorLogger';
import Axios from '../../services/axios';
import { axiosData /* dataHandlers */, dataHandlers } from './requestGenerators';
import { getRequestDataFormat, replaceURLDynamicFields } from './helpers';
import { searchDataNames } from './constants';
import { selectSearchData } from './selectors';

function* searchDataRequest({ name, payload: { replaceInURL, data } }) {
  const url = replaceURLDynamicFields(axiosData[name].url, replaceInURL || {});
  const requestData = getRequestDataFormat(axiosData[name].method, data);
  const res = yield call(Axios.request, {
    ...axiosData[name],
    url,
    ...requestData,
  });
  return dataHandlers[name](res);
}

function* changeMainSearchResult({ result, key }) {
  const { data: searchOldData } = yield select(selectSearchData(key));
  return [...searchOldData, ...result.result];
}

const dataMutations = {
  [searchDataNames.mainSearch]: changeMainSearchResult,
};

function* watchSearchData() {
  yield takeLatest(searchData, function* workSearchData({ payload: { name, key, changeData, ...payload } }) {
    const { offset } = payload.data;
    const { mode, ...copyData } = payload.data;
    const copyPayload = { ...payload, data: copyData };
    try {
      const data = yield call(searchDataRequest, { name, payload: copyPayload });
      if (data?.result) {
        data.end = !data?.result?.length;
      }
      if (dataMutations[name] && changeData) {
        data.result = yield call(dataMutations[name], { result: data, key });
      }
      yield put(searchData.success({ data, name, key, changeData, offset, mode }));
    } catch (e) {
      yield put(searchData.error({ name, key }));
      errorLogger(e);
    }
  });
}

export default function* rootSearchData() {
  yield all([watchSearchData()]);
}
