import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import {
  createProduct,
  getProducts,
  mutateProduct,
  getEditProduct,
  editProduct,
  setLimit,
  getAllStates,
  getProductSummary,
  handleSendSummary,
} from './actions';

const getInitialState = {
  loading: false,
  error: false,
  success: false,
  data: [],
  page: 1,
  pagesCount: 0,
  limit: 10,
};
const get = handleActions(
  {
    [setLimit]: (state, { payload: { value } }) => ({ ...state, limit: value }),
    [getProducts]: (state, { payload: { page } = {} }) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
      page: page || state.page,
    }),
    [getProducts.success]: (state, { payload: { data, page, pagesCount, payload } }) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
      pagesCount,
      data,
      page,
      payload,
    }),
    [getProducts.error]: (state) => ({ ...state, loading: false, error: true, success: false }),
    [getProducts.reset]: () => ({
      loading: false,
      error: false,
      success: false,
      data: [],
      page: 1,
      pagesCount: 0,
      limit: 10,
    }),
    [mutateProduct]: (state, { payload: { id } }) => {
      return {
        ...state,
        data: {
          ...state.data,
          rows: state.data.rows.map((product) => {
            if (product.id === id) {
              return { ...product, loading: true };
            }
            return product;
          }),
        },
      };
    },
    [mutateProduct.success]: (state, { payload: { id, ...rest } }) => ({
      ...state,
      data: {
        ...state.data,
        rows: state.data.rows.map((product) => {
          if (product.id === id) return { ...product, loading: false, ...rest };
          return product;
        }),
      },
    }),
    [mutateProduct.error]: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        rows: state.data.rows.map((product) => {
          if (product.id === id) {
            return { ...product, loading: false };
          }
          return product;
        }),
      },
    }),
  },
  getInitialState
);

const createInitialState = {
  loading: false,
  error: false,
};

const create = handleActions(
  {
    [createProduct]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [createProduct.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
    }),
    [createProduct.error]: (state) => ({ ...state, loading: false, error: true }),
    [createProduct.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  createInitialState
);

const getEditInitialState = {
  loading: false,
  error: false,
  data: {},
};

const getEdit = handleActions(
  {
    [getEditProduct]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [getEditProduct.success]: (state, { payload: { data } }) => ({
      ...state,
      loading: false,
      error: false,
      data,
    }),
    [getEditProduct.error]: (state) => ({ ...state, loading: false, error: true }),
    [getEditProduct.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  getEditInitialState
);
// editProduct
const editInitialState = {
  loading: false,
  error: false,
  data: {},
};

const edit = handleActions(
  {
    [editProduct]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [editProduct.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
    }),
    [editProduct.error]: (state) => ({ ...state, loading: false, error: true }),
    [editProduct.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  editInitialState
);
// states
const getInitialStates = {
  loading: false,
  error: false,
  data: [],
  count: 0,
  statesInfo: [],
};

const getStates = handleActions(
  {
    [getAllStates]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [getAllStates.success]: (state, { payload: { data, count, statesInfo } }) => {
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data,
        count,
        statesInfo,
      };
    },
    [getAllStates.error]: (state) => ({ ...state, loading: false, error: true }),
    [getAllStates.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  getInitialStates
);
// summary
const getSummaryInitialStates = {
  loading: false,
  error: false,
  data: [],
  allData: [],
  isData: false,
};

const getSummary = handleActions(
  {
    [getProductSummary]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [getProductSummary.success]: (state, { payload: { data } }) => {
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data,
        isData: !data?.products?.data?.length,
        allData: [...state.allData, ...data?.products?.data],
      };
    },
    [getProductSummary.error]: (state) => ({ ...state, loading: false, error: true }),
    [getProductSummary.reset]: () => ({
      loading: false,
      error: false,
      isData: false,
      allData: [],
      data: [],
    }),
  },
  getSummaryInitialStates
);
// senSummary
const sendSummaryInitialStates = {
  loading: false,
  error: false,
  success: false,
};

const sendSummary = handleActions(
  {
    [handleSendSummary]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [handleSendSummary.success]: (state) => {
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
      };
    },
    [handleSendSummary.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
    [handleSendSummary.reset]: () => ({
      loading: false,
      error: false,
      success: false,
    }),
  },
  sendSummaryInitialStates
);

const productsReducer = combineReducers({
  sendSummary,
  get,
  create,
  getEdit,
  edit,
  getStates,
  getSummary,
});

export default productsReducer;
