import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from './campaign.module.scss';
import Search from '../../../../components/search/Search';
import Select from '../../../../components/select/Select';
import MulitSelect from '../../../../components/multi-select/MultiSelect';
import { modalNames } from '../../../../store/modals/constants';
import { openModal } from '../../../../store/modals/actions';
import SimpleTable from './components/table/components/simple-table/SimpleTable';

const Campaign = () => {
  const dispatch = useDispatch();
  const handleOpenCreateModal = () => {
    dispatch(
      openModal({
        name: modalNames.createFB,
        title: 'Create for FB',
        data: { modaliks: 'modalik' },
      })
    );
  };
  return (
    <div className={classNames.compaingWrapper}>
      <div className="container maxw-100">
        <div className={classNames.campingFilters}>
          <div className={classNames.searchWithButton}>
            <div className={classNames.inputWrapper}>
              <Search placeholder="Որոնել" />
            </div>
            <button type="button" onClick={handleOpenCreateModal}>
              Create for FB
            </button>
          </div>
          <div className={classNames.filterList}>
            <div className={classNames.filterItem}>
              <MulitSelect
                withAll
                isSearchable
                placeholder="ընտրել"
                label="Date"
                onChange={(value) => console.log(value)}
                options={[
                  { label: 'ccc', value: 'ccc' },
                  { label: 'vvv', value: 'vvv' },
                  { label: 'vyyy', value: 'vyyy' },
                ]}
              />
            </div>
            <div className={classNames.filterItem}>
              <Select
                placeholder="ընտրել"
                label="Type"
                onChange={(value) => console.log(value)}
                options={[
                  { label: 'aaa', value: 'aaa' },
                  { label: 'ccc', value: 'ccc' },
                  { label: 'vvv', value: 'vvv' },
                  { label: 'vyyy', value: 'vyyy' },
                ]}
                widthDate
              />
            </div>
            <div className={classNames.filterItem}>
              <Select
                placeholder="ընտրել"
                label="Turn on/off"
                onChange={(value) => console.log(value)}
                options={[
                  { label: 'aaa', value: 'aaa' },
                  { label: 'ccc', value: 'ccc' },
                  { label: 'vvv', value: 'vvv' },
                  { label: 'vyyy', value: 'vyyy' },
                ]}
                widthDate
              />
            </div>
            <div className={classNames.filterItem}>
              <Select
                placeholder="ընտրել"
                label="Creo used / not used"
                onChange={(value) => console.log(value)}
                options={[
                  { label: 'aaa', value: 'aaa' },
                  { label: 'ccc', value: 'ccc' },
                  { label: 'vvv', value: 'vvv' },
                  { label: 'vyyy', value: 'vyyy' },
                ]}
                widthDate
              />
            </div>
          </div>
        </div>
        <div className={classNames.tableWrapper}>
          <SimpleTable />
        </div>
      </div>
    </div>
  );
};

export default Campaign;
