import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectLang, selectUserRole } from '../../../../store/user/selectors';
import { routes } from '../../../../routes';
import { typeNames } from '../../../special-product-page/constants';
import { Lang } from '../../../../T';

const useCards = (data) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const role = useSelector(selectUserRole);

  const IS_YEREVAN = useMemo(() => process.env.REACT_APP_LANG === 'true', []);
  const currency = useMemo(() => (IS_YEREVAN ? T.amd_am : T.amd), [IS_YEREVAN]);
  const moneyCardsData = useMemo(() => {
    const { overall, percentage } = data;

    return [
      {
        name: T.Gross_Profit,
        amount: overall?.grossTotal,
        cash: overall?.grossCash,
        card: overall?.grossNoCash,
        percent: percentage?.grossTotal,
        url: `${routes.special}/${typeNames.grossProfit}`,
      },
      {
        name: T.Delivery_price,
        amount: overall?.deliveryPrice,
        cash: overall?.deliveryPrice,
        card: null,
        percent: percentage?.deliveryPrice,
        url: '#',
      },
      {
        name: T.Net,
        amount: overall?.netTotal,
        cash: overall?.netCash,
        card: overall?.netNoCash,
        percent: percentage?.netTotal,
        url: `${routes.special}/${typeNames.netTotal}`,
      },
    ];
  }, [data, T]);

  const bidCardsData = useMemo(() => {
    const { overall, percentage } = data;
    return [
      {
        name: T.Total_count,
        amount: overall?.totalCount,
        percent: percentage?.grossTotal,
        url: `${routes.special}/${typeNames.totalCount}`,
      },
      {
        name: T.New_order,
        amount: overall?.newOrderCount,
        percent: percentage?.newOrderCount,
        url: `${routes.special}/${typeNames.newOrderCount}`,
      },
      {
        name: T.Accepted,
        amount: overall?.acceptedCount,
        percent: percentage?.acceptedCount,
        url: `${routes.special}/${typeNames.acceptedCount}`,
      },
      {
        name: T.Pending,
        amount: overall?.pendingCount,
        percent: percentage?.pendingCount,
        url: `${routes.special}/${typeNames.pending}`,
      },
      {
        name: T.Unaccepted,
        amount: overall?.unacceptedCount,
        percent: percentage?.unacceptedCount,
        url: `${routes.special}/${typeNames.unAcceptedCount}`,
      },
    ];
  }, [data, T]);

  const orderCardsData = useMemo(() => {
    const { overall, percentage } = data;

    return [
      {
        name: T.Sold_count,
        amount: overall?.currentSoldCount,
        percent: percentage?.currentSoldCount,
        url: `${routes.special}/${typeNames.currentSoldCount}`,
        bottomItems: [
          { id: crypto.randomUUID(), text: T.Real_sold, value: overall?.currentSoldCount },
          { id: crypto.randomUUID(), text: T.All_sold, value: overall?.overallSoldCount },
        ],
      },
      {
        name: T.Change_Count,
        amount: overall?.doneChangeCount,
        percent: percentage?.doneChangeCount,
        url: `${routes.special}/${typeNames.changeCount}`,
        bottomItems: [
          { id: crypto.randomUUID(), text: T.Pending, value: overall?.pendingChangeCount },
          { id: crypto.randomUUID(), text: T.Done, value: overall?.doneChangeCount },
        ],
      },
      {
        name: T.Return_Count,
        amount: overall?.doneReturnCount,
        percent: percentage?.doneReturnCount,
        url: `${routes.special}/${typeNames.returnCount}`,
        bottomItems: [
          { id: crypto.randomUUID(), text: T.Pending, value: overall?.pendingReturnCount },
          { id: crypto.randomUUID(), text: T.Done, value: overall?.doneReturnCount },
        ],
      },
      {
        name: T.Reject_Count,
        amount: overall?.rejectedTotal,
        percent: percentage?.rejectedTotal,
        url: `${routes.special}/${typeNames.rejectedCount}`,
        bottomItems: [
          { id: crypto.randomUUID(), text: T.By_call, value: overall?.callRejectedCount },
          { id: crypto.randomUUID(), text: T.In_place, value: overall?.rejectedCount },
          {
            id: crypto.randomUUID(),
            text: `${T.In_place} ${currency}`,
            value: overall?.rejectedInPlaceCount,
          },
        ],
      },
      {
        name: T.Sent_to_State_Count,
        amount: overall?.sentToStateCount,
        percent: percentage?.sentToStateCount,
        url: `${routes.special}/${typeNames.sentToStateCount}`,
        bottomItems: [],
      },
    ];
  }, [data, T, currency]);

  return { role, moneyCardsData, bidCardsData, orderCardsData, currency };
};

export default useCards;
