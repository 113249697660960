export const selectAllGetListsNames = ({ listsNames: { getAll } }) => {
  return getAll;
};

export const selectAllGetBoardNames = ({ listsNames: { getAllBoardsNames } }) => {
  return getAllBoardsNames;
};

export const selectMoveAllItems = ({ listsNames: { move } }) => {
  return move;
};

export const selectGetProductCounts = ({ listsNames: { getCounts } }) => {
  return getCounts.data;
};
