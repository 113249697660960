import React from 'react';

const DomainIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32">
      <path
        fill="currentColor"
        d="M28 26h-9.184A2.996 2.996 0 0 0 17 24.184V19h7a2.003 2.003 0 0 0 2-2V4a2.003 2.003 0 0 0-2-2H8a2.003 2.003 0 0 0-2 2v13a2.003 2.003 0 0 0 2 2h7v5.184A2.996 2.996 0 0 0 13.184 26H4v2h9.184a2.982 2.982 0 0 0 5.632 0H28Zm-4-14H8V9h16Zm0-8v3H8V4ZM8 14h16v3H8Zm8 14a1 1 0 1 1 1-1a1 1 0 0 1-1 1"
      />
    </svg>
  );
};

export default DomainIcon;
