import React, { useState } from 'react';
import PropTypes from 'prop-types';
import imgDec from '../../../../images/filterIconDec.svg';
import imgInc from '../../../../images/filterIconInc.svg';
import imgInitial from '../../../../images/filterIconReset.svg';

const FilterIco = ({ setFilterMod, value }) => {
  const [mod, setMod] = useState('imgDec');
  const icoMod = {
    imgDec,
    imgInc,
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <img
      src={icoMod[mod]}
      alt="filterImage"
      onClick={() => {
        setMod((prev) => (prev === 'imgDec' ? 'imgInc' : 'imgDec'));
        setFilterMod({ mod: mod === 'imgDec' ? 'imgInc' : 'imgDec', value });
      }}
    />
  );
};

FilterIco.propTypes = {
  setFilterMod: PropTypes.func,
  value: PropTypes.string,
};
export default FilterIco;

const FilterIcoBack = ({ filterMode, setFilterMode, onResetOtherFilters }) => {
  const icoMode = {
    0: imgInitial,
    1: imgInc,
    2: imgDec,
  };
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <img
      src={icoMode[filterMode]}
      alt="filterImage"
      onClick={() => {
        const mode = (filterMode + 1) % 3;
        setFilterMode(mode);
        onResetOtherFilters();
      }}
    />
  );
};

FilterIcoBack.propTypes = {
  setFilterMode: PropTypes.func,
  onResetOtherFilters: PropTypes.func,
  filterMode: PropTypes.number,
};
export { FilterIcoBack };
