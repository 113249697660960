import React from 'react';
import PropTypes from 'prop-types';
import classNames from './horizontalTabs.module.scss';
import { useHorizontalTabs } from './useHorizontalTabs';

const HorizontalTabs = ({ tabs, className }) => {
  const { tabIndex, changeTab } = useHorizontalTabs();
  return (
    <div className="container maxw-100">
      <div className={`${classNames.page} ${classNames[className]}`}>
        <div className={classNames.pageHeader}>
          <div className={classNames.headerRow}>
            {tabs.map(({ key, name }) => {
              return (
                <div
                  key={key}
                  className={`${classNames.tab} ${key === tabIndex ? classNames.active : ''}`}
                  onClick={() => changeTab(key)}
                >
                  {name}
                </div>
              );
            })}
          </div>
        </div>
        <div className={classNames.pageBody}>
          <div className={classNames.bodyRow}>
            <div>{tabs[tabIndex].children} </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HorizontalTabs.propTypes = {
  tabs: PropTypes.any,
  className: PropTypes.string,
};
export default HorizontalTabs;
