import React from 'react';
import { useSelector } from 'react-redux';
import useSpecial from './useSpecial';
import Cards from './components/cards/Cards';
import Filters from './components/filters/Filters';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';
import classNames from './special.module.scss';

const Special = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const {
    filter,
    setFilter,
    handlePeriodChange,
    data,
    handleSearch,
    productOptions,
    domainOptions,
    handleDomainChange,
    urlsOptions,
    handleUrlChange,
    handleUrlSearch,
    handleRegionChange,
    handleDeviceChange,
    handleReset,
    isResetDisabled,
    preset,
    setPreset,
    presetList,
    setPresetList,
    handleChooseUtmKey,
    handlePercentageTypeChange,
    handleUtmChange,
    utmValueList,
    getMoreUtm,
  } = useSpecial();

  return (
    <>
      <div className={classNames.pageTitle}>
        <h2>{T.analytics}</h2>
      </div>
      <Filters
        filter={filter}
        setFilter={setFilter}
        handlePeriodChange={handlePeriodChange}
        productOptions={productOptions}
        handleSearch={handleSearch}
        urlsOptions={urlsOptions}
        handleUrlChange={handleUrlChange}
        handleUrlSearch={handleUrlSearch}
        domainOptions={domainOptions}
        handleDomainChange={handleDomainChange}
        handleRegionChange={handleRegionChange}
        handleDeviceChange={handleDeviceChange}
        handleReset={handleReset}
        isResetDisabled={isResetDisabled}
        preset={preset}
        setPreset={setPreset}
        presetList={presetList}
        setPresetList={setPresetList}
        handleChooseUtmKey={handleChooseUtmKey}
        handleUtmChange={handleUtmChange}
        utmValueList={utmValueList}
        getMoreUtm={getMoreUtm}
        handlePercentageTypeChange={handlePercentageTypeChange}
        withPersentage
      />
      <Cards data={data} />
    </>
  );
};

export default Special;
