import PropTypes from 'prop-types';
import React from 'react';
import Options from './components/options/Options';
import { useEdit } from './useEdit';

const Edit = ({ item, listId, active, setShowMenu }) => {
  const { loading } = useEdit(item, active);
  return !loading && <Options item={item} listId={listId} setShowMenu={setShowMenu} />;
};
Edit.propTypes = {
  item: PropTypes.object,
  listId: PropTypes.string,
  listItemClass: PropTypes.string,
  active: PropTypes.bool,
  setShowMenu: PropTypes.func,
};
export default Edit;
