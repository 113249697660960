import { analAxios } from '../../services/axios';

export const getStatistic = (
  {
    period,
    productIds,
    ignoreOutOfInterval,
    percentageType,
    startDate,
    endDate,
    domainIds,
    urls,
    region,
    device,
    isAccountant,
    utm,
  },
  setData
) => {
  const utmParam = {};

  if (utm?.length) {
    utm.forEach(({ type, value }) => {
      if (!utmParam[`${type}`]) {
        utmParam[`${type}`] = [value];
      } else {
        utmParam[`${type}`].push(value);
      }
    });
  }
  const startDates = startDate?.toLocaleDateString('ru-RU').split('.');
  const endDates = endDate?.toLocaleDateString('ru-RU').split('.');
  analAxios
    .post('/api/order/statistics/overall', {
      intervalType: period.value !== 'custom' ? period.value : undefined,
      percentageType: percentageType.value,
      productIds: productIds.map(({ value }) => value),
      ignoreOutOfInterval: isAccountant ? undefined : ignoreOutOfInterval,
      startDate: startDate ? `${startDates[2]}-${startDates[1]}-${startDates[0]}` : undefined,
      endDate: endDate ? `${endDates[2]}-${endDates[1]}-${endDates[0]}` : undefined,
      domainIds: isAccountant ? undefined : domainIds.map(({ value }) => `${value}`),
      urls: isAccountant ? undefined : urls.map(({ value }) => value),
      orderedFrom: isAccountant ? undefined : region.value || undefined,
      createdFrom: isAccountant ? undefined : device.value || undefined,
      utms: utm?.length ? utmParam : undefined,
    })
    .then((res) => setData(res.data))
    .catch((e) => console.log(e));
};

export const getAnaliticDomains = (setDomainOptions) => {
  return analAxios
    .get('/api/domain')
    .then((res) => setDomainOptions(res?.data?.map(({ id, name }) => ({ label: name, value: id })) || []))
    .catch((e) => console.log(e));
};

export const getProductUrls = (setUrlOptions, domains, search) => {
  const domainIds = domains.map(({ value }) => value);
  return analAxios
    .post('/api/domain/products', { domainIds, search })
    .then((res) => {
      return setUrlOptions(res?.data?.result?.map((item) => ({ label: item, value: item })) || []);
    })
    .catch((e) => console.log(e));
};

export const getPresetListRequest = async (setPresetList) => {
  return analAxios
    .get('/api/user/preset/list?type=analytics')
    .then((res) => setPresetList(res?.data || []))
    .catch((e) => console.log(e));
};
export const createPresetRequest = async (presetName, preset) => {
  return analAxios
    .put(`/api/user/preset?type=analytics&name=${presetName}`, { preset })
    .then((res) => res)
    .catch((e) => console.log(e));
};

export const deletePresetRequest = async (presetName) => {
  return analAxios
    .delete(`/api/user/preset?type=analytics&name=${presetName}`)
    .then((res) => res)
    .catch((e) => console.log(e));
};

export const getPresetByNameRequest = async (presetName) => {
  return analAxios
    .get(`/api/user/preset?type=analytics&name=${presetName}`)
    .then((res) => res)
    .catch((e) => console.log(e));
};

export const getUtmByType = async (type, page = 1) => {
  const limit = 50;
  const offset = (page - 1) * limit;
  return analAxios
    .get(`/api/domain/utm?type=${type}&limit=${limit}&offset=${offset}`)
    .then((res) => res?.data)
    .catch((e) => console.log(e));
};
