import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { changeStatus } from '../../../../store/products/actions';

export const useStatusSwitch = ({ status, id }) => {
  const dispatch = useDispatch();
  const handleChange = useCallback(
    (status) => {
      dispatch(changeStatus({ status, id }));
    },
    [id]
  );
  const inputProps = useMemo(
    () => ({
      checked: status,
      onChange: ({ target: { checked } }) => handleChange(checked),
    }),
    [status, handleChange]
  );
  return { inputProps };
};
