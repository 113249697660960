import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectCreateBoardList } from '../../../../../../store/draggable-board/selector';
import { createList } from '../../../../../../store/draggable-board/actions';

export const useCreateForm = () => {
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, success } = useSelector(selectCreateBoardList);

  useEffect(() => {
    if (success) {
      setShowForm(false);
    }
  }, [success]);

  const handleSubmit = useCallback(
    (values) => {
      dispatch(createList({ values: { ...values, id } }));
    },
    [id]
  );

  const toggleCreateForm = useCallback(() => {
    setShowForm(!showForm);
  }, [showForm]);
  return { showForm, loading, handleSubmit, toggleCreateForm };
};
