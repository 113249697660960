import { routes } from './routes';

import Boards from './pages/boards/Boards';
import Workers from './pages/workers/Workers';
// eslint-disable-next-line import/no-cycle
import DraggableBoard from './pages/draggable-board/DraggableBoard';
// eslint-disable-next-line import/no-cycle
import Domain from './pages/domain/Domain';
import Products from './pages/products/Products';
// import ArmPost from './pages/arm-post/ArmPost';
// import Reports from './pages/reports/Reports';
import States from './pages/states/States';
import Store from './pages/store/Store';
import Customers from './pages/customers/Customers';
import Marketing from './pages/marketing/Marketing';
// import Analytics from './pages/analytics/Analytics';
import Special from './pages/special/Special';
import SpecialProductPage from './pages/special-product-page/SpecialProductPage';
import ProductBuying from './pages/product-buying/ProductBuying';
import ProductBuyingStats from './pages/product-buying-stats/ProductBuyingStats';
import History from './pages/history/History';

export const pagesComponents = {
  admin: [
    { path: routes.home, Component: Boards, exact: true },
    { path: routes.marketing, Component: Marketing, exact: true },
    // { path: routes.analytics, Component: Analytics, exact: true },
    { path: routes.workers, Component: Workers, exact: true },
    { path: routes.states, Component: States, exact: true },
    { path: routes.draggableBoard, Component: DraggableBoard, exact: true },
    { path: routes.domain, Component: Domain, exact: true },
    { path: routes.products, Component: Products, exact: true },
    // { path: routes.reports, Component: Reports },
    { path: routes.store, Component: Store, exact: true },
    { path: routes.customers, Component: Customers, exact: true },
    { path: routes.special, Component: Special, exact: true },
    { path: routes.specialPage, Component: SpecialProductPage, exact: true },
    { path: routes.productBuying, Component: ProductBuying, exact: true },
    { path: routes.productBuyingStats, Component: ProductBuyingStats, exact: true },
    { path: routes.history, Component: History, exact: true },
  ],
  operator: [
    { path: routes.home, Component: Boards, exact: true },
    { path: routes.draggableBoard, Component: DraggableBoard, exact: true },
    { path: routes.products, Component: Products, exact: true },
  ],
  chiefOperator: [
    { path: routes.home, Component: Boards, exact: true },
    { path: routes.draggableBoard, Component: DraggableBoard, exact: true },
    { path: routes.products, Component: Products, exact: true },
    { path: routes.customers, Component: Customers, exact: true },
  ],
  storeKeeper: [
    { path: routes.home, Component: Boards, exact: true },
    { path: routes.states, Component: States, exact: true },
    { path: routes.draggableBoard, Component: DraggableBoard, exact: true },
    { path: routes.products, Component: Products, exact: true },
    { path: routes.store, Component: Store, exact: true },
    { path: routes.customers, Component: Customers, exact: true },
  ],
  director: [
    { path: routes.home, Component: Boards, exact: true },
    { path: routes.states, Component: States, exact: true },
    { path: routes.workers, Component: Workers, exact: true },
    { path: routes.draggableBoard, Component: DraggableBoard, exact: true },
    { path: routes.domain, Component: Domain, exact: true },
    { path: routes.products, Component: Products, exact: true },
    // { path: routes.reports, Component: Reports, exact: true },
    { path: routes.store, Component: Store, exact: true },
    { path: routes.customers, Component: Customers, exact: true },
    { path: routes.marketing, Component: Marketing, exact: true },
    { path: routes.special, Component: Special, exact: true },
    { path: routes.specialPage, Component: SpecialProductPage, exact: true },
    { path: routes.productBuying, Component: ProductBuying, exact: true },
    { path: routes.productBuyingStats, Component: ProductBuyingStats, exact: true },
    { path: routes.history, Component: History, exact: true },
  ],
  deliveryMan: [{ path: routes.draggableBoard, Component: DraggableBoard, exact: true }],
  productCreator: [{ path: routes.products, Component: Products, exact: true }],
  marketingExpert: [
    { path: routes.special, Component: Special, exact: true },
    { path: routes.specialPage, Component: SpecialProductPage, exact: true },
    { path: routes.store, Component: Store, exact: true },
    { path: routes.productBuying, Component: ProductBuying, exact: true },
  ],
  accountant: [
    { path: routes.special, Component: Special, exact: true },
    { path: routes.specialPage, Component: SpecialProductPage, exact: true },
    { path: routes.store, Component: Store, exact: true },
  ],
  viewer: [
    { path: routes.products, Component: Products, exact: true },
    { path: routes.customers, Component: Customers, exact: true },
  ],
};
