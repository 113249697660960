import { useState, useMemo } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOutsideDetect } from '../../hooks/useOutsideDetect';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';

export const useSelect = ({ onChange, options, isSearchable, outerValue, onSearch }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [customOpen, setCustomOpen] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const ref = useOutsideDetect(() => {
    setIsOpen(false);
    setCustomOpen(false);
  });

  const optionsData = useMemo(() => {
    if (inputValue) {
      return options.filter((item) => item?.label?.includes(inputValue));
    }
    return [...options];
  }, [inputValue, options]);

  const handleSelectOpen = () => {
    if (isSearchable && !inputValue && !selected) {
      return;
    }
    setIsOpen((prev) => !prev);
  };

  const hanldeInputChange = (e) => {
    onSearch(e.target.value);
    setInputValue(e.target.value);
    setIsOpen(true);
    setIsDirty(true);
  };

  const handleChangeSelect = (label, value) => {
    onChange({ label, value });
    setSelected(label);
    setIsOpen(false);
    setIsDirty(false);
  };

  const handleCustom = () => {
    setIsOpen(false);
    setCustomOpen((prev) => !prev);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}/${month}/${day}`;
  };

  const handleChange = (date) => {
    const [start, end] = date;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      const dateStart = formatDate(start);
      const dateEnd = formatDate(end);
      setSelected(`${dateStart} - ${dateEnd}`);
      if (dateEnd) {
        setCustomOpen(false);
      }
      onChange(date);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setIsDirty(false);
      setInputValue('');
      onSearch('');
    }
  }, [isOpen]);

  useEffect(() => {
    setSelected(outerValue?.label || '');
  }, [outerValue]);

  useEffect(() => {
    const { label, value } = options.find((item) => item?.value === outerValue?.value) || {};
    onChange({ label, value });
    setSelected(label);
  }, [T]);

  return {
    ref,
    handleSelectOpen,
    handleChangeSelect,
    isOpen,
    inputValue,
    hanldeInputChange,
    optionsData,
    selected,
    isDirty,
    handleCustom,
    customOpen,
    startDate,
    endDate,
    handleChange,
  };
};
