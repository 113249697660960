import * as Yup from 'yup';

export const imgStyles = {
  width: '38px',
  height: '38px',
  borderRadius: '50%',
  objectFit: 'cover',
  marginRight: 12,
};

export const limitOptions = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 150, label: 150 },
  { value: 200, label: 200 },
];

export const validationSchema = Yup.object().shape({
  date: Yup.string(),
});
