import { createCombinedAction } from '../helpers';

export const getCustomers = createCombinedAction({ name: 'getCustomers', prefix: 'customers@@' });

export const getCustomersCount = createCombinedAction({
  name: 'getCustomersCount',
  prefix: 'customers@@',
});

export const deleteCustomerByPhone = createCombinedAction({
  name: 'deleteCustomerByPhone',
  prefix: 'customer@@',
});

export const getOrdersByPhone = createCombinedAction({
  name: 'getOrdersByPhone',
  prefix: 'customers',
});

export const getAddressesByPhone = createCombinedAction({
  name: 'getAddressesByPhone',
  prefix: 'customers',
});

export const getRatesByPhone = createCombinedAction({
  name: 'getRatesByPhone',
  prefix: 'customers',
});

export const printBuyers = createCombinedAction({
  name: 'printBuyers',
  prefix: 'customers',
});

export const addRate = createCombinedAction({
  name: 'addRate',
  prefix: 'customers',
});

export const deleteRate = createCombinedAction({
  name: 'deleteRate',
  prefix: 'customers',
});
