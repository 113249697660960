import Select from 'react-select';
import React from 'react';
import { bool, func, string } from 'prop-types';
import { useSelector } from 'react-redux';
import Search from '../../../../../../components/search/Search';
import classNames from './filters.module.scss';
import useFilters from './useFilters';
import { Lang } from '../../../../../../T';
import { selectLang } from '../../../../../../store/user/selectors';

const Filters = ({
  setPage,
  setBoardId,
  setSearch,
  setSelectOnlyWaiting,
  selectOnlyWaiting,
  setType,
  hasPermission,
}) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const waitingSelectData = [
    { label: T.all, value: false },
    { label: T.for_check, value: 'APPROVED' },
    { label: T.defect, value: 'DEFECT' },
  ];
  const typeOptions = [
    { label: T.all, value: null },
    { label: T.real, value: 'real' },
    { label: T.test, value: 'test' },
  ];
  const {
    boardData,
    filterByBoard,
    handleSearchProduct,
    totalCount,
    totalMarketingCount,
    totalDefectCount,
    filterWaiting,
    filterTypes,
    productCounts,
    totalNeedToCheckCount,
    isAccountant,
    isMarketing,
  } = useFilters(setPage, setBoardId, setSearch, setSelectOnlyWaiting, setType);

  return (
    <div className={classNames.filters}>
      <div className={classNames.inputs}>
        <div className={`${classNames.searchFilter} ${classNames.ProductsSearch}`}>
          <Search onChange={handleSearchProduct} setSearch={setSearch} placeholder={T.search} />
        </div>
        {!isMarketing && (
          <div className={classNames.selectFilter}>
            <div className={classNames.title}>{T.filter_by_board}</div>
            <Select options={boardData} onChange={filterByBoard} placeholder={T.all} disable />
          </div>
        )}

        {!isAccountant && !isMarketing && (
          <div className={classNames.selectFilter}>
            <div className={classNames.title}>{T.filter_by_checked_products}</div>
            <Select options={waitingSelectData} onChange={filterWaiting} placeholder={T.all} disable />
          </div>
        )}
        {!isAccountant && (
          <div className={classNames.selectFilter}>
            <div className={classNames.title}>{T.filter_by_type}</div>
            <Select options={typeOptions} onChange={filterTypes} placeholder={T.all} disable />
          </div>
        )}
      </div>
      <div className={classNames.counts}>
        {hasPermission && (
          <div className={classNames.count}>
            {T.marketing_count}: <span>{totalMarketingCount}</span>
          </div>
        )}
        {!isAccountant && !isMarketing && (
          <>
            <div className={classNames.count}>
              {T.spare_count}: <span>{totalCount || 0}</span>
            </div>
            <div className={classNames.count}>
              {T.defect_count}: <span>{totalDefectCount || 0}</span>
            </div>
            <div className={classNames.count}>
              {T.test_product}: <span>{productCounts?.testCount || 0}</span>
            </div>
            <div className={classNames.count}>
              {T.real_product}։ <span>{productCounts?.realCount || 0}</span>
            </div>
          </>
        )}
        {!isMarketing && selectOnlyWaiting === 'APPROVED' && (
          <div className={classNames.count}>
            {T.for_check}։ <span>{totalNeedToCheckCount || 0}</span>
          </div>
        )}
      </div>
    </div>
  );
};

Filters.propTypes = {
  setPage: func,
  setBoardId: func,
  setSearch: func,
  setSelectOnlyWaiting: func,
  setType: func,
  hasPermission: bool,
  selectOnlyWaiting: string,
};
export default Filters;
