import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from '../../card.module.scss';

const AddressInfo = ({ item }) => {
  const isYerevan = useMemo(() => item.data?.state === 'Երևան', []);

  const state = useMemo(() => {
    if (!isYerevan) {
      return <span className={classNames.bold}>{item.data.state} | </span>;
    }
    return '';
  }, []);

  const city = useMemo(() => {
    if (isYerevan) {
      return <span className={classNames.bold}>{item.data.city} | </span>;
    }
    return item.data.city;
  }, []);

  const buildingApartment = useMemo(() => {
    const { building, apartment } = item.data;
    if (building && apartment) {
      return (
        <>
          {building} {' , '}
          {apartment}
        </>
      );
    }
    return '';
  }, []);
  return (
    <p className={`${classNames.innerItem} ${classNames.innerItemAddress}`}>
      {state}
      {city} {item.data?.address} {buildingApartment}
    </p>
  );
};

AddressInfo.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    labels: PropTypes.array,
    data: PropTypes.object,
    commentsCount: PropTypes.number,
    listItemOrder: PropTypes.object,
    createdAt: PropTypes.string,
    productName: PropTypes.array,
  }),
};
export default AddressInfo;
