import React from 'react';

const GhostEmoji = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <defs>
        <clipPath id="clip-path">
          <path
            id="Path_6993"
            data-name="Path 6993"
            d="M19.416,13.278a.852.852,0,0,1,.842.957,5.119,5.119,0,0,1-10,.191.957.957,0,0,1,.957-1.2Z"
            transform="translate(-10.228 -13.229)"
            fill="#494949"
          />
        </clipPath>
      </defs>
      <g id="Group_7240" data-name="Group 7240" transform="translate(-1090 -440)">
        <rect
          id="Rectangle_11533"
          data-name="Rectangle 11533"
          width="30"
          height="30"
          transform="translate(1090 440)"
          fill="none"
        />
        <g id="Group_7239" data-name="Group 7239" transform="translate(1090 440.614)">
          <path
            id="Path_6988"
            data-name="Path 6988"
            d="M29.413,11.281a1.416,1.416,0,0,0-1.215,0c-1.273.421-2.2,1.55-3.464,2a2.8,2.8,0,0,1-.488.124V9.578A9.511,9.511,0,0,0,14.792,0h0A9.511,9.511,0,0,0,5.281,9.463v3.77h0C3.961,12.8,3.013,11.607,1.7,11.166a1.416,1.416,0,0,0-1.215.048A1.273,1.273,0,0,0,0,12.439a4.124,4.124,0,0,0,.957,1.914A11.559,11.559,0,0,0,5.31,18.467h0v6.5a8.909,8.909,0,0,0,.134,1.521h0a4.507,4.507,0,0,0,1.6.957c1.713.488,2.191-.746,3.473-1.186a3.2,3.2,0,0,1,2.162.239c2.21.737,3.024.344,5.081-.144a4.956,4.956,0,0,1,3.234.325c.957.459,1.828,1.177,2.813,1.206a.4.4,0,0,0,.373-.354,6.88,6.88,0,0,0,.105-2.555V18.62h0c2-.718,3.473-2.488,4.784-4.229a4.239,4.239,0,0,0,.909-1.914,1.311,1.311,0,0,0-.565-1.2Z"
            transform="translate(0.018)"
            fill="#71727d"
          />
          <path
            id="Path_6989"
            data-name="Path 6989"
            d="M11.186,10.391a1.386,1.386,0,0,1-1.158,1.531c-.632,0-1.148-.689-1.148-1.531S9.4,8.86,10.038,8.86,11.186,9.549,11.186,10.391Z"
            transform="translate(-0.367 -0.382)"
            fill="#fff"
          />
          <path
            id="Path_6990"
            data-name="Path 6990"
            d="M22.016,10.512c0,.861-.526,1.56-1.158,1.56S19.71,11.363,19.71,10.5s.517-1.56,1.158-1.5A1.383,1.383,0,0,1,22.016,10.512Z"
            transform="translate(-0.833 -0.388)"
            fill="#fff"
          />
          <path
            id="Path_6991"
            data-name="Path 6991"
            d="M19.416,13.278a.852.852,0,0,1,.842.957,5.119,5.119,0,0,1-10,.191.957.957,0,0,1,.957-1.2Z"
            transform="translate(-0.424 -0.571)"
            fill="#fff"
          />
          <g id="Group_7238" data-name="Group 7238" transform="translate(9.803 12.658)" clipPath="url(#clip-path)">
            <path
              id="Path_6992"
              data-name="Path 6992"
              d="M16.106,16.207a1.4,1.4,0,0,0-.718.182,1.349,1.349,0,0,0-.813-.249,1.435,1.435,0,1,0,0,2.871,1.5,1.5,0,0,0,.718-.182,1.349,1.349,0,0,0,.813.249,1.435,1.435,0,1,0,0-2.871Z"
              transform="translate(-10.354 -13.355)"
              fill="#ff7070"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GhostEmoji;
