import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../../../../components/date-picker/DatePicker';
import ArrowSvg from '../../../../svgs/ArrowSvg';
import classNames from './custom-datapicker.module.scss';

const CustomDatapicker = ({ startDate, setStartDate }) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className={classNames.customInputDatapicker} onClick={onClick} ref={ref}>
      {value}
      <span>
        <ArrowSvg />
      </span>
    </button>
  ));
  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      showYearPicker
      dateFormat="yyyy"
      yearItemNumber={5}
      customInput={<ExampleCustomInput />}
    />
  );
};
export default CustomDatapicker;

CustomDatapicker.propTypes = {
  onclick: PropTypes.func,
  value: PropTypes.string,
  onClick: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func,
};
