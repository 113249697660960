import { useCallback, useEffect, useState } from 'react';

export const useDropdown = (active) => {
  const [isOpen, setOpen] = useState(false);

  const toggleDropdown = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      setOpen(!isOpen);
    },
    [isOpen]
  );

  const hideDropDown = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
  }, []);

  useEffect(() => {
    const documentClick = (event) => {
      if (!event.target.closest('.dropdown-toggle')) {
        setOpen(false);
      }
    };
    if (active) {
      setOpen(true);
    }
    document.addEventListener('click', documentClick);

    return () => {
      document.removeEventListener('click', documentClick);
    };
  }, []);

  return { toggleDropdown, isOpen, hideDropDown };
};
