import * as Yup from 'yup';

export const validationSchema = (T) =>
  Yup.object().shape({
    password: Yup.string().min(6, T.minimum_6_symbol).required(T.required_field),
    retryPassword: Yup.string()
      .min(6, T.minimum_6_symbol)
      .required(T.required_field)
      .test('match', T.not_matched, function lom(confirmedPassword) {
        return this.parent.password === confirmedPassword;
      }),
  });
