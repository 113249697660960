import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { openModal } from '../../../../store/modals/actions';
import { modalNames } from '../../../../store/modals/constants';

export const useCreateBoardButton = () => {
  const dispatch = useDispatch();
  const openCreateBoardModal = useCallback(() => {
    dispatch(openModal({ name: modalNames.createEditBoard }));
  }, []);
  return { openCreateBoardModal };
};
