import React from 'react';

const FilterClearSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.943" height="15.943" viewBox="0 0 15.943 15.943">
      <path
        id="filter_list_off_FILL0_wght400_GRAD0_opsz48"
        d="M9.548,9.131l-1.2-1.2H18.672v1.2Zm4.211,4.211-1.2-1.2h3.71v1.2ZM9.848,17.553v-1.2h3.209v1.2Zm8.282,3.59-7.8-7.8H6.64v-1.2H9.126L3.05,6.062,3.912,5.2,18.993,20.281ZM6.118,7.927v1.2H4.233v-1.2Z"
        transform="translate(-3.05 -5.2)"
        fill="#364364"
      />
    </svg>
  );
};

export default FilterClearSvg;
