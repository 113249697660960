import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toggleArchive } from '../store/draggable-board/actions';

const HouseSvg = ({ fillColor }) => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        dispatch(toggleArchive.reset());
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="33.921" height="33.924" viewBox="0 0 33.921 33.924">
        <path
          id="home_1_"
          data-name="home (1)"
          d="M33.509,14.755l0,0L19.669.914a3.123,3.123,0,0,0-4.417,0L1.421,14.745l-.014.014A3.122,3.122,0,0,0,3.485,20.08c.032,0,.064,0,.1,0h.551V30.268a3.659,3.659,0,0,0,3.655,3.655H13.2a.994.994,0,0,0,.994-.994V24.945a1.67,1.67,0,0,1,1.668-1.668h3.193a1.669,1.669,0,0,1,1.668,1.668v7.984a.994.994,0,0,0,.994.994h5.414a3.659,3.659,0,0,0,3.655-3.655V20.085H31.3a3.124,3.124,0,0,0,2.21-5.33ZM32.1,17.764a1.128,1.128,0,0,1-.8.333H29.794a.994.994,0,0,0-.994.994V30.268a1.669,1.669,0,0,1-1.668,1.667h-4.42v-6.99a3.659,3.659,0,0,0-3.655-3.655H15.864a3.66,3.66,0,0,0-3.656,3.655v6.99H7.788A1.669,1.669,0,0,1,6.12,30.268V19.091a.994.994,0,0,0-.994-.994H3.647l-.047,0a1.134,1.134,0,0,1-.782-1.937h0L16.658,2.32a1.135,1.135,0,0,1,1.605,0L32.1,16.154l.006.006a1.138,1.138,0,0,1,0,1.6Zm0,0"
          transform="translate(-0.5 0.001)"
          fill={`${fillColor || '#fff'}`}
        />
      </svg>
    </div>
  );
};
HouseSvg.propTypes = {
  fillColor: PropTypes.string,
};
export default HouseSvg;
