import React from 'react';

const SadEmoji = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Component_48_1"
      data-name="Component 48 – 1"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <rect
        id="Rectangle_11118"
        data-name="Rectangle 11118"
        width="30"
        height="30"
        transform="translate(0)"
        fill="none"
      />
      <g id="Group_7040" data-name="Group 7040" transform="translate(5240.979 11076.236)">
        <g id="Group_7039" data-name="Group 7039" transform="translate(-5240.979 -11076.236)">
          <g id="Group_7023" data-name="Group 7023" transform="translate(0 0)">
            <g id="Group_6987" data-name="Group 6987" transform="translate(0)">
              <g id="Group_6971" data-name="Group 6971" transform="translate(0 0)">
                <g id="Group_6970" data-name="Group 6970" transform="translate(0 0)">
                  <g id="Group_6967" data-name="Group 6967">
                    <circle id="Ellipse_1452" data-name="Ellipse 1452" cx="15" cy="15" r="15" fill="#ffd053" />
                  </g>
                  <g id="Group_6968" data-name="Group 6968" transform="translate(2.36 2.197)">
                    <path
                      id="Path_6833"
                      data-name="Path 6833"
                      d="M1268.723,524.432a15,15,0,0,1-20.488,20.865,15,15,0,1,0,20.488-20.865Z"
                      transform="translate(-1248.235 -524.432)"
                      fill="#ffc448"
                    />
                  </g>
                  <g id="Group_6969" data-name="Group 6969" transform="translate(1.002 1.107)">
                    <path
                      id="Path_6834"
                      data-name="Path 6834"
                      d="M1241.556,533.714a13.926,13.926,0,0,1,24.966-8.489,13.927,13.927,0,1,0-23.115,15.431A13.864,13.864,0,0,1,1241.556,533.714Z"
                      transform="translate(-1240.519 -518.239)"
                      fill="#ffd77a"
                    />
                  </g>
                </g>
              </g>
              <g id="Group_6974" data-name="Group 6974" transform="translate(4.32 7.398)">
                <g id="Group_6972" data-name="Group 6972" transform="translate(0 0.918)">
                  <circle
                    id="Ellipse_1453"
                    data-name="Ellipse 1453"
                    cx="3.439"
                    cy="3.439"
                    r="3.439"
                    transform="translate(0 6.789) rotate(-80.783)"
                    fill="#fff"
                  />
                  <path
                    id="Path_6835"
                    data-name="Path 6835"
                    d="M1275.181,563.07a3.427,3.427,0,0,0-4.149-.514.85.85,0,0,0-.186.445c-.067.717.858,1.391,2.068,1.5s2.244-.376,2.311-1.094A.812.812,0,0,0,1275.181,563.07Z"
                    transform="translate(-1268.824 -561.575)"
                    fill="#2c2c2c"
                  />
                </g>
                <g id="Group_6973" data-name="Group 6973" transform="translate(12.552 0)">
                  <circle
                    id="Ellipse_1454"
                    data-name="Ellipse 1454"
                    cx="3.439"
                    cy="3.439"
                    r="3.439"
                    transform="translate(0 4.863) rotate(-45)"
                    fill="#fff"
                  />
                  <path
                    id="Path_6836"
                    data-name="Path 6836"
                    d="M1351.725,563.07a3.427,3.427,0,0,0-4.149-.514.852.852,0,0,0-.186.445c-.068.717.858,1.391,2.068,1.5s2.244-.376,2.311-1.094A.82.82,0,0,0,1351.725,563.07Z"
                    transform="translate(-1344.45 -560.657)"
                    fill="#2c2c2c"
                  />
                </g>
              </g>
              <path
                id="Path_6837"
                data-name="Path 6837"
                d="M1299.016,608.7a6.04,6.04,0,0,0-2.835-1.508,7.657,7.657,0,0,0-3.235-.059,8.1,8.1,0,0,0-2.971,1.232,7.037,7.037,0,0,0-2.182,2.294.352.352,0,0,0,.461.491l.329-.162c.772-.382,1.5-.743,2.242-1.032a14.059,14.059,0,0,1,2.521-.749,12.415,12.415,0,0,1,2.6-.223,16.278,16.278,0,0,1,2.76.314.352.352,0,0,0,.312-.6Z"
                transform="translate(-1278.434 -590.262)"
                fill="#2c2c2c"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SadEmoji;
