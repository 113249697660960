import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getRegions } from './action';

const getInitialState = {
  loading: false,
  error: false,
  data: [],
};

const regions = handleActions(
  {
    [getRegions]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [getRegions.success]: (state, { payload: { data } }) => ({
      ...state,
      loading: false,
      error: false,
      data,
    }),
    [getRegions.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  getInitialState
);

export default combineReducers({ regions });
