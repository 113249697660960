export const axiosData = {
  getComments: {
    method: 'get',
    url: '/api/order/comments/:id',
  },
  createComment: {
    method: 'post',
    url: '/api/order/comments/:id',
  },
};
