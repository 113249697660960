import React from 'react';
import PropTypes from 'prop-types';
import { useDropdown } from './useDropdown';

import classNames from './dropdown.module.scss';

const Dropdown = ({ button, children, active, listItemClass, placementRight }) => {
  const { isOpen, toggleDropdown } = useDropdown(active);

  return (
    <div className={` ${classNames.ddownItem} dropdown ${isOpen ? 'show' : ''} ${listItemClass || ''}`}>
      <div className={`${classNames.dropdownToggle} dropdown-toggle `} onClick={toggleDropdown}>
        {!active && button}
      </div>
      <div
        className={`${classNames.ddownItemContent} dropdown-menu ${placementRight ? 'dropdown-menu-right' : ''} ${
          isOpen ? 'show' : ''
        } `}
        aria-labelledby="dropdownMenuButton"
        onClick={toggleDropdown}
      >
        {children}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  button: PropTypes.node,
  children: PropTypes.node,
  hover: PropTypes.bool,
  listItemClass: PropTypes.string,
  placementRight: PropTypes.bool,
  active: PropTypes.bool,
};
export default Dropdown;
