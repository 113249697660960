import React, { useLayoutEffect, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { bool, number, object, string } from 'prop-types';
import classNames from './onGoingChart.module.scss';

import SmallArrowSvg from '../../../svgs/SmallArrowSvg';

export default function OnGoingChart() {
  const [sum, setSum] = useState(0);
  const [animatedData, setAnimatedData] = useState([]);

  useEffect(() => {
    let currentIndex = 0;
    const animationInterval = setInterval(() => {
      setAnimatedData(data.slice(0, currentIndex + 1));
      currentIndex += 1;

      if (currentIndex >= data.length) {
        clearInterval(animationInterval);
      }
    }, 500);

    return () => {
      clearInterval(animationInterval);
    };
  }, []);

  const data = [
    { name: 'UA', real: 400, test: 100 },
    { name: 'RTG', real: 150, test: 200 },
    { name: 'LAL', real: 250, test: 300 },
  ];

  useLayoutEffect(() => {
    let sum = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data.length; i++) {
      const { real = 0, test = 0 } = data[i];
      sum += real + test;
    }

    setSum(sum);
  }, []);

  const CustomBarUsed = (props) => {
    const { x, y, width, fill, payload } = props;
    if (Number.isNaN(width)) {
      return <span />;
    }
    return (
      <g>
        <rect x={x} y={y} width={width} height={25} fill={fill} strokeWidth={2} />
        <text
          className={!payload?.real ? classNames.hiddenText : ''}
          x={width < 380 ? x + width + 25 : x + width - 25}
          y={y + 25 / 2}
          fill={width < 380 ? 'black' : '#ffffff'}
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {((payload?.real / sum) * 100).toFixed()}%
        </text>
      </g>
    );
  };
  const CustomBarNotUsed = ({ x, y, width, fill, payload }) => {
    if (Number.isNaN(width)) {
      return <span />;
    }
    return (
      <g>
        <rect x={x} y={y} width={width} height={25} fill={fill} strokeWidth={2} />
        <text
          className={!payload?.test ? classNames.hiddenText : ''}
          x={width < 380 ? x + width + 25 : x + width - 25}
          y={y + 25 / 2}
          fill={width < 380 ? 'black' : '#ffffff'}
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {((payload?.test / sum) * 100).toFixed()}%
        </text>
      </g>
    );
  };
  const mixedPropTypes = {
    x: number,
    y: number,
    width: number,
    fill: string,
    payload: object,
  };
  CustomBarNotUsed.propTypes = mixedPropTypes;
  CustomBarUsed.propTypes = mixedPropTypes;

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { real, test, name } = payload[0]?.payload;
      return (
        <div
          style={{
            backgroundColor: 'whitesmoke',
            opacity: '0.9',
            fontSize: '15px',
            fontWeight: 'normal',
            width: '80px',
          }}
        >
          <p>{name}</p>
          <p>Real: {real}</p>
          <p>Test: {test}</p>
        </div>
      );
    }

    return null;
  };

  CustomTooltip.propTypes = {
    active: bool,
    payload: object,
    label: string,
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <span>Real 40/Test 7 (Ongoing Campaign)</span>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={animatedData} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="real" fill="#066BC6" shape={<CustomBarUsed />} />
          <Bar dataKey="test" fill="#252733" shape={<CustomBarNotUsed />} />
        </BarChart>
      </ResponsiveContainer>
      <div className={classNames.chartControls}>
        <div className={classNames.chartItem}>
          <div />
          <p>Real</p>
          <span>
            more
            <SmallArrowSvg />
          </span>
        </div>
        <div className={classNames.chartItem}>
          <div />
          <p>Test</p>
          <span>
            more
            <SmallArrowSvg />
          </span>
        </div>
      </div>
    </>
  );
}
