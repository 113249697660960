import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import {
  selectInitialFetchingLoading,
  selectIsAuthenticated,
  selectUser,
  selectUserRole,
} from './store/user/selectors';
import { initialFetching } from './store/user/actions';
// eslint-disable-next-line import/no-cycle
import { getFilteredPagesByPermissions } from './helpers';

export const useApp = () => {
  const loading = useSelector(selectInitialFetchingLoading);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const role = useSelector(selectUserRole);
  const pages = useMemo(() => getFilteredPagesByPermissions(role), [role]);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  useEffect(() => {
    dispatch(initialFetching());
  }, []);

  return { loading, pages, user, isAuthenticated, role };
};
