import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOpenConfirmation } from '../../../../hooks/useOpenConfirmation';
import { selectRemoveWorker } from '../../../../store/workers/selectors';
import { removeWorker } from '../../../../store/workers/actions';
import { openModal } from '../../../../store/modals/actions';
import { modalNames } from '../../../../store/modals/constants';
import { Lang } from '../../../../T';
import { selectLang } from '../../../../store/user/selectors';

export const useWorkerRow = ({ id }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const confirm = useOpenConfirmation();
  const { loading } = useSelector(selectRemoveWorker);
  const dispatch = useDispatch();

  const handleDelete = useCallback(
    (email) => {
      confirm({
        title: T.delete,
        description: `${T.are_you_sure_delete_user} - ${email}`,
        okText: T.delete,
        onOk: () => dispatch(removeWorker({ id })),
      });
    },
    [id]
  );

  const openEditModal = useCallback(() => {
    dispatch(openModal({ name: modalNames.createEditWorker, data: { id } }));
  }, [id]);

  return { handleDelete, openEditModal, loading, dispatch };
};
