export const createCommentDataHandler = (values) => {
  if (values.text.indexOf('@') === 0) {
    values.text = values.text.replaceAll('@', ' @@');
  } else {
    values.text = values.text.replaceAll(' @', ' @@');
  }
  return values.replyId
    ? {
        comment: {
          text: values.text,
          replyId: values.replyId,
        },
      }
    : {
        comment: {
          text: values.text,
        },
      };
};
