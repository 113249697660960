import React, { Suspense, useEffect, useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './components/loader/Loader';
import { useApp } from './useApp';
import PrivateRoute from './components/private-route/PrivateRoute';
import Modals from './modals/Modals';
import Layout from './layout/Layout';
import AuthenticationRoute from './components/authentication-route/AuthenticationRoute';

import NotFoundPage from './pages/notFoundPage/NotFoundPage';
import showNotification from './modals/components/notification/showNotification';
import { isIOS } from './helpers';
import { Lang } from './T';
import { selectLang } from './store/user/selectors';
import { setLang } from './store/user/actions';
import { routes } from './routes';
import { localStorageNames } from './localStorageNames';

const App = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { loading, pages, isAuthenticated } = useApp();
  const currentPathname = window.location.pathname.split('/')[1];
  console.log(currentPathname);
  const notification = useMemo(() => {
    if (isIOS()) {
      return false;
    }
    return Notification?.permission === 'denied'; // Todo chshtel Haykic
  }, []);

  const dispatch = useDispatch();
  const firebaseMode = true;

  useEffect(() => {
    const IS_YEREVAN = process.env.REACT_APP_LANG === 'true';
    const localLang = localStorage.getItem('lang');
    if (localLang) {
      dispatch(setLang(IS_YEREVAN ? localLang : 'en'));
    } else {
      dispatch(setLang(IS_YEREVAN ? 'am' : 'en'));
    }
    if (`/${currentPathname}` === routes.workerRegistration) {
      localStorage.removeItem(localStorageNames.authentication);
      localStorage.removeItem(localStorageNames.refreshToken);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('lang', lang);
  }, [lang]);

  useEffect(() => {
    document.body.classList.add(`${currentPathname}-bg-color`);
    return () => document.body.classList.remove(`${currentPathname}-bg-color`);
  }, [currentPathname]);

  useEffect(() => {
    if (isAuthenticated && notification) {
      showNotification(dispatch, T.warn_get_notifications, firebaseMode);
    }
  }, [notification, isAuthenticated]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <AuthenticationRoute />
          <Suspense fallback="...loading">
            <Switch>
              {pages?.map(({ Component, path, exact }, index) => {
                return (
                  <PrivateRoute path={path} exact={exact} key={index}>
                    <Layout key="layout" path={path}>
                      <Component />
                    </Layout>
                  </PrivateRoute>
                );
              })}
              {isAuthenticated && currentPathname !== 'privacy' && <Route path="*" component={NotFoundPage} />}
            </Switch>
          </Suspense>
        </>
      )}
      <Modals />
    </>
  );
};

export default App;
