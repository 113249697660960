import React from 'react';
import PropTypes from 'prop-types';
import useSearch from './useSearch';
import classNames from './search.module.scss';
import SearchSvg from '../../svgs/SearchSvg';
import CloseSvg from '../../svgs/CloseSvg';

const Search = ({ timer = 500, onChange, setSearch = () => {}, ...rest }) => {
  const { value, handleChange, handleResetSearch } = useSearch({ timer, onChange, setSearch });

  return (
    <>
      <div className={classNames.searchInputWrapper}>
        <input type="text" value={value} onChange={handleChange} {...rest} />
        <span className={classNames.iconWrapper}>
          {value.length ? (
            <span className={classNames.resetIcon} onClick={handleResetSearch}>
              <CloseSvg />
            </span>
          ) : (
            <span>
              <SearchSvg />
            </span>
          )}
        </span>
      </div>
    </>
  );
};

Search.propTypes = {
  timer: PropTypes.number,
  onChange: PropTypes.func,
  setSearch: PropTypes.func,
};
export default Search;
