export const modalNames = {
  comments: 'comments',
  importProduct: 'importProduct',
  exportProduct: 'exportProduct',
  checkProduct: 'checkProduct',
  createEditBoard: 'createEditBoard',
  confirmation: 'confirmation',
  delete: 'delete',
  createEditWorker: 'createEditWorker',
  createEditAddress: 'createEditAddress',
  alert: 'alert',
  createEditProduct: 'createEditProduct',
  createEditOrder: 'createEditOrder',
  identificationModal: 'identificationModal',
  createEditStickers: 'createEditStickers',
  editUserProfileSettings: 'editUserProfileSettings',
  createEditCD: 'createEditCD',
  attachDeliverymenProduct: 'attachDeliverymenProduct',
  downLoadCSF: 'downLoadCSF',
  notification: 'notification',
  SaleModals: 'SaleModals',
  doneModal: 'doneModal',
  rejectModal: 'rejectModal',
  filterFromProducts: 'filterFromProducts',
  stateProducts: 'stateProducts',
  SaleModalsDone: 'SaleModalsDone',
  SaleModalsReject: 'SaleModalsReject',
  productStatusModal: 'ProductStatusModal',
  createFB: 'createFB',
  createFBAds: 'createFBAds',
  createPreset: 'createPreset',
};
