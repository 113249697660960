import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectConfirmedPassword, selectLang } from '../../store/user/selectors';
import { sendConfirmedPasswords } from '../../store/user/actions';
import { Lang } from '../../T';

export const useRecoverPassword = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, success, error } = useSelector(selectConfirmedPassword);
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  const [eyeVisible, setEyeVisible] = useState({
    password: false,
    retryPassword: false,
  });

  const handleEyeVisible = (key) => {
    setEyeVisible((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  useEffect(() => {
    if (error) history.push('/resetPassword');
  }, [error]);

  useEffect(() => {
    if (success) history.push('/signinmoulk');
  }, [success]);
  const handleSubmit = useCallback((values) => {
    dispatch(sendConfirmedPasswords({ values, token, T }));
  }, []);

  return { handleSubmit, loading, eyeVisible, handleEyeVisible };
};
