export const routes = {
  welcomePage: '/',
  home: '/home',
  authentication: '/signinmoulk',
  workers: '/workers',
  states: '/states',
  draggableBoard: '/board/:id/:filterId',
  workerRegistration: '/worker-registration',
  domain: '/domain',
  products: '/products',
  // armPost: '/arm-post',
  // reports: '/reports',
  privacy: '/privacy',
  resetPassword: '/resetPassword',
  recoverPassword: '/recover-password',
  store: '/store',
  customers: '/customers',
  marketing: '/marketing',
  // analytics: '/analytics',
  special: '/analytics',
  specialPage: '/analytics/:typeName',
  history: '/history',
  productBuying: '/product-buying',
  productBuyingStats: '/product-buying/:productId',
};
