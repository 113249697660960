import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDraggableBoard } from './useDraggableBoard';
// eslint-disable-next-line import/no-cycle
import Column from './components/column/Column';
import CreateList from './components/cerate-list/CreateList';
import './styles.css';

const DraggableBoard = () => {
  const {
    changeContent,
    data = {},
    loading,
    changeListOrder,
    additional,
    archived,
    isOpenFilter,
    setChangeContent,
  } = useDraggableBoard();

  return (
    <div>
      {loading && !Object.keys(data).length ? (
        '...loading'
      ) : (
        <DragDropContext onDragEnd={changeListOrder}>
          <div
            className="app d-flex flex-row draggbleContainer"
            onScroll={(e) => {
              const { scrollLeft } = e.nativeEvent.target;
              [...data?.columnOrder, 'plus'].forEach((element, index) => {
                if (scrollLeft > index * 360 - 40 && scrollLeft < (index + 1) * 360 - 40) {
                  setIndicator(index);
                }
              });
            }}
          >
            <Droppable droppableId="all-droppables" direction="horizontal" type="column" isDropDisabled={archived}>
              {(provided) => (
                <div className="columns draggableItem" {...provided.droppableProps} ref={provided.innerRef}>
                  {data?.columnOrder?.map((columnId, index) => (
                    <Column
                      changeContent={changeContent}
                      key={columnId}
                      column={data.columns[columnId]}
                      index={index}
                      additionalInfo={additional?.[columnId]}
                      isOpenFilter={isOpenFilter}
                      archived={archived}
                      setChangeContent={setChangeContent}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {!archived && <CreateList />}
          </div>
          {/* {size[0] < 576 && ( */}
          {/* <SliderIndicator indicator={indicator} columnOrder={[...data?.columnOrder, 'plus']} /> */}
          {/* )} */}
        </DragDropContext>
      )}
    </div>
  );
};

export default DraggableBoard;
