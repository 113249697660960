import React from 'react';

import classNames from './privacyPolicy.module.scss';

// jagajuga
const PrivacyPolicy = () => {
  const IS_YEREVAN = process.env.REACT_APP_LANG === 'true';

  return (
    <div className={`${classNames.PrivacyPage} d-flex justify-content-center align-items-center h-100 w-100`}>
      <div className={classNames.Privacy}>
        {IS_YEREVAN ? (
          <p>
            Գաղտնիության քաղաքականություն Moulk ընկերության համար Թարմացվել է` 11․07.2022 Մեծատառով գրված բառերը, որոնք
            չեն սահմանվում սույն Գաղտնիության քաղաքականությամբ՝ սահմանված են Ընկերության կայքում տեղադրված Ընդհանուր
            Դրույթներով և Պայմաններով: Բարի գալուստ <a href="https://crm.moulk.group/">https://crm.moulk.group/</a>{' '}
            (Կայք), որը պատկանում և շահագործվում է ԱՁ Գագիկ Պետրոսյան ընկերության կողմից (Ընկերություն, մենք, մեզ, մեր,
            Օպերատոր): Moulk-ը վաճառքներ և առաքումներ իրականացնող ընկերություն է։ Որպեսզի մենք կարողանանք մատուցել
            Ծառայություն Ընկերությունը հավաքում և մշակում է մեր Կայքի այցելուների և Օգտատերերի անձնական տվյալները: Սույն
            Գաղտնիության քաղաքականությունը վերաբերում է Ընկերության կողմից հավաքագրված անձնական տեղեկատվությանը, երբ
            դուք օգտագործում եք մեր Կայքը և/կամ Ծառայությունները, կամ այլ կերպ տրամադրում եք մեզ անձնական
            տեղեկություններ: Խնդրում ենք ուշադիր կարդալ այն, որպեսզի հասկանաք ձեր անձնական տեղեկատվության վերաբերյալ մեր
            քաղաքականությունն ու գործելակերպը, և թե ինչպես ենք մենք վերաբերվելու դրանց: Եթե համաձայն չեք մեր
            Գաղտնիության քաղաքականության հետ, խնդրում ենք մի ներբեռնեք, տեղադրեք, գրանցվեք, մուտք գործեք կամ օգտագործեք
            Կայքը կամ Ծառայությունները: Այնուամենայնիվ, խնդրում ենք կարդալ սույն Գաղտնիության քաղաքականությունը նախքան
            մեր Ծառայություններից օգտվելը, որը ձեզ մանրամասն տեղեկություն կտա անձնական տվյալների մշակման մեր իրավական
            հիմքերի և ձեր անձնական տվյալների մշակման հետ կապված ձեր իրավունքների մասին: Սույն Գաղտնիության
            քաղաքականությունը (այսուհետ՝ Քաղաքականություն) վերաբերում է բոլոր այն տեղեկատվությանը, որն Օպերատորը կարող է
            ստանալ Օգտատիրոջ մասին վերջինիս կողմից Ծառայությունից օգտվելու ընթացքում՝ համաձայն կողմերի միջև կնքված
            Համաձայնագրի։ ԵՐԵԽԱՆԵՐԻ ԳԱՂՏՆԻՈՒԹՅՈՒՆԸ Ընկերությունը միտումնավոր տեղեկություններ չի հավաքում 18 տարեկանից
            ցածր երեխաներից: Եթե դուք 18 տարեկանից ցածր եք, խնդրում ենք մեզ որևէ անձնական տվյալ չներկայացնել: Մենք
            խրախուսում ենք ծնողներին և խնամակալներին վերահսկել իրենց երեխաների ինտերնետի օգտագործումը և օգնել կիրառելու
            մեր Գաղտնիության քաղաքականությունը՝ հրահանգելով իրենց երեխաներին երբեք չտրամադրել անձնական տվյալներ առանց
            ծնողների և խնամակալների թույլտվության: Եթե հիմքեր ունեք ենթադրելու, որ 18 տարեկանից փոքր երեխան անձնական
            տվյալներ է տրամադրել Օպերատորին Կայքի կամ Ծառայությունների միջոցով, խնդրում ենք կապ հաստատել մեզ հետ և մենք
            անմիջապես կուղեփակենք և կոչնչացնենք այդ տեղեկատվությունը մեր տվյալների բազայից: ԳԱՂՏՆԻՈՒԹՅԱՆ ՔԱՂԱՔԱԿԱՆՈՒԹՅԱՆ
            ՓՈՓՈԽՈՒԹՅՈՒՆՆԵՐԸ Գաղտնիության քաղաքականությունը վերջին անգամ թարմացվել է վերը նշված ամսաթվին, սակայն մենք
            առաջարկում ենք ժամանակ առ ժամանակ վերանայել այն, քանի որ մեր Կայքը և/կամ Ծառայությունները և մեր
            գործունեությունը կարող է փոխվել: Արդյունքում, երբեմն կարող է անհրաժեշտություն լինել, որ մենք փոփոխություններ
            կատարենք Գաղտնիության քաղաքականության մեջ: Ընկերությունն իրեն իրավունք է վերապահում թարմացնել կամ փոփոխել
            սույն Գաղտնիության քաղաքականությունը ցանկացած ժամանակ՝ առանց նախնական ծանուցման: Այնուամենայնիվ, եթե մենք
            փոփոխություններ կատարենք, որոնք, մեր կարծիքով, էականորեն կազդեն այս Գաղտնիության քաղաքականության կամ Ձեր
            իրավունքների վրա, մենք անմիջապես կտեղեկացնենք Ձեզ այդ փոփոխությունների մասին: Ձեր կողմից Կայքի և/կամ
            Ծառայությունների շարունակական օգտագործումը սույն Գաղտնիության քաղաքականության ցանկացած փոփոխությունից կամ
            վերանայումից հետո կնշանակի Ձեր համաձայնությունը նման փոփոխված Գաղտնիության քաղաքականության պայմանների հետ:
            Ծառայությունից օգտվելը նշանակում է Օգտատիրոջ անվերապահ համաձայնություն Քաղաքականությանը և դրանում նշված նրա
            անձնական տեղեկատվության մշակման պայմաններին: Ընդունելով Օֆերտան՝ Օգտատերը տալիս է իր համաձայնությունը
            Օպերատորին՝ մշակելու իր անձնական տվյալները, այդ թվում՝ կատարելու անձնական տվյալների հավաքագրում,
            համակարգում, պահպանում, փոփոխություն, օգտագործում, փոխանցում, ապանձնավորում, արգելափակում, ոչնչացում: Նշված
            պայմանների հետ անհամաձայնության դեպքում Օգտատերը պետք է ձեռնպահ մնա Ծառայությունից օգտվելուց:
            Քաղաքականության համար «Օգտատեր» հասկացությունն ունի Ծառայությունից օգտվող նշանակությունը: Օգտատերը նաև իր
            համաձայնությունն է տալիս Օպերատորի կողմից Համաձայնագրի նպատակների համար նախատեսված գործողություններն
            իրականացնելու համար իր անձնական տվյալների փոխանցմանը երրորդ անձանց, եթե առկա է Օպերատորի և երրորդ անձանց
            միջև պատշաճ կնքված Պայմանագիր կամ Համաձայնագիր: Համաձայնագրի նպատակների համար գաղտնի չի համարվում այն
            տեղեկատվությունը, որը կարող է ստացվել այլ եղանակով՝ առանց գաղտնիության պահանջների խախտման: Օգտատերը կրում է
            իր կողմից Օպերատորին տրամադրված տեղեկատվության ոչ ճշգրիտ լինելու բացասական հետևանքների ռիսկերը: 1․ Օպերատորի
            կողմից մշակվող Օգտատերերի անձնական տվյալները 1․ Քաղաքականության շրջանակներում «Օգտատիրոջ անձնական տվյալներ»
            նշանակում է. 1․ Անձնական այն տվյալները, որոնք Օգտատերը տրամադրում է իր մասին Համակարգում գրանցվելիս (հաշիվ
            ստեղծելիս) կամ Ծառայությունից օգտվելիս (անուն, ազգանուն, հեռախոսահամար, էլ. փոստ, պաշտոն, Ադմինիստրատորի
            անվանում): 2․ Այն տվյալները, որոնք ավտոմատ կերպով փոխանցվում են Ծառայությունից օգտվելու ընթացքում՝
            օգտագործելով Օգտատիրոջ սարքում տեղադրված և նրա կողմից օգտագործվող ծրագրային ապահովման վերաբերյալ
            տեղեկությունները, այդ թվում՝ IP հասցեն, Օգտատիրոջ բրաուզերի (կամ Ծառայություն մուտք գործող այլ ծրագրի) մասին
            տեղեկությունները, սարքավորումների և ծրագրային ապահովման տեխնիկական բնութագրերը, այլ տեղեկություններ: Սույն
            կետում նշված տվյալները և Քաղաքականության 5-րդ կետում նշված տվյալները իրենց բնույթով անանուն են և չեն կարող
            կապված լինել որևէ Օգտատիրոջ հետ։ 3․ Օգտատիրոջ մասին այլ տեղեկություններ, որոնց մշակումը նախատեսված է
            Օպերատորի Ծառայությունից օգտվելու Պայմաններով: 2․ Քաղաքականությունը վերաբերում է միայն Ծառայությունից
            օգտվելու ընթացքում մշակված տեղեկատվությանը: Օպերատորը չի վերահսկում և պատասխանատվություն չի կրում Երրորդ
            անձանց կայքերի կողմից տեղեկատվության մշակման համար, որոնց Օգտատերը կարող է հետևել Համակարգում առկա հղումների
            միջով: 3․ Օպերատորը չի ստուգում Օգտատիրոջ կողմից տրամադրված անձնական տվյալների արժանահավատությունը և չի
            կարող որոշել Օգտատիրոջ գործունակությունը: Օպերատորը առաջնորդվում է այն կանխավարկածով, որ Օգտատերը տրամադրում
            է ճշգրիտ անձնական տվյալներ և, ըստ անհրաժեշտության, այն թարմացնում է: 1․4․ Օպերատորը պատասխանատվություն չի
            կրում Օգտատիրոջ կողմից տրամադրված տեղեկատվության բովանդակության, ստույգության, օրինականության կամ
            արժանահավատության, ինչպես նաև երրորդ անձանց կողմից նշված տեղեկատվությունն ապօրինի օգտագործելու հնարավոր
            հետևանքների համար: 2․ Օգտատերերի անձնական տեղեկատվության մշակման նպատակները 1․ Օպերատորը մշակում և պահպանում
            է Օգտատիրոջ միայն այն անձնական տվյալները, որոնք անհրաժեշտ են վերջինիս Համակարգում գրանցվելու, Համաձայնագիրը
            կնքելու (Օֆերտան ակցեպտավորելու) և Ծառայությունից օգտվելու համար, և այն ժամկետով, որն օբյեկտիվորեն անհրաժեշտ
            է տվյալներ մշակելու նպատակներն իրականացնելու համար, բացառությամբ այն դեպքերի, երբ օրենքը նախատեսում է
            որոշակի ժամկետով անձնական տվյալների պարտադիր պահպանում: 2․ Օպերատորը մշակում է Օգտատիրոջ անձնական տվյալները
            հետևյալ նպատակներով. Համակարգի Ծառայությունից օգտվելու շրջանակներում Օգտատիրոջ նույնականացում, Համաձայնագրի
            շրջանակներում Օգտատիրոջն անհատականացված Ծառայության տրամադրում,Օգտատիրոջ հետ հաղորդակցության ապահովում, այդ
            թվում՝ Ծառայությունների օգտագործման և Համաձայնագրի Պայմանաների կատարման վերաբերյալ ծանուցումների,
            հարցումների և տեղեկությունների ուղարկում, Ծառայության որակի բարելավում և օգտագործման հարմարավետություն, նոր
            ծառայությունների մշակում, Անանուն տվյալների հիման վրա վիճակագրական և այլ հետազոտությունների անցկացում: 1․
            Օգտատերերի անձնական տվյալների մշակման և երրորդ անձանց փոխանցման պայմանները 1․ Օպերատորը պահպանում է
            Օգտատերերի անձնական տվյալները սույն Գաղտնիության քաղաքականության, «Անձնական տվյալների պաշտպանության մասին»
            ՀՀ օրենքի և այլ իրավական ակտերի պահանջներին համապատասխան: 2․ Օգտատիրոջ անձնական տվյալների գաղտնիությունը
            պահպանվում է, բացառությամբ այն դեպքերի, երբ Օգտատերը կամավոր կերպով տեղեկատվություն է տրամադրում իր մասին
            անորոշ թվով անձանց ընդհանուր մուտքի համար: 3․ Օպերատորն իրավունք ունի Օգտատիրոջ անձնական տվյալները փոխանցել
            երրորդ կողմերին, երբ․ Օգտատերը տվել է իր համաձայնությունը, Օգտատիրոջ անձնական տվյալների փոխանցումն անհրաժեշտ
            է Համաձայնագրի կատարման համար. Օգտատիրոջ անձնական տվյալների փոխանցումը նախատեսված է ՀՀ օրենսդրությամբ,
            Անհրաժեշտություն է առաջացել պաշտպանելու Օպերատորի կամ երրորդ անձանց իրավունքները և օրինական շահերը Օգտատիրոջ
            կողմից խախտվել են Համաձայնագրի Պայմանները, Քաղաքականությունը կամ Օպերատորի և Օգտատիրոջ հարաբերությունները
            կարգավորող այլ, այդ թվում՝ օրենսդրական նորմեր, Օգտատիրոջ անձնական տվյալների մշակման արդյունքում ստացված
            ապանձնավորված վիճակագրական տվյալներն անհրաժեշտ են Օպերատորին՝ հետազոտության համար: 4․ Օգտատերերի անձնական
            տվյալները մշակելիս Օպերատորը ղեկավարվում է սույն Գաղտնիության քաղաքականությամբ, «Անձնական տվյալների
            պաշտպանության մասին» ՀՀ օրենքով և այլ իրավական ակտերով: 2․ Անձնական տվյալների փոփոխություն և ոչնչացում:
            Անձնական տվյալների պարտադիր պահպանում 1․ Օգտատերը ցանկացած պահի կարող է փոփոխել (թարմացնել, լրացնել) իր
            կողմից տրամադրված անձնական տվյալները կամ դրա մի մասը։ 2․ Օգտատերը կարող է ոչնչացնել նաև իր տրամադրած
            անձնական տվյալները։ 3․ Քաղաքականությունով կարող է սահմանափակվել 4.1. և 4.2. կետերով նախատեսված իրավունքները՝
            ՀՀ գործող օրենսդրության պահանջներին համապատասխան: Մասնավորապես, նման սահմանափակումները կարող են նախատեսվել
            Օպերատորի պարտավորությամբ՝ օրենքով սահմանված ժամկետներում պահպանելու Օգտատիրոջ կողմից փոփոխված կամ ոչնչացված
            տեղեկատվությունը և օրենքով սահմանված կարգով այն փոխանցելու պետական մարմնին: 4․ Թխուկ (cookie files) ֆայլերի
            օգտագործումը Ըստ ԵՄ անձնական տվյալների պատպանության ընդհանուր կանոնակարգի (GDPR) (1) (6)-րդ հոդվածի, մենք
            օգտագործում ենք մեր վեբկայքում «թխուկ ֆայլեր» (cookie files) աշխատանքը օպտիմալացնելու համար: Թխուկ ֆայլերը
            փոքր տեքստային ֆայլեր են, որոնք ավտոմատ կերպով ստեղծվում են ձեր զննիչում (browser) և պահվում ձեր սարքում
            (օրինակ՝ դյուրակիր համակարգչում, գրասալիկում, սմարթֆոնում), երբ այցելում եք մեր կայք: Տեղեկատվությունը, որը
            ստեղծվում է ըստ օգտագործվող հատուկ սարքի, պահվում է թխուկ ֆայլում:Սա չի նշանակում, որ մենք կկարողանանք
            ուղղակիորեն նույնականացնել ձեզ: Թխուկ ֆայլերը նախատեսված են ավելի հարմար դարձնելու մեր ծառայություններից
            օգտվելը: Մենք օգտագործում ենք սեանսային թխուկ ֆայլեր, օրինակ՝ որոշելու համար, որ դուք արդեն այցելել եք մեր
            կայքում առանձին էջեր: Մեր կայքից դուրս գալուց հետո թխուկ ֆայլերը ավտոմատ կերպով ջնջվում են: Հարմարավետ
            օգտագործման համար մենք նաև օգտագործում ենք ժամանակավոր թխուկ ֆայլեր, որոնք պահվում են ձեր սարքի վրա որոշակի
            ժամանակահատվածում: Եթե ծառայություններից օգտվելու համար դուք կրկին այցելեք մեր կայք, ապա անմիջապես
            կհայտնաբերվի, որ դուք արդեն այցելել եք մեր կայքը, ինչ տվյալներ են մուտքագրվել ձեր կողմից և ինչ տեղադրումներ
            են կատարվել, ուստի, դուք ստիպված չեք լինի մուտքագրել այդ ամենը նորից: Շատ զննիչներ (browser) թխուկ ֆայլերը
            ընդունում են ավտոմատ կերպով: Դուք կարող եք հարմարեցնել ձեր զննիչի տեղադրումը այնպես, որ Ձեր սարքի վրա
            չպահպանվեն թխուկ ֆայլերը կամ տեղադրեք ծանուցում, որը կհայտնվի ամեն անգամ, երբ նոր թխուկ ֆայլեր տեղադրվեն:
            Սակայն, թխուկ ֆայլերի ամբողջական անջատումը կարող է հանգեցնել այն բանի, որ դուք չեք կարողանա լիարժեք չափով
            օգտագործել մեր կայքում եղած բոլոր գործառույթները: Օպերատորի կողմից Օգտատիրոջ սարքավորումներին և Օգտատիրոջ
            սարքավորումներին փոխանցվող թխուկները կարող են օգտագործվել Օպերատորի կողմից՝ Օգտատիրոջն անհատականացված
            Ծառայություններ տրամադրելու և Ծառայությունները բարելավելու նպատակներով վիճակագրական և հետազոտական կատարելու
            նպատակներով։ Օգտատերը տեղյակ է, որ իր կողմից Ծառայությունից օգտվելու համար օգտագործվող սարքավորումները և
            ծրագրաշարը կարող են ունենալ թխուկների հետ գործառնություններն արգելելու, ինչպես նաև նախկինում ստացված
            թխուկները ջնջելու գործառույթ: Թխուկների կառուցվածքը, դրա բովանդակությունը և տեխնիկական պարամետրերը որոշվում
            են Ընկերության կողմից և կարող են փոխվել առանց Օգտատիրոջ նախնական ծանուցման: Ծառայության մեջ տեղադրված
            հաշվիչները կարող են օգտագործվել Օգտատիրոջ թխուկները վերլուծելու, Ծառայությունների օգտագործման վերաբերյալ
            վիճակագրական տեղեկություններ հավաքելու և մշակելու, ինչպես նաև Ծառայությունների գործունեությունը ընդհանրապես
            կամ դրանց առանձին գործառույթները, մասնավորապես, ապահովելու համար: Հաշվիչների շահագործման տեխնիկական
            պարամետրերը որոշվում են Օպերատորի կողմից և կարող են փոխվել առանց Օգտատիրոջ նախնական ծանուցման: 4․ Օգտատիրոջ
            անձնական տվյալների պաշտպանության համար ձեռնարկված միջոցներ Օպերատորը ձեռնարկում է անհրաժեշտ և բավարար
            կազմակերպչական և տեխնիկական միջոցներ՝ պաշտպանելու Օգտատիրոջ անձնական տվյալները չարտոնված կամ պատահական
            մուտքերից, ոչնչացումից, փոփոխումից, արգելափակումից, պատճենումից, տարածումից, ինչպես նաև երրորդ անձանց այլ
            անօրինական գործողություններից: 5․ Քաղաքականության մեջ փոփոխություններ և լրացումներ կատարելու կարգը 1․
            Օպերատորն իրավունք ունի փոփոխություններ և լրացումներ կատարել Քաղաքականության մեջ: Քաղաքականության գործող
            տարբերակում փոփոխություններ և լրացումներ կատարելիս նշվում է վերջին թարմացման ամսաթիվը: Քաղաքականության նոր
            տարբերակն ուժի մեջ է մտնում հրապարակման պահից։ 2․ Քաղաքականությունը կիրառելու արդյունքում Օպերատորի և
            Օգտատիրոջ միջև ծագած հարաբերությունները կարգավորվում են ՀՀ օրենսդրությամբ: 6․ Հետադարձ կապ Քաղաքականության
            վերաբերյալ բոլոր առաջարկները կամ հարցերը Օգտատերն կարող է ուղարկել Օպերատորի ——— էլեկտրոնային փոստին: 7․
            Գաղտնիություն և անձնական տվյալների պաշտպանություն․ Ռիսկերի կառավարում 9․1․ Օֆերտայի պայմաններով Համաձայնագիր
            կնքելով՝ Կողմերը պարտավորվում են. 9.1․1․ Չհրապարակել Համաձայնագրի կատարման հետ կապված մյուս Կողմից ստացված
            գաղտնի տեղեկատվությունը, այդ թվում՝ Կողմի անձնական տվյալները։ Ձեռնարկել բոլոր անհրաժեշտ միջոցները
            Ծառայության ընթացքում փոխանակված կամ Կողմերին Ծառայությունից օգտվելու ընթացքում հասանելի դարձած
            տեղեկատվությունն անվտանգ պայմաններում պահելու և պաշտպանելու համար: 9.1.2. Ծառայության միջոցով չկատարել
            փողերի լվացման և ահաբեկչության ֆինանսավորմանն ուղղված անօրինական ֆինանսական գործարքներ, անօրինական առևտուր,
            ինչպես նաև ՀՀ օրենսդրությամբ արգելված այլ գործողություններ, 1․Կանխել ապօրինի առևտրի փորձերը, ապօրինի
            ֆինանսական գործարքները, հանցավոր ճանապարհով ստացված եկամուտների օրինականացման (փողերի լվացման)
            գործողությունները: 2․ Կողմից ստացված գաղտնի տեղեկատվությունը չօգտագործվել որևէ այլ նպատակով՝ բացի
            Համաձայնագրի նպատակներից, 9․1․5․ Համաձայնագրի նպատակների համար ողջամտորեն անհրաժեշտ քանակից ավելի
            չվերարտադրել, չպատճենել, չփոխանցել, չբացահայտել կամ այլ կերպ որևէ երրորդ կողմի հասանելի չդարձնել Կողմից
            ստացված գաղտնի տեղեկատվությունը՝ բացառությամբ օրենքով սահմանված դեպքերի, 9.2. Քաղաքականության դրույթները չեն
            կիրառվում այն տեղեկատվության նկատմամբ, որոնք․ 9․2․1․Օրենքով նախատեսված են որպես հանրամատչելի
            տեղեկություններ, ինչպես նաևԿողմի համաձայնությամբ կամ իր անձնական տվյալները հանրամատչելի դարձնելուն ուղղված
            գիտակցված գործողությունների կատարմամբ մատչելի են դարձել որոշակի կամ անորոշ շրջանակի անձանց համար
            (հանրամատչելի անձնական տվյալներ),9.2.2. Բացահայտվել են օրենքով կամ գործադիր մարմնի որևէ կարգավորող իրավական
            ակտի համաձայն։ 10․ Օպերատորը հետևում և ապահովում է, որ Օգտատերերի անձնական տվյալները մշակվեն ՀՀ
            օրենսդրության պահանջների պահպանմամբ: Ապահովել Օգտատիրոջ անձնական տվյալների, նրա հաշվից կատարված Վճարումների
            և այլ տեղեկատվության գաղտնիությունը, որոնք Օպերատորին հայտնի են դարձել Ծառայությունից օգտվելու հետ կապված:
            Օպերատորը Օգտատիրոջ վերաբերյալ տեղեկատվությունը կարող է բացահայտել և օգտագործվել այլ նպատակներով միայն
            Օգտատիրոջ համաձայնությամբ կամ կարգադրությամբ, բացառությամբ օրենքով սահմանված այն դեպքերի, երբ. •
            տեղեկատվությունը պահանջվում է հրապարակել օրենքով նախատեսված հիմքերով կամ կասկածելի գործարքների վերաբերյալ
            կամ դատարանից կամ լիազորված պետական մարմիններից համապատասխան հարցումներ ստանալուց հետո, • դա անհրաժեշտ է
            Ծառայությունների մատուցման համար (Օպերատորն իրավունք ունի Վճարում ստացող անձին բացահայտել Վճարողի անձնական
            տվյալները և վճարման գործարքին վերաբերող մանրամասնությունները), • դա անհրաժեշտ է Օպերատորի խախտված
            իրավունքների պաշտպանության (այդ թվում՝ դատական) համար, • նման տեղեկատվությունը հասանելի է հանրությանը և
            օրենքով նախատեսված է որպես հանրամատչելի տեղեկություն: Օպերատորը պահպանում է Համակարգի միջոցով կատարված
            գործարքների վերաբերյալ տեղեկությունները Համաձայնագիրը լուծելու օրվանից հաշված 3 (երեք) տարի: Օպերատորը կարող
            է Գաղտնիության, տեղեկատվության պահպանման և անվտանգության ապահովման նպատակով՝ ցանկացած պահի իրականացնել
            Ծառայության տեխնիկական սպասարկման կանխարգելիչ և այլ աշխատանքներ՝ սահմանափակելով Օգտատիրոջ մուտքը
            Ծառայություն: 2022 Moulk
          </p>
        ) : (
          <div>
            <p>
              Privacy Policy Our CRM website values your privacy and is committed to protecting any personal information
              that you provide to us. This Privacy Policy (“Policy”) describes how we collect, use, and disclose
              personal information. By accessing or using our website, you consent to the collection, use, and
              disclosure of your personal information in accordance with this Policy.
            </p>
            <p>
              1. Collection of Personal Information We collect personal information that you voluntarily provide to us,
              such as your name, email address, phone number, and company name when you register for an account or use
              our services. We may also collect personal information from third-party sources, such as public databases
              or social media platforms.
            </p>
            <p>
              2. Use of Personal Information We may use your personal information for the following purposes: To provide
              and improve our services to you; To communicate with you about our services, including sending you
              newsletters, updates, and promotional offers; To respond to your inquiries and support requests; To
              analyze and improve our website and services; To comply with legal obligations and protect our rights and
              property.
            </p>
            <p>
              3. Disclosure of Personal Information We may disclose your personal information to third-party service
              providers that perform services on our behalf, such as hosting providers and payment processors. We
              require these service providers to use your personal information only for the purposes for which it was
              disclosed and to protect it in accordance with this Policy. We may also disclose your personal information
              in response to a valid legal request, such as a court order or subpoena, or to protect our rights and
              property. In the event that our company is acquired or merged with another company, your personal
              information may be transferred to the acquiring company.
            </p>
            <p>
              4. Security of Personal Information We take reasonable measures to protect your personal information from
              unauthorized access, use, or disclosure. However, no method of transmission over the internet or
              electronic storage is completely secure. Therefore, we cannot guarantee the absolute security of your
              personal information.
            </p>
            <p>
              5. Retention of Personal Information We retain your personal information for as long as necessary to
              fulfill the purposes for which it was collected, unless a longer retention period is required by law.
            </p>
            <p>
              6. Your Rights You have the right to access and update your personal information, and to request that we
              delete your personal information. To exercise these rights, please contact us using the contact
              information provided below.
            </p>
            <p>
              7. Children’s Privacy Our website is not intended for use by children under the age of 18. We do not
              knowingly collect personal information from children under the age of 18.
            </p>
            <p>
              8. Changes to this Policy We reserve the right to modify or update this Policy at any time, without prior
              notice. Any changes to the Policy will be effective immediately upon posting. Your continued use of the
              website after any modifications to the Policy constitutes your acceptance of the modified Policy.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
