import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Button from '../../../../../../components/button/Button';
import useAddOrder from './useAddOrder';
import { Lang } from '../../../../../../T';
import { selectLang } from '../../../../../../store/user/selectors';

const AddOrder = ({ id, name, deliveryList, listType }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { handleAddOrder, archived } = useAddOrder({ id, name, deliveryList });

  return !archived && listType === 'newOrder' ? (
    <Button
      className={`${deliveryList ? 'addOrderBtn AfterButtonHas' : 'addOrderBtn AfterButtonHasNot'}`}
      onClick={handleAddOrder}
    >
      {T.add_order}
    </Button>
  ) : null;
};

AddOrder.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  deliveryList: PropTypes.bool,
  listType: PropTypes.string,
};
export default AddOrder;
