import React from 'react';
import classNames from './dashboard.module.scss';
import OnGoingChart from '../../../components/charts/on-going-chart/OnGoingChart';
import TestRealAreaChart from '../../../components/charts/area-chart/AreaChart';
import AccountAndFunChart from '../../../components/charts/account-and-fun-chart/AccountAndFunChart';
import TargetChart from '../../../components/charts/target-chart/TargetChart';

const Dashboard = () => {
  const accountData = [
    { name: 'Used', used: 0.3 },
    { name: 'Not used', notUsed: 60 },
  ];

  const funPageData = [
    { name: 'Used', used: 59 },
    { name: 'Not used', notUsed: 290 },
  ];

  const dataAm = [
    { name: 'New ads', newAds: 23 },
    { name: 'Manage ads', manageAds: 12 },
    { name: 'Turn of ads', turnOffAds: 20 },
    { name: 'Lead server', leadServer: 5 },
  ];

  const dataGe = [
    { name: 'New ads', newAds: 55 },
    { name: 'Manage ads', manageAds: 78 },
    { name: 'Turn of ads', turnOffAds: 99 },
    { name: 'Lead server', leadServer: 120 },
  ];

  const testRealAreaChart = [
    {
      fbDate: '26.06.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 78,
      fb: 56,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 140,
      fb: 120,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 130,
      fb: 123,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 110,
      fb: 96,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '26.06.2022',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 1456,
      fb: 345,
    },
    {
      fbDate: '06.06.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 567,
      fb: 768,
    },
    {
      fbDate: '22.06.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 567,
      fb: 231,
    },
    {
      fbDate: '26.26.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 232,
      fb: 122,
    },
    {
      fbDate: '26.02.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 122,
      fb: 122,
    },
    {
      fbDate: '26.06.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 233,
      fb: 232,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 122,
      fb: 345,
    },
    {
      fbDate: '26.06.2022',
      fbTime: '14:12:02:14',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 455,
      fb: 566,
    },
    {
      fbDate: '06.06.2023',
      fbTime: '14:12:02:14',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 677,
      fb: 788,
    },
    {
      fbDate: '22.06.2023',
      fbTime: '14:12:02:12',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 566,
      fb: 677,
    },
    {
      fbDate: '26.26.2023',
      fbTime: '14:12:02:12',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 566,
      fb: 788,
    },
    {
      fbDate: '26.02.2023',
      fbTime: '14:12:02:34',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 566,
      fb: 455,
    },
    {
      fbDate: '26.06.2023',
      fbTime: '14:12:02:23',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 344,
      fb: 233,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02:12',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 112,
      fb: 122,
    },
    {
      fbDate: '26.06.2022',
      fbTime: '02:13:12',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 99,
      fb: 88,
    },
    {
      fbDate: '26.06.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 78,
      fb: 56,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 140,
      fb: 120,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 130,
      fb: 123,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 110,
      fb: 96,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 160,
      fb: 143,
    },
    {
      fbDate: '26.06.2022',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 1456,
      fb: 345,
    },
    {
      fbDate: '06.06.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 567,
      fb: 768,
    },
    {
      fbDate: '22.06.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 567,
      fb: 231,
    },
    {
      fbDate: '26.26.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 232,
      fb: 122,
    },
    {
      fbDate: '26.02.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 122,
      fb: 122,
    },
    {
      fbDate: '26.06.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 233,
      fb: 232,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 122,
      fb: 345,
    },
    {
      fbDate: '26.06.2022',
      fbTime: '14:12:02:14',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 455,
      fb: 566,
    },
    {
      fbDate: '06.06.2023',
      fbTime: '14:12:02:14',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 677,
      fb: 788,
    },
    {
      fbDate: '22.06.2023',
      fbTime: '14:12:02:12',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 566,
      fb: 677,
    },
    {
      fbDate: '26.26.2023',
      fbTime: '14:12:02:12',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 566,
      fb: 788,
    },
    {
      fbDate: '26.02.2023',
      fbTime: '14:12:02:34',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 566,
      fb: 455,
    },
    {
      fbDate: '26.06.2023',
      fbTime: '14:12:02:23',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 344,
      fb: 233,
    },
    {
      fbDate: '02.28.2023',
      fbTime: '14:12:02:12',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 112,
      fb: 122,
    },
    {
      fbDate: '26.06.2022',
      fbTime: '02:13:12',
      fbLead: 'count 14',
      fbType: 'real',
      allDate: '26.06.2023',
      allTime: '14:12:02',
      allLead: 'count 14',
      allType: 'real',
      all: 99,
      fb: 88,
    },
  ];

  return (
    <div className="container maxw-100">
      <div className={classNames.dashboard}>
        <div className={classNames.dashboardFilters} />
        <div className={classNames.dashboardBody}>
          <div className={classNames.statisticItem}>
            <OnGoingChart />
          </div>
          <div className={classNames.statisticItem}>
            <TestRealAreaChart data={testRealAreaChart} />
          </div>
          <div className={classNames.statisticItem}>
            <AccountAndFunChart mode="adsAccount" data={accountData} />
          </div>
          <div className={classNames.statisticItem}>
            <AccountAndFunChart mdoe="funPage" data={funPageData} />
          </div>
          <div className={classNames.statisticItem}>
            <TargetChart mode="Am" data={dataAm} />
          </div>
          <div className={classNames.statisticItem}>
            <TargetChart mode="Ge" data={dataGe} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
