import { useState } from 'react';

export const useAdsFilters = () => {
  const [showFilters, setShowFilters] = useState(false);

  const handleFiltersShow = () => {
    setShowFilters((prev) => !prev);
  };
  return { showFilters, handleFiltersShow };
};
