import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLang, selectResetPassToEmail } from '../../store/user/selectors';
import { sendResetPasswordToMail } from '../../store/user/actions';
import { Lang } from '../../T';

export const useResetPassword = () => {
  const dispatch = useDispatch();
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const { loading, success } = useSelector(selectResetPassToEmail);
  const [isTimer, setIsTimer] = useState(true);
  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setIsTimer(false);
      }, 60000);
    }
  }, [success, isTimer]);

  const handleSubmit = useCallback((values) => {
    dispatch(sendResetPasswordToMail({ values, T }));
    setIsTimer(true);
  }, []);

  return { handleSubmit, loading, success, isTimer };
};
