export const applyQueryParams = (queryParams) => {
  const { dateRange, boardId, operatorIds, productIds, deliveryIds, type } = queryParams;
  const completedURL = `/excel/${type}/?startDate=${dateRange[0].toISOString().split('T')[0]}&endDate=${
    dateRange[1].toISOString().split('T')[0]
  }&boardId=${boardId}&productIds=${productIds}&deliveryIds=${deliveryIds}&operatorIds=${operatorIds}`;
  return completedURL;
};
export const handleChangeStructure = (response, role) => {
  const newStructure = response?.map((el) => {
    return { label: `${el.firstName} ${el.lastName}`, value: el.id };
  });
  return { [role]: newStructure };
};
