import { createCombinedAction } from '../helpers';

export const getSticker = createCombinedAction({
  name: 'getSticker',
  prefix: 'stickers@@',
});

export const createSticker = createCombinedAction({
  name: 'createSticker',
  prefix: 'stickers@@',
});

export const editSticker = createCombinedAction({
  name: 'editSticker',
  prefix: 'stickers@@',
});

export const removeSticker = createCombinedAction({
  name: 'removeSticker',
  prefix: 'stickers@@',
});
