import React from 'react';

const PaidCheckSvg = () => {
  return (
    <svg
      id="check_circle_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      width="22.718"
      height="22.718"
      viewBox="0 0 22.718 22.718"
    >
      <path id="Path_6852" data-name="Path 6852" d="M0,0H22.718V22.718H0Z" fill="none" />
      <path
        id="Path_6853"
        data-name="Path 6853"
        d="M11.466,2a9.466,9.466,0,1,0,9.466,9.466A9.469,9.469,0,0,0,11.466,2Zm0,17.038a7.573,7.573,0,1,1,7.573-7.573A7.583,7.583,0,0,1,11.466,19.038ZM15.811,7.282,9.573,13.52,7.121,11.078,5.786,12.412,9.573,16.2l7.573-7.573Z"
        transform="translate(-0.107 -0.107)"
        fill="#43425d"
      />
    </svg>
  );
};

export default PaidCheckSvg;
