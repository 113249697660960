import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CustomDatapicker from '../customSingle-datapicker/custom-datapicker';
import { useSalary } from './useSalary';
import PaidCheckSvg from '../../../../svgs/PaidCheckSvg';
import classNames from './salary.module.scss';
import { Lang } from '../../../../T';
import { selectLang } from '../../../../store/user/selectors';

const Salary = ({ id, firstName = '', lastName = '' }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const {
    bonusValue,
    handleBonusChange,
    salary,
    salaryLoading,
    month,
    success = [],
    handlePay,
    year,
    startDate,
    setStartDate,
    countSuccess,
    isOverYear,
  } = useSalary(id);
  const mounths = {
    '01': T.jan,
    '02': T.feb,
    '03': T.mar,
    '04': T.apr,
    '05': T.may,
    '06': T.june,
    '07': T.july,
    '08': T.aug,
    '09': T.sept,
    10: T.oct,
    11: T.nov,
    12: T.dec,
  };
  const determineMonth = (date) => {
    const month = date.split('-')[1];
    return mounths[month];
  };

  return (
    <>
      <div className={classNames.salaryTable}>
        <div className={classNames.salaryTableHeader}>
          <div className={classNames.salaryTableRow}>
            <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_datePicker}`}>
              <CustomDatapicker startDate={startDate} setStartDate={setStartDate} />
            </div>
            <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_months}`}>
              <p>{T.month}</p>
            </div>
            <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_totalSalary}`}>
              <p>{T.main_sal}</p>
            </div>
            <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_percent}`}>
              <p>{T.percent}</p>
            </div>
            <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_bonus}`}>
              <p>{T.bonus}</p>
            </div>
            <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_total}`}>
              <p>{T.total}</p>
            </div>
            <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_salaryStatus}`}>
              <p>{T.salary_status}</p>
            </div>
          </div>
        </div>
        {salaryLoading ? (
          <div className={classNames.salaryTableBody}>
            <span>Loading...</span>
          </div>
        ) : !salary.length ? (
          <div className={classNames.salaryTableBody}>
            <span>{T.no_data_found}</span>
          </div>
        ) : (
          salary?.map((el, index) => {
            const isSameYear = +el.month.split('-')[0] === +year;
            const isSuccess = success.includes(
              +(el?.month?.split('-')[1][0] === '0' ? el?.month?.split('-')[1][1] : el?.month?.split('-')[1])
            );
            return (
              <div key={el.month} className={classNames.salaryTableBody}>
                <div className={classNames.salaryTableRow}>
                  <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_datePicker}`} />
                  <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_months}`}>
                    <p>{determineMonth(el.month)}</p>
                  </div>
                  <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_totalSalary}`}>
                    <p>{el.staticSalary}</p>
                  </div>
                  <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_percent}`}>
                    <p>{el.percentSalary}</p>
                  </div>
                  <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_bonus}`}>
                    <input
                      className={`${el.isPaid ? classNames.enabledInput : classNames.staticInput} ${
                        isOverYear || (isSameYear && month <= el.month.split('-')[1]) ? classNames.cursor : ''
                      }`}
                      type="number"
                      value={el.bonus || bonusValue[index] || bonusValue.defaultValue}
                      onChange={(e) => handleBonusChange(e, index)}
                      placeholder={el.bonus || '0'}
                      disabled={el.isPaid || (isSameYear && month <= el.month.split('-')[1]) || isSuccess || isOverYear}
                    />
                  </div>
                  <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_total}`}>
                    <p className={classNames.salaryRow_item}>{el.totalSalary}</p>
                  </div>
                  <div className={`${classNames.salaryTableRow_item} ${classNames.salaryTableRow_item_salaryStatus}`}>
                    <button
                      className={el.isPaid || isSuccess ? classNames.paid : classNames.forPay}
                      id={el.userId}
                      type="button"
                      onClick={() => handlePay(index, el.month.split('-')[1], firstName, lastName)}
                      disabled={el.isPaid || (isSameYear && month <= el.month.split('-')[1]) || isSuccess || isOverYear}
                    >
                      {el.isPaid || isSuccess ? (
                        <span>
                          <PaidCheckSvg />
                          {T.payed}
                        </span>
                      ) : countSuccess ? (
                        T.to_count
                      ) : (
                        T.to_pay
                      )}
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};
Salary.propTypes = {
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default Salary;
