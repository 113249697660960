import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Thumb = ({ file }) => {
  const [{ loading, thumb }, setState] = useState({
    loading: false,
    thumb: undefined,
  });

  useEffect(() => {
    if (!file || typeof file === 'string') {
      return;
    }

    setState({ loading: true });
    const reader = new FileReader();

    reader.onloadend = () => {
      setState({ loading: false, thumb: reader.result });
    };

    reader.readAsDataURL(file);
  }, [file]);

  if (!file) {
    return null;
  }

  if (loading) {
    return <p>loading...</p>;
  }

  return <img src={typeof file === 'string' ? file : thumb} alt={file.name} height={40} width={40} />;
};

Thumb.propTypes = {
  file: PropTypes.any,
};

export default Thumb;
