import React from 'react';

const BigLogoSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="153.125" height="36.996" viewBox="0 0 153.125 36.996" fill="#007bff">
      <path
        d="M10.691-23.516v3.746a11.862,11.862,0,0,0,1.449-1.258A16.852,16.852,0,0,1,13.986-22.6a7.5,7.5,0,0,1,2.133-1.039,7.5,7.5,0,0,1,2.256-.4,6.881,6.881,0,0,1,3.5,1.135,8.4,8.4,0,0,1,3.145,4.033A15.419,15.419,0,0,1,29.3-22.846a7.962,7.962,0,0,1,3.9-1.189A6.818,6.818,0,0,1,38.609-21.4,9.759,9.759,0,0,1,40.824-14.9V0h-7V-13.043a5.048,5.048,0,0,0-1.066-3.268,3.2,3.2,0,0,0-2.57-1.326,4.012,4.012,0,0,0-3.117,1.5,5.267,5.267,0,0,0-1.312,3.582V0H18.813V-12.715a5.515,5.515,0,0,0-1.08-3.459,3.2,3.2,0,0,0-2.611-1.408A3.972,3.972,0,0,0,12-16.037a5.534,5.534,0,0,0-1.312,3.705V0H3.637V-23.516ZM47.031-11.7A11.976,11.976,0,0,1,50.5-20.59a12.119,12.119,0,0,1,8.914-3.445,11.4,11.4,0,0,1,8.49,3.514,11.975,11.975,0,0,1,3.432,8.709,12.065,12.065,0,0,1-3.432,8.8,11.547,11.547,0,0,1-8.6,3.5q-5.824,0-9.051-3.705A12.557,12.557,0,0,1,47.031-11.7Zm17.418,0a6.106,6.106,0,0,0-1.559-4.156,4.711,4.711,0,0,0-3.637-1.777,4.917,4.917,0,0,0-3.719,1.668,5.643,5.643,0,0,0-1.559,3.992,6.358,6.358,0,0,0,1.545,4.32,4.776,4.776,0,0,0,3.732,1.777,4.753,4.753,0,0,0,3.637-1.736A5.921,5.921,0,0,0,64.449-11.7ZM77.355-23.461h7.109V-12.2a6.5,6.5,0,0,0,1.24,4.061,3.73,3.73,0,0,0,3.067,1.627A4.1,4.1,0,0,0,92.029-8.08a5.929,5.929,0,0,0,1.322-3.951v-11.43h7.109V-9.789A9.346,9.346,0,0,1,97.143-2.5,12.005,12.005,0,0,1,88.881.438,11.743,11.743,0,0,1,80.5-2.516a10.264,10.264,0,0,1-3.141-7.848ZM107.434-36.5h7V0h-7Zm14.055,0h6.945v22.162l6.943-9.174h8.4l-7.8,10.358L144.43,0h-7.861l-8.135-12.455V0h-6.945Zm26.523,32.58a4.233,4.233,0,0,1,1.285-3.1,4.206,4.206,0,0,1,3.09-1.289,4.206,4.206,0,0,1,3.09,1.289,4.233,4.233,0,0,1,1.285,3.1,4.2,4.2,0,0,1-1.285,3.072,4.206,4.206,0,0,1-3.09,1.289A4.206,4.206,0,0,1,149.3-.852,4.2,4.2,0,0,1,148.012-3.924Z"
        transform="translate(-3.637 36.504)"
      />
    </svg>
  );
};

export default BigLogoSvg;
