export const selectWarehouseData = (store) => {
  return store.warehouse.getWarehouse;
};

export const selectWarehouseCounts = (store) => {
  return store.warehouse.getCounts;
};

export const selectWarehouseRequests = (store) => {
  return store.warehouse.getPendingRequest;
};

export const selectInputModalData = (store) => {
  return store.warehouse.getInput;
};

export const selectExportModalData = (store) => {
  return store.warehouse.getExport;
};

export const selectCreateRequestData = (store) => {
  return store.warehouse.createRequest;
};

export const selectEditRequestData = (store) => {
  return store.warehouse.editInput;
};

export const selectDescriptionData = (store) => {
  return store.warehouse.getDescriptions;
};

export const selectAddDescriptionData = (store) => {
  return store.warehouse.addDescription;
};

export const selectReviewData = (store) => {
  return store.warehouse.reviewPendingRequest;
};

export const selectWarehouseCountsData = (store) => {
  return store.warehouse.getWarehouseCounts;
};
