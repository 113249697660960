import React from 'react';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import Button from '../../../../../../components/button/Button';
import { validationSchema } from './validationSchema';
import { initialValues } from './constants';
import { useCreateForm } from './useCreateForm';
import { Lang } from '../../../../../../T';
import { selectLang } from '../../../../../../store/user/selectors';

const CreateForm = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { showForm, loading, handleSubmit, toggleCreateForm } = useCreateForm();
  return (
    <>
      <div className="draggableFormCreatParent">
        {!showForm ? (
          <Button onClick={toggleCreateForm} className="draggableFormCreat">
            + {T.create_column}
          </Button>
        ) : (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema(T)}>
            <Form>
              <div>
                <Field type="text" name="name" placeholder={T.name} />
                <ErrorMessage className="text-red" name="name" component="div" />
              </div>
              <div className="d-flex justify-content-end">
                <Button loading={loading} onClick={toggleCreateForm}>
                  ×
                </Button>
                <Button loading={loading} type="submit">
                  {T.create}
                </Button>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </>
  );
};

export default CreateForm;
