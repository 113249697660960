import { applyMiddleware, createStore } from 'redux';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import Axios from '../services/axios';

import rootSaga from './rootSaga';

import user from './user/reducer';
import modals from './modals/reducer';
import boards from './boards/reducer';
import workers from './workers/reducer';
import draggableBoard from './draggable-board/reducer';
import getData from './get-data/reducer';
import domain from './domain/reducer';
import products from './products/reducer';
import searchData from './search-data/reducer';
import listsNames from './lists/reducer';
import CD from './c-d/reducer';
import deliverymanProducts from './deliveryman-products/reducer';
import download from './download/reducer';
import stickers from './stickers/reducer';
import region from './regions/reducer';
import cities from './cities/reducer';
import posts from './posts/reducer';
import regions from './region/reducer';
import filter from './filter/reducer';
import permisions from './permissions/reducer';
import comments from './comments/reducer';
import address from './address/reducer';
import reports from './reports/reducer';
import warehouse from './warehouse/reducer';
import customers from './customers/reducer';
import storePermission from './store/reducer';
import storeService from './store-service/reducer';

const composeEnhancers = composeWithDevTools({});
const sagaMiddleware = createSagaMiddleware();

// ______________________ REDUCERS ______________

const reducers = combineReducers({
  user,
  modals,
  boards,
  workers,
  draggableBoard,
  getData,
  domain,
  products,
  searchData,
  listsNames,
  CD,
  deliverymanProducts,
  download,
  stickers,
  region,
  cities,
  posts,
  regions,
  filter,
  permisions,
  comments,
  address,
  reports,
  warehouse,
  customers,
  storePermission,
  storeService,
});

// ______________________ REDUCERS END ______________

const store = createStore(reducers, composeEnhancers(applyMiddleware(sagaMiddleware)));
Axios.store = store;

sagaMiddleware.run(rootSaga);

export default store;
