import * as Yup from 'yup';

export const validationSchema = (T) =>
  Yup.object().shape({
    email: Yup.string().email().required(),
    name: Yup.string().required(T.required_field),
    lastName: Yup.string().required(T.required_field),
    password: Yup.string().required(T.required_field).min(6, T.minimum_6_symbol),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], T.not_matched)
      .required(T.repeat_password),

    photo: Yup.object()
      .shape({
        name: Yup.string().required(T.required_field).nullable(),
      })
      .nullable(),
  });
