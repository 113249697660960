export const axiosData = {
  get: { url: '/api/warehouse', method: 'get' },
  getCounts: { url: '/api/warehouse/requests/pending/count', method: 'get' },
  getRequests: { url: '/api/warehouse/requests/pending', method: 'get' },
  reviewRequest: { url: '/api/warehouse/requests/review', method: 'post' },
  getInput: { url: '/api/warehouse/requests/imports', method: 'get' },
  getExport: { url: '/api/warehouse/requests/exports', method: 'get' },
  createProductRequest: { url: '/api/warehouse/requests', method: 'post' },
  editProductInput: { url: '/api/warehouse/requests/edit', method: 'put' },
  getDescription: { url: '/api/warehouse/descriptions', method: 'get' },
  addDescription: { url: '/api/warehouse/descriptions', method: 'post' },
  getWarehouseCounts: { url: '/api/warehouse/counts', method: 'get' },
};

export const sortDirection = {
  1: 'DESC',
  2: 'ASC',
};
