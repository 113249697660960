import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SmallCopySvg from '../../svgs/SmallCopySvg';
import styles from './copyItem.module.scss';

const CopyItem = ({ text, label = '', type = 'text', href = '', target = '' }) => {
  const [isCopied, setIsCopied] = useState('');
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setIsCopied(value);
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 1000);
    }
  }, [isCopied]);
  return (
    <span className={`${styles.copyItem} ${isCopied === text ? styles.copied : ''}`}>
      {type === 'text' && <>{label || text}</>}
      {type === 'link' && (
        <a href={href} target={target}>
          {label || text}
        </a>
      )}
      <button
        type="button"
        onClick={() => {
          handleCopy(text);
        }}
      >
        <SmallCopySvg />
      </button>
    </span>
  );
};
CopyItem.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
};
export default CopyItem;
