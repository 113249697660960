import moment from 'moment';

export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const handleGetListData = (data) => {
  const columns = data.reduce((acc, item) => {
    const clone = { ...item.list };
    clone.id = `${clone.id}`;
    clone.title = clone.name;
    clone.items = clone.listItems;
    clone.quantity = { count: item.count };
    acc[clone.id] = clone;
    return acc;
  }, {});

  const res = {
    columnOrder: data
      .sort(({ list: { order } }, { list: { order: secOrder } }) => order - secOrder)
      .map(({ list: { id } }) => `${id}`),
    columns,
  };

  return res;
};

export const handleCreateListData = (values) => {
  return { name: values.name, boardId: values.id };
};

export const changeLocalTitle = ({ data, title, id }) => {
  const cloneData = { ...data };
  cloneData.columns = { ...cloneData.columns };
  cloneData.columns[id] = { ...cloneData.columns[id], title };
  return cloneData;
};

export const checkListItemRemovable = ({ data, listId, id }) => {
  return !!data.columns[listId].listItems.find(({ id: itemId }) => itemId !== id);
};
export const handleRemoveListItem = ({ data, listId, id }) => {
  const cloneData = { ...data };
  cloneData.columns = { ...cloneData.columns };
  cloneData.columns[listId] = { ...cloneData.columns[listId] };
  cloneData.columns[listId].listItems = cloneData?.columns[listId]?.listItems?.filter(
    ({ id: itemId }) => itemId !== id
  );
  cloneData.columns[listId].items = cloneData?.columns[listId]?.items?.filter(({ id: itemId }) => itemId !== id);

  cloneData.columns[listId].quantity.count -= 1;

  return cloneData;
};

export const handleRemoveList = ({ data, listId }) => {
  const cloneData = { ...data };
  cloneData.columns = { ...cloneData.columns };

  cloneData.columnOrder = cloneData.columnOrder.filter((id) => +id !== +listId);

  delete cloneData.columns[listId];

  return cloneData;
};

export const getNeighborItemIndex = ({ id, data, listId }) => {
  return data.columns[listId]?.listItems?.findIndex((item) => +item.id === +id);
};

export const addToList = ({ data, neighborListItemId, listItem }) => {
  const cloneData = [...data];
  if (neighborListItemId) {
    const prevItemIndex = cloneData.find(({ id }) => id === neighborListItemId);
    if (prevItemIndex !== -1) {
      cloneData.splice(prevItemIndex, 0, listItem);
    }
  } else {
    cloneData.push(listItem);
  }
  return cloneData;
};

export const handleCreateList = ({ data, list }) => {
  const cloneData = { ...data };

  cloneData.columns = { ...cloneData.columns };
  cloneData.columnOrder = [...cloneData.columnOrder, `${list.id}`];
  cloneData.columns[list.id] = {
    ...list,
    id: `${list.id}`,
    title: list.name,
    listItems: [],
    items: [],
    quantity: { count: 0 },
  };

  return cloneData;
};

export const handleCreateListItem = ({ data, result: { listItem, neighborListItemId } }) => {
  const cloneData = { ...data };
  const { listId } = listItem;

  cloneData.columns = { ...cloneData.columns };
  cloneData.columns[listId] = { ...cloneData.columns[listId] };
  let cloneItems = [...(cloneData?.columns[listId]?.listItems || [])];
  cloneItems = addToList({ data: cloneItems, listItem, neighborListItemId });

  cloneItems = cloneItems.sort(({ order }, { order: secOrder }) => +order - +secOrder);
  cloneData.columns[listId].listItems = [...cloneItems];
  cloneData.columns[listId].items = [...cloneItems];

  cloneData.columns[listId].quantity.count = +cloneData.columns[listId].quantity.count + 1;

  return cloneData;
};

export const handleEditListItem = ({ data, listId, result: { listItem, neighborListItemId } }) => {
  const newData = handleRemoveListItem({ data, listId, id: listItem.id });

  const newListId = listItem.listId;
  const cloneData = { ...newData };
  cloneData.columns = { ...cloneData.columns };
  cloneData.columns[newListId] = { ...cloneData.columns[newListId] };
  let cloneItems = [...cloneData.columns[newListId].listItems];

  cloneItems = addToList({ data: cloneItems, listItem, neighborListItemId });

  cloneItems = cloneItems.sort(({ order }, { order: secOrder }) => order - secOrder);
  cloneData.columns[newListId].listItems = [...cloneItems];
  cloneData.columns[newListId].items = [...cloneItems];

  cloneData.columns[newListId].quantity.count = +cloneData.columns[newListId].quantity.count + 1;

  return cloneData;
};

export const handleCopyListItem = ({ data, listId, id, listItem }) => {
  const newListId = listItem.listId;
  const cloneData = { ...data };
  cloneData.columns = { ...cloneData.columns };
  cloneData.columns[listId] = { ...cloneData.columns[listId] };
  let cloneItems = [...cloneData.columns[newListId].listItems];

  const itemIndex = cloneItems.findIndex(({ id: itemId }) => itemId === id);
  cloneItems = cloneItems.map((item, index) => {
    if (index > itemIndex) {
      item.order += 1;
    }
    return item;
  });
  cloneItems.splice(itemIndex, 0, listItem);

  cloneItems = cloneItems.sort(({ order }, { order: secOrder }) => order - secOrder);
  cloneData.columns[newListId].listItems = [...cloneItems];
  cloneData.columns[newListId].items = [...cloneItems];

  cloneData.columns[newListId].quantity.count = +cloneData.columns[newListId].quantity.count + 1;

  return cloneData;
};

const getDeliveryTime = ({ date, hourMinute: { minute, hour }, endDate = false }) => {
  if (!minute && !hour && endDate) return null;
  const cloneDate = !minute && !hour ? `${moment(date).format('YYYY-MM-DDTHH:mm:ss')}.000Z` : moment(date);
  if (minute || hour) {
    cloneDate.minutes(minute || 0);
    cloneDate.hours(hour || 0);
  }
  return cloneDate;
};

export const handleCreateListItemData = (values, isEdit) => {
  const orderedFrom = values.region === 'Երևան' ? 'yerevan' : values.region === 'warehouse' ? 'warehouse' : 'region';
  const IS_YEREVAN = process.env.REACT_APP_LANG === 'true';

  return {
    address: values.address,
    createdFrom: values.createType,
    index: values.index ? +values.index : undefined,
    cd: values.cd && values.region === 'state' ? values.cd : undefined,
    city: values.city,
    deliveryPrice: values.deliveryPrice,
    fullName: `${values.firstName.trim()} ${values.lastName.trim()}`,
    comments: values.comments,
    listId: values.list.value,
    // state: values.region === 'Երևան' ? 'Երևան' : values.state,
    state: values.region === 'Երևան' && !IS_YEREVAN ? 'Tbilisi' : values.state,
    sex: values.sex,
    building: values.building,
    apartment: values.apartment,
    orderedFrom, // yerevan, region, warehouse
    deliveryStart: getDeliveryTime({
      date: values.date,
      hourMinute: {
        hour: values.hourMinute.startHour,
        minute: values.hourMinute.startMinute,
      },
    }),
    deliveryEnd: getDeliveryTime({
      date: values.date,
      hourMinute: {
        hour: values.hourMinute.endHour,
        minute: values.hourMinute.endMinute,
      },
      endDate: true,
    }),
    phone1: values.mainPhone,
    phone2: values.otherPhone,
    ...(!isEdit ? { labels: values.stickers.map(({ value }) => +value) } : {}),
    products: values.products.map(
      ({
        count,
        sale,
        name,
        price,
        selectedProduct: {
          value: { id },
        },
      }) => ({ count, price: isEdit ? price : undefined, sale: sale || 0, name, id })
    ),
  };
};

const getReturnProducts = (products) => {
  return products
    .filter(({ checked }) => checked)
    .map(({ productId, returnCount, returnPrice, count, sale }) => ({
      productId,
      count: returnCount,
      returnChangePrice: returnPrice - (sale / count).toFixed() * returnCount,
    }));
};

const getChangeProducts = (products) => {
  return products
    .filter(({ checked }) => checked)
    .map(
      ({
        productId,
        extraSale,
        returnCount,
        changePrice,
        productToChange: {
          value: { id: newProductId },
        },
        changeCount: newProductCount,
      }) => {
        return {
          productId,
          count: returnCount,
          returnChangePrice: changePrice - extraSale,
          newProductId,
          newProductCount,
          extraSale,
        };
      }
    );
};

export const handleEditListItemReturnChangeData = (values) => {
  const returnChangeProducts =
    values.orderType === 'return' ? getReturnProducts(values.returnProducts) : getChangeProducts(values.changeProducts);

  return {
    deliveryStart: getDeliveryTime({
      date: values.date,
      hourMinute: {
        hour: values.hourMinute.startHour,
        minute: values.hourMinute.startMinute,
      },
    }),
    deliveryEnd: getDeliveryTime({
      date: values.date,
      hourMinute: {
        hour: values.hourMinute.endHour,
        minute: values.hourMinute.endMinute,
      },
      endDate: true,
    }),
    listId: values.list.value,
    orderType: values.orderType,
    returnChangeProducts,
    returnChangePlace: values.returnChangePlace,
    returnChangeState: values.returnChangePlace === 'deliveryman' ? values.returnChangeState?.value : undefined,
    returnChangeAddress: values.returnChangePlace === 'deliveryman' ? values.returnChangeAddress : undefined,
    returnChangeDeliveryPrice:
      values.returnChangePlace === 'deliveryman' ? values.returnChangeDeliveryPrice : undefined,
    labels: values.stickers.map(({ value }) => +value),
  };
};

export const handleEditListItemData = (values) => {
  const isEdit = true;
  const newValues = handleCreateListItemData(values, isEdit);
  return newValues;
};

export const getListsWithNewOrdering = ({ destIndex, sourceIndex, columns, columnOrder }) => {
  const columnsClone = { ...columns };
  const movedItemId = columnOrder[sourceIndex];
  columnsClone[movedItemId] = { ...columnsClone[movedItemId] };
  const movedItem = columnsClone[movedItemId];

  const columnOrderClone = [...columnOrder];
  const destId = columnOrder[destIndex];
  const newOrder = columnsClone[destId].order;

  movedItem.order = newOrder;

  if (sourceIndex < destIndex) {
    // move right

    const listIds = columnOrderClone.slice(sourceIndex + 1, destIndex + 1);

    listIds.forEach((id) => {
      columnsClone[id].order -= 1;
    });

    return { columns: columnsClone, order: newOrder, id: movedItemId };
  }

  // move left
  const listIds = columnOrderClone.slice(destIndex, sourceIndex);

  listIds.forEach((id) => {
    columnsClone[id].order += 1;
  });

  return { columns: columnsClone, order: newOrder, id: movedItemId };
};

export const getSameBoardItemsWithNewOrdering = ({ destIndex, sourceIndex, columns }) => {
  const columnsClone = [...columns];
  const movedItemId = columnsClone[sourceIndex].id;
  const newOrder = columnsClone[destIndex].order;
  columnsClone[sourceIndex] = { ...columnsClone[sourceIndex], order: newOrder };

  if (sourceIndex < destIndex) {
    // move down

    const movedItems = [...columnsClone].slice(sourceIndex + 1, destIndex + 1);

    movedItems.forEach((item) => {
      item.order -= 1;
    });

    return { columns: columnsClone, order: newOrder, id: movedItemId };
  }

  // move up
  const movedItems = [...columnsClone].slice(destIndex, sourceIndex);

  movedItems.forEach((item) => {
    item.order += 1;
  });

  return { columns: columnsClone, order: newOrder, id: movedItemId };
};

export const getSameOtherItemsWithNewOrdering = ({ result, state }) => {
  const sourceColumn = state.columns[result.source.droppableId];
  const destinationColumn = state.columns[result.destination.droppableId];
  const item = sourceColumn.items[result.source.index];

  // 1. remove item from source column
  const newSourceColumn = {
    ...sourceColumn,
    items: [...sourceColumn.items],
    quantity: {
      ...sourceColumn.quantity,
      count: +sourceColumn.quantity.count - 1,
    },
  };
  newSourceColumn.items.splice(result.source.index, 1);

  // 2. insert into destination column
  const newDestinationColumn = {
    ...destinationColumn,
    items: [...destinationColumn.items],
    quantity: {
      ...destinationColumn.quantity,
      count: +destinationColumn.quantity.count + 1,
    },
  };
  // in line modification of items

  const newOrder =
    newDestinationColumn.items[result.destination.index]?.order ||
    (newDestinationColumn.items[newDestinationColumn.items.length - 1]?.order &&
      newDestinationColumn.items[newDestinationColumn.items.length - 1]?.order + 1) ||
    1;

  item.order = newOrder;
  newDestinationColumn.items.splice(result.destination.index, 0, item);

  for (let i = result.destination.index; i < newDestinationColumn.items.length; i += 1) {
    newDestinationColumn.items[i].order += 1;
  }

  const listId = state.columns[newDestinationColumn.id].id;
  const order = newOrder;

  return { id: item.id, listId, order, newSourceColumn, newDestinationColumn };
};

export const getGlobalBoardId = () => {
  const { pathname } = window.location;
  if (pathname.includes('/board/')) {
    return +pathname.split('/board/')?.[1]?.[0];
  }
  return undefined;
};

export const getFilteredTableData = (values, dateRange) => {
  const [startDate, endDate] = dateRange;
  const sourceLabel = values.createdFrom.map((source) => {
    return `'${source.value}'`;
  });
  const body = {
    states: values.state.map((stateLabel) => `'${stateLabel.label}'`),
    workerIds: values.workerId.map((worker) => worker.value),
    domainIds: values.domainId.map((domain) => domain.value),
    createdFrom: sourceLabel.includes('T.all') ? [] : sourceLabel,
    deliveryDate:
      values.date === 'delivery'
        ? startDate?.toLocaleString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : null,
    createdAtEnd:
      values.date === 'purchase'
        ? endDate?.toLocaleString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : null,
    createdAtStart:
      values.date === 'purchase'
        ? startDate?.toLocaleString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
        : null,
  };
  if (!body.createdAtStart) {
    delete body.createdAtStart;
  }
  if (!body.createdAtEnd) {
    delete body.createdAtEnd;
  }
  if (!body.deliveryDate) {
    delete body.deliveryDate;
  }
  return body;
};
