import { lazy } from 'react';
import { modalNames } from '../store/modals/constants';

const ImportProduct = lazy(() => import('./components/import-product'));
const CheckProduct = lazy(() => import('./components/check-product'));
const ExportProduct = lazy(() => import('./components/export-product'));
const CommentModal = lazy(() => import('./components/comments'));
const StateProducts = lazy(() => import('../pages/states/state-products/StateProducts'));
const CreateBoard = lazy(() => import('./components/create-edit-board/CreateEditBoard'));
const Confirmation = lazy(() => import('./components/confirmation/Confirmation'));
const CreateEditWorker = lazy(() => import('./components/create-edit-worker/CreateEditWorker'));
const Alert = lazy(() => import('./components/alert/Alert'));
const CreateEditProduct = lazy(() => import('./components/create-edit-product/CreateEditProduct'));
const CreateEditAddress = lazy(() => import('./components/create-edit-address/CreateEditAddress'));
const CreateEditOrder = lazy(() => import('./components/create-edit-order/CreateEditOrder'));
const UserInfoModal = lazy(() => import('./components/create-edit-order/user-info-modal/UserInfoModal'));
const StickersCreateEdit = lazy(() => import('./components/create-edit-stickers/CreateEditStickers'));
const EditUserProfileSettings = lazy(() => import('./components/edit-user-profile-settings/EditUserProfileSettings'));
const CreateEditCD = lazy(() => import('./components/create-edit-cd/CreateEditCD'));
const AttachDeliverymanProduct = lazy(() => import('./components/attach-deliveryman-product/AttachDeliverymanProduct'));
const DownLoadCSF = lazy(() => import('./components/download-csf/DownLoadCSF'));
const Notification = lazy(() => import('./components/notification/Notification'));
const SaleModals = lazy(() => import('./components/sale-modals/SaleModals'));
const SaleModalsDone = lazy(() => import('../pages/states/moreInfo/workerRow/modals/sale-done/SaleDoneState'));
const SaleModalsReject = lazy(() =>
  import('../pages/states/moreInfo/workerRow/modals/sale-rejected/SaleRejectedState')
);
const DoneModal = lazy(() => import('./components/delverymanModals/DoneModal'));
const RejectModal = lazy(() => import('./components/delverymanModals/RejectModal'));
const FilterFromProducts = lazy(() => import('./components/filter-from-products'));
const ProductStatusModal = lazy(() => import('./components/product-status/ProductStatusModal'));
const CreateFB = lazy(() => import('./components/createFB/CreateFB'));
const CreateFBAds = lazy(() => import('./components/createFBAds/CreateFBAds'));
const CreatePreset = lazy(() => import('./components/create-preset/CreatePreset'));

export const getModalsComponents = () => {
  return [
    { name: modalNames.comments, Component: CommentModal },
    { name: modalNames.importProduct, Component: ImportProduct },
    { name: modalNames.checkProduct, Component: CheckProduct },
    { name: modalNames.exportProduct, Component: ExportProduct },
    { name: modalNames.createEditBoard, Component: CreateBoard },
    { name: modalNames.confirmation, Component: Confirmation, className: 'higher' },
    { name: modalNames.createEditWorker, Component: CreateEditWorker },
    { name: modalNames.identificationModal, Component: UserInfoModal },
    { name: modalNames.alert, Component: Alert, className: 'higher' },
    { name: modalNames.createEditProduct, Component: CreateEditProduct },
    { name: modalNames.createEditAddress, Component: CreateEditAddress },
    { name: modalNames.createEditOrder, Component: CreateEditOrder },
    { name: modalNames.createEditStickers, Component: StickersCreateEdit },
    { name: modalNames.editUserProfileSettings, Component: EditUserProfileSettings },
    { name: modalNames.createEditCD, Component: CreateEditCD },
    { name: modalNames.SaleModalsDone, Component: SaleModalsDone },
    { name: modalNames.SaleModalsReject, Component: SaleModalsReject },
    { name: modalNames.attachDeliverymenProduct, Component: AttachDeliverymanProduct },
    { name: modalNames.stateProducts, Component: StateProducts },
    { name: modalNames.downLoadCSF, Component: DownLoadCSF },
    { name: modalNames.notification, Component: Notification },
    { name: modalNames.SaleModals, Component: SaleModals, isHiddenCloser: false },
    { name: modalNames.doneModal, Component: DoneModal },
    { name: modalNames.rejectModal, Component: RejectModal },
    { name: modalNames.filterFromProducts, Component: FilterFromProducts },
    { name: modalNames.productStatusModal, Component: ProductStatusModal },
    { name: modalNames.createFB, Component: CreateFB },
    { name: modalNames.createFBAds, Component: CreateFBAds },
    { name: modalNames.createPreset, Component: CreatePreset },
  ];
};
