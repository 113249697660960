import React from 'react';

const SearchSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.8" height="13.258" viewBox="0 0 12.8 13.258">
      <path
        id="search"
        d="M13.589,12.076,10.434,8.794a5.351,5.351,0,1,0-4.1,1.912A5.3,5.3,0,0,0,9.4,9.737l3.179,3.307a.7.7,0,1,0,1.006-.968ZM6.337,1.4A3.956,3.956,0,1,1,2.38,5.353,3.961,3.961,0,0,1,6.337,1.4Z"
        transform="translate(-0.984)"
        fill="#bbc1c8"
      />
    </svg>
  );
};

export default SearchSvg;
