import { createActions } from 'redux-actions';

export const {
  dragListItemToOutListMessage,
  dragListItemToSameListMessage,
  createEditOrderMessage,
  createListMessage,
  removeListMessage,
  dragListMessage,
  moveItemToArchiveMessage,
  deleteItemMessage,
  reArchiveItemMessage,
  copyItemMessage,
} = createActions(
  'DRAG_LIST_ITEM_TO_OUT_LIST_MESSAGE',
  'DRAG_LIST_ITEM_TO_SAME_LIST_MESSAGE',
  'CREATE_EDIT_ORDER_MESSAGE',
  'CREATE_LIST_MESSAGE',
  'REMOVE_LIST_MESSAGE',
  'DRAG_LIST_MESSAGE',
  'MOVE_ITEM_TO_ARCHIVE_MESSAGE',
  'DELETE_ITEM_MESSAGE',
  'RE_ARCHIVE_ITEM_MESSAGE',
  'COPY_ITEM_MESSAGE',
  {
    prefix: 'draggableTable@@',
  }
);
