import { getDataNames } from './constants';

export const axiosData = {
  [getDataNames.positionsList]: {
    method: 'get',
    url: '/api/admin/permissions/group',
  },
  [getDataNames.permissionsListData]: {
    method: 'get',
    url: '/api/admin/permissions',
  },
  [getDataNames.boardLists]: {
    method: 'get',
    url: '/api/admin/boards/:id/lists',
  },
  [getDataNames.boardListsToSelect]: {
    method: 'get',
    url: '/api/admin/boards/:id/lists',
  },
};

export const dataHandlers = {
  [getDataNames.positionsList]: ({ data: { result } }) => result,
  [getDataNames.permissionsListData]: ({ data: { result } }) => result,
  [getDataNames.boardLists]: ({ data: { result } }) => result.map(({ name, id }) => ({ label: name, value: id })),
  [getDataNames.boardListsToSelect]: ({ data: { result } }) =>
    result.map(({ name, id }) => ({ label: name, value: id })),
};
