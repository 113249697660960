import { getRegion } from '../../modals/components/create-edit-order/helpers';

export const handleEditProfileData = (values) => {
  const formData = new FormData();

  formData.append('firstName', values.firstName);
  formData.append('lastName', values.lastName);
  formData.append('oldPassword', values.oldPassword);
  formData.append('password', values.newPassword);
  formData.append('retryPassword', values.repeatNewPassword);
  if (typeof values.photo === 'object' && values.photo) formData.append('image', values.photo);
  return formData;
};

export const getUserData = (data) => {
  return {
    building: data?.building || '',
    city: data?.city || '',
    state: data?.state || '',
    sex: data?.sex || '',
    mainPhone: data?.phone1 || '',
    otherPhone: data?.phone2 || '',
    region: getRegion(data) || '',
    address: data?.address || '',
    firstName: data?.name?.split(' ')[0] || '',
    lastName: data?.name?.split(' ')[1] || '',
    apartment: data?.apartment || '',
  };
};
