import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavbarState } from '../store/user/selectors';
import { setNavbarState } from '../store/user/actions';

const useNavbarOpen = () => {
  const open = useSelector(selectNavbarState);
  const dispatch = useDispatch();

  const handleToggleSidebar = () => {
    dispatch(setNavbarState(!open));
  };

  useEffect(() => {
    const navbarState = localStorage.getItem('navbarState');

    if (navbarState) {
      dispatch(setNavbarState(navbarState === 'true'));
    }
    return () => {
      dispatch(setNavbarState(false));
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('navbarState', open);
  }, [open]);
  return { handleToggleSidebar };
};

export default useNavbarOpen;
