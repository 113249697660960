import { useCallback, useState } from 'react';

export const useEditInput = ({ onSave, value }) => {
  const [localValue, setLocalValue] = useState(value);
  const handleChange = useCallback(({ target: { value } }) => {
    setLocalValue(value);
  }, []);

  const handleSave = useCallback(() => {
    if (!localValue.trim()) return;
    onSave(localValue);
  }, [onSave, localValue]);

  return {
    handleChange,
    handleSave,
    localValue,
  };
};
