// eslint-disable-next-line import/no-extraneous-dependencies
import firebase from 'firebase/app';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'firebase/messaging';

import { take, call, apply, fork } from 'redux-saga/effects';
import {
  createEditOrderMessage,
  createListMessage,
  dragListItemToOutListMessage,
  dragListItemToSameListMessage,
  dragListMessage,
  moveItemToArchiveMessage,
  removeListMessage,
  deleteItemMessage,
  reArchiveItemMessage,
  copyItemMessage,
} from './actions';
import { downLoadBuyer, downLoadCSF } from '../../store/download/actions';

// Yerevan config
export const config = {
  apiKey: 'AIzaSyCjepiaASncpjBhaS_IFmug_Q0FOZ5WJ9Y',
  authDomain: 'onkeet-notifications.firebaseapp.com',
  databaseURL: 'https://onkeet-notifications.firebaseio.com',
  projectId: 'onkeet-notifications',
  storageBucket: 'onkeet-notifications.appspot.com',
  messagingSenderId: '233210427587',
  appId: '1:233210427587:web:c40724d3e1e12cb3b60a76',
  measurementId: 'G-0KGWMBKD2R',
};

// Test config
// export const config = {
//   apiKey: 'AIzaSyCSmSU2fS_jBHjDHl4Pbu71Yzbq59kA6Ss',
//   authDomain: 'moulk-dev.firebaseapp.com',
//   databaseURL: 'https://moulk-dev.firebaseio.com',
//   projectId: 'moulk-dev',
//   storageBucket: 'moulk-dev.appspot.com',
//   messagingSenderId: '606004580137',
//   appId: '1:606004580137:web:f5071776fe04360154b138',
//   measurementId: 'G-L0QFP80ZQ2',
// };

// Tbilisi config
// export const config = {
//   apiKey: "AIzaSyDYZH9V5HUjXug4m39a0QfOKcsBV24mZiA",
//   authDomain: "moulk-ge.firebaseapp.com",
//   projectId: "moulk-ge",
//   storageBucket: "moulk-ge.appspot.com",
//   messagingSenderId: "139090911415",
//   appId: "1:139090911415:web:5d9dbe34737027c7886d32",
//   measurementId: "G-VNDWE3KYH0"
// };

export const initializedApp = (() => {
  if (!firebase.apps.length) {
    return firebase.initializeApp(config);
  }
  return firebase.app(); // if already initialized, use that one
})();

const messaging = firebase.messaging.isSupported() ? initializedApp.messaging() : null;

export function* getMessagingToken() {
  try {
    const res = yield apply(messaging, messaging.getToken);
    return res;
  } catch (e) {
    return null;
  }
}
function* getFirebaseServiceWorker() {
  const worker = yield apply(navigator.serviceWorker, navigator.serviceWorker.getRegistration, [
    '/firebase-cloud-messaging-push-scope.js',
  ]);
  return worker;
}

export function* unregisterFirebaseWorker() {
  if (!messaging) return;
  yield messaging.deleteToken();

  const worker = yield call(getFirebaseServiceWorker);
  if (worker) {
    yield worker.unregister();
  }
}

export function* messagingInit(Axios) {
  const currentToken = yield call(getMessagingToken);
  if (currentToken) {
    try {
      yield call(Axios.request, {
        method: 'post',
        baseURL: process.env.REACT_APP_BASE_ENDPOINT,
        url: '/api/users/firebase/token',
        data: {
          firebaseToken: currentToken,
        },
      });
    } catch (e) {
      console.warn({ e });
    }
  } else {
    // todo handle not getting token
  }
}

export function* firebaseMessagingRegistrationWatcher(loginSuccessActionsArray, Axios) {
  while (true) {
    yield take(loginSuccessActionsArray);
    if (firebase.messaging.isSupported()) {
      yield call(messagingInit, Axios);

      yield fork(firebaseMessagingWatcherRegistration, Axios.store);
    }
  }
}

export function* firebaseMessagingUnregisterWatcher(actions) {
  while (true) {
    yield take(actions);
    if (firebase.messaging.isSupported()) {
      yield call(unregisterFirebaseWorker);
    }
  }
}

export function firebaseMessagingWatcherRegistration(store) {
  if ('serviceWorker' in navigator) {
    try {
      messaging.onMessage(({ notification }) => {
        const { title: type, body } = notification;
        try {
          const actions = {
            listItemMovedOutOfList: dragListItemToOutListMessage,
            listItemMovedInList: dragListItemToSameListMessage,
            newOrderCreated: createEditOrderMessage,
            csvCompleted: downLoadCSF,
            listCreated: createListMessage,
            listArchived: removeListMessage,
            listDeleted: removeListMessage,
            listChangeOrder: dragListMessage,
            itemArchived: moveItemToArchiveMessage,
            itemDeleted: deleteItemMessage,
            itemArchivedUndo: reArchiveItemMessage,
            itemCopied: copyItemMessage,
            buyerCompleted: downLoadBuyer,
          };

          const payload = { ...JSON.parse(body), store };
          store.dispatch(actions[type](payload));
        } catch (e) {
          // todo handle error
          console.warn({ e });
        }
      });
    } catch (err) {
      // todo handle error
      console.warn({ err });
    }
  }
}

export default firebase;
