import { all, takeEvery, put, call } from 'redux-saga/effects';
import Axios from '../../services/axios';
import { errorLogger } from '../../helpers/errorLogger';
import { getCities } from './actions';
import { axiosData } from './requestGenerators';

function* getCitiesRequest({ payload }) {
  const url = axiosData.getCities().url.replace(':id', payload.stateId);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getCities(payload.offset),
    url,
  });
  return result;
}

function* watchGetCities() {
  yield takeEvery(getCities, function* workGetCities({ payload }) {
    try {
      const response = yield call(getCitiesRequest, { payload });
      yield put(
        getCities.success(
          response.map((el) => {
            return {
              value: el.hayPostId,
              label: el.name,
            };
          })
        )
      );
    } catch (e) {
      errorLogger(e);
      yield put(getCities.error());
    }
  });
}

export default function* getAllCities() {
  yield all([watchGetCities()]);
}
