import { useSelector } from 'react-redux';
import { selectLang } from '../../store/user/selectors';
import { Lang } from '../../T';

const useConstants = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const periodOptions = [
    { label: T.Today, value: 'today' },
    { label: T.Yesterday, value: 'yesterday' },
    { label: T.CurrentWeek, value: 'currentWeek' },
    { label: T.CurrentMonth, value: 'currentMonth' },
    { label: T.CurrentYear, value: 'currentYear' },
    { label: T.PreviousWeek, value: 'previousWeek' },
    { label: T.PreviousMonth, value: 'previousMonth' },
    { label: T.PreviousYear, value: 'previousYear' },
  ];

  const initialFilter = {
    period: periodOptions[2],
    productIds: [],
    startDate: null,
    endDate: null,
    urls: [],
  };

  return { periodOptions, initialFilter };
};

export default useConstants;
