import React from 'react';
import PropTypes from 'prop-types';
import classNames from './modalContainer.module.scss';
import CloseSvg from '../../../../svgs/CloseSvg';

const ModalContainer = ({ title, children, visible, onClose, name, className = '', isHiddenCloser = true }) => {
  return (
    <div
      data-name={name}
      className={`${classNames.modalWrapper} ${className} ${visible ? `${classNames.visible} visible` : ''}`}
    >
      <div>
        <div className={`${visible ? 'show' : 'd-none'}`} tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className={`modal-content ${classNames.container}`}>
              <div className="modal-header">
                <span>{title}</span>
                <button type="button" onClick={onClose} className="close" data-dismiss="modal" aria-label="Close">
                  {isHiddenCloser ? (
                    name === 'confirmation' ||
                    name === 'createEditStickers' ||
                    name === 'doneModal' ||
                    name === 'rejectModal' ||
                    name === 'alert' ? (
                      <span aria-hidden="true">
                        <CloseSvg />
                      </span>
                    ) : (
                      <span aria-hidden="true">
                        <CloseSvg />
                      </span>
                    )
                  ) : null}
                </button>
              </div>
              <div>
                <div className="modal-body">{visible && children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  isHiddenCloser: PropTypes.bool,
};

export default ModalContainer;
