export const selectInitialFetchingLoading = ({ user: { get } = {} }) => !get?.isFetched;
export const selectIsAuthenticated = ({ user: { get } = {} }) => get?.isAuthenticated;
export const selectIsAdmin = ({ user: { get } = {} }) => get?.isAuthenticated && get?.isAdmin;
export const selectUser = ({ user: { get } = {} }) => get;
export const selectUserByPhone = ({ user: { userByPhone } = {} }) => userByPhone;
export const selectUserOrdersByPhone = ({ user: { userOrdersByPhone } = {} }) => userOrdersByPhone;
export const selectUserData = ({ user: { userData } = {} }) => userData;
export const selectUserRatesByPhone = ({ user: { userRatesByPhone } = {} }) => userRatesByPhone;
export const selectUserAddressesByPhone = ({ user: { userAddressesByPhone } = {} }) => userAddressesByPhone;
export const selectCanUseInfo = ({ user: { useUserInfo } = {} }) => useUserInfo.canUse;
export const selectUserRole = ({
  user: {
    get: { role },
  },
}) => role;

export const selectLoginLoading = ({
  user: {
    get: { loading },
  },
}) => loading;

export const selectUserEdit = ({ user: { edit } }) => edit;
export const selectResetPassToEmail = ({ user: { postToMail } }) => postToMail;
export const selectConfirmedPassword = ({ user: { postConfirmedPassword } }) => postConfirmedPassword;
export const selectFormValues = ({ user: { formValues } }) => formValues.formValues;

export const selectLang = ({ user }) => user?.lang?.lang;
export const selectNavbarState = ({ user }) => user?.navbarState?.open;
