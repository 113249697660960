import { useMemo, useState } from 'react';

const usePagination = (total) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const pagesCount = useMemo(() => {
    return Math.ceil(total / limit);
  }, [total, limit]);
  const changePage = (page) => setPage(page);
  const changeLimit = (newLimit) => {
    setLimit(newLimit.value);
    setPage(1);
  };
  return { page, setPage, limit, setLimit, changePage, changeLimit, pagesCount };
};

export default usePagination;
