import { createCombinedAction } from '../helpers';

export const createBoard = createCombinedAction({
  name: 'createBoard',
  prefix: 'board@@',
});

export const editBoard = createCombinedAction({
  name: 'editBoard',
  prefix: 'board@@',
});

export const getBoards = createCombinedAction({
  name: 'getBoards',
  prefix: 'board@@',
});

export const deleteBoardById = createCombinedAction({
  name: 'deleteBoardById',
  prefix: 'board@@',
});

export const getByIdBoard = createCombinedAction({
  name: 'getByIdBoard',
  prefix: 'board@@',
});

export const getSelectableBoards = createCombinedAction({
  name: 'getSelectableBoards',
  prefix: 'board@@',
});

export const getStatisticById = createCombinedAction({
  name: 'getStatisticById',
  prefix: 'board@@',
});
