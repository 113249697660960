import { routes } from '../routes';

export const homePages = {
  admin: routes.special,
  director: routes.special,
  storeKeeper: routes.home,
  chiefOperator: routes.home,
  operator: routes.home,
  productCreator: routes.products,
  accountant: routes.special,
  marketingExpert: routes.special,
  viewer: routes.products,
};

export const getPaths = (T) => ({
  admin: [
    { id: crypto.randomUUID(), path: routes.special, title: T.analytics },
    { id: crypto.randomUUID(), path: routes.home, title: T.board },
    { id: crypto.randomUUID(), path: routes.store, title: T.warehouse },
    { id: crypto.randomUUID(), path: routes.states, title: T.states },
    { id: crypto.randomUUID(), path: routes.products, title: T.product },
    { id: crypto.randomUUID(), path: routes.customers, title: T.customer },
    { id: crypto.randomUUID(), path: routes.workers, title: T.administrators },
    { id: crypto.randomUUID(), path: routes.marketing, title: T.marketing },
    { id: crypto.randomUUID(), path: routes.domain, title: T.domain },
    { id: crypto.randomUUID(), path: routes.productBuying, title: T.productBuying },
    { id: crypto.randomUUID(), path: routes.history, title: T.History },
  ],
  director: [
    { id: crypto.randomUUID(), path: routes.special, title: T.analytics },
    { id: crypto.randomUUID(), path: routes.home, title: T.board },
    { id: crypto.randomUUID(), path: routes.store, title: T.warehouse },
    { id: crypto.randomUUID(), path: routes.states, title: T.states },
    { id: crypto.randomUUID(), path: routes.products, title: T.product },
    { id: crypto.randomUUID(), path: routes.customers, title: T.customer },
    { id: crypto.randomUUID(), path: routes.workers, title: T.administrators },
    { id: crypto.randomUUID(), path: routes.marketing, title: T.marketing },
    { id: crypto.randomUUID(), path: routes.domain, title: T.domain },
    { id: crypto.randomUUID(), path: routes.productBuying, title: T.productBuying },
  ],
  storeKeeper: [
    { id: crypto.randomUUID(), path: routes.home, title: T.board },
    { id: crypto.randomUUID(), path: routes.states, title: T.states },
    { id: crypto.randomUUID(), path: routes.store, title: T.warehouse },
    { id: crypto.randomUUID(), path: routes.customers, title: T.customer },
  ],
  chiefOperator: [
    { id: crypto.randomUUID(), path: routes.home, title: T.board },
    { id: crypto.randomUUID(), path: routes.products, title: T.product },
    { id: crypto.randomUUID(), path: routes.customers, title: T.customer },
  ],
  operator: [{ id: crypto.randomUUID(), path: routes.home, title: T.board }],
  productCreator: [{ id: crypto.randomUUID(), path: routes.products, title: T.product }],
  accountant: [
    { id: crypto.randomUUID(), path: routes.special, title: T.analytics },
    { id: crypto.randomUUID(), path: routes.store, title: T.warehouse },
  ],
  marketingExpert: [
    { id: crypto.randomUUID(), path: routes.special, title: T.analytics },
    { id: crypto.randomUUID(), path: routes.store, title: T.warehouse },
    { id: crypto.randomUUID(), path: routes.productBuying, title: T.productBuying },
  ],
  viewer: [
    { id: crypto.randomUUID(), path: routes.products, title: T.product },
    { id: crypto.randomUUID(), path: routes.customers, title: T.customer },
  ],
});
