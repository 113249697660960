import { handleActions } from 'redux-actions';
import { getDataNames } from './constants';
import { getData } from './actions';

const initialState = {};
Object.values(getDataNames).map((name) => {
  return (initialState[name] = {
    loading: false,
    error: false,
    data: {},
  });
});

export default handleActions(
  {
    [getData]: (state, { payload: { key } }) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: true,
        error: false,
      },
    }),
    [getData.success]: (state, { payload: { key, data } }) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: false,
        data,
      },
    }),
    [getData.error]: (state, { payload: { key } }) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: false,
        error: true,
      },
    }),
    [getData.reset]: (state, { payload: { key } }) => ({
      ...state,
      [key]: {
        ...state[key],
        loading: false,
        error: false,
        data: {},
      },
    }),
  },
  initialState
);
