import { createCombinedAction } from '../helpers';

export const getAllListsNames = createCombinedAction({
  name: 'getAllListsNames',
  prefix: 'listsNames@@',
});

export const getAllBoards = createCombinedAction({
  name: 'getAllBoards',
  prefix: 'allBoards@@',
});

export const moveAllItems = createCombinedAction({
  name: 'moveAllItems',
  prefix: 'allBoards@@',
});

export const getProductsCount = createCombinedAction({
  name: 'getProductsCount',
  prefix: 'listsNames@@',
});
