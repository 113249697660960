import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useResetPassword } from './useResetPassword';
import { validationSchema } from './validationSchema';
import { initialValues } from './constants';
import Button from '../../components/button/Button';
import classNames from './resetPassword.module.scss';
import LogoSvg from '../../svgs/LogoSvg';
import ControlArrow from '../../svgs/ControlArrow';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';

const ResetPassword = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { handleSubmit, loading, success, isTimer } = useResetPassword();
  const history = useHistory();
  return (
    <div className={`${classNames.loginPage} d-flex justify-content-center align-items-center h-100 w-100`}>
      <div className={classNames.AuthenticationContent}>
        <Button
          className={classNames.goBack}
          onClick={() => {
            history.push('/signinmoulk');
          }}
        >
          <ControlArrow />
        </Button>
        <h1>
          <LogoSvg />
        </h1>
        <Formik initialValues={initialValues} validationSchema={validationSchema(T)} onSubmit={handleSubmit}>
          {({ values }) => {
            return (
              <Form className="w-100">
                <p className={classNames.forgotText}>{T.forgot_password}</p>
                <div className={`${classNames.InputParent} ${classNames.InputParentWithText}`}>
                  <Field className="w-100" type="email" name="email" placeholder={T.email} />
                  <ErrorMessage className="text-red" name="email" component="div" />
                  {success && !isTimer && (
                    <Button disabled={isTimer} onClick={() => handleSubmit(values)}>
                      {T.send_again}
                    </Button>
                  )}
                </div>
                <span className={classNames.infoText}>{T.go_by_link_for_reset}</span>
                <div className={classNames.AuthenticationBtn}>
                  <Button disabled={success} type="submit" className="btn btn-success" loading={loading}>
                    {T.send}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
