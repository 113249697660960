import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuthentication } from './useAuthentication';
import { validationSchema } from './validationSchema';
import { initialValues } from './constants';
import Button from '../../components/button/Button';
import classNames from './authentication.module.scss';
import LogoSvg from '../../svgs/LogoSvg';
import CloseEyeSvg from '../../svgs/CloseEyeSvg';
import EyeSvg from '../../svgs/EyeSvg';
import { Lang } from '../../T';
import { selectLang } from '../../store/user/selectors';

const Authentication = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { handleSubmit, loading, isVisible, handleVisible } = useAuthentication();
  const history = useHistory();
  return (
    <div className={`${classNames.loginPage} d-flex justify-content-center align-items-center h-100 w-100`}>
      <div className={classNames.AuthenticationContent}>
        <h1>
          <LogoSvg />
        </h1>
        <Formik initialValues={initialValues} validationSchema={validationSchema(T)} onSubmit={handleSubmit}>
          {({ setFieldValue, values }) => (
            <Form className="w-100">
              <div className={classNames.InputParent}>
                <Field className="w-100" type="email" name="email" placeholder={`${T.user_name} *`} />
                <ErrorMessage className="text-red" name="email" component="div" />
              </div>
              <div className={classNames.InputParent}>
                <div className={classNames.inputPassword}>
                  <Field
                    className="w-100"
                    type={!isVisible ? 'password' : 'text'}
                    name="password"
                    placeholder={`${T.password} *`}
                  />
                  <Button onClick={handleVisible}>{!isVisible ? <CloseEyeSvg /> : <EyeSvg />}</Button>
                </div>
                <ErrorMessage className="text-red" name="password" component="div" />
              </div>
              <div className={classNames.rememberForgot}>
                <div className="rememberMe">
                  <Field className="rememberCheck" type="checkbox" name="remember" />
                  <span style={{ cursor: 'pointer' }} onClick={() => setFieldValue('remember', !values.remember)}>
                    {T.remember_password}
                  </span>
                </div>
                <a href="#" onClick={() => history.push('/resetPassword')}>
                  {T.forgot_password}
                </a>
              </div>
              <div className={classNames.AuthenticationBtn}>
                <Button type="submit" className="btn btn-success" loading={loading}>
                  {T.log_in}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Authentication;
