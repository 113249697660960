import React from 'react';
import HorizontalTabs from '../../components/horizontal-tabs/HorizontalTabs';
import { useMarketing } from './useMarketing';

const Marketing = () => {
  const { tabs } = useMarketing();
  return (
    <div>
      <HorizontalTabs className="header" tabs={tabs} />
    </div>
  );
};

export default Marketing;
