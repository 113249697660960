import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import EditInput from './components/edit-input/EditInput';
import { useEditableTitle } from './useEditableTitle';
import classNames from './editableTitle.module.scss';

const EditableTitle = ({ title, id, archived }) => {
  const { handleChangeListTitle, showSendForm, toggleSendForm, loading, setShowSendForm } = useEditableTitle({
    title,
    id,
  });
  useEffect(() => {
    document.addEventListener('auxclick', () => setShowSendForm(false));

    return () => document.removeEventListener('auxclick', () => setShowSendForm(false));
  }, []);

  return !showSendForm ? (
    <span className={classNames.columnTitle} onDoubleClick={!archived ? toggleSendForm : () => {}}>
      {title}
    </span>
  ) : loading ? (
    '...loading'
  ) : (
    <EditInput className={classNames.editIntput} value={title} onSave={handleChangeListTitle} />
  );
};

EditableTitle.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  archived: PropTypes.bool,
};
export default EditableTitle;
