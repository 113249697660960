import React from 'react';
import Campaign from './components/campaign/Campaign';
import Dashboard from './dashboard/Dashboard';
import Ads from './components/ads/Ads';

export const useMarketing = () => {
  const tabs = [
    {
      key: 0,
      name: 'Dashboard',
      children: <Dashboard />,
    },
    {
      key: 1,
      name: 'ADS',
      children: <Ads />,
    },
    {
      key: 2,
      name: 'Campaign',
      children: <Campaign />,
    },
    {
      key: 3,
      name: 'Target salary',
      children: <div>Target salary</div>,
    },
    {
      key: 4,
      name: 'Recommendation',
      children: <div>Recommendation</div>,
    },
  ];

  return {
    tabs,
  };
};
