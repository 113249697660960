import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { archiveList, removeList } from '../../../../store/draggable-board/actions';
import { selectArchiveList, selectDraggableBoard } from '../../../../store/draggable-board/selector';
import { openModal } from '../../../../store/modals/actions';
import { modalNames } from '../../../../store/modals/constants';
import { Lang } from '../../../../T';
import { selectLang } from '../../../../store/user/selectors';

export const useColumn = ({ column }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { loading } = useSelector(selectArchiveList(column.id));
  const { archived } = useSelector(selectDraggableBoard);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [showMenu, setShowMenu] = useState(false);
  const [item, setItem] = useState({});
  const [top, setTop] = useState(0);
  const [event, setEvent] = useState(null);
  const deliveryList = useMemo(() => column.listType === 'deliveryMan', [column]);
  const stateColumn = useMemo(() => column.listType === 'state2', [column]);
  const stateColumnFix = useMemo(() => column.listType === 'state3', [column]);

  const isDownloaded = useMemo(() => column.downloaded, [column]);
  const deleteItem = useCallback(() => {
    dispatch(removeList({ id: column.id, boardId: id }));
  }, [column.id, id]);

  const handleRemoveList = useCallback(
    (e) => {
      setShowMenu && setShowMenu(false);
      dispatch(
        openModal({
          name: modalNames.confirmation,
          data: {
            title: T.delete,
            description: T.are_you_sure_delete_column,
            onOk: () => deleteItem(e),
          },
        })
      );
    },
    [item]
  );

  const handleFilterList = useCallback((id) => {
    dispatch(
      openModal({
        name: modalNames.filterFromProducts,
        data: id,
        title: T.filter,
      })
    );
  }, []);

  const handleArchiveList = useCallback(() => {
    dispatch(archiveList({ listId: column.id, archived }));
  }, [column.id]);
  const openEdit = (clientY, item, event) => {
    setItem(item);
    setTop(event.screenY < 650 ? clientY : clientY - 90);
    setShowMenu(true);
    setEvent((prev) => {
      if (prev) {
        prev.target.closest('.item.orderItem').style.zIndex = 1;
      }
      return event;
    });
  };

  useEffect(() => {
    if (event) {
      event.target.closest('.item.orderItem').style.zIndex = 10000000;
    }
  }, [event]);
  useEffect(() => {
    if (!showMenu) {
      const page = document.querySelector('.main-page');
      const body = document.querySelector('body');
      body.style = { overFlow: 'auto' };
      page.style = { overFlow: 'auto' };
      setEvent((prev) => {
        if (prev) {
          prev.target.closest('.item.orderItem').style.zIndex = 1;
        }
        return null;
      });
    }
  }, [showMenu]);
  return {
    handleFilterList,
    handleArchiveList,
    loading,
    handleRemoveList,
    deliveryList,
    id,
    archived,
    openEdit,
    showMenu,
    setShowMenu,
    item,
    top,
    stateColumn,
    isDownloaded,
    stateColumnFix,
  };
};
