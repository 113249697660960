export const axiosData = {
  getDeliverymanProducts: {
    url: '/api/admin/deliveryman/:id/products?boardId=&offset=&limit=100',
    method: 'get',
  },
  getProducts: {
    url: '/api/admin/deliveryman/:id/products/extended?boardId=&offset=&limit=20&searchWord=',
    method: 'get',
  },
  setDeliverymanProducts: {
    url: '/api/admin/deliveryman/:id/products',
    method: 'put',
  },
  clearDeliverymanProducts: {
    url: '/api/admin/deliveryman/:id/products',
    method: 'delete',
  },
};
