import { all, takeEvery, put, call } from 'redux-saga/effects';
import { createDomain, getDomain, removeDomain } from './actions';
import { errorLogger } from '../../helpers/errorLogger';
import Axios from '../../services/axios';
import { axiosData } from './requestGenerators';
import { notifyMessage } from '../message';
import { droppingErrors } from '../draggable-board/constants';

function* createDomainRequest(name) {
  const res = yield call(Axios.request, {
    ...axiosData.createDomain,
    data: {
      name,
    },
  });

  return res;
}

function* watchCreateDomain() {
  yield takeEvery(
    createDomain,
    function* workCreateDomain({
      payload: {
        values: { name },
        T,
      },
    }) {
      try {
        yield call(createDomainRequest, name);
        yield put(createDomain.success());
        yield put(getDomain({ boardId: null, all: true }));
      } catch (e) {
        const { message: errorMessage } = e.response.data.error;
        yield call(
          notifyMessage,
          {
            body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage,
            timer: 3000,
          },
          T
        );
        yield put(createDomain.error());
        errorLogger(e);
      }
    }
  );
}

function* getDomainRequest(boardId, all) {
  const url = `${axiosData.getDomain.url}${boardId ? `?boardId=${boardId}` : '?'}${all ? 'all=true' : ''}`;
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getDomain,
    url,
  });

  return result;
}

function* watchGetDomain() {
  yield takeEvery(getDomain, function* workGetDomain({ payload: { boardId, all } }) {
    try {
      const data = yield call(getDomainRequest, boardId, all);
      yield put(getDomain.success({ data }));
    } catch (e) {
      yield put(getDomain.error());
      errorLogger(e);
    }
  });
}

function* removeDomainRequest(id) {
  const url = axiosData.removeDomain.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.removeDomain,
    url,
  });

  return result;
}

function* watchRemoveDomain() {
  yield takeEvery(removeDomain, function* workGetDomain({ payload: { id } }) {
    try {
      yield call(removeDomainRequest, id);
      yield put(removeDomain.success());

      yield put(getDomain({ boardId: null, all: true }));
    } catch (e) {
      yield put(removeDomain.error());
      errorLogger(e);
    }
  });
}

export default function* domain() {
  yield all([watchCreateDomain(), watchGetDomain(), watchRemoveDomain()]);
}
