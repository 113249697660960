import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { selectProducts } from '../../../../store/products/selectors';
import { selectLang } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';

import useConstants from '../../constants';
import { debounce } from '../../../../helpers/debounce';
import { getProducts } from '../../../../store/products/actions';
import { getProductUrls } from '../../../special/helpers';
import useConstant from '../../../special/constants';
import { formatNumberWithThousandsSeparator } from '../../../../helpers/formatNubmer';

const useFilter = ({ filter, setFilter, data }) => {
  const { dateOption: config } = useConstant();
  const [copied, setCopied] = useState(false);
  const dispatch = useDispatch();
  const { initialFilter, periodOptions } = useConstants();

  const products = useSelector(selectProducts);
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const [search, setSearch] = useState('');
  const [urlSearch, setUrlSearch] = useState('');
  const [urlsOptions, setUrlOptions] = useState([]);

  const productOptions = useMemo(
    () =>
      products?.data?.rows?.map(({ id, name, barcode }) => ({
        label: `${name}${barcode ? ` - ${barcode}` : ''}`,
        value: id,
      })) || [],
    [products]
  );

  const isResetDisabled = useMemo(() => {
    const { period, productIds, startDate, endDate, urls } = filter;
    return !(
      period.value !== periodOptions[2].value ||
      !!productIds.length ||
      startDate !== null ||
      endDate !== null ||
      !!urls.length
    );
  }, [filter, T]);

  const handlePeriodChange = (e) => {
    setFilter((prev) => {
      if (e.length === 2) {
        const label = `${e[0].toLocaleString('ru', config)} - ${e[1].toLocaleString('ru', config)}`;

        return {
          ...prev,
          startDate: e[0],
          endDate: e[1],
          period: { value: 'custom', label },
        };
      }

      return { ...prev, period: e, startDate: null, endDate: null };
    });
  };

  const handleProductChange = (productIds) => setFilter((prev) => ({ ...prev, productIds }));

  const handleUrlChange = (urls) => setFilter((prev) => ({ ...prev, urls }));

  const handleUrlSearch = debounce((value) => setUrlSearch(value), 500);

  const handleSearch = debounce((value) => setSearch(value), 500);

  const handleReset = () => setFilter(initialFilter);

  const handleCopyTable = () => {
    let result = '';

    data.forEach((item) => {
      result += `${item.id}\t`;
      result += `${item.name}\t`;
      result += `${item.type}\t`;
      result += `${item.newOrderCount}\t`;
      result += `${item.acceptedCount}\t`;
      result += `${item.unacceptedCount}\t`;
      result += `${item.currentSoldCount}\t`;
      result += `${item.overallSoldCount}\t`;
      result += `${item.pendingReturnCount}\t`;
      result += `${item.doneReturnCount}\t`;
      result += `${item.rejectedCount}\t`;
      result += `${item.pendingChangeCount}\t`;
      result += `${item.doneChangeCount}\t`;
      result += `${item.marketingCount}\t`;
      result += `${item.realCount}\t`;
      result += `${formatNumberWithThousandsSeparator(item.grossTotal, ' ')}\t`;
      result += '\n';
    });
    navigator.clipboard.writeText(result);
    setCopied(true);
  };

  useEffect(() => {
    dispatch(getProducts({ searchWord: search, limit: 50, T }));

    return () => dispatch(getProducts.reset());
  }, [search]);

  useEffect(() => {
    getProductUrls(setUrlOptions, [], urlSearch);
  }, [urlSearch]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  return {
    urlsOptions,
    productOptions,
    isResetDisabled,
    handlePeriodChange,
    handleProductChange,
    handleUrlChange,
    handleUrlSearch,
    handleSearch,
    handleReset,
    handleCopyTable,
    copied,
  };
};

export default useFilter;
