export const axiosData = {
  login: {
    url: '/api/users/sign-in',
    method: 'post',
  },
  initialFetching: {
    url: '/api/users/info',
    method: 'post',
  },
  editProfile: {
    url: '/api/users',
    method: 'put',
  },
  resetWithEmail: {
    url: '/api/users/forget/password',
    method: 'post',
  },
  recoverPassword: {
    url: '/api/users/recover/password',
    method: 'post',
  },
  logOut: {
    url: '/api/users/sign-out',
    method: 'post',
  },
  userByPhone: {
    url: '/api/buyers/byPhone?phone=',
    method: 'get',
  },
  userOrdersByPhone: {
    url: '/api/buyers/orders',
    method: 'get',
  },
  userRatesByPhone: {
    url: '/api/buyers/rates',
    method: 'get',
  },
  userAddressesByPhone: {
    url: '/api/buyers/addresses',
    method: 'get',
  },
};
