import { all, takeEvery, put, call } from 'redux-saga/effects';
import {
  getWorkerEdit,
  getWorkers,
  getWorkerSalary,
  payWorkerSalary,
  removeWorker,
  resendEmail,
  selectWorker,
  workerCreate,
  workerEdit,
  workerRegistration,
  checkOrderEditibality,
  removeEditibality,
} from './actions';
import { errorLogger } from '../../helpers/errorLogger';
import { workersData } from './mockdata';
import { closeModal } from '../modals/actions';
import { modalNames } from '../modals/constants';
import Axios from '../../services/axios';
import { axiosData } from './requestGenerator';
import { workerRegistrationDataHandler } from './helpers';
import showNotification from '../../modals/components/notification/showNotification';
import { notifyMessage } from '../message';
import { setStorePermission } from '../store/actions';

function* checkOrderEditibalityRequest(id) {
  const url = axiosData.checkOrderEditibality.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.checkOrderEditibality,
    url,
  });
  return result;
}

function* watchCheckOrderEditible() {
  // OK
  yield takeEvery(checkOrderEditibality, function* workCheckEditible({ payload: { id, T } }) {
    try {
      const result = yield call(checkOrderEditibalityRequest, id);
      if (!result.editable) {
        yield call(notifyMessage, { body: T.error_someone_already_work, timer: 2000 }, T);
      }
      yield put(checkOrderEditibality.success({ editable: result.editable }));
    } catch (e) {
      yield put(checkOrderEditibality.error());
      errorLogger(e);
    }
  });
}

function* removeOrderEditibalityRequest(id) {
  const url = axiosData.removeOrderEditibality.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.removeOrderEditibality,
    url,
  });
  return result;
}

function* watchRemoveCheckOrderEditible() {
  yield takeEvery(removeEditibality, function* workRemoveCheckEditible({ payload: { id } }) {
    try {
      yield call(removeOrderEditibalityRequest, id);
      yield put(checkOrderEditibality.reset());
    } catch (e) {
      yield put(checkOrderEditibality.error());
      errorLogger(e);
    }
  });
}

function* payWorkersSalaryRequest(payload) {
  const url = axiosData.workerPaySalary.url.replace(':id', payload.id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.workerPaySalary,
    url,
    data: payload.data,
  });
  return result;
}

function* watchPayWorkerSalary() {
  yield takeEvery(payWorkerSalary, function* workPayWorkerSalary({ payload }) {
    try {
      const result = yield call(payWorkersSalaryRequest, payload);
      yield put(
        payWorkerSalary.success({
          data: result,
          id: payload.id,
          month: payload.data.month,
        })
      );
    } catch (e) {
      yield put(payWorkerSalary.error({ id: payload.id }));
      errorLogger(e);
    }
  });
}

function* getWorkersRequest(active, boardId) {
  const url = `${axiosData.getWorkers.url}${active ? '?status=active' : ''}${
    active && boardId ? `&boardId=${boardId}` : boardId ? `?boardId=${boardId}` : ''
  }`;
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getWorkers,
    url,
  });
  return result;
}

function* watchGetWorkers() {
  yield takeEvery(getWorkers, function* workGetWorkers({ payload: { active, boardId } }) {
    try {
      const result = yield call(getWorkersRequest, active, boardId);
      yield put(getWorkers.success({ data: result }));
    } catch (e) {
      yield put(getWorkers.error());
      errorLogger(e);
    }
  });
}

function* removeWorkerRequest(id) {
  const url = axiosData.removeWorker.url.replace(':id', id);
  yield call(Axios.request, {
    ...axiosData.removeWorker,
    url,
  });
}

function* watchRemoveWorker() {
  yield takeEvery(removeWorker, function* workRemoveWorker({ payload: { id } }) {
    try {
      if (id !== 1) {
        yield call(removeWorkerRequest, id);
      }
      yield put(removeWorker.success({ data: workersData }));
      yield put(getWorkers({ active: false }));
    } catch (e) {
      yield put(removeWorker.error());
      errorLogger(e);
    }
  });
}

function* getWorkerEditRequest(id) {
  const url = axiosData.getWorkerInfoToEdit.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getWorkerInfoToEdit,
    url,
  });

  const listsUrl = axiosData.getWorkerInfoToEditLists.url.replace(':id', id);
  const {
    data: { result: listsResult },
  } = yield call(Axios.request, {
    ...axiosData.getWorkerInfoToEditLists,
    url: listsUrl,
  });

  return { ...result, lists: listsResult };
}

function* watchGetWorkerEdit() {
  yield takeEvery(getWorkerEdit, function* workRemoveWorker({ payload: { id } }) {
    try {
      const data = yield call(getWorkerEditRequest, id);
      yield put(getWorkerEdit.success({ data }));
    } catch (e) {
      yield put(getWorkerEdit.error());
      errorLogger(e);
    }
  });
}

function* workerEditRequest(values, id) {
  const url = axiosData.workerEdit.url.replace(':id', id);
  const res = yield call(Axios.request, {
    ...axiosData.workerEdit,
    url,
    data: values,
  });

  return res;
}

function* watchWorkerEdit() {
  yield takeEvery(workerEdit, function* workRemoveWorker({ payload: { values, id } }) {
    try {
      yield call(workerEditRequest, values, id);
      yield put(workerEdit.success());
      yield put(closeModal({ name: modalNames.createEditWorker }));
    } catch (e) {
      yield put(workerEdit.error());
      errorLogger(e);
    }
  });
}

function* workerCreateRequest(values) {
  const res = yield call(Axios.request, {
    ...axiosData.createWorker,
    data: {
      ...values,
      permissions: values.permissions,
    },
  });

  return res;
}

function* watchWorkerCreate() {
  // OK
  yield takeEvery(workerCreate, function* workWorkerCreate({ payload: { values, T, store } }) {
    try {
      const res = yield call(workerCreateRequest, values);
      yield put(workerCreate.success());

      yield put(closeModal({ name: modalNames.createEditWorker }));
      if (store) {
        yield put(setStorePermission({ values: store, id: res?.data?.result?.userId }));
      }
      yield put(getWorkers({ active: false }));
    } catch (e) {
      yield put(workerCreate.error());

      yield call(notifyMessage, { body: T.the_email_already_taken, timer: '2000' }, T);

      errorLogger(e);
    }
  });
}

function* workerRegistrationRequest(values) {
  const res = yield call(Axios.request, {
    ...axiosData.workerRegistration,
    data: workerRegistrationDataHandler(values),
    params: {
      token: values.token,
    },
  });
  return res;
}

function* watchWorkerRegistration() {
  // OK
  yield takeEvery(workerRegistration, function* workWorkerRegistration({ payload: { values, T } }) {
    try {
      yield call(workerRegistrationRequest, values);
      yield put(workerRegistration.success());
    } catch (e) {
      yield put(workerRegistration.error());
      yield call(notifyMessage, { body: T.upload_normal_image_or_check_fields, timer: '2000' }, T);
      errorLogger(e);
    }
  });
}

function* workerSelectRequest(payload) {
  const url = axiosData.selectWorker.url.replace(':id', payload.listId).replace(':userId', payload.userId);
  const res = yield call(Axios.request, {
    ...axiosData.selectWorker,
    url,
  });

  return res;
}

function* watchWorkerSelect() {
  yield takeEvery(selectWorker, function* workWorkerSelect({ payload }) {
    const { dispatch } = payload;
    try {
      yield call(workerSelectRequest, payload);
      yield put(selectWorker.success());
      showNotification(dispatch, T.employee_details_edited);
    } catch (e) {
      yield put(selectWorker.error());
      errorLogger(e);
    }
  });
}

function* getWorkerSalaryRequest(id, year) {
  const url = axiosData.workerGetSalary.url.replace(':id', id);
  const currentYear = new Date().getFullYear();
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.workerGetSalary,
    url,
    params: { year: year || currentYear },
  });
  return result;
}

function* watchGetWorkerSalary() {
  yield takeEvery(getWorkerSalary, function* workGetSalaries({ payload: { id, year } }) {
    try {
      const data = yield call(getWorkerSalaryRequest, id, year);
      yield put(getWorkerSalary.success({ data, id }));
    } catch (e) {
      yield put(getWorkerSalary.error());
    }
  });
}

function* resendEmailRequest(email) {
  const res = yield call(Axios.request, {
    ...axiosData.resendEmail,
    data: { email },
  });

  return res;
}

function* watchResendEmail() {
  yield takeEvery(resendEmail, function* workResendEmailReq({ payload: { email } }) {
    try {
      yield call(resendEmailRequest, email);
      yield put(resendEmail.success());
    } catch (e) {
      yield put(resendEmail.error());
      errorLogger(e);
    }
  });
}

export default function* workers() {
  yield all([
    watchGetWorkers(),
    watchRemoveWorker(),
    watchGetWorkerEdit(),
    watchWorkerEdit(),
    watchWorkerCreate(),
    watchWorkerRegistration(),
    watchWorkerSelect(),
    watchPayWorkerSalary(),
    watchGetWorkerSalary(),
    watchResendEmail(),
    watchCheckOrderEditible(),
    watchRemoveCheckOrderEditible(),
  ]);
}
