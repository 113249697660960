import React from 'react';

const CommentsSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.812" height="17.821" viewBox="0 0 17.812 17.821">
      <path
        id="chat"
        d="M15.329,2.61A8.907,8.907,0,0,0,1.061,12.884L.144,16.92a.732.732,0,0,0,.875.875l4.035-.917A8.907,8.907,0,0,0,15.329,2.61Zm-4.44,8.515H5.316a.731.731,0,1,1,0-1.463h5.573a.731.731,0,0,1,0,1.463Zm1.858-2.972H5.316a.731.731,0,1,1,0-1.463h7.431a.731.731,0,0,1,0,1.463Z"
        transform="translate(-0.125 0)"
        fill="#397ae8"
      />
    </svg>
  );
};

export default CommentsSvg;
