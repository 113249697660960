import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { createDomain, getDomain, removeDomain } from './actions';

const createInitialState = {
  loading: false,
  error: false,
  success: false,
};

const create = handleActions(
  {
    [createDomain]: () => ({
      loading: true,
      error: false,
    }),
    [createDomain.success]: () => ({
      loading: false,
      error: false,
    }),
    [createDomain.error]: () => ({
      loading: false,
      error: true,
    }),
    [createDomain.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  createInitialState
);

const getInitialState = {
  loading: false,
  error: false,
  data: [],
};

const get = handleActions(
  {
    [getDomain]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [getDomain.success]: (state, { payload: { data } }) => ({
      ...state,
      loading: false,
      error: false,
      data,
    }),
    [getDomain.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [getDomain.reset]: (state) => ({
      ...state,
      loading: false,
      error: false,
    }),
  },
  getInitialState
);

const removeInitialState = {
  loading: false,
  error: false,
  data: [],
  success: false,
};

const remove = handleActions(
  {
    [removeDomain]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [removeDomain.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [removeDomain.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
    [removeDomain.reset]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: false,
    }),
  },
  removeInitialState
);

export default combineReducers({ remove, create, get });
