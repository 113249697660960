import { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { typeNames, typeOptions } from './constants';
import { routes } from '../../routes';
import { getOrderStatistic } from './helpers';
import useConstant from '../special/constants';

import { getProducts } from '../../store/products/actions';
import {
  getAnaliticDomains,
  getPresetByNameRequest,
  getPresetListRequest,
  getProductUrls,
  getUtmByType,
} from '../special/helpers';
import { selectProducts } from '../../store/products/selectors';
import { debounce } from '../../helpers/debounce';
import { selectLang, selectUserRole } from '../../store/user/selectors';
import { Lang } from '../../T';

const useSpecialProductPage = (limit, page, setTotal) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const dispatch = useDispatch();
  const history = useHistory();

  const typeHeaders = {
    grossProfit: T.Gross_Profit,
    netTotal: T.Net,
    newOrderCount: T.New_order,
    acceptedCount: T.Accepted,
    unAcceptedCount: T.Unaccepted,
    totalCount: T.Total_count,
    currentSoldCount: T.Current_Sold_Count,
    changeCount: T.Change_Count,
    returnCount: T.Return_Count,
    saleOverall: T.Sold_Count,
    pendingChangeCount: T.Change_Count_Pending,
    pendingReturnCount: T.Return_Count_Pending,
    rejectedCount: T.Reject_Count,
    callRejectedCount: T.Call_Rejected_Count,
    rejectedInPlaceCount: T.Rejected_In_Place_Count,
    pending: T.Pending,
    sentToStateCount: T.Sent_to_State_Count,
  };

  const { dateOption, deviceOptions, initialFilter, periodOptions, regionOptions } = useConstant();

  const products = useSelector(selectProducts);
  const role = useSelector(selectUserRole);

  const { typeName } = useParams();
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(initialFilter);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const [preset, setPreset] = useState(null);
  const [presetList, setPresetList] = useState([]);

  const [utmKey, setUtmKey] = useState(null);
  const [utmPage, setUtmPage] = useState(1);
  const [utmValueList, setUtmValueList] = useState([]);
  const [utmValueCount, setUtmValueCount] = useState(null);

  const [search, setSearch] = useState('');
  const [urlSearch, setUrlSearch] = useState('');

  const [domainOptions, setDomainOptions] = useState([]);
  const [urlsOptions, setUrlOptions] = useState([]);

  const handleUtmChange = (e) => {
    setFilter((prev) => ({ ...prev, utm: e }));
  };

  const handleChooseUtmKey = (newKey) => {
    setUtmPage(1);
    setUtmKey(newKey);
  };

  const goBackToSpecial = () => {
    localStorage.removeItem('filters');
    localStorage.removeItem('preset');
    localStorage.setItem('filter', JSON.stringify(filter));
    history.push(routes.special);
  };

  const handlePeriodChange = (e) => {
    setFilter((prev) => {
      const newState = { ...prev };
      if (e.length === 2) {
        const [startDate, endDate] = e;
        newState.startDate = startDate;
        newState.endDate = endDate;
        newState.period = {
          value: 'custom',
          label: `${e[0].toLocaleString('ru', dateOption)} - ${e[1].toLocaleString('ru', dateOption)}`,
        };
      } else {
        newState.period = e;
        newState.startDate = null;
        newState.endDate = null;
      }
      return newState;
    });
  };

  const handleRegionChange = (e) => setFilter((prev) => ({ ...prev, region: e }));

  const handleDeviceChange = (e) => setFilter((prev) => ({ ...prev, device: e }));

  const handleDomainChange = (e) => setFilter((prev) => ({ ...prev, domainIds: e }));

  const handleUrlChange = (e) => setFilter((prev) => ({ ...prev, urls: e }));

  const handleUrlSearch = debounce((value) => setUrlSearch(value), 500);

  const handleSearch = debounce((value) => setSearch(value), 500);

  const handleReset = () => {
    setFilter(initialFilter);
    setPreset(null);
  };

  const getPresetByName = async () => {
    if (preset) {
      const { data } = await getPresetByNameRequest(preset);
      if (data) {
        const startDate = data?.startDate ? new Date(data?.startDate) : null;
        const endDate = data?.endDate ? new Date(data?.endDate) : null;

        setFilter({
          ...data,
          startDate,
          endDate,
          percentageType: data?.percentageType?.value || initialFilter?.percentageType?.value,
        });
      } else {
        localStorage.removeItem('preset');
      }
    }
  };

  const getUTM = async (type) => {
    const data = await getUtmByType(type);

    setUtmValueList(data?.result?.map((item) => ({ label: item, value: item })));
    setUtmValueCount(data?.count);
  };

  const getMoreUtm = async (scrollTop, offsetHeight, scrollHeight) => {
    if (utmValueList.length < utmValueCount && scrollTop + offsetHeight + 5 > scrollHeight) {
      setUtmPage((prev) => prev + 1);
      const data = await getUtmByType(utmKey, utmPage + 1);
      setUtmValueList((prev) => [...prev, ...data?.result?.map((item) => ({ label: item, value: item }))]);
    }
  };

  const productOptions = useMemo(
    () =>
      products?.data?.rows?.map((product) => ({
        label: `${product.name}${product.barcode ? ` - ${product.barcode}` : ''}`,
        value: product.id,
      })) || [],
    [products]
  );

  const isResetDisabled = useMemo(() => {
    const {
      period,
      productIds,
      ignoreOutOfInterval,
      startTime,
      endTime,
      startDate,
      endDate,
      domainIds,
      urls,
      region,
      device,
      utm,
    } = filter;
    return !(
      period.value !== periodOptions[2].value ||
      region.value !== regionOptions[0].value ||
      device.value !== deviceOptions[0].value ||
      !!productIds.length ||
      startDate !== null ||
      endDate !== null ||
      startTime !== null ||
      endTime !== null ||
      ignoreOutOfInterval ||
      !!urls.length ||
      !!domainIds.length ||
      !!utm.length
    );
  }, [filter]);

  useEffect(() => {
    if (utmKey !== null) {
      getUTM(utmKey);
    }
  }, [utmKey]);

  useEffect(() => {
    const localFilters = JSON.parse(localStorage.getItem('filters') || '{}');
    const localPreset = localStorage.getItem('preset') || null;

    if (isFirstTime) {
      if (localPreset) {
        setPreset(localPreset);
      }

      if (localFilters.period) {
        const startDate = localFilters?.startDate ? new Date(localFilters?.startDate) : null;
        const endDate = localFilters?.endDate ? new Date(localFilters?.endDate) : null;

        setFilter({ ...localFilters, startDate, endDate });
      }
      setIsFirstTime(false);
    }
  }, [isFirstTime]);

  useEffect(() => {
    dispatch(getProducts({ searchWord: search, limit: 50, T }));

    return () => {
      dispatch(getProducts.reset());
    };
  }, [search]);

  useEffect(() => {
    if (role !== 'accountant') {
      getAnaliticDomains(setDomainOptions);
    }
    getPresetListRequest(setPresetList);
  }, []);

  useEffect(() => {
    if (preset) {
      getPresetByName();
    }
  }, [preset]);

  useEffect(() => {
    if (role !== 'accountant') {
      getProductUrls(setUrlOptions, filter.domainIds, urlSearch);
    }
  }, [filter.domainIds, urlSearch]);

  useEffect(() => {
    if (typeNames[typeName]) {
      if (!isFirstTime) {
        getOrderStatistic(
          {
            ...filter,
            limit,
            offset: (page - 1) * limit,
            type: typeOptions[typeName],
            isAccountant: role === 'accountant',
          },
          setData,
          setTotal
        );
      }
    } else {
      history.push(routes.special);
    }
  }, [filter, limit, page]);

  return {
    filter,
    data,
    typeName,
    setFilter,
    handlePeriodChange,
    handleSearch,
    productOptions,
    domainOptions,
    handleDomainChange,
    urlsOptions,
    handleUrlChange,
    handleUrlSearch,
    handleRegionChange,
    handleDeviceChange,
    handleReset,
    isResetDisabled,
    preset,
    setPreset,
    presetList,
    setPresetList,
    handleChooseUtmKey,
    handleUtmChange,
    utmValueList,
    getMoreUtm,
    goBackToSpecial,
    role,
    typeHeaders,
  };
};

export default useSpecialProductPage;
