import React from 'react';
import { Link } from 'react-router-dom';

import { object } from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from './cards.module.scss';
import SmallArrow from '../../../../svgs/SmallArrow';
import useCards from './useCards';
import { formatNumberWithThousandsSeparator } from '../../../../helpers/formatNubmer';
import { selectLang } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';

const Cards = ({ data }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const { role, moneyCardsData, bidCardsData, orderCardsData, currency } = useCards(data);

  return (
    <div className={classNames.cardsContent}>
      {role !== 'marketingExpert' && (
        <>
          <div className={classNames.title}>{T.Money}</div>
          <div className={classNames.row}>
            {moneyCardsData.map(({ name, amount, cash, card, percent, url }) => {
              return (
                <Link to={url} className={classNames.card} key={name}>
                  <div className={classNames.top}>
                    <div className={classNames.name}>{name}</div>
                    {percent && (
                      <div className={`${classNames.percent} ${percent < 0 ? classNames.negative : ''}`}>
                        <SmallArrow isNegative={percent < 0} />
                        <span>{percent < 0 ? -1 * percent : percent}%</span>
                      </div>
                    )}
                  </div>
                  <div className={classNames.amount}>
                    {formatNumberWithThousandsSeparator(amount, ' ')} {currency}
                  </div>
                  <div className={classNames.bottom}>
                    {cash !== null && (
                      <div className={classNames.bottomItem}>
                        {T.Cash} | {formatNumberWithThousandsSeparator(cash, ' ')} {currency}
                      </div>
                    )}
                    {card !== null && (
                      <div className={classNames.bottomItem}>
                        {T.Card} | {formatNumberWithThousandsSeparator(card, ' ')} {currency}
                      </div>
                    )}
                  </div>
                </Link>
              );
            })}
          </div>
        </>
      )}
      <div className={classNames.title}>{T.Bids_count}</div>
      <div className={classNames.row}>
        {bidCardsData.map(({ name, amount, percent, url }) => {
          return (
            <Link to={url} className={classNames.card} key={name}>
              <div className={classNames.top}>
                <div className={classNames.name}>{name}</div>
                {percent && (
                  <div className={`${classNames.percent} ${percent < 0 ? classNames.negative : ''}`}>
                    <SmallArrow isNegative={percent < 0} />
                    <span>{percent < 0 ? -1 * percent : percent}%</span>
                  </div>
                )}
              </div>
              <div className={classNames.amount}>{formatNumberWithThousandsSeparator(amount, ' ')}</div>
            </Link>
          );
        })}
      </div>
      <div className={classNames.title}>{T.Orders_count}</div>
      <div className={classNames.row}>
        {orderCardsData.map(({ name, percent, amount, bottomItems, url }) => {
          return (
            <Link to={url} className={classNames.card} key={name}>
              <div className={classNames.top}>
                <div className={classNames.name}>{name}</div>
                <div className={`${classNames.percent} ${percent < 0 ? classNames.negative : ''}`}>
                  <SmallArrow isNegative={percent < 0} />
                  <span>{percent < 0 ? -1 * percent : percent}%</span>
                </div>
              </div>
              <div className={classNames.amount}>{formatNumberWithThousandsSeparator(amount, ' ')}</div>
              <div className={classNames.bottom}>
                {bottomItems.map(({ id, text, value }) => (
                  <div className={classNames.bottomItem} key={id}>
                    {text} | {value}
                  </div>
                ))}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

Cards.propTypes = {
  data: object,
};

export default Cards;
