import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import './switch.css';

const Switch = ({
  text,
  size = 'default',
  disabled,
  offstyle = 'btn-secondary custom-swich-disabled',
  onstyle = 'btn-success custom-switch-active',
  inputProps = {},
  name,
  checked,
  isFormik = true,
}) => {
  return (
    <>
      <label className="d-flex w-100 align-items-center swich-parent-label">
        {text && <span className="switch-label m-0">{text}</span>}
        <span style={{ cursor: disabled ? 'not-allowed' : '' }} className={`${size} switch-wrapper customSwichItem`}>
          {isFormik ? (
            <Field type="checkbox" disabled={disabled} name={name} {...inputProps} />
          ) : (
            <input type="checkbox" disabled={disabled} name={name} {...inputProps} />
          )}
          <span className={`${checked ? onstyle : offstyle} switch`}>
            <span className="switch-handle" />
          </span>
        </span>
      </label>
    </>
  );
};

Switch.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  offstyle: PropTypes.string,
  onstyle: PropTypes.string,
  inputProps: PropTypes.object,
  name: PropTypes.string,
  checked: PropTypes.bool,
  isFormik: PropTypes.bool,
};

export default memo(Switch);
