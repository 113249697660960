export const selectWorkers = ({ workers: { get } }) => get;
export const selectEditibality = ({ workers: { checkEditibality } }) => checkEditibality;
export const selectRemoveWorker = ({ workers: { remove } }) => remove;
export const selectGetWorkerEdit = ({ workers: { getEdit } }) => getEdit;
export const selectWorkerEdit = ({ workers: { edit } }) => edit;
export const selectWorkerCreate = ({ workers: { create } }) => create;
export const selectWorkerRegistration = ({ workers: { registration } }) => registration;
export const selectWorkerResend = ({ workers: { resend } }) => resend;
export const selectWorkerSalary =
  (key) =>
  ({ workers: { getSalary } }) =>
    getSalary[key] || {};
export const selectPaySalary =
  (key) =>
  ({ workers: { pay } }) => {
    return pay[key] || {};
  };
