import { useState } from 'react';
import { useRef } from 'react';
import { useOutsideDetect } from '../../../../hooks/useOutsideDetect';

export const useCusomMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useOutsideDetect(setIsOpen);

  const controlButton = useRef(null);

  const top = controlButton?.current?.getBoundingClientRect().top;
  const left = controlButton?.current?.getBoundingClientRect().left;
  const height = controlButton?.current?.getBoundingClientRect().height;

  const handleDots = (e) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  return { isOpen, handleDots, ref, setIsOpen, controlButton, top, left, height };
};
