import { createActions } from 'redux-actions';
import { createCombinedAction } from '../helpers';

export const deliverymanProductsGet = createCombinedAction({
  name: 'deliverymanProductsGet',
  prefix: 'deliverymanProducts@@',
});

export const getDeliverymanProducts = createCombinedAction({
  name: 'getDeliverymanProducts',
  prefix: 'deliverymanProducts@@',
});

export const searchDeliverymanProducts = createCombinedAction({
  name: 'searchDeliverymanProducts',
  prefix: 'deliverymanProducts@@',
});

export const deliverymanProductsSet = createCombinedAction({
  name: 'deliverymanProductsSet',
  prefix: 'deliverymanProducts@@',
});
export const deliverymanProductsClear = createCombinedAction({
  name: 'deliverymanProductsAccept',
  prefix: 'deliverymanProducts@@',
});
export const saveProductCounts = createCombinedAction({
  name: 'saveProductCounts',
  prefix: 'deliverymanProducts@@',
});

export const { changeSpareProductCount, cancelSpareProductCountChanges } = createActions(
  'CHANGE_SPARE_PRODUCT_COUNT',
  'CANCEL_SPARE_PRODUCT_COUNT_CHANGES',
  {
    prefix: 'deliverymanProducts@@',
  }
);
