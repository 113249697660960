import { createActions } from 'redux-actions';
import { createCombinedAction } from '../helpers';

export const { initialFetching, initialFetchingSuccess, setInitialFetching } = createActions(
  'INITIAL_FETCHING',
  'INITIAL_FETCHING_SUCCESS',
  'SET_INITIAL_FETCHING',
  {
    prefix: 'user@@',
  }
);

export const login = createCombinedAction({
  name: 'login',
  prefix: 'user@@',
});

export const editProfile = createCombinedAction({
  name: 'editProfile',
  prefix: 'user@@',
});

export const logout = createCombinedAction({
  name: 'logout',
  prefix: 'user@@',
});

export const sendResetPasswordToMail = createCombinedAction({
  name: 'resetPassword',
  prefix: 'user@@',
});

export const sendConfirmedPasswords = createCombinedAction({
  name: 'confirmedPasswords',
  prefix: 'user@@',
});

export const getUserByPhone = createCombinedAction({ name: 'getUser', prefix: 'user@@' });
export const getUserOrdersByPhone = createCombinedAction({
  name: 'getUserOrders',
  prefix: 'user@@',
});
export const saveUserData = createCombinedAction({
  name: 'saveUserData',
  prefix: 'user@@',
});
export const useInfo = createCombinedAction({
  name: 'useInfo',
  prefix: 'user@@',
});
export const getUserRatesByPhone = createCombinedAction({ name: 'getUserRates', prefix: 'user@@' });
export const getUserAddressesByPhone = createCombinedAction({
  name: 'getUserAddresses',
  prefix: 'user@@',
});
export const setFormValues = createCombinedAction({
  name: 'setFormValues',
  prefix: 'user@@',
});

export const setLang = createCombinedAction({
  name: 'setLang',
  prefix: 'user@@',
});

export const setNavbarState = createCombinedAction({
  name: 'setNavbarState',
  prefix: 'user@@',
});
