export const workersData = [
  {
    name: 'worker 1',
    date: '22 apr 2021',
    email: 'test@email.com',
    role: 'araqich',
    id: 1,
  },
  {
    name: 'worker 2',
    date: '22 apr 2023',
    email: 'test@emai2.com',
    role: 'araqich',
    id: 2,
  },
];

export const editWorkerData = {
  email: 'aa@email.com',
  position: '',
  salary: 0,
  percent: 0,
};
