import React from 'react';
import { useSelector } from 'react-redux';
import { array, func, object } from 'prop-types';
import classNames from './filters.module.scss';
import Select from '../../../../components/select/Select';
import { selectLang } from '../../../../store/user/selectors';
import { Lang } from '../../../../T';
import useFilters from './useFilters';

const Filters = ({ filter, setFilter, periodOptions, initialFilter, changePage }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { isResetDisabled, handleReset, handlePeriodChange, userOptions, handleUserChange } = useFilters({
    periodOptions,
    filter,
    setFilter,
    initialFilter,
    changePage,
  });

  return (
    <div className={classNames.filterWrapper}>
      <div className={classNames.filters}>
        <div className={classNames.period}>
          <Select
            options={periodOptions}
            onChange={handlePeriodChange}
            label={T.Date}
            value={filter.period}
            placeholder=""
            widthDate
          />
        </div>
        <div className={classNames.period}>
          <Select
            options={userOptions}
            onChange={handleUserChange}
            label={T.Users}
            value={filter.userId}
            placeholder={T.Users}
          />
        </div>
        {!isResetDisabled ? (
          <button className={classNames.resetFilter} type="button" disabled={isResetDisabled} onClick={handleReset}>
            {T.reset_filter}
          </button>
        ) : null}
      </div>
    </div>
  );
};
Filters.propTypes = {
  filter: object,
  setFilter: func,
  initialFilter: object,
  periodOptions: array,
  changePage: func,
};

export default Filters;
