import { searchDataNames } from './constants';

export const axiosData = {
  [searchDataNames.city]: {
    method: 'get',
    url: '/api/admin/permissions/group',
  },
  [searchDataNames.state]: {
    method: 'get',
    url: '/api/admin/permissions',
  },
  [searchDataNames.product]: {
    method: 'get',
    url: '/api/product',
  },

  [searchDataNames.mainSearch]: {
    method: 'post',
    url: '/api/elastic/suggestions/documents/search',
  },
};

export const dataHandlers = {
  [searchDataNames.city]: ({ data: { result } }) => result,
  [searchDataNames.state]: ({ data: { result } }) => result,
  [searchDataNames.mainSearch]: ({ data }) => data,
  [searchDataNames.product]: ({
    data: {
      result: { rows },
    },
  }) => rows.map(({ name, ...rest }) => ({ label: name, value: rest })),
};
