export const axiosData = {
  createWorker: { url: '/api/admin/invite', method: 'post' },
  resendEmail: { url: '/api/admin/invite/resend', method: 'post' },
  getWorkers: { url: '/api/admin/users', method: 'get' },
  removeWorker: { url: '/api/admin/users/:id', method: 'delete' },
  getWorkerInfoToEdit: {
    url: '/api/admin/users/:id',
    method: 'get',
  },
  getWorkerInfoToEditLists: {
    url: '/api/admin/users/:id/lists',
    method: 'get',
  },
  workerRegistration: {
    url: '/api/users/invite/accept',
    method: 'post',
  },
  workerEdit: {
    url: '/api/admin/users/:id',
    method: 'put',
  },
  selectWorker: {
    url: '/api/order/:id/set/operator/:userId',
    method: 'put',
  },
  workerPaySalary: {
    url: '/api/users/:id/salaries/pay',
    method: 'post',
  },
  workerGetSalary: {
    url: '/api/users/:id/salaries',
    method: 'get',
  },
  checkOrderEditibality: {
    url: '/api/order/:id/edit/state',
    method: 'post',
  },
  removeOrderEditibality: {
    url: '/api/order/:id/edit/state',
    method: 'delete',
  },
};
