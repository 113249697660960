import React from 'react';
import PropTypes from 'prop-types';
import Notifications from './components/notifications/Notifications';
import Settings from './components/settings/Settings';

const UserInfo = ({ path }) => (
  <div className="d-flex align-items-center pr-md-2">
    <div className="mr-md-2">
      <Notifications />
    </div>
    <div>
      <Settings path={path} />
    </div>
  </div>
);
UserInfo.propTypes = {
  path: PropTypes.string,
};
export default UserInfo;
