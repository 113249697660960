import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
  drag,
  getDraggableTable,
  createList,
  removeList,
  editList,
  createListItem,
  getListItemToEdit,
  editListItem,
  copyListItem,
  removeListItem,
  getDraggableTableListItems,
  archiveListItem,
  resetReturnChangeListItemStatus,
  archiveList,
  statusShippingPut,
  toggleArchive,
  changeOrderStatusDone,
  changeOrderStatusBack,
  putFilteredValueInStore,
  getMoreInfoOrder,
  revertStatus,
  getBoardName,
  saveListItemId,
} from './actions';

const changeOrderStatusBackInitialState = {
  loading: false,
  error: false,
  success: false,
};

const changeOrderStatusToBack = handleActions(
  {
    [changeOrderStatusBack]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [changeOrderStatusBack.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [changeOrderStatusBack.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  changeOrderStatusBackInitialState
);

const changeOrderStatusInitialState = {
  loading: false,
  error: false,
  success: false,
};

const changeOrderStatusToDone = handleActions(
  {
    [changeOrderStatusDone]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [changeOrderStatusDone.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [changeOrderStatusDone.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
  },
  changeOrderStatusInitialState
);

const createListInitialState = {
  loading: false,
  error: false,
  success: false,
};

const createBoardList = handleActions(
  {
    [createList]: (state) => ({ ...state, loading: true, error: false }),
    [createList.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [createList.error]: (state) => ({ ...state, loading: false, error: true }),
    [createList.reset]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: false,
    }),
  },
  createListInitialState
);

const initialState = {
  loading: false,
  error: false,
  data: {
    columns: {},
    columnOrder: [],
  },
  archived: false,
  boardName: '',
};

const removeListInitialState = {
  loading: false,
  error: false,
  success: false,
};

const removeBoardList = handleActions(
  {
    [removeList]: (state) => ({ ...state, loading: true, error: false }),
    [removeList.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [removeList.error]: (state) => ({ ...state, loading: false, error: true }),
    [removeList.reset]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: false,
    }),
  },
  removeListInitialState
);

const archiveListInitialState = {
  loading: false,
  error: false,
  success: false,
};

const archiveBoardList = handleActions(
  {
    [archiveList]: (state, { payload: { listId } }) => ({
      ...state,
      [listId]: { loading: true, error: false },
    }),
    [archiveList.success]: (state, { payload: { listId } }) => ({
      ...state,
      [listId]: {
        loading: false,
        error: false,
      },
    }),
    [archiveList.error]: (state, { payload: { listId } }) => ({
      ...state,
      [listId]: {
        loading: false,
        error: true,
      },
    }),
    [archiveList.reset]: () => ({}),
  },
  archiveListInitialState
);

const editListInitialState = {};

const editBoardList = handleActions(
  {
    [editList]: (state, { payload: { key } }) => ({
      ...state,
      [key]: { ...state[key], loading: true, error: false },
    }),
    [editList.success]: (state, { payload: { key } }) => ({
      ...state,
      [key]: {
        loading: false,
        error: false,
        success: true,
      },
    }),
    [editList.error]: (state, { payload: { key } }) => ({
      ...state,
      [key]: { loading: false, error: true },
    }),
    [editList.reset]: (state, { payload: { key } }) => ({
      ...state,
      [key]: { loading: false, error: false, success: false },
    }),
  },
  editListInitialState
);

export const getBoard = handleActions(
  {
    [getDraggableTable]: (state) => ({ ...state, loading: true, error: false, success: false }),
    [getDraggableTable.success]: (state, { payload: { data, boardName } }) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
      data,
      boardName: boardName?.name,
    }),
    [getDraggableTable.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
    [getDraggableTable.reset]: () => ({
      loading: false,
      error: false,
      success: false,
      data: [],
    }),
    [drag.success]: (state, { payload: data }) => ({ ...state, data }),
    [getDraggableTableListItems.success]: (state, { payload: { listItems, id } }) => {
      return {
        ...state,
        data: {
          ...state.data,
          columns: {
            ...state.data.columns,
            [id]: {
              ...state.data.columns[id],
              listItems: [...state.data.columns[id].listItems, ...listItems],
              items: [...state.data.columns[id].items, ...listItems],
              loading: false,
            },
          },
        },
      };
    },
    [getDraggableTableListItems]: (state, { payload: { listId } }) => {
      return {
        ...state,
        data: {
          ...state.data,
          columns: {
            ...state.data.columns,
            [listId]: {
              ...state.data.columns[listId],
              loading: true,
            },
          },
        },
      };
    },

    [toggleArchive]: (state) => {
      return {
        ...state,
        archived: !state.archived,
      };
    },
    [toggleArchive.reset]: (state) => {
      return {
        ...state,
        archived: false,
      };
    },
  },
  initialState
);

const createBoardListItemInitialState = {
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
};

export const createBoardListItem = handleActions(
  {
    [createListItem]: (state) => ({
      ...state,
      loading: true,
      error: false,
      success: false,
    }),
    [createListItem.success]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        errorMessage: payload.clearMessage,
      };
    },
    [createListItem.error]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: payload,
        success: false,
      };
    },
    [createListItem.reset]: () => ({
      loading: false,
      error: false,
      success: false,
    }),
  },
  createBoardListItemInitialState
);

const copyBoardListItemInitialState = {};

export const copyBoardListItem = handleActions(
  {
    [copyListItem]: (state, { payload: { id } }) => ({
      ...state,
      [id]: { loading: true, error: false, success: false },
    }),
    [copyListItem.success]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: false,
        error: false,
        success: true,
      },
    }),
    [copyListItem.error]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: false,
        error: true,
        success: false,
      },
    }),
    [copyListItem.reset]: () => ({}),
  },
  copyBoardListItemInitialState
);

const removeBoardListItemInitialState = {};

export const removeBoardListItem = handleActions(
  {
    [removeListItem]: (state, { payload: { id } }) => ({
      ...state,
      [id]: { loading: true, error: false },
    }),
    [removeListItem.success]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: false,
        error: false,
      },
    }),
    [removeListItem.error]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: false,
        error: true,
      },
    }),
    [removeListItem.reset]: () => ({}),
  },
  removeBoardListItemInitialState
);

const archiveBoardListItemInitialState = {
  loading: false,
  success: false,
  error: false,
  accessToLoad: false,
};

export const archiveBoardListItem = handleActions(
  {
    [archiveListItem]: (state, { payload: { id } }) => ({
      ...state,
      [id]: { loading: true, error: false, success: false },
      accessToLoad: true,
    }),
    [archiveListItem.success]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: false,
        error: false,
        success: true,
      },
      accessToLoad: false,
    }),
    [archiveListItem.error]: (state, { payload: { id } }) => ({
      ...state,
      [id]: {
        loading: false,
        error: true,
        success: false,
      },
      accessToLoad: false,
    }),
    [archiveListItem.reset]: () => ({}),
  },
  archiveBoardListItemInitialState
);

const getBoardListItemToEditInitialState = {
  loading: false,
  error: false,
  data: {},
  isDeliveryMan: false,
  isWarehouse: false,
};

export const getBoardListItemToEdit = handleActions(
  {
    [getListItemToEdit]: (state) => ({ ...state, loading: true, error: false }),
    [getListItemToEdit.success]: (state, { payload: { data } }) => {
      return {
        ...state,
        loading: false,
        error: false,
        data,
        isDeliveryMan: data.orderList.listType === 'deliveryMan' && data.orderStatus === null,
        isWarehouse:
          data.orderList.listType === 'warehouse' && (data.orderStatus === null || data.orderStatus === 'then'),
      };
    },
    [getListItemToEdit.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [getListItemToEdit.reset]: () => ({
      loading: false,
      error: false,
      data: {},
      isDeliveryMan: false,
      isWarehouse: false,
    }),
  },
  getBoardListItemToEditInitialState
);

// viewMore
const getMoreInfoOrderInitialState = {
  loading: false,
  error: false,
  data: {},
};

export const getMore = handleActions(
  {
    [getMoreInfoOrder]: (state) => ({ ...state, loading: true, error: false }),
    [getMoreInfoOrder.success]: (state, { payload: { data } }) => {
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data,
      };
    },
    [getMoreInfoOrder.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
    [getListItemToEdit.reset]: () => ({
      loading: false,
      error: false,
      data: {},
    }),
  },
  getMoreInfoOrderInitialState
);

const statusShippingState = {
  success: false,
  loading: false,
  error: false,
  data: {},
};

export const statusShipping = handleActions(
  {
    [statusShippingPut]: (state) => ({
      ...state,
      success: false,
      loading: true,
      error: false,
    }),
    [statusShippingPut.success]: (state) => ({
      ...state,
      success: true,
      loading: false,
      error: false,
    }),
    [statusShippingPut.error]: (state) => ({
      ...state,
      success: false,
      loading: false,
      error: true,
    }),
  },
  statusShippingState
);

const editBoardListItemInitialState = {
  success: false,
  loading: false,
  error: false,
};

export const editBoardListItem = handleActions(
  {
    [editListItem]: (state) => ({
      ...state,
      success: false,
      loading: true,
      error: false,
    }),
    [editListItem.success]: (state) => ({
      ...state,
      success: true,
      loading: false,
      error: false,
    }),
    [editListItem.error]: (state) => ({
      ...state,
      success: false,
      loading: false,
      error: true,
    }),
    [editListItem.reset]: () => ({
      success: false,
      loading: false,
      error: false,
    }),
  },
  editBoardListItemInitialState
);

const resetReturnChangeListItemStatusInitialState = {
  loading: false,
  error: false,
};
export const resetReturnChangeListItem = handleActions(
  {
    [resetReturnChangeListItemStatus]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [resetReturnChangeListItemStatus.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
    }),
    [resetReturnChangeListItemStatus.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [resetReturnChangeListItemStatus.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  resetReturnChangeListItemStatusInitialState
);
const dragStatusInitialState = {
  loading: false,
  error: false,
  success: false,
};
export const dragState = handleActions(
  {
    [drag]: (state) => ({ ...state, loading: true, error: false }),
    [drag.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [drag.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
  },
  dragStatusInitialState
);

const putFilteredValueInitialState = {
  loading: false,
  error: false,
  success: false,
  filteredValue: {},
};
export const putFilteredValue = handleActions(
  {
    [putFilteredValueInStore]: (state) => ({ ...state, loading: true, error: false }),
    [putFilteredValueInStore.success]: (state, { payload: { values, dateRange } }) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
      filteredValue: values,
      dateRange,
    }),
    [putFilteredValueInStore.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
      filteredValue: {},
      dateRange: [null, null],
    }),
    [putFilteredValueInStore.reset]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: false,
      filteredValue: {},
      dateRange: [null, null],
    }),
  },
  putFilteredValueInitialState
);

const revertStatusInitialState = {
  loading: false,
  error: false,
  success: false,
};

export const revert = handleActions(
  {
    [revertStatus]: (state) => ({ ...state, loading: true, error: false, success: false }),
    [revertStatus.success]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
    }),
    [revertStatus.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
    [revertStatus.reset]: (state) => ({
      ...state,
      loading: false,
      error: false,
      success: false,
    }),
  },
  revertStatusInitialState
);

const getBoardNameInitialState = {
  loading: false,
  error: false,
  success: false,
  boardName: '',
};

export const getBoardNameReq = handleActions(
  {
    [getBoardName]: (state) => ({ ...state, loading: true, error: false, success: false }),
    [getBoardName.success]: (state, { payload: { boardName } }) => ({
      ...state,
      loading: false,
      error: false,
      success: true,
      boardName,
    }),
    [getBoardName.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
  },
  getBoardNameInitialState
);
const saveListItemIdInitialState = {
  loading: false,
  error: false,
  success: false,
  listItemId: '',
};

export const save = handleActions(
  {
    [saveListItemId]: (state) => ({ ...state, loading: true, error: false, success: false }),
    [saveListItemId.success]: (state, { payload: { listItemId } }) => {
      return { ...state, loading: false, error: false, success: true, listItemId };
    },
    [saveListItemId.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
  },
  saveListItemIdInitialState
);

const addLabelInitialState = {
  loading: false,
  error: false,
  success: false,
};

export const addLabel = handleActions(
  {
    [saveListItemId]: (state) => ({ ...state, loading: true, error: false, success: false }),
    [saveListItemId.success]: () => {
      return { loading: false, error: false, success: true };
    },
    [saveListItemId.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
  },
  addLabelInitialState
);

const removelabelInitialState = {
  loading: false,
  error: false,
  success: false,
  listItemId: '',
};

export const removeLabel = handleActions(
  {
    [saveListItemId]: (state) => ({ ...state, loading: true, error: false, success: false }),
    [saveListItemId.success]: () => {
      return { loading: false, error: false, success: true };
    },
    [saveListItemId.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
      success: false,
    }),
  },
  removelabelInitialState
);

export default combineReducers({
  dragState,
  getBoard,
  createBoardList,
  removeBoardList,
  editBoardList,
  createBoardListItem,
  changeOrderStatusToDone,
  getBoardListItemToEdit,
  editBoardListItem,
  copyBoardListItem,
  removeBoardListItem,
  archiveBoardListItem,
  resetReturnChangeListItem,
  archiveBoardList,
  statusShipping,
  changeOrderStatusToBack,
  putFilteredValue,
  getMore,
  revert,
  getBoardNameReq,
  save,
});
