import React from 'react';

import { Route, Redirect, useHistory, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectIsAuthenticated, selectUserRole } from '../../store/user/selectors';
import { routes } from '../../routes';

import Layout from '../../layout/Layout';
import WelcomPage from '../welcome-page/WelcomPage';
import WorkerRegistration from '../../pages/worker-registration/WorkerRegistration';
import Authentication from '../../pages/authentication/Authentication';
import NotFoundPage from '../../pages/notFoundPage/NotFoundPage';
import PrivacyPolicy from '../../pages/privacy/PrivacyPolicy';
import ResetPassword from '../../pages/resetPassword/ResetPassword';
import RecoverPassword from '../../pages/recoverPassword/RecoverPassword';
import { homePages } from '../../layout/constants';

const AuthenticationRoute = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const role = useSelector(selectUserRole);
  const {
    location: { pathname },
  } = useHistory();

  if (pathname === routes.privacy) {
    return (
      <Switch>
        <Route exact path={routes.privacy}>
          <PrivacyPolicy />
        </Route>
      </Switch>
    );
  }

  if (!isAuthenticated) {
    return (
      <Switch>
        <Route exact path={routes.welcomePage}>
          <Layout key="layout-login">
            <WelcomPage />
          </Layout>
        </Route>
        <Route exact path={routes.workerRegistration}>
          <Layout key="layout-login">
            <WorkerRegistration />
          </Layout>
        </Route>
        <Route exact path={routes.recoverPassword}>
          <Layout key="layout-login">
            <RecoverPassword />
          </Layout>
        </Route>
        <Route exact path={routes.authentication}>
          <Layout key="layout-login">
            <Authentication />
          </Layout>
        </Route>
        <Route exact path={routes.privacy}>
          <Layout key="layout-login">
            <PrivacyPolicy />
          </Layout>
        </Route>
        <Route exact path={routes.resetPassword}>
          <Layout key="layout-login">
            <ResetPassword />
          </Layout>
        </Route>
        <Route path="*" component={NotFoundPage} />
      </Switch>
    );
  }

  if (pathname === routes.authentication || pathname === routes.welcomePage) {
    return <Redirect to={homePages[role]} />;
  }
  return null;

  // return pathname === routes.authentication &&
  //   (role === 'marketingExpert' || role === 'accountant') ? (
  //   <Redirect to={routes.special} />
  // ) : pathname === routes.welcomePage && (role === 'marketingExpert' || role === 'accountant') ? (
  //   <Redirect to={routes.special} />
  // ) : pathname === routes.authentication && role === 'viewer' ? (
  //   <Redirect to={routes.products} />
  // ) : pathname === routes.welcomePage && role === 'viewer' ? (
  //   <Redirect to={routes.products} />
  // ) : pathname === routes.welcomePage && role !== 'deliveryMan' ? (
  //   <Redirect to={role === 'productCreator' ? routes.products : routes.home} />
  // ) : pathname === routes.welcomePage && role === 'deliveryMan' ? (
  //   <Redirect to={routes.draggableBoard.replace('id', 943157612)} />
  // ) : pathname === routes.authentication && role !== 'deliveryMan' ? (
  //   <Redirect to={role === 'productCreator' ? routes.products : routes.home} />
  // ) : pathname === routes.authentication && role === 'deliveryMan' ? (
  //   <Redirect to={routes.draggableBoard.replace('id', 943157612)} />
  // ) : null;
};

export default AuthenticationRoute;
