import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useOptions } from './useOptions';
import { modalNames } from '../../../../../../store/modals/constants';
import { openModal } from '../../../../../../store/modals/actions';
import classNames from './options.module.scss';
import { selectLang, selectUserRole } from '../../../../../../store/user/selectors';
import { Lang } from '../../../../../../T';

const Options = ({
  item,
  listId,
  setShowMenu,
  handleFilterList,
  listType,
  changeContent,
  setChangeContent,
  card = false,
}) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);
  const { archived, handleCopy, handleRemove, handleArchive, loading } = useOptions(item, listId);
  const role = useSelector(selectUserRole);
  const dispatch = useDispatch();
  const deleteItem = useCallback(
    (e) => {
      setShowMenu && setShowMenu(false);
      dispatch(
        openModal({
          name: modalNames.confirmation,
          data: {
            title: T.delete_order,
            description: T.are_you_sure_delete_order,
            onOk: () => handleRemove(e),
          },
        })
      );
    },
    [item]
  );

  return (
    <ul className={`list-unstyled ${classNames.optionsList}`}>
      {!archived && (
        <li
          role="presentation"
          onClick={(e) => {
            handleCopy(e);
            setShowMenu && setShowMenu(false);
          }}
        >
          <span />
          {T.copy}
        </li>
      )}
      <li
        role="presentation"
        onClick={(e) => {
          if (!loading) {
            handleArchive(e);
          }
          setShowMenu && setShowMenu(false);
        }}
      >
        <span />
        {!archived ? T.move_to_archive : T.remove_from_archive}
      </li>
      {role === 'admin' && (
        <li
          role="presentation"
          onClick={(e) => {
            deleteItem(e);
          }}
        >
          {T.delete}
        </li>
      )}
      {!card && (
        <li role="presentation" onClick={handleFilterList}>
          {T.filter}
        </li>
      )}
      {listType === 'newOrder' && (
        <li
          role="presentation"
          onClick={() => {
            setChangeContent((prev) => !prev);
          }}
        >
          {!changeContent ? T.to_count : T.cancel_count}
        </li>
      )}
    </ul>
  );
};

Options.propTypes = {
  item: PropTypes.object,
  listId: PropTypes.string,
  setShowMenu: PropTypes.func,
  handleFilterList: PropTypes.func,
  listType: PropTypes.string,
  loading: PropTypes.bool,
  changeContent: PropTypes.bool,
  setChangeContent: PropTypes.func,
  card: PropTypes.bool,
};
export default Options;
