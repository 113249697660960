import axios from '../../services/axios';
import { messages } from './constants';

export const getHistoryRequest = async (filter, page, limit) => {
  const { period, startDate, endDate, userId } = filter;
  const startDates = startDate?.toLocaleDateString('ru-RU').split('.');
  const endDates = endDate?.toLocaleDateString('ru-RU').split('.');
  const { data } = await axios
    .get('/api/history', {
      params: {
        intervalType: period.value !== 'custom' ? period.value : undefined,
        startDate: startDate ? `${startDates[2]}-${startDates[1]}-${startDates[0]}` : undefined,
        endDate: endDate ? `${endDates[2]}-${endDates[1]}-${endDates[0]}` : undefined,
        userId: userId?.value,
        limit,
        offset: (page - 1) * limit,
      },
    })
    .then((res) => res);
  return data.result;
};

export const createHistoryMessage = (item, lang) => {
  return messages(lang, item)[item.type];
};
