import React from 'react';

const FilterTriangleCrossedSvg = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.0267 18L0 0.973254L0.973257 0L18 17.0267L17.0267 18ZM1.33516 4.43763V3.07929H3.46438V4.43763H1.33516ZM4.05184 9.19219V7.83384H8.22174V9.19219H4.05184ZM7.33492 4.43763L5.97657 3.07929H17.6381V4.43763H7.33492ZM7.67433 13.9475V12.5891H11.2968V13.9475H7.67433ZM12.0895 9.19289L10.7311 7.83455H14.92V9.19289H12.0895Z"
        fill="#252733"
      />
    </svg>
  );
};

export default FilterTriangleCrossedSvg;
