import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../../store/modals/actions';
import { modalNames } from '../../../../store/modals/constants';
import { selectDraggableBoard } from '../../../../store/draggable-board/selector';
// eslint-disable-next-line import/no-cycle
import { useApp } from '../../../../useApp';
import { moveAllItems } from '../../../../store/lists/actions';
import RightSvg from '../../../../svgs/rightArrow';
import { Lang } from '../../../../T';
import { selectLang } from '../../../../store/user/selectors';

const Move = ({ listId, disable }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const dispatch = useDispatch();

  const { archived } = useSelector(selectDraggableBoard);
  const { role } = useApp();

  const handleSubmitMove = () => {
    dispatch(moveAllItems({ listId, T }));
  };
  const handleMove = useCallback(() => {
    dispatch(
      openModal({
        name: modalNames.confirmation,
        data: {
          title: T.move_orders_to_state_on_way,
          description: T.are_you_sure_to_move_orders,
          okText: T.move,
          onOk: handleSubmitMove,
        },
      })
    );
  }, [listId]);

  return (
    !archived &&
    (role === 'storeKeeper' || role === 'admin' || role === 'director') && (
      <button type="button" className="moveBoardBtn" onClick={handleMove} disabled={disable}>
        <RightSvg />
        {T.move}
      </button>
    )
  );
};

Move.propTypes = {
  listId: PropTypes.string,
  disable: PropTypes.bool,
};

export default Move;
