import React from 'react';
import PropTypes from 'prop-types';
import classNames from './select.module.scss';
import { useSelect } from './useSelect';
import SearchSvg from '../../svgs/SearchSvg';
import ChevronSvg from '../../svgs/ChevronSvg';
import DateRangePicker from '../date-range-picker/DateRangePicker';

const Select = ({
  isSearchable,
  placeholder,
  label,
  value: outerValue,
  options,
  onChange,
  onSearch = () => {},
  widthDate,
  height,
}) => {
  const {
    isOpen,
    handleChangeSelect,
    handleSelectOpen,
    ref,
    inputValue,
    hanldeInputChange,
    optionsData,
    selected,
    isDirty,
    handleCustom,
    customOpen,
    startDate,
    endDate,
    handleChange,
  } = useSelect({ onChange, options, isSearchable, outerValue, onSearch });

  return (
    <div className={classNames.selectWrapper}>
      <ul>
        {label && <label>{label}</label>}
        <li ref={ref}>
          <div onClick={handleSelectOpen} className={classNames.checkWrapper}>
            <input
              style={{ height }}
              type="text"
              value={(isDirty ? inputValue : selected) || ''}
              placeholder={placeholder}
              readOnly={!isSearchable}
              onChange={hanldeInputChange}
            />
            {isSearchable ? <SearchSvg /> : <ChevronSvg />}
          </div>
          {isOpen && (
            <ul>
              {optionsData.length ? (
                <>
                  {optionsData?.map(({ value, label }) => (
                    <li key={value}>
                      <button type="button" onClick={() => handleChangeSelect(label, value)}>
                        {label}
                      </button>
                    </li>
                  ))}
                  {widthDate && (
                    <li>
                      <button type="button" onClick={handleCustom}>
                        Custom
                      </button>
                    </li>
                  )}
                </>
              ) : (
                <p className={classNames.noDataItem}>no data</p>
              )}
            </ul>
          )}
          {customOpen && <DateRangePicker startDate={startDate} endDate={endDate} handleChange={handleChange} />}
        </li>
      </ul>
    </div>
  );
};

Select.propTypes = {
  isSearchable: PropTypes.bool,
  widthDate: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  height: PropTypes.number,
  value: PropTypes.object,
  onSearch: PropTypes.func,
};

export default Select;
