import { bool } from 'prop-types';
import React from 'react';

const SmallArrow = ({ isNegative = true }) => {
  return !isNegative ? (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_301)">
        <path
          d="M6.67745 2.57252L4.17745 0.0725151C4.13061 0.0259523 4.06725 -0.000183105 4.0012 -0.000183105C3.93515 -0.000183105 3.87179 0.0259523 3.82495 0.0725151L1.32495 2.57252L1.67745 2.92501L3.74995 0.852515V8.00002H4.24995V0.852515L6.32245 2.92501L6.67745 2.57252Z"
          fill="#11AF80"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_301">
          <rect width="8" height="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_309)">
        <path
          d="M1.32255 5.4275L3.82255 7.9275C3.86939 7.97406 3.93275 8.0002 3.9988 8.0002C4.06485 8.0002 4.12821 7.97406 4.17505 7.9275L6.67505 5.4275L6.32255 5.075L4.25005 7.1475L4.25005 2.12e-07L3.75005 2.55712e-07L3.75005 7.1475L1.67755 5.075L1.32255 5.4275Z"
          fill="#E7281C"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_309">
          <rect width="8" height="8" fill="white" transform="translate(8 8) rotate(180)" />
        </clipPath>
      </defs>
    </svg>
  );
};

SmallArrow.propTypes = {
  isNegative: bool,
};
export default SmallArrow;
