import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useApp } from '../../../useApp';
import classNames from './mobileMenu.module.scss';
import { Lang } from '../../../T';
import { selectLang } from '../../../store/user/selectors';
import { getPaths } from '../../constants';

const MobileMenu = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { role } = useApp();
  const paths = getPaths(T);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className={classNames.mobileMenu}>
      <ul>
        {paths[role].map((path) => (
          <React.Fragment key={path.id}>
            <li>
              <NavLink activeClassName={classNames.active} to={path.path} className={`${classNames.sidebarLinks}`}>
                {path.title}
              </NavLink>
            </li>
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};
export default MobileMenu;
