import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from './navbar.module.scss';
import { useApp } from '../../../useApp';
import Settings from '../header/components/user-info/components/settings/Settings';
import { Lang } from '../../../T';
import { selectLang } from '../../../store/user/selectors';
import { getPaths, homePages } from '../../constants';
import DoubleArrow from '../../../svgs/DoubleArrow';
import AnalyticsIcon from '../../../svgs/navbar/AnalyticsIcon';
import BoardsIcon from '../../../svgs/navbar/BoardsIcon';
import StoreIcon from '../../../svgs/navbar/StoreIcon';
import RegionIcon from '../../../svgs/navbar/RegionIcon';
import ProductIcon from '../../../svgs/navbar/ProductIcon';
import CustomerIcon from '../../../svgs/navbar/CustomerIcon';
import AdminIcon from '../../../svgs/navbar/AdminIcon';
import MarketingIcon from '../../../svgs/navbar/MarketingIcon';
import DomainIcon from '../../../svgs/navbar/DomainIcon';
import MoneyIcon from '../../../svgs/navbar/MoneyIcon';
import { routes } from '../../../routes';
import BigLogoSvg from '../../../svgs/BigLogoSvg';
import SmallLogoSvg from '../../../svgs/SmallLogoSvg';

const Navbar = ({ handleToggleSidebar, isSidebarClose }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { role } = useApp();
  const paths = getPaths(T);
  const PathIcons = {
    [routes.special]: <AnalyticsIcon />,
    [routes.home]: <BoardsIcon />,
    [routes.store]: <StoreIcon />,
    [routes.states]: <RegionIcon />,
    [routes.products]: <ProductIcon />,
    [routes.customers]: <CustomerIcon />,
    [routes.workers]: <AdminIcon />,
    [routes.marketing]: <MarketingIcon />,
    [routes.domain]: <DomainIcon />,
    [routes.productBuying]: <MoneyIcon />,
    [routes.history]: <MoneyIcon />,
  };

  return (
    <nav className={`${classNames.nav} h-100`}>
      <button
        type="button"
        className={`${classNames.controlButton} ${isSidebarClose ? classNames.controlButtonClosed : ''}`}
        onClick={handleToggleSidebar}
      >
        <DoubleArrow />
      </button>
      <div className={`${classNames.navLogo} ${isSidebarClose ? classNames.navLogoClosed : ''}`}>
        <NavLink to={homePages[role]}>{isSidebarClose ? <SmallLogoSvg /> : <BigLogoSvg />}</NavLink>
      </div>
      <ul className={`${classNames.sidebarList} ${isSidebarClose ? classNames.sidebarListClosed : ''} list-unstyled`}>
        {paths[role].map((path) => (
          <React.Fragment key={path.id}>
            <li>
              <NavLink activeClassName={classNames.active} to={path.path} className={`${classNames.sidebarLinks}`}>
                <span>{PathIcons[path.path]}</span>
                <p>{path.title}</p>
              </NavLink>
            </li>
          </React.Fragment>
        ))}
      </ul>
      <div className={`${classNames.sidebarMenu} ${isSidebarClose ? classNames.sidebarMenuClosed : ''}`}>
        <Settings />
      </div>
    </nav>
  );
};
Navbar.propTypes = {
  handleToggleSidebar: PropTypes.func,
  isSidebarClose: PropTypes.bool,
};
export default Navbar;
