import React from 'react';

const ControlArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.481" height="34.175" viewBox="0 0 19.481 34.175">
      <g transform="translate(-97.141 34.176) rotate(-90)">
        <path
          d="M17.088,116.621a2.386,2.386,0,0,1-1.692-.7L.7,101.226a2.393,2.393,0,0,1,3.385-3.385l13,13,13-13a2.393,2.393,0,0,1,3.384,3.385L18.78,115.921A2.386,2.386,0,0,1,17.088,116.621Z"
          transform="translate(0)"
          fill="#a6a6ab"
        />
      </g>
    </svg>
  );
};

export default ControlArrow;
