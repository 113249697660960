import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ExistBoards from './components/exist-boards/ExistBoards';
import classNames from './board.module.scss';
import { initialFetching } from '../../store/user/actions';

const Boards = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initialFetching());
  }, []);
  return (
    <div className="container maxw-100">
      <div className={classNames.boardNameContent}>
        <ExistBoards />
      </div>
    </div>
  );
};

export default Boards;
