import React from 'react';

const ArrowSvg = () => {
  return (
    <div className="arrowIcon">
      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7">
        <path
          id="Polygon_145"
          data-name="Polygon 145"
          d="M5.5,0,11,7H0Z"
          transform="translate(11 7) rotate(180)"
          fill="#397ae8"
        />
      </svg>
    </div>
  );
};

export default ArrowSvg;
