import React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const HeightPreservingItem = ({ children, ...props }) => {
  const [size, setSize] = useState(0);
  const knownSize = props['data-known-size'];
  useEffect(() => {
    setSize((prevSize) => {
      return knownSize === 0 ? prevSize : knownSize;
    });
  }, [knownSize]);
  // check style.css for the height-preserving-container rule
  return (
    <div
      {...props}
      className="height-preserving-container"
      style={{
        '--child-height': `${size}px`,
      }}
    >
      {children}
    </div>
  );
};

HeightPreservingItem.propTypes = {
  children: PropTypes.node,
  'data-known-size': PropTypes.number,
};

export default HeightPreservingItem;
