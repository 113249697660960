export const axiosData = {
  downLoadCSF: {
    url: '/api/lists/state/print',
    method: 'get',
  },
  referForDownload: {
    url: '/api/admin/lists/state/print',
    method: 'post',
  },
  downLoadBuyer: {
    url: '/api/buyers/download/:path',
    method: 'get',
  },
};
