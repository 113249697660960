import { all, call, put, takeEvery } from 'redux-saga/effects';
import { errorLogger } from '../../helpers/errorLogger';
import axios from '../../services/axios';
import { droppingErrors } from '../draggable-board/constants';
import { notifyMessage } from '../message';
import {
  addRate,
  deleteCustomerByPhone,
  deleteRate,
  getAddressesByPhone,
  getCustomers,
  getCustomersCount,
  getOrdersByPhone,
  getRatesByPhone,
  printBuyers,
} from './actions';
import { axiosData } from './requestGenerators';

function* getCustomersRequest(payload) {
  return yield call(axios.request, {
    ...axiosData.getCustomers,
    data: payload,
  });
}

function* watchCustomersRequest() {
  // OK
  yield takeEvery(getCustomers, function* workCustomers({ payload }) {
    const { T, ...rest } = payload;
    try {
      const response = yield call(getCustomersRequest, rest);
      yield put(getCustomers.success({ data: response.data.result }));
    } catch (e) {
      yield put(getCustomers.error());
      const { message: errorMessage } = e.response?.data?.error;
      yield call(
        notifyMessage,
        { body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage, timer: 3000 },
        T
      );
      errorLogger(e);
    }
  });
}

function* getCustomersCountRequest(payload) {
  return yield call(axios.request, {
    ...axiosData.getCustomersCount,
    data: payload,
  });
}

function* watchCustomersCountRequest() {
  // OK
  yield takeEvery(getCustomersCount, function* workCustomersCount({ payload: { T, ...payload } }) {
    try {
      const response = yield call(getCustomersCountRequest, payload);
      yield put(getCustomersCount.success({ data: response.data.result }));
    } catch (e) {
      yield put(getCustomersCount.error());
      const { message: errorMessage } = e.response.data.error;
      yield call(
        notifyMessage,
        {
          body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage,
          timer: 3000,
        },
        T
      );
      errorLogger(e);
    }
  });
}

function* deleteCustomerByPhoneRequest({ phone }) {
  const url = axiosData.deleteCustomer.url.replace(':phone', phone);

  yield call(axios.request, {
    ...axiosData.deleteCustomer,
    url,
  });
}

function* watchDeleleCustomerByPhoneRequest() {
  // OK
  yield takeEvery(deleteCustomerByPhone, function* workDeleteCustomer({ payload: { T, ...payload } }) {
    try {
      yield call(deleteCustomerByPhoneRequest, payload);
      yield put(deleteCustomerByPhone.success());
    } catch (e) {
      const { message: errorMessage } = e.response.data.error;
      yield call(
        notifyMessage,
        {
          body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage,
          timer: 3000,
        },
        T
      );
      errorLogger(e);
    }
  });
}

function* getOrdersByPhoneRequest(payload) {
  const url = axiosData.getOrders.url
    .replace(':phone', payload.phone)
    .replace(':limit', payload.limit)
    .replace(':offset', payload.offset);
  return yield call(axios.request, {
    ...axiosData.getOrders,
    url,
  });
}

function* watchGetOrdersRequest() {
  // OK
  yield takeEvery(getOrdersByPhone, function* workGetOrders({ payload: { T, ...payload } }) {
    try {
      const response = yield call(getOrdersByPhoneRequest, payload);
      yield put(getOrdersByPhone.success({ data: response?.data?.result }));
    } catch (e) {
      yield put(getOrdersByPhone.error());
      const { message: errorMessage } = e.response?.data?.error;
      yield call(
        notifyMessage,
        {
          body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage,
          timer: 3000,
        },
        T
      );
      errorLogger(e);
    }
  });
}

function* getAddressesByPhoneRequest({ phoneId }) {
  const url = axiosData.getAddress.url.replace(':phone', phoneId);

  return yield call(axios.request, {
    ...axiosData.getAddress,
    url,
  });
}

function* watchGetAddressesRequest() {
  // OK
  yield takeEvery(getAddressesByPhone, function* workGetAddresses({ payload: { T, ...payload } }) {
    try {
      const response = yield call(getAddressesByPhoneRequest, payload);
      yield put(getAddressesByPhone.success({ data: response.data?.result }));
    } catch (e) {
      yield put(getAddressesByPhone.error());
      const { message: errorMessage } = e.response.data.error;
      yield call(
        notifyMessage,
        {
          body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage,
          timer: 3000,
        },
        T
      );
      errorLogger(e);
    }
  });
}

function* getRatesByPhoneRequest({ phoneId }) {
  const url = axiosData.getRates.url.replace(':phone', phoneId);

  return yield call(axios.request, {
    ...axiosData.getRates,
    url,
  });
}

function* watchGetRatesRequest() {
  // OK
  yield takeEvery(getRatesByPhone, function* workGetRates({ payload: { T, ...payload } }) {
    try {
      const response = yield call(getRatesByPhoneRequest, payload);
      yield put(getRatesByPhone.success({ data: response.data?.result?.rows }));
    } catch (e) {
      yield put(getRatesByPhone.error());
      const { message: errorMessage } = e.response?.data?.error || T.error_something_went_wrong;
      yield call(
        notifyMessage,
        {
          body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage,
          timer: 3000,
        },
        T
      );
      errorLogger(e);
    }
  });
}

function* getPrintBuyersRequest({ checkboxIds, deletedItems, allCheckboxIds, filterParams = {} }) {
  return yield call(axios.request, {
    ...axiosData.printBuyers,
    data: {
      phones: checkboxIds,
      ...(allCheckboxIds === 'all'
        ? { excludePhones: [] }
        : allCheckboxIds === 'partial'
        ? { excludePhones: deletedItems }
        : {}),
      ...(filterParams?.sex?.value ? { sex: filterParams?.sex?.value } : {}),
      ...(filterParams?.status?.value ? { status: filterParams?.status?.value } : {}),
      ...(filterParams?.state?.value ? { state: filterParams?.state?.value } : {}),
      ...(filterParams?.minOrderCount ? { minOrderCount: filterParams?.minOrderCount } : {}),
      ...(filterParams?.maxOrderCount ? { maxOrderCount: filterParams?.maxOrderCount } : {}),
      ...(filterParams?.maxSpent ? { maxSpent: filterParams?.maxSpent } : {}),
      ...(filterParams?.minSpent ? { minSpent: filterParams?.minSpent } : {}),
      ...(filterParams?.searchWord?.length ? { searchWord: filterParams?.searchWord } : {}),
      ...(filterParams?.minOrderDate?.length ? { minOrderDate: filterParams?.minOrderDate } : {}),
      ...(filterParams?.maxOrderDate?.length ? { maxOrderDate: filterParams?.maxOrderDate } : {}),
    },
  });
}

function* watchGetPrintBuyersRequest() {
  // OK
  yield takeEvery(printBuyers, function* workPrintBuyers({ payload: { T, ...payload } }) {
    try {
      const response = yield call(getPrintBuyersRequest, payload);
      yield put(printBuyers.success({ data: response }));
      yield call(notifyMessage, { body: T.file_is_creating, timer: 1000 }, T);
    } catch (e) {
      yield put(printBuyers.error());
      const { message: errorMessage } = e.response.data.error;
      yield call(
        notifyMessage,
        { body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage, timer: 3000 },
        T
      );
      errorLogger(e);
    }
  });
}

function* getAddRateByPhoneRequest({ phone, rate }) {
  const url = axiosData.addRate.url.replace(':phone', phone).replace(':rate', rate);

  return yield call(axios.request, {
    ...axiosData.addRate,
    url,
  });
}

function* watchAddRateByPhoneRequest() {
  // OK
  yield takeEvery(addRate, function* workAddRate({ payload: { T, ...payload } }) {
    try {
      yield call(getAddRateByPhoneRequest, payload);
    } catch (e) {
      yield put(addRate.error());
      const { message: errorMessage } = e.response.data.error;
      yield call(
        notifyMessage,
        { body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage, timer: 3000 },
        T
      );
      errorLogger(e);
    }
  });
}

function* getDeleteRateByPhoneRequest({ mainPhone }) {
  const url = axiosData.deleteRates.url.replace(':phone', mainPhone);

  return yield call(axios.request, {
    ...axiosData.deleteRates,
    url,
  });
}

function* watchDeleteRateByPhoneRequest() {
  // OK
  yield takeEvery(deleteRate, function* workDeleteRate({ payload: { T, ...payload } }) {
    try {
      yield call(getDeleteRateByPhoneRequest, payload);
    } catch (e) {
      yield put(deleteRate.error());
      const { message: errorMessage } = e.response.data.error;
      yield call(
        notifyMessage,
        {
          body: droppingErrors(T)[errorMessage] || droppingErrors(T).defaultMessage,
          timer: 3000,
        },
        T
      );
      errorLogger(e);
    }
  });
}

export default function* customers() {
  yield all([
    watchCustomersRequest(),
    watchCustomersCountRequest(),
    watchDeleleCustomerByPhoneRequest(),
    watchGetOrdersRequest(),
    watchGetAddressesRequest(),
    watchGetRatesRequest(),
    watchGetPrintBuyersRequest(),
    watchAddRateByPhoneRequest(),
    watchDeleteRateByPhoneRequest(),
  ]);
}
