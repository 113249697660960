import React from 'react';
import PropTypes from 'prop-types';
import classNames from './multiSelect.module.scss';
import SearchSvg from '../../svgs/SearchSvg';
import ChevronSvg from '../../svgs/ChevronSvg';
import { useMultiSelect } from './useMultiSelect';
import CloseSvg from '../../svgs/CloseSvg';

const MultiSelect = ({
  isSearchable,
  placeholder,
  label,
  value: outerValue,
  onSearch = () => {},
  options,
  onChange,
  height,
  withAll,
}) => {
  const {
    isOpen,
    handleChangeSelect,
    handleSelectOpen,
    ref,
    inputValue,
    hanldeInputChange,
    selected,
    clearChips,
    inputRef,
  } = useMultiSelect({ onChange, options, outerValue, onSearch });
  return (
    <div className={classNames.selectWrapper}>
      <ul>
        {label && <label>{label}</label>}
        <li ref={ref}>
          <div onClick={handleSelectOpen} className={classNames.checkWrapper}>
            {selected?.length ? (
              <div className={classNames.selelctedCount}>
                <p>{`+ ${selected.length}`}</p>
                <button type="button" onClick={clearChips}>
                  <CloseSvg />
                </button>
              </div>
            ) : null}
            <input
              ref={inputRef}
              style={{ height }}
              type="text"
              value={inputValue}
              placeholder={selected.length ? '' : placeholder}
              readOnly={!isSearchable}
              onChange={hanldeInputChange}
            />
            {isSearchable ? <SearchSvg /> : <ChevronSvg />}
          </div>
          {isOpen && (
            <ul>
              {options.length ? (
                <>
                  {withAll && (
                    <li>
                      <label
                        className={`${
                          selected.length && selected.length !== options.length ? classNames.partialSelect : ''
                        }`}
                      >
                        <input
                          value="all"
                          checked={selected.length === options.length}
                          onChange={(e) => handleChangeSelect(e)}
                          type="checkbox"
                        />
                        <span />
                        All
                      </label>
                    </li>
                  )}
                  {options?.map(({ value, label }) => (
                    <li key={value}>
                      <label>
                        <input
                          value={value}
                          checked={!!selected?.find((item) => item.value === value)}
                          onChange={(e) => handleChangeSelect(e, label, value)}
                          type="checkbox"
                        />
                        <span />
                        {label}
                      </label>
                    </li>
                  ))}
                </>
              ) : (
                <p className={classNames.noDataItem}>no data</p>
              )}
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};

MultiSelect.propTypes = {
  isSearchable: PropTypes.bool,
  withAll: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  height: PropTypes.number,
  value: PropTypes.array,
  onSearch: PropTypes.func,
};

export default MultiSelect;
