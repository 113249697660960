import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useExistBoards } from './useExistBoards';
import { routes } from '../../../../routes';
import classNames from './existBoards.module.scss';
import Button from '../../../../components/button/Button';
import CreateBoardButton from '../create-board-button/CreateBoardButton';
import { selectLang, selectUserRole } from '../../../../store/user/selectors';
import VerticalDotsSvg from '../../../../svgs/VerticalDotsSvg';
import { Lang } from '../../../../T';

const ExistBoards = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const { data, loading, openEditBoardModal, openDeleteModal, deleteLoading } = useExistBoards();
  const [showMenu, setShowMenu] = useState(null);
  const history = useHistory();
  const role = useSelector(selectUserRole);

  useEffect(() => {
    const documentClick = () => {
      if (showMenu) {
        setShowMenu(null);
      }
    };
    document.addEventListener('click', documentClick);
    return () => {
      document.removeEventListener('click', documentClick);
    };
  }, [showMenu]);

  return loading || deleteLoading ? (
    <span>Loading....</span>
  ) : (
    <>
      <div className={classNames.boardsHeader}>
        <h2>{T.boards}</h2>
      </div>
      <div className={classNames.boardnNameContainer}>
        {data?.map(({ name, id }) => {
          return (
            <div key={id} className={classNames.existBoardItemContent}>
              <div
                className={`${classNames.existBoardItem} d-flex`}
                onClick={() => history.push(routes.draggableBoard.replace(':id', id).replace(':filterId', 'default'))}
              >
                <span className={classNames.itemName}>{name}</span>
                <>
                  {(role === 'admin' || role === 'director') && (
                    <Button
                      className={classNames.dotsButton}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowMenu(showMenu ? null : id);
                      }}
                    >
                      <VerticalDotsSvg />
                    </Button>
                  )}
                  {showMenu === id && (
                    <div className={classNames.boardActionsContent}>
                      <button
                        type="button"
                        onClick={(e) => {
                          openEditBoardModal(id);
                          e.stopPropagation();
                        }}
                      >
                        {T.edit}
                      </button>
                      <button
                        type="button"
                        disabled={role === 'director'}
                        onClick={(e) => {
                          openDeleteModal(name, id);
                          e.stopPropagation();
                        }}
                      >
                        {T.delete}
                      </button>
                    </div>
                  )}
                </>
              </div>
            </div>
          );
        })}
      </div>

      <CreateBoardButton />
    </>
  );
};
export default ExistBoards;
