import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/user/selectors';
import { Lang } from '../../T';
import { getHistoryRequest } from './helpers';
import usePagination from '../../hooks/usePagination';

const useHistory = () => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const periodOptions = [
    { label: T.Today, value: 'today' },
    { label: T.Yesterday, value: 'yesterday' },
    { label: T.CurrentWeek, value: 'currentWeek' },
    { label: T.CurrentMonth, value: 'currentMonth' },
    { label: T.CurrentYear, value: 'currentYear' },
    { label: T.PreviousWeek, value: 'previousWeek' },
    { label: T.PreviousMonth, value: 'previousMonth' },
    { label: T.PreviousYear, value: 'previousYear' },
  ];

  const initialFilter = { period: periodOptions[2], startDate: null, endDate: null, userId: null };

  const [filter, setFilter] = useState(initialFilter);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);

  const { page, limit, changePage, changeLimit, pagesCount } = usePagination(total);

  const getHistory = async (filter, page, limit) => {
    const { rows, count } = await getHistoryRequest(filter, page, limit);
    setTotal(count || 0);
    setData(rows || []);
  };

  useEffect(() => {
    getHistory(filter, page, limit);
  }, [filter, page, limit]);

  return {
    filter,
    setFilter,
    periodOptions,
    initialFilter,
    page,
    limit,
    changePage,
    changeLimit,
    pagesCount,
    data,
    total,
  };
};

export default useHistory;
