import { all, takeEvery, put, call } from 'redux-saga/effects';
import Axios from '../../services/axios';
import { errorLogger } from '../../helpers/errorLogger';
import { getSticker, createSticker, editSticker, removeSticker } from './actions';
import { axiosData } from './requestGenerators';

function* getStickersRequest(boardId) {
  const url = axiosData.getStickers.url.replace(':id', boardId);
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.getStickers, url });

  return result;
}

function* watchGetStickers() {
  yield takeEvery(getSticker, function* workGetStickers({ payload: { boardId } }) {
    try {
      const data = yield call(getStickersRequest, boardId);
      yield put(getSticker.success({ data }));
    } catch (e) {
      errorLogger(e);
      yield put(getSticker.error());
    }
  });
}

function* createStickerRequest(data) {
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.createSticker, data });

  return result;
}

function* watchCreateSticker() {
  yield takeEvery(createSticker, function* workCreateSticker({ payload: { values } }) {
    try {
      yield call(createStickerRequest, values);

      yield put(createSticker.success());
      yield put(createSticker.reset());
      yield put(getSticker({ boardId: values.boardId }));
    } catch (e) {
      errorLogger(e);
      yield put(createSticker.error());
    }
  });
}

function* editStickerRequest({ id, ...data }) {
  const url = axiosData.editSticker.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.editSticker, data, url });

  return result;
}

function* watchEditSticker() {
  yield takeEvery(
    editSticker,
    function* workEditSticker({
      payload: {
        values: { boardId, ...values },
      },
    }) {
      const { id } = values;
      try {
        yield call(editStickerRequest, values);

        yield put(editSticker.success({ id }));
        yield put(editSticker.reset({ id }));
        yield put(getSticker({ boardId }));
      } catch (e) {
        errorLogger(e);
        yield put(editSticker.error({ id }));
      }
    }
  );
}

function* removeStickerRequest(id) {
  const url = axiosData.removeSticker.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.removeSticker, url });

  return result;
}

function* watchRemoveSticker() {
  yield takeEvery(removeSticker, function* workRemoveSticker({ payload: { boardId, id } }) {
    try {
      yield call(removeStickerRequest, id);

      yield put(removeSticker.success({ id }));
      yield put(removeSticker.reset({ id }));
      yield put(getSticker({ boardId }));
    } catch (e) {
      errorLogger(e);
      yield put(removeSticker.error({ id }));
    }
  });
}

export default function* stickers() {
  yield all([watchGetStickers(), watchCreateSticker(), watchEditSticker(), watchRemoveSticker()]);
}
