import { all, takeEvery, put, call } from 'redux-saga/effects';
import { getAddressEdit, getAddress, removeAddress, addressCreate, addressEdit } from './actions';
import { errorLogger } from '../../helpers/errorLogger';
import { workersData } from './mockdata';
import { closeModal } from '../modals/actions';
import { modalNames } from '../modals/constants';
import Axios from '../../services/axios';
import { axiosData } from './requestGenerator';
import { notifyMessage } from '../message';
import showNotification from '../../modals/components/notification/showNotification';

function* getAddressRequest() {
  const {
    data: { result },
  } = yield call(Axios.request, { ...axiosData.getAddress });
  return result;
}

function* watchGetAddress() {
  yield takeEvery(getAddress, function* workGetAddress() {
    try {
      const result = yield call(getAddressRequest);
      yield put(getAddress.success({ data: result }));
    } catch (e) {
      yield put(getAddress.error());
      errorLogger(e);
    }
  });
}

function* removeAddressRequest(id) {
  const url = axiosData.removeAddress.url.replace(':id', id);
  yield call(Axios.request, {
    ...axiosData.removeAddress,
    url,
  });
}

function* watchRemoveAddress() {
  yield takeEvery(removeAddress, function* workRemoveAddress({ payload: { id } }) {
    try {
      if (id !== 1) {
        yield call(removeAddressRequest, id);
      }
      yield put(removeAddress.success({ data: workersData }));
      yield put(getAddress());
    } catch (e) {
      yield put(removeAddress.error());
      errorLogger(e);
    }
  });
}

function* getAddressEditRequest(id) {
  const url = axiosData.getAddressInfoToEdit.url.replace(':id', id);
  const {
    data: { result },
  } = yield call(Axios.request, {
    ...axiosData.getAddressInfoToEdit,
    url,
  });

  const listsUrl = axiosData.getAddressInfoToEditLists.url.replace(':id', id);
  const {
    data: { result: listsResult },
  } = yield call(Axios.request, {
    ...axiosData.getAddressInfoToEditLists,
    url: listsUrl,
  });

  return { ...result, lists: listsResult };
}

function* watchGetAddressEdit() {
  yield takeEvery(getAddressEdit, function* workRemoveAddress({ payload: { id } }) {
    try {
      const data = yield call(getAddressEditRequest, id);
      yield put(getAddressEdit.success({ data }));
    } catch (e) {
      yield put(getAddressEdit.error());
      errorLogger(e);
    }
  });
}

function* addressEditRequest(values) {
  const url = axiosData.addressEdit.url.replace(':id', values.id);
  const res = yield call(Axios.request, {
    ...axiosData.addressEdit,
    url,
    data: {
      name: values.senderName,
      address: values.senderAddress,
      zipCode: values.senderZip,
      boardId: values.senderBoard,
      bankAccount: values.senderBank,
    },
  });

  return res;
}

function* watchAddressEdit() {
  // OK
  yield takeEvery(addressEdit, function* workRemoveAddress({ payload: { values, dispatch, T } }) {
    try {
      yield call(addressEditRequest, values);
      yield put(addressEdit.success());
      yield put(closeModal({ name: modalNames.createEditAddress }));
      yield put(getAddress());
    } catch (e) {
      showNotification(dispatch, e.respons.data.error.detailed_message);
      yield put(addressEdit.error());
      yield call(notifyMessage, { body: T.the_email_already_taken, timer: '2000' }, T);
      errorLogger(e);
    }
  });
}

function* addressCreateRequest(values) {
  const res = yield call(Axios.request, {
    ...axiosData.createAddress,
    data: {
      name: values.senderName,
      address: values.senderAddress,
      zipCode: values.senderZip,
      boardId: values.senderBoard,
      bankAccount: values.senderBank,
    },
  });

  return res;
}

function* watchAddressCreate() {
  // OK
  yield takeEvery(addressCreate, function* workAddressCreate({ payload: { values, T } }) {
    try {
      yield call(addressCreateRequest, values);
      yield put(addressCreate.success());

      yield put(closeModal({ name: modalNames.createEditAddress }));
      yield put(getAddress());
    } catch (e) {
      yield put(addressCreate.error());
      yield call(notifyMessage, { body: T.the_email_already_taken, timer: '2000' }, T);

      errorLogger(e);
    }
  });
}

export default function* address() {
  yield all([watchGetAddress(), watchRemoveAddress(), watchGetAddressEdit(), watchAddressEdit(), watchAddressCreate()]);
}
