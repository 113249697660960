import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../../store/modals/actions';
import { modalNames } from '../../../../store/modals/constants';
import { selectDraggableBoard } from '../../../../store/draggable-board/selector';
// eslint-disable-next-line import/no-cycle
import { useApp } from '../../../../useApp';
import { Lang } from '../../../../T';
import { selectLang } from '../../../../store/user/selectors';

const AddProductDeliveryman = ({ userId, boardId, listId }) => {
  const lang = useSelector(selectLang);
  const T = Lang(lang);

  const dispatch = useDispatch();
  const { archived } = useSelector(selectDraggableBoard);
  const { role } = useApp();

  const handleOpenDeliverymanModal = useCallback(() => {
    dispatch(
      openModal({
        name: modalNames.attachDeliverymenProduct,
        data: { id: userId, boardId, listId },
      })
    );
  }, [userId, boardId, listId]);

  return (
    !archived &&
    (role === 'storeKeeper' || role === 'admin' || role === 'director') && (
      <button type="button" className="addProductBoardBtn" onClick={handleOpenDeliverymanModal}>
        + {T.add_product}
      </button>
    )
  );
};

AddProductDeliveryman.propTypes = {
  userId: PropTypes.number,
  boardId: PropTypes.string,
  listId: PropTypes.string,
};

export default AddProductDeliveryman;
