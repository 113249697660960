import { all, call, put, takeEvery } from 'redux-saga/effects';
import { deleteStorePermission, getStorePermission, setStorePermission } from './actions';
import { axiosData } from './request';
import { storeAxios } from '../../services/axios';
import { errorLogger } from '../../helpers/errorLogger';

function* getStorePermissionRequest(id) {
  const url = axiosData.getStorePermission.url.replace(':id', id);
  const response = yield call(storeAxios.request, { ...axiosData.getStorePermission, url });
  return response?.data.result;
}

function* watchGetStorePermission() {
  yield takeEvery(getStorePermission, function* worker({ payload: { id } }) {
    try {
      const response = yield call(getStorePermissionRequest, id);
      yield put(getStorePermission.success(response));
    } catch (e) {
      yield put(getStorePermission.error());
      errorLogger(e);
    }
  });
}

function* setStorePermissionRequest({ id, values }) {
  const url = axiosData.setStorePermission.url.replace(':id', id);
  const response = yield call(storeAxios.request, {
    ...axiosData.setStorePermission,
    url,
    data: {
      ...values,
      ads: undefined,
      productPermissions: !values.product ? undefined : values?.productPermissions,
    },
  });
  return response?.data.result;
}

function* watchSetStorePermission() {
  yield takeEvery(setStorePermission, function* worker({ payload: { id, values } }) {
    try {
      const response = yield call(setStorePermissionRequest, { id, values });
      yield put(setStorePermission.success(response));
    } catch (e) {
      yield put(setStorePermission.error());
      errorLogger(e);
    }
  });
}

function* deleteStorePermissionRequest(id) {
  const url = axiosData.deleteStorePermission.url.replace(':id', id);
  const response = yield call(storeAxios.request, { ...axiosData.deleteStorePermission, url });
  return response?.data.result;
}

function* watchdeleteStorePermission() {
  yield takeEvery(deleteStorePermission, function* worker({ payload: { id } }) {
    try {
      const response = yield call(deleteStorePermissionRequest, id);
      yield put(deleteStorePermission.success(response));
    } catch (e) {
      yield put(deleteStorePermission.error());
      errorLogger(e);
    }
  });
}

export default function* workers() {
  yield all([watchGetStorePermission(), watchSetStorePermission(), watchdeleteStorePermission()]);
}
