import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getAddress, removeAddress, getAddressEdit, addressEdit, addressCreate } from './actions';

const getInitialState = {
  loading: false,
  error: false,
  data: [],
};
const get = handleActions(
  {
    [getAddress]: () => ({
      loading: true,
      error: false,
      data: [],
    }),
    [getAddress.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      data,
    }),
    [getAddress.error]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [getAddress.reset]: () => ({
      loading: false,
      error: false,
      data: [],
    }),
  },
  getInitialState
);

const removeWorkerInitialState = { loading: false, error: false };

const remove = handleActions(
  {
    [removeAddress]: () => ({
      loading: true,
      error: false,
    }),
    [removeAddress.success]: () => ({
      loading: false,
      error: false,
    }),
    [removeAddress.error]: () => ({
      loading: false,
      error: true,
    }),
    [removeAddress.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  removeWorkerInitialState
);

const getEditInitialData = { loading: false, error: false, data: {} };

const getEdit = handleActions(
  {
    [getAddressEdit]: () => ({
      loading: true,
      error: false,
      data: {},
    }),
    [getAddressEdit.success]: (_, { payload: { data } }) => ({
      loading: false,
      error: false,
      data,
    }),
    [getAddressEdit.error]: () => ({
      loading: false,
      error: true,
      data: {},
    }),
    [getAddressEdit.reset]: () => ({
      loading: false,
      error: false,
      data: {},
    }),
  },
  getEditInitialData
);

const editInitialData = { loading: false, error: false };

const edit = handleActions(
  {
    [addressEdit]: () => ({
      loading: true,
      error: false,
    }),
    [addressEdit.success]: () => ({
      loading: false,
      error: false,
    }),
    [addressEdit.error]: () => ({
      loading: false,
      error: true,
    }),
    [addressEdit.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  editInitialData
);

const createInitialState = {
  loading: false,
  error: false,
};
const create = handleActions(
  {
    [addressCreate]: () => ({
      loading: true,
      error: false,
    }),
    [addressCreate.success]: () => ({
      loading: false,
      error: false,
    }),
    [addressCreate.error]: () => ({
      loading: false,
      error: true,
    }),
    [addressCreate.reset]: () => ({
      loading: false,
      error: false,
    }),
  },
  createInitialState
);

export default combineReducers({ get, remove, getEdit, edit, create });
